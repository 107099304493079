/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MaintenanceScheduleWithRecommendation
 */
export interface MaintenanceScheduleWithRecommendation {
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceScheduleWithRecommendation
     */
    covered?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceScheduleWithRecommendation
     */
    services?: string;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceScheduleWithRecommendation
     */
    mileage?: number;
    /**
     * 
     * @type {Date}
     * @memberof MaintenanceScheduleWithRecommendation
     */
    date?: Date;
}

/**
 * Check if a given object implements the MaintenanceScheduleWithRecommendation interface.
 */
export function instanceOfMaintenanceScheduleWithRecommendation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MaintenanceScheduleWithRecommendationFromJSON(json: any): MaintenanceScheduleWithRecommendation {
    return MaintenanceScheduleWithRecommendationFromJSONTyped(json, false);
}

export function MaintenanceScheduleWithRecommendationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaintenanceScheduleWithRecommendation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'covered': !exists(json, 'covered') ? undefined : json['covered'],
        'services': !exists(json, 'services') ? undefined : json['services'],
        'mileage': !exists(json, 'mileage') ? undefined : json['mileage'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
    };
}

export function MaintenanceScheduleWithRecommendationToJSON(value?: MaintenanceScheduleWithRecommendation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'covered': value.covered,
        'services': value.services,
        'mileage': value.mileage,
        'date': value.date === undefined ? undefined : (value.date.toISOString().substr(0,10)),
    };
}

