/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InspectionSectionConversationMessageInfo } from './InspectionSectionConversationMessageInfo';
import {
    InspectionSectionConversationMessageInfoFromJSON,
    InspectionSectionConversationMessageInfoFromJSONTyped,
    InspectionSectionConversationMessageInfoToJSON,
} from './InspectionSectionConversationMessageInfo';

/**
 * 
 * @export
 * @interface InspectionSectionConversationMessageEntry
 */
export interface InspectionSectionConversationMessageEntry {
    /**
     * 
     * @type {string}
     * @memberof InspectionSectionConversationMessageEntry
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof InspectionSectionConversationMessageEntry
     */
    created?: Date;
    /**
     * 
     * @type {InspectionSectionConversationMessageInfo}
     * @memberof InspectionSectionConversationMessageEntry
     */
    info?: InspectionSectionConversationMessageInfo;
}

/**
 * Check if a given object implements the InspectionSectionConversationMessageEntry interface.
 */
export function instanceOfInspectionSectionConversationMessageEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InspectionSectionConversationMessageEntryFromJSON(json: any): InspectionSectionConversationMessageEntry {
    return InspectionSectionConversationMessageEntryFromJSONTyped(json, false);
}

export function InspectionSectionConversationMessageEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionSectionConversationMessageEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'info': !exists(json, 'info') ? undefined : InspectionSectionConversationMessageInfoFromJSON(json['info']),
    };
}

export function InspectionSectionConversationMessageEntryToJSON(value?: InspectionSectionConversationMessageEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'info': InspectionSectionConversationMessageInfoToJSON(value.info),
    };
}

