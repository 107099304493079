/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntakeRequestInfo
 */
export interface IntakeRequestInfo {
    /**
     * 
     * @type {string}
     * @memberof IntakeRequestInfo
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof IntakeRequestInfo
     */
    vehicleId?: string;
    /**
     * 
     * @type {string}
     * @memberof IntakeRequestInfo
     */
    ipAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof IntakeRequestInfo
     */
    signature?: string;
    /**
     * 
     * @type {string}
     * @memberof IntakeRequestInfo
     */
    concern?: string;
    /**
     * 
     * @type {Date}
     * @memberof IntakeRequestInfo
     */
    dateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof IntakeRequestInfo
     */
    repairOrderId?: string;
}

/**
 * Check if a given object implements the IntakeRequestInfo interface.
 */
export function instanceOfIntakeRequestInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IntakeRequestInfoFromJSON(json: any): IntakeRequestInfo {
    return IntakeRequestInfoFromJSONTyped(json, false);
}

export function IntakeRequestInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntakeRequestInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'vehicleId': !exists(json, 'vehicleId') ? undefined : json['vehicleId'],
        'ipAddress': !exists(json, 'ipAddress') ? undefined : json['ipAddress'],
        'signature': !exists(json, 'signature') ? undefined : json['signature'],
        'concern': !exists(json, 'concern') ? undefined : json['concern'],
        'dateTime': !exists(json, 'dateTime') ? undefined : (new Date(json['dateTime'])),
        'repairOrderId': !exists(json, 'repairOrderId') ? undefined : json['repairOrderId'],
    };
}

export function IntakeRequestInfoToJSON(value?: IntakeRequestInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'vehicleId': value.vehicleId,
        'ipAddress': value.ipAddress,
        'signature': value.signature,
        'concern': value.concern,
        'dateTime': value.dateTime === undefined ? undefined : (value.dateTime.toISOString()),
        'repairOrderId': value.repairOrderId,
    };
}

