/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepairOrderStatusEntry
 */
export interface RepairOrderStatusEntry {
    /**
     * 
     * @type {string}
     * @memberof RepairOrderStatusEntry
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderStatusEntry
     */
    displayName?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderStatusEntry
     */
    rank?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderStatusEntry
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderStatusEntry
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderStatusEntry
     */
    replacing?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RepairOrderStatusEntry
     */
    hide?: boolean;
}

/**
 * Check if a given object implements the RepairOrderStatusEntry interface.
 */
export function instanceOfRepairOrderStatusEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairOrderStatusEntryFromJSON(json: any): RepairOrderStatusEntry {
    return RepairOrderStatusEntryFromJSONTyped(json, false);
}

export function RepairOrderStatusEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairOrderStatusEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'replacing': !exists(json, 'replacing') ? undefined : json['replacing'],
        'hide': !exists(json, 'hide') ? undefined : json['hide'],
    };
}

export function RepairOrderStatusEntryToJSON(value?: RepairOrderStatusEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'rank': value.rank,
        'color': value.color,
        'shop': value.shop,
        'replacing': value.replacing,
        'hide': value.hide,
    };
}

