/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerEntry } from './CustomerEntry';
import {
    CustomerEntryFromJSON,
    CustomerEntryFromJSONTyped,
    CustomerEntryToJSON,
} from './CustomerEntry';
import type { IntakeRequestEntry } from './IntakeRequestEntry';
import {
    IntakeRequestEntryFromJSON,
    IntakeRequestEntryFromJSONTyped,
    IntakeRequestEntryToJSON,
} from './IntakeRequestEntry';
import type { RepairOrderEntry } from './RepairOrderEntry';
import {
    RepairOrderEntryFromJSON,
    RepairOrderEntryFromJSONTyped,
    RepairOrderEntryToJSON,
} from './RepairOrderEntry';
import type { VehicleEntry } from './VehicleEntry';
import {
    VehicleEntryFromJSON,
    VehicleEntryFromJSONTyped,
    VehicleEntryToJSON,
} from './VehicleEntry';

/**
 * 
 * @export
 * @interface IntakeRequestEntryExpanded
 */
export interface IntakeRequestEntryExpanded {
    /**
     * 
     * @type {IntakeRequestEntry}
     * @memberof IntakeRequestEntryExpanded
     */
    entry?: IntakeRequestEntry;
    /**
     * 
     * @type {Array<string>}
     * @memberof IntakeRequestEntryExpanded
     */
    cannedJobs?: Array<string>;
    /**
     * 
     * @type {CustomerEntry}
     * @memberof IntakeRequestEntryExpanded
     */
    customer?: CustomerEntry;
    /**
     * 
     * @type {VehicleEntry}
     * @memberof IntakeRequestEntryExpanded
     */
    vehicle?: VehicleEntry;
    /**
     * 
     * @type {RepairOrderEntry}
     * @memberof IntakeRequestEntryExpanded
     */
    repairOrder?: RepairOrderEntry;
}

/**
 * Check if a given object implements the IntakeRequestEntryExpanded interface.
 */
export function instanceOfIntakeRequestEntryExpanded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IntakeRequestEntryExpandedFromJSON(json: any): IntakeRequestEntryExpanded {
    return IntakeRequestEntryExpandedFromJSONTyped(json, false);
}

export function IntakeRequestEntryExpandedFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntakeRequestEntryExpanded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': !exists(json, 'entry') ? undefined : IntakeRequestEntryFromJSON(json['entry']),
        'cannedJobs': !exists(json, 'cannedJobs') ? undefined : json['cannedJobs'],
        'customer': !exists(json, 'customer') ? undefined : CustomerEntryFromJSON(json['customer']),
        'vehicle': !exists(json, 'vehicle') ? undefined : VehicleEntryFromJSON(json['vehicle']),
        'repairOrder': !exists(json, 'repairOrder') ? undefined : RepairOrderEntryFromJSON(json['repairOrder']),
    };
}

export function IntakeRequestEntryExpandedToJSON(value?: IntakeRequestEntryExpanded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': IntakeRequestEntryToJSON(value.entry),
        'cannedJobs': value.cannedJobs,
        'customer': CustomerEntryToJSON(value.customer),
        'vehicle': VehicleEntryToJSON(value.vehicle),
        'repairOrder': RepairOrderEntryToJSON(value.repairOrder),
    };
}

