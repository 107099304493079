/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RepairLinkPartInCartExpandedInfo } from './RepairLinkPartInCartExpandedInfo';
import {
    RepairLinkPartInCartExpandedInfoFromJSON,
    RepairLinkPartInCartExpandedInfoFromJSONTyped,
    RepairLinkPartInCartExpandedInfoToJSON,
} from './RepairLinkPartInCartExpandedInfo';
import type { RepairLinkPriceCheckItem } from './RepairLinkPriceCheckItem';
import {
    RepairLinkPriceCheckItemFromJSON,
    RepairLinkPriceCheckItemFromJSONTyped,
    RepairLinkPriceCheckItemToJSON,
} from './RepairLinkPriceCheckItem';

/**
 * 
 * @export
 * @interface RepairLinkPartInCartExpandedChecked
 */
export interface RepairLinkPartInCartExpandedChecked {
    /**
     * 
     * @type {RepairLinkPriceCheckItem}
     * @memberof RepairLinkPartInCartExpandedChecked
     */
    checkResult?: RepairLinkPriceCheckItem;
    /**
     * 
     * @type {RepairLinkPartInCartExpandedInfo}
     * @memberof RepairLinkPartInCartExpandedChecked
     */
    part?: RepairLinkPartInCartExpandedInfo;
}

/**
 * Check if a given object implements the RepairLinkPartInCartExpandedChecked interface.
 */
export function instanceOfRepairLinkPartInCartExpandedChecked(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairLinkPartInCartExpandedCheckedFromJSON(json: any): RepairLinkPartInCartExpandedChecked {
    return RepairLinkPartInCartExpandedCheckedFromJSONTyped(json, false);
}

export function RepairLinkPartInCartExpandedCheckedFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairLinkPartInCartExpandedChecked {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checkResult': !exists(json, 'checkResult') ? undefined : RepairLinkPriceCheckItemFromJSON(json['checkResult']),
        'part': !exists(json, 'part') ? undefined : RepairLinkPartInCartExpandedInfoFromJSON(json['part']),
    };
}

export function RepairLinkPartInCartExpandedCheckedToJSON(value?: RepairLinkPartInCartExpandedChecked | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checkResult': RepairLinkPriceCheckItemToJSON(value.checkResult),
        'part': RepairLinkPartInCartExpandedInfoToJSON(value.part),
    };
}

