/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerFeedbackInfo } from './CustomerFeedbackInfo';
import {
    CustomerFeedbackInfoFromJSON,
    CustomerFeedbackInfoFromJSONTyped,
    CustomerFeedbackInfoToJSON,
} from './CustomerFeedbackInfo';

/**
 * 
 * @export
 * @interface ApiKeyEntry
 */
export interface ApiKeyEntry {
    /**
     * 
     * @type {string}
     * @memberof ApiKeyEntry
     */
    apiKey?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApiKeyEntry
     */
    reviewDateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyEntry
     */
    inspection?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyEntry
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyEntry
     */
    repairOrder?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyEntry
     */
    vehicle?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyEntry
     */
    customer?: string;
    /**
     * 
     * @type {Date}
     * @memberof ApiKeyEntry
     */
    expiration?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ApiKeyEntry
     */
    showPrice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiKeyEntry
     */
    intent?: ApiKeyEntryIntentEnum;
    /**
     * 
     * @type {Date}
     * @memberof ApiKeyEntry
     */
    createdAt?: Date;
    /**
     * 
     * @type {CustomerFeedbackInfo}
     * @memberof ApiKeyEntry
     */
    info?: CustomerFeedbackInfo;
}


/**
 * @export
 */
export const ApiKeyEntryIntentEnum = {
    Authorization: 'authorization',
    TextToPay: 'text_to_pay',
    Feedback: 'feedback',
    Inspection: 'inspection'
} as const;
export type ApiKeyEntryIntentEnum = typeof ApiKeyEntryIntentEnum[keyof typeof ApiKeyEntryIntentEnum];


/**
 * Check if a given object implements the ApiKeyEntry interface.
 */
export function instanceOfApiKeyEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ApiKeyEntryFromJSON(json: any): ApiKeyEntry {
    return ApiKeyEntryFromJSONTyped(json, false);
}

export function ApiKeyEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiKeyEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiKey': !exists(json, 'apiKey') ? undefined : json['apiKey'],
        'reviewDateTime': !exists(json, 'reviewDateTime') ? undefined : (new Date(json['reviewDateTime'])),
        'inspection': !exists(json, 'inspection') ? undefined : json['inspection'],
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'repairOrder': !exists(json, 'repairOrder') ? undefined : json['repairOrder'],
        'vehicle': !exists(json, 'vehicle') ? undefined : json['vehicle'],
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'expiration': !exists(json, 'expiration') ? undefined : (new Date(json['expiration'])),
        'showPrice': !exists(json, 'showPrice') ? undefined : json['showPrice'],
        'intent': !exists(json, 'intent') ? undefined : json['intent'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'info': !exists(json, 'info') ? undefined : CustomerFeedbackInfoFromJSON(json['info']),
    };
}

export function ApiKeyEntryToJSON(value?: ApiKeyEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiKey': value.apiKey,
        'reviewDateTime': value.reviewDateTime === undefined ? undefined : (value.reviewDateTime.toISOString()),
        'inspection': value.inspection,
        'shop': value.shop,
        'repairOrder': value.repairOrder,
        'vehicle': value.vehicle,
        'customer': value.customer,
        'expiration': value.expiration === undefined ? undefined : (value.expiration.toISOString()),
        'showPrice': value.showPrice,
        'intent': value.intent,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'info': CustomerFeedbackInfoToJSON(value.info),
    };
}

