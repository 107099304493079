/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShopInfo
 */
export interface ShopInfo {
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    barNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    epaNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    invoiceHeader?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    legalDisclaimer?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    franchise?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    partsTechUserId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    invoiceShowPartBrands?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    invoiceShowPartNumbers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    invoiceShowHours?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    invoiceShowHourlyRate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    invoiceShowTechnician?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShopInfo
     */
    calendarStartDay?: number;
    /**
     * 
     * @type {number}
     * @memberof ShopInfo
     */
    calendarEndDay?: number;
    /**
     * 
     * @type {Date}
     * @memberof ShopInfo
     */
    calendarStartTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ShopInfo
     */
    calendarEndTime?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    customerPortalShowPrice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    invoiceFooter?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    shopLogoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    shopLogoThumbnailUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    authorizationShowPartNumbers?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    invoiceByService?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    closeRoWithoutInvoiceNumber?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    closeRoWithoutReceived?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    closeRoWithoutMileage?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    showEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    invoiceShowLatestAuthorizationOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    googleMapsUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    yelpUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    enableCarfax?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    carfaxAuthorized?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    deferredServicesInInvoice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    isOptionalConcern?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    repairLinkCredentialsSet?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    codatId?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    codatDefaultItemId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ShopInfo
     */
    lastCodatModifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    intakeDisclaimer: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    showKpi?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ShopInfo
     */
    invoiceTextNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    invoiceShowVehicleTire?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    quickVinAuthorized?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfo
     */
    ignoreVerification?: boolean;
}

/**
 * Check if a given object implements the ShopInfo interface.
 */
export function instanceOfShopInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "googleMapsUrl" in value;
    isInstance = isInstance && "yelpUrl" in value;
    isInstance = isInstance && "intakeDisclaimer" in value;

    return isInstance;
}

export function ShopInfoFromJSON(json: any): ShopInfo {
    return ShopInfoFromJSONTyped(json, false);
}

export function ShopInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'barNumber': !exists(json, 'barNumber') ? undefined : json['barNumber'],
        'epaNumber': !exists(json, 'epaNumber') ? undefined : json['epaNumber'],
        'invoiceHeader': !exists(json, 'invoiceHeader') ? undefined : json['invoiceHeader'],
        'legalDisclaimer': !exists(json, 'legalDisclaimer') ? undefined : json['legalDisclaimer'],
        'franchise': !exists(json, 'franchise') ? undefined : json['franchise'],
        'partsTechUserId': !exists(json, 'partsTechUserId') ? undefined : json['partsTechUserId'],
        'invoiceShowPartBrands': !exists(json, 'invoiceShowPartBrands') ? undefined : json['invoiceShowPartBrands'],
        'invoiceShowPartNumbers': !exists(json, 'invoiceShowPartNumbers') ? undefined : json['invoiceShowPartNumbers'],
        'invoiceShowHours': !exists(json, 'invoiceShowHours') ? undefined : json['invoiceShowHours'],
        'invoiceShowHourlyRate': !exists(json, 'invoiceShowHourlyRate') ? undefined : json['invoiceShowHourlyRate'],
        'invoiceShowTechnician': !exists(json, 'invoiceShowTechnician') ? undefined : json['invoiceShowTechnician'],
        'calendarStartDay': !exists(json, 'calendarStartDay') ? undefined : json['calendarStartDay'],
        'calendarEndDay': !exists(json, 'calendarEndDay') ? undefined : json['calendarEndDay'],
        'calendarStartTime': !exists(json, 'calendarStartTime') ? undefined : (new Date(json['calendarStartTime'])),
        'calendarEndTime': !exists(json, 'calendarEndTime') ? undefined : (new Date(json['calendarEndTime'])),
        'customerPortalShowPrice': !exists(json, 'customerPortalShowPrice') ? undefined : json['customerPortalShowPrice'],
        'invoiceFooter': !exists(json, 'invoiceFooter') ? undefined : json['invoiceFooter'],
        'shopLogoUrl': !exists(json, 'shopLogoUrl') ? undefined : json['shopLogoUrl'],
        'shopLogoThumbnailUrl': !exists(json, 'shopLogoThumbnailUrl') ? undefined : json['shopLogoThumbnailUrl'],
        'authorizationShowPartNumbers': !exists(json, 'authorizationShowPartNumbers') ? undefined : json['authorizationShowPartNumbers'],
        'invoiceByService': !exists(json, 'invoiceByService') ? undefined : json['invoiceByService'],
        'closeRoWithoutInvoiceNumber': !exists(json, 'closeRoWithoutInvoiceNumber') ? undefined : json['closeRoWithoutInvoiceNumber'],
        'closeRoWithoutReceived': !exists(json, 'closeRoWithoutReceived') ? undefined : json['closeRoWithoutReceived'],
        'closeRoWithoutMileage': !exists(json, 'closeRoWithoutMileage') ? undefined : json['closeRoWithoutMileage'],
        'showEmail': !exists(json, 'showEmail') ? undefined : json['showEmail'],
        'invoiceShowLatestAuthorizationOnly': !exists(json, 'invoiceShowLatestAuthorizationOnly') ? undefined : json['invoiceShowLatestAuthorizationOnly'],
        'googleMapsUrl': json['googleMapsUrl'],
        'yelpUrl': json['yelpUrl'],
        'enableCarfax': !exists(json, 'enableCarfax') ? undefined : json['enableCarfax'],
        'carfaxAuthorized': !exists(json, 'carfaxAuthorized') ? undefined : json['carfaxAuthorized'],
        'deferredServicesInInvoice': !exists(json, 'deferredServicesInInvoice') ? undefined : json['deferredServicesInInvoice'],
        'isOptionalConcern': !exists(json, 'isOptionalConcern') ? undefined : json['isOptionalConcern'],
        'repairLinkCredentialsSet': !exists(json, 'repairLinkCredentialsSet') ? undefined : json['repairLinkCredentialsSet'],
        'codatId': !exists(json, 'codatId') ? undefined : json['codatId'],
        'codatDefaultItemId': !exists(json, 'codatDefaultItemId') ? undefined : json['codatDefaultItemId'],
        'lastCodatModifiedDate': !exists(json, 'lastCodatModifiedDate') ? undefined : (new Date(json['lastCodatModifiedDate'])),
        'intakeDisclaimer': json['intakeDisclaimer'],
        'showKpi': !exists(json, 'showKpi') ? undefined : json['showKpi'],
        'invoiceTextNumber': !exists(json, 'invoiceTextNumber') ? undefined : json['invoiceTextNumber'],
        'invoiceShowVehicleTire': !exists(json, 'invoiceShowVehicleTire') ? undefined : json['invoiceShowVehicleTire'],
        'quickVinAuthorized': !exists(json, 'quickVinAuthorized') ? undefined : json['quickVinAuthorized'],
        'ignoreVerification': !exists(json, 'ignoreVerification') ? undefined : json['ignoreVerification'],
    };
}

export function ShopInfoToJSON(value?: ShopInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'address': value.address,
        'barNumber': value.barNumber,
        'epaNumber': value.epaNumber,
        'invoiceHeader': value.invoiceHeader,
        'legalDisclaimer': value.legalDisclaimer,
        'franchise': value.franchise,
        'partsTechUserId': value.partsTechUserId,
        'invoiceShowPartBrands': value.invoiceShowPartBrands,
        'invoiceShowPartNumbers': value.invoiceShowPartNumbers,
        'invoiceShowHours': value.invoiceShowHours,
        'invoiceShowHourlyRate': value.invoiceShowHourlyRate,
        'invoiceShowTechnician': value.invoiceShowTechnician,
        'calendarStartDay': value.calendarStartDay,
        'calendarEndDay': value.calendarEndDay,
        'calendarStartTime': value.calendarStartTime === undefined ? undefined : (value.calendarStartTime.toISOString()),
        'calendarEndTime': value.calendarEndTime === undefined ? undefined : (value.calendarEndTime.toISOString()),
        'customerPortalShowPrice': value.customerPortalShowPrice,
        'invoiceFooter': value.invoiceFooter,
        'shopLogoUrl': value.shopLogoUrl,
        'shopLogoThumbnailUrl': value.shopLogoThumbnailUrl,
        'authorizationShowPartNumbers': value.authorizationShowPartNumbers,
        'invoiceByService': value.invoiceByService,
        'closeRoWithoutInvoiceNumber': value.closeRoWithoutInvoiceNumber,
        'closeRoWithoutReceived': value.closeRoWithoutReceived,
        'closeRoWithoutMileage': value.closeRoWithoutMileage,
        'showEmail': value.showEmail,
        'invoiceShowLatestAuthorizationOnly': value.invoiceShowLatestAuthorizationOnly,
        'googleMapsUrl': value.googleMapsUrl,
        'yelpUrl': value.yelpUrl,
        'enableCarfax': value.enableCarfax,
        'carfaxAuthorized': value.carfaxAuthorized,
        'deferredServicesInInvoice': value.deferredServicesInInvoice,
        'isOptionalConcern': value.isOptionalConcern,
        'repairLinkCredentialsSet': value.repairLinkCredentialsSet,
        'codatId': value.codatId,
        'codatDefaultItemId': value.codatDefaultItemId,
        'lastCodatModifiedDate': value.lastCodatModifiedDate === undefined ? undefined : (value.lastCodatModifiedDate.toISOString()),
        'intakeDisclaimer': value.intakeDisclaimer,
        'showKpi': value.showKpi,
        'invoiceTextNumber': value.invoiceTextNumber,
        'invoiceShowVehicleTire': value.invoiceShowVehicleTire,
        'quickVinAuthorized': value.quickVinAuthorized,
        'ignoreVerification': value.ignoreVerification,
    };
}

