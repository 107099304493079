/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RepairLinkOrderInfo } from './RepairLinkOrderInfo';
import {
    RepairLinkOrderInfoFromJSON,
    RepairLinkOrderInfoFromJSONTyped,
    RepairLinkOrderInfoToJSON,
} from './RepairLinkOrderInfo';

/**
 * 
 * @export
 * @interface RepairLinkOrderEntry
 */
export interface RepairLinkOrderEntry {
    /**
     * 
     * @type {string}
     * @memberof RepairLinkOrderEntry
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof RepairLinkOrderEntry
     */
    createdAt?: Date;
    /**
     * 
     * @type {RepairLinkOrderInfo}
     * @memberof RepairLinkOrderEntry
     */
    info?: RepairLinkOrderInfo;
}

/**
 * Check if a given object implements the RepairLinkOrderEntry interface.
 */
export function instanceOfRepairLinkOrderEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairLinkOrderEntryFromJSON(json: any): RepairLinkOrderEntry {
    return RepairLinkOrderEntryFromJSONTyped(json, false);
}

export function RepairLinkOrderEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairLinkOrderEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'info': !exists(json, 'info') ? undefined : RepairLinkOrderInfoFromJSON(json['info']),
    };
}

export function RepairLinkOrderEntryToJSON(value?: RepairLinkOrderEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdBy': value.createdBy,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'info': RepairLinkOrderInfoToJSON(value.info),
    };
}

