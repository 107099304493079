/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChatRequestSetting } from './ChatRequestSetting';
import {
    ChatRequestSettingFromJSON,
    ChatRequestSettingFromJSONTyped,
    ChatRequestSettingToJSON,
} from './ChatRequestSetting';
import type { CustomerEntry } from './CustomerEntry';
import {
    CustomerEntryFromJSON,
    CustomerEntryFromJSONTyped,
    CustomerEntryToJSON,
} from './CustomerEntry';
import type { RepairOrderExpanded } from './RepairOrderExpanded';
import {
    RepairOrderExpandedFromJSON,
    RepairOrderExpandedFromJSONTyped,
    RepairOrderExpandedToJSON,
} from './RepairOrderExpanded';
import type { ServiceExpanded } from './ServiceExpanded';
import {
    ServiceExpandedFromJSON,
    ServiceExpandedFromJSONTyped,
    ServiceExpandedToJSON,
} from './ServiceExpanded';
import type { ShopEntry } from './ShopEntry';
import {
    ShopEntryFromJSON,
    ShopEntryFromJSONTyped,
    ShopEntryToJSON,
} from './ShopEntry';

/**
 * 
 * @export
 * @interface RepairOrderCustomerSummaryRequest
 */
export interface RepairOrderCustomerSummaryRequest {
    /**
     * 
     * @type {ChatRequestSetting}
     * @memberof RepairOrderCustomerSummaryRequest
     */
    chatRequestSetting?: ChatRequestSetting;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderCustomerSummaryRequest
     */
    customPrompt?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderCustomerSummaryRequest
     */
    existingText?: string;
    /**
     * 
     * @type {RepairOrderExpanded}
     * @memberof RepairOrderCustomerSummaryRequest
     */
    repairOrderExpanded?: RepairOrderExpanded;
    /**
     * 
     * @type {Array<ServiceExpanded>}
     * @memberof RepairOrderCustomerSummaryRequest
     */
    services?: Array<ServiceExpanded>;
    /**
     * 
     * @type {ShopEntry}
     * @memberof RepairOrderCustomerSummaryRequest
     */
    shopEntry?: ShopEntry;
    /**
     * 
     * @type {CustomerEntry}
     * @memberof RepairOrderCustomerSummaryRequest
     */
    customerEntry?: CustomerEntry;
}

/**
 * Check if a given object implements the RepairOrderCustomerSummaryRequest interface.
 */
export function instanceOfRepairOrderCustomerSummaryRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairOrderCustomerSummaryRequestFromJSON(json: any): RepairOrderCustomerSummaryRequest {
    return RepairOrderCustomerSummaryRequestFromJSONTyped(json, false);
}

export function RepairOrderCustomerSummaryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairOrderCustomerSummaryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chatRequestSetting': !exists(json, 'chatRequestSetting') ? undefined : ChatRequestSettingFromJSON(json['chatRequestSetting']),
        'customPrompt': !exists(json, 'customPrompt') ? undefined : json['customPrompt'],
        'existingText': !exists(json, 'existingText') ? undefined : json['existingText'],
        'repairOrderExpanded': !exists(json, 'repairOrderExpanded') ? undefined : RepairOrderExpandedFromJSON(json['repairOrderExpanded']),
        'services': !exists(json, 'services') ? undefined : ((json['services'] as Array<any>).map(ServiceExpandedFromJSON)),
        'shopEntry': !exists(json, 'shopEntry') ? undefined : ShopEntryFromJSON(json['shopEntry']),
        'customerEntry': !exists(json, 'customerEntry') ? undefined : CustomerEntryFromJSON(json['customerEntry']),
    };
}

export function RepairOrderCustomerSummaryRequestToJSON(value?: RepairOrderCustomerSummaryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chatRequestSetting': ChatRequestSettingToJSON(value.chatRequestSetting),
        'customPrompt': value.customPrompt,
        'existingText': value.existingText,
        'repairOrderExpanded': RepairOrderExpandedToJSON(value.repairOrderExpanded),
        'services': value.services === undefined ? undefined : ((value.services as Array<any>).map(ServiceExpandedToJSON)),
        'shopEntry': ShopEntryToJSON(value.shopEntry),
        'customerEntry': CustomerEntryToJSON(value.customerEntry),
    };
}

