/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Taxonomy } from './Taxonomy';
import {
    TaxonomyFromJSON,
    TaxonomyFromJSONTyped,
    TaxonomyToJSON,
} from './Taxonomy';

/**
 * 
 * @export
 * @interface CannedJobMotorFluidsInfo
 */
export interface CannedJobMotorFluidsInfo {
    /**
     * 
     * @type {string}
     * @memberof CannedJobMotorFluidsInfo
     */
    cannedJob?: string;
    /**
     * 
     * @type {string}
     * @memberof CannedJobMotorFluidsInfo
     */
    name?: string;
    /**
     * 
     * @type {Taxonomy}
     * @memberof CannedJobMotorFluidsInfo
     */
    taxonomy?: Taxonomy;
    /**
     * 
     * @type {string}
     * @memberof CannedJobMotorFluidsInfo
     */
    partsMatrix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CannedJobMotorFluidsInfo
     */
    taxExempt?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CannedJobMotorFluidsInfo
     */
    suggestedPricePerUnit?: number;
    /**
     * 
     * @type {number}
     * @memberof CannedJobMotorFluidsInfo
     */
    suggestedQuantity?: number;
    /**
     * 
     * @type {string}
     * @memberof CannedJobMotorFluidsInfo
     */
    unitOfMeasure?: CannedJobMotorFluidsInfoUnitOfMeasureEnum;
}


/**
 * @export
 */
export const CannedJobMotorFluidsInfoUnitOfMeasureEnum = {
    DiscreteUnit: 'discrete_unit',
    Liter: 'liter',
    Ml: 'ml',
    Gallon: 'gallon',
    Quart: 'quart',
    Ounce: 'ounce',
    FlOz: 'fl_oz'
} as const;
export type CannedJobMotorFluidsInfoUnitOfMeasureEnum = typeof CannedJobMotorFluidsInfoUnitOfMeasureEnum[keyof typeof CannedJobMotorFluidsInfoUnitOfMeasureEnum];


/**
 * Check if a given object implements the CannedJobMotorFluidsInfo interface.
 */
export function instanceOfCannedJobMotorFluidsInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CannedJobMotorFluidsInfoFromJSON(json: any): CannedJobMotorFluidsInfo {
    return CannedJobMotorFluidsInfoFromJSONTyped(json, false);
}

export function CannedJobMotorFluidsInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CannedJobMotorFluidsInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cannedJob': !exists(json, 'cannedJob') ? undefined : json['cannedJob'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'taxonomy': !exists(json, 'taxonomy') ? undefined : TaxonomyFromJSON(json['taxonomy']),
        'partsMatrix': !exists(json, 'partsMatrix') ? undefined : json['partsMatrix'],
        'taxExempt': !exists(json, 'taxExempt') ? undefined : json['taxExempt'],
        'suggestedPricePerUnit': !exists(json, 'suggestedPricePerUnit') ? undefined : json['suggestedPricePerUnit'],
        'suggestedQuantity': !exists(json, 'suggestedQuantity') ? undefined : json['suggestedQuantity'],
        'unitOfMeasure': !exists(json, 'unitOfMeasure') ? undefined : json['unitOfMeasure'],
    };
}

export function CannedJobMotorFluidsInfoToJSON(value?: CannedJobMotorFluidsInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cannedJob': value.cannedJob,
        'name': value.name,
        'taxonomy': TaxonomyToJSON(value.taxonomy),
        'partsMatrix': value.partsMatrix,
        'taxExempt': value.taxExempt,
        'suggestedPricePerUnit': value.suggestedPricePerUnit,
        'suggestedQuantity': value.suggestedQuantity,
        'unitOfMeasure': value.unitOfMeasure,
    };
}

