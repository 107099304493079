/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CannedJobCustomLaborInfo } from './CannedJobCustomLaborInfo';
import {
    CannedJobCustomLaborInfoFromJSON,
    CannedJobCustomLaborInfoFromJSONTyped,
    CannedJobCustomLaborInfoToJSON,
} from './CannedJobCustomLaborInfo';

/**
 * 
 * @export
 * @interface CannedJobCustomLaborEntry
 */
export interface CannedJobCustomLaborEntry {
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomLaborEntry
     */
    id?: string;
    /**
     * 
     * @type {CannedJobCustomLaborInfo}
     * @memberof CannedJobCustomLaborEntry
     */
    info?: CannedJobCustomLaborInfo;
}

/**
 * Check if a given object implements the CannedJobCustomLaborEntry interface.
 */
export function instanceOfCannedJobCustomLaborEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CannedJobCustomLaborEntryFromJSON(json: any): CannedJobCustomLaborEntry {
    return CannedJobCustomLaborEntryFromJSONTyped(json, false);
}

export function CannedJobCustomLaborEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CannedJobCustomLaborEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : CannedJobCustomLaborInfoFromJSON(json['info']),
    };
}

export function CannedJobCustomLaborEntryToJSON(value?: CannedJobCustomLaborEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': CannedJobCustomLaborInfoToJSON(value.info),
    };
}

