import { FIREBASE_PROD_CONFIG, FIREBASE_QA_CONFIG } from './firebase'

type Environment = 'development' | 'QA' | 'production' | 'test'

// https://vitejs.dev/guide/env-and-mode.html
const env = import.meta.env.MODE as Environment

export function getEnv(): Environment {
  return env
}

export function getFirebaseConfig() {
  switch (env) {
    case 'development': {
      return FIREBASE_QA_CONFIG
    }
    case 'QA': {
      return FIREBASE_QA_CONFIG
    }
    case 'production': {
      return FIREBASE_PROD_CONFIG
    }
    case 'test': {
      return FIREBASE_QA_CONFIG
    }
  }
}

export function getFinixConfig() {
  switch (env) {
    case 'development': {
      return {
        finixEnvironment: 'sandbox',
        applicationID: 'APhErBD3BdR5RsHyvjZYkoNm'
      }
    }
    case 'QA': {
      return {
        finixEnvironment: 'sandbox',
        applicationID: 'APhErBD3BdR5RsHyvjZYkoNm'
      }
    }
    case 'production': {
      return {
        finixEnvironment: 'live',
        applicationID: 'APbumtcyqb3gHxCM35wZQdvW'
      }
    }
    case 'test': {
      return {
        finixEnvironment: 'sandbox',
        applicationID: 'APhErBD3BdR5RsHyvjZYkoNm'
      }
    }
  }
}

export function getRepairLinkConfig() {
  switch (env) {
    case 'development': {
      return {
        clientId: '0GEBdeNAIyeE6Jq4KwYExEFuGhf3sR8i',
        apiUrl: 'https://api-qa.oeconnection.com/oauth2',
        mainUrl: 'https://oecqa.repairlinkshop.com'
      }
    }
    case 'QA': {
      return {
        clientId: '0GEBdeNAIyeE6Jq4KwYExEFuGhf3sR8i',
        apiUrl: 'https://api-qa.oeconnection.com/oauth2',
        mainUrl: 'https://oecqa.repairlinkshop.com'
      }
    }
    case 'production': {
      return {
        clientId: '0GEBdeNAIyeE6Jq4KwYExEFuGhf3sR8i',
        apiUrl: 'https://api.oeconnection.com/oauth2',
        mainUrl: 'https://repairlinkshop.com'
      }
    }
    case 'test': {
      return {
        clientId: '0GEBdeNAIyeE6Jq4KwYExEFuGhf3sR8i',
        apiUrl: 'https://api-qa.oeconnection.com/oauth2',
        mainUrl: 'https://oecqa.repairlinkshop.com'
      }
    }
  }
}

// If true, any errors in the react-app will trigger sendBugReport API calls
// We want these reports to happen in QA, so that we can catch issues prior
// to prod deployment. All code in QA is also intended to be vetted, so there
// should not be too many alerts. However, dev is expected to be very buggy,
// so we should avoid false alarms
export function shouldAlertOnErrors(): boolean {
  switch (env) {
    case 'development': {
      return false
    }
    case 'QA': {
      return true
    }
    case 'production': {
      return true
    }
    case 'test': {
      return false
    }
  }
}

export function getInTuneApiUrl() {
  const qaApi = 'https://intune-api-qa.herokuapp.com/rest'
  const prodApi = 'https://intune-api.herokuapp.com/rest'

  switch (env) {
    case 'development':
      return qaApi
    case 'QA':
      return qaApi
    case 'production':
      return prodApi
    case 'test':
      return qaApi
  }
}

export function getReplicacheKey() {
  return 'l69b13e40eae14bcf97e46a62a34a82e5'
}
