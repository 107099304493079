/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CodatInvoiceCreationRequest
 */
export interface CodatInvoiceCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof CodatInvoiceCreationRequest
     */
    repairOrderId?: string;
    /**
     * 
     * @type {number}
     * @memberof CodatInvoiceCreationRequest
     */
    localId?: number;
    /**
     * 
     * @type {string}
     * @memberof CodatInvoiceCreationRequest
     */
    codatId?: string;
    /**
     * 
     * @type {string}
     * @memberof CodatInvoiceCreationRequest
     */
    codatPushKey?: string;
}

/**
 * Check if a given object implements the CodatInvoiceCreationRequest interface.
 */
export function instanceOfCodatInvoiceCreationRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CodatInvoiceCreationRequestFromJSON(json: any): CodatInvoiceCreationRequest {
    return CodatInvoiceCreationRequestFromJSONTyped(json, false);
}

export function CodatInvoiceCreationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CodatInvoiceCreationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'repairOrderId': !exists(json, 'repairOrderId') ? undefined : json['repairOrderId'],
        'localId': !exists(json, 'localId') ? undefined : json['localId'],
        'codatId': !exists(json, 'codatId') ? undefined : json['codatId'],
        'codatPushKey': !exists(json, 'codatPushKey') ? undefined : json['codatPushKey'],
    };
}

export function CodatInvoiceCreationRequestToJSON(value?: CodatInvoiceCreationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'repairOrderId': value.repairOrderId,
        'localId': value.localId,
        'codatId': value.codatId,
        'codatPushKey': value.codatPushKey,
    };
}

