/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkTime
 */
export interface WorkTime {
    /**
     * 
     * @type {number}
     * @memberof WorkTime
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkTime
     */
    literalName?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkTime
     */
    sumBaseLaborTime?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkTime
     */
    baseLaborTimes?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkTime
     */
    baseLaborTimeDesc?: Array<string>;
}

/**
 * Check if a given object implements the WorkTime interface.
 */
export function instanceOfWorkTime(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WorkTimeFromJSON(json: any): WorkTime {
    return WorkTimeFromJSONTyped(json, false);
}

export function WorkTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'literalName': !exists(json, 'literalName') ? undefined : json['literalName'],
        'sumBaseLaborTime': !exists(json, 'sumBaseLaborTime') ? undefined : json['sumBaseLaborTime'],
        'baseLaborTimes': !exists(json, 'baseLaborTimes') ? undefined : json['baseLaborTimes'],
        'baseLaborTimeDesc': !exists(json, 'baseLaborTimeDesc') ? undefined : json['baseLaborTimeDesc'],
    };
}

export function WorkTimeToJSON(value?: WorkTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'literalName': value.literalName,
        'sumBaseLaborTime': value.sumBaseLaborTime,
        'baseLaborTimes': value.baseLaborTimes,
        'baseLaborTimeDesc': value.baseLaborTimeDesc,
    };
}

