/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderEntry
 */
export interface OrderEntry {
    /**
     * 
     * @type {number}
     * @memberof OrderEntry
     */
    orderNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderEntry
     */
    customer?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderEntry
     */
    vehicle?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderEntry
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderEntry
     */
    status?: OrderEntryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderEntry
     */
    description?: string;
}


/**
 * @export
 */
export const OrderEntryStatusEnum = {
    InProgress: 'IN_PROGRESS',
    Complete: 'COMPLETE'
} as const;
export type OrderEntryStatusEnum = typeof OrderEntryStatusEnum[keyof typeof OrderEntryStatusEnum];


/**
 * Check if a given object implements the OrderEntry interface.
 */
export function instanceOfOrderEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrderEntryFromJSON(json: any): OrderEntry {
    return OrderEntryFromJSONTyped(json, false);
}

export function OrderEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'vehicle': !exists(json, 'vehicle') ? undefined : json['vehicle'],
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function OrderEntryToJSON(value?: OrderEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderNumber': value.orderNumber,
        'customer': value.customer,
        'vehicle': value.vehicle,
        'date': value.date === undefined ? undefined : (value.date.toISOString().substr(0,10)),
        'status': value.status,
        'description': value.description,
    };
}

