/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ServiceInfo } from './ServiceInfo';
import {
    ServiceInfoFromJSON,
    ServiceInfoFromJSONTyped,
    ServiceInfoToJSON,
} from './ServiceInfo';

/**
 * 
 * @export
 * @interface ServiceEntry
 */
export interface ServiceEntry {
    /**
     * 
     * @type {string}
     * @memberof ServiceEntry
     */
    id?: string;
    /**
     * 
     * @type {ServiceInfo}
     * @memberof ServiceEntry
     */
    info?: ServiceInfo;
}

/**
 * Check if a given object implements the ServiceEntry interface.
 */
export function instanceOfServiceEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceEntryFromJSON(json: any): ServiceEntry {
    return ServiceEntryFromJSONTyped(json, false);
}

export function ServiceEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : ServiceInfoFromJSON(json['info']),
    };
}

export function ServiceEntryToJSON(value?: ServiceEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': ServiceInfoToJSON(value.info),
    };
}

