/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailInspectionRequest
 */
export interface EmailInspectionRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailInspectionRequest
     */
    inspectionId?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailInspectionRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailInspectionRequest
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailInspectionRequest
     */
    toEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailInspectionRequest
     */
    toName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailInspectionRequest
     */
    showPrice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailInspectionRequest
     */
    showPartsLabor?: boolean;
}

/**
 * Check if a given object implements the EmailInspectionRequest interface.
 */
export function instanceOfEmailInspectionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmailInspectionRequestFromJSON(json: any): EmailInspectionRequest {
    return EmailInspectionRequestFromJSONTyped(json, false);
}

export function EmailInspectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailInspectionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspectionId': !exists(json, 'inspectionId') ? undefined : json['inspectionId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'toEmail': !exists(json, 'toEmail') ? undefined : json['toEmail'],
        'toName': !exists(json, 'toName') ? undefined : json['toName'],
        'showPrice': !exists(json, 'showPrice') ? undefined : json['showPrice'],
        'showPartsLabor': !exists(json, 'showPartsLabor') ? undefined : json['showPartsLabor'],
    };
}

export function EmailInspectionRequestToJSON(value?: EmailInspectionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspectionId': value.inspectionId,
        'title': value.title,
        'body': value.body,
        'toEmail': value.toEmail,
        'toName': value.toName,
        'showPrice': value.showPrice,
        'showPartsLabor': value.showPartsLabor,
    };
}

