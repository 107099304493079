/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchOperation
 */
export interface PatchOperation {
    /**
     * 
     * @type {string}
     * @memberof PatchOperation
     */
    op?: PatchOperationOpEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchOperation
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchOperation
     */
    value?: string;
}


/**
 * @export
 */
export const PatchOperationOpEnum = {
    Put: 'put',
    Del: 'del',
    Clear: 'clear'
} as const;
export type PatchOperationOpEnum = typeof PatchOperationOpEnum[keyof typeof PatchOperationOpEnum];


/**
 * Check if a given object implements the PatchOperation interface.
 */
export function instanceOfPatchOperation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatchOperationFromJSON(json: any): PatchOperation {
    return PatchOperationFromJSONTyped(json, false);
}

export function PatchOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchOperation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'op': !exists(json, 'op') ? undefined : json['op'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function PatchOperationToJSON(value?: PatchOperation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'op': value.op,
        'key': value.key,
        'value': value.value,
    };
}

