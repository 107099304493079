/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReplicacheCookie
 */
export interface ReplicacheCookie {
    /**
     * 
     * @type {string}
     * @memberof ReplicacheCookie
     */
    clientGroupID?: string;
    /**
     * 
     * @type {number}
     * @memberof ReplicacheCookie
     */
    order?: number;
}

/**
 * Check if a given object implements the ReplicacheCookie interface.
 */
export function instanceOfReplicacheCookie(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReplicacheCookieFromJSON(json: any): ReplicacheCookie {
    return ReplicacheCookieFromJSONTyped(json, false);
}

export function ReplicacheCookieFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReplicacheCookie {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientGroupID': !exists(json, 'clientGroupID') ? undefined : json['clientGroupID'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function ReplicacheCookieToJSON(value?: ReplicacheCookie | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientGroupID': value.clientGroupID,
        'order': value.order,
    };
}

