/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LinkInventoryAdjustmentRequest
 */
export interface LinkInventoryAdjustmentRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof LinkInventoryAdjustmentRequest
     */
    instances?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LinkInventoryAdjustmentRequest
     */
    adjustment?: string;
}

/**
 * Check if a given object implements the LinkInventoryAdjustmentRequest interface.
 */
export function instanceOfLinkInventoryAdjustmentRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LinkInventoryAdjustmentRequestFromJSON(json: any): LinkInventoryAdjustmentRequest {
    return LinkInventoryAdjustmentRequestFromJSONTyped(json, false);
}

export function LinkInventoryAdjustmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkInventoryAdjustmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'instances': !exists(json, 'instances') ? undefined : json['instances'],
        'adjustment': !exists(json, 'adjustment') ? undefined : json['adjustment'],
    };
}

export function LinkInventoryAdjustmentRequestToJSON(value?: LinkInventoryAdjustmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'instances': value.instances,
        'adjustment': value.adjustment,
    };
}

