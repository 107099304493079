/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LaborOverride
 */
export interface LaborOverride {
    /**
     * 
     * @type {string}
     * @memberof LaborOverride
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LaborOverride
     */
    parent?: string;
    /**
     * 
     * @type {string}
     * @memberof LaborOverride
     */
    service?: string;
    /**
     * 
     * @type {number}
     * @memberof LaborOverride
     */
    motorApplicationId?: number;
    /**
     * 
     * @type {number}
     * @memberof LaborOverride
     */
    motorMaintenanceScheduleId?: number;
    /**
     * 
     * @type {string}
     * @memberof LaborOverride
     */
    overrideName?: string;
    /**
     * 
     * @type {number}
     * @memberof LaborOverride
     */
    numHours?: number;
    /**
     * 
     * @type {number}
     * @memberof LaborOverride
     */
    hourlyRate?: number;
    /**
     * 
     * @type {string}
     * @memberof LaborOverride
     */
    markup?: string;
    /**
     * 
     * @type {string}
     * @memberof LaborOverride
     */
    notes?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LaborOverride
     */
    taxExempt?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LaborOverride
     */
    laborMatrixCatalog?: string;
    /**
     * 
     * @type {number}
     * @memberof LaborOverride
     */
    snapshotMotorNumHours?: number;
    /**
     * 
     * @type {string}
     * @memberof LaborOverride
     */
    placeholderId?: string;
    /**
     * 
     * @type {string}
     * @memberof LaborOverride
     */
    placeholderType?: LaborOverridePlaceholderTypeEnum;
}


/**
 * @export
 */
export const LaborOverridePlaceholderTypeEnum = {
    MotorLabor: 'motor_labor'
} as const;
export type LaborOverridePlaceholderTypeEnum = typeof LaborOverridePlaceholderTypeEnum[keyof typeof LaborOverridePlaceholderTypeEnum];


/**
 * Check if a given object implements the LaborOverride interface.
 */
export function instanceOfLaborOverride(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LaborOverrideFromJSON(json: any): LaborOverride {
    return LaborOverrideFromJSONTyped(json, false);
}

export function LaborOverrideFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaborOverride {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'parent': !exists(json, 'parent') ? undefined : json['parent'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'motorApplicationId': !exists(json, 'motorApplicationId') ? undefined : json['motorApplicationId'],
        'motorMaintenanceScheduleId': !exists(json, 'motorMaintenanceScheduleId') ? undefined : json['motorMaintenanceScheduleId'],
        'overrideName': !exists(json, 'overrideName') ? undefined : json['overrideName'],
        'numHours': !exists(json, 'numHours') ? undefined : json['numHours'],
        'hourlyRate': !exists(json, 'hourlyRate') ? undefined : json['hourlyRate'],
        'markup': !exists(json, 'markup') ? undefined : json['markup'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'taxExempt': !exists(json, 'taxExempt') ? undefined : json['taxExempt'],
        'laborMatrixCatalog': !exists(json, 'laborMatrixCatalog') ? undefined : json['laborMatrixCatalog'],
        'snapshotMotorNumHours': !exists(json, 'snapshotMotorNumHours') ? undefined : json['snapshotMotorNumHours'],
        'placeholderId': !exists(json, 'placeholderId') ? undefined : json['placeholderId'],
        'placeholderType': !exists(json, 'placeholderType') ? undefined : json['placeholderType'],
    };
}

export function LaborOverrideToJSON(value?: LaborOverride | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parent': value.parent,
        'service': value.service,
        'motorApplicationId': value.motorApplicationId,
        'motorMaintenanceScheduleId': value.motorMaintenanceScheduleId,
        'overrideName': value.overrideName,
        'numHours': value.numHours,
        'hourlyRate': value.hourlyRate,
        'markup': value.markup,
        'notes': value.notes,
        'taxExempt': value.taxExempt,
        'laborMatrixCatalog': value.laborMatrixCatalog,
        'snapshotMotorNumHours': value.snapshotMotorNumHours,
        'placeholderId': value.placeholderId,
        'placeholderType': value.placeholderType,
    };
}

