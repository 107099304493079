/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplierOrder } from './SupplierOrder';
import {
    SupplierOrderFromJSON,
    SupplierOrderFromJSONTyped,
    SupplierOrderToJSON,
} from './SupplierOrder';

/**
 * 
 * @export
 * @interface CartInfo
 */
export interface CartInfo {
    /**
     * 
     * @type {string}
     * @memberof CartInfo
     */
    sessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof CartInfo
     */
    redirectURL?: string;
    /**
     * 
     * @type {Array<SupplierOrder>}
     * @memberof CartInfo
     */
    orders?: Array<SupplierOrder>;
}

/**
 * Check if a given object implements the CartInfo interface.
 */
export function instanceOfCartInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CartInfoFromJSON(json: any): CartInfo {
    return CartInfoFromJSONTyped(json, false);
}

export function CartInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'redirectURL': !exists(json, 'redirectURL') ? undefined : json['redirectURL'],
        'orders': !exists(json, 'orders') ? undefined : ((json['orders'] as Array<any>).map(SupplierOrderFromJSON)),
    };
}

export function CartInfoToJSON(value?: CartInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
        'redirectURL': value.redirectURL,
        'orders': value.orders === undefined ? undefined : ((value.orders as Array<any>).map(SupplierOrderToJSON)),
    };
}

