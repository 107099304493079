/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EntryInfo
 */
export interface EntryInfo {
    /**
     * 
     * @type {string}
     * @memberof EntryInfo
     */
    section?: string;
    /**
     * 
     * @type {number}
     * @memberof EntryInfo
     */
    rank?: number;
    /**
     * 
     * @type {string}
     * @memberof EntryInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EntryInfo
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof EntryInfo
     */
    service?: string;
    /**
     * 
     * @type {string}
     * @memberof EntryInfo
     */
    status?: EntryInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EntryInfo
     */
    customStatus?: string;
    /**
     * 
     * @type {Date}
     * @memberof EntryInfo
     */
    archived?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EntryInfo
     */
    complete?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EntryInfo
     */
    addressed?: Date;
    /**
     * 
     * @type {string}
     * @memberof EntryInfo
     */
    customerNotes?: string;
}


/**
 * @export
 */
export const EntryInfoStatusEnum = {
    Passed: 'Passed',
    Attention: 'Attention',
    Urgent: 'Urgent',
    NotInspected: 'Not_Inspected'
} as const;
export type EntryInfoStatusEnum = typeof EntryInfoStatusEnum[keyof typeof EntryInfoStatusEnum];


/**
 * Check if a given object implements the EntryInfo interface.
 */
export function instanceOfEntryInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EntryInfoFromJSON(json: any): EntryInfo {
    return EntryInfoFromJSONTyped(json, false);
}

export function EntryInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntryInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'section': !exists(json, 'section') ? undefined : json['section'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'customStatus': !exists(json, 'customStatus') ? undefined : json['customStatus'],
        'archived': !exists(json, 'archived') ? undefined : (new Date(json['archived'])),
        'complete': !exists(json, 'complete') ? undefined : (new Date(json['complete'])),
        'addressed': !exists(json, 'addressed') ? undefined : (new Date(json['addressed'])),
        'customerNotes': !exists(json, 'customerNotes') ? undefined : json['customerNotes'],
    };
}

export function EntryInfoToJSON(value?: EntryInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'section': value.section,
        'rank': value.rank,
        'title': value.title,
        'description': value.description,
        'service': value.service,
        'status': value.status,
        'customStatus': value.customStatus,
        'archived': value.archived === undefined ? undefined : (value.archived.toISOString()),
        'complete': value.complete === undefined ? undefined : (value.complete.toISOString()),
        'addressed': value.addressed === undefined ? undefined : (value.addressed.toISOString()),
        'customerNotes': value.customerNotes,
    };
}

