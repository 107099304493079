/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentInfo
 */
export interface AppointmentInfo {
    /**
     * 
     * @type {string}
     * @memberof AppointmentInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentInfo
     */
    customer?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentInfo
     */
    vehicle?: string;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentInfo
     */
    startDateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof AppointmentInfo
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentInfo
     */
    concern?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentInfo
     */
    waiting?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentInfo
     */
    vip?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppointmentInfo
     */
    resources?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AppointmentInfo
     */
    estimatedWorkHours?: number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentInfo
     */
    color?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentInfo
     */
    archived?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentInfo
     */
    isConfirmed?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppointmentInfo
     */
    cannedJobs?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppointmentInfo
     */
    services?: Array<string>;
}

/**
 * Check if a given object implements the AppointmentInfo interface.
 */
export function instanceOfAppointmentInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AppointmentInfoFromJSON(json: any): AppointmentInfo {
    return AppointmentInfoFromJSONTyped(json, false);
}

export function AppointmentInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'vehicle': !exists(json, 'vehicle') ? undefined : json['vehicle'],
        'startDateTime': !exists(json, 'startDateTime') ? undefined : (new Date(json['startDateTime'])),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'concern': !exists(json, 'concern') ? undefined : json['concern'],
        'waiting': !exists(json, 'waiting') ? undefined : json['waiting'],
        'vip': !exists(json, 'vip') ? undefined : json['vip'],
        'resources': !exists(json, 'resources') ? undefined : json['resources'],
        'estimatedWorkHours': !exists(json, 'estimatedWorkHours') ? undefined : json['estimatedWorkHours'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'isConfirmed': !exists(json, 'isConfirmed') ? undefined : json['isConfirmed'],
        'cannedJobs': !exists(json, 'cannedJobs') ? undefined : json['cannedJobs'],
        'services': !exists(json, 'services') ? undefined : json['services'],
    };
}

export function AppointmentInfoToJSON(value?: AppointmentInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'customer': value.customer,
        'vehicle': value.vehicle,
        'startDateTime': value.startDateTime === undefined ? undefined : (value.startDateTime.toISOString()),
        'notes': value.notes,
        'concern': value.concern,
        'waiting': value.waiting,
        'vip': value.vip,
        'resources': value.resources,
        'estimatedWorkHours': value.estimatedWorkHours,
        'color': value.color,
        'archived': value.archived,
        'isConfirmed': value.isConfirmed,
        'cannedJobs': value.cannedJobs,
        'services': value.services,
    };
}

