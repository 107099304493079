/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublicCustomerCreationRequest
 */
export interface PublicCustomerCreationRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerCreationRequest
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerCreationRequest
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerCreationRequest
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerCreationRequest
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerCreationRequest
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerCreationRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerCreationRequest
     */
    streetAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerCreationRequest
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerCreationRequest
     */
    state?: PublicCustomerCreationRequestStateEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerCreationRequest
     */
    zipcode?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerCreationRequest
     */
    country?: PublicCustomerCreationRequestCountryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PublicCustomerCreationRequest
     */
    taxEmempt?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicCustomerCreationRequest
     */
    marketingOptOut?: boolean;
}


/**
 * @export
 */
export const PublicCustomerCreationRequestStateEnum = {
    Al: 'AL',
    Ak: 'AK',
    Az: 'AZ',
    Ar: 'AR',
    Ca: 'CA',
    Co: 'CO',
    Ct: 'CT',
    De: 'DE',
    Fl: 'FL',
    Ga: 'GA',
    Hi: 'HI',
    Id: 'ID',
    Il: 'IL',
    In: 'IN',
    Ia: 'IA',
    Ks: 'KS',
    Ky: 'KY',
    La: 'LA',
    Me: 'ME',
    Md: 'MD',
    Ma: 'MA',
    Mi: 'MI',
    Mn: 'MN',
    Ms: 'MS',
    Mo: 'MO',
    Mt: 'MT',
    Ne: 'NE',
    Nv: 'NV',
    Nh: 'NH',
    Nj: 'NJ',
    Nm: 'NM',
    Ny: 'NY',
    Nc: 'NC',
    Nd: 'ND',
    Oh: 'OH',
    Ok: 'OK',
    Or: 'OR',
    Pa: 'PA',
    Ri: 'RI',
    Sc: 'SC',
    Sd: 'SD',
    Tn: 'TN',
    Tx: 'TX',
    Ut: 'UT',
    Vt: 'VT',
    Va: 'VA',
    Wa: 'WA',
    Wv: 'WV',
    Wi: 'WI',
    Wy: 'WY',
    Dc: 'DC'
} as const;
export type PublicCustomerCreationRequestStateEnum = typeof PublicCustomerCreationRequestStateEnum[keyof typeof PublicCustomerCreationRequestStateEnum];

/**
 * @export
 */
export const PublicCustomerCreationRequestCountryEnum = {
    Usa: 'USA',
    Canada: 'Canada'
} as const;
export type PublicCustomerCreationRequestCountryEnum = typeof PublicCustomerCreationRequestCountryEnum[keyof typeof PublicCustomerCreationRequestCountryEnum];


/**
 * Check if a given object implements the PublicCustomerCreationRequest interface.
 */
export function instanceOfPublicCustomerCreationRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PublicCustomerCreationRequestFromJSON(json: any): PublicCustomerCreationRequest {
    return PublicCustomerCreationRequestFromJSONTyped(json, false);
}

export function PublicCustomerCreationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicCustomerCreationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'streetAddress': !exists(json, 'streetAddress') ? undefined : json['streetAddress'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zipcode': !exists(json, 'zipcode') ? undefined : json['zipcode'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'taxEmempt': !exists(json, 'taxEmempt') ? undefined : json['taxEmempt'],
        'marketingOptOut': !exists(json, 'marketingOptOut') ? undefined : json['marketingOptOut'],
    };
}

export function PublicCustomerCreationRequestToJSON(value?: PublicCustomerCreationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'firstname': value.firstname,
        'lastname': value.lastname,
        'companyName': value.companyName,
        'phone': value.phone,
        'email': value.email,
        'streetAddress': value.streetAddress,
        'city': value.city,
        'state': value.state,
        'zipcode': value.zipcode,
        'country': value.country,
        'taxEmempt': value.taxEmempt,
        'marketingOptOut': value.marketingOptOut,
    };
}

