/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PartStatusInfo
 */
export interface PartStatusInfo {
    /**
     * 
     * @type {string}
     * @memberof PartStatusInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof PartStatusInfo
     */
    part?: string;
    /**
     * 
     * @type {string}
     * @memberof PartStatusInfo
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof PartStatusInfo
     */
    timestamp?: Date;
}

/**
 * Check if a given object implements the PartStatusInfo interface.
 */
export function instanceOfPartStatusInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PartStatusInfoFromJSON(json: any): PartStatusInfo {
    return PartStatusInfoFromJSONTyped(json, false);
}

export function PartStatusInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartStatusInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'part': !exists(json, 'part') ? undefined : json['part'],
        'invoiceNumber': !exists(json, 'invoiceNumber') ? undefined : json['invoiceNumber'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
    };
}

export function PartStatusInfoToJSON(value?: PartStatusInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'part': value.part,
        'invoiceNumber': value.invoiceNumber,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
    };
}

