/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Mutation
 */
export interface Mutation {
    /**
     * 
     * @type {string}
     * @memberof Mutation
     */
    clientID?: string;
    /**
     * 
     * @type {number}
     * @memberof Mutation
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Mutation
     */
    name?: string;
    /**
     * 
     * @type {object}
     * @memberof Mutation
     */
    args?: object;
    /**
     * 
     * @type {number}
     * @memberof Mutation
     */
    timestamp?: number;
}

/**
 * Check if a given object implements the Mutation interface.
 */
export function instanceOfMutation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MutationFromJSON(json: any): Mutation {
    return MutationFromJSONTyped(json, false);
}

export function MutationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Mutation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientID': !exists(json, 'clientID') ? undefined : json['clientID'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'args': !exists(json, 'args') ? undefined : json['args'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
    };
}

export function MutationToJSON(value?: Mutation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientID': value.clientID,
        'id': value.id,
        'name': value.name,
        'args': value.args,
        'timestamp': value.timestamp,
    };
}

