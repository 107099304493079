/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePartsMatrixRanksRequest
 */
export interface UpdatePartsMatrixRanksRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePartsMatrixRanksRequest
     */
    shop?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePartsMatrixRanksRequest
     */
    partsMatrices?: Array<string>;
}

/**
 * Check if a given object implements the UpdatePartsMatrixRanksRequest interface.
 */
export function instanceOfUpdatePartsMatrixRanksRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdatePartsMatrixRanksRequestFromJSON(json: any): UpdatePartsMatrixRanksRequest {
    return UpdatePartsMatrixRanksRequestFromJSONTyped(json, false);
}

export function UpdatePartsMatrixRanksRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePartsMatrixRanksRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'partsMatrices': !exists(json, 'partsMatrices') ? undefined : json['partsMatrices'],
    };
}

export function UpdatePartsMatrixRanksRequestToJSON(value?: UpdatePartsMatrixRanksRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'partsMatrices': value.partsMatrices,
    };
}

