/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChatRequestSetting } from './ChatRequestSetting';
import {
    ChatRequestSettingFromJSON,
    ChatRequestSettingFromJSONTyped,
    ChatRequestSettingToJSON,
} from './ChatRequestSetting';
import type { MaintenanceSchedule } from './MaintenanceSchedule';
import {
    MaintenanceScheduleFromJSON,
    MaintenanceScheduleFromJSONTyped,
    MaintenanceScheduleToJSON,
} from './MaintenanceSchedule';
import type { ServiceHistory } from './ServiceHistory';
import {
    ServiceHistoryFromJSON,
    ServiceHistoryFromJSONTyped,
    ServiceHistoryToJSON,
} from './ServiceHistory';

/**
 * 
 * @export
 * @interface RecommendMaintenanceScheduleRequest
 */
export interface RecommendMaintenanceScheduleRequest {
    /**
     * 
     * @type {ChatRequestSetting}
     * @memberof RecommendMaintenanceScheduleRequest
     */
    chatRequestSetting?: ChatRequestSetting;
    /**
     * 
     * @type {string}
     * @memberof RecommendMaintenanceScheduleRequest
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof RecommendMaintenanceScheduleRequest
     */
    customPrompt?: string;
    /**
     * 
     * @type {number}
     * @memberof RecommendMaintenanceScheduleRequest
     */
    mileage?: number;
    /**
     * 
     * @type {Array<MaintenanceSchedule>}
     * @memberof RecommendMaintenanceScheduleRequest
     */
    maintenanceSchedule?: Array<MaintenanceSchedule>;
    /**
     * 
     * @type {ServiceHistory}
     * @memberof RecommendMaintenanceScheduleRequest
     */
    serviceHistory?: ServiceHistory;
}

/**
 * Check if a given object implements the RecommendMaintenanceScheduleRequest interface.
 */
export function instanceOfRecommendMaintenanceScheduleRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RecommendMaintenanceScheduleRequestFromJSON(json: any): RecommendMaintenanceScheduleRequest {
    return RecommendMaintenanceScheduleRequestFromJSONTyped(json, false);
}

export function RecommendMaintenanceScheduleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecommendMaintenanceScheduleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chatRequestSetting': !exists(json, 'chatRequestSetting') ? undefined : ChatRequestSettingFromJSON(json['chatRequestSetting']),
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'customPrompt': !exists(json, 'customPrompt') ? undefined : json['customPrompt'],
        'mileage': !exists(json, 'mileage') ? undefined : json['mileage'],
        'maintenanceSchedule': !exists(json, 'maintenanceSchedule') ? undefined : ((json['maintenanceSchedule'] as Array<any>).map(MaintenanceScheduleFromJSON)),
        'serviceHistory': !exists(json, 'serviceHistory') ? undefined : ServiceHistoryFromJSON(json['serviceHistory']),
    };
}

export function RecommendMaintenanceScheduleRequestToJSON(value?: RecommendMaintenanceScheduleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chatRequestSetting': ChatRequestSettingToJSON(value.chatRequestSetting),
        'shop': value.shop,
        'customPrompt': value.customPrompt,
        'mileage': value.mileage,
        'maintenanceSchedule': value.maintenanceSchedule === undefined ? undefined : ((value.maintenanceSchedule as Array<any>).map(MaintenanceScheduleToJSON)),
        'serviceHistory': ServiceHistoryToJSON(value.serviceHistory),
    };
}

