/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VehicleOilFilterInfo } from './VehicleOilFilterInfo';
import {
    VehicleOilFilterInfoFromJSON,
    VehicleOilFilterInfoFromJSONTyped,
    VehicleOilFilterInfoToJSON,
} from './VehicleOilFilterInfo';

/**
 * 
 * @export
 * @interface VehicleOilFilterEntry
 */
export interface VehicleOilFilterEntry {
    /**
     * 
     * @type {string}
     * @memberof VehicleOilFilterEntry
     */
    id?: string;
    /**
     * 
     * @type {VehicleOilFilterInfo}
     * @memberof VehicleOilFilterEntry
     */
    info?: VehicleOilFilterInfo;
    /**
     * 
     * @type {Date}
     * @memberof VehicleOilFilterEntry
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the VehicleOilFilterEntry interface.
 */
export function instanceOfVehicleOilFilterEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleOilFilterEntryFromJSON(json: any): VehicleOilFilterEntry {
    return VehicleOilFilterEntryFromJSONTyped(json, false);
}

export function VehicleOilFilterEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleOilFilterEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : VehicleOilFilterInfoFromJSON(json['info']),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}

export function VehicleOilFilterEntryToJSON(value?: VehicleOilFilterEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': VehicleOilFilterInfoToJSON(value.info),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}

