/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PatchOperation } from './PatchOperation';
import {
    PatchOperationFromJSON,
    PatchOperationFromJSONTyped,
    PatchOperationToJSON,
} from './PatchOperation';
import type { ReplicacheCookie } from './ReplicacheCookie';
import {
    ReplicacheCookieFromJSON,
    ReplicacheCookieFromJSONTyped,
    ReplicacheCookieToJSON,
} from './ReplicacheCookie';

/**
 * 
 * @export
 * @interface CachePullResponseOK
 */
export interface CachePullResponseOK {
    /**
     * 
     * @type {ReplicacheCookie}
     * @memberof CachePullResponseOK
     */
    cookie?: ReplicacheCookie;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof CachePullResponseOK
     */
    lastMutationIDChanges?: { [key: string]: number; };
    /**
     * 
     * @type {Array<PatchOperation>}
     * @memberof CachePullResponseOK
     */
    patch?: Array<PatchOperation>;
}

/**
 * Check if a given object implements the CachePullResponseOK interface.
 */
export function instanceOfCachePullResponseOK(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CachePullResponseOKFromJSON(json: any): CachePullResponseOK {
    return CachePullResponseOKFromJSONTyped(json, false);
}

export function CachePullResponseOKFromJSONTyped(json: any, ignoreDiscriminator: boolean): CachePullResponseOK {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cookie': !exists(json, 'cookie') ? undefined : ReplicacheCookieFromJSON(json['cookie']),
        'lastMutationIDChanges': !exists(json, 'lastMutationIDChanges') ? undefined : json['lastMutationIDChanges'],
        'patch': !exists(json, 'patch') ? undefined : ((json['patch'] as Array<any>).map(PatchOperationFromJSON)),
    };
}

export function CachePullResponseOKToJSON(value?: CachePullResponseOK | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cookie': ReplicacheCookieToJSON(value.cookie),
        'lastMutationIDChanges': value.lastMutationIDChanges,
        'patch': value.patch === undefined ? undefined : ((value.patch as Array<any>).map(PatchOperationToJSON)),
    };
}

