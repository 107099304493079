/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InspectionEntryServiceEntry
 */
export interface InspectionEntryServiceEntry {
    /**
     * 
     * @type {string}
     * @memberof InspectionEntryServiceEntry
     */
    inspectionEntryId?: string;
    /**
     * 
     * @type {string}
     * @memberof InspectionEntryServiceEntry
     */
    serviceId?: string;
}

/**
 * Check if a given object implements the InspectionEntryServiceEntry interface.
 */
export function instanceOfInspectionEntryServiceEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InspectionEntryServiceEntryFromJSON(json: any): InspectionEntryServiceEntry {
    return InspectionEntryServiceEntryFromJSONTyped(json, false);
}

export function InspectionEntryServiceEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionEntryServiceEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspectionEntryId': !exists(json, 'inspectionEntryId') ? undefined : json['inspectionEntryId'],
        'serviceId': !exists(json, 'serviceId') ? undefined : json['serviceId'],
    };
}

export function InspectionEntryServiceEntryToJSON(value?: InspectionEntryServiceEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspectionEntryId': value.inspectionEntryId,
        'serviceId': value.serviceId,
    };
}

