/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AppointmentInfo } from './AppointmentInfo';
import {
    AppointmentInfoFromJSON,
    AppointmentInfoFromJSONTyped,
    AppointmentInfoToJSON,
} from './AppointmentInfo';

/**
 * 
 * @export
 * @interface CreateAppointmentRequest
 */
export interface CreateAppointmentRequest {
    /**
     * 
     * @type {AppointmentInfo}
     * @memberof CreateAppointmentRequest
     */
    info?: AppointmentInfo;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAppointmentRequest
     */
    sendEmailConfirmation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAppointmentRequest
     */
    sendTextConfirmation?: boolean;
}

/**
 * Check if a given object implements the CreateAppointmentRequest interface.
 */
export function instanceOfCreateAppointmentRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateAppointmentRequestFromJSON(json: any): CreateAppointmentRequest {
    return CreateAppointmentRequestFromJSONTyped(json, false);
}

export function CreateAppointmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAppointmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'info': !exists(json, 'info') ? undefined : AppointmentInfoFromJSON(json['info']),
        'sendEmailConfirmation': !exists(json, 'sendEmailConfirmation') ? undefined : json['sendEmailConfirmation'],
        'sendTextConfirmation': !exists(json, 'sendTextConfirmation') ? undefined : json['sendTextConfirmation'],
    };
}

export function CreateAppointmentRequestToJSON(value?: CreateAppointmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'info': AppointmentInfoToJSON(value.info),
        'sendEmailConfirmation': value.sendEmailConfirmation,
        'sendTextConfirmation': value.sendTextConfirmation,
    };
}

