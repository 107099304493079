/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PromptInfo
 */
export interface PromptInfo {
    /**
     * 
     * @type {string}
     * @memberof PromptInfo
     */
    entry?: string;
    /**
     * 
     * @type {number}
     * @memberof PromptInfo
     */
    rank?: number;
    /**
     * 
     * @type {string}
     * @memberof PromptInfo
     */
    question?: string;
    /**
     * 
     * @type {string}
     * @memberof PromptInfo
     */
    type?: PromptInfoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PromptInfo
     */
    cannedJob?: string;
    /**
     * 
     * @type {string}
     * @memberof PromptInfo
     */
    answer?: string;
    /**
     * 
     * @type {string}
     * @memberof PromptInfo
     */
    multipleChoiceAnswer?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PromptInfo
     */
    required?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PromptInfo
     */
    requiresPhoto?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PromptInfo
     */
    requiresNotes?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PromptInfo
     */
    multipleChoiceMultipleSelectAnswers?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PromptInfo
     */
    customerNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof PromptInfo
     */
    technicianNotes?: string;
    /**
     * 
     * @type {Date}
     * @memberof PromptInfo
     */
    archived?: Date;
}


/**
 * @export
 */
export const PromptInfoTypeEnum = {
    YesNo: 'yes_no',
    OpenEnded: 'open_ended',
    Numeric: 'numeric',
    MultipleChoice: 'multiple_choice',
    MultipleChoiceMultipleSelect: 'multiple_choice_multiple_select',
    ActionCannedJob: 'action_canned_job'
} as const;
export type PromptInfoTypeEnum = typeof PromptInfoTypeEnum[keyof typeof PromptInfoTypeEnum];


/**
 * Check if a given object implements the PromptInfo interface.
 */
export function instanceOfPromptInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PromptInfoFromJSON(json: any): PromptInfo {
    return PromptInfoFromJSONTyped(json, false);
}

export function PromptInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': !exists(json, 'entry') ? undefined : json['entry'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'question': !exists(json, 'question') ? undefined : json['question'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'cannedJob': !exists(json, 'cannedJob') ? undefined : json['cannedJob'],
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
        'multipleChoiceAnswer': !exists(json, 'multipleChoiceAnswer') ? undefined : json['multipleChoiceAnswer'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'requiresPhoto': !exists(json, 'requiresPhoto') ? undefined : json['requiresPhoto'],
        'requiresNotes': !exists(json, 'requiresNotes') ? undefined : json['requiresNotes'],
        'multipleChoiceMultipleSelectAnswers': !exists(json, 'multipleChoiceMultipleSelectAnswers') ? undefined : json['multipleChoiceMultipleSelectAnswers'],
        'customerNotes': !exists(json, 'customerNotes') ? undefined : json['customerNotes'],
        'technicianNotes': !exists(json, 'technicianNotes') ? undefined : json['technicianNotes'],
        'archived': !exists(json, 'archived') ? undefined : (new Date(json['archived'])),
    };
}

export function PromptInfoToJSON(value?: PromptInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': value.entry,
        'rank': value.rank,
        'question': value.question,
        'type': value.type,
        'cannedJob': value.cannedJob,
        'answer': value.answer,
        'multipleChoiceAnswer': value.multipleChoiceAnswer,
        'required': value.required,
        'requiresPhoto': value.requiresPhoto,
        'requiresNotes': value.requiresNotes,
        'multipleChoiceMultipleSelectAnswers': value.multipleChoiceMultipleSelectAnswers,
        'customerNotes': value.customerNotes,
        'technicianNotes': value.technicianNotes,
        'archived': value.archived === undefined ? undefined : (value.archived.toISOString()),
    };
}

