/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShopInfo } from './ShopInfo';
import {
    ShopInfoFromJSON,
    ShopInfoFromJSONTyped,
    ShopInfoToJSON,
} from './ShopInfo';

/**
 * 
 * @export
 * @interface ShopInfoRequest
 */
export interface ShopInfoRequest {
    /**
     * 
     * @type {ShopInfo}
     * @memberof ShopInfoRequest
     */
    shopInfo?: ShopInfo;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfoRequest
     */
    hasDefaultRecords?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopInfoRequest
     */
    excludesDefaultCannedJobs?: boolean;
}

/**
 * Check if a given object implements the ShopInfoRequest interface.
 */
export function instanceOfShopInfoRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ShopInfoRequestFromJSON(json: any): ShopInfoRequest {
    return ShopInfoRequestFromJSONTyped(json, false);
}

export function ShopInfoRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopInfoRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shopInfo': !exists(json, 'shopInfo') ? undefined : ShopInfoFromJSON(json['shopInfo']),
        'hasDefaultRecords': !exists(json, 'hasDefaultRecords') ? undefined : json['hasDefaultRecords'],
        'excludesDefaultCannedJobs': !exists(json, 'excludesDefaultCannedJobs') ? undefined : json['excludesDefaultCannedJobs'],
    };
}

export function ShopInfoRequestToJSON(value?: ShopInfoRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shopInfo': ShopInfoToJSON(value.shopInfo),
        'hasDefaultRecords': value.hasDefaultRecords,
        'excludesDefaultCannedJobs': value.excludesDefaultCannedJobs,
    };
}

