/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiscountInfo
 */
export interface DiscountInfo {
    /**
     * 
     * @type {string}
     * @memberof DiscountInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountInfo
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountInfo
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountInfo
     */
    percentage?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountInfo
     */
    includeLabor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountInfo
     */
    includeParts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountInfo
     */
    includesSublet?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DiscountInfo
     */
    cap?: number;
}

/**
 * Check if a given object implements the DiscountInfo interface.
 */
export function instanceOfDiscountInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DiscountInfoFromJSON(json: any): DiscountInfo {
    return DiscountInfoFromJSONTyped(json, false);
}

export function DiscountInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'percentage': !exists(json, 'percentage') ? undefined : json['percentage'],
        'includeLabor': !exists(json, 'includeLabor') ? undefined : json['includeLabor'],
        'includeParts': !exists(json, 'includeParts') ? undefined : json['includeParts'],
        'includesSublet': !exists(json, 'includesSublet') ? undefined : json['includesSublet'],
        'cap': !exists(json, 'cap') ? undefined : json['cap'],
    };
}

export function DiscountInfoToJSON(value?: DiscountInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'title': value.title,
        'amount': value.amount,
        'percentage': value.percentage,
        'includeLabor': value.includeLabor,
        'includeParts': value.includeParts,
        'includesSublet': value.includesSublet,
        'cap': value.cap,
    };
}

