/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LaborApplication } from './LaborApplication';
import {
    LaborApplicationFromJSON,
    LaborApplicationFromJSONTyped,
    LaborApplicationToJSON,
} from './LaborApplication';
import type { MaintenanceScheduleTimingInfo } from './MaintenanceScheduleTimingInfo';
import {
    MaintenanceScheduleTimingInfoFromJSON,
    MaintenanceScheduleTimingInfoFromJSONTyped,
    MaintenanceScheduleTimingInfoToJSON,
} from './MaintenanceScheduleTimingInfo';

/**
 * 
 * @export
 * @interface MaintenanceSchedule
 */
export interface MaintenanceSchedule {
    /**
     * 
     * @type {Array<MaintenanceScheduleTimingInfo>}
     * @memberof MaintenanceSchedule
     */
    timingInfoList?: Array<MaintenanceScheduleTimingInfo>;
    /**
     * 
     * @type {LaborApplication}
     * @memberof MaintenanceSchedule
     */
    laborApplication?: LaborApplication;
}

/**
 * Check if a given object implements the MaintenanceSchedule interface.
 */
export function instanceOfMaintenanceSchedule(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MaintenanceScheduleFromJSON(json: any): MaintenanceSchedule {
    return MaintenanceScheduleFromJSONTyped(json, false);
}

export function MaintenanceScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaintenanceSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timingInfoList': !exists(json, 'timingInfoList') ? undefined : ((json['timingInfoList'] as Array<any>).map(MaintenanceScheduleTimingInfoFromJSON)),
        'laborApplication': !exists(json, 'laborApplication') ? undefined : LaborApplicationFromJSON(json['laborApplication']),
    };
}

export function MaintenanceScheduleToJSON(value?: MaintenanceSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timingInfoList': value.timingInfoList === undefined ? undefined : ((value.timingInfoList as Array<any>).map(MaintenanceScheduleTimingInfoToJSON)),
        'laborApplication': LaborApplicationToJSON(value.laborApplication),
    };
}

