/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InventoryAdjustmentDocumentInfo
 */
export interface InventoryAdjustmentDocumentInfo {
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentDocumentInfo
     */
    inventoryAdjustment?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryAdjustmentDocumentInfo
     */
    rank?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentDocumentInfo
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentDocumentInfo
     */
    mediaUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentDocumentInfo
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentDocumentInfo
     */
    archivedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryAdjustmentDocumentInfo
     */
    archivedAt?: Date;
}

/**
 * Check if a given object implements the InventoryAdjustmentDocumentInfo interface.
 */
export function instanceOfInventoryAdjustmentDocumentInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InventoryAdjustmentDocumentInfoFromJSON(json: any): InventoryAdjustmentDocumentInfo {
    return InventoryAdjustmentDocumentInfoFromJSONTyped(json, false);
}

export function InventoryAdjustmentDocumentInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryAdjustmentDocumentInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inventoryAdjustment': !exists(json, 'inventoryAdjustment') ? undefined : json['inventoryAdjustment'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'mediaUrl': !exists(json, 'mediaUrl') ? undefined : json['mediaUrl'],
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
        'archivedBy': !exists(json, 'archivedBy') ? undefined : json['archivedBy'],
        'archivedAt': !exists(json, 'archivedAt') ? undefined : (new Date(json['archivedAt'])),
    };
}

export function InventoryAdjustmentDocumentInfoToJSON(value?: InventoryAdjustmentDocumentInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inventoryAdjustment': value.inventoryAdjustment,
        'rank': value.rank,
        'description': value.description,
        'mediaUrl': value.mediaUrl,
        'thumbnailUrl': value.thumbnailUrl,
        'archivedBy': value.archivedBy,
        'archivedAt': value.archivedAt === undefined ? undefined : (value.archivedAt.toISOString()),
    };
}

