/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EntryEntryOfInspection } from './EntryEntryOfInspection';
import {
    EntryEntryOfInspectionFromJSON,
    EntryEntryOfInspectionFromJSONTyped,
    EntryEntryOfInspectionToJSON,
} from './EntryEntryOfInspection';
import type { InspectionAnswerToCannedJobEntryOfInspection } from './InspectionAnswerToCannedJobEntryOfInspection';
import {
    InspectionAnswerToCannedJobEntryOfInspectionFromJSON,
    InspectionAnswerToCannedJobEntryOfInspectionFromJSONTyped,
    InspectionAnswerToCannedJobEntryOfInspectionToJSON,
} from './InspectionAnswerToCannedJobEntryOfInspection';
import type { PromptEntryOfInspection } from './PromptEntryOfInspection';
import {
    PromptEntryOfInspectionFromJSON,
    PromptEntryOfInspectionFromJSONTyped,
    PromptEntryOfInspectionToJSON,
} from './PromptEntryOfInspection';

/**
 * 
 * @export
 * @interface GetClassesRequest
 */
export interface GetClassesRequest {
    /**
     * 
     * @type {EntryEntryOfInspection}
     * @memberof GetClassesRequest
     */
    entryEntryOfInspection?: EntryEntryOfInspection;
    /**
     * 
     * @type {PromptEntryOfInspection}
     * @memberof GetClassesRequest
     */
    promptEntryOfInspection?: PromptEntryOfInspection;
    /**
     * 
     * @type {InspectionAnswerToCannedJobEntryOfInspection}
     * @memberof GetClassesRequest
     */
    inspectionAnswerToCannedJobEntryOfInspection?: InspectionAnswerToCannedJobEntryOfInspection;
}

/**
 * Check if a given object implements the GetClassesRequest interface.
 */
export function instanceOfGetClassesRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetClassesRequestFromJSON(json: any): GetClassesRequest {
    return GetClassesRequestFromJSONTyped(json, false);
}

export function GetClassesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetClassesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entryEntryOfInspection': !exists(json, 'entryEntryOfInspection') ? undefined : EntryEntryOfInspectionFromJSON(json['entryEntryOfInspection']),
        'promptEntryOfInspection': !exists(json, 'promptEntryOfInspection') ? undefined : PromptEntryOfInspectionFromJSON(json['promptEntryOfInspection']),
        'inspectionAnswerToCannedJobEntryOfInspection': !exists(json, 'inspectionAnswerToCannedJobEntryOfInspection') ? undefined : InspectionAnswerToCannedJobEntryOfInspectionFromJSON(json['inspectionAnswerToCannedJobEntryOfInspection']),
    };
}

export function GetClassesRequestToJSON(value?: GetClassesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entryEntryOfInspection': EntryEntryOfInspectionToJSON(value.entryEntryOfInspection),
        'promptEntryOfInspection': PromptEntryOfInspectionToJSON(value.promptEntryOfInspection),
        'inspectionAnswerToCannedJobEntryOfInspection': InspectionAnswerToCannedJobEntryOfInspectionToJSON(value.inspectionAnswerToCannedJobEntryOfInspection),
    };
}

