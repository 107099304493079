/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignTechToServiceRequest
 */
export interface AssignTechToServiceRequest {
    /**
     * 
     * @type {string}
     * @memberof AssignTechToServiceRequest
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof AssignTechToServiceRequest
     */
    service?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignTechToServiceRequest
     */
    techs?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof AssignTechToServiceRequest
     */
    techCredit?: { [key: string]: number; };
}

/**
 * Check if a given object implements the AssignTechToServiceRequest interface.
 */
export function instanceOfAssignTechToServiceRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssignTechToServiceRequestFromJSON(json: any): AssignTechToServiceRequest {
    return AssignTechToServiceRequestFromJSONTyped(json, false);
}

export function AssignTechToServiceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignTechToServiceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'techs': !exists(json, 'techs') ? undefined : json['techs'],
        'techCredit': !exists(json, 'techCredit') ? undefined : json['techCredit'],
    };
}

export function AssignTechToServiceRequestToJSON(value?: AssignTechToServiceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'service': value.service,
        'techs': value.techs,
        'techCredit': value.techCredit,
    };
}

