/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepairPaymentInfo
 */
export interface RepairPaymentInfo {
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    repairOrder?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    counterSale?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairPaymentInfo
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairPaymentInfo
     */
    surcharge?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    via?: RepairPaymentInfoViaEnum;
    /**
     * 
     * @type {Date}
     * @memberof RepairPaymentInfo
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    finixTransferId?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    cachedFinixTransferStatus?: RepairPaymentInfoCachedFinixTransferStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    receiptDateTime?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    receiptApplicationIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    receiptApplicationLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    receiptMerchantId?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    receiptReferenceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    receiptAccountNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    receiptCardBrand?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    receiptEntryMode?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    receiptApprovalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    apiKey?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    codatId?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairPaymentInfo
     */
    codatPushKey?: string;
    /**
     * 
     * @type {Date}
     * @memberof RepairPaymentInfo
     */
    lastCodatModifiedDate?: Date;
}


/**
 * @export
 */
export const RepairPaymentInfoViaEnum = {
    CreditDebitCard: 'credit_debit_card',
    Visa: 'visa',
    Mastercard: 'mastercard',
    Discover: 'discover',
    AmericanExpress: 'american_express',
    Cash: 'cash',
    Check: 'check',
    Finix: 'finix',
    Credit: 'credit'
} as const;
export type RepairPaymentInfoViaEnum = typeof RepairPaymentInfoViaEnum[keyof typeof RepairPaymentInfoViaEnum];

/**
 * @export
 */
export const RepairPaymentInfoCachedFinixTransferStatusEnum = {
    Canceled: 'CANCELED',
    Pending: 'PENDING',
    Failed: 'FAILED',
    Succeeded: 'SUCCEEDED',
    Unknown: 'UNKNOWN'
} as const;
export type RepairPaymentInfoCachedFinixTransferStatusEnum = typeof RepairPaymentInfoCachedFinixTransferStatusEnum[keyof typeof RepairPaymentInfoCachedFinixTransferStatusEnum];


/**
 * Check if a given object implements the RepairPaymentInfo interface.
 */
export function instanceOfRepairPaymentInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairPaymentInfoFromJSON(json: any): RepairPaymentInfo {
    return RepairPaymentInfoFromJSONTyped(json, false);
}

export function RepairPaymentInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairPaymentInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'repairOrder': !exists(json, 'repairOrder') ? undefined : json['repairOrder'],
        'counterSale': !exists(json, 'counterSale') ? undefined : json['counterSale'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'surcharge': !exists(json, 'surcharge') ? undefined : json['surcharge'],
        'via': !exists(json, 'via') ? undefined : json['via'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'finixTransferId': !exists(json, 'finixTransferId') ? undefined : json['finixTransferId'],
        'cachedFinixTransferStatus': !exists(json, 'cachedFinixTransferStatus') ? undefined : json['cachedFinixTransferStatus'],
        'receiptDateTime': !exists(json, 'receiptDateTime') ? undefined : json['receiptDateTime'],
        'receiptApplicationIdentifier': !exists(json, 'receiptApplicationIdentifier') ? undefined : json['receiptApplicationIdentifier'],
        'receiptApplicationLabel': !exists(json, 'receiptApplicationLabel') ? undefined : json['receiptApplicationLabel'],
        'receiptMerchantId': !exists(json, 'receiptMerchantId') ? undefined : json['receiptMerchantId'],
        'receiptReferenceNumber': !exists(json, 'receiptReferenceNumber') ? undefined : json['receiptReferenceNumber'],
        'receiptAccountNumber': !exists(json, 'receiptAccountNumber') ? undefined : json['receiptAccountNumber'],
        'receiptCardBrand': !exists(json, 'receiptCardBrand') ? undefined : json['receiptCardBrand'],
        'receiptEntryMode': !exists(json, 'receiptEntryMode') ? undefined : json['receiptEntryMode'],
        'receiptApprovalCode': !exists(json, 'receiptApprovalCode') ? undefined : json['receiptApprovalCode'],
        'info': !exists(json, 'info') ? undefined : json['info'],
        'apiKey': !exists(json, 'apiKey') ? undefined : json['apiKey'],
        'codatId': !exists(json, 'codatId') ? undefined : json['codatId'],
        'codatPushKey': !exists(json, 'codatPushKey') ? undefined : json['codatPushKey'],
        'lastCodatModifiedDate': !exists(json, 'lastCodatModifiedDate') ? undefined : (new Date(json['lastCodatModifiedDate'])),
    };
}

export function RepairPaymentInfoToJSON(value?: RepairPaymentInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'repairOrder': value.repairOrder,
        'counterSale': value.counterSale,
        'amount': value.amount,
        'surcharge': value.surcharge,
        'via': value.via,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'finixTransferId': value.finixTransferId,
        'cachedFinixTransferStatus': value.cachedFinixTransferStatus,
        'receiptDateTime': value.receiptDateTime,
        'receiptApplicationIdentifier': value.receiptApplicationIdentifier,
        'receiptApplicationLabel': value.receiptApplicationLabel,
        'receiptMerchantId': value.receiptMerchantId,
        'receiptReferenceNumber': value.receiptReferenceNumber,
        'receiptAccountNumber': value.receiptAccountNumber,
        'receiptCardBrand': value.receiptCardBrand,
        'receiptEntryMode': value.receiptEntryMode,
        'receiptApprovalCode': value.receiptApprovalCode,
        'info': value.info,
        'apiKey': value.apiKey,
        'codatId': value.codatId,
        'codatPushKey': value.codatPushKey,
        'lastCodatModifiedDate': value.lastCodatModifiedDate === undefined ? undefined : (value.lastCodatModifiedDate.toISOString()),
    };
}

