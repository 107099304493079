/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PriceLevelInfo
 */
export interface PriceLevelInfo {
    /**
     * 
     * @type {string}
     * @memberof PriceLevelInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceLevelInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceLevelInfo
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof PriceLevelInfo
     */
    rank?: number;
    /**
     * 
     * @type {Date}
     * @memberof PriceLevelInfo
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PriceLevelInfo
     */
    modified?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PriceLevelInfo
     */
    archived?: Date;
}

/**
 * Check if a given object implements the PriceLevelInfo interface.
 */
export function instanceOfPriceLevelInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PriceLevelInfoFromJSON(json: any): PriceLevelInfo {
    return PriceLevelInfoFromJSONTyped(json, false);
}

export function PriceLevelInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceLevelInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'modified': !exists(json, 'modified') ? undefined : (new Date(json['modified'])),
        'archived': !exists(json, 'archived') ? undefined : (new Date(json['archived'])),
    };
}

export function PriceLevelInfoToJSON(value?: PriceLevelInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'name': value.name,
        'description': value.description,
        'rank': value.rank,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'modified': value.modified === undefined ? undefined : (value.modified.toISOString()),
        'archived': value.archived === undefined ? undefined : (value.archived.toISOString()),
    };
}

