/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepairOrderCodatLineItem
 */
export interface RepairOrderCodatLineItem {
    /**
     * 
     * @type {string}
     * @memberof RepairOrderCodatLineItem
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderCodatLineItem
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderCodatLineItem
     */
    unitAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderCodatLineItem
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderCodatLineItem
     */
    discountAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderCodatLineItem
     */
    subTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderCodatLineItem
     */
    tax?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderCodatLineItem
     */
    total?: number;
}

/**
 * Check if a given object implements the RepairOrderCodatLineItem interface.
 */
export function instanceOfRepairOrderCodatLineItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairOrderCodatLineItemFromJSON(json: any): RepairOrderCodatLineItem {
    return RepairOrderCodatLineItemFromJSONTyped(json, false);
}

export function RepairOrderCodatLineItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairOrderCodatLineItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'unitAmount': !exists(json, 'unitAmount') ? undefined : json['unitAmount'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'discountAmount': !exists(json, 'discountAmount') ? undefined : json['discountAmount'],
        'subTotal': !exists(json, 'subTotal') ? undefined : json['subTotal'],
        'tax': !exists(json, 'tax') ? undefined : json['tax'],
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function RepairOrderCodatLineItemToJSON(value?: RepairOrderCodatLineItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'unitAmount': value.unitAmount,
        'quantity': value.quantity,
        'discountAmount': value.discountAmount,
        'subTotal': value.subTotal,
        'tax': value.tax,
        'total': value.total,
    };
}

