/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InventoryPartInstanceEntry } from './InventoryPartInstanceEntry';
import {
    InventoryPartInstanceEntryFromJSON,
    InventoryPartInstanceEntryFromJSONTyped,
    InventoryPartInstanceEntryToJSON,
} from './InventoryPartInstanceEntry';
import type { PurchaseOrderEntry } from './PurchaseOrderEntry';
import {
    PurchaseOrderEntryFromJSON,
    PurchaseOrderEntryFromJSONTyped,
    PurchaseOrderEntryToJSON,
} from './PurchaseOrderEntry';

/**
 * 
 * @export
 * @interface PurchaseOrderEntryExpanded
 */
export interface PurchaseOrderEntryExpanded {
    /**
     * 
     * @type {PurchaseOrderEntry}
     * @memberof PurchaseOrderEntryExpanded
     */
    purchaseOrderEntry?: PurchaseOrderEntry;
    /**
     * 
     * @type {Array<InventoryPartInstanceEntry>}
     * @memberof PurchaseOrderEntryExpanded
     */
    inventoryPartInstanceEntries?: Array<InventoryPartInstanceEntry>;
}

/**
 * Check if a given object implements the PurchaseOrderEntryExpanded interface.
 */
export function instanceOfPurchaseOrderEntryExpanded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PurchaseOrderEntryExpandedFromJSON(json: any): PurchaseOrderEntryExpanded {
    return PurchaseOrderEntryExpandedFromJSONTyped(json, false);
}

export function PurchaseOrderEntryExpandedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchaseOrderEntryExpanded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'purchaseOrderEntry': !exists(json, 'purchaseOrderEntry') ? undefined : PurchaseOrderEntryFromJSON(json['purchaseOrderEntry']),
        'inventoryPartInstanceEntries': !exists(json, 'inventoryPartInstanceEntries') ? undefined : ((json['inventoryPartInstanceEntries'] as Array<any>).map(InventoryPartInstanceEntryFromJSON)),
    };
}

export function PurchaseOrderEntryExpandedToJSON(value?: PurchaseOrderEntryExpanded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'purchaseOrderEntry': PurchaseOrderEntryToJSON(value.purchaseOrderEntry),
        'inventoryPartInstanceEntries': value.inventoryPartInstanceEntries === undefined ? undefined : ((value.inventoryPartInstanceEntries as Array<any>).map(InventoryPartInstanceEntryToJSON)),
    };
}

