/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CannedJobCustomFeeEntry } from './CannedJobCustomFeeEntry';
import {
    CannedJobCustomFeeEntryFromJSON,
    CannedJobCustomFeeEntryFromJSONTyped,
    CannedJobCustomFeeEntryToJSON,
} from './CannedJobCustomFeeEntry';
import type { CannedJobCustomLaborEntry } from './CannedJobCustomLaborEntry';
import {
    CannedJobCustomLaborEntryFromJSON,
    CannedJobCustomLaborEntryFromJSONTyped,
    CannedJobCustomLaborEntryToJSON,
} from './CannedJobCustomLaborEntry';
import type { CannedJobCustomPartEntry } from './CannedJobCustomPartEntry';
import {
    CannedJobCustomPartEntryFromJSON,
    CannedJobCustomPartEntryFromJSONTyped,
    CannedJobCustomPartEntryToJSON,
} from './CannedJobCustomPartEntry';
import type { CannedJobEntry } from './CannedJobEntry';
import {
    CannedJobEntryFromJSON,
    CannedJobEntryFromJSONTyped,
    CannedJobEntryToJSON,
} from './CannedJobEntry';
import type { CannedJobInventoryPartEntry } from './CannedJobInventoryPartEntry';
import {
    CannedJobInventoryPartEntryFromJSON,
    CannedJobInventoryPartEntryFromJSONTyped,
    CannedJobInventoryPartEntryToJSON,
} from './CannedJobInventoryPartEntry';
import type { CannedJobMotorFluidsEntry } from './CannedJobMotorFluidsEntry';
import {
    CannedJobMotorFluidsEntryFromJSON,
    CannedJobMotorFluidsEntryFromJSONTyped,
    CannedJobMotorFluidsEntryToJSON,
} from './CannedJobMotorFluidsEntry';
import type { CannedJobMotorLaborEntry } from './CannedJobMotorLaborEntry';
import {
    CannedJobMotorLaborEntryFromJSON,
    CannedJobMotorLaborEntryFromJSONTyped,
    CannedJobMotorLaborEntryToJSON,
} from './CannedJobMotorLaborEntry';
import type { CannedJobPartLookupEntry } from './CannedJobPartLookupEntry';
import {
    CannedJobPartLookupEntryFromJSON,
    CannedJobPartLookupEntryFromJSONTyped,
    CannedJobPartLookupEntryToJSON,
} from './CannedJobPartLookupEntry';
import type { CannedJobRelatedEntry } from './CannedJobRelatedEntry';
import {
    CannedJobRelatedEntryFromJSON,
    CannedJobRelatedEntryFromJSONTyped,
    CannedJobRelatedEntryToJSON,
} from './CannedJobRelatedEntry';
import type { CannedJobToInventoryProductEntry } from './CannedJobToInventoryProductEntry';
import {
    CannedJobToInventoryProductEntryFromJSON,
    CannedJobToInventoryProductEntryFromJSONTyped,
    CannedJobToInventoryProductEntryToJSON,
} from './CannedJobToInventoryProductEntry';
import type { InventoryPartEntry } from './InventoryPartEntry';
import {
    InventoryPartEntryFromJSON,
    InventoryPartEntryFromJSONTyped,
    InventoryPartEntryToJSON,
} from './InventoryPartEntry';

/**
 * 
 * @export
 * @interface CannedJobExpanded
 */
export interface CannedJobExpanded {
    /**
     * 
     * @type {CannedJobEntry}
     * @memberof CannedJobExpanded
     */
    entry?: CannedJobEntry;
    /**
     * 
     * @type {Array<CannedJobMotorLaborEntry>}
     * @memberof CannedJobExpanded
     */
    motorLabor?: Array<CannedJobMotorLaborEntry>;
    /**
     * 
     * @type {Array<CannedJobMotorFluidsEntry>}
     * @memberof CannedJobExpanded
     */
    motorFluids?: Array<CannedJobMotorFluidsEntry>;
    /**
     * 
     * @type {Array<CannedJobPartLookupEntry>}
     * @memberof CannedJobExpanded
     */
    partLookups?: Array<CannedJobPartLookupEntry>;
    /**
     * 
     * @type {Array<CannedJobCustomLaborEntry>}
     * @memberof CannedJobExpanded
     */
    customLabor?: Array<CannedJobCustomLaborEntry>;
    /**
     * 
     * @type {Array<CannedJobCustomPartEntry>}
     * @memberof CannedJobExpanded
     */
    customParts?: Array<CannedJobCustomPartEntry>;
    /**
     * 
     * @type {Array<CannedJobCustomFeeEntry>}
     * @memberof CannedJobExpanded
     */
    customFees?: Array<CannedJobCustomFeeEntry>;
    /**
     * 
     * @type {Array<CannedJobInventoryPartEntry>}
     * @memberof CannedJobExpanded
     */
    inventoryParts?: Array<CannedJobInventoryPartEntry>;
    /**
     * 
     * @type {Array<CannedJobToInventoryProductEntry>}
     * @memberof CannedJobExpanded
     */
    inventoryProducts?: Array<CannedJobToInventoryProductEntry>;
    /**
     * 
     * @type {{ [key: string]: InventoryPartEntry; }}
     * @memberof CannedJobExpanded
     */
    idToInventoryParts?: { [key: string]: InventoryPartEntry; };
    /**
     * 
     * @type {{ [key: string]: CannedJobCustomPartEntry; }}
     * @memberof CannedJobExpanded
     */
    idToInventoryProducts?: { [key: string]: CannedJobCustomPartEntry; };
    /**
     * 
     * @type {Array<CannedJobRelatedEntry>}
     * @memberof CannedJobExpanded
     */
    related?: Array<CannedJobRelatedEntry>;
}

/**
 * Check if a given object implements the CannedJobExpanded interface.
 */
export function instanceOfCannedJobExpanded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CannedJobExpandedFromJSON(json: any): CannedJobExpanded {
    return CannedJobExpandedFromJSONTyped(json, false);
}

export function CannedJobExpandedFromJSONTyped(json: any, ignoreDiscriminator: boolean): CannedJobExpanded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': !exists(json, 'entry') ? undefined : CannedJobEntryFromJSON(json['entry']),
        'motorLabor': !exists(json, 'motorLabor') ? undefined : ((json['motorLabor'] as Array<any>).map(CannedJobMotorLaborEntryFromJSON)),
        'motorFluids': !exists(json, 'motorFluids') ? undefined : ((json['motorFluids'] as Array<any>).map(CannedJobMotorFluidsEntryFromJSON)),
        'partLookups': !exists(json, 'partLookups') ? undefined : ((json['partLookups'] as Array<any>).map(CannedJobPartLookupEntryFromJSON)),
        'customLabor': !exists(json, 'customLabor') ? undefined : ((json['customLabor'] as Array<any>).map(CannedJobCustomLaborEntryFromJSON)),
        'customParts': !exists(json, 'customParts') ? undefined : ((json['customParts'] as Array<any>).map(CannedJobCustomPartEntryFromJSON)),
        'customFees': !exists(json, 'customFees') ? undefined : ((json['customFees'] as Array<any>).map(CannedJobCustomFeeEntryFromJSON)),
        'inventoryParts': !exists(json, 'inventoryParts') ? undefined : ((json['inventoryParts'] as Array<any>).map(CannedJobInventoryPartEntryFromJSON)),
        'inventoryProducts': !exists(json, 'inventoryProducts') ? undefined : ((json['inventoryProducts'] as Array<any>).map(CannedJobToInventoryProductEntryFromJSON)),
        'idToInventoryParts': !exists(json, 'idToInventoryParts') ? undefined : (mapValues(json['idToInventoryParts'], InventoryPartEntryFromJSON)),
        'idToInventoryProducts': !exists(json, 'idToInventoryProducts') ? undefined : (mapValues(json['idToInventoryProducts'], CannedJobCustomPartEntryFromJSON)),
        'related': !exists(json, 'related') ? undefined : ((json['related'] as Array<any>).map(CannedJobRelatedEntryFromJSON)),
    };
}

export function CannedJobExpandedToJSON(value?: CannedJobExpanded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': CannedJobEntryToJSON(value.entry),
        'motorLabor': value.motorLabor === undefined ? undefined : ((value.motorLabor as Array<any>).map(CannedJobMotorLaborEntryToJSON)),
        'motorFluids': value.motorFluids === undefined ? undefined : ((value.motorFluids as Array<any>).map(CannedJobMotorFluidsEntryToJSON)),
        'partLookups': value.partLookups === undefined ? undefined : ((value.partLookups as Array<any>).map(CannedJobPartLookupEntryToJSON)),
        'customLabor': value.customLabor === undefined ? undefined : ((value.customLabor as Array<any>).map(CannedJobCustomLaborEntryToJSON)),
        'customParts': value.customParts === undefined ? undefined : ((value.customParts as Array<any>).map(CannedJobCustomPartEntryToJSON)),
        'customFees': value.customFees === undefined ? undefined : ((value.customFees as Array<any>).map(CannedJobCustomFeeEntryToJSON)),
        'inventoryParts': value.inventoryParts === undefined ? undefined : ((value.inventoryParts as Array<any>).map(CannedJobInventoryPartEntryToJSON)),
        'inventoryProducts': value.inventoryProducts === undefined ? undefined : ((value.inventoryProducts as Array<any>).map(CannedJobToInventoryProductEntryToJSON)),
        'idToInventoryParts': value.idToInventoryParts === undefined ? undefined : (mapValues(value.idToInventoryParts, InventoryPartEntryToJSON)),
        'idToInventoryProducts': value.idToInventoryProducts === undefined ? undefined : (mapValues(value.idToInventoryProducts, CannedJobCustomPartEntryToJSON)),
        'related': value.related === undefined ? undefined : ((value.related as Array<any>).map(CannedJobRelatedEntryToJSON)),
    };
}

