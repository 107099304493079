/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerFeedbackInfo } from './CustomerFeedbackInfo';
import {
    CustomerFeedbackInfoFromJSON,
    CustomerFeedbackInfoFromJSONTyped,
    CustomerFeedbackInfoToJSON,
} from './CustomerFeedbackInfo';

/**
 * 
 * @export
 * @interface CustomerFeedbackEntry
 */
export interface CustomerFeedbackEntry {
    /**
     * 
     * @type {string}
     * @memberof CustomerFeedbackEntry
     */
    apiKey?: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerFeedbackEntry
     */
    dateTime?: Date;
    /**
     * 
     * @type {CustomerFeedbackInfo}
     * @memberof CustomerFeedbackEntry
     */
    info?: CustomerFeedbackInfo;
}

/**
 * Check if a given object implements the CustomerFeedbackEntry interface.
 */
export function instanceOfCustomerFeedbackEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerFeedbackEntryFromJSON(json: any): CustomerFeedbackEntry {
    return CustomerFeedbackEntryFromJSONTyped(json, false);
}

export function CustomerFeedbackEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerFeedbackEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiKey': !exists(json, 'apiKey') ? undefined : json['apiKey'],
        'dateTime': !exists(json, 'dateTime') ? undefined : (new Date(json['dateTime'])),
        'info': !exists(json, 'info') ? undefined : CustomerFeedbackInfoFromJSON(json['info']),
    };
}

export function CustomerFeedbackEntryToJSON(value?: CustomerFeedbackEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiKey': value.apiKey,
        'dateTime': value.dateTime === undefined ? undefined : (value.dateTime.toISOString()),
        'info': CustomerFeedbackInfoToJSON(value.info),
    };
}

