/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CannedJobInfo
 */
export interface CannedJobInfo {
    /**
     * 
     * @type {string}
     * @memberof CannedJobInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof CannedJobInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CannedJobInfo
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CannedJobInfo
     */
    taxExempt?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CannedJobInfo
     */
    shopSuppliesFeeExempt?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CannedJobInfo
     */
    shopHazmatFeeExempt?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CannedJobInfo
     */
    lastUpdated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CannedJobInfo
     */
    deactivated?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CannedJobInfo
     */
    archived?: Date;
    /**
     * 
     * @type {string}
     * @memberof CannedJobInfo
     */
    shortCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CannedJobInfo
     */
    selectableByCustomer?: boolean;
}

/**
 * Check if a given object implements the CannedJobInfo interface.
 */
export function instanceOfCannedJobInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CannedJobInfoFromJSON(json: any): CannedJobInfo {
    return CannedJobInfoFromJSONTyped(json, false);
}

export function CannedJobInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CannedJobInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'taxExempt': !exists(json, 'taxExempt') ? undefined : json['taxExempt'],
        'shopSuppliesFeeExempt': !exists(json, 'shopSuppliesFeeExempt') ? undefined : json['shopSuppliesFeeExempt'],
        'shopHazmatFeeExempt': !exists(json, 'shopHazmatFeeExempt') ? undefined : json['shopHazmatFeeExempt'],
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : (new Date(json['lastUpdated'])),
        'deactivated': !exists(json, 'deactivated') ? undefined : (new Date(json['deactivated'])),
        'archived': !exists(json, 'archived') ? undefined : (new Date(json['archived'])),
        'shortCode': !exists(json, 'shortCode') ? undefined : json['shortCode'],
        'selectableByCustomer': !exists(json, 'selectableByCustomer') ? undefined : json['selectableByCustomer'],
    };
}

export function CannedJobInfoToJSON(value?: CannedJobInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'name': value.name,
        'description': value.description,
        'taxExempt': value.taxExempt,
        'shopSuppliesFeeExempt': value.shopSuppliesFeeExempt,
        'shopHazmatFeeExempt': value.shopHazmatFeeExempt,
        'lastUpdated': value.lastUpdated === undefined ? undefined : (value.lastUpdated.toISOString()),
        'deactivated': value.deactivated === undefined ? undefined : (value.deactivated.toISOString()),
        'archived': value.archived === undefined ? undefined : (value.archived.toISOString()),
        'shortCode': value.shortCode,
        'selectableByCustomer': value.selectableByCustomer,
    };
}

