/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FluidItem
 */
export interface FluidItem {
    /**
     * 
     * @type {number}
     * @memberof FluidItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FluidItem
     */
    gradeCode?: string;
    /**
     * 
     * @type {string}
     * @memberof FluidItem
     */
    gradeDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FluidItem
     */
    notes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FluidItem
     */
    tempRange?: string;
    /**
     * 
     * @type {string}
     * @memberof FluidItem
     */
    tradeName?: string;
    /**
     * 
     * @type {string}
     * @memberof FluidItem
     */
    viscosity?: string;
    /**
     * 
     * @type {string}
     * @memberof FluidItem
     */
    formulation?: string;
}

/**
 * Check if a given object implements the FluidItem interface.
 */
export function instanceOfFluidItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FluidItemFromJSON(json: any): FluidItem {
    return FluidItemFromJSONTyped(json, false);
}

export function FluidItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FluidItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'gradeCode': !exists(json, 'gradeCode') ? undefined : json['gradeCode'],
        'gradeDescription': !exists(json, 'gradeDescription') ? undefined : json['gradeDescription'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'tempRange': !exists(json, 'tempRange') ? undefined : json['tempRange'],
        'tradeName': !exists(json, 'tradeName') ? undefined : json['tradeName'],
        'viscosity': !exists(json, 'viscosity') ? undefined : json['viscosity'],
        'formulation': !exists(json, 'formulation') ? undefined : json['formulation'],
    };
}

export function FluidItemToJSON(value?: FluidItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'gradeCode': value.gradeCode,
        'gradeDescription': value.gradeDescription,
        'notes': value.notes,
        'tempRange': value.tempRange,
        'tradeName': value.tradeName,
        'viscosity': value.viscosity,
        'formulation': value.formulation,
    };
}

