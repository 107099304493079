/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerEntry } from './CustomerEntry';
import {
    CustomerEntryFromJSON,
    CustomerEntryFromJSONTyped,
    CustomerEntryToJSON,
} from './CustomerEntry';
import type { RepairOrderEntry } from './RepairOrderEntry';
import {
    RepairOrderEntryFromJSON,
    RepairOrderEntryFromJSONTyped,
    RepairOrderEntryToJSON,
} from './RepairOrderEntry';
import type { VehicleEntry } from './VehicleEntry';
import {
    VehicleEntryFromJSON,
    VehicleEntryFromJSONTyped,
    VehicleEntryToJSON,
} from './VehicleEntry';

/**
 * 
 * @export
 * @interface RepairOrderWithRelated
 */
export interface RepairOrderWithRelated {
    /**
     * 
     * @type {RepairOrderEntry}
     * @memberof RepairOrderWithRelated
     */
    repairOrder?: RepairOrderEntry;
    /**
     * 
     * @type {CustomerEntry}
     * @memberof RepairOrderWithRelated
     */
    customer?: CustomerEntry;
    /**
     * 
     * @type {VehicleEntry}
     * @memberof RepairOrderWithRelated
     */
    vehicle?: VehicleEntry;
}

/**
 * Check if a given object implements the RepairOrderWithRelated interface.
 */
export function instanceOfRepairOrderWithRelated(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairOrderWithRelatedFromJSON(json: any): RepairOrderWithRelated {
    return RepairOrderWithRelatedFromJSONTyped(json, false);
}

export function RepairOrderWithRelatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairOrderWithRelated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'repairOrder': !exists(json, 'repairOrder') ? undefined : RepairOrderEntryFromJSON(json['repairOrder']),
        'customer': !exists(json, 'customer') ? undefined : CustomerEntryFromJSON(json['customer']),
        'vehicle': !exists(json, 'vehicle') ? undefined : VehicleEntryFromJSON(json['vehicle']),
    };
}

export function RepairOrderWithRelatedToJSON(value?: RepairOrderWithRelated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'repairOrder': RepairOrderEntryToJSON(value.repairOrder),
        'customer': CustomerEntryToJSON(value.customer),
        'vehicle': VehicleEntryToJSON(value.vehicle),
    };
}

