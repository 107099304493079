/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MyRole } from './MyRole';
import {
    MyRoleFromJSON,
    MyRoleFromJSONTyped,
    MyRoleToJSON,
} from './MyRole';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
} from './UserProfile';

/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    token?: string;
    /**
     * 
     * @type {UserProfile}
     * @memberof LoginResponse
     */
    profile?: UserProfile;
    /**
     * 
     * @type {Array<MyRole>}
     * @memberof LoginResponse
     */
    myRoles?: Array<MyRole>;
}

/**
 * Check if a given object implements the LoginResponse interface.
 */
export function instanceOfLoginResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LoginResponseFromJSON(json: any): LoginResponse {
    return LoginResponseFromJSONTyped(json, false);
}

export function LoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': !exists(json, 'token') ? undefined : json['token'],
        'profile': !exists(json, 'profile') ? undefined : UserProfileFromJSON(json['profile']),
        'myRoles': !exists(json, 'myRoles') ? undefined : ((json['myRoles'] as Array<any>).map(MyRoleFromJSON)),
    };
}

export function LoginResponseToJSON(value?: LoginResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'profile': UserProfileToJSON(value.profile),
        'myRoles': value.myRoles === undefined ? undefined : ((value.myRoles as Array<any>).map(MyRoleToJSON)),
    };
}

