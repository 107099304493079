/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleYMMSE
 */
export interface VehicleYMMSE {
    /**
     * 
     * @type {number}
     * @memberof VehicleYMMSE
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleYMMSE
     */
    makeVcdbId?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleYMMSE
     */
    modelVcdbId?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleYMMSE
     */
    submodelVcdbId?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleYMMSE
     */
    engineVcdbId?: number;
    /**
     * 
     * @type {string}
     * @memberof VehicleYMMSE
     */
    makeName?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleYMMSE
     */
    modelName?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleYMMSE
     */
    submodelName?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleYMMSE
     */
    engineName?: string;
}

/**
 * Check if a given object implements the VehicleYMMSE interface.
 */
export function instanceOfVehicleYMMSE(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleYMMSEFromJSON(json: any): VehicleYMMSE {
    return VehicleYMMSEFromJSONTyped(json, false);
}

export function VehicleYMMSEFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleYMMSE {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': !exists(json, 'year') ? undefined : json['year'],
        'makeVcdbId': !exists(json, 'makeVcdbId') ? undefined : json['makeVcdbId'],
        'modelVcdbId': !exists(json, 'modelVcdbId') ? undefined : json['modelVcdbId'],
        'submodelVcdbId': !exists(json, 'submodelVcdbId') ? undefined : json['submodelVcdbId'],
        'engineVcdbId': !exists(json, 'engineVcdbId') ? undefined : json['engineVcdbId'],
        'makeName': !exists(json, 'makeName') ? undefined : json['makeName'],
        'modelName': !exists(json, 'modelName') ? undefined : json['modelName'],
        'submodelName': !exists(json, 'submodelName') ? undefined : json['submodelName'],
        'engineName': !exists(json, 'engineName') ? undefined : json['engineName'],
    };
}

export function VehicleYMMSEToJSON(value?: VehicleYMMSE | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'makeVcdbId': value.makeVcdbId,
        'modelVcdbId': value.modelVcdbId,
        'submodelVcdbId': value.submodelVcdbId,
        'engineVcdbId': value.engineVcdbId,
        'makeName': value.makeName,
        'modelName': value.modelName,
        'submodelName': value.submodelName,
        'engineName': value.engineName,
    };
}

