/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SerializableGeometry } from './SerializableGeometry';
import {
    SerializableGeometryFromJSON,
    SerializableGeometryFromJSONTyped,
    SerializableGeometryToJSON,
} from './SerializableGeometry';
import type { SerializableRelationship } from './SerializableRelationship';
import {
    SerializableRelationshipFromJSON,
    SerializableRelationshipFromJSONTyped,
    SerializableRelationshipToJSON,
} from './SerializableRelationship';

/**
 * 
 * @export
 * @interface SerializableBlock
 */
export interface SerializableBlock {
    /**
     * 
     * @type {string}
     * @memberof SerializableBlock
     */
    blockType?: SerializableBlockBlockTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SerializableBlock
     */
    columnIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof SerializableBlock
     */
    columnSpan?: number;
    /**
     * 
     * @type {number}
     * @memberof SerializableBlock
     */
    confidence?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SerializableBlock
     */
    entityTypes?: Array<SerializableBlockEntityTypesEnum>;
    /**
     * 
     * @type {number}
     * @memberof SerializableBlock
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof SerializableBlock
     */
    rowIndex?: number;
    /**
     * 
     * @type {number}
     * @memberof SerializableBlock
     */
    rowSpan?: number;
    /**
     * 
     * @type {string}
     * @memberof SerializableBlock
     */
    selectionStatus?: SerializableBlockSelectionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SerializableBlock
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof SerializableBlock
     */
    textType?: SerializableBlockTextTypeEnum;
    /**
     * 
     * @type {SerializableGeometry}
     * @memberof SerializableBlock
     */
    geometry?: SerializableGeometry;
    /**
     * 
     * @type {Array<SerializableRelationship>}
     * @memberof SerializableBlock
     */
    relationships?: Array<SerializableRelationship>;
    /**
     * 
     * @type {string}
     * @memberof SerializableBlock
     */
    id?: string;
}


/**
 * @export
 */
export const SerializableBlockBlockTypeEnum = {
    KeyValueSet: 'KEY_VALUE_SET',
    Page: 'PAGE',
    Line: 'LINE',
    Word: 'WORD',
    Table: 'TABLE',
    Cell: 'CELL',
    SelectionElement: 'SELECTION_ELEMENT',
    MergedCell: 'MERGED_CELL',
    Title: 'TITLE',
    Query: 'QUERY',
    QueryResult: 'QUERY_RESULT',
    Signature: 'SIGNATURE',
    TableTitle: 'TABLE_TITLE',
    TableFooter: 'TABLE_FOOTER',
    Null: 'null'
} as const;
export type SerializableBlockBlockTypeEnum = typeof SerializableBlockBlockTypeEnum[keyof typeof SerializableBlockBlockTypeEnum];

/**
 * @export
 */
export const SerializableBlockEntityTypesEnum = {
    Key: 'KEY',
    Value: 'VALUE',
    ColumnHeader: 'COLUMN_HEADER',
    TableTitle: 'TABLE_TITLE',
    TableFooter: 'TABLE_FOOTER',
    TableSectionTitle: 'TABLE_SECTION_TITLE',
    TableSummary: 'TABLE_SUMMARY',
    StructuredTable: 'STRUCTURED_TABLE',
    SemiStructuredTable: 'SEMI_STRUCTURED_TABLE',
    Null: 'null'
} as const;
export type SerializableBlockEntityTypesEnum = typeof SerializableBlockEntityTypesEnum[keyof typeof SerializableBlockEntityTypesEnum];

/**
 * @export
 */
export const SerializableBlockSelectionStatusEnum = {
    Selected: 'SELECTED',
    NotSelected: 'NOT_SELECTED',
    Null: 'null'
} as const;
export type SerializableBlockSelectionStatusEnum = typeof SerializableBlockSelectionStatusEnum[keyof typeof SerializableBlockSelectionStatusEnum];

/**
 * @export
 */
export const SerializableBlockTextTypeEnum = {
    Handwriting: 'HANDWRITING',
    Printed: 'PRINTED',
    Null: 'null'
} as const;
export type SerializableBlockTextTypeEnum = typeof SerializableBlockTextTypeEnum[keyof typeof SerializableBlockTextTypeEnum];


/**
 * Check if a given object implements the SerializableBlock interface.
 */
export function instanceOfSerializableBlock(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SerializableBlockFromJSON(json: any): SerializableBlock {
    return SerializableBlockFromJSONTyped(json, false);
}

export function SerializableBlockFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerializableBlock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blockType': !exists(json, 'blockType') ? undefined : json['blockType'],
        'columnIndex': !exists(json, 'columnIndex') ? undefined : json['columnIndex'],
        'columnSpan': !exists(json, 'columnSpan') ? undefined : json['columnSpan'],
        'confidence': !exists(json, 'confidence') ? undefined : json['confidence'],
        'entityTypes': !exists(json, 'entityTypes') ? undefined : json['entityTypes'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'rowIndex': !exists(json, 'rowIndex') ? undefined : json['rowIndex'],
        'rowSpan': !exists(json, 'rowSpan') ? undefined : json['rowSpan'],
        'selectionStatus': !exists(json, 'selectionStatus') ? undefined : json['selectionStatus'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'textType': !exists(json, 'textType') ? undefined : json['textType'],
        'geometry': !exists(json, 'geometry') ? undefined : SerializableGeometryFromJSON(json['geometry']),
        'relationships': !exists(json, 'relationships') ? undefined : ((json['relationships'] as Array<any>).map(SerializableRelationshipFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function SerializableBlockToJSON(value?: SerializableBlock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blockType': value.blockType,
        'columnIndex': value.columnIndex,
        'columnSpan': value.columnSpan,
        'confidence': value.confidence,
        'entityTypes': value.entityTypes,
        'page': value.page,
        'rowIndex': value.rowIndex,
        'rowSpan': value.rowSpan,
        'selectionStatus': value.selectionStatus,
        'text': value.text,
        'textType': value.textType,
        'geometry': SerializableGeometryToJSON(value.geometry),
        'relationships': value.relationships === undefined ? undefined : ((value.relationships as Array<any>).map(SerializableRelationshipToJSON)),
        'id': value.id,
    };
}

