/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IdName } from './IdName';
import {
    IdNameFromJSON,
    IdNameFromJSONTyped,
    IdNameToJSON,
} from './IdName';

/**
 * 
 * @export
 * @interface PartTaxonomy
 */
export interface PartTaxonomy {
    /**
     * 
     * @type {IdName}
     * @memberof PartTaxonomy
     */
    category?: IdName;
    /**
     * 
     * @type {IdName}
     * @memberof PartTaxonomy
     */
    subcategory?: IdName;
    /**
     * 
     * @type {IdName}
     * @memberof PartTaxonomy
     */
    partType?: IdName;
}

/**
 * Check if a given object implements the PartTaxonomy interface.
 */
export function instanceOfPartTaxonomy(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PartTaxonomyFromJSON(json: any): PartTaxonomy {
    return PartTaxonomyFromJSONTyped(json, false);
}

export function PartTaxonomyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartTaxonomy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': !exists(json, 'category') ? undefined : IdNameFromJSON(json['category']),
        'subcategory': !exists(json, 'subcategory') ? undefined : IdNameFromJSON(json['subcategory']),
        'partType': !exists(json, 'partType') ? undefined : IdNameFromJSON(json['partType']),
    };
}

export function PartTaxonomyToJSON(value?: PartTaxonomy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': IdNameToJSON(value.category),
        'subcategory': IdNameToJSON(value.subcategory),
        'partType': IdNameToJSON(value.partType),
    };
}

