/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Taxonomy } from './Taxonomy';
import {
    TaxonomyFromJSON,
    TaxonomyFromJSONTyped,
    TaxonomyToJSON,
} from './Taxonomy';
import type { WorkTime } from './WorkTime';
import {
    WorkTimeFromJSON,
    WorkTimeFromJSONTyped,
    WorkTimeToJSON,
} from './WorkTime';

/**
 * 
 * @export
 * @interface LaborApplication
 */
export interface LaborApplication {
    /**
     * 
     * @type {number}
     * @memberof LaborApplication
     */
    id?: number;
    /**
     * 
     * @type {Taxonomy}
     * @memberof LaborApplication
     */
    taxonomy?: Taxonomy;
    /**
     * 
     * @type {string}
     * @memberof LaborApplication
     */
    positionName?: string;
    /**
     * 
     * @type {number}
     * @memberof LaborApplication
     */
    sumBaseLaborTime?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LaborApplication
     */
    baseLaborTimes?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LaborApplication
     */
    baseLaborTimeDesc?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LaborApplication
     */
    sumAllLaborTime?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LaborApplication
     */
    allLaborTimes?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LaborApplication
     */
    allLaborTimeDesc?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof LaborApplication
     */
    additionalLaborTimes?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LaborApplication
     */
    additionalLaborTimeDesc?: Array<string>;
    /**
     * 
     * @type {Array<WorkTime>}
     * @memberof LaborApplication
     */
    optionalWorkTimes?: Array<WorkTime>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LaborApplication
     */
    notes?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof LaborApplication
     */
    attributes?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LaborApplication
     */
    attributeDescriptors?: { [key: string]: string; };
}

/**
 * Check if a given object implements the LaborApplication interface.
 */
export function instanceOfLaborApplication(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LaborApplicationFromJSON(json: any): LaborApplication {
    return LaborApplicationFromJSONTyped(json, false);
}

export function LaborApplicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaborApplication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'taxonomy': !exists(json, 'taxonomy') ? undefined : TaxonomyFromJSON(json['taxonomy']),
        'positionName': !exists(json, 'positionName') ? undefined : json['positionName'],
        'sumBaseLaborTime': !exists(json, 'sumBaseLaborTime') ? undefined : json['sumBaseLaborTime'],
        'baseLaborTimes': !exists(json, 'baseLaborTimes') ? undefined : json['baseLaborTimes'],
        'baseLaborTimeDesc': !exists(json, 'baseLaborTimeDesc') ? undefined : json['baseLaborTimeDesc'],
        'sumAllLaborTime': !exists(json, 'sumAllLaborTime') ? undefined : json['sumAllLaborTime'],
        'allLaborTimes': !exists(json, 'allLaborTimes') ? undefined : json['allLaborTimes'],
        'allLaborTimeDesc': !exists(json, 'allLaborTimeDesc') ? undefined : json['allLaborTimeDesc'],
        'additionalLaborTimes': !exists(json, 'additionalLaborTimes') ? undefined : json['additionalLaborTimes'],
        'additionalLaborTimeDesc': !exists(json, 'additionalLaborTimeDesc') ? undefined : json['additionalLaborTimeDesc'],
        'optionalWorkTimes': !exists(json, 'optionalWorkTimes') ? undefined : ((json['optionalWorkTimes'] as Array<any>).map(WorkTimeFromJSON)),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
        'attributeDescriptors': !exists(json, 'attributeDescriptors') ? undefined : json['attributeDescriptors'],
    };
}

export function LaborApplicationToJSON(value?: LaborApplication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'taxonomy': TaxonomyToJSON(value.taxonomy),
        'positionName': value.positionName,
        'sumBaseLaborTime': value.sumBaseLaborTime,
        'baseLaborTimes': value.baseLaborTimes,
        'baseLaborTimeDesc': value.baseLaborTimeDesc,
        'sumAllLaborTime': value.sumAllLaborTime,
        'allLaborTimes': value.allLaborTimes,
        'allLaborTimeDesc': value.allLaborTimeDesc,
        'additionalLaborTimes': value.additionalLaborTimes,
        'additionalLaborTimeDesc': value.additionalLaborTimeDesc,
        'optionalWorkTimes': value.optionalWorkTimes === undefined ? undefined : ((value.optionalWorkTimes as Array<any>).map(WorkTimeToJSON)),
        'notes': value.notes,
        'attributes': value.attributes,
        'attributeDescriptors': value.attributeDescriptors,
    };
}

