/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InventoryAdjustmentInfo } from './InventoryAdjustmentInfo';
import {
    InventoryAdjustmentInfoFromJSON,
    InventoryAdjustmentInfoFromJSONTyped,
    InventoryAdjustmentInfoToJSON,
} from './InventoryAdjustmentInfo';

/**
 * 
 * @export
 * @interface UpdateInventoryInstanceWithAdjustmentRequest
 */
export interface UpdateInventoryInstanceWithAdjustmentRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateInventoryInstanceWithAdjustmentRequest
     */
    partId?: string;
    /**
     * 
     * @type {InventoryAdjustmentInfo}
     * @memberof UpdateInventoryInstanceWithAdjustmentRequest
     */
    adjustment?: InventoryAdjustmentInfo;
}

/**
 * Check if a given object implements the UpdateInventoryInstanceWithAdjustmentRequest interface.
 */
export function instanceOfUpdateInventoryInstanceWithAdjustmentRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateInventoryInstanceWithAdjustmentRequestFromJSON(json: any): UpdateInventoryInstanceWithAdjustmentRequest {
    return UpdateInventoryInstanceWithAdjustmentRequestFromJSONTyped(json, false);
}

export function UpdateInventoryInstanceWithAdjustmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateInventoryInstanceWithAdjustmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partId': !exists(json, 'partId') ? undefined : json['partId'],
        'adjustment': !exists(json, 'adjustment') ? undefined : InventoryAdjustmentInfoFromJSON(json['adjustment']),
    };
}

export function UpdateInventoryInstanceWithAdjustmentRequestToJSON(value?: UpdateInventoryInstanceWithAdjustmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partId': value.partId,
        'adjustment': InventoryAdjustmentInfoToJSON(value.adjustment),
    };
}

