/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentSettingsInfo
 */
export interface AppointmentSettingsInfo {
    /**
     * 
     * @type {string}
     * @memberof AppointmentSettingsInfo
     */
    shop?: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentSettingsInfo
     */
    duration?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentSettingsInfo
     */
    startIncrements?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentSettingsInfo
     */
    calendarDays?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentSettingsInfo
     */
    weekdays?: number;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentSettingsInfo
     */
    startDateInclusive?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentSettingsInfo
     */
    endDateInclusive?: Date;
}

/**
 * Check if a given object implements the AppointmentSettingsInfo interface.
 */
export function instanceOfAppointmentSettingsInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AppointmentSettingsInfoFromJSON(json: any): AppointmentSettingsInfo {
    return AppointmentSettingsInfoFromJSONTyped(json, false);
}

export function AppointmentSettingsInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentSettingsInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'startIncrements': !exists(json, 'startIncrements') ? undefined : json['startIncrements'],
        'calendarDays': !exists(json, 'calendarDays') ? undefined : json['calendarDays'],
        'weekdays': !exists(json, 'weekdays') ? undefined : json['weekdays'],
        'startDateInclusive': !exists(json, 'startDateInclusive') ? undefined : (new Date(json['startDateInclusive'])),
        'endDateInclusive': !exists(json, 'endDateInclusive') ? undefined : (new Date(json['endDateInclusive'])),
    };
}

export function AppointmentSettingsInfoToJSON(value?: AppointmentSettingsInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'duration': value.duration,
        'startIncrements': value.startIncrements,
        'calendarDays': value.calendarDays,
        'weekdays': value.weekdays,
        'startDateInclusive': value.startDateInclusive === undefined ? undefined : (value.startDateInclusive.toISOString().substr(0,10)),
        'endDateInclusive': value.endDateInclusive === undefined ? undefined : (value.endDateInclusive.toISOString().substr(0,10)),
    };
}

