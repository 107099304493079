/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InventoryAdjustmentDocumentInfo } from './InventoryAdjustmentDocumentInfo';
import {
    InventoryAdjustmentDocumentInfoFromJSON,
    InventoryAdjustmentDocumentInfoFromJSONTyped,
    InventoryAdjustmentDocumentInfoToJSON,
} from './InventoryAdjustmentDocumentInfo';

/**
 * 
 * @export
 * @interface InventoryAdjustmentDocumentEntry
 */
export interface InventoryAdjustmentDocumentEntry {
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentDocumentEntry
     */
    id?: string;
    /**
     * 
     * @type {InventoryAdjustmentDocumentInfo}
     * @memberof InventoryAdjustmentDocumentEntry
     */
    info?: InventoryAdjustmentDocumentInfo;
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentDocumentEntry
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryAdjustmentDocumentEntry
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentDocumentEntry
     */
    updatedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryAdjustmentDocumentEntry
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the InventoryAdjustmentDocumentEntry interface.
 */
export function instanceOfInventoryAdjustmentDocumentEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InventoryAdjustmentDocumentEntryFromJSON(json: any): InventoryAdjustmentDocumentEntry {
    return InventoryAdjustmentDocumentEntryFromJSONTyped(json, false);
}

export function InventoryAdjustmentDocumentEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryAdjustmentDocumentEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : InventoryAdjustmentDocumentInfoFromJSON(json['info']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}

export function InventoryAdjustmentDocumentEntryToJSON(value?: InventoryAdjustmentDocumentEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': InventoryAdjustmentDocumentInfoToJSON(value.info),
        'createdBy': value.createdBy,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedBy': value.updatedBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}

