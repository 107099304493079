/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CatalogLaborMatrixEntry } from './CatalogLaborMatrixEntry';
import {
    CatalogLaborMatrixEntryFromJSON,
    CatalogLaborMatrixEntryFromJSONTyped,
    CatalogLaborMatrixEntryToJSON,
} from './CatalogLaborMatrixEntry';
import type { CustomFeeEntry } from './CustomFeeEntry';
import {
    CustomFeeEntryFromJSON,
    CustomFeeEntryFromJSONTyped,
    CustomFeeEntryToJSON,
} from './CustomFeeEntry';
import type { DiscountEntry } from './DiscountEntry';
import {
    DiscountEntryFromJSON,
    DiscountEntryFromJSONTyped,
    DiscountEntryToJSON,
} from './DiscountEntry';
import type { InventoryPartInstanceEntry } from './InventoryPartInstanceEntry';
import {
    InventoryPartInstanceEntryFromJSON,
    InventoryPartInstanceEntryFromJSONTyped,
    InventoryPartInstanceEntryToJSON,
} from './InventoryPartInstanceEntry';
import type { LaborApplication } from './LaborApplication';
import {
    LaborApplicationFromJSON,
    LaborApplicationFromJSONTyped,
    LaborApplicationToJSON,
} from './LaborApplication';
import type { LaborOverride } from './LaborOverride';
import {
    LaborOverrideFromJSON,
    LaborOverrideFromJSONTyped,
    LaborOverrideToJSON,
} from './LaborOverride';
import type { PartEntry } from './PartEntry';
import {
    PartEntryFromJSON,
    PartEntryFromJSONTyped,
    PartEntryToJSON,
} from './PartEntry';
import type { PartOverride } from './PartOverride';
import {
    PartOverrideFromJSON,
    PartOverrideFromJSONTyped,
    PartOverrideToJSON,
} from './PartOverride';
import type { RepairLinkPartInCartExpanded } from './RepairLinkPartInCartExpanded';
import {
    RepairLinkPartInCartExpandedFromJSON,
    RepairLinkPartInCartExpandedFromJSONTyped,
    RepairLinkPartInCartExpandedToJSON,
} from './RepairLinkPartInCartExpanded';
import type { ServiceEntry } from './ServiceEntry';
import {
    ServiceEntryFromJSON,
    ServiceEntryFromJSONTyped,
    ServiceEntryToJSON,
} from './ServiceEntry';
import type { SubletEntry } from './SubletEntry';
import {
    SubletEntryFromJSON,
    SubletEntryFromJSONTyped,
    SubletEntryToJSON,
} from './SubletEntry';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
} from './UserProfile';
import type { WarrantyEntry } from './WarrantyEntry';
import {
    WarrantyEntryFromJSON,
    WarrantyEntryFromJSONTyped,
    WarrantyEntryToJSON,
} from './WarrantyEntry';

/**
 * 
 * @export
 * @interface ServiceExpanded
 */
export interface ServiceExpanded {
    /**
     * 
     * @type {ServiceEntry}
     * @memberof ServiceExpanded
     */
    entry?: ServiceEntry;
    /**
     * 
     * @type {{ [key: string]: LaborApplication; }}
     * @memberof ServiceExpanded
     */
    motorApplications?: { [key: string]: LaborApplication; };
    /**
     * 
     * @type {{ [key: string]: CatalogLaborMatrixEntry; }}
     * @memberof ServiceExpanded
     */
    catalogLaborMatrixEntries?: { [key: string]: CatalogLaborMatrixEntry; };
    /**
     * 
     * @type {Array<LaborOverride>}
     * @memberof ServiceExpanded
     */
    laborOverrides?: Array<LaborOverride>;
    /**
     * 
     * @type {Array<UserProfile>}
     * @memberof ServiceExpanded
     */
    techs?: Array<UserProfile>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ServiceExpanded
     */
    serviceTechFractionalCredit?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ServiceExpanded
     */
    laborOverrideTechFractionalCredit?: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {Array<CustomFeeEntry>}
     * @memberof ServiceExpanded
     */
    customFees?: Array<CustomFeeEntry>;
    /**
     * 
     * @type {Array<PartEntry>}
     * @memberof ServiceExpanded
     */
    partsInCart?: Array<PartEntry>;
    /**
     * 
     * @type {Array<PartEntry>}
     * @memberof ServiceExpanded
     */
    orderedParts?: Array<PartEntry>;
    /**
     * 
     * @type {Array<RepairLinkPartInCartExpanded>}
     * @memberof ServiceExpanded
     */
    repairLinkParts?: Array<RepairLinkPartInCartExpanded>;
    /**
     * 
     * @type {Array<PartOverride>}
     * @memberof ServiceExpanded
     */
    partOverrides?: Array<PartOverride>;
    /**
     * 
     * @type {Array<SubletEntry>}
     * @memberof ServiceExpanded
     */
    sublets?: Array<SubletEntry>;
    /**
     * 
     * @type {{ [key: string]: Array<InventoryPartInstanceEntry>; }}
     * @memberof ServiceExpanded
     */
    partOverrideToInventory?: { [key: string]: Array<InventoryPartInstanceEntry>; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: InventoryPartInstanceEntry; }; }}
     * @memberof ServiceExpanded
     */
    partOverrideToLinkIdInventory?: { [key: string]: { [key: string]: InventoryPartInstanceEntry; }; };
    /**
     * 
     * @type {number}
     * @memberof ServiceExpanded
     */
    laborPrice?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ServiceExpanded
     */
    laborRetailPrices?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof ServiceExpanded
     */
    partsRetailPrice?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ServiceExpanded
     */
    partRetailPrices?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof ServiceExpanded
     */
    cachedRetailPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceExpanded
     */
    customFee?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceExpanded
     */
    shopDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceExpanded
     */
    customDiscount?: number;
    /**
     * 
     * @type {DiscountEntry}
     * @memberof ServiceExpanded
     */
    serviceDiscount?: DiscountEntry;
    /**
     * 
     * @type {{ [key: string]: WarrantyEntry; }}
     * @memberof ServiceExpanded
     */
    partToWarranty?: { [key: string]: WarrantyEntry; };
    /**
     * 
     * @type {{ [key: string]: WarrantyEntry; }}
     * @memberof ServiceExpanded
     */
    laborToWarranty?: { [key: string]: WarrantyEntry; };
    /**
     * 
     * @type {number}
     * @memberof ServiceExpanded
     */
    partWarrantyTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof ServiceExpanded
     */
    laborWarrantyTotal?: number;
}

/**
 * Check if a given object implements the ServiceExpanded interface.
 */
export function instanceOfServiceExpanded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceExpandedFromJSON(json: any): ServiceExpanded {
    return ServiceExpandedFromJSONTyped(json, false);
}

export function ServiceExpandedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceExpanded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': !exists(json, 'entry') ? undefined : ServiceEntryFromJSON(json['entry']),
        'motorApplications': !exists(json, 'motorApplications') ? undefined : (mapValues(json['motorApplications'], LaborApplicationFromJSON)),
        'catalogLaborMatrixEntries': !exists(json, 'catalogLaborMatrixEntries') ? undefined : (mapValues(json['catalogLaborMatrixEntries'], CatalogLaborMatrixEntryFromJSON)),
        'laborOverrides': !exists(json, 'laborOverrides') ? undefined : ((json['laborOverrides'] as Array<any>).map(LaborOverrideFromJSON)),
        'techs': !exists(json, 'techs') ? undefined : ((json['techs'] as Array<any>).map(UserProfileFromJSON)),
        'serviceTechFractionalCredit': !exists(json, 'serviceTechFractionalCredit') ? undefined : json['serviceTechFractionalCredit'],
        'laborOverrideTechFractionalCredit': !exists(json, 'laborOverrideTechFractionalCredit') ? undefined : json['laborOverrideTechFractionalCredit'],
        'customFees': !exists(json, 'customFees') ? undefined : ((json['customFees'] as Array<any>).map(CustomFeeEntryFromJSON)),
        'partsInCart': !exists(json, 'partsInCart') ? undefined : ((json['partsInCart'] as Array<any>).map(PartEntryFromJSON)),
        'orderedParts': !exists(json, 'orderedParts') ? undefined : ((json['orderedParts'] as Array<any>).map(PartEntryFromJSON)),
        'repairLinkParts': !exists(json, 'repairLinkParts') ? undefined : ((json['repairLinkParts'] as Array<any>).map(RepairLinkPartInCartExpandedFromJSON)),
        'partOverrides': !exists(json, 'partOverrides') ? undefined : ((json['partOverrides'] as Array<any>).map(PartOverrideFromJSON)),
        'sublets': !exists(json, 'sublets') ? undefined : ((json['sublets'] as Array<any>).map(SubletEntryFromJSON)),
        'partOverrideToInventory': !exists(json, 'partOverrideToInventory') ? undefined : json['partOverrideToInventory'],
        'partOverrideToLinkIdInventory': !exists(json, 'partOverrideToLinkIdInventory') ? undefined : json['partOverrideToLinkIdInventory'],
        'laborPrice': !exists(json, 'laborPrice') ? undefined : json['laborPrice'],
        'laborRetailPrices': !exists(json, 'laborRetailPrices') ? undefined : json['laborRetailPrices'],
        'partsRetailPrice': !exists(json, 'partsRetailPrice') ? undefined : json['partsRetailPrice'],
        'partRetailPrices': !exists(json, 'partRetailPrices') ? undefined : json['partRetailPrices'],
        'cachedRetailPrice': !exists(json, 'cachedRetailPrice') ? undefined : json['cachedRetailPrice'],
        'customFee': !exists(json, 'customFee') ? undefined : json['customFee'],
        'shopDiscount': !exists(json, 'shopDiscount') ? undefined : json['shopDiscount'],
        'customDiscount': !exists(json, 'customDiscount') ? undefined : json['customDiscount'],
        'serviceDiscount': !exists(json, 'serviceDiscount') ? undefined : DiscountEntryFromJSON(json['serviceDiscount']),
        'partToWarranty': !exists(json, 'partToWarranty') ? undefined : (mapValues(json['partToWarranty'], WarrantyEntryFromJSON)),
        'laborToWarranty': !exists(json, 'laborToWarranty') ? undefined : (mapValues(json['laborToWarranty'], WarrantyEntryFromJSON)),
        'partWarrantyTotal': !exists(json, 'partWarrantyTotal') ? undefined : json['partWarrantyTotal'],
        'laborWarrantyTotal': !exists(json, 'laborWarrantyTotal') ? undefined : json['laborWarrantyTotal'],
    };
}

export function ServiceExpandedToJSON(value?: ServiceExpanded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': ServiceEntryToJSON(value.entry),
        'motorApplications': value.motorApplications === undefined ? undefined : (mapValues(value.motorApplications, LaborApplicationToJSON)),
        'catalogLaborMatrixEntries': value.catalogLaborMatrixEntries === undefined ? undefined : (mapValues(value.catalogLaborMatrixEntries, CatalogLaborMatrixEntryToJSON)),
        'laborOverrides': value.laborOverrides === undefined ? undefined : ((value.laborOverrides as Array<any>).map(LaborOverrideToJSON)),
        'techs': value.techs === undefined ? undefined : ((value.techs as Array<any>).map(UserProfileToJSON)),
        'serviceTechFractionalCredit': value.serviceTechFractionalCredit,
        'laborOverrideTechFractionalCredit': value.laborOverrideTechFractionalCredit,
        'customFees': value.customFees === undefined ? undefined : ((value.customFees as Array<any>).map(CustomFeeEntryToJSON)),
        'partsInCart': value.partsInCart === undefined ? undefined : ((value.partsInCart as Array<any>).map(PartEntryToJSON)),
        'orderedParts': value.orderedParts === undefined ? undefined : ((value.orderedParts as Array<any>).map(PartEntryToJSON)),
        'repairLinkParts': value.repairLinkParts === undefined ? undefined : ((value.repairLinkParts as Array<any>).map(RepairLinkPartInCartExpandedToJSON)),
        'partOverrides': value.partOverrides === undefined ? undefined : ((value.partOverrides as Array<any>).map(PartOverrideToJSON)),
        'sublets': value.sublets === undefined ? undefined : ((value.sublets as Array<any>).map(SubletEntryToJSON)),
        'partOverrideToInventory': value.partOverrideToInventory,
        'partOverrideToLinkIdInventory': value.partOverrideToLinkIdInventory,
        'laborPrice': value.laborPrice,
        'laborRetailPrices': value.laborRetailPrices,
        'partsRetailPrice': value.partsRetailPrice,
        'partRetailPrices': value.partRetailPrices,
        'cachedRetailPrice': value.cachedRetailPrice,
        'customFee': value.customFee,
        'shopDiscount': value.shopDiscount,
        'customDiscount': value.customDiscount,
        'serviceDiscount': DiscountEntryToJSON(value.serviceDiscount),
        'partToWarranty': value.partToWarranty === undefined ? undefined : (mapValues(value.partToWarranty, WarrantyEntryToJSON)),
        'laborToWarranty': value.laborToWarranty === undefined ? undefined : (mapValues(value.laborToWarranty, WarrantyEntryToJSON)),
        'partWarrantyTotal': value.partWarrantyTotal,
        'laborWarrantyTotal': value.laborWarrantyTotal,
    };
}

