/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PunchoutInfo
 */
export interface PunchoutInfo {
    /**
     * 
     * @type {string}
     * @memberof PunchoutInfo
     */
    redirectURL?: string;
    /**
     * 
     * @type {string}
     * @memberof PunchoutInfo
     */
    sessionID?: string;
}

/**
 * Check if a given object implements the PunchoutInfo interface.
 */
export function instanceOfPunchoutInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PunchoutInfoFromJSON(json: any): PunchoutInfo {
    return PunchoutInfoFromJSONTyped(json, false);
}

export function PunchoutInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PunchoutInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'redirectURL': !exists(json, 'redirectURL') ? undefined : json['redirectURL'],
        'sessionID': !exists(json, 'sessionID') ? undefined : json['sessionID'],
    };
}

export function PunchoutInfoToJSON(value?: PunchoutInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'redirectURL': value.redirectURL,
        'sessionID': value.sessionID,
    };
}

