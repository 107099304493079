/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SingleMaintenanceSchedule } from './SingleMaintenanceSchedule';
import {
    SingleMaintenanceScheduleFromJSON,
    SingleMaintenanceScheduleFromJSONTyped,
    SingleMaintenanceScheduleToJSON,
} from './SingleMaintenanceSchedule';

/**
 * 
 * @export
 * @interface IntervalMaintenanceSchedule
 */
export interface IntervalMaintenanceSchedule {
    /**
     * 
     * @type {number}
     * @memberof IntervalMaintenanceSchedule
     */
    interval?: number;
    /**
     * 
     * @type {Array<SingleMaintenanceSchedule>}
     * @memberof IntervalMaintenanceSchedule
     */
    maintenanceSchedules?: Array<SingleMaintenanceSchedule>;
}

/**
 * Check if a given object implements the IntervalMaintenanceSchedule interface.
 */
export function instanceOfIntervalMaintenanceSchedule(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IntervalMaintenanceScheduleFromJSON(json: any): IntervalMaintenanceSchedule {
    return IntervalMaintenanceScheduleFromJSONTyped(json, false);
}

export function IntervalMaintenanceScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntervalMaintenanceSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
        'maintenanceSchedules': !exists(json, 'maintenanceSchedules') ? undefined : ((json['maintenanceSchedules'] as Array<any>).map(SingleMaintenanceScheduleFromJSON)),
    };
}

export function IntervalMaintenanceScheduleToJSON(value?: IntervalMaintenanceSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interval': value.interval,
        'maintenanceSchedules': value.maintenanceSchedules === undefined ? undefined : ((value.maintenanceSchedules as Array<any>).map(SingleMaintenanceScheduleToJSON)),
    };
}

