/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RepairLinkPartInCartExpandedChecked } from './RepairLinkPartInCartExpandedChecked';
import {
    RepairLinkPartInCartExpandedCheckedFromJSON,
    RepairLinkPartInCartExpandedCheckedFromJSONTyped,
    RepairLinkPartInCartExpandedCheckedToJSON,
} from './RepairLinkPartInCartExpandedChecked';

/**
 * 
 * @export
 * @interface UpdateOrderRepairLinkRequest
 */
export interface UpdateOrderRepairLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderRepairLinkRequest
     */
    service?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderRepairLinkRequest
     */
    accessToken?: string;
    /**
     * 
     * @type {Array<RepairLinkPartInCartExpandedChecked>}
     * @memberof UpdateOrderRepairLinkRequest
     */
    checkedParts?: Array<RepairLinkPartInCartExpandedChecked>;
}

/**
 * Check if a given object implements the UpdateOrderRepairLinkRequest interface.
 */
export function instanceOfUpdateOrderRepairLinkRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateOrderRepairLinkRequestFromJSON(json: any): UpdateOrderRepairLinkRequest {
    return UpdateOrderRepairLinkRequestFromJSONTyped(json, false);
}

export function UpdateOrderRepairLinkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOrderRepairLinkRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'service': !exists(json, 'service') ? undefined : json['service'],
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'checkedParts': !exists(json, 'checkedParts') ? undefined : ((json['checkedParts'] as Array<any>).map(RepairLinkPartInCartExpandedCheckedFromJSON)),
    };
}

export function UpdateOrderRepairLinkRequestToJSON(value?: UpdateOrderRepairLinkRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service': value.service,
        'accessToken': value.accessToken,
        'checkedParts': value.checkedParts === undefined ? undefined : ((value.checkedParts as Array<any>).map(RepairLinkPartInCartExpandedCheckedToJSON)),
    };
}

