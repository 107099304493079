/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InventoryPartInstanceEntry } from './InventoryPartInstanceEntry';
import {
    InventoryPartInstanceEntryFromJSON,
    InventoryPartInstanceEntryFromJSONTyped,
    InventoryPartInstanceEntryToJSON,
} from './InventoryPartInstanceEntry';
import type { RepairOrderEntry } from './RepairOrderEntry';
import {
    RepairOrderEntryFromJSON,
    RepairOrderEntryFromJSONTyped,
    RepairOrderEntryToJSON,
} from './RepairOrderEntry';

/**
 * 
 * @export
 * @interface InventoryPartInstanceExpanded
 */
export interface InventoryPartInstanceExpanded {
    /**
     * 
     * @type {InventoryPartInstanceEntry}
     * @memberof InventoryPartInstanceExpanded
     */
    entry?: InventoryPartInstanceEntry;
    /**
     * 
     * @type {RepairOrderEntry}
     * @memberof InventoryPartInstanceExpanded
     */
    repairOrder?: RepairOrderEntry;
}

/**
 * Check if a given object implements the InventoryPartInstanceExpanded interface.
 */
export function instanceOfInventoryPartInstanceExpanded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InventoryPartInstanceExpandedFromJSON(json: any): InventoryPartInstanceExpanded {
    return InventoryPartInstanceExpandedFromJSONTyped(json, false);
}

export function InventoryPartInstanceExpandedFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryPartInstanceExpanded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': !exists(json, 'entry') ? undefined : InventoryPartInstanceEntryFromJSON(json['entry']),
        'repairOrder': !exists(json, 'repairOrder') ? undefined : RepairOrderEntryFromJSON(json['repairOrder']),
    };
}

export function InventoryPartInstanceExpandedToJSON(value?: InventoryPartInstanceExpanded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': InventoryPartInstanceEntryToJSON(value.entry),
        'repairOrder': RepairOrderEntryToJSON(value.repairOrder),
    };
}

