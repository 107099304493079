/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CannedJobCustomFeeInfo
 */
export interface CannedJobCustomFeeInfo {
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomFeeInfo
     */
    cannedJob?: string;
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomFeeInfo
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CannedJobCustomFeeInfo
     */
    numDollars?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CannedJobCustomFeeInfo
     */
    taxable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomFeeInfo
     */
    parentRow?: string;
}

/**
 * Check if a given object implements the CannedJobCustomFeeInfo interface.
 */
export function instanceOfCannedJobCustomFeeInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CannedJobCustomFeeInfoFromJSON(json: any): CannedJobCustomFeeInfo {
    return CannedJobCustomFeeInfoFromJSONTyped(json, false);
}

export function CannedJobCustomFeeInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CannedJobCustomFeeInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cannedJob': !exists(json, 'cannedJob') ? undefined : json['cannedJob'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'numDollars': !exists(json, 'numDollars') ? undefined : json['numDollars'],
        'taxable': !exists(json, 'taxable') ? undefined : json['taxable'],
        'parentRow': !exists(json, 'parent_row') ? undefined : json['parent_row'],
    };
}

export function CannedJobCustomFeeInfoToJSON(value?: CannedJobCustomFeeInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cannedJob': value.cannedJob,
        'name': value.name,
        'numDollars': value.numDollars,
        'taxable': value.taxable,
        'parent_row': value.parentRow,
    };
}

