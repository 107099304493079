/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VendorInfo
 */
export interface VendorInfo {
    /**
     * 
     * @type {string}
     * @memberof VendorInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof VendorInfo
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof VendorInfo
     */
    partsTechId?: number;
    /**
     * 
     * @type {string}
     * @memberof VendorInfo
     */
    accountNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof VendorInfo
     */
    archivedOn?: Date;
}

/**
 * Check if a given object implements the VendorInfo interface.
 */
export function instanceOfVendorInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VendorInfoFromJSON(json: any): VendorInfo {
    return VendorInfoFromJSONTyped(json, false);
}

export function VendorInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'partsTechId': !exists(json, 'partsTechId') ? undefined : json['partsTechId'],
        'accountNumber': !exists(json, 'account_number') ? undefined : json['account_number'],
        'archivedOn': !exists(json, 'archived_on') ? undefined : (new Date(json['archived_on'])),
    };
}

export function VendorInfoToJSON(value?: VendorInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'name': value.name,
        'partsTechId': value.partsTechId,
        'account_number': value.accountNumber,
        'archived_on': value.archivedOn === undefined ? undefined : (value.archivedOn.toISOString()),
    };
}

