/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SerializableBlock } from './SerializableBlock';
import {
    SerializableBlockFromJSON,
    SerializableBlockFromJSONTyped,
    SerializableBlockToJSON,
} from './SerializableBlock';

/**
 * 
 * @export
 * @interface PartInvoiceAnalysisResponse
 */
export interface PartInvoiceAnalysisResponse {
    /**
     * 
     * @type {Array<SerializableBlock>}
     * @memberof PartInvoiceAnalysisResponse
     */
    blocks?: Array<SerializableBlock>;
    /**
     * 
     * @type {string}
     * @memberof PartInvoiceAnalysisResponse
     */
    fillTableResponse?: string;
}

/**
 * Check if a given object implements the PartInvoiceAnalysisResponse interface.
 */
export function instanceOfPartInvoiceAnalysisResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PartInvoiceAnalysisResponseFromJSON(json: any): PartInvoiceAnalysisResponse {
    return PartInvoiceAnalysisResponseFromJSONTyped(json, false);
}

export function PartInvoiceAnalysisResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartInvoiceAnalysisResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blocks': !exists(json, 'blocks') ? undefined : ((json['blocks'] as Array<any>).map(SerializableBlockFromJSON)),
        'fillTableResponse': !exists(json, 'fillTableResponse') ? undefined : json['fillTableResponse'],
    };
}

export function PartInvoiceAnalysisResponseToJSON(value?: PartInvoiceAnalysisResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blocks': value.blocks === undefined ? undefined : ((value.blocks as Array<any>).map(SerializableBlockToJSON)),
        'fillTableResponse': value.fillTableResponse,
    };
}

