/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkUpdateRepairOrderStatusRequest
 */
export interface BulkUpdateRepairOrderStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateRepairOrderStatusRequest
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateRepairOrderStatusRequest
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateRepairOrderStatusRequest
     */
    to?: string;
}

/**
 * Check if a given object implements the BulkUpdateRepairOrderStatusRequest interface.
 */
export function instanceOfBulkUpdateRepairOrderStatusRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BulkUpdateRepairOrderStatusRequestFromJSON(json: any): BulkUpdateRepairOrderStatusRequest {
    return BulkUpdateRepairOrderStatusRequestFromJSONTyped(json, false);
}

export function BulkUpdateRepairOrderStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdateRepairOrderStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
    };
}

export function BulkUpdateRepairOrderStatusRequestToJSON(value?: BulkUpdateRepairOrderStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'from': value.from,
        'to': value.to,
    };
}

