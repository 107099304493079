/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CounterSaleEntry } from './CounterSaleEntry';
import {
    CounterSaleEntryFromJSON,
    CounterSaleEntryFromJSONTyped,
    CounterSaleEntryToJSON,
} from './CounterSaleEntry';
import type { CounterSalePricing } from './CounterSalePricing';
import {
    CounterSalePricingFromJSON,
    CounterSalePricingFromJSONTyped,
    CounterSalePricingToJSON,
} from './CounterSalePricing';

/**
 * 
 * @export
 * @interface CounterSaleExpanded
 */
export interface CounterSaleExpanded {
    /**
     * 
     * @type {CounterSaleEntry}
     * @memberof CounterSaleExpanded
     */
    entry?: CounterSaleEntry;
    /**
     * 
     * @type {CounterSalePricing}
     * @memberof CounterSaleExpanded
     */
    pricing?: CounterSalePricing;
}

/**
 * Check if a given object implements the CounterSaleExpanded interface.
 */
export function instanceOfCounterSaleExpanded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CounterSaleExpandedFromJSON(json: any): CounterSaleExpanded {
    return CounterSaleExpandedFromJSONTyped(json, false);
}

export function CounterSaleExpandedFromJSONTyped(json: any, ignoreDiscriminator: boolean): CounterSaleExpanded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': !exists(json, 'entry') ? undefined : CounterSaleEntryFromJSON(json['entry']),
        'pricing': !exists(json, 'pricing') ? undefined : CounterSalePricingFromJSON(json['pricing']),
    };
}

export function CounterSaleExpandedToJSON(value?: CounterSaleExpanded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': CounterSaleEntryToJSON(value.entry),
        'pricing': CounterSalePricingToJSON(value.pricing),
    };
}

