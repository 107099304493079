/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateCustomerIntakeRequestInfo
 */
export interface CreateCustomerIntakeRequestInfo {
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerIntakeRequestInfo
     */
    shopId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerIntakeRequestInfo
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerIntakeRequestInfo
     */
    vehicleId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerIntakeRequestInfo
     */
    signatureDataUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerIntakeRequestInfo
     */
    concern?: string;
}

/**
 * Check if a given object implements the CreateCustomerIntakeRequestInfo interface.
 */
export function instanceOfCreateCustomerIntakeRequestInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateCustomerIntakeRequestInfoFromJSON(json: any): CreateCustomerIntakeRequestInfo {
    return CreateCustomerIntakeRequestInfoFromJSONTyped(json, false);
}

export function CreateCustomerIntakeRequestInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCustomerIntakeRequestInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shopId': !exists(json, 'shopId') ? undefined : json['shopId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'vehicleId': !exists(json, 'vehicleId') ? undefined : json['vehicleId'],
        'signatureDataUrl': !exists(json, 'signatureDataUrl') ? undefined : json['signatureDataUrl'],
        'concern': !exists(json, 'concern') ? undefined : json['concern'],
    };
}

export function CreateCustomerIntakeRequestInfoToJSON(value?: CreateCustomerIntakeRequestInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shopId': value.shopId,
        'customerId': value.customerId,
        'vehicleId': value.vehicleId,
        'signatureDataUrl': value.signatureDataUrl,
        'concern': value.concern,
    };
}

