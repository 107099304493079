/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CannedJobEntry } from './CannedJobEntry';
import {
    CannedJobEntryFromJSON,
    CannedJobEntryFromJSONTyped,
    CannedJobEntryToJSON,
} from './CannedJobEntry';
import type { CustomerEntry } from './CustomerEntry';
import {
    CustomerEntryFromJSON,
    CustomerEntryFromJSONTyped,
    CustomerEntryToJSON,
} from './CustomerEntry';
import type { InventoryPartEntry } from './InventoryPartEntry';
import {
    InventoryPartEntryFromJSON,
    InventoryPartEntryFromJSONTyped,
    InventoryPartEntryToJSON,
} from './InventoryPartEntry';

/**
 * 
 * @export
 * @interface PartsMatrixUsers
 */
export interface PartsMatrixUsers {
    /**
     * 
     * @type {Array<CannedJobEntry>}
     * @memberof PartsMatrixUsers
     */
    cannedJobs?: Array<CannedJobEntry>;
    /**
     * 
     * @type {Array<CustomerEntry>}
     * @memberof PartsMatrixUsers
     */
    customers?: Array<CustomerEntry>;
    /**
     * 
     * @type {Array<InventoryPartEntry>}
     * @memberof PartsMatrixUsers
     */
    inventoryParts?: Array<InventoryPartEntry>;
}

/**
 * Check if a given object implements the PartsMatrixUsers interface.
 */
export function instanceOfPartsMatrixUsers(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PartsMatrixUsersFromJSON(json: any): PartsMatrixUsers {
    return PartsMatrixUsersFromJSONTyped(json, false);
}

export function PartsMatrixUsersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartsMatrixUsers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cannedJobs': !exists(json, 'cannedJobs') ? undefined : ((json['cannedJobs'] as Array<any>).map(CannedJobEntryFromJSON)),
        'customers': !exists(json, 'customers') ? undefined : ((json['customers'] as Array<any>).map(CustomerEntryFromJSON)),
        'inventoryParts': !exists(json, 'inventoryParts') ? undefined : ((json['inventoryParts'] as Array<any>).map(InventoryPartEntryFromJSON)),
    };
}

export function PartsMatrixUsersToJSON(value?: PartsMatrixUsers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cannedJobs': value.cannedJobs === undefined ? undefined : ((value.cannedJobs as Array<any>).map(CannedJobEntryToJSON)),
        'customers': value.customers === undefined ? undefined : ((value.customers as Array<any>).map(CustomerEntryToJSON)),
        'inventoryParts': value.inventoryParts === undefined ? undefined : ((value.inventoryParts as Array<any>).map(InventoryPartEntryToJSON)),
    };
}

