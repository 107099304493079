/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MessageContent
 */
export interface MessageContent {
    /**
     * 
     * @type {string}
     * @memberof MessageContent
     */
    sender?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageContent
     */
    content?: string;
}

/**
 * Check if a given object implements the MessageContent interface.
 */
export function instanceOfMessageContent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MessageContentFromJSON(json: any): MessageContent {
    return MessageContentFromJSONTyped(json, false);
}

export function MessageContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sender': !exists(json, 'sender') ? undefined : json['sender'],
        'content': !exists(json, 'content') ? undefined : json['content'],
    };
}

export function MessageContentToJSON(value?: MessageContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sender': value.sender,
        'content': value.content,
    };
}

