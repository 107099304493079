/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GenerateApiKeyWithIntentRequest
 */
export interface GenerateApiKeyWithIntentRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateApiKeyWithIntentRequest
     */
    repairOrderId?: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateApiKeyWithIntentRequest
     */
    intent?: GenerateApiKeyWithIntentRequestIntentEnum;
    /**
     * 
     * @type {boolean}
     * @memberof GenerateApiKeyWithIntentRequest
     */
    showPartsLabor?: boolean;
}


/**
 * @export
 */
export const GenerateApiKeyWithIntentRequestIntentEnum = {
    Authorization: 'authorization',
    TextToPay: 'text_to_pay',
    Feedback: 'feedback',
    Inspection: 'inspection'
} as const;
export type GenerateApiKeyWithIntentRequestIntentEnum = typeof GenerateApiKeyWithIntentRequestIntentEnum[keyof typeof GenerateApiKeyWithIntentRequestIntentEnum];


/**
 * Check if a given object implements the GenerateApiKeyWithIntentRequest interface.
 */
export function instanceOfGenerateApiKeyWithIntentRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GenerateApiKeyWithIntentRequestFromJSON(json: any): GenerateApiKeyWithIntentRequest {
    return GenerateApiKeyWithIntentRequestFromJSONTyped(json, false);
}

export function GenerateApiKeyWithIntentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateApiKeyWithIntentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'repairOrderId': !exists(json, 'repairOrderId') ? undefined : json['repairOrderId'],
        'intent': !exists(json, 'intent') ? undefined : json['intent'],
        'showPartsLabor': !exists(json, 'showPartsLabor') ? undefined : json['showPartsLabor'],
    };
}

export function GenerateApiKeyWithIntentRequestToJSON(value?: GenerateApiKeyWithIntentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'repairOrderId': value.repairOrderId,
        'intent': value.intent,
        'showPartsLabor': value.showPartsLabor,
    };
}

