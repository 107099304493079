/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CarfaxDisplayRecord } from './CarfaxDisplayRecord';
import {
    CarfaxDisplayRecordFromJSON,
    CarfaxDisplayRecordFromJSONTyped,
    CarfaxDisplayRecordToJSON,
} from './CarfaxDisplayRecord';
import type { CarfaxServiceCategory } from './CarfaxServiceCategory';
import {
    CarfaxServiceCategoryFromJSON,
    CarfaxServiceCategoryFromJSONTyped,
    CarfaxServiceCategoryToJSON,
} from './CarfaxServiceCategory';

/**
 * 
 * @export
 * @interface ServiceHistory
 */
export interface ServiceHistory {
    /**
     * 
     * @type {Array<CarfaxServiceCategory>}
     * @memberof ServiceHistory
     */
    carfaxServiceCategories?: Array<CarfaxServiceCategory>;
    /**
     * 
     * @type {Array<CarfaxDisplayRecord>}
     * @memberof ServiceHistory
     */
    carfaxDisplayRecords?: Array<CarfaxDisplayRecord>;
}

/**
 * Check if a given object implements the ServiceHistory interface.
 */
export function instanceOfServiceHistory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ServiceHistoryFromJSON(json: any): ServiceHistory {
    return ServiceHistoryFromJSONTyped(json, false);
}

export function ServiceHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'carfaxServiceCategories': !exists(json, 'carfaxServiceCategories') ? undefined : ((json['carfaxServiceCategories'] as Array<any>).map(CarfaxServiceCategoryFromJSON)),
        'carfaxDisplayRecords': !exists(json, 'carfaxDisplayRecords') ? undefined : ((json['carfaxDisplayRecords'] as Array<any>).map(CarfaxDisplayRecordFromJSON)),
    };
}

export function ServiceHistoryToJSON(value?: ServiceHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'carfaxServiceCategories': value.carfaxServiceCategories === undefined ? undefined : ((value.carfaxServiceCategories as Array<any>).map(CarfaxServiceCategoryToJSON)),
        'carfaxDisplayRecords': value.carfaxDisplayRecords === undefined ? undefined : ((value.carfaxDisplayRecords as Array<any>).map(CarfaxDisplayRecordToJSON)),
    };
}

