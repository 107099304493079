/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CannedJobMotorFluidsInfo } from './CannedJobMotorFluidsInfo';
import {
    CannedJobMotorFluidsInfoFromJSON,
    CannedJobMotorFluidsInfoFromJSONTyped,
    CannedJobMotorFluidsInfoToJSON,
} from './CannedJobMotorFluidsInfo';

/**
 * 
 * @export
 * @interface CannedJobMotorFluidsEntry
 */
export interface CannedJobMotorFluidsEntry {
    /**
     * 
     * @type {string}
     * @memberof CannedJobMotorFluidsEntry
     */
    id?: string;
    /**
     * 
     * @type {CannedJobMotorFluidsInfo}
     * @memberof CannedJobMotorFluidsEntry
     */
    info?: CannedJobMotorFluidsInfo;
}

/**
 * Check if a given object implements the CannedJobMotorFluidsEntry interface.
 */
export function instanceOfCannedJobMotorFluidsEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CannedJobMotorFluidsEntryFromJSON(json: any): CannedJobMotorFluidsEntry {
    return CannedJobMotorFluidsEntryFromJSONTyped(json, false);
}

export function CannedJobMotorFluidsEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CannedJobMotorFluidsEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : CannedJobMotorFluidsInfoFromJSON(json['info']),
    };
}

export function CannedJobMotorFluidsEntryToJSON(value?: CannedJobMotorFluidsEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': CannedJobMotorFluidsInfoToJSON(value.info),
    };
}

