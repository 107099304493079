/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateCustomerIntakeRequestInfo } from './CreateCustomerIntakeRequestInfo';
import {
    CreateCustomerIntakeRequestInfoFromJSON,
    CreateCustomerIntakeRequestInfoFromJSONTyped,
    CreateCustomerIntakeRequestInfoToJSON,
} from './CreateCustomerIntakeRequestInfo';

/**
 * 
 * @export
 * @interface CreateCustomerIntakeRequestObject
 */
export interface CreateCustomerIntakeRequestObject {
    /**
     * 
     * @type {CreateCustomerIntakeRequestInfo}
     * @memberof CreateCustomerIntakeRequestObject
     */
    info?: CreateCustomerIntakeRequestInfo;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateCustomerIntakeRequestObject
     */
    cannedJobs?: Array<string>;
}

/**
 * Check if a given object implements the CreateCustomerIntakeRequestObject interface.
 */
export function instanceOfCreateCustomerIntakeRequestObject(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateCustomerIntakeRequestObjectFromJSON(json: any): CreateCustomerIntakeRequestObject {
    return CreateCustomerIntakeRequestObjectFromJSONTyped(json, false);
}

export function CreateCustomerIntakeRequestObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCustomerIntakeRequestObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'info': !exists(json, 'info') ? undefined : CreateCustomerIntakeRequestInfoFromJSON(json['info']),
        'cannedJobs': !exists(json, 'cannedJobs') ? undefined : json['cannedJobs'],
    };
}

export function CreateCustomerIntakeRequestObjectToJSON(value?: CreateCustomerIntakeRequestObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'info': CreateCustomerIntakeRequestInfoToJSON(value.info),
        'cannedJobs': value.cannedJobs,
    };
}

