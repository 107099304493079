/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IdName } from './IdName';
import {
    IdNameFromJSON,
    IdNameFromJSONTyped,
    IdNameToJSON,
} from './IdName';
import type { Subgroup } from './Subgroup';
import {
    SubgroupFromJSON,
    SubgroupFromJSONTyped,
    SubgroupToJSON,
} from './Subgroup';

/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {IdName}
     * @memberof Group
     */
    idName?: IdName;
    /**
     * 
     * @type {{ [key: string]: Subgroup; }}
     * @memberof Group
     */
    subgroups?: { [key: string]: Subgroup; };
}

/**
 * Check if a given object implements the Group interface.
 */
export function instanceOfGroup(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GroupFromJSON(json: any): Group {
    return GroupFromJSONTyped(json, false);
}

export function GroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): Group {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idName': !exists(json, 'idName') ? undefined : IdNameFromJSON(json['idName']),
        'subgroups': !exists(json, 'subgroups') ? undefined : (mapValues(json['subgroups'], SubgroupFromJSON)),
    };
}

export function GroupToJSON(value?: Group | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idName': IdNameToJSON(value.idName),
        'subgroups': value.subgroups === undefined ? undefined : (mapValues(value.subgroups, SubgroupToJSON)),
    };
}

