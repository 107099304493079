/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InventoryPartInstanceInfo
 */
export interface InventoryPartInstanceInfo {
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInstanceInfo
     */
    inventoryPartId?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInstanceInfo
     */
    partsTechSupplierId?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInstanceInfo
     */
    partsTechOrderItemId?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryPartInstanceInfo
     */
    cost?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryPartInstanceInfo
     */
    isCore?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInstanceInfo
     */
    claimedByPartOverride?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInstanceInfo
     */
    claimedByCounterSale?: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryPartInstanceInfo
     */
    ordered?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InventoryPartInstanceInfo
     */
    received?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InventoryPartInstanceInfo
     */
    used?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InventoryPartInstanceInfo
     */
    returned?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InventoryPartInstanceInfo
     */
    lost?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InventoryPartInstanceInfo
     */
    corePaymentReceived?: Date;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInstanceInfo
     */
    supplierName?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInstanceInfo
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInstanceInfo
     */
    receivedBy?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryPartInstanceInfo
     */
    coreRefund?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInstanceInfo
     */
    coreItem?: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryPartInstanceInfo
     */
    markedForReturn?: Date;
    /**
     * 
     * @type {number}
     * @memberof InventoryPartInstanceInfo
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInstanceInfo
     */
    unitOfMeasure?: InventoryPartInstanceInfoUnitOfMeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof InventoryPartInstanceInfo
     */
    counterSalePricePerUnit?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInstanceInfo
     */
    vendorId?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInstanceInfo
     */
    purchaseOrder?: string;
}


/**
 * @export
 */
export const InventoryPartInstanceInfoUnitOfMeasureEnum = {
    DiscreteUnit: 'discrete_unit',
    Liter: 'liter',
    Ml: 'ml',
    Gallon: 'gallon',
    Quart: 'quart',
    Ounce: 'ounce',
    FlOz: 'fl_oz'
} as const;
export type InventoryPartInstanceInfoUnitOfMeasureEnum = typeof InventoryPartInstanceInfoUnitOfMeasureEnum[keyof typeof InventoryPartInstanceInfoUnitOfMeasureEnum];


/**
 * Check if a given object implements the InventoryPartInstanceInfo interface.
 */
export function instanceOfInventoryPartInstanceInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InventoryPartInstanceInfoFromJSON(json: any): InventoryPartInstanceInfo {
    return InventoryPartInstanceInfoFromJSONTyped(json, false);
}

export function InventoryPartInstanceInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryPartInstanceInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inventoryPartId': !exists(json, 'inventoryPartId') ? undefined : json['inventoryPartId'],
        'partsTechSupplierId': !exists(json, 'partsTechSupplierId') ? undefined : json['partsTechSupplierId'],
        'partsTechOrderItemId': !exists(json, 'partsTechOrderItemId') ? undefined : json['partsTechOrderItemId'],
        'cost': !exists(json, 'cost') ? undefined : json['cost'],
        'isCore': !exists(json, 'isCore') ? undefined : json['isCore'],
        'claimedByPartOverride': !exists(json, 'claimedByPartOverride') ? undefined : json['claimedByPartOverride'],
        'claimedByCounterSale': !exists(json, 'claimedByCounterSale') ? undefined : json['claimedByCounterSale'],
        'ordered': !exists(json, 'ordered') ? undefined : (new Date(json['ordered'])),
        'received': !exists(json, 'received') ? undefined : (new Date(json['received'])),
        'used': !exists(json, 'used') ? undefined : (new Date(json['used'])),
        'returned': !exists(json, 'returned') ? undefined : (new Date(json['returned'])),
        'lost': !exists(json, 'lost') ? undefined : (new Date(json['lost'])),
        'corePaymentReceived': !exists(json, 'corePaymentReceived') ? undefined : (new Date(json['corePaymentReceived'])),
        'supplierName': !exists(json, 'supplierName') ? undefined : json['supplierName'],
        'invoiceNumber': !exists(json, 'invoiceNumber') ? undefined : json['invoiceNumber'],
        'receivedBy': !exists(json, 'receivedBy') ? undefined : json['receivedBy'],
        'coreRefund': !exists(json, 'coreRefund') ? undefined : json['coreRefund'],
        'coreItem': !exists(json, 'coreItem') ? undefined : json['coreItem'],
        'markedForReturn': !exists(json, 'markedForReturn') ? undefined : (new Date(json['markedForReturn'])),
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'unitOfMeasure': !exists(json, 'unitOfMeasure') ? undefined : json['unitOfMeasure'],
        'counterSalePricePerUnit': !exists(json, 'counterSalePricePerUnit') ? undefined : json['counterSalePricePerUnit'],
        'vendorId': !exists(json, 'vendorId') ? undefined : json['vendorId'],
        'purchaseOrder': !exists(json, 'purchaseOrder') ? undefined : json['purchaseOrder'],
    };
}

export function InventoryPartInstanceInfoToJSON(value?: InventoryPartInstanceInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inventoryPartId': value.inventoryPartId,
        'partsTechSupplierId': value.partsTechSupplierId,
        'partsTechOrderItemId': value.partsTechOrderItemId,
        'cost': value.cost,
        'isCore': value.isCore,
        'claimedByPartOverride': value.claimedByPartOverride,
        'claimedByCounterSale': value.claimedByCounterSale,
        'ordered': value.ordered === undefined ? undefined : (value.ordered.toISOString()),
        'received': value.received === undefined ? undefined : (value.received.toISOString()),
        'used': value.used === undefined ? undefined : (value.used.toISOString()),
        'returned': value.returned === undefined ? undefined : (value.returned.toISOString()),
        'lost': value.lost === undefined ? undefined : (value.lost.toISOString()),
        'corePaymentReceived': value.corePaymentReceived === undefined ? undefined : (value.corePaymentReceived.toISOString()),
        'supplierName': value.supplierName,
        'invoiceNumber': value.invoiceNumber,
        'receivedBy': value.receivedBy,
        'coreRefund': value.coreRefund,
        'coreItem': value.coreItem,
        'markedForReturn': value.markedForReturn === undefined ? undefined : (value.markedForReturn.toISOString()),
        'quantity': value.quantity,
        'unitOfMeasure': value.unitOfMeasure,
        'counterSalePricePerUnit': value.counterSalePricePerUnit,
        'vendorId': value.vendorId,
        'purchaseOrder': value.purchaseOrder,
    };
}

