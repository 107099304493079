/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomFeeInfo } from './CustomFeeInfo';
import {
    CustomFeeInfoFromJSON,
    CustomFeeInfoFromJSONTyped,
    CustomFeeInfoToJSON,
} from './CustomFeeInfo';

/**
 * 
 * @export
 * @interface CustomFeeEntry
 */
export interface CustomFeeEntry {
    /**
     * 
     * @type {string}
     * @memberof CustomFeeEntry
     */
    id?: string;
    /**
     * 
     * @type {CustomFeeInfo}
     * @memberof CustomFeeEntry
     */
    info?: CustomFeeInfo;
}

/**
 * Check if a given object implements the CustomFeeEntry interface.
 */
export function instanceOfCustomFeeEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomFeeEntryFromJSON(json: any): CustomFeeEntry {
    return CustomFeeEntryFromJSONTyped(json, false);
}

export function CustomFeeEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFeeEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : CustomFeeInfoFromJSON(json['info']),
    };
}

export function CustomFeeEntryToJSON(value?: CustomFeeEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': CustomFeeInfoToJSON(value.info),
    };
}

