/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FluidCapacityItemInfo } from './FluidCapacityItemInfo';
import {
    FluidCapacityItemInfoFromJSON,
    FluidCapacityItemInfoFromJSONTyped,
    FluidCapacityItemInfoToJSON,
} from './FluidCapacityItemInfo';
import type { FluidItemInfo } from './FluidItemInfo';
import {
    FluidItemInfoFromJSON,
    FluidItemInfoFromJSONTyped,
    FluidItemInfoToJSON,
} from './FluidItemInfo';
import type { VehicleYMMSE } from './VehicleYMMSE';
import {
    VehicleYMMSEFromJSON,
    VehicleYMMSEFromJSONTyped,
    VehicleYMMSEToJSON,
} from './VehicleYMMSE';

/**
 * 
 * @export
 * @interface VehicleFluidsInfo
 */
export interface VehicleFluidsInfo {
    /**
     * 
     * @type {string}
     * @memberof VehicleFluidsInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleFluidsInfo
     */
    vehicle?: string;
    /**
     * 
     * @type {VehicleYMMSE}
     * @memberof VehicleFluidsInfo
     */
    vehicleYMMSE?: VehicleYMMSE;
    /**
     * 
     * @type {number}
     * @memberof VehicleFluidsInfo
     */
    applicationId?: number;
    /**
     * 
     * @type {FluidItemInfo}
     * @memberof VehicleFluidsInfo
     */
    fluidItem?: FluidItemInfo;
    /**
     * 
     * @type {FluidCapacityItemInfo}
     * @memberof VehicleFluidsInfo
     */
    fluidCapacityItem?: FluidCapacityItemInfo;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleFluidsInfo
     */
    isCustom?: boolean;
}

/**
 * Check if a given object implements the VehicleFluidsInfo interface.
 */
export function instanceOfVehicleFluidsInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleFluidsInfoFromJSON(json: any): VehicleFluidsInfo {
    return VehicleFluidsInfoFromJSONTyped(json, false);
}

export function VehicleFluidsInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleFluidsInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'vehicle': !exists(json, 'vehicle') ? undefined : json['vehicle'],
        'vehicleYMMSE': !exists(json, 'vehicleYMMSE') ? undefined : VehicleYMMSEFromJSON(json['vehicleYMMSE']),
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'fluidItem': !exists(json, 'fluidItem') ? undefined : FluidItemInfoFromJSON(json['fluidItem']),
        'fluidCapacityItem': !exists(json, 'fluidCapacityItem') ? undefined : FluidCapacityItemInfoFromJSON(json['fluidCapacityItem']),
        'isCustom': !exists(json, 'isCustom') ? undefined : json['isCustom'],
    };
}

export function VehicleFluidsInfoToJSON(value?: VehicleFluidsInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'vehicle': value.vehicle,
        'vehicleYMMSE': VehicleYMMSEToJSON(value.vehicleYMMSE),
        'applicationId': value.applicationId,
        'fluidItem': FluidItemInfoToJSON(value.fluidItem),
        'fluidCapacityItem': FluidCapacityItemInfoToJSON(value.fluidCapacityItem),
        'isCustom': value.isCustom,
    };
}

