/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CarfaxServiceCategory
 */
export interface CarfaxServiceCategory {
    /**
     * 
     * @type {string}
     * @memberof CarfaxServiceCategory
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof CarfaxServiceCategory
     */
    serviceName?: string;
    /**
     * 
     * @type {string}
     * @memberof CarfaxServiceCategory
     */
    odometer?: string;
}

/**
 * Check if a given object implements the CarfaxServiceCategory interface.
 */
export function instanceOfCarfaxServiceCategory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CarfaxServiceCategoryFromJSON(json: any): CarfaxServiceCategory {
    return CarfaxServiceCategoryFromJSONTyped(json, false);
}

export function CarfaxServiceCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarfaxServiceCategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : json['date'],
        'serviceName': !exists(json, 'serviceName') ? undefined : json['serviceName'],
        'odometer': !exists(json, 'odometer') ? undefined : json['odometer'],
    };
}

export function CarfaxServiceCategoryToJSON(value?: CarfaxServiceCategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'serviceName': value.serviceName,
        'odometer': value.odometer,
    };
}

