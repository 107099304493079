/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CannedJobInfo } from './CannedJobInfo';
import {
    CannedJobInfoFromJSON,
    CannedJobInfoFromJSONTyped,
    CannedJobInfoToJSON,
} from './CannedJobInfo';

/**
 * 
 * @export
 * @interface CannedJobEntry
 */
export interface CannedJobEntry {
    /**
     * 
     * @type {string}
     * @memberof CannedJobEntry
     */
    id?: string;
    /**
     * 
     * @type {CannedJobInfo}
     * @memberof CannedJobEntry
     */
    info?: CannedJobInfo;
}

/**
 * Check if a given object implements the CannedJobEntry interface.
 */
export function instanceOfCannedJobEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CannedJobEntryFromJSON(json: any): CannedJobEntry {
    return CannedJobEntryFromJSONTyped(json, false);
}

export function CannedJobEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CannedJobEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : CannedJobInfoFromJSON(json['info']),
    };
}

export function CannedJobEntryToJSON(value?: CannedJobEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': CannedJobInfoToJSON(value.info),
    };
}

