/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SerializableBoundingBox } from './SerializableBoundingBox';
import {
    SerializableBoundingBoxFromJSON,
    SerializableBoundingBoxFromJSONTyped,
    SerializableBoundingBoxToJSON,
} from './SerializableBoundingBox';
import type { SerializablePoint } from './SerializablePoint';
import {
    SerializablePointFromJSON,
    SerializablePointFromJSONTyped,
    SerializablePointToJSON,
} from './SerializablePoint';

/**
 * 
 * @export
 * @interface SerializableGeometry
 */
export interface SerializableGeometry {
    /**
     * 
     * @type {SerializableBoundingBox}
     * @memberof SerializableGeometry
     */
    boundingBox?: SerializableBoundingBox;
    /**
     * 
     * @type {Array<SerializablePoint>}
     * @memberof SerializableGeometry
     */
    polygon?: Array<SerializablePoint>;
}

/**
 * Check if a given object implements the SerializableGeometry interface.
 */
export function instanceOfSerializableGeometry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SerializableGeometryFromJSON(json: any): SerializableGeometry {
    return SerializableGeometryFromJSONTyped(json, false);
}

export function SerializableGeometryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerializableGeometry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'boundingBox': !exists(json, 'boundingBox') ? undefined : SerializableBoundingBoxFromJSON(json['boundingBox']),
        'polygon': !exists(json, 'polygon') ? undefined : ((json['polygon'] as Array<any>).map(SerializablePointFromJSON)),
    };
}

export function SerializableGeometryToJSON(value?: SerializableGeometry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'boundingBox': SerializableBoundingBoxToJSON(value.boundingBox),
        'polygon': value.polygon === undefined ? undefined : ((value.polygon as Array<any>).map(SerializablePointToJSON)),
    };
}

