/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VehicleReminderMessageInfo } from './VehicleReminderMessageInfo';
import {
    VehicleReminderMessageInfoFromJSON,
    VehicleReminderMessageInfoFromJSONTyped,
    VehicleReminderMessageInfoToJSON,
} from './VehicleReminderMessageInfo';

/**
 * 
 * @export
 * @interface VehicleReminderMessageEntry
 */
export interface VehicleReminderMessageEntry {
    /**
     * 
     * @type {string}
     * @memberof VehicleReminderMessageEntry
     */
    id?: string;
    /**
     * 
     * @type {VehicleReminderMessageInfo}
     * @memberof VehicleReminderMessageEntry
     */
    info?: VehicleReminderMessageInfo;
    /**
     * 
     * @type {Date}
     * @memberof VehicleReminderMessageEntry
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof VehicleReminderMessageEntry
     */
    createdBy?: string;
}

/**
 * Check if a given object implements the VehicleReminderMessageEntry interface.
 */
export function instanceOfVehicleReminderMessageEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleReminderMessageEntryFromJSON(json: any): VehicleReminderMessageEntry {
    return VehicleReminderMessageEntryFromJSONTyped(json, false);
}

export function VehicleReminderMessageEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleReminderMessageEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : VehicleReminderMessageInfoFromJSON(json['info']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
    };
}

export function VehicleReminderMessageEntryToJSON(value?: VehicleReminderMessageEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': VehicleReminderMessageInfoToJSON(value.info),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
    };
}

