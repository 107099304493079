/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerServiceStatusRequest
 */
export interface CustomerServiceStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerServiceStatusRequest
     */
    serviceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerServiceStatusRequest
     */
    isDeferral?: boolean;
}

/**
 * Check if a given object implements the CustomerServiceStatusRequest interface.
 */
export function instanceOfCustomerServiceStatusRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerServiceStatusRequestFromJSON(json: any): CustomerServiceStatusRequest {
    return CustomerServiceStatusRequestFromJSONTyped(json, false);
}

export function CustomerServiceStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerServiceStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceId': !exists(json, 'serviceId') ? undefined : json['serviceId'],
        'isDeferral': !exists(json, 'isDeferral') ? undefined : json['isDeferral'],
    };
}

export function CustomerServiceStatusRequestToJSON(value?: CustomerServiceStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceId': value.serviceId,
        'isDeferral': value.isDeferral,
    };
}

