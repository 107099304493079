/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepairLinkPartInCartData
 */
export interface RepairLinkPartInCartData {
    /**
     * 
     * @type {string}
     * @memberof RepairLinkPartInCartData
     */
    partDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkPartInCartData
     */
    manufacturerName?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkPartInCartData
     */
    partNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairLinkPartInCartData
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairLinkPartInCartData
     */
    listPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairLinkPartInCartData
     */
    salePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairLinkPartInCartData
     */
    corePrice?: number;
}

/**
 * Check if a given object implements the RepairLinkPartInCartData interface.
 */
export function instanceOfRepairLinkPartInCartData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairLinkPartInCartDataFromJSON(json: any): RepairLinkPartInCartData {
    return RepairLinkPartInCartDataFromJSONTyped(json, false);
}

export function RepairLinkPartInCartDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairLinkPartInCartData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partDescription': !exists(json, 'partDescription') ? undefined : json['partDescription'],
        'manufacturerName': !exists(json, 'manufacturerName') ? undefined : json['manufacturerName'],
        'partNumber': !exists(json, 'partNumber') ? undefined : json['partNumber'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'listPrice': !exists(json, 'listPrice') ? undefined : json['listPrice'],
        'salePrice': !exists(json, 'salePrice') ? undefined : json['salePrice'],
        'corePrice': !exists(json, 'corePrice') ? undefined : json['corePrice'],
    };
}

export function RepairLinkPartInCartDataToJSON(value?: RepairLinkPartInCartData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partDescription': value.partDescription,
        'manufacturerName': value.manufacturerName,
        'partNumber': value.partNumber,
        'quantity': value.quantity,
        'listPrice': value.listPrice,
        'salePrice': value.salePrice,
        'corePrice': value.corePrice,
    };
}

