/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InventoryPartEntry } from './InventoryPartEntry';
import {
    InventoryPartEntryFromJSON,
    InventoryPartEntryFromJSONTyped,
    InventoryPartEntryToJSON,
} from './InventoryPartEntry';

/**
 * 
 * @export
 * @interface ShopInventory
 */
export interface ShopInventory {
    /**
     * 
     * @type {{ [key: string]: InventoryPartEntry; }}
     * @memberof ShopInventory
     */
    allParts?: { [key: string]: InventoryPartEntry; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ShopInventory
     */
    availableUnits?: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ShopInventory
     */
    inShopUnits?: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ShopInventory
     */
    receivedUnits?: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ShopInventory
     */
    pendingClaimedUnits?: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ShopInventory
     */
    pendingReturnUnits?: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ShopInventory
     */
    returnedUnits?: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ShopInventory
     */
    lostUnits?: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {{ [key: string]: { [key: string]: number; }; }}
     * @memberof ShopInventory
     */
    usedUnits?: { [key: string]: { [key: string]: number; }; };
    /**
     * 
     * @type {number}
     * @memberof ShopInventory
     */
    totalNumParts?: number;
}

/**
 * Check if a given object implements the ShopInventory interface.
 */
export function instanceOfShopInventory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ShopInventoryFromJSON(json: any): ShopInventory {
    return ShopInventoryFromJSONTyped(json, false);
}

export function ShopInventoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopInventory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allParts': !exists(json, 'allParts') ? undefined : (mapValues(json['allParts'], InventoryPartEntryFromJSON)),
        'availableUnits': !exists(json, 'availableUnits') ? undefined : json['availableUnits'],
        'inShopUnits': !exists(json, 'inShopUnits') ? undefined : json['inShopUnits'],
        'receivedUnits': !exists(json, 'receivedUnits') ? undefined : json['receivedUnits'],
        'pendingClaimedUnits': !exists(json, 'pendingClaimedUnits') ? undefined : json['pendingClaimedUnits'],
        'pendingReturnUnits': !exists(json, 'pendingReturnUnits') ? undefined : json['pendingReturnUnits'],
        'returnedUnits': !exists(json, 'returnedUnits') ? undefined : json['returnedUnits'],
        'lostUnits': !exists(json, 'lostUnits') ? undefined : json['lostUnits'],
        'usedUnits': !exists(json, 'usedUnits') ? undefined : json['usedUnits'],
        'totalNumParts': !exists(json, 'totalNumParts') ? undefined : json['totalNumParts'],
    };
}

export function ShopInventoryToJSON(value?: ShopInventory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allParts': value.allParts === undefined ? undefined : (mapValues(value.allParts, InventoryPartEntryToJSON)),
        'availableUnits': value.availableUnits,
        'inShopUnits': value.inShopUnits,
        'receivedUnits': value.receivedUnits,
        'pendingClaimedUnits': value.pendingClaimedUnits,
        'pendingReturnUnits': value.pendingReturnUnits,
        'returnedUnits': value.returnedUnits,
        'lostUnits': value.lostUnits,
        'usedUnits': value.usedUnits,
        'totalNumParts': value.totalNumParts,
    };
}

