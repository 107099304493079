/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CartInfo } from './CartInfo';
import {
    CartInfoFromJSON,
    CartInfoFromJSONTyped,
    CartInfoToJSON,
} from './CartInfo';

/**
 * 
 * @export
 * @interface OrderCartResponse
 */
export interface OrderCartResponse {
    /**
     * 
     * @type {CartInfo}
     * @memberof OrderCartResponse
     */
    orderedCart?: CartInfo;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderCartResponse
     */
    instanceIds?: Array<string>;
}

/**
 * Check if a given object implements the OrderCartResponse interface.
 */
export function instanceOfOrderCartResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrderCartResponseFromJSON(json: any): OrderCartResponse {
    return OrderCartResponseFromJSONTyped(json, false);
}

export function OrderCartResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCartResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderedCart': !exists(json, 'orderedCart') ? undefined : CartInfoFromJSON(json['orderedCart']),
        'instanceIds': !exists(json, 'instanceIds') ? undefined : json['instanceIds'],
    };
}

export function OrderCartResponseToJSON(value?: OrderCartResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderedCart': CartInfoToJSON(value.orderedCart),
        'instanceIds': value.instanceIds,
    };
}

