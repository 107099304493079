/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SingleMaintenanceSchedule } from './SingleMaintenanceSchedule';
import {
    SingleMaintenanceScheduleFromJSON,
    SingleMaintenanceScheduleFromJSONTyped,
    SingleMaintenanceScheduleToJSON,
} from './SingleMaintenanceSchedule';

/**
 * 
 * @export
 * @interface SupplementaryMaintenanceSchedule
 */
export interface SupplementaryMaintenanceSchedule {
    /**
     * 
     * @type {string}
     * @memberof SupplementaryMaintenanceSchedule
     */
    frequency?: string;
    /**
     * 
     * @type {Array<SingleMaintenanceSchedule>}
     * @memberof SupplementaryMaintenanceSchedule
     */
    maintenanceSchedules?: Array<SingleMaintenanceSchedule>;
}

/**
 * Check if a given object implements the SupplementaryMaintenanceSchedule interface.
 */
export function instanceOfSupplementaryMaintenanceSchedule(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplementaryMaintenanceScheduleFromJSON(json: any): SupplementaryMaintenanceSchedule {
    return SupplementaryMaintenanceScheduleFromJSONTyped(json, false);
}

export function SupplementaryMaintenanceScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplementaryMaintenanceSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'frequency': !exists(json, 'frequency') ? undefined : json['frequency'],
        'maintenanceSchedules': !exists(json, 'maintenanceSchedules') ? undefined : ((json['maintenanceSchedules'] as Array<any>).map(SingleMaintenanceScheduleFromJSON)),
    };
}

export function SupplementaryMaintenanceScheduleToJSON(value?: SupplementaryMaintenanceSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'frequency': value.frequency,
        'maintenanceSchedules': value.maintenanceSchedules === undefined ? undefined : ((value.maintenanceSchedules as Array<any>).map(SingleMaintenanceScheduleToJSON)),
    };
}

