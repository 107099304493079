/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CounterSaleInfo } from './CounterSaleInfo';
import {
    CounterSaleInfoFromJSON,
    CounterSaleInfoFromJSONTyped,
    CounterSaleInfoToJSON,
} from './CounterSaleInfo';

/**
 * 
 * @export
 * @interface CounterSaleEntry
 */
export interface CounterSaleEntry {
    /**
     * 
     * @type {string}
     * @memberof CounterSaleEntry
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof CounterSaleEntry
     */
    localId?: number;
    /**
     * 
     * @type {Date}
     * @memberof CounterSaleEntry
     */
    created?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CounterSaleEntry
     */
    lastUpdated?: Date;
    /**
     * 
     * @type {CounterSaleInfo}
     * @memberof CounterSaleEntry
     */
    info?: CounterSaleInfo;
}

/**
 * Check if a given object implements the CounterSaleEntry interface.
 */
export function instanceOfCounterSaleEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CounterSaleEntryFromJSON(json: any): CounterSaleEntry {
    return CounterSaleEntryFromJSONTyped(json, false);
}

export function CounterSaleEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CounterSaleEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'localId': !exists(json, 'localId') ? undefined : json['localId'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : (new Date(json['lastUpdated'])),
        'info': !exists(json, 'info') ? undefined : CounterSaleInfoFromJSON(json['info']),
    };
}

export function CounterSaleEntryToJSON(value?: CounterSaleEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'localId': value.localId,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'lastUpdated': value.lastUpdated === undefined ? undefined : (value.lastUpdated.toISOString()),
        'info': CounterSaleInfoToJSON(value.info),
    };
}

