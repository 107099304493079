/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepairLinkDealerData
 */
export interface RepairLinkDealerData {
    /**
     * 
     * @type {number}
     * @memberof RepairLinkDealerData
     */
    repairLinkVendorId?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairLinkDealerData
     */
    oemId?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkDealerData
     */
    oemName?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkDealerData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkDealerData
     */
    addressLineOne?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkDealerData
     */
    addressLineTwo?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkDealerData
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkDealerData
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkDealerData
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkDealerData
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkDealerData
     */
    country?: string;
}

/**
 * Check if a given object implements the RepairLinkDealerData interface.
 */
export function instanceOfRepairLinkDealerData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairLinkDealerDataFromJSON(json: any): RepairLinkDealerData {
    return RepairLinkDealerDataFromJSONTyped(json, false);
}

export function RepairLinkDealerDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairLinkDealerData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'repairLinkVendorId': !exists(json, 'repairLinkVendorId') ? undefined : json['repairLinkVendorId'],
        'oemId': !exists(json, 'oemId') ? undefined : json['oemId'],
        'oemName': !exists(json, 'oemName') ? undefined : json['oemName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'addressLineOne': !exists(json, 'addressLineOne') ? undefined : json['addressLineOne'],
        'addressLineTwo': !exists(json, 'addressLineTwo') ? undefined : json['addressLineTwo'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'country': !exists(json, 'country') ? undefined : json['country'],
    };
}

export function RepairLinkDealerDataToJSON(value?: RepairLinkDealerData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'repairLinkVendorId': value.repairLinkVendorId,
        'oemId': value.oemId,
        'oemName': value.oemName,
        'name': value.name,
        'addressLineOne': value.addressLineOne,
        'addressLineTwo': value.addressLineTwo,
        'phoneNumber': value.phoneNumber,
        'city': value.city,
        'state': value.state,
        'zipCode': value.zipCode,
        'country': value.country,
    };
}

