/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SerializableExpenseCurrency } from './SerializableExpenseCurrency';
import {
    SerializableExpenseCurrencyFromJSON,
    SerializableExpenseCurrencyFromJSONTyped,
    SerializableExpenseCurrencyToJSON,
} from './SerializableExpenseCurrency';
import type { SerializableExpenseDetection } from './SerializableExpenseDetection';
import {
    SerializableExpenseDetectionFromJSON,
    SerializableExpenseDetectionFromJSONTyped,
    SerializableExpenseDetectionToJSON,
} from './SerializableExpenseDetection';
import type { SerializableExpenseGroupProperty } from './SerializableExpenseGroupProperty';
import {
    SerializableExpenseGroupPropertyFromJSON,
    SerializableExpenseGroupPropertyFromJSONTyped,
    SerializableExpenseGroupPropertyToJSON,
} from './SerializableExpenseGroupProperty';
import type { SerializableExpenseType } from './SerializableExpenseType';
import {
    SerializableExpenseTypeFromJSON,
    SerializableExpenseTypeFromJSONTyped,
    SerializableExpenseTypeToJSON,
} from './SerializableExpenseType';

/**
 * 
 * @export
 * @interface SerializableExpenseField
 */
export interface SerializableExpenseField {
    /**
     * 
     * @type {SerializableExpenseCurrency}
     * @memberof SerializableExpenseField
     */
    currency?: SerializableExpenseCurrency;
    /**
     * 
     * @type {Array<SerializableExpenseGroupProperty>}
     * @memberof SerializableExpenseField
     */
    groupProperties?: Array<SerializableExpenseGroupProperty>;
    /**
     * 
     * @type {SerializableExpenseDetection}
     * @memberof SerializableExpenseField
     */
    labelDetection?: SerializableExpenseDetection;
    /**
     * 
     * @type {number}
     * @memberof SerializableExpenseField
     */
    pageNumber?: number;
    /**
     * 
     * @type {SerializableExpenseType}
     * @memberof SerializableExpenseField
     */
    type?: SerializableExpenseType;
    /**
     * 
     * @type {SerializableExpenseDetection}
     * @memberof SerializableExpenseField
     */
    valueDetection?: SerializableExpenseDetection;
}

/**
 * Check if a given object implements the SerializableExpenseField interface.
 */
export function instanceOfSerializableExpenseField(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SerializableExpenseFieldFromJSON(json: any): SerializableExpenseField {
    return SerializableExpenseFieldFromJSONTyped(json, false);
}

export function SerializableExpenseFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerializableExpenseField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currency': !exists(json, 'currency') ? undefined : SerializableExpenseCurrencyFromJSON(json['currency']),
        'groupProperties': !exists(json, 'groupProperties') ? undefined : ((json['groupProperties'] as Array<any>).map(SerializableExpenseGroupPropertyFromJSON)),
        'labelDetection': !exists(json, 'labelDetection') ? undefined : SerializableExpenseDetectionFromJSON(json['labelDetection']),
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'type': !exists(json, 'type') ? undefined : SerializableExpenseTypeFromJSON(json['type']),
        'valueDetection': !exists(json, 'valueDetection') ? undefined : SerializableExpenseDetectionFromJSON(json['valueDetection']),
    };
}

export function SerializableExpenseFieldToJSON(value?: SerializableExpenseField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currency': SerializableExpenseCurrencyToJSON(value.currency),
        'groupProperties': value.groupProperties === undefined ? undefined : ((value.groupProperties as Array<any>).map(SerializableExpenseGroupPropertyToJSON)),
        'labelDetection': SerializableExpenseDetectionToJSON(value.labelDetection),
        'pageNumber': value.pageNumber,
        'type': SerializableExpenseTypeToJSON(value.type),
        'valueDetection': SerializableExpenseDetectionToJSON(value.valueDetection),
    };
}

