/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MergeVehiclesRequest
 */
export interface MergeVehiclesRequest {
    /**
     * 
     * @type {string}
     * @memberof MergeVehiclesRequest
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof MergeVehiclesRequest
     */
    vehicleToKeep?: string;
    /**
     * 
     * @type {string}
     * @memberof MergeVehiclesRequest
     */
    vehicleToArchive?: string;
}

/**
 * Check if a given object implements the MergeVehiclesRequest interface.
 */
export function instanceOfMergeVehiclesRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MergeVehiclesRequestFromJSON(json: any): MergeVehiclesRequest {
    return MergeVehiclesRequestFromJSONTyped(json, false);
}

export function MergeVehiclesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergeVehiclesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'vehicleToKeep': !exists(json, 'vehicleToKeep') ? undefined : json['vehicleToKeep'],
        'vehicleToArchive': !exists(json, 'vehicleToArchive') ? undefined : json['vehicleToArchive'],
    };
}

export function MergeVehiclesRequestToJSON(value?: MergeVehiclesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'vehicleToKeep': value.vehicleToKeep,
        'vehicleToArchive': value.vehicleToArchive,
    };
}

