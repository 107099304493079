/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCatalogLaborMatrixRanksRequest
 */
export interface UpdateCatalogLaborMatrixRanksRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCatalogLaborMatrixRanksRequest
     */
    shop?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCatalogLaborMatrixRanksRequest
     */
    catalogLaborMatrices?: Array<string>;
}

/**
 * Check if a given object implements the UpdateCatalogLaborMatrixRanksRequest interface.
 */
export function instanceOfUpdateCatalogLaborMatrixRanksRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateCatalogLaborMatrixRanksRequestFromJSON(json: any): UpdateCatalogLaborMatrixRanksRequest {
    return UpdateCatalogLaborMatrixRanksRequestFromJSONTyped(json, false);
}

export function UpdateCatalogLaborMatrixRanksRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCatalogLaborMatrixRanksRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'catalogLaborMatrices': !exists(json, 'catalogLaborMatrices') ? undefined : json['catalogLaborMatrices'],
    };
}

export function UpdateCatalogLaborMatrixRanksRequestToJSON(value?: UpdateCatalogLaborMatrixRanksRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'catalogLaborMatrices': value.catalogLaborMatrices,
    };
}

