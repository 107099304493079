/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerEntry } from './CustomerEntry';
import {
    CustomerEntryFromJSON,
    CustomerEntryFromJSONTyped,
    CustomerEntryToJSON,
} from './CustomerEntry';
import type { RepairOrderEntry } from './RepairOrderEntry';
import {
    RepairOrderEntryFromJSON,
    RepairOrderEntryFromJSONTyped,
    RepairOrderEntryToJSON,
} from './RepairOrderEntry';
import type { VehicleEntry } from './VehicleEntry';
import {
    VehicleEntryFromJSON,
    VehicleEntryFromJSONTyped,
    VehicleEntryToJSON,
} from './VehicleEntry';
import type { VehicleReminderMessageEntry } from './VehicleReminderMessageEntry';
import {
    VehicleReminderMessageEntryFromJSON,
    VehicleReminderMessageEntryFromJSONTyped,
    VehicleReminderMessageEntryToJSON,
} from './VehicleReminderMessageEntry';

/**
 * 
 * @export
 * @interface VehicleReminder
 */
export interface VehicleReminder {
    /**
     * 
     * @type {VehicleEntry}
     * @memberof VehicleReminder
     */
    vehicleEntry?: VehicleEntry;
    /**
     * 
     * @type {Array<CustomerEntry>}
     * @memberof VehicleReminder
     */
    customerEntries?: Array<CustomerEntry>;
    /**
     * 
     * @type {Array<RepairOrderEntry>}
     * @memberof VehicleReminder
     */
    closedRepairOrderEntries?: Array<RepairOrderEntry>;
    /**
     * 
     * @type {Array<VehicleReminderMessageEntry>}
     * @memberof VehicleReminder
     */
    vehicleReminderMessageEntries?: Array<VehicleReminderMessageEntry>;
}

/**
 * Check if a given object implements the VehicleReminder interface.
 */
export function instanceOfVehicleReminder(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleReminderFromJSON(json: any): VehicleReminder {
    return VehicleReminderFromJSONTyped(json, false);
}

export function VehicleReminderFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleReminder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicleEntry': !exists(json, 'vehicleEntry') ? undefined : VehicleEntryFromJSON(json['vehicleEntry']),
        'customerEntries': !exists(json, 'customerEntries') ? undefined : ((json['customerEntries'] as Array<any>).map(CustomerEntryFromJSON)),
        'closedRepairOrderEntries': !exists(json, 'closedRepairOrderEntries') ? undefined : ((json['closedRepairOrderEntries'] as Array<any>).map(RepairOrderEntryFromJSON)),
        'vehicleReminderMessageEntries': !exists(json, 'vehicleReminderMessageEntries') ? undefined : ((json['vehicleReminderMessageEntries'] as Array<any>).map(VehicleReminderMessageEntryFromJSON)),
    };
}

export function VehicleReminderToJSON(value?: VehicleReminder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicleEntry': VehicleEntryToJSON(value.vehicleEntry),
        'customerEntries': value.customerEntries === undefined ? undefined : ((value.customerEntries as Array<any>).map(CustomerEntryToJSON)),
        'closedRepairOrderEntries': value.closedRepairOrderEntries === undefined ? undefined : ((value.closedRepairOrderEntries as Array<any>).map(RepairOrderEntryToJSON)),
        'vehicleReminderMessageEntries': value.vehicleReminderMessageEntries === undefined ? undefined : ((value.vehicleReminderMessageEntries as Array<any>).map(VehicleReminderMessageEntryToJSON)),
    };
}

