export const FIREBASE_DUMMY_CONFIG = {
  apiKey: '',
  authDomain: '',
  projectId: '',
  storageBucket: '',
  messagingSenderId: '',
  appId: '',
  measurementId: ''
}

export const FIREBASE_QA_CONFIG = {
  apiKey: 'AIzaSyA0HaOyysqMfqo0ozoD8noGtw5DVOV5HgA',
  authDomain: 'intune-qa.firebaseapp.com',
  projectId: 'intune-qa',
  storageBucket: 'intune-qa.appspot.com',
  messagingSenderId: '973645272623',
  appId: '1:973645272623:web:3a91a7ace7859f0ad398da',
  measurementId: 'G-5B0YRFDXS4'
}

export const FIREBASE_PROD_CONFIG = {
  apiKey: 'AIzaSyCyJpW7MQk1Qlv24LhGyqTo3F4DDQ2kXbc',
  authDomain: 'intune-prod.firebaseapp.com',
  projectId: 'intune-prod',
  storageBucket: 'intune-prod.appspot.com',
  messagingSenderId: '330453155936',
  appId: '1:330453155936:web:83fe8a7b2781d1bf8b0305'
}
