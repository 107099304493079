/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InspectionConversationMessageInfo } from './InspectionConversationMessageInfo';
import {
    InspectionConversationMessageInfoFromJSON,
    InspectionConversationMessageInfoFromJSONTyped,
    InspectionConversationMessageInfoToJSON,
} from './InspectionConversationMessageInfo';

/**
 * 
 * @export
 * @interface InspectionConversationMessageEntry
 */
export interface InspectionConversationMessageEntry {
    /**
     * 
     * @type {string}
     * @memberof InspectionConversationMessageEntry
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof InspectionConversationMessageEntry
     */
    created?: Date;
    /**
     * 
     * @type {InspectionConversationMessageInfo}
     * @memberof InspectionConversationMessageEntry
     */
    info?: InspectionConversationMessageInfo;
}

/**
 * Check if a given object implements the InspectionConversationMessageEntry interface.
 */
export function instanceOfInspectionConversationMessageEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InspectionConversationMessageEntryFromJSON(json: any): InspectionConversationMessageEntry {
    return InspectionConversationMessageEntryFromJSONTyped(json, false);
}

export function InspectionConversationMessageEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionConversationMessageEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'info': !exists(json, 'info') ? undefined : InspectionConversationMessageInfoFromJSON(json['info']),
    };
}

export function InspectionConversationMessageEntryToJSON(value?: InspectionConversationMessageEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'info': InspectionConversationMessageInfoToJSON(value.info),
    };
}

