/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Kpi
 */
export interface Kpi {
    /**
     * 
     * @type {number}
     * @memberof Kpi
     */
    numOfRepairOrders?: number;
    /**
     * 
     * @type {number}
     * @memberof Kpi
     */
    aro?: number;
    /**
     * 
     * @type {number}
     * @memberof Kpi
     */
    taxable?: number;
    /**
     * 
     * @type {number}
     * @memberof Kpi
     */
    taxExempt?: number;
    /**
     * 
     * @type {number}
     * @memberof Kpi
     */
    taxes?: number;
    /**
     * 
     * @type {number}
     * @memberof Kpi
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof Kpi
     */
    laborRetailPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Kpi
     */
    partsRetailPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Kpi
     */
    partsCost?: number;
}

/**
 * Check if a given object implements the Kpi interface.
 */
export function instanceOfKpi(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function KpiFromJSON(json: any): Kpi {
    return KpiFromJSONTyped(json, false);
}

export function KpiFromJSONTyped(json: any, ignoreDiscriminator: boolean): Kpi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numOfRepairOrders': !exists(json, 'numOfRepairOrders') ? undefined : json['numOfRepairOrders'],
        'aro': !exists(json, 'aro') ? undefined : json['aro'],
        'taxable': !exists(json, 'taxable') ? undefined : json['taxable'],
        'taxExempt': !exists(json, 'taxExempt') ? undefined : json['taxExempt'],
        'taxes': !exists(json, 'taxes') ? undefined : json['taxes'],
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'laborRetailPrice': !exists(json, 'laborRetailPrice') ? undefined : json['laborRetailPrice'],
        'partsRetailPrice': !exists(json, 'partsRetailPrice') ? undefined : json['partsRetailPrice'],
        'partsCost': !exists(json, 'partsCost') ? undefined : json['partsCost'],
    };
}

export function KpiToJSON(value?: Kpi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numOfRepairOrders': value.numOfRepairOrders,
        'aro': value.aro,
        'taxable': value.taxable,
        'taxExempt': value.taxExempt,
        'taxes': value.taxes,
        'hours': value.hours,
        'laborRetailPrice': value.laborRetailPrice,
        'partsRetailPrice': value.partsRetailPrice,
        'partsCost': value.partsCost,
    };
}

