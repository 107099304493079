/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateIntakeRequestObject
 */
export interface UpdateIntakeRequestObject {
    /**
     * 
     * @type {string}
     * @memberof UpdateIntakeRequestObject
     */
    intakeRequestId?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIntakeRequestObject
     */
    repairOrderId?: string;
}

/**
 * Check if a given object implements the UpdateIntakeRequestObject interface.
 */
export function instanceOfUpdateIntakeRequestObject(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateIntakeRequestObjectFromJSON(json: any): UpdateIntakeRequestObject {
    return UpdateIntakeRequestObjectFromJSONTyped(json, false);
}

export function UpdateIntakeRequestObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateIntakeRequestObject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'intakeRequestId': !exists(json, 'intakeRequestId') ? undefined : json['intakeRequestId'],
        'repairOrderId': !exists(json, 'repairOrderId') ? undefined : json['repairOrderId'],
    };
}

export function UpdateIntakeRequestObjectToJSON(value?: UpdateIntakeRequestObject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'intakeRequestId': value.intakeRequestId,
        'repairOrderId': value.repairOrderId,
    };
}

