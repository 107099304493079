/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PushSubscriptionInfo
 */
export interface PushSubscriptionInfo {
    /**
     * 
     * @type {string}
     * @memberof PushSubscriptionInfo
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof PushSubscriptionInfo
     */
    endpoint?: string;
    /**
     * 
     * @type {string}
     * @memberof PushSubscriptionInfo
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof PushSubscriptionInfo
     */
    auth?: string;
}

/**
 * Check if a given object implements the PushSubscriptionInfo interface.
 */
export function instanceOfPushSubscriptionInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PushSubscriptionInfoFromJSON(json: any): PushSubscriptionInfo {
    return PushSubscriptionInfoFromJSONTyped(json, false);
}

export function PushSubscriptionInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushSubscriptionInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'endpoint': !exists(json, 'endpoint') ? undefined : json['endpoint'],
        'key': !exists(json, 'key') ? undefined : json['key'],
        'auth': !exists(json, 'auth') ? undefined : json['auth'],
    };
}

export function PushSubscriptionInfoToJSON(value?: PushSubscriptionInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'endpoint': value.endpoint,
        'key': value.key,
        'auth': value.auth,
    };
}

