/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CopySectionRequest
 */
export interface CopySectionRequest {
    /**
     * 
     * @type {string}
     * @memberof CopySectionRequest
     */
    section?: string;
    /**
     * 
     * @type {string}
     * @memberof CopySectionRequest
     */
    destinationInspection?: string;
}

/**
 * Check if a given object implements the CopySectionRequest interface.
 */
export function instanceOfCopySectionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CopySectionRequestFromJSON(json: any): CopySectionRequest {
    return CopySectionRequestFromJSONTyped(json, false);
}

export function CopySectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopySectionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'section': !exists(json, 'section') ? undefined : json['section'],
        'destinationInspection': !exists(json, 'destinationInspection') ? undefined : json['destinationInspection'],
    };
}

export function CopySectionRequestToJSON(value?: CopySectionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'section': value.section,
        'destinationInspection': value.destinationInspection,
    };
}

