/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PromptInfo } from './PromptInfo';
import {
    PromptInfoFromJSON,
    PromptInfoFromJSONTyped,
    PromptInfoToJSON,
} from './PromptInfo';

/**
 * 
 * @export
 * @interface PromptEntry
 */
export interface PromptEntry {
    /**
     * 
     * @type {string}
     * @memberof PromptEntry
     */
    id?: string;
    /**
     * 
     * @type {PromptInfo}
     * @memberof PromptEntry
     */
    info?: PromptInfo;
}

/**
 * Check if a given object implements the PromptEntry interface.
 */
export function instanceOfPromptEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PromptEntryFromJSON(json: any): PromptEntry {
    return PromptEntryFromJSONTyped(json, false);
}

export function PromptEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : PromptInfoFromJSON(json['info']),
    };
}

export function PromptEntryToJSON(value?: PromptEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': PromptInfoToJSON(value.info),
    };
}

