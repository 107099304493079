/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CannedJobPartLookupInfo } from './CannedJobPartLookupInfo';
import {
    CannedJobPartLookupInfoFromJSON,
    CannedJobPartLookupInfoFromJSONTyped,
    CannedJobPartLookupInfoToJSON,
} from './CannedJobPartLookupInfo';

/**
 * 
 * @export
 * @interface CannedJobPartLookupEntry
 */
export interface CannedJobPartLookupEntry {
    /**
     * 
     * @type {string}
     * @memberof CannedJobPartLookupEntry
     */
    id?: string;
    /**
     * 
     * @type {CannedJobPartLookupInfo}
     * @memberof CannedJobPartLookupEntry
     */
    info?: CannedJobPartLookupInfo;
}

/**
 * Check if a given object implements the CannedJobPartLookupEntry interface.
 */
export function instanceOfCannedJobPartLookupEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CannedJobPartLookupEntryFromJSON(json: any): CannedJobPartLookupEntry {
    return CannedJobPartLookupEntryFromJSONTyped(json, false);
}

export function CannedJobPartLookupEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CannedJobPartLookupEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : CannedJobPartLookupInfoFromJSON(json['info']),
    };
}

export function CannedJobPartLookupEntryToJSON(value?: CannedJobPartLookupEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': CannedJobPartLookupInfoToJSON(value.info),
    };
}

