/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepairLinkPriceCheckItem
 */
export interface RepairLinkPriceCheckItem {
    /**
     * 
     * @type {string}
     * @memberof RepairLinkPriceCheckItem
     */
    requestedPartNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkPriceCheckItem
     */
    supersededPartNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkPriceCheckItem
     */
    partId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RepairLinkPriceCheckItem
     */
    isValid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkPriceCheckItem
     */
    manufacturerName?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairLinkPriceCheckItem
     */
    quantityAvailable?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkPriceCheckItem
     */
    quantityDisplay?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairLinkPriceCheckItem
     */
    listPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairLinkPriceCheckItem
     */
    salePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairLinkPriceCheckItem
     */
    corePrice?: number;
}

/**
 * Check if a given object implements the RepairLinkPriceCheckItem interface.
 */
export function instanceOfRepairLinkPriceCheckItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairLinkPriceCheckItemFromJSON(json: any): RepairLinkPriceCheckItem {
    return RepairLinkPriceCheckItemFromJSONTyped(json, false);
}

export function RepairLinkPriceCheckItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairLinkPriceCheckItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestedPartNumber': !exists(json, 'requestedPartNumber') ? undefined : json['requestedPartNumber'],
        'supersededPartNumber': !exists(json, 'supersededPartNumber') ? undefined : json['supersededPartNumber'],
        'partId': !exists(json, 'partId') ? undefined : json['partId'],
        'isValid': !exists(json, 'isValid') ? undefined : json['isValid'],
        'manufacturerName': !exists(json, 'manufacturerName') ? undefined : json['manufacturerName'],
        'quantityAvailable': !exists(json, 'quantityAvailable') ? undefined : json['quantityAvailable'],
        'quantityDisplay': !exists(json, 'quantityDisplay') ? undefined : json['quantityDisplay'],
        'listPrice': !exists(json, 'listPrice') ? undefined : json['listPrice'],
        'salePrice': !exists(json, 'salePrice') ? undefined : json['salePrice'],
        'corePrice': !exists(json, 'corePrice') ? undefined : json['corePrice'],
    };
}

export function RepairLinkPriceCheckItemToJSON(value?: RepairLinkPriceCheckItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestedPartNumber': value.requestedPartNumber,
        'supersededPartNumber': value.supersededPartNumber,
        'partId': value.partId,
        'isValid': value.isValid,
        'manufacturerName': value.manufacturerName,
        'quantityAvailable': value.quantityAvailable,
        'quantityDisplay': value.quantityDisplay,
        'listPrice': value.listPrice,
        'salePrice': value.salePrice,
        'corePrice': value.corePrice,
    };
}

