/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateRolePermissionsRequest
 */
export interface UpdateRolePermissionsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRolePermissionsRequest
     */
    role?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRolePermissionsRequest
     */
    permissions?: Array<UpdateRolePermissionsRequestPermissionsEnum>;
}


/**
 * @export
 */
export const UpdateRolePermissionsRequestPermissionsEnum = {
    Default: 'default_',
    AssignRoles: 'assign_roles',
    UpdateShopProfile: 'update_shop_profile',
    ManageCredentials: 'manage_credentials',
    ManagePricing: 'manage_pricing',
    DeleteShop: 'delete_shop',
    IsTechnician: 'is_technician',
    AssignToRepairOrder: 'assign_to_repair_order',
    ManageCannedJobs: 'manage_canned_jobs',
    DeleteRepairOrder: 'delete_repair_order',
    ReopenRepairOrder: 'reopen_repair_order',
    ManageRepairOrder: 'manage_repair_order',
    ManageResource: 'manage_resource',
    ManageAppointment: 'manage_appointment',
    AccessAdminReports: 'access_admin_reports',
    ManageTimeClock: 'manage_time_clock',
    ManageExpenseCards: 'manage_expense_cards'
} as const;
export type UpdateRolePermissionsRequestPermissionsEnum = typeof UpdateRolePermissionsRequestPermissionsEnum[keyof typeof UpdateRolePermissionsRequestPermissionsEnum];


/**
 * Check if a given object implements the UpdateRolePermissionsRequest interface.
 */
export function instanceOfUpdateRolePermissionsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateRolePermissionsRequestFromJSON(json: any): UpdateRolePermissionsRequest {
    return UpdateRolePermissionsRequestFromJSONTyped(json, false);
}

export function UpdateRolePermissionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRolePermissionsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': !exists(json, 'role') ? undefined : json['role'],
        'permissions': !exists(json, 'permissions') ? undefined : json['permissions'],
    };
}

export function UpdateRolePermissionsRequestToJSON(value?: UpdateRolePermissionsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
        'permissions': value.permissions,
    };
}

