/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CannedJobCustomLaborInfo
 */
export interface CannedJobCustomLaborInfo {
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomLaborInfo
     */
    cannedJob?: string;
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomLaborInfo
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CannedJobCustomLaborInfo
     */
    numHours?: number;
    /**
     * 
     * @type {number}
     * @memberof CannedJobCustomLaborInfo
     */
    hourlyRate?: number;
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomLaborInfo
     */
    laborMatrix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CannedJobCustomLaborInfo
     */
    taxExempt?: boolean;
}

/**
 * Check if a given object implements the CannedJobCustomLaborInfo interface.
 */
export function instanceOfCannedJobCustomLaborInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CannedJobCustomLaborInfoFromJSON(json: any): CannedJobCustomLaborInfo {
    return CannedJobCustomLaborInfoFromJSONTyped(json, false);
}

export function CannedJobCustomLaborInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CannedJobCustomLaborInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cannedJob': !exists(json, 'cannedJob') ? undefined : json['cannedJob'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'numHours': !exists(json, 'numHours') ? undefined : json['numHours'],
        'hourlyRate': !exists(json, 'hourlyRate') ? undefined : json['hourlyRate'],
        'laborMatrix': !exists(json, 'laborMatrix') ? undefined : json['laborMatrix'],
        'taxExempt': !exists(json, 'taxExempt') ? undefined : json['taxExempt'],
    };
}

export function CannedJobCustomLaborInfoToJSON(value?: CannedJobCustomLaborInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cannedJob': value.cannedJob,
        'name': value.name,
        'numHours': value.numHours,
        'hourlyRate': value.hourlyRate,
        'laborMatrix': value.laborMatrix,
        'taxExempt': value.taxExempt,
    };
}

