import { sdk } from '../../config'
import { shouldAlertOnErrors } from '../../config/configs'

type BugReportSeverity = 'error' | 'warning'

export const sendBugReport = (
  severity: BugReportSeverity,
  subject: string,
  body: string
) => {
  if (shouldAlertOnErrors()) {
    sdk.bugReport({
      severity,
      body,
      subject
    })
  }
}
