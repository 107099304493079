/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimeClockInfo
 */
export interface TimeClockInfo {
    /**
     * 
     * @type {string}
     * @memberof TimeClockInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeClockInfo
     */
    employee?: string;
    /**
     * 
     * @type {Date}
     * @memberof TimeClockInfo
     */
    clockInDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimeClockInfo
     */
    clockOutDateTime?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimeClockInfo
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof TimeClockInfo
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof TimeClockInfo
     */
    modifiedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof TimeClockInfo
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof TimeClockInfo
     */
    archivedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof TimeClockInfo
     */
    archivedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeClockInfo
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeClockInfo
     */
    repairOrder?: string;
}

/**
 * Check if a given object implements the TimeClockInfo interface.
 */
export function instanceOfTimeClockInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TimeClockInfoFromJSON(json: any): TimeClockInfo {
    return TimeClockInfoFromJSONTyped(json, false);
}

export function TimeClockInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeClockInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'employee': !exists(json, 'employee') ? undefined : json['employee'],
        'clockInDateTime': !exists(json, 'clockInDateTime') ? undefined : (new Date(json['clockInDateTime'])),
        'clockOutDateTime': !exists(json, 'clockOutDateTime') ? undefined : (new Date(json['clockOutDateTime'])),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'modifiedAt': !exists(json, 'modifiedAt') ? undefined : (new Date(json['modifiedAt'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'archivedAt': !exists(json, 'archivedAt') ? undefined : (new Date(json['archivedAt'])),
        'archivedBy': !exists(json, 'archivedBy') ? undefined : json['archivedBy'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'repairOrder': !exists(json, 'repairOrder') ? undefined : json['repairOrder'],
    };
}

export function TimeClockInfoToJSON(value?: TimeClockInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'employee': value.employee,
        'clockInDateTime': value.clockInDateTime === undefined ? undefined : (value.clockInDateTime.toISOString()),
        'clockOutDateTime': value.clockOutDateTime === undefined ? undefined : (value.clockOutDateTime.toISOString()),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'modifiedAt': value.modifiedAt === undefined ? undefined : (value.modifiedAt.toISOString()),
        'modifiedBy': value.modifiedBy,
        'archivedAt': value.archivedAt === undefined ? undefined : (value.archivedAt.toISOString()),
        'archivedBy': value.archivedBy,
        'status': value.status,
        'repairOrder': value.repairOrder,
    };
}

