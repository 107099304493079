/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentScheduleResponseRequest
 */
export interface AppointmentScheduleResponseRequest {
    /**
     * 
     * @type {string}
     * @memberof AppointmentScheduleResponseRequest
     */
    appointmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentScheduleResponseRequest
     */
    shopId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentScheduleResponseRequest
     */
    isRequestAccepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentScheduleResponseRequest
     */
    sendEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentScheduleResponseRequest
     */
    sendSms?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentScheduleResponseRequest
     */
    newDateTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof AppointmentScheduleResponseRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentScheduleResponseRequest
     */
    message?: string;
}

/**
 * Check if a given object implements the AppointmentScheduleResponseRequest interface.
 */
export function instanceOfAppointmentScheduleResponseRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AppointmentScheduleResponseRequestFromJSON(json: any): AppointmentScheduleResponseRequest {
    return AppointmentScheduleResponseRequestFromJSONTyped(json, false);
}

export function AppointmentScheduleResponseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentScheduleResponseRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appointmentId': !exists(json, 'appointmentId') ? undefined : json['appointmentId'],
        'shopId': !exists(json, 'shopId') ? undefined : json['shopId'],
        'isRequestAccepted': !exists(json, 'isRequestAccepted') ? undefined : json['isRequestAccepted'],
        'sendEmail': !exists(json, 'sendEmail') ? undefined : json['sendEmail'],
        'sendSms': !exists(json, 'sendSms') ? undefined : json['sendSms'],
        'newDateTime': !exists(json, 'newDateTime') ? undefined : (new Date(json['newDateTime'])),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function AppointmentScheduleResponseRequestToJSON(value?: AppointmentScheduleResponseRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appointmentId': value.appointmentId,
        'shopId': value.shopId,
        'isRequestAccepted': value.isRequestAccepted,
        'sendEmail': value.sendEmail,
        'sendSms': value.sendSms,
        'newDateTime': value.newDateTime === undefined ? undefined : (value.newDateTime.toISOString()),
        'title': value.title,
        'message': value.message,
    };
}

