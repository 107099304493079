/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SerializableExpenseGroupProperty
 */
export interface SerializableExpenseGroupProperty {
    /**
     * 
     * @type {string}
     * @memberof SerializableExpenseGroupProperty
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SerializableExpenseGroupProperty
     */
    types?: Array<string>;
}

/**
 * Check if a given object implements the SerializableExpenseGroupProperty interface.
 */
export function instanceOfSerializableExpenseGroupProperty(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SerializableExpenseGroupPropertyFromJSON(json: any): SerializableExpenseGroupProperty {
    return SerializableExpenseGroupPropertyFromJSONTyped(json, false);
}

export function SerializableExpenseGroupPropertyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerializableExpenseGroupProperty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'types': !exists(json, 'types') ? undefined : json['types'],
    };
}

export function SerializableExpenseGroupPropertyToJSON(value?: SerializableExpenseGroupProperty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'types': value.types,
    };
}

