/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IdName } from './IdName';
import {
    IdNameFromJSON,
    IdNameFromJSONTyped,
    IdNameToJSON,
} from './IdName';

/**
 * 
 * @export
 * @interface VehicleInformation
 */
export interface VehicleInformation {
    /**
     * 
     * @type {string}
     * @memberof VehicleInformation
     */
    vin?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleInformation
     */
    registeredCountry?: VehicleInformationRegisteredCountryEnum;
    /**
     * 
     * @type {string}
     * @memberof VehicleInformation
     */
    registeredState?: VehicleInformationRegisteredStateEnum;
    /**
     * 
     * @type {string}
     * @memberof VehicleInformation
     */
    licensePlate?: string;
    /**
     * 
     * @type {IdName}
     * @memberof VehicleInformation
     */
    make?: IdName;
    /**
     * 
     * @type {IdName}
     * @memberof VehicleInformation
     */
    model?: IdName;
    /**
     * 
     * @type {IdName}
     * @memberof VehicleInformation
     */
    subModel?: IdName;
    /**
     * 
     * @type {IdName}
     * @memberof VehicleInformation
     */
    engine?: IdName;
    /**
     * 
     * @type {number}
     * @memberof VehicleInformation
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof VehicleInformation
     */
    mileage?: number;
    /**
     * 
     * @type {Date}
     * @memberof VehicleInformation
     */
    mileageDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VehicleInformation
     */
    manufactureDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof VehicleInformation
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleInformation
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleInformation
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof VehicleInformation
     */
    nextInspectionDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VehicleInformation
     */
    nextOilServiceDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof VehicleInformation
     */
    nextOilServiceMileage?: number;
    /**
     * 
     * @type {Date}
     * @memberof VehicleInformation
     */
    archived?: Date;
}


/**
 * @export
 */
export const VehicleInformationRegisteredCountryEnum = {
    Usa: 'USA',
    Canada: 'Canada'
} as const;
export type VehicleInformationRegisteredCountryEnum = typeof VehicleInformationRegisteredCountryEnum[keyof typeof VehicleInformationRegisteredCountryEnum];

/**
 * @export
 */
export const VehicleInformationRegisteredStateEnum = {
    Al: 'AL',
    Ak: 'AK',
    Az: 'AZ',
    Ar: 'AR',
    Ca: 'CA',
    Co: 'CO',
    Ct: 'CT',
    De: 'DE',
    Fl: 'FL',
    Ga: 'GA',
    Hi: 'HI',
    Id: 'ID',
    Il: 'IL',
    In: 'IN',
    Ia: 'IA',
    Ks: 'KS',
    Ky: 'KY',
    La: 'LA',
    Me: 'ME',
    Md: 'MD',
    Ma: 'MA',
    Mi: 'MI',
    Mn: 'MN',
    Ms: 'MS',
    Mo: 'MO',
    Mt: 'MT',
    Ne: 'NE',
    Nv: 'NV',
    Nh: 'NH',
    Nj: 'NJ',
    Nm: 'NM',
    Ny: 'NY',
    Nc: 'NC',
    Nd: 'ND',
    Oh: 'OH',
    Ok: 'OK',
    Or: 'OR',
    Pa: 'PA',
    Ri: 'RI',
    Sc: 'SC',
    Sd: 'SD',
    Tn: 'TN',
    Tx: 'TX',
    Ut: 'UT',
    Vt: 'VT',
    Va: 'VA',
    Wa: 'WA',
    Wv: 'WV',
    Wi: 'WI',
    Wy: 'WY',
    Dc: 'DC'
} as const;
export type VehicleInformationRegisteredStateEnum = typeof VehicleInformationRegisteredStateEnum[keyof typeof VehicleInformationRegisteredStateEnum];


/**
 * Check if a given object implements the VehicleInformation interface.
 */
export function instanceOfVehicleInformation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleInformationFromJSON(json: any): VehicleInformation {
    return VehicleInformationFromJSONTyped(json, false);
}

export function VehicleInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
        'registeredCountry': !exists(json, 'registeredCountry') ? undefined : json['registeredCountry'],
        'registeredState': !exists(json, 'registeredState') ? undefined : json['registeredState'],
        'licensePlate': !exists(json, 'licensePlate') ? undefined : json['licensePlate'],
        'make': !exists(json, 'make') ? undefined : IdNameFromJSON(json['make']),
        'model': !exists(json, 'model') ? undefined : IdNameFromJSON(json['model']),
        'subModel': !exists(json, 'subModel') ? undefined : IdNameFromJSON(json['subModel']),
        'engine': !exists(json, 'engine') ? undefined : IdNameFromJSON(json['engine']),
        'year': !exists(json, 'year') ? undefined : json['year'],
        'mileage': !exists(json, 'mileage') ? undefined : json['mileage'],
        'mileageDate': !exists(json, 'mileageDate') ? undefined : (new Date(json['mileageDate'])),
        'manufactureDate': !exists(json, 'manufactureDate') ? undefined : (new Date(json['manufactureDate'])),
        'color': !exists(json, 'color') ? undefined : json['color'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'nextInspectionDate': !exists(json, 'nextInspectionDate') ? undefined : (new Date(json['nextInspectionDate'])),
        'nextOilServiceDate': !exists(json, 'nextOilServiceDate') ? undefined : (new Date(json['nextOilServiceDate'])),
        'nextOilServiceMileage': !exists(json, 'nextOilServiceMileage') ? undefined : json['nextOilServiceMileage'],
        'archived': !exists(json, 'archived') ? undefined : (new Date(json['archived'])),
    };
}

export function VehicleInformationToJSON(value?: VehicleInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vin': value.vin,
        'registeredCountry': value.registeredCountry,
        'registeredState': value.registeredState,
        'licensePlate': value.licensePlate,
        'make': IdNameToJSON(value.make),
        'model': IdNameToJSON(value.model),
        'subModel': IdNameToJSON(value.subModel),
        'engine': IdNameToJSON(value.engine),
        'year': value.year,
        'mileage': value.mileage,
        'mileageDate': value.mileageDate === undefined ? undefined : (value.mileageDate.toISOString().substr(0,10)),
        'manufactureDate': value.manufactureDate === undefined ? undefined : (value.manufactureDate.toISOString().substr(0,10)),
        'color': value.color,
        'notes': value.notes,
        'name': value.name,
        'nextInspectionDate': value.nextInspectionDate === undefined ? undefined : (value.nextInspectionDate.toISOString()),
        'nextOilServiceDate': value.nextOilServiceDate === undefined ? undefined : (value.nextOilServiceDate.toISOString()),
        'nextOilServiceMileage': value.nextOilServiceMileage,
        'archived': value.archived === undefined ? undefined : (value.archived.toISOString()),
    };
}

