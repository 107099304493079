/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SectionInfo } from './SectionInfo';
import {
    SectionInfoFromJSON,
    SectionInfoFromJSONTyped,
    SectionInfoToJSON,
} from './SectionInfo';

/**
 * 
 * @export
 * @interface SectionEntry
 */
export interface SectionEntry {
    /**
     * 
     * @type {string}
     * @memberof SectionEntry
     */
    id?: string;
    /**
     * 
     * @type {SectionInfo}
     * @memberof SectionEntry
     */
    info?: SectionInfo;
}

/**
 * Check if a given object implements the SectionEntry interface.
 */
export function instanceOfSectionEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SectionEntryFromJSON(json: any): SectionEntry {
    return SectionEntryFromJSONTyped(json, false);
}

export function SectionEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SectionEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : SectionInfoFromJSON(json['info']),
    };
}

export function SectionEntryToJSON(value?: SectionEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': SectionInfoToJSON(value.info),
    };
}

