/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SerializableBlock } from './SerializableBlock';
import {
    SerializableBlockFromJSON,
    SerializableBlockFromJSONTyped,
    SerializableBlockToJSON,
} from './SerializableBlock';
import type { SerializableExpenseField } from './SerializableExpenseField';
import {
    SerializableExpenseFieldFromJSON,
    SerializableExpenseFieldFromJSONTyped,
    SerializableExpenseFieldToJSON,
} from './SerializableExpenseField';
import type { SerializableLineItemGroup } from './SerializableLineItemGroup';
import {
    SerializableLineItemGroupFromJSON,
    SerializableLineItemGroupFromJSONTyped,
    SerializableLineItemGroupToJSON,
} from './SerializableLineItemGroup';

/**
 * 
 * @export
 * @interface SerializableExpenseDocument
 */
export interface SerializableExpenseDocument {
    /**
     * 
     * @type {Array<SerializableBlock>}
     * @memberof SerializableExpenseDocument
     */
    blocks?: Array<SerializableBlock>;
    /**
     * 
     * @type {number}
     * @memberof SerializableExpenseDocument
     */
    expenseIndex?: number;
    /**
     * 
     * @type {Array<SerializableLineItemGroup>}
     * @memberof SerializableExpenseDocument
     */
    lineItemGroups?: Array<SerializableLineItemGroup>;
    /**
     * 
     * @type {Array<SerializableExpenseField>}
     * @memberof SerializableExpenseDocument
     */
    summaryFields?: Array<SerializableExpenseField>;
}

/**
 * Check if a given object implements the SerializableExpenseDocument interface.
 */
export function instanceOfSerializableExpenseDocument(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SerializableExpenseDocumentFromJSON(json: any): SerializableExpenseDocument {
    return SerializableExpenseDocumentFromJSONTyped(json, false);
}

export function SerializableExpenseDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerializableExpenseDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blocks': !exists(json, 'blocks') ? undefined : ((json['blocks'] as Array<any>).map(SerializableBlockFromJSON)),
        'expenseIndex': !exists(json, 'expenseIndex') ? undefined : json['expenseIndex'],
        'lineItemGroups': !exists(json, 'lineItemGroups') ? undefined : ((json['lineItemGroups'] as Array<any>).map(SerializableLineItemGroupFromJSON)),
        'summaryFields': !exists(json, 'summaryFields') ? undefined : ((json['summaryFields'] as Array<any>).map(SerializableExpenseFieldFromJSON)),
    };
}

export function SerializableExpenseDocumentToJSON(value?: SerializableExpenseDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blocks': value.blocks === undefined ? undefined : ((value.blocks as Array<any>).map(SerializableBlockToJSON)),
        'expenseIndex': value.expenseIndex,
        'lineItemGroups': value.lineItemGroups === undefined ? undefined : ((value.lineItemGroups as Array<any>).map(SerializableLineItemGroupToJSON)),
        'summaryFields': value.summaryFields === undefined ? undefined : ((value.summaryFields as Array<any>).map(SerializableExpenseFieldToJSON)),
    };
}

