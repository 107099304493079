/* eslint-disable no-console */
import { DEFAULT_ERROR_MESSAGE } from '../../config'
import { sendBugReport } from './sendBugReport'

export const handleGenericError = (e: unknown) => {
  if (e instanceof Error) {
    // Only V8 browsers have stack trace functionality
    let stackTrace = e.stack ?? '(Not available)'
    if (!e.stack && Error.captureStackTrace) {
      Error.captureStackTrace(e)
      stackTrace = e.stack!
    }
    sendBugReport(
      'error',
      `Error encountered: Name = "${e.name}" Message = "${e.message}"`,
      `Stack trace: ${stackTrace}`
    )
    if (import.meta.env.MODE === 'development') {
      console.error(
        `Error encountered: Name = "${e.name}" Message = "${e.message}"`
      )
      console.error(`Stack trace: ${stackTrace}`)
    }
  } else {
    const errorType = typeof e
    const strRep = JSON.stringify(e)
    const stackTrace = new Error().stack
    sendBugReport(
      'error',
      `Encountered error of type ${errorType}: ${strRep.slice(0, 50)}`,
      `String contents: ${strRep} \nStack trace: ${stackTrace}`
    )
    if (import.meta.env.MODE === 'development') {
      console.error(
        `Encountered error of type ${errorType}: ${strRep.slice(0, 50)}`
      )
      console.error(`String contents: ${strRep} \nStack trace: ${stackTrace}`)
    }
  }
  return DEFAULT_ERROR_MESSAGE
}
