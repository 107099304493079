/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductivityStatusInfo } from './ProductivityStatusInfo';
import {
    ProductivityStatusInfoFromJSON,
    ProductivityStatusInfoFromJSONTyped,
    ProductivityStatusInfoToJSON,
} from './ProductivityStatusInfo';

/**
 * 
 * @export
 * @interface ProductivityStatusEntry
 */
export interface ProductivityStatusEntry {
    /**
     * 
     * @type {string}
     * @memberof ProductivityStatusEntry
     */
    id?: string;
    /**
     * 
     * @type {ProductivityStatusInfo}
     * @memberof ProductivityStatusEntry
     */
    info?: ProductivityStatusInfo;
}

/**
 * Check if a given object implements the ProductivityStatusEntry interface.
 */
export function instanceOfProductivityStatusEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductivityStatusEntryFromJSON(json: any): ProductivityStatusEntry {
    return ProductivityStatusEntryFromJSONTyped(json, false);
}

export function ProductivityStatusEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductivityStatusEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : ProductivityStatusInfoFromJSON(json['info']),
    };
}

export function ProductivityStatusEntryToJSON(value?: ProductivityStatusEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': ProductivityStatusInfoToJSON(value.info),
    };
}

