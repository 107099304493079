/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PartTaxonomy } from './PartTaxonomy';
import {
    PartTaxonomyFromJSON,
    PartTaxonomyFromJSONTyped,
    PartTaxonomyToJSON,
} from './PartTaxonomy';

/**
 * 
 * @export
 * @interface CannedJobCustomPartInfo
 */
export interface CannedJobCustomPartInfo {
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomPartInfo
     */
    cannedJob?: string;
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomPartInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomPartInfo
     */
    counterSale?: string;
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomPartInfo
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CannedJobCustomPartInfo
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomPartInfo
     */
    unitOfMeasure?: CannedJobCustomPartInfoUnitOfMeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof CannedJobCustomPartInfo
     */
    costPerUnit?: number;
    /**
     * 
     * @type {number}
     * @memberof CannedJobCustomPartInfo
     */
    retailPricePerUnit?: number;
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomPartInfo
     */
    partsMatrix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CannedJobCustomPartInfo
     */
    taxExempt?: boolean;
    /**
     * 
     * @type {PartTaxonomy}
     * @memberof CannedJobCustomPartInfo
     */
    partTaxonomy?: PartTaxonomy;
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomPartInfo
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomPartInfo
     */
    partNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomPartInfo
     */
    supplierName?: string;
    /**
     * 
     * @type {Date}
     * @memberof CannedJobCustomPartInfo
     */
    archivedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CannedJobCustomPartInfo
     */
    vendorId?: string;
}


/**
 * @export
 */
export const CannedJobCustomPartInfoUnitOfMeasureEnum = {
    DiscreteUnit: 'discrete_unit',
    Liter: 'liter',
    Ml: 'ml',
    Gallon: 'gallon',
    Quart: 'quart',
    Ounce: 'ounce',
    FlOz: 'fl_oz'
} as const;
export type CannedJobCustomPartInfoUnitOfMeasureEnum = typeof CannedJobCustomPartInfoUnitOfMeasureEnum[keyof typeof CannedJobCustomPartInfoUnitOfMeasureEnum];


/**
 * Check if a given object implements the CannedJobCustomPartInfo interface.
 */
export function instanceOfCannedJobCustomPartInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CannedJobCustomPartInfoFromJSON(json: any): CannedJobCustomPartInfo {
    return CannedJobCustomPartInfoFromJSONTyped(json, false);
}

export function CannedJobCustomPartInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CannedJobCustomPartInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cannedJob': !exists(json, 'cannedJob') ? undefined : json['cannedJob'],
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'counterSale': !exists(json, 'counterSale') ? undefined : json['counterSale'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'unitOfMeasure': !exists(json, 'unitOfMeasure') ? undefined : json['unitOfMeasure'],
        'costPerUnit': !exists(json, 'costPerUnit') ? undefined : json['costPerUnit'],
        'retailPricePerUnit': !exists(json, 'retailPricePerUnit') ? undefined : json['retailPricePerUnit'],
        'partsMatrix': !exists(json, 'partsMatrix') ? undefined : json['partsMatrix'],
        'taxExempt': !exists(json, 'taxExempt') ? undefined : json['taxExempt'],
        'partTaxonomy': !exists(json, 'partTaxonomy') ? undefined : PartTaxonomyFromJSON(json['partTaxonomy']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'partNumber': !exists(json, 'partNumber') ? undefined : json['partNumber'],
        'supplierName': !exists(json, 'supplierName') ? undefined : json['supplierName'],
        'archivedAt': !exists(json, 'archivedAt') ? undefined : (new Date(json['archivedAt'])),
        'vendorId': !exists(json, 'vendorId') ? undefined : json['vendorId'],
    };
}

export function CannedJobCustomPartInfoToJSON(value?: CannedJobCustomPartInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cannedJob': value.cannedJob,
        'shop': value.shop,
        'counterSale': value.counterSale,
        'name': value.name,
        'quantity': value.quantity,
        'unitOfMeasure': value.unitOfMeasure,
        'costPerUnit': value.costPerUnit,
        'retailPricePerUnit': value.retailPricePerUnit,
        'partsMatrix': value.partsMatrix,
        'taxExempt': value.taxExempt,
        'partTaxonomy': PartTaxonomyToJSON(value.partTaxonomy),
        'description': value.description,
        'partNumber': value.partNumber,
        'supplierName': value.supplierName,
        'archivedAt': value.archivedAt === undefined ? undefined : (value.archivedAt.toISOString()),
        'vendorId': value.vendorId,
    };
}

