/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OilFilter
 */
export interface OilFilter {
    /**
     * 
     * @type {string}
     * @memberof OilFilter
     */
    partNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OilFilter
     */
    variantParameter?: string;
    /**
     * 
     * @type {number}
     * @memberof OilFilter
     */
    brandId?: number;
    /**
     * 
     * @type {string}
     * @memberof OilFilter
     */
    brandName?: string;
}

/**
 * Check if a given object implements the OilFilter interface.
 */
export function instanceOfOilFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OilFilterFromJSON(json: any): OilFilter {
    return OilFilterFromJSONTyped(json, false);
}

export function OilFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): OilFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partNumber': !exists(json, 'partNumber') ? undefined : json['partNumber'],
        'variantParameter': !exists(json, 'variantParameter') ? undefined : json['variantParameter'],
        'brandId': !exists(json, 'brandId') ? undefined : json['brandId'],
        'brandName': !exists(json, 'brandName') ? undefined : json['brandName'],
    };
}

export function OilFilterToJSON(value?: OilFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partNumber': value.partNumber,
        'variantParameter': value.variantParameter,
        'brandId': value.brandId,
        'brandName': value.brandName,
    };
}

