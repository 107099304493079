/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OilFilter } from './OilFilter';
import {
    OilFilterFromJSON,
    OilFilterFromJSONTyped,
    OilFilterToJSON,
} from './OilFilter';
import type { VehicleYMMSE } from './VehicleYMMSE';
import {
    VehicleYMMSEFromJSON,
    VehicleYMMSEFromJSONTyped,
    VehicleYMMSEToJSON,
} from './VehicleYMMSE';

/**
 * 
 * @export
 * @interface VehicleOilFilterInfo
 */
export interface VehicleOilFilterInfo {
    /**
     * 
     * @type {string}
     * @memberof VehicleOilFilterInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleOilFilterInfo
     */
    vehicle?: string;
    /**
     * 
     * @type {VehicleYMMSE}
     * @memberof VehicleOilFilterInfo
     */
    vehicleYMMSE?: VehicleYMMSE;
    /**
     * 
     * @type {OilFilter}
     * @memberof VehicleOilFilterInfo
     */
    oilFilter?: OilFilter;
    /**
     * 
     * @type {boolean}
     * @memberof VehicleOilFilterInfo
     */
    isCustom?: boolean;
}

/**
 * Check if a given object implements the VehicleOilFilterInfo interface.
 */
export function instanceOfVehicleOilFilterInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleOilFilterInfoFromJSON(json: any): VehicleOilFilterInfo {
    return VehicleOilFilterInfoFromJSONTyped(json, false);
}

export function VehicleOilFilterInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleOilFilterInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'vehicle': !exists(json, 'vehicle') ? undefined : json['vehicle'],
        'vehicleYMMSE': !exists(json, 'vehicleYMMSE') ? undefined : VehicleYMMSEFromJSON(json['vehicleYMMSE']),
        'oilFilter': !exists(json, 'oilFilter') ? undefined : OilFilterFromJSON(json['oilFilter']),
        'isCustom': !exists(json, 'isCustom') ? undefined : json['isCustom'],
    };
}

export function VehicleOilFilterInfoToJSON(value?: VehicleOilFilterInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'vehicle': value.vehicle,
        'vehicleYMMSE': VehicleYMMSEToJSON(value.vehicleYMMSE),
        'oilFilter': OilFilterToJSON(value.oilFilter),
        'isCustom': value.isCustom,
    };
}

