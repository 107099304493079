/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentRequestCardNotPresent
 */
export interface PaymentRequestCardNotPresent {
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestCardNotPresent
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestCardNotPresent
     */
    repairOrder?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestCardNotPresent
     */
    counterSale?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestCardNotPresent
     */
    token?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentRequestCardNotPresent
     */
    amountCents?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentRequestCardNotPresent
     */
    surchargeCents?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentRequestCardNotPresent
     */
    useLevelOneProcessing?: boolean;
}

/**
 * Check if a given object implements the PaymentRequestCardNotPresent interface.
 */
export function instanceOfPaymentRequestCardNotPresent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaymentRequestCardNotPresentFromJSON(json: any): PaymentRequestCardNotPresent {
    return PaymentRequestCardNotPresentFromJSONTyped(json, false);
}

export function PaymentRequestCardNotPresentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentRequestCardNotPresent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'repairOrder': !exists(json, 'repairOrder') ? undefined : json['repairOrder'],
        'counterSale': !exists(json, 'counterSale') ? undefined : json['counterSale'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'amountCents': !exists(json, 'amountCents') ? undefined : json['amountCents'],
        'surchargeCents': !exists(json, 'surchargeCents') ? undefined : json['surchargeCents'],
        'useLevelOneProcessing': !exists(json, 'useLevelOneProcessing') ? undefined : json['useLevelOneProcessing'],
    };
}

export function PaymentRequestCardNotPresentToJSON(value?: PaymentRequestCardNotPresent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'repairOrder': value.repairOrder,
        'counterSale': value.counterSale,
        'token': value.token,
        'amountCents': value.amountCents,
        'surchargeCents': value.surchargeCents,
        'useLevelOneProcessing': value.useLevelOneProcessing,
    };
}

