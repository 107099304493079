/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Rrule } from './Rrule';
import {
    RruleFromJSON,
    RruleFromJSONTyped,
    RruleToJSON,
} from './Rrule';

/**
 * 
 * @export
 * @interface AutomatedVehicleReminderInfo
 */
export interface AutomatedVehicleReminderInfo {
    /**
     * 
     * @type {string}
     * @memberof AutomatedVehicleReminderInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof AutomatedVehicleReminderInfo
     */
    type?: AutomatedVehicleReminderInfoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AutomatedVehicleReminderInfo
     */
    timeframe?: AutomatedVehicleReminderInfoTimeframeEnum;
    /**
     * 
     * @type {Rrule}
     * @memberof AutomatedVehicleReminderInfo
     */
    rrule?: Rrule;
}


/**
 * @export
 */
export const AutomatedVehicleReminderInfoTypeEnum = {
    Inspection: 'inspection',
    OilService: 'oil_service'
} as const;
export type AutomatedVehicleReminderInfoTypeEnum = typeof AutomatedVehicleReminderInfoTypeEnum[keyof typeof AutomatedVehicleReminderInfoTypeEnum];

/**
 * @export
 */
export const AutomatedVehicleReminderInfoTimeframeEnum = {
    LastMonth: 'Last_Month',
    Last2Weeks: 'Last_2_Weeks',
    LastWeek: 'Last_Week',
    NextWeek: 'Next_Week',
    Next2Weeks: 'Next_2_Weeks',
    NextMonth: 'Next_Month'
} as const;
export type AutomatedVehicleReminderInfoTimeframeEnum = typeof AutomatedVehicleReminderInfoTimeframeEnum[keyof typeof AutomatedVehicleReminderInfoTimeframeEnum];


/**
 * Check if a given object implements the AutomatedVehicleReminderInfo interface.
 */
export function instanceOfAutomatedVehicleReminderInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AutomatedVehicleReminderInfoFromJSON(json: any): AutomatedVehicleReminderInfo {
    return AutomatedVehicleReminderInfoFromJSONTyped(json, false);
}

export function AutomatedVehicleReminderInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AutomatedVehicleReminderInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'timeframe': !exists(json, 'timeframe') ? undefined : json['timeframe'],
        'rrule': !exists(json, 'rrule') ? undefined : RruleFromJSON(json['rrule']),
    };
}

export function AutomatedVehicleReminderInfoToJSON(value?: AutomatedVehicleReminderInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'type': value.type,
        'timeframe': value.timeframe,
        'rrule': RruleToJSON(value.rrule),
    };
}

