/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RepairOrderCodatLineItem } from './RepairOrderCodatLineItem';
import {
    RepairOrderCodatLineItemFromJSON,
    RepairOrderCodatLineItemFromJSONTyped,
    RepairOrderCodatLineItemToJSON,
} from './RepairOrderCodatLineItem';

/**
 * 
 * @export
 * @interface RepairOrderCodatLineItemsEntry
 */
export interface RepairOrderCodatLineItemsEntry {
    /**
     * 
     * @type {string}
     * @memberof RepairOrderCodatLineItemsEntry
     */
    shopId?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderCodatLineItemsEntry
     */
    companyId?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderCodatLineItemsEntry
     */
    shopDefaultItemId?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderCodatLineItemsEntry
     */
    repairOrderId?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderCodatLineItemsEntry
     */
    localId?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderCodatLineItemsEntry
     */
    codatInvoiceId?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderCodatLineItemsEntry
     */
    codatInvoicePushKey?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderCodatLineItemsEntry
     */
    customerId?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderCodatLineItemsEntry
     */
    customerName?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderCodatLineItemsEntry
     */
    codatCustomerId?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderCodatLineItemsEntry
     */
    customerCodatPushKey?: string;
    /**
     * 
     * @type {Date}
     * @memberof RepairOrderCodatLineItemsEntry
     */
    closedOn?: Date;
    /**
     * 
     * @type {Array<RepairOrderCodatLineItem>}
     * @memberof RepairOrderCodatLineItemsEntry
     */
    lineItems?: Array<RepairOrderCodatLineItem>;
}

/**
 * Check if a given object implements the RepairOrderCodatLineItemsEntry interface.
 */
export function instanceOfRepairOrderCodatLineItemsEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairOrderCodatLineItemsEntryFromJSON(json: any): RepairOrderCodatLineItemsEntry {
    return RepairOrderCodatLineItemsEntryFromJSONTyped(json, false);
}

export function RepairOrderCodatLineItemsEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairOrderCodatLineItemsEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shopId': !exists(json, 'shopId') ? undefined : json['shopId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'shopDefaultItemId': !exists(json, 'shopDefaultItemId') ? undefined : json['shopDefaultItemId'],
        'repairOrderId': !exists(json, 'repairOrderId') ? undefined : json['repairOrderId'],
        'localId': !exists(json, 'localId') ? undefined : json['localId'],
        'codatInvoiceId': !exists(json, 'codatInvoiceId') ? undefined : json['codatInvoiceId'],
        'codatInvoicePushKey': !exists(json, 'codatInvoicePushKey') ? undefined : json['codatInvoicePushKey'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'customerName': !exists(json, 'customerName') ? undefined : json['customerName'],
        'codatCustomerId': !exists(json, 'codatCustomerId') ? undefined : json['codatCustomerId'],
        'customerCodatPushKey': !exists(json, 'customerCodatPushKey') ? undefined : json['customerCodatPushKey'],
        'closedOn': !exists(json, 'closedOn') ? undefined : (new Date(json['closedOn'])),
        'lineItems': !exists(json, 'lineItems') ? undefined : ((json['lineItems'] as Array<any>).map(RepairOrderCodatLineItemFromJSON)),
    };
}

export function RepairOrderCodatLineItemsEntryToJSON(value?: RepairOrderCodatLineItemsEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shopId': value.shopId,
        'companyId': value.companyId,
        'shopDefaultItemId': value.shopDefaultItemId,
        'repairOrderId': value.repairOrderId,
        'localId': value.localId,
        'codatInvoiceId': value.codatInvoiceId,
        'codatInvoicePushKey': value.codatInvoicePushKey,
        'customerId': value.customerId,
        'customerName': value.customerName,
        'codatCustomerId': value.codatCustomerId,
        'customerCodatPushKey': value.customerCodatPushKey,
        'closedOn': value.closedOn === undefined ? undefined : (value.closedOn.toISOString()),
        'lineItems': value.lineItems === undefined ? undefined : ((value.lineItems as Array<any>).map(RepairOrderCodatLineItemToJSON)),
    };
}

