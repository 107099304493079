/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductivityStatusInfo
 */
export interface ProductivityStatusInfo {
    /**
     * 
     * @type {string}
     * @memberof ProductivityStatusInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductivityStatusInfo
     */
    title?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductivityStatusInfo
     */
    requireRepairOrder?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ProductivityStatusInfo
     */
    archivedAt?: Date;
}

/**
 * Check if a given object implements the ProductivityStatusInfo interface.
 */
export function instanceOfProductivityStatusInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProductivityStatusInfoFromJSON(json: any): ProductivityStatusInfo {
    return ProductivityStatusInfoFromJSONTyped(json, false);
}

export function ProductivityStatusInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductivityStatusInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'requireRepairOrder': !exists(json, 'requireRepairOrder') ? undefined : json['requireRepairOrder'],
        'archivedAt': !exists(json, 'archivedAt') ? undefined : (new Date(json['archivedAt'])),
    };
}

export function ProductivityStatusInfoToJSON(value?: ProductivityStatusInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'title': value.title,
        'requireRepairOrder': value.requireRepairOrder,
        'archivedAt': value.archivedAt === undefined ? undefined : (value.archivedAt.toISOString()),
    };
}

