/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EntryEntry } from './EntryEntry';
import {
    EntryEntryFromJSON,
    EntryEntryFromJSONTyped,
    EntryEntryToJSON,
} from './EntryEntry';
import type { InspectionAnswerToCannedJobEntry } from './InspectionAnswerToCannedJobEntry';
import {
    InspectionAnswerToCannedJobEntryFromJSON,
    InspectionAnswerToCannedJobEntryFromJSONTyped,
    InspectionAnswerToCannedJobEntryToJSON,
} from './InspectionAnswerToCannedJobEntry';
import type { InspectionEntry } from './InspectionEntry';
import {
    InspectionEntryFromJSON,
    InspectionEntryFromJSONTyped,
    InspectionEntryToJSON,
} from './InspectionEntry';
import type { InspectionEntryStatusEntry } from './InspectionEntryStatusEntry';
import {
    InspectionEntryStatusEntryFromJSON,
    InspectionEntryStatusEntryFromJSONTyped,
    InspectionEntryStatusEntryToJSON,
} from './InspectionEntryStatusEntry';
import type { InspectionMediaEntry } from './InspectionMediaEntry';
import {
    InspectionMediaEntryFromJSON,
    InspectionMediaEntryFromJSONTyped,
    InspectionMediaEntryToJSON,
} from './InspectionMediaEntry';
import type { MultipleChoiceAnswerEntry } from './MultipleChoiceAnswerEntry';
import {
    MultipleChoiceAnswerEntryFromJSON,
    MultipleChoiceAnswerEntryFromJSONTyped,
    MultipleChoiceAnswerEntryToJSON,
} from './MultipleChoiceAnswerEntry';
import type { PromptEntry } from './PromptEntry';
import {
    PromptEntryFromJSON,
    PromptEntryFromJSONTyped,
    PromptEntryToJSON,
} from './PromptEntry';
import type { SectionEntry } from './SectionEntry';
import {
    SectionEntryFromJSON,
    SectionEntryFromJSONTyped,
    SectionEntryToJSON,
} from './SectionEntry';

/**
 * 
 * @export
 * @interface InspectionExpanded
 */
export interface InspectionExpanded {
    /**
     * 
     * @type {InspectionEntry}
     * @memberof InspectionExpanded
     */
    inspection?: InspectionEntry;
    /**
     * 
     * @type {Array<SectionEntry>}
     * @memberof InspectionExpanded
     */
    sections?: Array<SectionEntry>;
    /**
     * 
     * @type {Array<EntryEntry>}
     * @memberof InspectionExpanded
     */
    entries?: Array<EntryEntry>;
    /**
     * 
     * @type {Array<PromptEntry>}
     * @memberof InspectionExpanded
     */
    prompts?: Array<PromptEntry>;
    /**
     * 
     * @type {Array<MultipleChoiceAnswerEntry>}
     * @memberof InspectionExpanded
     */
    multipleChoiceAnswers?: Array<MultipleChoiceAnswerEntry>;
    /**
     * 
     * @type {Array<InspectionAnswerToCannedJobEntry>}
     * @memberof InspectionExpanded
     */
    answerToCannedJobs?: Array<InspectionAnswerToCannedJobEntry>;
    /**
     * 
     * @type {Array<InspectionMediaEntry>}
     * @memberof InspectionExpanded
     */
    media?: Array<InspectionMediaEntry>;
    /**
     * 
     * @type {Array<InspectionEntryStatusEntry>}
     * @memberof InspectionExpanded
     */
    entryStatuses?: Array<InspectionEntryStatusEntry>;
}

/**
 * Check if a given object implements the InspectionExpanded interface.
 */
export function instanceOfInspectionExpanded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InspectionExpandedFromJSON(json: any): InspectionExpanded {
    return InspectionExpandedFromJSONTyped(json, false);
}

export function InspectionExpandedFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionExpanded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspection': !exists(json, 'inspection') ? undefined : InspectionEntryFromJSON(json['inspection']),
        'sections': !exists(json, 'sections') ? undefined : ((json['sections'] as Array<any>).map(SectionEntryFromJSON)),
        'entries': !exists(json, 'entries') ? undefined : ((json['entries'] as Array<any>).map(EntryEntryFromJSON)),
        'prompts': !exists(json, 'prompts') ? undefined : ((json['prompts'] as Array<any>).map(PromptEntryFromJSON)),
        'multipleChoiceAnswers': !exists(json, 'multipleChoiceAnswers') ? undefined : ((json['multipleChoiceAnswers'] as Array<any>).map(MultipleChoiceAnswerEntryFromJSON)),
        'answerToCannedJobs': !exists(json, 'answerToCannedJobs') ? undefined : ((json['answerToCannedJobs'] as Array<any>).map(InspectionAnswerToCannedJobEntryFromJSON)),
        'media': !exists(json, 'media') ? undefined : ((json['media'] as Array<any>).map(InspectionMediaEntryFromJSON)),
        'entryStatuses': !exists(json, 'entryStatuses') ? undefined : ((json['entryStatuses'] as Array<any>).map(InspectionEntryStatusEntryFromJSON)),
    };
}

export function InspectionExpandedToJSON(value?: InspectionExpanded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspection': InspectionEntryToJSON(value.inspection),
        'sections': value.sections === undefined ? undefined : ((value.sections as Array<any>).map(SectionEntryToJSON)),
        'entries': value.entries === undefined ? undefined : ((value.entries as Array<any>).map(EntryEntryToJSON)),
        'prompts': value.prompts === undefined ? undefined : ((value.prompts as Array<any>).map(PromptEntryToJSON)),
        'multipleChoiceAnswers': value.multipleChoiceAnswers === undefined ? undefined : ((value.multipleChoiceAnswers as Array<any>).map(MultipleChoiceAnswerEntryToJSON)),
        'answerToCannedJobs': value.answerToCannedJobs === undefined ? undefined : ((value.answerToCannedJobs as Array<any>).map(InspectionAnswerToCannedJobEntryToJSON)),
        'media': value.media === undefined ? undefined : ((value.media as Array<any>).map(InspectionMediaEntryToJSON)),
        'entryStatuses': value.entryStatuses === undefined ? undefined : ((value.entryStatuses as Array<any>).map(InspectionEntryStatusEntryToJSON)),
    };
}

