/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLaborMatrixUsersRequest
 */
export interface UpdateLaborMatrixUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateLaborMatrixUsersRequest
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLaborMatrixUsersRequest
     */
    originalLaborMatrix?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLaborMatrixUsersRequest
     */
    replacementLaborMatrix?: string;
}

/**
 * Check if a given object implements the UpdateLaborMatrixUsersRequest interface.
 */
export function instanceOfUpdateLaborMatrixUsersRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateLaborMatrixUsersRequestFromJSON(json: any): UpdateLaborMatrixUsersRequest {
    return UpdateLaborMatrixUsersRequestFromJSONTyped(json, false);
}

export function UpdateLaborMatrixUsersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLaborMatrixUsersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'originalLaborMatrix': !exists(json, 'originalLaborMatrix') ? undefined : json['originalLaborMatrix'],
        'replacementLaborMatrix': !exists(json, 'replacementLaborMatrix') ? undefined : json['replacementLaborMatrix'],
    };
}

export function UpdateLaborMatrixUsersRequestToJSON(value?: UpdateLaborMatrixUsersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'originalLaborMatrix': value.originalLaborMatrix,
        'replacementLaborMatrix': value.replacementLaborMatrix,
    };
}

