/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RepairOrderCodatLineItem } from './RepairOrderCodatLineItem';
import {
    RepairOrderCodatLineItemFromJSON,
    RepairOrderCodatLineItemFromJSONTyped,
    RepairOrderCodatLineItemToJSON,
} from './RepairOrderCodatLineItem';

/**
 * 
 * @export
 * @interface RepairOrderPricing
 */
export interface RepairOrderPricing {
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    laborRetailPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    partsCost?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    partsRetailPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    shopSuppliesFee?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    shopHazmatFee?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    surchargeFee?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    customFees?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    customDiscounts?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    repairOrderShopDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    discountTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    taxes?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    totalWithSurcharge?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    taxable?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    partWarrantyTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    laborWarrantyTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderPricing
     */
    sublet?: number;
    /**
     * 
     * @type {Array<RepairOrderCodatLineItem>}
     * @memberof RepairOrderPricing
     */
    lineItemPricingList?: Array<RepairOrderCodatLineItem>;
}

/**
 * Check if a given object implements the RepairOrderPricing interface.
 */
export function instanceOfRepairOrderPricing(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairOrderPricingFromJSON(json: any): RepairOrderPricing {
    return RepairOrderPricingFromJSONTyped(json, false);
}

export function RepairOrderPricingFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairOrderPricing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'laborRetailPrice': !exists(json, 'laborRetailPrice') ? undefined : json['laborRetailPrice'],
        'partsCost': !exists(json, 'partsCost') ? undefined : json['partsCost'],
        'partsRetailPrice': !exists(json, 'partsRetailPrice') ? undefined : json['partsRetailPrice'],
        'shopSuppliesFee': !exists(json, 'shopSuppliesFee') ? undefined : json['shopSuppliesFee'],
        'shopHazmatFee': !exists(json, 'shopHazmatFee') ? undefined : json['shopHazmatFee'],
        'surchargeFee': !exists(json, 'surchargeFee') ? undefined : json['surchargeFee'],
        'customFees': !exists(json, 'customFees') ? undefined : json['customFees'],
        'customDiscounts': !exists(json, 'customDiscounts') ? undefined : json['customDiscounts'],
        'repairOrderShopDiscount': !exists(json, 'repairOrderShopDiscount') ? undefined : json['repairOrderShopDiscount'],
        'discountTotal': !exists(json, 'discountTotal') ? undefined : json['discountTotal'],
        'taxes': !exists(json, 'taxes') ? undefined : json['taxes'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'totalWithSurcharge': !exists(json, 'totalWithSurcharge') ? undefined : json['totalWithSurcharge'],
        'taxable': !exists(json, 'taxable') ? undefined : json['taxable'],
        'partWarrantyTotal': !exists(json, 'partWarrantyTotal') ? undefined : json['partWarrantyTotal'],
        'laborWarrantyTotal': !exists(json, 'laborWarrantyTotal') ? undefined : json['laborWarrantyTotal'],
        'sublet': !exists(json, 'sublet') ? undefined : json['sublet'],
        'lineItemPricingList': !exists(json, 'lineItemPricingList') ? undefined : ((json['lineItemPricingList'] as Array<any>).map(RepairOrderCodatLineItemFromJSON)),
    };
}

export function RepairOrderPricingToJSON(value?: RepairOrderPricing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hours': value.hours,
        'laborRetailPrice': value.laborRetailPrice,
        'partsCost': value.partsCost,
        'partsRetailPrice': value.partsRetailPrice,
        'shopSuppliesFee': value.shopSuppliesFee,
        'shopHazmatFee': value.shopHazmatFee,
        'surchargeFee': value.surchargeFee,
        'customFees': value.customFees,
        'customDiscounts': value.customDiscounts,
        'repairOrderShopDiscount': value.repairOrderShopDiscount,
        'discountTotal': value.discountTotal,
        'taxes': value.taxes,
        'total': value.total,
        'totalWithSurcharge': value.totalWithSurcharge,
        'taxable': value.taxable,
        'partWarrantyTotal': value.partWarrantyTotal,
        'laborWarrantyTotal': value.laborWarrantyTotal,
        'sublet': value.sublet,
        'lineItemPricingList': value.lineItemPricingList === undefined ? undefined : ((value.lineItemPricingList as Array<any>).map(RepairOrderCodatLineItemToJSON)),
    };
}

