/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePaymentTypes
 */
export interface UpdatePaymentTypes {
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentTypes
     */
    shop?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePaymentTypes
     */
    paymentIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentTypes
     */
    type?: UpdatePaymentTypesTypeEnum;
}


/**
 * @export
 */
export const UpdatePaymentTypesTypeEnum = {
    CreditDebitCard: 'credit_debit_card',
    Visa: 'visa',
    Mastercard: 'mastercard',
    Discover: 'discover',
    AmericanExpress: 'american_express',
    Cash: 'cash',
    Check: 'check',
    Finix: 'finix',
    Credit: 'credit'
} as const;
export type UpdatePaymentTypesTypeEnum = typeof UpdatePaymentTypesTypeEnum[keyof typeof UpdatePaymentTypesTypeEnum];


/**
 * Check if a given object implements the UpdatePaymentTypes interface.
 */
export function instanceOfUpdatePaymentTypes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdatePaymentTypesFromJSON(json: any): UpdatePaymentTypes {
    return UpdatePaymentTypesFromJSONTyped(json, false);
}

export function UpdatePaymentTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePaymentTypes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'paymentIds': !exists(json, 'paymentIds') ? undefined : json['paymentIds'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function UpdatePaymentTypesToJSON(value?: UpdatePaymentTypes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'paymentIds': value.paymentIds,
        'type': value.type,
    };
}

