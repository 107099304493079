/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IdName } from './IdName';
import {
    IdNameFromJSON,
    IdNameFromJSONTyped,
    IdNameToJSON,
} from './IdName';

/**
 * 
 * @export
 * @interface Taxonomy
 */
export interface Taxonomy {
    /**
     * 
     * @type {IdName}
     * @memberof Taxonomy
     */
    system?: IdName;
    /**
     * 
     * @type {IdName}
     * @memberof Taxonomy
     */
    group?: IdName;
    /**
     * 
     * @type {IdName}
     * @memberof Taxonomy
     */
    subgroup?: IdName;
    /**
     * 
     * @type {number}
     * @memberof Taxonomy
     */
    taxonomyId?: number;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    literalName?: string;
}

/**
 * Check if a given object implements the Taxonomy interface.
 */
export function instanceOfTaxonomy(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TaxonomyFromJSON(json: any): Taxonomy {
    return TaxonomyFromJSONTyped(json, false);
}

export function TaxonomyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Taxonomy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'system': !exists(json, 'system') ? undefined : IdNameFromJSON(json['system']),
        'group': !exists(json, 'group') ? undefined : IdNameFromJSON(json['group']),
        'subgroup': !exists(json, 'subgroup') ? undefined : IdNameFromJSON(json['subgroup']),
        'taxonomyId': !exists(json, 'taxonomyId') ? undefined : json['taxonomyId'],
        'literalName': !exists(json, 'literalName') ? undefined : json['literalName'],
    };
}

export function TaxonomyToJSON(value?: Taxonomy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'system': IdNameToJSON(value.system),
        'group': IdNameToJSON(value.group),
        'subgroup': IdNameToJSON(value.subgroup),
        'taxonomyId': value.taxonomyId,
        'literalName': value.literalName,
    };
}

