/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerAdditionalPhoneInfo } from './CustomerAdditionalPhoneInfo';
import {
    CustomerAdditionalPhoneInfoFromJSON,
    CustomerAdditionalPhoneInfoFromJSONTyped,
    CustomerAdditionalPhoneInfoToJSON,
} from './CustomerAdditionalPhoneInfo';

/**
 * 
 * @export
 * @interface CustomerAdditionalPhoneEntry
 */
export interface CustomerAdditionalPhoneEntry {
    /**
     * 
     * @type {string}
     * @memberof CustomerAdditionalPhoneEntry
     */
    id?: string;
    /**
     * 
     * @type {CustomerAdditionalPhoneInfo}
     * @memberof CustomerAdditionalPhoneEntry
     */
    info?: CustomerAdditionalPhoneInfo;
}

/**
 * Check if a given object implements the CustomerAdditionalPhoneEntry interface.
 */
export function instanceOfCustomerAdditionalPhoneEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerAdditionalPhoneEntryFromJSON(json: any): CustomerAdditionalPhoneEntry {
    return CustomerAdditionalPhoneEntryFromJSONTyped(json, false);
}

export function CustomerAdditionalPhoneEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerAdditionalPhoneEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : CustomerAdditionalPhoneInfoFromJSON(json['info']),
    };
}

export function CustomerAdditionalPhoneEntryToJSON(value?: CustomerAdditionalPhoneEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': CustomerAdditionalPhoneInfoToJSON(value.info),
    };
}

