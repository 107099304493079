/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublicCustomerEntry
 */
export interface PublicCustomerEntry {
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerEntry
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerEntry
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerEntry
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerEntry
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerEntry
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCustomerEntry
     */
    phone?: string;
}

/**
 * Check if a given object implements the PublicCustomerEntry interface.
 */
export function instanceOfPublicCustomerEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PublicCustomerEntryFromJSON(json: any): PublicCustomerEntry {
    return PublicCustomerEntryFromJSONTyped(json, false);
}

export function PublicCustomerEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicCustomerEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function PublicCustomerEntryToJSON(value?: PublicCustomerEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstname': value.firstname,
        'lastname': value.lastname,
        'companyName': value.companyName,
        'email': value.email,
        'phone': value.phone,
    };
}

