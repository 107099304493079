/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateStandaloneApiKeyForCustomerRequest
 */
export interface CreateStandaloneApiKeyForCustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateStandaloneApiKeyForCustomerRequest
     */
    inspectionId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateStandaloneApiKeyForCustomerRequest
     */
    showPrice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateStandaloneApiKeyForCustomerRequest
     */
    showPartsLabor?: boolean;
}

/**
 * Check if a given object implements the CreateStandaloneApiKeyForCustomerRequest interface.
 */
export function instanceOfCreateStandaloneApiKeyForCustomerRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateStandaloneApiKeyForCustomerRequestFromJSON(json: any): CreateStandaloneApiKeyForCustomerRequest {
    return CreateStandaloneApiKeyForCustomerRequestFromJSONTyped(json, false);
}

export function CreateStandaloneApiKeyForCustomerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateStandaloneApiKeyForCustomerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspectionId': !exists(json, 'inspectionId') ? undefined : json['inspectionId'],
        'showPrice': !exists(json, 'showPrice') ? undefined : json['showPrice'],
        'showPartsLabor': !exists(json, 'showPartsLabor') ? undefined : json['showPartsLabor'],
    };
}

export function CreateStandaloneApiKeyForCustomerRequestToJSON(value?: CreateStandaloneApiKeyForCustomerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspectionId': value.inspectionId,
        'showPrice': value.showPrice,
        'showPartsLabor': value.showPartsLabor,
    };
}

