/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MyRole
 */
export interface MyRole {
    /**
     * 
     * @type {string}
     * @memberof MyRole
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof MyRole
     */
    shopName?: string;
    /**
     * 
     * @type {string}
     * @memberof MyRole
     */
    roleName?: string;
}

/**
 * Check if a given object implements the MyRole interface.
 */
export function instanceOfMyRole(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MyRoleFromJSON(json: any): MyRole {
    return MyRoleFromJSONTyped(json, false);
}

export function MyRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): MyRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'shopName': !exists(json, 'shopName') ? undefined : json['shopName'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
    };
}

export function MyRoleToJSON(value?: MyRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'shopName': value.shopName,
        'roleName': value.roleName,
    };
}

