/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PartTaxonomy } from './PartTaxonomy';
import {
    PartTaxonomyFromJSON,
    PartTaxonomyFromJSONTyped,
    PartTaxonomyToJSON,
} from './PartTaxonomy';

/**
 * 
 * @export
 * @interface PartOverride
 */
export interface PartOverride {
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    service?: string;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    inventoryPart?: string;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    supplierName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    poNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    partNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    partName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    partDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof PartOverride
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof PartOverride
     */
    costPerUnit?: number;
    /**
     * 
     * @type {number}
     * @memberof PartOverride
     */
    corePerUnit?: number;
    /**
     * 
     * @type {number}
     * @memberof PartOverride
     */
    pricePerUnit?: number;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    markup?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartOverride
     */
    taxExempt?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartOverride
     */
    ordered?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PartOverride
     */
    received?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    unitOfMeasure?: PartOverrideUnitOfMeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    placeholderId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    placeholderType?: PartOverridePlaceholderTypeEnum;
    /**
     * 
     * @type {PartTaxonomy}
     * @memberof PartOverride
     */
    partTaxonomy?: PartTaxonomy;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    cannedPart?: string;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    vendorId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    oilFilter?: string;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    fluids?: string;
    /**
     * 
     * @type {Date}
     * @memberof PartOverride
     */
    receivedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    receivedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof PartOverride
     */
    brandDisplayName?: string;
}


/**
 * @export
 */
export const PartOverrideUnitOfMeasureEnum = {
    DiscreteUnit: 'discrete_unit',
    Liter: 'liter',
    Ml: 'ml',
    Gallon: 'gallon',
    Quart: 'quart',
    Ounce: 'ounce',
    FlOz: 'fl_oz'
} as const;
export type PartOverrideUnitOfMeasureEnum = typeof PartOverrideUnitOfMeasureEnum[keyof typeof PartOverrideUnitOfMeasureEnum];

/**
 * @export
 */
export const PartOverridePlaceholderTypeEnum = {
    MotorFluid: 'motor_fluid',
    PartsTechLookup: 'parts_tech_lookup',
    Inventory: 'inventory'
} as const;
export type PartOverridePlaceholderTypeEnum = typeof PartOverridePlaceholderTypeEnum[keyof typeof PartOverridePlaceholderTypeEnum];


/**
 * Check if a given object implements the PartOverride interface.
 */
export function instanceOfPartOverride(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PartOverrideFromJSON(json: any): PartOverride {
    return PartOverrideFromJSONTyped(json, false);
}

export function PartOverrideFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartOverride {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'service': !exists(json, 'service') ? undefined : json['service'],
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'inventoryPart': !exists(json, 'inventoryPart') ? undefined : json['inventoryPart'],
        'supplierName': !exists(json, 'supplierName') ? undefined : json['supplierName'],
        'poNumber': !exists(json, 'poNumber') ? undefined : json['poNumber'],
        'partNumber': !exists(json, 'partNumber') ? undefined : json['partNumber'],
        'partName': !exists(json, 'partName') ? undefined : json['partName'],
        'partDescription': !exists(json, 'partDescription') ? undefined : json['partDescription'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'costPerUnit': !exists(json, 'costPerUnit') ? undefined : json['costPerUnit'],
        'corePerUnit': !exists(json, 'corePerUnit') ? undefined : json['corePerUnit'],
        'pricePerUnit': !exists(json, 'pricePerUnit') ? undefined : json['pricePerUnit'],
        'markup': !exists(json, 'markup') ? undefined : json['markup'],
        'taxExempt': !exists(json, 'taxExempt') ? undefined : json['taxExempt'],
        'ordered': !exists(json, 'ordered') ? undefined : json['ordered'],
        'received': !exists(json, 'received') ? undefined : json['received'],
        'unitOfMeasure': !exists(json, 'unitOfMeasure') ? undefined : json['unitOfMeasure'],
        'placeholderId': !exists(json, 'placeholderId') ? undefined : json['placeholderId'],
        'placeholderType': !exists(json, 'placeholderType') ? undefined : json['placeholderType'],
        'partTaxonomy': !exists(json, 'partTaxonomy') ? undefined : PartTaxonomyFromJSON(json['partTaxonomy']),
        'cannedPart': !exists(json, 'cannedPart') ? undefined : json['cannedPart'],
        'vendorId': !exists(json, 'vendorId') ? undefined : json['vendorId'],
        'oilFilter': !exists(json, 'oilFilter') ? undefined : json['oilFilter'],
        'fluids': !exists(json, 'fluids') ? undefined : json['fluids'],
        'receivedAt': !exists(json, 'receivedAt') ? undefined : (new Date(json['receivedAt'])),
        'receivedBy': !exists(json, 'receivedBy') ? undefined : json['receivedBy'],
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'brandDisplayName': !exists(json, 'brandDisplayName') ? undefined : json['brandDisplayName'],
    };
}

export function PartOverrideToJSON(value?: PartOverride | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service': value.service,
        'uuid': value.uuid,
        'inventoryPart': value.inventoryPart,
        'supplierName': value.supplierName,
        'poNumber': value.poNumber,
        'partNumber': value.partNumber,
        'partName': value.partName,
        'partDescription': value.partDescription,
        'quantity': value.quantity,
        'costPerUnit': value.costPerUnit,
        'corePerUnit': value.corePerUnit,
        'pricePerUnit': value.pricePerUnit,
        'markup': value.markup,
        'taxExempt': value.taxExempt,
        'ordered': value.ordered,
        'received': value.received,
        'unitOfMeasure': value.unitOfMeasure,
        'placeholderId': value.placeholderId,
        'placeholderType': value.placeholderType,
        'partTaxonomy': PartTaxonomyToJSON(value.partTaxonomy),
        'cannedPart': value.cannedPart,
        'vendorId': value.vendorId,
        'oilFilter': value.oilFilter,
        'fluids': value.fluids,
        'receivedAt': value.receivedAt === undefined ? undefined : (value.receivedAt.toISOString()),
        'receivedBy': value.receivedBy,
        'brand': value.brand,
        'brandDisplayName': value.brandDisplayName,
    };
}

