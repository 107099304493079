/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePartsTechQuoteRequest
 */
export interface CreatePartsTechQuoteRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePartsTechQuoteRequest
     */
    service?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartsTechQuoteRequest
     */
    keyword?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartsTechQuoteRequest
     */
    redirectUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartsTechQuoteRequest
     */
    partsMatrix?: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartsTechQuoteRequest
     */
    partOverride?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreatePartsTechQuoteRequest
     */
    partTypeIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePartsTechQuoteRequest
     */
    taxExempt?: boolean;
}

/**
 * Check if a given object implements the CreatePartsTechQuoteRequest interface.
 */
export function instanceOfCreatePartsTechQuoteRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreatePartsTechQuoteRequestFromJSON(json: any): CreatePartsTechQuoteRequest {
    return CreatePartsTechQuoteRequestFromJSONTyped(json, false);
}

export function CreatePartsTechQuoteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePartsTechQuoteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'service': !exists(json, 'service') ? undefined : json['service'],
        'keyword': !exists(json, 'keyword') ? undefined : json['keyword'],
        'redirectUrl': !exists(json, 'redirectUrl') ? undefined : json['redirectUrl'],
        'partsMatrix': !exists(json, 'partsMatrix') ? undefined : json['partsMatrix'],
        'partOverride': !exists(json, 'partOverride') ? undefined : json['partOverride'],
        'partTypeIds': !exists(json, 'partTypeIds') ? undefined : json['partTypeIds'],
        'taxExempt': !exists(json, 'taxExempt') ? undefined : json['taxExempt'],
    };
}

export function CreatePartsTechQuoteRequestToJSON(value?: CreatePartsTechQuoteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service': value.service,
        'keyword': value.keyword,
        'redirectUrl': value.redirectUrl,
        'partsMatrix': value.partsMatrix,
        'partOverride': value.partOverride,
        'partTypeIds': value.partTypeIds,
        'taxExempt': value.taxExempt,
    };
}

