/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PartsTechCredentials
 */
export interface PartsTechCredentials {
    /**
     * 
     * @type {string}
     * @memberof PartsTechCredentials
     */
    shopId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartsTechCredentials
     */
    partsTechUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartsTechCredentials
     */
    partsTechApiKey?: string;
}

/**
 * Check if a given object implements the PartsTechCredentials interface.
 */
export function instanceOfPartsTechCredentials(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PartsTechCredentialsFromJSON(json: any): PartsTechCredentials {
    return PartsTechCredentialsFromJSONTyped(json, false);
}

export function PartsTechCredentialsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartsTechCredentials {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shopId': !exists(json, 'shopId') ? undefined : json['shopId'],
        'partsTechUserId': !exists(json, 'partsTechUserId') ? undefined : json['partsTechUserId'],
        'partsTechApiKey': !exists(json, 'partsTechApiKey') ? undefined : json['partsTechApiKey'],
    };
}

export function PartsTechCredentialsToJSON(value?: PartsTechCredentials | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shopId': value.shopId,
        'partsTechUserId': value.partsTechUserId,
        'partsTechApiKey': value.partsTechApiKey,
    };
}

