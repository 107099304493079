/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WarrantyInfo
 */
export interface WarrantyInfo {
    /**
     * 
     * @type {string}
     * @memberof WarrantyInfo
     */
    part?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyInfo
     */
    labor?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WarrantyInfo
     */
    selfWarrantied?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WarrantyInfo
     */
    shopVendor?: string;
    /**
     * 
     * @type {string}
     * @memberof WarrantyInfo
     */
    vendorName?: string;
    /**
     * 
     * @type {number}
     * @memberof WarrantyInfo
     */
    amount?: number;
}

/**
 * Check if a given object implements the WarrantyInfo interface.
 */
export function instanceOfWarrantyInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WarrantyInfoFromJSON(json: any): WarrantyInfo {
    return WarrantyInfoFromJSONTyped(json, false);
}

export function WarrantyInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WarrantyInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'part': !exists(json, 'part') ? undefined : json['part'],
        'labor': !exists(json, 'labor') ? undefined : json['labor'],
        'selfWarrantied': !exists(json, 'selfWarrantied') ? undefined : json['selfWarrantied'],
        'shopVendor': !exists(json, 'shopVendor') ? undefined : json['shopVendor'],
        'vendorName': !exists(json, 'vendorName') ? undefined : json['vendorName'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function WarrantyInfoToJSON(value?: WarrantyInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'part': value.part,
        'labor': value.labor,
        'selfWarrantied': value.selfWarrantied,
        'shopVendor': value.shopVendor,
        'vendorName': value.vendorName,
        'amount': value.amount,
    };
}

