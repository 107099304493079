/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';

/**
 * 
 * @export
 * @interface PartsTechPartTaxonomy
 */
export interface PartsTechPartTaxonomy {
    /**
     * 
     * @type {{ [key: string]: Category; }}
     * @memberof PartsTechPartTaxonomy
     */
    categories?: { [key: string]: Category; };
}

/**
 * Check if a given object implements the PartsTechPartTaxonomy interface.
 */
export function instanceOfPartsTechPartTaxonomy(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PartsTechPartTaxonomyFromJSON(json: any): PartsTechPartTaxonomy {
    return PartsTechPartTaxonomyFromJSONTyped(json, false);
}

export function PartsTechPartTaxonomyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartsTechPartTaxonomy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categories': !exists(json, 'categories') ? undefined : (mapValues(json['categories'], CategoryFromJSON)),
    };
}

export function PartsTechPartTaxonomyToJSON(value?: PartsTechPartTaxonomy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categories': value.categories === undefined ? undefined : (mapValues(value.categories, CategoryToJSON)),
    };
}

