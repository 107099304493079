/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InventoryAdjustmentInfo } from './InventoryAdjustmentInfo';
import {
    InventoryAdjustmentInfoFromJSON,
    InventoryAdjustmentInfoFromJSONTyped,
    InventoryAdjustmentInfoToJSON,
} from './InventoryAdjustmentInfo';

/**
 * 
 * @export
 * @interface InventoryAdjustmentEntry
 */
export interface InventoryAdjustmentEntry {
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentEntry
     */
    id?: string;
    /**
     * 
     * @type {InventoryAdjustmentInfo}
     * @memberof InventoryAdjustmentEntry
     */
    info?: InventoryAdjustmentInfo;
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentEntry
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryAdjustmentEntry
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentEntry
     */
    updatedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryAdjustmentEntry
     */
    updatedAt?: Date;
}

/**
 * Check if a given object implements the InventoryAdjustmentEntry interface.
 */
export function instanceOfInventoryAdjustmentEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InventoryAdjustmentEntryFromJSON(json: any): InventoryAdjustmentEntry {
    return InventoryAdjustmentEntryFromJSONTyped(json, false);
}

export function InventoryAdjustmentEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryAdjustmentEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : InventoryAdjustmentInfoFromJSON(json['info']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}

export function InventoryAdjustmentEntryToJSON(value?: InventoryAdjustmentEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': InventoryAdjustmentInfoToJSON(value.info),
        'createdBy': value.createdBy,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedBy': value.updatedBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}

