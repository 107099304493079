/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PromptEntry } from './PromptEntry';
import {
    PromptEntryFromJSON,
    PromptEntryFromJSONTyped,
    PromptEntryToJSON,
} from './PromptEntry';

/**
 * 
 * @export
 * @interface PromptEntryOfInspection
 */
export interface PromptEntryOfInspection {
    /**
     * 
     * @type {string}
     * @memberof PromptEntryOfInspection
     */
    inspection?: string;
    /**
     * 
     * @type {PromptEntry}
     * @memberof PromptEntryOfInspection
     */
    prompt?: PromptEntry;
}

/**
 * Check if a given object implements the PromptEntryOfInspection interface.
 */
export function instanceOfPromptEntryOfInspection(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PromptEntryOfInspectionFromJSON(json: any): PromptEntryOfInspection {
    return PromptEntryOfInspectionFromJSONTyped(json, false);
}

export function PromptEntryOfInspectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PromptEntryOfInspection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspection': !exists(json, 'inspection') ? undefined : json['inspection'],
        'prompt': !exists(json, 'prompt') ? undefined : PromptEntryFromJSON(json['prompt']),
    };
}

export function PromptEntryOfInspectionToJSON(value?: PromptEntryOfInspection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspection': value.inspection,
        'prompt': PromptEntryToJSON(value.prompt),
    };
}

