/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Mutation } from './Mutation';
import {
    MutationFromJSON,
    MutationFromJSONTyped,
    MutationToJSON,
} from './Mutation';

/**
 * 
 * @export
 * @interface PushRequest
 */
export interface PushRequest {
    /**
     * 
     * @type {number}
     * @memberof PushRequest
     */
    pushVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof PushRequest
     */
    clientGroupID?: string;
    /**
     * 
     * @type {Array<Mutation>}
     * @memberof PushRequest
     */
    mutations?: Array<Mutation>;
    /**
     * 
     * @type {string}
     * @memberof PushRequest
     */
    profileID?: string;
    /**
     * 
     * @type {string}
     * @memberof PushRequest
     */
    schemaVersion?: string;
}

/**
 * Check if a given object implements the PushRequest interface.
 */
export function instanceOfPushRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PushRequestFromJSON(json: any): PushRequest {
    return PushRequestFromJSONTyped(json, false);
}

export function PushRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pushVersion': !exists(json, 'pushVersion') ? undefined : json['pushVersion'],
        'clientGroupID': !exists(json, 'clientGroupID') ? undefined : json['clientGroupID'],
        'mutations': !exists(json, 'mutations') ? undefined : ((json['mutations'] as Array<any>).map(MutationFromJSON)),
        'profileID': !exists(json, 'profileID') ? undefined : json['profileID'],
        'schemaVersion': !exists(json, 'schemaVersion') ? undefined : json['schemaVersion'],
    };
}

export function PushRequestToJSON(value?: PushRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pushVersion': value.pushVersion,
        'clientGroupID': value.clientGroupID,
        'mutations': value.mutations === undefined ? undefined : ((value.mutations as Array<any>).map(MutationToJSON)),
        'profileID': value.profileID,
        'schemaVersion': value.schemaVersion,
    };
}

