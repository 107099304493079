/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MaintenanceScheduleTimingInfo
 */
export interface MaintenanceScheduleTimingInfo {
    /**
     * 
     * @type {number}
     * @memberof MaintenanceScheduleTimingInfo
     */
    maintenanceScheduleID?: number;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceScheduleTimingInfo
     */
    frequencyDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceScheduleTimingInfo
     */
    indicator?: string;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceScheduleTimingInfo
     */
    intervalKilometer?: number;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceScheduleTimingInfo
     */
    intervalMile?: number;
    /**
     * 
     * @type {number}
     * @memberof MaintenanceScheduleTimingInfo
     */
    intervalMonth?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MaintenanceScheduleTimingInfo
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceScheduleTimingInfo
     */
    severeServiceDescription?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MaintenanceScheduleTimingInfo
     */
    notes?: Array<string>;
}

/**
 * Check if a given object implements the MaintenanceScheduleTimingInfo interface.
 */
export function instanceOfMaintenanceScheduleTimingInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MaintenanceScheduleTimingInfoFromJSON(json: any): MaintenanceScheduleTimingInfo {
    return MaintenanceScheduleTimingInfoFromJSONTyped(json, false);
}

export function MaintenanceScheduleTimingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaintenanceScheduleTimingInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maintenanceScheduleID': !exists(json, 'maintenanceScheduleID') ? undefined : json['maintenanceScheduleID'],
        'frequencyDescription': !exists(json, 'frequencyDescription') ? undefined : json['frequencyDescription'],
        'indicator': !exists(json, 'indicator') ? undefined : json['indicator'],
        'intervalKilometer': !exists(json, 'intervalKilometer') ? undefined : json['intervalKilometer'],
        'intervalMile': !exists(json, 'intervalMile') ? undefined : json['intervalMile'],
        'intervalMonth': !exists(json, 'intervalMonth') ? undefined : json['intervalMonth'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'severeServiceDescription': !exists(json, 'severeServiceDescription') ? undefined : json['severeServiceDescription'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function MaintenanceScheduleTimingInfoToJSON(value?: MaintenanceScheduleTimingInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'maintenanceScheduleID': value.maintenanceScheduleID,
        'frequencyDescription': value.frequencyDescription,
        'indicator': value.indicator,
        'intervalKilometer': value.intervalKilometer,
        'intervalMile': value.intervalMile,
        'intervalMonth': value.intervalMonth,
        'isActive': value.isActive,
        'severeServiceDescription': value.severeServiceDescription,
        'notes': value.notes,
    };
}

