/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerInformation } from './CustomerInformation';
import {
    CustomerInformationFromJSON,
    CustomerInformationFromJSONTyped,
    CustomerInformationToJSON,
} from './CustomerInformation';

/**
 * 
 * @export
 * @interface CustomerEntry
 */
export interface CustomerEntry {
    /**
     * 
     * @type {string}
     * @memberof CustomerEntry
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerEntry
     */
    createTime?: Date;
    /**
     * 
     * @type {CustomerInformation}
     * @memberof CustomerEntry
     */
    information?: CustomerInformation;
}

/**
 * Check if a given object implements the CustomerEntry interface.
 */
export function instanceOfCustomerEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerEntryFromJSON(json: any): CustomerEntry {
    return CustomerEntryFromJSONTyped(json, false);
}

export function CustomerEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createTime': !exists(json, 'createTime') ? undefined : (new Date(json['createTime'])),
        'information': !exists(json, 'information') ? undefined : CustomerInformationFromJSON(json['information']),
    };
}

export function CustomerEntryToJSON(value?: CustomerEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createTime': value.createTime === undefined ? undefined : (value.createTime.toISOString()),
        'information': CustomerInformationToJSON(value.information),
    };
}

