/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FluidCapacityItem } from './FluidCapacityItem';
import {
    FluidCapacityItemFromJSON,
    FluidCapacityItemFromJSONTyped,
    FluidCapacityItemToJSON,
} from './FluidCapacityItem';
import type { Taxonomy } from './Taxonomy';
import {
    TaxonomyFromJSON,
    TaxonomyFromJSONTyped,
    TaxonomyToJSON,
} from './Taxonomy';

/**
 * 
 * @export
 * @interface FluidCapacity
 */
export interface FluidCapacity {
    /**
     * 
     * @type {number}
     * @memberof FluidCapacity
     */
    id?: number;
    /**
     * 
     * @type {Taxonomy}
     * @memberof FluidCapacity
     */
    taxonomy?: Taxonomy;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof FluidCapacity
     */
    additionalAttributes?: { [key: string]: number; };
    /**
     * 
     * @type {Array<string>}
     * @memberof FluidCapacity
     */
    qualifiers?: Array<string>;
    /**
     * 
     * @type {Array<FluidCapacityItem>}
     * @memberof FluidCapacity
     */
    items?: Array<FluidCapacityItem>;
}

/**
 * Check if a given object implements the FluidCapacity interface.
 */
export function instanceOfFluidCapacity(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FluidCapacityFromJSON(json: any): FluidCapacity {
    return FluidCapacityFromJSONTyped(json, false);
}

export function FluidCapacityFromJSONTyped(json: any, ignoreDiscriminator: boolean): FluidCapacity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'taxonomy': !exists(json, 'taxonomy') ? undefined : TaxonomyFromJSON(json['taxonomy']),
        'additionalAttributes': !exists(json, 'additionalAttributes') ? undefined : json['additionalAttributes'],
        'qualifiers': !exists(json, 'qualifiers') ? undefined : json['qualifiers'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(FluidCapacityItemFromJSON)),
    };
}

export function FluidCapacityToJSON(value?: FluidCapacity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'taxonomy': TaxonomyToJSON(value.taxonomy),
        'additionalAttributes': value.additionalAttributes,
        'qualifiers': value.qualifiers,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(FluidCapacityItemToJSON)),
    };
}

