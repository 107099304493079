import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes'

import { RawFetchDecoder, RawFetchFunction } from '../models/sdkTypes'
import { handleSdkError } from '../utils'
import SdkError from '../utils/handleError/SdkError'

type SdkBaseQueryArgs<Data> = () => Promise<Data>

// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries
export async function sdkBaseQuery<Data>(
  args: SdkBaseQueryArgs<Data>
): Promise<QueryReturnValue<Data, SdkError, undefined>> {
  return sdkQuery(args, true)
}

// Example usage: https://redux-toolkit.js.org/rtk-query/api/createApi#queryfn
// getCustomerDetail: builder.query<CustomerEntry[], GetCustomerInfoRequest>({
//   queryFn: (req) => sdkQueryNoToast(() => getCustomerInfo(req)),
//   providesTags: ...
// }),
export async function sdkQueryNoToast<Data>(
  arg: SdkBaseQueryArgs<Data>
): Promise<QueryReturnValue<Data, SdkError, undefined>> {
  return sdkQuery(arg, false)
}

async function sdkQuery<Data>(
  args: SdkBaseQueryArgs<Data>,
  showToast: boolean
): Promise<QueryReturnValue<Data, SdkError, undefined>> {
  try {
    const result: Data = await args()
    return {
      data: result
    }
  } catch (e) {
    return {
      error: await handleSdkError(e, showToast)
    }
  }
}

export function generateRawSdkQueryFn<Options, RawData, DecodedData>(
  rawSdkFn: RawFetchFunction<Options, RawData>,
  decoder: RawFetchDecoder<DecodedData>
): (options: Options) => SdkBaseQueryArgs<DecodedData> {
  return (options: Options) => async () => {
    const apiResponse = await rawSdkFn(options)
    return await decoder(apiResponse.raw)
  }
}
