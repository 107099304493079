/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SemVer
 */
export interface SemVer {
    /**
     * 
     * @type {number}
     * @memberof SemVer
     */
    major?: number;
    /**
     * 
     * @type {number}
     * @memberof SemVer
     */
    minor?: number;
    /**
     * 
     * @type {number}
     * @memberof SemVer
     */
    patch?: number;
}

/**
 * Check if a given object implements the SemVer interface.
 */
export function instanceOfSemVer(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SemVerFromJSON(json: any): SemVer {
    return SemVerFromJSONTyped(json, false);
}

export function SemVerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SemVer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'major': !exists(json, 'major') ? undefined : json['major'],
        'minor': !exists(json, 'minor') ? undefined : json['minor'],
        'patch': !exists(json, 'patch') ? undefined : json['patch'],
    };
}

export function SemVerToJSON(value?: SemVer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'major': value.major,
        'minor': value.minor,
        'patch': value.patch,
    };
}

