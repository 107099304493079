/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FluidCapacityItem
 */
export interface FluidCapacityItem {
    /**
     * 
     * @type {number}
     * @memberof FluidCapacityItem
     */
    value?: number;
    /**
     * 
     * @type {string}
     * @memberof FluidCapacityItem
     */
    unitOfMeasure?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof FluidCapacityItem
     */
    extendedParameters?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof FluidCapacityItem
     */
    notes?: Array<string>;
}

/**
 * Check if a given object implements the FluidCapacityItem interface.
 */
export function instanceOfFluidCapacityItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FluidCapacityItemFromJSON(json: any): FluidCapacityItem {
    return FluidCapacityItemFromJSONTyped(json, false);
}

export function FluidCapacityItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FluidCapacityItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'unitOfMeasure': !exists(json, 'unitOfMeasure') ? undefined : json['unitOfMeasure'],
        'extendedParameters': !exists(json, 'extendedParameters') ? undefined : json['extendedParameters'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function FluidCapacityItemToJSON(value?: FluidCapacityItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'unitOfMeasure': value.unitOfMeasure,
        'extendedParameters': value.extendedParameters,
        'notes': value.notes,
    };
}

