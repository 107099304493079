/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerInformation
 */
export interface CustomerInformation {
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    source?: CustomerInformationSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    otherSource?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    streetAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    state?: CustomerInformationStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    zipcode?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    country?: CustomerInformationCountryEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerInformation
     */
    taxExempt?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerInformation
     */
    marketingOptOut?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    defaultLaborMatrix?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    defaultPartsMatrix?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    defaultPriceLevel?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    chatOverrideId?: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerInformation
     */
    archived?: Date;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    codatId?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerInformation
     */
    codatPushKey?: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerInformation
     */
    lastCodatModifiedDate?: Date;
}


/**
 * @export
 */
export const CustomerInformationSourceEnum = {
    WalkIn: 'Walk_In',
    Google: 'Google',
    Facebook: 'Facebook',
    Yelp: 'Yelp',
    LocalAd: 'Local_Ad',
    Other: 'Other',
    IntakeForm: 'intake_form'
} as const;
export type CustomerInformationSourceEnum = typeof CustomerInformationSourceEnum[keyof typeof CustomerInformationSourceEnum];

/**
 * @export
 */
export const CustomerInformationStateEnum = {
    Al: 'AL',
    Ak: 'AK',
    Az: 'AZ',
    Ar: 'AR',
    Ca: 'CA',
    Co: 'CO',
    Ct: 'CT',
    De: 'DE',
    Fl: 'FL',
    Ga: 'GA',
    Hi: 'HI',
    Id: 'ID',
    Il: 'IL',
    In: 'IN',
    Ia: 'IA',
    Ks: 'KS',
    Ky: 'KY',
    La: 'LA',
    Me: 'ME',
    Md: 'MD',
    Ma: 'MA',
    Mi: 'MI',
    Mn: 'MN',
    Ms: 'MS',
    Mo: 'MO',
    Mt: 'MT',
    Ne: 'NE',
    Nv: 'NV',
    Nh: 'NH',
    Nj: 'NJ',
    Nm: 'NM',
    Ny: 'NY',
    Nc: 'NC',
    Nd: 'ND',
    Oh: 'OH',
    Ok: 'OK',
    Or: 'OR',
    Pa: 'PA',
    Ri: 'RI',
    Sc: 'SC',
    Sd: 'SD',
    Tn: 'TN',
    Tx: 'TX',
    Ut: 'UT',
    Vt: 'VT',
    Va: 'VA',
    Wa: 'WA',
    Wv: 'WV',
    Wi: 'WI',
    Wy: 'WY',
    Dc: 'DC'
} as const;
export type CustomerInformationStateEnum = typeof CustomerInformationStateEnum[keyof typeof CustomerInformationStateEnum];

/**
 * @export
 */
export const CustomerInformationCountryEnum = {
    Usa: 'USA',
    Canada: 'Canada'
} as const;
export type CustomerInformationCountryEnum = typeof CustomerInformationCountryEnum[keyof typeof CustomerInformationCountryEnum];


/**
 * Check if a given object implements the CustomerInformation interface.
 */
export function instanceOfCustomerInformation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerInformationFromJSON(json: any): CustomerInformation {
    return CustomerInformationFromJSONTyped(json, false);
}

export function CustomerInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'otherSource': !exists(json, 'otherSource') ? undefined : json['otherSource'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'streetAddress': !exists(json, 'streetAddress') ? undefined : json['streetAddress'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zipcode': !exists(json, 'zipcode') ? undefined : json['zipcode'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'taxExempt': !exists(json, 'taxExempt') ? undefined : json['taxExempt'],
        'marketingOptOut': !exists(json, 'marketingOptOut') ? undefined : json['marketingOptOut'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'defaultLaborMatrix': !exists(json, 'defaultLaborMatrix') ? undefined : json['defaultLaborMatrix'],
        'defaultPartsMatrix': !exists(json, 'defaultPartsMatrix') ? undefined : json['defaultPartsMatrix'],
        'defaultPriceLevel': !exists(json, 'defaultPriceLevel') ? undefined : json['defaultPriceLevel'],
        'chatOverrideId': !exists(json, 'chatOverrideId') ? undefined : json['chatOverrideId'],
        'archived': !exists(json, 'archived') ? undefined : (new Date(json['archived'])),
        'codatId': !exists(json, 'codatId') ? undefined : json['codatId'],
        'codatPushKey': !exists(json, 'codatPushKey') ? undefined : json['codatPushKey'],
        'lastCodatModifiedDate': !exists(json, 'lastCodatModifiedDate') ? undefined : (new Date(json['lastCodatModifiedDate'])),
    };
}

export function CustomerInformationToJSON(value?: CustomerInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'source': value.source,
        'otherSource': value.otherSource,
        'firstname': value.firstname,
        'lastname': value.lastname,
        'companyName': value.companyName,
        'phone': value.phone,
        'email': value.email,
        'streetAddress': value.streetAddress,
        'city': value.city,
        'state': value.state,
        'zipcode': value.zipcode,
        'country': value.country,
        'taxExempt': value.taxExempt,
        'marketingOptOut': value.marketingOptOut,
        'notes': value.notes,
        'defaultLaborMatrix': value.defaultLaborMatrix,
        'defaultPartsMatrix': value.defaultPartsMatrix,
        'defaultPriceLevel': value.defaultPriceLevel,
        'chatOverrideId': value.chatOverrideId,
        'archived': value.archived === undefined ? undefined : (value.archived.toISOString()),
        'codatId': value.codatId,
        'codatPushKey': value.codatPushKey,
        'lastCodatModifiedDate': value.lastCodatModifiedDate === undefined ? undefined : (value.lastCodatModifiedDate.toISOString()),
    };
}

