/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RepairOrderEntry } from './RepairOrderEntry';
import {
    RepairOrderEntryFromJSON,
    RepairOrderEntryFromJSONTyped,
    RepairOrderEntryToJSON,
} from './RepairOrderEntry';
import type { RepairOrderPricing } from './RepairOrderPricing';
import {
    RepairOrderPricingFromJSON,
    RepairOrderPricingFromJSONTyped,
    RepairOrderPricingToJSON,
} from './RepairOrderPricing';
import type { RepairOrderStatusEntry } from './RepairOrderStatusEntry';
import {
    RepairOrderStatusEntryFromJSON,
    RepairOrderStatusEntryFromJSONTyped,
    RepairOrderStatusEntryToJSON,
} from './RepairOrderStatusEntry';

/**
 * 
 * @export
 * @interface RepairOrderExpanded
 */
export interface RepairOrderExpanded {
    /**
     * 
     * @type {RepairOrderEntry}
     * @memberof RepairOrderExpanded
     */
    entry?: RepairOrderEntry;
    /**
     * 
     * @type {RepairOrderStatusEntry}
     * @memberof RepairOrderExpanded
     */
    status?: RepairOrderStatusEntry;
    /**
     * 
     * @type {RepairOrderPricing}
     * @memberof RepairOrderExpanded
     */
    authorizedPricing?: RepairOrderPricing;
    /**
     * 
     * @type {RepairOrderPricing}
     * @memberof RepairOrderExpanded
     */
    totalPricing?: RepairOrderPricing;
    /**
     * 
     * @type {Array<string>}
     * @memberof RepairOrderExpanded
     */
    techs?: Array<string>;
}

/**
 * Check if a given object implements the RepairOrderExpanded interface.
 */
export function instanceOfRepairOrderExpanded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairOrderExpandedFromJSON(json: any): RepairOrderExpanded {
    return RepairOrderExpandedFromJSONTyped(json, false);
}

export function RepairOrderExpandedFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairOrderExpanded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': !exists(json, 'entry') ? undefined : RepairOrderEntryFromJSON(json['entry']),
        'status': !exists(json, 'status') ? undefined : RepairOrderStatusEntryFromJSON(json['status']),
        'authorizedPricing': !exists(json, 'authorizedPricing') ? undefined : RepairOrderPricingFromJSON(json['authorizedPricing']),
        'totalPricing': !exists(json, 'totalPricing') ? undefined : RepairOrderPricingFromJSON(json['totalPricing']),
        'techs': !exists(json, 'techs') ? undefined : json['techs'],
    };
}

export function RepairOrderExpandedToJSON(value?: RepairOrderExpanded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': RepairOrderEntryToJSON(value.entry),
        'status': RepairOrderStatusEntryToJSON(value.status),
        'authorizedPricing': RepairOrderPricingToJSON(value.authorizedPricing),
        'totalPricing': RepairOrderPricingToJSON(value.totalPricing),
        'techs': value.techs,
    };
}

