/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SerializableGeometry } from './SerializableGeometry';
import {
    SerializableGeometryFromJSON,
    SerializableGeometryFromJSONTyped,
    SerializableGeometryToJSON,
} from './SerializableGeometry';

/**
 * 
 * @export
 * @interface SerializableExpenseDetection
 */
export interface SerializableExpenseDetection {
    /**
     * 
     * @type {number}
     * @memberof SerializableExpenseDetection
     */
    confidence?: number;
    /**
     * 
     * @type {SerializableGeometry}
     * @memberof SerializableExpenseDetection
     */
    geometry?: SerializableGeometry;
    /**
     * 
     * @type {string}
     * @memberof SerializableExpenseDetection
     */
    text?: string;
}

/**
 * Check if a given object implements the SerializableExpenseDetection interface.
 */
export function instanceOfSerializableExpenseDetection(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SerializableExpenseDetectionFromJSON(json: any): SerializableExpenseDetection {
    return SerializableExpenseDetectionFromJSONTyped(json, false);
}

export function SerializableExpenseDetectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerializableExpenseDetection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'confidence': !exists(json, 'confidence') ? undefined : json['confidence'],
        'geometry': !exists(json, 'geometry') ? undefined : SerializableGeometryFromJSON(json['geometry']),
        'text': !exists(json, 'text') ? undefined : json['text'],
    };
}

export function SerializableExpenseDetectionToJSON(value?: SerializableExpenseDetection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'confidence': value.confidence,
        'geometry': SerializableGeometryToJSON(value.geometry),
        'text': value.text,
    };
}

