/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InspectionAnswerToCannedJobEntry } from './InspectionAnswerToCannedJobEntry';
import {
    InspectionAnswerToCannedJobEntryFromJSON,
    InspectionAnswerToCannedJobEntryFromJSONTyped,
    InspectionAnswerToCannedJobEntryToJSON,
} from './InspectionAnswerToCannedJobEntry';

/**
 * 
 * @export
 * @interface InspectionAnswerToCannedJobEntryOfInspection
 */
export interface InspectionAnswerToCannedJobEntryOfInspection {
    /**
     * 
     * @type {string}
     * @memberof InspectionAnswerToCannedJobEntryOfInspection
     */
    inspection?: string;
    /**
     * 
     * @type {InspectionAnswerToCannedJobEntry}
     * @memberof InspectionAnswerToCannedJobEntryOfInspection
     */
    answerToCannedJob?: InspectionAnswerToCannedJobEntry;
}

/**
 * Check if a given object implements the InspectionAnswerToCannedJobEntryOfInspection interface.
 */
export function instanceOfInspectionAnswerToCannedJobEntryOfInspection(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InspectionAnswerToCannedJobEntryOfInspectionFromJSON(json: any): InspectionAnswerToCannedJobEntryOfInspection {
    return InspectionAnswerToCannedJobEntryOfInspectionFromJSONTyped(json, false);
}

export function InspectionAnswerToCannedJobEntryOfInspectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionAnswerToCannedJobEntryOfInspection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspection': !exists(json, 'inspection') ? undefined : json['inspection'],
        'answerToCannedJob': !exists(json, 'answerToCannedJob') ? undefined : InspectionAnswerToCannedJobEntryFromJSON(json['answerToCannedJob']),
    };
}

export function InspectionAnswerToCannedJobEntryOfInspectionToJSON(value?: InspectionAnswerToCannedJobEntryOfInspection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspection': value.inspection,
        'answerToCannedJob': InspectionAnswerToCannedJobEntryToJSON(value.answerToCannedJob),
    };
}

