/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InventoryAdjustmentInfo } from './InventoryAdjustmentInfo';
import {
    InventoryAdjustmentInfoFromJSON,
    InventoryAdjustmentInfoFromJSONTyped,
    InventoryAdjustmentInfoToJSON,
} from './InventoryAdjustmentInfo';
import type { InventoryPartInstanceInfo } from './InventoryPartInstanceInfo';
import {
    InventoryPartInstanceInfoFromJSON,
    InventoryPartInstanceInfoFromJSONTyped,
    InventoryPartInstanceInfoToJSON,
} from './InventoryPartInstanceInfo';

/**
 * 
 * @export
 * @interface CreateInventoryInstancesWithAdjustmentRequest
 */
export interface CreateInventoryInstancesWithAdjustmentRequest {
    /**
     * 
     * @type {Array<InventoryPartInstanceInfo>}
     * @memberof CreateInventoryInstancesWithAdjustmentRequest
     */
    instances?: Array<InventoryPartInstanceInfo>;
    /**
     * 
     * @type {InventoryAdjustmentInfo}
     * @memberof CreateInventoryInstancesWithAdjustmentRequest
     */
    adjustment?: InventoryAdjustmentInfo;
}

/**
 * Check if a given object implements the CreateInventoryInstancesWithAdjustmentRequest interface.
 */
export function instanceOfCreateInventoryInstancesWithAdjustmentRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateInventoryInstancesWithAdjustmentRequestFromJSON(json: any): CreateInventoryInstancesWithAdjustmentRequest {
    return CreateInventoryInstancesWithAdjustmentRequestFromJSONTyped(json, false);
}

export function CreateInventoryInstancesWithAdjustmentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateInventoryInstancesWithAdjustmentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'instances': !exists(json, 'instances') ? undefined : ((json['instances'] as Array<any>).map(InventoryPartInstanceInfoFromJSON)),
        'adjustment': !exists(json, 'adjustment') ? undefined : InventoryAdjustmentInfoFromJSON(json['adjustment']),
    };
}

export function CreateInventoryInstancesWithAdjustmentRequestToJSON(value?: CreateInventoryInstancesWithAdjustmentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'instances': value.instances === undefined ? undefined : ((value.instances as Array<any>).map(InventoryPartInstanceInfoToJSON)),
        'adjustment': InventoryAdjustmentInfoToJSON(value.adjustment),
    };
}

