/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OneTimeReminderInfo
 */
export interface OneTimeReminderInfo {
    /**
     * 
     * @type {string}
     * @memberof OneTimeReminderInfo
     */
    customer?: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeReminderInfo
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeReminderInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeReminderInfo
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeReminderInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof OneTimeReminderInfo
     */
    via?: OneTimeReminderInfoViaEnum;
    /**
     * 
     * @type {string}
     * @memberof OneTimeReminderInfo
     */
    type?: OneTimeReminderInfoTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof OneTimeReminderInfo
     */
    scheduledDateTime?: Date;
}


/**
 * @export
 */
export const OneTimeReminderInfoViaEnum = {
    Sms: 'sms',
    Email: 'email'
} as const;
export type OneTimeReminderInfoViaEnum = typeof OneTimeReminderInfoViaEnum[keyof typeof OneTimeReminderInfoViaEnum];

/**
 * @export
 */
export const OneTimeReminderInfoTypeEnum = {
    Appointment: 'appointment'
} as const;
export type OneTimeReminderInfoTypeEnum = typeof OneTimeReminderInfoTypeEnum[keyof typeof OneTimeReminderInfoTypeEnum];


/**
 * Check if a given object implements the OneTimeReminderInfo interface.
 */
export function instanceOfOneTimeReminderInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OneTimeReminderInfoFromJSON(json: any): OneTimeReminderInfo {
    return OneTimeReminderInfoFromJSONTyped(json, false);
}

export function OneTimeReminderInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OneTimeReminderInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'via': !exists(json, 'via') ? undefined : json['via'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'scheduledDateTime': !exists(json, 'scheduledDateTime') ? undefined : (new Date(json['scheduledDateTime'])),
    };
}

export function OneTimeReminderInfoToJSON(value?: OneTimeReminderInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customer': value.customer,
        'phoneNumber': value.phoneNumber,
        'email': value.email,
        'message': value.message,
        'title': value.title,
        'via': value.via,
        'type': value.type,
        'scheduledDateTime': value.scheduledDateTime === undefined ? undefined : (value.scheduledDateTime.toISOString()),
    };
}

