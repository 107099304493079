/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmbeddedDashboardRequest
 */
export interface EmbeddedDashboardRequest {
    /**
     * 
     * @type {number}
     * @memberof EmbeddedDashboardRequest
     */
    dashboardId?: number;
    /**
     * 
     * @type {string}
     * @memberof EmbeddedDashboardRequest
     */
    shop?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EmbeddedDashboardRequest
     */
    otherParams?: { [key: string]: string; };
}

/**
 * Check if a given object implements the EmbeddedDashboardRequest interface.
 */
export function instanceOfEmbeddedDashboardRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmbeddedDashboardRequestFromJSON(json: any): EmbeddedDashboardRequest {
    return EmbeddedDashboardRequestFromJSONTyped(json, false);
}

export function EmbeddedDashboardRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmbeddedDashboardRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboardId': !exists(json, 'dashboardId') ? undefined : json['dashboardId'],
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'otherParams': !exists(json, 'otherParams') ? undefined : json['otherParams'],
    };
}

export function EmbeddedDashboardRequestToJSON(value?: EmbeddedDashboardRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dashboardId': value.dashboardId,
        'shop': value.shop,
        'otherParams': value.otherParams,
    };
}

