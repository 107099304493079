/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CannedJobCustomPartEntry } from './CannedJobCustomPartEntry';
import {
    CannedJobCustomPartEntryFromJSON,
    CannedJobCustomPartEntryFromJSONTyped,
    CannedJobCustomPartEntryToJSON,
} from './CannedJobCustomPartEntry';
import type { CustomFeeEntry } from './CustomFeeEntry';
import {
    CustomFeeEntryFromJSON,
    CustomFeeEntryFromJSONTyped,
    CustomFeeEntryToJSON,
} from './CustomFeeEntry';
import type { CustomerEntry } from './CustomerEntry';
import {
    CustomerEntryFromJSON,
    CustomerEntryFromJSONTyped,
    CustomerEntryToJSON,
} from './CustomerEntry';
import type { InventoryPartInstanceEntry } from './InventoryPartInstanceEntry';
import {
    InventoryPartInstanceEntryFromJSON,
    InventoryPartInstanceEntryFromJSONTyped,
    InventoryPartInstanceEntryToJSON,
} from './InventoryPartInstanceEntry';
import type { RepairPaymentEntry } from './RepairPaymentEntry';
import {
    RepairPaymentEntryFromJSON,
    RepairPaymentEntryFromJSONTyped,
    RepairPaymentEntryToJSON,
} from './RepairPaymentEntry';

/**
 * 
 * @export
 * @interface CounterSalePricing
 */
export interface CounterSalePricing {
    /**
     * 
     * @type {number}
     * @memberof CounterSalePricing
     */
    partsCost?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterSalePricing
     */
    partsRetailPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterSalePricing
     */
    customFees?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterSalePricing
     */
    customDiscounts?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterSalePricing
     */
    shopDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterSalePricing
     */
    taxes?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterSalePricing
     */
    totalBeforeSurcharge?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterSalePricing
     */
    totalSurcharge?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterSalePricing
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterSalePricing
     */
    taxable?: number;
    /**
     * 
     * @type {number}
     * @memberof CounterSalePricing
     */
    balance?: number;
    /**
     * 
     * @type {Array<RepairPaymentEntry>}
     * @memberof CounterSalePricing
     */
    payments?: Array<RepairPaymentEntry>;
    /**
     * 
     * @type {Array<CannedJobCustomPartEntry>}
     * @memberof CounterSalePricing
     */
    cannedJobCustomPartEntries?: Array<CannedJobCustomPartEntry>;
    /**
     * 
     * @type {Array<InventoryPartInstanceEntry>}
     * @memberof CounterSalePricing
     */
    inventoryPartEntries?: Array<InventoryPartInstanceEntry>;
    /**
     * 
     * @type {Array<CustomFeeEntry>}
     * @memberof CounterSalePricing
     */
    customFeeEntries?: Array<CustomFeeEntry>;
    /**
     * 
     * @type {CustomerEntry}
     * @memberof CounterSalePricing
     */
    customerEntry?: CustomerEntry;
}

/**
 * Check if a given object implements the CounterSalePricing interface.
 */
export function instanceOfCounterSalePricing(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CounterSalePricingFromJSON(json: any): CounterSalePricing {
    return CounterSalePricingFromJSONTyped(json, false);
}

export function CounterSalePricingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CounterSalePricing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partsCost': !exists(json, 'partsCost') ? undefined : json['partsCost'],
        'partsRetailPrice': !exists(json, 'partsRetailPrice') ? undefined : json['partsRetailPrice'],
        'customFees': !exists(json, 'customFees') ? undefined : json['customFees'],
        'customDiscounts': !exists(json, 'customDiscounts') ? undefined : json['customDiscounts'],
        'shopDiscount': !exists(json, 'shopDiscount') ? undefined : json['shopDiscount'],
        'taxes': !exists(json, 'taxes') ? undefined : json['taxes'],
        'totalBeforeSurcharge': !exists(json, 'totalBeforeSurcharge') ? undefined : json['totalBeforeSurcharge'],
        'totalSurcharge': !exists(json, 'totalSurcharge') ? undefined : json['totalSurcharge'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'taxable': !exists(json, 'taxable') ? undefined : json['taxable'],
        'balance': !exists(json, 'balance') ? undefined : json['balance'],
        'payments': !exists(json, 'payments') ? undefined : ((json['payments'] as Array<any>).map(RepairPaymentEntryFromJSON)),
        'cannedJobCustomPartEntries': !exists(json, 'cannedJobCustomPartEntries') ? undefined : ((json['cannedJobCustomPartEntries'] as Array<any>).map(CannedJobCustomPartEntryFromJSON)),
        'inventoryPartEntries': !exists(json, 'inventoryPartEntries') ? undefined : ((json['inventoryPartEntries'] as Array<any>).map(InventoryPartInstanceEntryFromJSON)),
        'customFeeEntries': !exists(json, 'customFeeEntries') ? undefined : ((json['customFeeEntries'] as Array<any>).map(CustomFeeEntryFromJSON)),
        'customerEntry': !exists(json, 'customerEntry') ? undefined : CustomerEntryFromJSON(json['customerEntry']),
    };
}

export function CounterSalePricingToJSON(value?: CounterSalePricing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partsCost': value.partsCost,
        'partsRetailPrice': value.partsRetailPrice,
        'customFees': value.customFees,
        'customDiscounts': value.customDiscounts,
        'shopDiscount': value.shopDiscount,
        'taxes': value.taxes,
        'totalBeforeSurcharge': value.totalBeforeSurcharge,
        'totalSurcharge': value.totalSurcharge,
        'total': value.total,
        'taxable': value.taxable,
        'balance': value.balance,
        'payments': value.payments === undefined ? undefined : ((value.payments as Array<any>).map(RepairPaymentEntryToJSON)),
        'cannedJobCustomPartEntries': value.cannedJobCustomPartEntries === undefined ? undefined : ((value.cannedJobCustomPartEntries as Array<any>).map(CannedJobCustomPartEntryToJSON)),
        'inventoryPartEntries': value.inventoryPartEntries === undefined ? undefined : ((value.inventoryPartEntries as Array<any>).map(InventoryPartInstanceEntryToJSON)),
        'customFeeEntries': value.customFeeEntries === undefined ? undefined : ((value.customFeeEntries as Array<any>).map(CustomFeeEntryToJSON)),
        'customerEntry': CustomerEntryToJSON(value.customerEntry),
    };
}

