/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InspectionMediaInfo
 */
export interface InspectionMediaInfo {
    /**
     * 
     * @type {string}
     * @memberof InspectionMediaInfo
     */
    entry?: string;
    /**
     * 
     * @type {string}
     * @memberof InspectionMediaInfo
     */
    prompt?: string;
    /**
     * 
     * @type {string}
     * @memberof InspectionMediaInfo
     */
    answer?: string;
    /**
     * 
     * @type {number}
     * @memberof InspectionMediaInfo
     */
    rank?: number;
    /**
     * 
     * @type {string}
     * @memberof InspectionMediaInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof InspectionMediaInfo
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof InspectionMediaInfo
     */
    archived?: Date;
}

/**
 * Check if a given object implements the InspectionMediaInfo interface.
 */
export function instanceOfInspectionMediaInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InspectionMediaInfoFromJSON(json: any): InspectionMediaInfo {
    return InspectionMediaInfoFromJSONTyped(json, false);
}

export function InspectionMediaInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionMediaInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': !exists(json, 'entry') ? undefined : json['entry'],
        'prompt': !exists(json, 'prompt') ? undefined : json['prompt'],
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'archived': !exists(json, 'archived') ? undefined : (new Date(json['archived'])),
    };
}

export function InspectionMediaInfoToJSON(value?: InspectionMediaInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': value.entry,
        'prompt': value.prompt,
        'answer': value.answer,
        'rank': value.rank,
        'title': value.title,
        'description': value.description,
        'archived': value.archived === undefined ? undefined : (value.archived.toISOString()),
    };
}

