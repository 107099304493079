/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubletInfo
 */
export interface SubletInfo {
    /**
     * 
     * @type {string}
     * @memberof SubletInfo
     */
    service?: string;
    /**
     * 
     * @type {string}
     * @memberof SubletInfo
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof SubletInfo
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof SubletInfo
     */
    cost?: number;
    /**
     * 
     * @type {string}
     * @memberof SubletInfo
     */
    vendorId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubletInfo
     */
    invoiceNumber?: string;
}

/**
 * Check if a given object implements the SubletInfo interface.
 */
export function instanceOfSubletInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SubletInfoFromJSON(json: any): SubletInfo {
    return SubletInfoFromJSONTyped(json, false);
}

export function SubletInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubletInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'service': !exists(json, 'service') ? undefined : json['service'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'cost': !exists(json, 'cost') ? undefined : json['cost'],
        'vendorId': !exists(json, 'vendorId') ? undefined : json['vendorId'],
        'invoiceNumber': !exists(json, 'invoiceNumber') ? undefined : json['invoiceNumber'],
    };
}

export function SubletInfoToJSON(value?: SubletInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service': value.service,
        'title': value.title,
        'price': value.price,
        'cost': value.cost,
        'vendorId': value.vendorId,
        'invoiceNumber': value.invoiceNumber,
    };
}

