/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InventoryPartInstanceInfo } from './InventoryPartInstanceInfo';
import {
    InventoryPartInstanceInfoFromJSON,
    InventoryPartInstanceInfoFromJSONTyped,
    InventoryPartInstanceInfoToJSON,
} from './InventoryPartInstanceInfo';

/**
 * 
 * @export
 * @interface InventoryPartInstanceEntry
 */
export interface InventoryPartInstanceEntry {
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInstanceEntry
     */
    id?: string;
    /**
     * 
     * @type {InventoryPartInstanceInfo}
     * @memberof InventoryPartInstanceEntry
     */
    info?: InventoryPartInstanceInfo;
    /**
     * 
     * @type {Date}
     * @memberof InventoryPartInstanceEntry
     */
    created?: Date;
}

/**
 * Check if a given object implements the InventoryPartInstanceEntry interface.
 */
export function instanceOfInventoryPartInstanceEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InventoryPartInstanceEntryFromJSON(json: any): InventoryPartInstanceEntry {
    return InventoryPartInstanceEntryFromJSONTyped(json, false);
}

export function InventoryPartInstanceEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryPartInstanceEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : InventoryPartInstanceInfoFromJSON(json['info']),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
    };
}

export function InventoryPartInstanceEntryToJSON(value?: InventoryPartInstanceEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': InventoryPartInstanceInfoToJSON(value.info),
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
    };
}

