/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CatalogLaborMatrixInfo
 */
export interface CatalogLaborMatrixInfo {
    /**
     * 
     * @type {string}
     * @memberof CatalogLaborMatrixInfo
     */
    shop?: string;
    /**
     * 
     * @type {number}
     * @memberof CatalogLaborMatrixInfo
     */
    rank?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogLaborMatrixInfo
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogLaborMatrixInfo
     */
    ageActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CatalogLaborMatrixInfo
     */
    age?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogLaborMatrixInfo
     */
    yearActive?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CatalogLaborMatrixInfo
     */
    startYear?: number;
    /**
     * 
     * @type {number}
     * @memberof CatalogLaborMatrixInfo
     */
    endYear?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogLaborMatrixInfo
     */
    makeActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CatalogLaborMatrixInfo
     */
    make?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CatalogLaborMatrixInfo
     */
    modelActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CatalogLaborMatrixInfo
     */
    model?: string;
    /**
     * 
     * @type {number}
     * @memberof CatalogLaborMatrixInfo
     */
    factor?: number;
    /**
     * 
     * @type {string}
     * @memberof CatalogLaborMatrixInfo
     */
    archivedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof CatalogLaborMatrixInfo
     */
    dateArchived?: Date;
}

/**
 * Check if a given object implements the CatalogLaborMatrixInfo interface.
 */
export function instanceOfCatalogLaborMatrixInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CatalogLaborMatrixInfoFromJSON(json: any): CatalogLaborMatrixInfo {
    return CatalogLaborMatrixInfoFromJSONTyped(json, false);
}

export function CatalogLaborMatrixInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CatalogLaborMatrixInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'ageActive': !exists(json, 'ageActive') ? undefined : json['ageActive'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'yearActive': !exists(json, 'yearActive') ? undefined : json['yearActive'],
        'startYear': !exists(json, 'startYear') ? undefined : json['startYear'],
        'endYear': !exists(json, 'endYear') ? undefined : json['endYear'],
        'makeActive': !exists(json, 'makeActive') ? undefined : json['makeActive'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'modelActive': !exists(json, 'modelActive') ? undefined : json['modelActive'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'factor': !exists(json, 'factor') ? undefined : json['factor'],
        'archivedBy': !exists(json, 'archivedBy') ? undefined : json['archivedBy'],
        'dateArchived': !exists(json, 'dateArchived') ? undefined : (new Date(json['dateArchived'])),
    };
}

export function CatalogLaborMatrixInfoToJSON(value?: CatalogLaborMatrixInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'rank': value.rank,
        'description': value.description,
        'ageActive': value.ageActive,
        'age': value.age,
        'yearActive': value.yearActive,
        'startYear': value.startYear,
        'endYear': value.endYear,
        'makeActive': value.makeActive,
        'make': value.make,
        'modelActive': value.modelActive,
        'model': value.model,
        'factor': value.factor,
        'archivedBy': value.archivedBy,
        'dateArchived': value.dateArchived === undefined ? undefined : (value.dateArchived.toISOString()),
    };
}

