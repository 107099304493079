/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PartsTechPart } from './PartsTechPart';
import {
    PartsTechPartFromJSON,
    PartsTechPartFromJSONTyped,
    PartsTechPartToJSON,
} from './PartsTechPart';

/**
 * 
 * @export
 * @interface PartEntry
 */
export interface PartEntry {
    /**
     * 
     * @type {string}
     * @memberof PartEntry
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof PartEntry
     */
    service?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PartEntry
     */
    ordered?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartEntry
     */
    poNumber?: string;
    /**
     * 
     * @type {PartsTechPart}
     * @memberof PartEntry
     */
    info?: PartsTechPart;
}

/**
 * Check if a given object implements the PartEntry interface.
 */
export function instanceOfPartEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PartEntryFromJSON(json: any): PartEntry {
    return PartEntryFromJSONTyped(json, false);
}

export function PartEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'ordered': !exists(json, 'ordered') ? undefined : json['ordered'],
        'poNumber': !exists(json, 'poNumber') ? undefined : json['poNumber'],
        'info': !exists(json, 'info') ? undefined : PartsTechPartFromJSON(json['info']),
    };
}

export function PartEntryToJSON(value?: PartEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'service': value.service,
        'ordered': value.ordered,
        'poNumber': value.poNumber,
        'info': PartsTechPartToJSON(value.info),
    };
}

