/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerFeedbackInfo
 */
export interface CustomerFeedbackInfo {
    /**
     * 
     * @type {boolean}
     * @memberof CustomerFeedbackInfo
     */
    warranty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerFeedbackInfo
     */
    timely?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerFeedbackInfo
     */
    courteous?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerFeedbackInfo
     */
    informed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerFeedbackInfo
     */
    completed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomerFeedbackInfo
     */
    stars?: number;
}

/**
 * Check if a given object implements the CustomerFeedbackInfo interface.
 */
export function instanceOfCustomerFeedbackInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerFeedbackInfoFromJSON(json: any): CustomerFeedbackInfo {
    return CustomerFeedbackInfoFromJSONTyped(json, false);
}

export function CustomerFeedbackInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerFeedbackInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'warranty': !exists(json, 'warranty') ? undefined : json['warranty'],
        'timely': !exists(json, 'timely') ? undefined : json['timely'],
        'courteous': !exists(json, 'courteous') ? undefined : json['courteous'],
        'informed': !exists(json, 'informed') ? undefined : json['informed'],
        'completed': !exists(json, 'completed') ? undefined : json['completed'],
        'stars': !exists(json, 'stars') ? undefined : json['stars'],
    };
}

export function CustomerFeedbackInfoToJSON(value?: CustomerFeedbackInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'warranty': value.warranty,
        'timely': value.timely,
        'courteous': value.courteous,
        'informed': value.informed,
        'completed': value.completed,
        'stars': value.stars,
    };
}

