/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReplicacheCookie } from './ReplicacheCookie';
import {
    ReplicacheCookieFromJSON,
    ReplicacheCookieFromJSONTyped,
    ReplicacheCookieToJSON,
} from './ReplicacheCookie';

/**
 * 
 * @export
 * @interface PullRequest
 */
export interface PullRequest {
    /**
     * 
     * @type {number}
     * @memberof PullRequest
     */
    pullVersion?: number;
    /**
     * 
     * @type {string}
     * @memberof PullRequest
     */
    clientGroupID?: string;
    /**
     * 
     * @type {ReplicacheCookie}
     * @memberof PullRequest
     */
    cookie?: ReplicacheCookie;
    /**
     * 
     * @type {string}
     * @memberof PullRequest
     */
    profileID?: string;
    /**
     * 
     * @type {string}
     * @memberof PullRequest
     */
    schemaVersion?: string;
}

/**
 * Check if a given object implements the PullRequest interface.
 */
export function instanceOfPullRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PullRequestFromJSON(json: any): PullRequest {
    return PullRequestFromJSONTyped(json, false);
}

export function PullRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PullRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pullVersion': !exists(json, 'pullVersion') ? undefined : json['pullVersion'],
        'clientGroupID': !exists(json, 'clientGroupID') ? undefined : json['clientGroupID'],
        'cookie': !exists(json, 'cookie') ? undefined : ReplicacheCookieFromJSON(json['cookie']),
        'profileID': !exists(json, 'profileID') ? undefined : json['profileID'],
        'schemaVersion': !exists(json, 'schemaVersion') ? undefined : json['schemaVersion'],
    };
}

export function PullRequestToJSON(value?: PullRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pullVersion': value.pullVersion,
        'clientGroupID': value.clientGroupID,
        'cookie': ReplicacheCookieToJSON(value.cookie),
        'profileID': value.profileID,
        'schemaVersion': value.schemaVersion,
    };
}

