/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LaborMatrixInfo
 */
export interface LaborMatrixInfo {
    /**
     * 
     * @type {string}
     * @memberof LaborMatrixInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof LaborMatrixInfo
     */
    priceLevel?: string;
    /**
     * 
     * @type {number}
     * @memberof LaborMatrixInfo
     */
    hourlyRate?: number;
    /**
     * 
     * @type {string}
     * @memberof LaborMatrixInfo
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof LaborMatrixInfo
     */
    archived?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof LaborMatrixInfo
     */
    isDefault?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LaborMatrixInfo
     */
    rank?: number;
}

/**
 * Check if a given object implements the LaborMatrixInfo interface.
 */
export function instanceOfLaborMatrixInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LaborMatrixInfoFromJSON(json: any): LaborMatrixInfo {
    return LaborMatrixInfoFromJSONTyped(json, false);
}

export function LaborMatrixInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LaborMatrixInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'priceLevel': !exists(json, 'priceLevel') ? undefined : json['priceLevel'],
        'hourlyRate': !exists(json, 'hourlyRate') ? undefined : json['hourlyRate'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'archived': !exists(json, 'archived') ? undefined : (new Date(json['archived'])),
        'isDefault': !exists(json, 'isDefault') ? undefined : json['isDefault'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
    };
}

export function LaborMatrixInfoToJSON(value?: LaborMatrixInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'priceLevel': value.priceLevel,
        'hourlyRate': value.hourlyRate,
        'description': value.description,
        'archived': value.archived === undefined ? undefined : (value.archived.toISOString()),
        'isDefault': value.isDefault,
        'rank': value.rank,
    };
}

