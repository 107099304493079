/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreditCardReader
 */
export interface CreditCardReader {
    /**
     * 
     * @type {string}
     * @memberof CreditCardReader
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCardReader
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCardReader
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCardReader
     */
    finixDeviceId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreditCardReader
     */
    activated?: boolean;
}

/**
 * Check if a given object implements the CreditCardReader interface.
 */
export function instanceOfCreditCardReader(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreditCardReaderFromJSON(json: any): CreditCardReader {
    return CreditCardReaderFromJSONTyped(json, false);
}

export function CreditCardReaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreditCardReader {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'finixDeviceId': !exists(json, 'finixDeviceId') ? undefined : json['finixDeviceId'],
        'activated': !exists(json, 'activated') ? undefined : json['activated'],
    };
}

export function CreditCardReaderToJSON(value?: CreditCardReader | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'shop': value.shop,
        'name': value.name,
        'finixDeviceId': value.finixDeviceId,
        'activated': value.activated,
    };
}

