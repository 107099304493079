/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PurchaseOrderInfo
 */
export interface PurchaseOrderInfo {
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInfo
     */
    vendor?: string;
    /**
     * 
     * @type {Date}
     * @memberof PurchaseOrderInfo
     */
    invoiceDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PurchaseOrderInfo
     */
    number?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderInfo
     */
    subtotal?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderInfo
     */
    tax?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderInfo
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchaseOrderInfo
     */
    partsTechPurchaseId?: number;
}

/**
 * Check if a given object implements the PurchaseOrderInfo interface.
 */
export function instanceOfPurchaseOrderInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PurchaseOrderInfoFromJSON(json: any): PurchaseOrderInfo {
    return PurchaseOrderInfoFromJSONTyped(json, false);
}

export function PurchaseOrderInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchaseOrderInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vendor': !exists(json, 'vendor') ? undefined : json['vendor'],
        'invoiceDate': !exists(json, 'invoiceDate') ? undefined : (new Date(json['invoiceDate'])),
        'number': !exists(json, 'number') ? undefined : json['number'],
        'subtotal': !exists(json, 'subtotal') ? undefined : json['subtotal'],
        'tax': !exists(json, 'tax') ? undefined : json['tax'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'partsTechPurchaseId': !exists(json, 'partsTechPurchaseId') ? undefined : json['partsTechPurchaseId'],
    };
}

export function PurchaseOrderInfoToJSON(value?: PurchaseOrderInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vendor': value.vendor,
        'invoiceDate': value.invoiceDate === undefined ? undefined : (value.invoiceDate.toISOString()),
        'number': value.number,
        'subtotal': value.subtotal,
        'tax': value.tax,
        'total': value.total,
        'partsTechPurchaseId': value.partsTechPurchaseId,
    };
}

