/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InspectionInfo
 */
export interface InspectionInfo {
    /**
     * 
     * @type {string}
     * @memberof InspectionInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof InspectionInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof InspectionInfo
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InspectionInfo
     */
    repairOrder?: string;
    /**
     * 
     * @type {string}
     * @memberof InspectionInfo
     */
    tech?: string;
    /**
     * 
     * @type {string}
     * @memberof InspectionInfo
     */
    notes?: string;
    /**
     * 
     * @type {Date}
     * @memberof InspectionInfo
     */
    archived?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InspectionInfo
     */
    started?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InspectionInfo
     */
    finished?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InspectionInfo
     */
    advisorCompleteTime?: Date;
}

/**
 * Check if a given object implements the InspectionInfo interface.
 */
export function instanceOfInspectionInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InspectionInfoFromJSON(json: any): InspectionInfo {
    return InspectionInfoFromJSONTyped(json, false);
}

export function InspectionInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'repairOrder': !exists(json, 'repairOrder') ? undefined : json['repairOrder'],
        'tech': !exists(json, 'tech') ? undefined : json['tech'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'archived': !exists(json, 'archived') ? undefined : (new Date(json['archived'])),
        'started': !exists(json, 'started') ? undefined : (new Date(json['started'])),
        'finished': !exists(json, 'finished') ? undefined : (new Date(json['finished'])),
        'advisorCompleteTime': !exists(json, 'advisorCompleteTime') ? undefined : (new Date(json['advisorCompleteTime'])),
    };
}

export function InspectionInfoToJSON(value?: InspectionInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'title': value.title,
        'description': value.description,
        'repairOrder': value.repairOrder,
        'tech': value.tech,
        'notes': value.notes,
        'archived': value.archived === undefined ? undefined : (value.archived.toISOString()),
        'started': value.started === undefined ? undefined : (value.started.toISOString()),
        'finished': value.finished === undefined ? undefined : (value.finished.toISOString()),
        'advisorCompleteTime': value.advisorCompleteTime === undefined ? undefined : (value.advisorCompleteTime.toISOString()),
    };
}

