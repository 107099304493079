/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InspectionEntryStatusInfo
 */
export interface InspectionEntryStatusInfo {
    /**
     * 
     * @type {string}
     * @memberof InspectionEntryStatusInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof InspectionEntryStatusInfo
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof InspectionEntryStatusInfo
     */
    rank?: number;
    /**
     * 
     * @type {string}
     * @memberof InspectionEntryStatusInfo
     */
    color?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InspectionEntryStatusInfo
     */
    showInCustomerPortal?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InspectionEntryStatusInfo
     */
    requiresNotesOrPhoto?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof InspectionEntryStatusInfo
     */
    archivedAt?: Date;
}

/**
 * Check if a given object implements the InspectionEntryStatusInfo interface.
 */
export function instanceOfInspectionEntryStatusInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InspectionEntryStatusInfoFromJSON(json: any): InspectionEntryStatusInfo {
    return InspectionEntryStatusInfoFromJSONTyped(json, false);
}

export function InspectionEntryStatusInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionEntryStatusInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'showInCustomerPortal': !exists(json, 'showInCustomerPortal') ? undefined : json['showInCustomerPortal'],
        'requiresNotesOrPhoto': !exists(json, 'requiresNotesOrPhoto') ? undefined : json['requiresNotesOrPhoto'],
        'archivedAt': !exists(json, 'archivedAt') ? undefined : (new Date(json['archivedAt'])),
    };
}

export function InspectionEntryStatusInfoToJSON(value?: InspectionEntryStatusInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'title': value.title,
        'rank': value.rank,
        'color': value.color,
        'showInCustomerPortal': value.showInCustomerPortal,
        'requiresNotesOrPhoto': value.requiresNotesOrPhoto,
        'archivedAt': value.archivedAt === undefined ? undefined : (value.archivedAt.toISOString()),
    };
}

