/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CannedJobInventoryPartInfo
 */
export interface CannedJobInventoryPartInfo {
    /**
     * 
     * @type {string}
     * @memberof CannedJobInventoryPartInfo
     */
    cannedJob?: string;
    /**
     * 
     * @type {string}
     * @memberof CannedJobInventoryPartInfo
     */
    inventoryPart?: string;
    /**
     * 
     * @type {number}
     * @memberof CannedJobInventoryPartInfo
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof CannedJobInventoryPartInfo
     */
    partsMatrix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CannedJobInventoryPartInfo
     */
    taxExempt?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CannedJobInventoryPartInfo
     */
    unitOfMeasure?: CannedJobInventoryPartInfoUnitOfMeasureEnum;
}


/**
 * @export
 */
export const CannedJobInventoryPartInfoUnitOfMeasureEnum = {
    DiscreteUnit: 'discrete_unit',
    Liter: 'liter',
    Ml: 'ml',
    Gallon: 'gallon',
    Quart: 'quart',
    Ounce: 'ounce',
    FlOz: 'fl_oz'
} as const;
export type CannedJobInventoryPartInfoUnitOfMeasureEnum = typeof CannedJobInventoryPartInfoUnitOfMeasureEnum[keyof typeof CannedJobInventoryPartInfoUnitOfMeasureEnum];


/**
 * Check if a given object implements the CannedJobInventoryPartInfo interface.
 */
export function instanceOfCannedJobInventoryPartInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CannedJobInventoryPartInfoFromJSON(json: any): CannedJobInventoryPartInfo {
    return CannedJobInventoryPartInfoFromJSONTyped(json, false);
}

export function CannedJobInventoryPartInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CannedJobInventoryPartInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cannedJob': !exists(json, 'cannedJob') ? undefined : json['cannedJob'],
        'inventoryPart': !exists(json, 'inventoryPart') ? undefined : json['inventoryPart'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'partsMatrix': !exists(json, 'partsMatrix') ? undefined : json['partsMatrix'],
        'taxExempt': !exists(json, 'taxExempt') ? undefined : json['taxExempt'],
        'unitOfMeasure': !exists(json, 'unitOfMeasure') ? undefined : json['unitOfMeasure'],
    };
}

export function CannedJobInventoryPartInfoToJSON(value?: CannedJobInventoryPartInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cannedJob': value.cannedJob,
        'inventoryPart': value.inventoryPart,
        'quantity': value.quantity,
        'partsMatrix': value.partsMatrix,
        'taxExempt': value.taxExempt,
        'unitOfMeasure': value.unitOfMeasure,
    };
}

