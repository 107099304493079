/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InventoryAdjustmentDocumentEntry } from './InventoryAdjustmentDocumentEntry';
import {
    InventoryAdjustmentDocumentEntryFromJSON,
    InventoryAdjustmentDocumentEntryFromJSONTyped,
    InventoryAdjustmentDocumentEntryToJSON,
} from './InventoryAdjustmentDocumentEntry';
import type { InventoryAdjustmentEntry } from './InventoryAdjustmentEntry';
import {
    InventoryAdjustmentEntryFromJSON,
    InventoryAdjustmentEntryFromJSONTyped,
    InventoryAdjustmentEntryToJSON,
} from './InventoryAdjustmentEntry';

/**
 * 
 * @export
 * @interface InventoryAdjustmentEntryExpanded
 */
export interface InventoryAdjustmentEntryExpanded {
    /**
     * 
     * @type {InventoryAdjustmentEntry}
     * @memberof InventoryAdjustmentEntryExpanded
     */
    entry?: InventoryAdjustmentEntry;
    /**
     * 
     * @type {Array<InventoryAdjustmentDocumentEntry>}
     * @memberof InventoryAdjustmentEntryExpanded
     */
    documents?: Array<InventoryAdjustmentDocumentEntry>;
}

/**
 * Check if a given object implements the InventoryAdjustmentEntryExpanded interface.
 */
export function instanceOfInventoryAdjustmentEntryExpanded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InventoryAdjustmentEntryExpandedFromJSON(json: any): InventoryAdjustmentEntryExpanded {
    return InventoryAdjustmentEntryExpandedFromJSONTyped(json, false);
}

export function InventoryAdjustmentEntryExpandedFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryAdjustmentEntryExpanded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': !exists(json, 'entry') ? undefined : InventoryAdjustmentEntryFromJSON(json['entry']),
        'documents': !exists(json, 'documents') ? undefined : ((json['documents'] as Array<any>).map(InventoryAdjustmentDocumentEntryFromJSON)),
    };
}

export function InventoryAdjustmentEntryExpandedToJSON(value?: InventoryAdjustmentEntryExpanded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': InventoryAdjustmentEntryToJSON(value.entry),
        'documents': value.documents === undefined ? undefined : ((value.documents as Array<any>).map(InventoryAdjustmentDocumentEntryToJSON)),
    };
}

