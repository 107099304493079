/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CounterSaleInfo
 */
export interface CounterSaleInfo {
    /**
     * 
     * @type {string}
     * @memberof CounterSaleInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof CounterSaleInfo
     */
    customer?: string;
    /**
     * 
     * @type {string}
     * @memberof CounterSaleInfo
     */
    taxesAndFees?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CounterSaleInfo
     */
    taxExempt?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CounterSaleInfo
     */
    discount?: string;
    /**
     * 
     * @type {string}
     * @memberof CounterSaleInfo
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof CounterSaleInfo
     */
    status?: CounterSaleInfoStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof CounterSaleInfo
     */
    archived?: Date;
    /**
     * 
     * @type {string}
     * @memberof CounterSaleInfo
     */
    archiveReason?: string;
    /**
     * 
     * @type {Date}
     * @memberof CounterSaleInfo
     */
    soldOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CounterSaleInfo
     */
    refundedOn?: Date;
}


/**
 * @export
 */
export const CounterSaleInfoStatusEnum = {
    InProgress: 'in_progress',
    Completed: 'completed',
    Refunded: 'refunded'
} as const;
export type CounterSaleInfoStatusEnum = typeof CounterSaleInfoStatusEnum[keyof typeof CounterSaleInfoStatusEnum];


/**
 * Check if a given object implements the CounterSaleInfo interface.
 */
export function instanceOfCounterSaleInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CounterSaleInfoFromJSON(json: any): CounterSaleInfo {
    return CounterSaleInfoFromJSONTyped(json, false);
}

export function CounterSaleInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CounterSaleInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'taxesAndFees': !exists(json, 'taxesAndFees') ? undefined : json['taxesAndFees'],
        'taxExempt': !exists(json, 'taxExempt') ? undefined : json['taxExempt'],
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'archived': !exists(json, 'archived') ? undefined : (new Date(json['archived'])),
        'archiveReason': !exists(json, 'archiveReason') ? undefined : json['archiveReason'],
        'soldOn': !exists(json, 'soldOn') ? undefined : (new Date(json['soldOn'])),
        'refundedOn': !exists(json, 'refundedOn') ? undefined : (new Date(json['refundedOn'])),
    };
}

export function CounterSaleInfoToJSON(value?: CounterSaleInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'customer': value.customer,
        'taxesAndFees': value.taxesAndFees,
        'taxExempt': value.taxExempt,
        'discount': value.discount,
        'notes': value.notes,
        'status': value.status,
        'archived': value.archived === undefined ? undefined : (value.archived.toISOString()),
        'archiveReason': value.archiveReason,
        'soldOn': value.soldOn === undefined ? undefined : (value.soldOn.toISOString()),
        'refundedOn': value.refundedOn === undefined ? undefined : (value.refundedOn.toISOString()),
    };
}

