/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VehicleReminderMessageInfo
 */
export interface VehicleReminderMessageInfo {
    /**
     * 
     * @type {string}
     * @memberof VehicleReminderMessageInfo
     */
    vehicle?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleReminderMessageInfo
     */
    type?: VehicleReminderMessageInfoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VehicleReminderMessageInfo
     */
    message?: string;
}


/**
 * @export
 */
export const VehicleReminderMessageInfoTypeEnum = {
    Inspection: 'inspection',
    OilService: 'oil_service'
} as const;
export type VehicleReminderMessageInfoTypeEnum = typeof VehicleReminderMessageInfoTypeEnum[keyof typeof VehicleReminderMessageInfoTypeEnum];


/**
 * Check if a given object implements the VehicleReminderMessageInfo interface.
 */
export function instanceOfVehicleReminderMessageInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VehicleReminderMessageInfoFromJSON(json: any): VehicleReminderMessageInfo {
    return VehicleReminderMessageInfoFromJSONTyped(json, false);
}

export function VehicleReminderMessageInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleReminderMessageInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vehicle': !exists(json, 'vehicle') ? undefined : json['vehicle'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function VehicleReminderMessageInfoToJSON(value?: VehicleReminderMessageInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vehicle': value.vehicle,
        'type': value.type,
        'message': value.message,
    };
}

