/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InventoryAdjustmentInfo
 */
export interface InventoryAdjustmentInfo {
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentInfo
     */
    adjustmentType?: InventoryAdjustmentInfoAdjustmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentInfo
     */
    adjustmentMethod?: InventoryAdjustmentInfoAdjustmentMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentInfo
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof InventoryAdjustmentInfo
     */
    currentQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryAdjustmentInfo
     */
    newQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryAdjustmentInfo
     */
    quantityDifference?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentInfo
     */
    unitOfMeasure?: InventoryAdjustmentInfoUnitOfMeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof InventoryAdjustmentInfo
     */
    archivedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof InventoryAdjustmentInfo
     */
    archivedAt?: Date;
}


/**
 * @export
 */
export const InventoryAdjustmentInfoAdjustmentTypeEnum = {
    Add: 'Add',
    Remove: 'Remove',
    Return: 'Return',
    Reconciliation: 'Reconciliation',
    MarkAsSold: 'Mark_As_Sold',
    ManualUpdate: 'Manual_Update'
} as const;
export type InventoryAdjustmentInfoAdjustmentTypeEnum = typeof InventoryAdjustmentInfoAdjustmentTypeEnum[keyof typeof InventoryAdjustmentInfoAdjustmentTypeEnum];

/**
 * @export
 */
export const InventoryAdjustmentInfoAdjustmentMethodEnum = {
    Lifo: 'LIFO',
    Fifo: 'FIFO',
    ManualSelection: 'Manual_Selection',
    NotApplicable: 'Not_Applicable'
} as const;
export type InventoryAdjustmentInfoAdjustmentMethodEnum = typeof InventoryAdjustmentInfoAdjustmentMethodEnum[keyof typeof InventoryAdjustmentInfoAdjustmentMethodEnum];

/**
 * @export
 */
export const InventoryAdjustmentInfoUnitOfMeasureEnum = {
    DiscreteUnit: 'discrete_unit',
    Liter: 'liter',
    Ml: 'ml',
    Gallon: 'gallon',
    Quart: 'quart',
    Ounce: 'ounce',
    FlOz: 'fl_oz'
} as const;
export type InventoryAdjustmentInfoUnitOfMeasureEnum = typeof InventoryAdjustmentInfoUnitOfMeasureEnum[keyof typeof InventoryAdjustmentInfoUnitOfMeasureEnum];


/**
 * Check if a given object implements the InventoryAdjustmentInfo interface.
 */
export function instanceOfInventoryAdjustmentInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InventoryAdjustmentInfoFromJSON(json: any): InventoryAdjustmentInfo {
    return InventoryAdjustmentInfoFromJSONTyped(json, false);
}

export function InventoryAdjustmentInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryAdjustmentInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'adjustmentType': !exists(json, 'adjustmentType') ? undefined : json['adjustmentType'],
        'adjustmentMethod': !exists(json, 'adjustmentMethod') ? undefined : json['adjustmentMethod'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'currentQuantity': !exists(json, 'currentQuantity') ? undefined : json['currentQuantity'],
        'newQuantity': !exists(json, 'newQuantity') ? undefined : json['newQuantity'],
        'quantityDifference': !exists(json, 'quantityDifference') ? undefined : json['quantityDifference'],
        'unitOfMeasure': !exists(json, 'unitOfMeasure') ? undefined : json['unitOfMeasure'],
        'archivedBy': !exists(json, 'archivedBy') ? undefined : json['archivedBy'],
        'archivedAt': !exists(json, 'archivedAt') ? undefined : (new Date(json['archivedAt'])),
    };
}

export function InventoryAdjustmentInfoToJSON(value?: InventoryAdjustmentInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'adjustmentType': value.adjustmentType,
        'adjustmentMethod': value.adjustmentMethod,
        'notes': value.notes,
        'currentQuantity': value.currentQuantity,
        'newQuantity': value.newQuantity,
        'quantityDifference': value.quantityDifference,
        'unitOfMeasure': value.unitOfMeasure,
        'archivedBy': value.archivedBy,
        'archivedAt': value.archivedAt === undefined ? undefined : (value.archivedAt.toISOString()),
    };
}

