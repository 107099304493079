/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SerializableRelationship
 */
export interface SerializableRelationship {
    /**
     * 
     * @type {Array<string>}
     * @memberof SerializableRelationship
     */
    ids?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SerializableRelationship
     */
    type?: SerializableRelationshipTypeEnum;
}


/**
 * @export
 */
export const SerializableRelationshipTypeEnum = {
    Value: 'VALUE',
    Child: 'CHILD',
    ComplexFeatures: 'COMPLEX_FEATURES',
    MergedCell: 'MERGED_CELL',
    Title: 'TITLE',
    Answer: 'ANSWER',
    Table: 'TABLE',
    TableTitle: 'TABLE_TITLE',
    TableFooter: 'TABLE_FOOTER',
    Null: 'null'
} as const;
export type SerializableRelationshipTypeEnum = typeof SerializableRelationshipTypeEnum[keyof typeof SerializableRelationshipTypeEnum];


/**
 * Check if a given object implements the SerializableRelationship interface.
 */
export function instanceOfSerializableRelationship(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SerializableRelationshipFromJSON(json: any): SerializableRelationship {
    return SerializableRelationshipFromJSONTyped(json, false);
}

export function SerializableRelationshipFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerializableRelationship {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function SerializableRelationshipToJSON(value?: SerializableRelationship | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
        'type': value.type,
    };
}

