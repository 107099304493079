/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePriceLevelRanksRequest
 */
export interface UpdatePriceLevelRanksRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePriceLevelRanksRequest
     */
    shop?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePriceLevelRanksRequest
     */
    priceLevels?: Array<string>;
}

/**
 * Check if a given object implements the UpdatePriceLevelRanksRequest interface.
 */
export function instanceOfUpdatePriceLevelRanksRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdatePriceLevelRanksRequestFromJSON(json: any): UpdatePriceLevelRanksRequest {
    return UpdatePriceLevelRanksRequestFromJSONTyped(json, false);
}

export function UpdatePriceLevelRanksRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePriceLevelRanksRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'priceLevels': !exists(json, 'priceLevels') ? undefined : json['priceLevels'],
    };
}

export function UpdatePriceLevelRanksRequestToJSON(value?: UpdatePriceLevelRanksRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'priceLevels': value.priceLevels,
    };
}

