/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChatRequestSetting } from './ChatRequestSetting';
import {
    ChatRequestSettingFromJSON,
    ChatRequestSettingFromJSONTyped,
    ChatRequestSettingToJSON,
} from './ChatRequestSetting';

/**
 * 
 * @export
 * @interface RewriteRequest
 */
export interface RewriteRequest {
    /**
     * 
     * @type {ChatRequestSetting}
     * @memberof RewriteRequest
     */
    chatRequestSetting?: ChatRequestSetting;
    /**
     * 
     * @type {string}
     * @memberof RewriteRequest
     */
    customPrompt?: string;
    /**
     * 
     * @type {string}
     * @memberof RewriteRequest
     */
    existingText?: string;
    /**
     * 
     * @type {string}
     * @memberof RewriteRequest
     */
    shop?: string;
}

/**
 * Check if a given object implements the RewriteRequest interface.
 */
export function instanceOfRewriteRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RewriteRequestFromJSON(json: any): RewriteRequest {
    return RewriteRequestFromJSONTyped(json, false);
}

export function RewriteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RewriteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chatRequestSetting': !exists(json, 'chatRequestSetting') ? undefined : ChatRequestSettingFromJSON(json['chatRequestSetting']),
        'customPrompt': !exists(json, 'customPrompt') ? undefined : json['customPrompt'],
        'existingText': !exists(json, 'existingText') ? undefined : json['existingText'],
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
    };
}

export function RewriteRequestToJSON(value?: RewriteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chatRequestSetting': ChatRequestSettingToJSON(value.chatRequestSetting),
        'customPrompt': value.customPrompt,
        'existingText': value.existingText,
        'shop': value.shop,
    };
}

