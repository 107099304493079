/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepairOrderInfo
 */
export interface RepairOrderInfo {
    /**
     * 
     * @type {string}
     * @memberof RepairOrderInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderInfo
     */
    customer?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderInfo
     */
    vehicle?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderInfo
     */
    appointment?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RepairOrderInfo
     */
    isEstimate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderInfo
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderInfo
     */
    concern?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderInfo
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderInfo
     */
    serviceAdvisor?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderInfo
     */
    taxesAndFees?: string;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderInfo
     */
    mileageIn?: number;
    /**
     * 
     * @type {number}
     * @memberof RepairOrderInfo
     */
    mileageOut?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RepairOrderInfo
     */
    taxExempt?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairOrderInfo
     */
    shopSuppliesFeeExempt?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairOrderInfo
     */
    shopHazmatFeeExempt?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RepairOrderInfo
     */
    customFeeExempt?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderInfo
     */
    discount?: string;
    /**
     * 
     * @type {Date}
     * @memberof RepairOrderInfo
     */
    closedOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RepairOrderInfo
     */
    followUp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RepairOrderInfo
     */
    archived?: Date;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderInfo
     */
    archiveReason?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderInfo
     */
    codatId?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderInfo
     */
    codatPushKey?: string;
    /**
     * 
     * @type {Date}
     * @memberof RepairOrderInfo
     */
    lastCodatModifiedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RepairOrderInfo
     */
    invoiceDate?: Date;
}

/**
 * Check if a given object implements the RepairOrderInfo interface.
 */
export function instanceOfRepairOrderInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairOrderInfoFromJSON(json: any): RepairOrderInfo {
    return RepairOrderInfoFromJSONTyped(json, false);
}

export function RepairOrderInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairOrderInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'vehicle': !exists(json, 'vehicle') ? undefined : json['vehicle'],
        'appointment': !exists(json, 'appointment') ? undefined : json['appointment'],
        'isEstimate': !exists(json, 'isEstimate') ? undefined : json['isEstimate'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'concern': !exists(json, 'concern') ? undefined : json['concern'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'serviceAdvisor': !exists(json, 'serviceAdvisor') ? undefined : json['serviceAdvisor'],
        'taxesAndFees': !exists(json, 'taxesAndFees') ? undefined : json['taxesAndFees'],
        'mileageIn': !exists(json, 'mileageIn') ? undefined : json['mileageIn'],
        'mileageOut': !exists(json, 'mileageOut') ? undefined : json['mileageOut'],
        'taxExempt': !exists(json, 'taxExempt') ? undefined : json['taxExempt'],
        'shopSuppliesFeeExempt': !exists(json, 'shopSuppliesFeeExempt') ? undefined : json['shopSuppliesFeeExempt'],
        'shopHazmatFeeExempt': !exists(json, 'shopHazmatFeeExempt') ? undefined : json['shopHazmatFeeExempt'],
        'customFeeExempt': !exists(json, 'customFeeExempt') ? undefined : json['customFeeExempt'],
        'discount': !exists(json, 'discount') ? undefined : json['discount'],
        'closedOn': !exists(json, 'closedOn') ? undefined : (new Date(json['closedOn'])),
        'followUp': !exists(json, 'followUp') ? undefined : (new Date(json['followUp'])),
        'archived': !exists(json, 'archived') ? undefined : (new Date(json['archived'])),
        'archiveReason': !exists(json, 'archiveReason') ? undefined : json['archiveReason'],
        'codatId': !exists(json, 'codatId') ? undefined : json['codatId'],
        'codatPushKey': !exists(json, 'codatPushKey') ? undefined : json['codatPushKey'],
        'lastCodatModifiedDate': !exists(json, 'lastCodatModifiedDate') ? undefined : (new Date(json['lastCodatModifiedDate'])),
        'invoiceDate': !exists(json, 'invoiceDate') ? undefined : (new Date(json['invoiceDate'])),
    };
}

export function RepairOrderInfoToJSON(value?: RepairOrderInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'customer': value.customer,
        'vehicle': value.vehicle,
        'appointment': value.appointment,
        'isEstimate': value.isEstimate,
        'status': value.status,
        'concern': value.concern,
        'notes': value.notes,
        'serviceAdvisor': value.serviceAdvisor,
        'taxesAndFees': value.taxesAndFees,
        'mileageIn': value.mileageIn,
        'mileageOut': value.mileageOut,
        'taxExempt': value.taxExempt,
        'shopSuppliesFeeExempt': value.shopSuppliesFeeExempt,
        'shopHazmatFeeExempt': value.shopHazmatFeeExempt,
        'customFeeExempt': value.customFeeExempt,
        'discount': value.discount,
        'closedOn': value.closedOn === undefined ? undefined : (value.closedOn.toISOString()),
        'followUp': value.followUp === undefined ? undefined : (value.followUp.toISOString()),
        'archived': value.archived === undefined ? undefined : (value.archived.toISOString()),
        'archiveReason': value.archiveReason,
        'codatId': value.codatId,
        'codatPushKey': value.codatPushKey,
        'lastCodatModifiedDate': value.lastCodatModifiedDate === undefined ? undefined : (value.lastCodatModifiedDate.toISOString()),
        'invoiceDate': value.invoiceDate === undefined ? undefined : (value.invoiceDate.toISOString()),
    };
}

