/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RepairLinkPartInCartData } from './RepairLinkPartInCartData';
import {
    RepairLinkPartInCartDataFromJSON,
    RepairLinkPartInCartDataFromJSONTyped,
    RepairLinkPartInCartDataToJSON,
} from './RepairLinkPartInCartData';

/**
 * 
 * @export
 * @interface RepairLinkPartInCartInfo
 */
export interface RepairLinkPartInCartInfo {
    /**
     * 
     * @type {string}
     * @memberof RepairLinkPartInCartInfo
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkPartInCartInfo
     */
    service?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkPartInCartInfo
     */
    repairLinkOrder?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkPartInCartInfo
     */
    repairLinkDealer?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkPartInCartInfo
     */
    repairLinkVehicle?: string;
    /**
     * 
     * @type {RepairLinkPartInCartData}
     * @memberof RepairLinkPartInCartInfo
     */
    data?: RepairLinkPartInCartData;
}

/**
 * Check if a given object implements the RepairLinkPartInCartInfo interface.
 */
export function instanceOfRepairLinkPartInCartInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairLinkPartInCartInfoFromJSON(json: any): RepairLinkPartInCartInfo {
    return RepairLinkPartInCartInfoFromJSONTyped(json, false);
}

export function RepairLinkPartInCartInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairLinkPartInCartInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'repairLinkOrder': !exists(json, 'repairLinkOrder') ? undefined : json['repairLinkOrder'],
        'repairLinkDealer': !exists(json, 'repairLinkDealer') ? undefined : json['repairLinkDealer'],
        'repairLinkVehicle': !exists(json, 'repairLinkVehicle') ? undefined : json['repairLinkVehicle'],
        'data': !exists(json, 'data') ? undefined : RepairLinkPartInCartDataFromJSON(json['data']),
    };
}

export function RepairLinkPartInCartInfoToJSON(value?: RepairLinkPartInCartInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'service': value.service,
        'repairLinkOrder': value.repairLinkOrder,
        'repairLinkDealer': value.repairLinkDealer,
        'repairLinkVehicle': value.repairLinkVehicle,
        'data': RepairLinkPartInCartDataToJSON(value.data),
    };
}

