/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddFinixMerchantIdRequest,
  AddInventoryToServiceRequest,
  AddPartsToServiceRequest,
  ApiKeyEntry,
  ApiKeyFailedPaymentAttemptEntry,
  AppointmentEntry,
  AppointmentScheduleResponseRequest,
  AppointmentSettingsEntry,
  AppointmentTimeConstraintsEntry,
  ArchiveVehicleImageRequest,
  AssignTechToServiceRequest,
  AssignTechsRequest,
  AuthorizationEntry,
  AuthorizationInfo,
  AutomatedVehicleReminderEntry,
  AutomatedVehicleReminderInfo,
  Brand,
  BulkUpdateRepairOrderStatusRequest,
  CachePullResponseOK,
  CannedJobCustomFeeEntry,
  CannedJobCustomFeeInfo,
  CannedJobCustomLaborEntry,
  CannedJobCustomLaborInfo,
  CannedJobCustomPartEntry,
  CannedJobCustomPartInfo,
  CannedJobEntry,
  CannedJobExpanded,
  CannedJobInfo,
  CannedJobInventoryPartEntry,
  CannedJobInventoryPartInfo,
  CannedJobMotorFluidsEntry,
  CannedJobMotorFluidsInfo,
  CannedJobMotorLaborEntry,
  CannedJobMotorLaborInfo,
  CannedJobPartLookupEntry,
  CannedJobPartLookupInfo,
  CannedJobRelatedEntry,
  CannedJobRelatedInfo,
  CannedJobToInventoryProductEntry,
  CannedJobToInventoryProductInfo,
  CapitalOsAccount,
  CatalogLaborMatrixEntry,
  CatalogLaborMatrixInfo,
  ClaimInventoryRequest,
  CodatCustomerCreationRequest,
  CodatInvoiceCreationRequest,
  CodatRepairPaymentCreationRequest,
  CopyEntryRequest,
  CopyInspectionRequest,
  CopyPartsTechPartsToServiceRequest,
  CopySectionRequest,
  CounterSaleEntry,
  CounterSaleExpanded,
  CounterSaleInfo,
  CreateAppointmentRequest,
  CreateCannedJobService,
  CreateCustomerIntakeRequestObject,
  CreateInventoryInstancesWithAdjustmentRequest,
  CreateInventoryInstancesWithAdjustmentResponse,
  CreateLaborOverridesRequest,
  CreatePartsTechQuoteRequest,
  CreatePurchaseOrderRequest,
  CreateStandaloneApiKeyForCustomerRequest,
  CreateVerificationRequest,
  CreditCardReader,
  CustomFeeEntry,
  CustomFeeInfo,
  CustomerAdditionalEmailAddressEntry,
  CustomerAdditionalEmailAddressInfo,
  CustomerAdditionalPhoneEntry,
  CustomerAdditionalPhoneInfo,
  CustomerEntry,
  CustomerFeedbackEntry,
  CustomerFeedbackExpanded,
  CustomerFeedbackInfo,
  CustomerInformation,
  CustomerOrdersEntry,
  CustomerPages,
  CustomerPortalInfo,
  CustomerServiceStatusRequest,
  DiscountEntry,
  DiscountInfo,
  EmailInspectionRequest,
  EmailInvoiceRequest,
  EmbeddedDashboardRequest,
  EmployeeRole,
  EntryEntry,
  EntryExpanded,
  EntryInfo,
  ExpandedPingPerson,
  ExpenseAnalysisResponse,
  FluidApplication,
  FormInfo,
  GenerateApiKeyWithIntentRequest,
  GetClassesRequest,
  GetRepairOrdersCSVRequest,
  IdName,
  InspectionAnswerToCannedJobEntry,
  InspectionAnswerToCannedJobInfo,
  InspectionConversationMessageEntry,
  InspectionConversationMessageInfo,
  InspectionCustomerNoteRequest,
  InspectionEntry,
  InspectionEntryServiceEntry,
  InspectionEntryStatusEntry,
  InspectionEntryStatusInfo,
  InspectionExpanded,
  InspectionInfo,
  InspectionMediaEntry,
  InspectionSectionConversationMessageEntry,
  InspectionSectionConversationMessageInfo,
  IntakeRequestEntryExpanded,
  InventoryAdjustmentDocumentEntry,
  InventoryAdjustmentEntry,
  InventoryAdjustmentEntryExpanded,
  InventoryAdjustmentInfo,
  InventoryPartEntry,
  InventoryPartInfo,
  InventoryPartInstanceEntry,
  InventoryPartInstanceExpanded,
  InventoryPartInstanceInfo,
  Kpi,
  LaborApplication,
  LaborMatrixEntry,
  LaborMatrixInfo,
  LaborMatrixUsers,
  LaborOverride,
  LinkInventoryAdjustmentRequest,
  LoginResponse,
  MaintenanceSchedule,
  MaintenanceScheduleTimeline,
  MaintenanceScheduleWithRecommendation,
  MergeCustomersRequest,
  MergeVehiclesRequest,
  MessageTemplateEntry,
  MessageTemplateInfo,
  MotorTaxonomy,
  MultipleChoiceAnswerEntry,
  MultipleChoiceAnswerInfo,
  MyRole,
  NewCustomerRequest,
  NewVehicleRequest,
  NotificationEntry,
  NotificationRequest,
  OnboardFinixMerchantRequest,
  OneTimeReminderInfo,
  OrderCartResponse,
  PartInvoiceAnalysisResponse,
  PartOverride,
  PartStatusEntry,
  PartStatusInfo,
  PartsMatrixEntry,
  PartsMatrixInfo,
  PartsMatrixUsers,
  PartsTechCredentials,
  PartsTechPartTaxonomy,
  PaymentRequestCardNotPresent,
  PaymentRequestCardPresent,
  PingPerson,
  PingTeam,
  PriceLevelEntry,
  PriceLevelInfo,
  PrintActionEntry,
  PrintActionInfo,
  ProductivityStatusEntry,
  ProductivityStatusInfo,
  PromptEntry,
  PromptInfo,
  PublicCustomerCreationRequest,
  PublicCustomerEntry,
  PublicShopEntry,
  PullRequest,
  PunchoutInfo,
  PurchaseOrderEntryExpanded,
  PushRequest,
  PushSubscriptionInfo,
  PutTextRequest,
  RecommendMaintenanceScheduleRequest,
  ReorderRepairOrderStatusesRequest,
  RepairLinkOrderEntry,
  RepairLinkPartInCartExpandedChecked,
  RepairOrderAttachmentEntry,
  RepairOrderCodatLineItemsEntry,
  RepairOrderCustomerSummaryRequest,
  RepairOrderEntry,
  RepairOrderExpanded,
  RepairOrderInfo,
  RepairOrderMediaEntry,
  RepairOrderStatusEntry,
  RepairOrderWithRelated,
  RepairPaymentEntry,
  RepairPaymentInfo,
  ReplyCustomerRequest,
  ResourceEntry,
  ResourceInfo,
  RewriteRequest,
  Role,
  SectionEntry,
  SectionExpanded,
  SectionInfo,
  SemVer,
  SendFeedbackLinkRequest,
  SendVehicleReminderSmsRequest,
  ServiceEntry,
  ServiceExpanded,
  ServiceHistory,
  ServiceInfo,
  SetFinixIdentityIdRequest,
  ShopEntry,
  ShopFeatureEntry,
  ShopFeatureInfo,
  ShopInfoRequest,
  ShopInventory,
  SubletEntry,
  SubletInfo,
  TaxesAndFees,
  TaxesAndFeesEntry,
  TextToPayPaymentRequest,
  TimeClockEntry,
  TimeClockInfo,
  UpdateCatalogLaborMatrixRanksRequest,
  UpdateEmailRequest,
  UpdateInspectionEntryRanksRequest,
  UpdateInspectionEntryStatusRanksRequest,
  UpdateInspectionSectionRanksRequest,
  UpdateIntakeRequestObject,
  UpdateInventoryInstanceWithAdjustmentRequest,
  UpdateLaborMatrixRanksRequest,
  UpdateLaborMatrixUsersRequest,
  UpdateMileageRequest,
  UpdateMyPinRequest,
  UpdateOrderRepairLinkRequest,
  UpdatePartsMatrixRanksRequest,
  UpdatePartsMatrixUsersRequest,
  UpdatePartsTechQuoteRequest,
  UpdatePaymentTypes,
  UpdatePriceLevelRanksRequest,
  UpdatePurchaseOrderRequest,
  UpdateQuantityRequest,
  UpdateRolePermissionsRequest,
  UserProfile,
  VehicleEntry,
  VehicleFluidsEntry,
  VehicleFluidsInfo,
  VehicleImageEntry,
  VehicleInformation,
  VehicleOilFilterEntry,
  VehicleOilFilterInfo,
  VehicleReminder,
  VehicleReminderMessageEntry,
  VehicleReminderMessageInfo,
  VehicleWithCustomers,
  VendorEntry,
  VendorInfo,
  VerificationEntry,
  WarrantyEntry,
  WarrantyInfo,
} from '../models';
import {
    AddFinixMerchantIdRequestFromJSON,
    AddFinixMerchantIdRequestToJSON,
    AddInventoryToServiceRequestFromJSON,
    AddInventoryToServiceRequestToJSON,
    AddPartsToServiceRequestFromJSON,
    AddPartsToServiceRequestToJSON,
    ApiKeyEntryFromJSON,
    ApiKeyEntryToJSON,
    ApiKeyFailedPaymentAttemptEntryFromJSON,
    ApiKeyFailedPaymentAttemptEntryToJSON,
    AppointmentEntryFromJSON,
    AppointmentEntryToJSON,
    AppointmentScheduleResponseRequestFromJSON,
    AppointmentScheduleResponseRequestToJSON,
    AppointmentSettingsEntryFromJSON,
    AppointmentSettingsEntryToJSON,
    AppointmentTimeConstraintsEntryFromJSON,
    AppointmentTimeConstraintsEntryToJSON,
    ArchiveVehicleImageRequestFromJSON,
    ArchiveVehicleImageRequestToJSON,
    AssignTechToServiceRequestFromJSON,
    AssignTechToServiceRequestToJSON,
    AssignTechsRequestFromJSON,
    AssignTechsRequestToJSON,
    AuthorizationEntryFromJSON,
    AuthorizationEntryToJSON,
    AuthorizationInfoFromJSON,
    AuthorizationInfoToJSON,
    AutomatedVehicleReminderEntryFromJSON,
    AutomatedVehicleReminderEntryToJSON,
    AutomatedVehicleReminderInfoFromJSON,
    AutomatedVehicleReminderInfoToJSON,
    BrandFromJSON,
    BrandToJSON,
    BulkUpdateRepairOrderStatusRequestFromJSON,
    BulkUpdateRepairOrderStatusRequestToJSON,
    CachePullResponseOKFromJSON,
    CachePullResponseOKToJSON,
    CannedJobCustomFeeEntryFromJSON,
    CannedJobCustomFeeEntryToJSON,
    CannedJobCustomFeeInfoFromJSON,
    CannedJobCustomFeeInfoToJSON,
    CannedJobCustomLaborEntryFromJSON,
    CannedJobCustomLaborEntryToJSON,
    CannedJobCustomLaborInfoFromJSON,
    CannedJobCustomLaborInfoToJSON,
    CannedJobCustomPartEntryFromJSON,
    CannedJobCustomPartEntryToJSON,
    CannedJobCustomPartInfoFromJSON,
    CannedJobCustomPartInfoToJSON,
    CannedJobEntryFromJSON,
    CannedJobEntryToJSON,
    CannedJobExpandedFromJSON,
    CannedJobExpandedToJSON,
    CannedJobInfoFromJSON,
    CannedJobInfoToJSON,
    CannedJobInventoryPartEntryFromJSON,
    CannedJobInventoryPartEntryToJSON,
    CannedJobInventoryPartInfoFromJSON,
    CannedJobInventoryPartInfoToJSON,
    CannedJobMotorFluidsEntryFromJSON,
    CannedJobMotorFluidsEntryToJSON,
    CannedJobMotorFluidsInfoFromJSON,
    CannedJobMotorFluidsInfoToJSON,
    CannedJobMotorLaborEntryFromJSON,
    CannedJobMotorLaborEntryToJSON,
    CannedJobMotorLaborInfoFromJSON,
    CannedJobMotorLaborInfoToJSON,
    CannedJobPartLookupEntryFromJSON,
    CannedJobPartLookupEntryToJSON,
    CannedJobPartLookupInfoFromJSON,
    CannedJobPartLookupInfoToJSON,
    CannedJobRelatedEntryFromJSON,
    CannedJobRelatedEntryToJSON,
    CannedJobRelatedInfoFromJSON,
    CannedJobRelatedInfoToJSON,
    CannedJobToInventoryProductEntryFromJSON,
    CannedJobToInventoryProductEntryToJSON,
    CannedJobToInventoryProductInfoFromJSON,
    CannedJobToInventoryProductInfoToJSON,
    CapitalOsAccountFromJSON,
    CapitalOsAccountToJSON,
    CatalogLaborMatrixEntryFromJSON,
    CatalogLaborMatrixEntryToJSON,
    CatalogLaborMatrixInfoFromJSON,
    CatalogLaborMatrixInfoToJSON,
    ClaimInventoryRequestFromJSON,
    ClaimInventoryRequestToJSON,
    CodatCustomerCreationRequestFromJSON,
    CodatCustomerCreationRequestToJSON,
    CodatInvoiceCreationRequestFromJSON,
    CodatInvoiceCreationRequestToJSON,
    CodatRepairPaymentCreationRequestFromJSON,
    CodatRepairPaymentCreationRequestToJSON,
    CopyEntryRequestFromJSON,
    CopyEntryRequestToJSON,
    CopyInspectionRequestFromJSON,
    CopyInspectionRequestToJSON,
    CopyPartsTechPartsToServiceRequestFromJSON,
    CopyPartsTechPartsToServiceRequestToJSON,
    CopySectionRequestFromJSON,
    CopySectionRequestToJSON,
    CounterSaleEntryFromJSON,
    CounterSaleEntryToJSON,
    CounterSaleExpandedFromJSON,
    CounterSaleExpandedToJSON,
    CounterSaleInfoFromJSON,
    CounterSaleInfoToJSON,
    CreateAppointmentRequestFromJSON,
    CreateAppointmentRequestToJSON,
    CreateCannedJobServiceFromJSON,
    CreateCannedJobServiceToJSON,
    CreateCustomerIntakeRequestObjectFromJSON,
    CreateCustomerIntakeRequestObjectToJSON,
    CreateInventoryInstancesWithAdjustmentRequestFromJSON,
    CreateInventoryInstancesWithAdjustmentRequestToJSON,
    CreateInventoryInstancesWithAdjustmentResponseFromJSON,
    CreateInventoryInstancesWithAdjustmentResponseToJSON,
    CreateLaborOverridesRequestFromJSON,
    CreateLaborOverridesRequestToJSON,
    CreatePartsTechQuoteRequestFromJSON,
    CreatePartsTechQuoteRequestToJSON,
    CreatePurchaseOrderRequestFromJSON,
    CreatePurchaseOrderRequestToJSON,
    CreateStandaloneApiKeyForCustomerRequestFromJSON,
    CreateStandaloneApiKeyForCustomerRequestToJSON,
    CreateVerificationRequestFromJSON,
    CreateVerificationRequestToJSON,
    CreditCardReaderFromJSON,
    CreditCardReaderToJSON,
    CustomFeeEntryFromJSON,
    CustomFeeEntryToJSON,
    CustomFeeInfoFromJSON,
    CustomFeeInfoToJSON,
    CustomerAdditionalEmailAddressEntryFromJSON,
    CustomerAdditionalEmailAddressEntryToJSON,
    CustomerAdditionalEmailAddressInfoFromJSON,
    CustomerAdditionalEmailAddressInfoToJSON,
    CustomerAdditionalPhoneEntryFromJSON,
    CustomerAdditionalPhoneEntryToJSON,
    CustomerAdditionalPhoneInfoFromJSON,
    CustomerAdditionalPhoneInfoToJSON,
    CustomerEntryFromJSON,
    CustomerEntryToJSON,
    CustomerFeedbackEntryFromJSON,
    CustomerFeedbackEntryToJSON,
    CustomerFeedbackExpandedFromJSON,
    CustomerFeedbackExpandedToJSON,
    CustomerFeedbackInfoFromJSON,
    CustomerFeedbackInfoToJSON,
    CustomerInformationFromJSON,
    CustomerInformationToJSON,
    CustomerOrdersEntryFromJSON,
    CustomerOrdersEntryToJSON,
    CustomerPagesFromJSON,
    CustomerPagesToJSON,
    CustomerPortalInfoFromJSON,
    CustomerPortalInfoToJSON,
    CustomerServiceStatusRequestFromJSON,
    CustomerServiceStatusRequestToJSON,
    DiscountEntryFromJSON,
    DiscountEntryToJSON,
    DiscountInfoFromJSON,
    DiscountInfoToJSON,
    EmailInspectionRequestFromJSON,
    EmailInspectionRequestToJSON,
    EmailInvoiceRequestFromJSON,
    EmailInvoiceRequestToJSON,
    EmbeddedDashboardRequestFromJSON,
    EmbeddedDashboardRequestToJSON,
    EmployeeRoleFromJSON,
    EmployeeRoleToJSON,
    EntryEntryFromJSON,
    EntryEntryToJSON,
    EntryExpandedFromJSON,
    EntryExpandedToJSON,
    EntryInfoFromJSON,
    EntryInfoToJSON,
    ExpandedPingPersonFromJSON,
    ExpandedPingPersonToJSON,
    ExpenseAnalysisResponseFromJSON,
    ExpenseAnalysisResponseToJSON,
    FluidApplicationFromJSON,
    FluidApplicationToJSON,
    FormInfoFromJSON,
    FormInfoToJSON,
    GenerateApiKeyWithIntentRequestFromJSON,
    GenerateApiKeyWithIntentRequestToJSON,
    GetClassesRequestFromJSON,
    GetClassesRequestToJSON,
    GetRepairOrdersCSVRequestFromJSON,
    GetRepairOrdersCSVRequestToJSON,
    IdNameFromJSON,
    IdNameToJSON,
    InspectionAnswerToCannedJobEntryFromJSON,
    InspectionAnswerToCannedJobEntryToJSON,
    InspectionAnswerToCannedJobInfoFromJSON,
    InspectionAnswerToCannedJobInfoToJSON,
    InspectionConversationMessageEntryFromJSON,
    InspectionConversationMessageEntryToJSON,
    InspectionConversationMessageInfoFromJSON,
    InspectionConversationMessageInfoToJSON,
    InspectionCustomerNoteRequestFromJSON,
    InspectionCustomerNoteRequestToJSON,
    InspectionEntryFromJSON,
    InspectionEntryToJSON,
    InspectionEntryServiceEntryFromJSON,
    InspectionEntryServiceEntryToJSON,
    InspectionEntryStatusEntryFromJSON,
    InspectionEntryStatusEntryToJSON,
    InspectionEntryStatusInfoFromJSON,
    InspectionEntryStatusInfoToJSON,
    InspectionExpandedFromJSON,
    InspectionExpandedToJSON,
    InspectionInfoFromJSON,
    InspectionInfoToJSON,
    InspectionMediaEntryFromJSON,
    InspectionMediaEntryToJSON,
    InspectionSectionConversationMessageEntryFromJSON,
    InspectionSectionConversationMessageEntryToJSON,
    InspectionSectionConversationMessageInfoFromJSON,
    InspectionSectionConversationMessageInfoToJSON,
    IntakeRequestEntryExpandedFromJSON,
    IntakeRequestEntryExpandedToJSON,
    InventoryAdjustmentDocumentEntryFromJSON,
    InventoryAdjustmentDocumentEntryToJSON,
    InventoryAdjustmentEntryFromJSON,
    InventoryAdjustmentEntryToJSON,
    InventoryAdjustmentEntryExpandedFromJSON,
    InventoryAdjustmentEntryExpandedToJSON,
    InventoryAdjustmentInfoFromJSON,
    InventoryAdjustmentInfoToJSON,
    InventoryPartEntryFromJSON,
    InventoryPartEntryToJSON,
    InventoryPartInfoFromJSON,
    InventoryPartInfoToJSON,
    InventoryPartInstanceEntryFromJSON,
    InventoryPartInstanceEntryToJSON,
    InventoryPartInstanceExpandedFromJSON,
    InventoryPartInstanceExpandedToJSON,
    InventoryPartInstanceInfoFromJSON,
    InventoryPartInstanceInfoToJSON,
    KpiFromJSON,
    KpiToJSON,
    LaborApplicationFromJSON,
    LaborApplicationToJSON,
    LaborMatrixEntryFromJSON,
    LaborMatrixEntryToJSON,
    LaborMatrixInfoFromJSON,
    LaborMatrixInfoToJSON,
    LaborMatrixUsersFromJSON,
    LaborMatrixUsersToJSON,
    LaborOverrideFromJSON,
    LaborOverrideToJSON,
    LinkInventoryAdjustmentRequestFromJSON,
    LinkInventoryAdjustmentRequestToJSON,
    LoginResponseFromJSON,
    LoginResponseToJSON,
    MaintenanceScheduleFromJSON,
    MaintenanceScheduleToJSON,
    MaintenanceScheduleTimelineFromJSON,
    MaintenanceScheduleTimelineToJSON,
    MaintenanceScheduleWithRecommendationFromJSON,
    MaintenanceScheduleWithRecommendationToJSON,
    MergeCustomersRequestFromJSON,
    MergeCustomersRequestToJSON,
    MergeVehiclesRequestFromJSON,
    MergeVehiclesRequestToJSON,
    MessageTemplateEntryFromJSON,
    MessageTemplateEntryToJSON,
    MessageTemplateInfoFromJSON,
    MessageTemplateInfoToJSON,
    MotorTaxonomyFromJSON,
    MotorTaxonomyToJSON,
    MultipleChoiceAnswerEntryFromJSON,
    MultipleChoiceAnswerEntryToJSON,
    MultipleChoiceAnswerInfoFromJSON,
    MultipleChoiceAnswerInfoToJSON,
    MyRoleFromJSON,
    MyRoleToJSON,
    NewCustomerRequestFromJSON,
    NewCustomerRequestToJSON,
    NewVehicleRequestFromJSON,
    NewVehicleRequestToJSON,
    NotificationEntryFromJSON,
    NotificationEntryToJSON,
    NotificationRequestFromJSON,
    NotificationRequestToJSON,
    OnboardFinixMerchantRequestFromJSON,
    OnboardFinixMerchantRequestToJSON,
    OneTimeReminderInfoFromJSON,
    OneTimeReminderInfoToJSON,
    OrderCartResponseFromJSON,
    OrderCartResponseToJSON,
    PartInvoiceAnalysisResponseFromJSON,
    PartInvoiceAnalysisResponseToJSON,
    PartOverrideFromJSON,
    PartOverrideToJSON,
    PartStatusEntryFromJSON,
    PartStatusEntryToJSON,
    PartStatusInfoFromJSON,
    PartStatusInfoToJSON,
    PartsMatrixEntryFromJSON,
    PartsMatrixEntryToJSON,
    PartsMatrixInfoFromJSON,
    PartsMatrixInfoToJSON,
    PartsMatrixUsersFromJSON,
    PartsMatrixUsersToJSON,
    PartsTechCredentialsFromJSON,
    PartsTechCredentialsToJSON,
    PartsTechPartTaxonomyFromJSON,
    PartsTechPartTaxonomyToJSON,
    PaymentRequestCardNotPresentFromJSON,
    PaymentRequestCardNotPresentToJSON,
    PaymentRequestCardPresentFromJSON,
    PaymentRequestCardPresentToJSON,
    PingPersonFromJSON,
    PingPersonToJSON,
    PingTeamFromJSON,
    PingTeamToJSON,
    PriceLevelEntryFromJSON,
    PriceLevelEntryToJSON,
    PriceLevelInfoFromJSON,
    PriceLevelInfoToJSON,
    PrintActionEntryFromJSON,
    PrintActionEntryToJSON,
    PrintActionInfoFromJSON,
    PrintActionInfoToJSON,
    ProductivityStatusEntryFromJSON,
    ProductivityStatusEntryToJSON,
    ProductivityStatusInfoFromJSON,
    ProductivityStatusInfoToJSON,
    PromptEntryFromJSON,
    PromptEntryToJSON,
    PromptInfoFromJSON,
    PromptInfoToJSON,
    PublicCustomerCreationRequestFromJSON,
    PublicCustomerCreationRequestToJSON,
    PublicCustomerEntryFromJSON,
    PublicCustomerEntryToJSON,
    PublicShopEntryFromJSON,
    PublicShopEntryToJSON,
    PullRequestFromJSON,
    PullRequestToJSON,
    PunchoutInfoFromJSON,
    PunchoutInfoToJSON,
    PurchaseOrderEntryExpandedFromJSON,
    PurchaseOrderEntryExpandedToJSON,
    PushRequestFromJSON,
    PushRequestToJSON,
    PushSubscriptionInfoFromJSON,
    PushSubscriptionInfoToJSON,
    PutTextRequestFromJSON,
    PutTextRequestToJSON,
    RecommendMaintenanceScheduleRequestFromJSON,
    RecommendMaintenanceScheduleRequestToJSON,
    ReorderRepairOrderStatusesRequestFromJSON,
    ReorderRepairOrderStatusesRequestToJSON,
    RepairLinkOrderEntryFromJSON,
    RepairLinkOrderEntryToJSON,
    RepairLinkPartInCartExpandedCheckedFromJSON,
    RepairLinkPartInCartExpandedCheckedToJSON,
    RepairOrderAttachmentEntryFromJSON,
    RepairOrderAttachmentEntryToJSON,
    RepairOrderCodatLineItemsEntryFromJSON,
    RepairOrderCodatLineItemsEntryToJSON,
    RepairOrderCustomerSummaryRequestFromJSON,
    RepairOrderCustomerSummaryRequestToJSON,
    RepairOrderEntryFromJSON,
    RepairOrderEntryToJSON,
    RepairOrderExpandedFromJSON,
    RepairOrderExpandedToJSON,
    RepairOrderInfoFromJSON,
    RepairOrderInfoToJSON,
    RepairOrderMediaEntryFromJSON,
    RepairOrderMediaEntryToJSON,
    RepairOrderStatusEntryFromJSON,
    RepairOrderStatusEntryToJSON,
    RepairOrderWithRelatedFromJSON,
    RepairOrderWithRelatedToJSON,
    RepairPaymentEntryFromJSON,
    RepairPaymentEntryToJSON,
    RepairPaymentInfoFromJSON,
    RepairPaymentInfoToJSON,
    ReplyCustomerRequestFromJSON,
    ReplyCustomerRequestToJSON,
    ResourceEntryFromJSON,
    ResourceEntryToJSON,
    ResourceInfoFromJSON,
    ResourceInfoToJSON,
    RewriteRequestFromJSON,
    RewriteRequestToJSON,
    RoleFromJSON,
    RoleToJSON,
    SectionEntryFromJSON,
    SectionEntryToJSON,
    SectionExpandedFromJSON,
    SectionExpandedToJSON,
    SectionInfoFromJSON,
    SectionInfoToJSON,
    SemVerFromJSON,
    SemVerToJSON,
    SendFeedbackLinkRequestFromJSON,
    SendFeedbackLinkRequestToJSON,
    SendVehicleReminderSmsRequestFromJSON,
    SendVehicleReminderSmsRequestToJSON,
    ServiceEntryFromJSON,
    ServiceEntryToJSON,
    ServiceExpandedFromJSON,
    ServiceExpandedToJSON,
    ServiceHistoryFromJSON,
    ServiceHistoryToJSON,
    ServiceInfoFromJSON,
    ServiceInfoToJSON,
    SetFinixIdentityIdRequestFromJSON,
    SetFinixIdentityIdRequestToJSON,
    ShopEntryFromJSON,
    ShopEntryToJSON,
    ShopFeatureEntryFromJSON,
    ShopFeatureEntryToJSON,
    ShopFeatureInfoFromJSON,
    ShopFeatureInfoToJSON,
    ShopInfoRequestFromJSON,
    ShopInfoRequestToJSON,
    ShopInventoryFromJSON,
    ShopInventoryToJSON,
    SubletEntryFromJSON,
    SubletEntryToJSON,
    SubletInfoFromJSON,
    SubletInfoToJSON,
    TaxesAndFeesFromJSON,
    TaxesAndFeesToJSON,
    TaxesAndFeesEntryFromJSON,
    TaxesAndFeesEntryToJSON,
    TextToPayPaymentRequestFromJSON,
    TextToPayPaymentRequestToJSON,
    TimeClockEntryFromJSON,
    TimeClockEntryToJSON,
    TimeClockInfoFromJSON,
    TimeClockInfoToJSON,
    UpdateCatalogLaborMatrixRanksRequestFromJSON,
    UpdateCatalogLaborMatrixRanksRequestToJSON,
    UpdateEmailRequestFromJSON,
    UpdateEmailRequestToJSON,
    UpdateInspectionEntryRanksRequestFromJSON,
    UpdateInspectionEntryRanksRequestToJSON,
    UpdateInspectionEntryStatusRanksRequestFromJSON,
    UpdateInspectionEntryStatusRanksRequestToJSON,
    UpdateInspectionSectionRanksRequestFromJSON,
    UpdateInspectionSectionRanksRequestToJSON,
    UpdateIntakeRequestObjectFromJSON,
    UpdateIntakeRequestObjectToJSON,
    UpdateInventoryInstanceWithAdjustmentRequestFromJSON,
    UpdateInventoryInstanceWithAdjustmentRequestToJSON,
    UpdateLaborMatrixRanksRequestFromJSON,
    UpdateLaborMatrixRanksRequestToJSON,
    UpdateLaborMatrixUsersRequestFromJSON,
    UpdateLaborMatrixUsersRequestToJSON,
    UpdateMileageRequestFromJSON,
    UpdateMileageRequestToJSON,
    UpdateMyPinRequestFromJSON,
    UpdateMyPinRequestToJSON,
    UpdateOrderRepairLinkRequestFromJSON,
    UpdateOrderRepairLinkRequestToJSON,
    UpdatePartsMatrixRanksRequestFromJSON,
    UpdatePartsMatrixRanksRequestToJSON,
    UpdatePartsMatrixUsersRequestFromJSON,
    UpdatePartsMatrixUsersRequestToJSON,
    UpdatePartsTechQuoteRequestFromJSON,
    UpdatePartsTechQuoteRequestToJSON,
    UpdatePaymentTypesFromJSON,
    UpdatePaymentTypesToJSON,
    UpdatePriceLevelRanksRequestFromJSON,
    UpdatePriceLevelRanksRequestToJSON,
    UpdatePurchaseOrderRequestFromJSON,
    UpdatePurchaseOrderRequestToJSON,
    UpdateQuantityRequestFromJSON,
    UpdateQuantityRequestToJSON,
    UpdateRolePermissionsRequestFromJSON,
    UpdateRolePermissionsRequestToJSON,
    UserProfileFromJSON,
    UserProfileToJSON,
    VehicleEntryFromJSON,
    VehicleEntryToJSON,
    VehicleFluidsEntryFromJSON,
    VehicleFluidsEntryToJSON,
    VehicleFluidsInfoFromJSON,
    VehicleFluidsInfoToJSON,
    VehicleImageEntryFromJSON,
    VehicleImageEntryToJSON,
    VehicleInformationFromJSON,
    VehicleInformationToJSON,
    VehicleOilFilterEntryFromJSON,
    VehicleOilFilterEntryToJSON,
    VehicleOilFilterInfoFromJSON,
    VehicleOilFilterInfoToJSON,
    VehicleReminderFromJSON,
    VehicleReminderToJSON,
    VehicleReminderMessageEntryFromJSON,
    VehicleReminderMessageEntryToJSON,
    VehicleReminderMessageInfoFromJSON,
    VehicleReminderMessageInfoToJSON,
    VehicleWithCustomersFromJSON,
    VehicleWithCustomersToJSON,
    VendorEntryFromJSON,
    VendorEntryToJSON,
    VendorInfoFromJSON,
    VendorInfoToJSON,
    VerificationEntryFromJSON,
    VerificationEntryToJSON,
    WarrantyEntryFromJSON,
    WarrantyEntryToJSON,
    WarrantyInfoFromJSON,
    WarrantyInfoToJSON,
} from '../models';

export interface AddInventoryToServiceOperationRequest {
    authorization: string;
    addInventoryToServiceRequest?: AddInventoryToServiceRequest;
}

export interface AddPartsToServiceOperationRequest {
    authorization: string;
    addPartsToServiceRequest?: AddPartsToServiceRequest;
}

export interface ArchiveAppointmentRequest {
    authorization: string;
    shop: string;
    appointment: string;
}

export interface ArchiveAutomatedVehicleReminderRequest {
    authorization: string;
    shop: string;
    id: string;
}

export interface ArchiveCounterSaleRequest {
    authorization: string;
    shop: string;
    id: string;
    archiveReason: string;
}

export interface ArchiveIntakeRequestRequest {
    authorization: string;
    requestId: string;
}

export interface ArchivePriceLevelRequest {
    authorization: string;
    shop: string;
    id: string;
}

export interface ArchivePurchaseOrderRequest {
    authorization: string;
    shop: string;
    id: string;
    isDeletion: boolean;
}

export interface ArchiveRepairOrderAttachmentRequest {
    authorization: string;
    attachmentId: string;
}

export interface ArchiveResourceRequest {
    authorization: string;
    shop: string;
    resource: string;
}

export interface ArchiveServiceRequest {
    authorization: string;
    shop: string;
    service: string;
}

export interface ArchiveShopVendorRequest {
    authorization: string;
    shop: string;
    vendorId: string;
}

export interface ArchiveVehicleImageOperationRequest {
    authorization: string;
    archiveVehicleImageRequest?: ArchiveVehicleImageRequest;
}

export interface AssignTechsToLaborOverrideRequest {
    authorization: string;
    assignTechsRequest?: AssignTechsRequest;
}

export interface AssignTechsToServiceRequest {
    authorization: string;
    assignTechToServiceRequest?: AssignTechToServiceRequest;
}

export interface AuthorizeShopForCarfaxRequest {
    authorization: string;
    body?: string;
}

export interface AuthorizeShopForQuickVinRequest {
    authorization: string;
    body?: string;
}

export interface BrowseMaintenanceSchedulesForVehicleRequest {
    authorization: string;
    shop: string;
    vehicle: string;
}

export interface BrowseServicesFromTaxonomyRequest {
    authorization: string;
    shop: string;
    vehicle: string;
    system?: number;
    group?: number;
    subgroup?: number;
    taxonomy?: number;
}

export interface BugReportRequest {
    subject: string;
    body: string;
    userAgent?: string;
    authorization?: string;
    severity?: string;
}

export interface BulkUpdateRepairOrderStatusOperationRequest {
    authorization: string;
    bulkUpdateRepairOrderStatusRequest?: BulkUpdateRepairOrderStatusRequest;
}

export interface CheckFeaturesForShopRequest {
    authorization: string;
    shop: string;
    features: Array<string>;
}

export interface CheckFeaturesForShopByApiKeyRequest {
    xAPIKey: string;
    features: Array<string>;
}

export interface CheckRepairLinkPricesRequest {
    authorization: string;
    service: string;
    accessToken: string;
}

export interface CheckShopOnboardedRequest {
    authorization: string;
    shop: string;
}

export interface CheckVerificationCodeRequest {
    shopId: string;
    customerId: string;
    verificationCode: string;
}

export interface CheckVersionWebappRequest {
    appVersion: number;
}

export interface ClaimInventoryOperationRequest {
    authorization: string;
    claimInventoryRequest?: ClaimInventoryRequest;
}

export interface ClearCartRequest {
    authorization: string;
    service: string;
}

export interface CodatCustomerCreationOperationRequest {
    codatCustomerCreationRequest?: CodatCustomerCreationRequest;
}

export interface CodatInvoiceCreationOperationRequest {
    codatInvoiceCreationRequest?: CodatInvoiceCreationRequest;
}

export interface CodatLambdaFunctionTriggerWithShopRequest {
    shopId: string;
}

export interface CodatRepairPaymentCreationOperationRequest {
    codatRepairPaymentCreationRequest?: CodatRepairPaymentCreationRequest;
}

export interface CollectPaymentCardNotPresentRequest {
    authorization: string;
    paymentRequestCardNotPresent?: PaymentRequestCardNotPresent;
}

export interface CollectPaymentCardPresentRequest {
    authorization: string;
    paymentRequestCardPresent?: PaymentRequestCardPresent;
}

export interface ConvertQuotedPartRequest {
    authorization: string;
    partOverride?: PartOverride;
}

export interface CopyCannedJobRequest {
    authorization: string;
    shop: string;
    cannedJob: string;
}

export interface CopyEntryOperationRequest {
    authorization: string;
    copyEntryRequest?: CopyEntryRequest;
}

export interface CopyPartsTechPartsToServiceOperationRequest {
    authorization: string;
    copyPartsTechPartsToServiceRequest?: CopyPartsTechPartsToServiceRequest;
}

export interface CopySectionOperationRequest {
    authorization: string;
    copySectionRequest?: CopySectionRequest;
}

export interface CopyToNewInspectionRequest {
    authorization: string;
    copyInspectionRequest?: CopyInspectionRequest;
}

export interface CreateAccountRequest {
    firebaseIdToken: string;
    realName: string;
    profilePictureUrl?: string;
    userId?: string;
}

export interface CreateAdditionalEmailAddressRequest {
    authorization: string;
    customerAdditionalEmailAddressInfo?: CustomerAdditionalEmailAddressInfo;
}

export interface CreateAdditionalPhoneRequest {
    authorization: string;
    customerAdditionalPhoneInfo?: CustomerAdditionalPhoneInfo;
}

export interface CreateAnswerToCannedJobRequest {
    authorization: string;
    inspectionAnswerToCannedJobInfo?: InspectionAnswerToCannedJobInfo;
}

export interface CreateAppointmentOperationRequest {
    authorization: string;
    createAppointmentRequest?: CreateAppointmentRequest;
}

export interface CreateAppointmentWithVerificationIdRequest {
    xAPIKey: string;
    createAppointmentRequest?: CreateAppointmentRequest;
}

export interface CreateAuthorizationRequest {
    authorization: string;
    authorizationInfo?: AuthorizationInfo;
}

export interface CreateAuthorizationByApiKeyRequest {
    xAPIKey: string;
    authorizationInfo?: AuthorizationInfo;
}

export interface CreateAutomatedVehicleReminderRequest {
    authorization: string;
    automatedVehicleReminderInfo?: AutomatedVehicleReminderInfo;
}

export interface CreateCannedJobRequest {
    authorization: string;
    cannedJobInfo?: CannedJobInfo;
}

export interface CreateCannedJobCustomFeeRequest {
    authorization: string;
    cannedJobCustomFeeInfo?: CannedJobCustomFeeInfo;
}

export interface CreateCannedJobCustomLaborRequest {
    authorization: string;
    cannedJobCustomLaborInfo?: CannedJobCustomLaborInfo;
}

export interface CreateCannedJobCustomPartRequest {
    authorization: string;
    cannedJobCustomPartInfo?: CannedJobCustomPartInfo;
}

export interface CreateCannedJobMotorFluidsRequest {
    authorization: string;
    cannedJobMotorFluidsInfo?: CannedJobMotorFluidsInfo;
}

export interface CreateCannedJobMotorLaborRequest {
    authorization: string;
    cannedJobMotorLaborInfo?: CannedJobMotorLaborInfo;
}

export interface CreateCannedJobPartLookupRequest {
    authorization: string;
    cannedJobPartLookupInfo?: CannedJobPartLookupInfo;
}

export interface CreateCannedJobRelatedRequest {
    authorization: string;
    cannedJobRelatedInfo?: CannedJobRelatedInfo;
}

export interface CreateCannedJobToInventoryPartRequest {
    authorization: string;
    cannedJobInventoryPartInfo?: CannedJobInventoryPartInfo;
}

export interface CreateCannedJobToInventoryProductRequest {
    authorization: string;
    cannedJobToInventoryProductInfo?: CannedJobToInventoryProductInfo;
}

export interface CreateCatalogLaborMatrixEntryRequest {
    authorization: string;
    catalogLaborMatrixInfo?: CatalogLaborMatrixInfo;
}

export interface CreateCounterSaleRequest {
    authorization: string;
    counterSaleInfo?: CounterSaleInfo;
}

export interface CreateCustomFeeRequest {
    authorization: string;
    customFeeInfo?: CustomFeeInfo;
}

export interface CreateCustomMessageTemplateRequest {
    authorization: string;
    messageTemplateInfo?: MessageTemplateInfo;
}

export interface CreateCustomerRequest {
    authorization: string;
    newCustomerRequest?: NewCustomerRequest;
}

export interface CreateDiscountRequest {
    authorization: string;
    discountInfo?: DiscountInfo;
}

export interface CreateEmbeddedDashboardLinkRequest {
    authorization: string;
    embeddedDashboardRequest?: EmbeddedDashboardRequest;
}

export interface CreateFinixBuyerRequest {
    authorization: string;
    customerInformation?: CustomerInformation;
}

export interface CreateInspectionEntryRequest {
    authorization: string;
    entryInfo?: EntryInfo;
}

export interface CreateInspectionEntryMessageRequest {
    authorization: string;
    inspectionConversationMessageInfo?: InspectionConversationMessageInfo;
}

export interface CreateInspectionEntryServiceAssociationRequest {
    authorization: string;
    inspectionEntryServiceEntry?: InspectionEntryServiceEntry;
}

export interface CreateInspectionEntryStatusRequest {
    authorization: string;
    inspectionEntryStatusInfo?: InspectionEntryStatusInfo;
}

export interface CreateInspectionPromptRequest {
    authorization: string;
    promptInfo?: PromptInfo;
}

export interface CreateInspectionSectionRequest {
    authorization: string;
    sectionInfo?: SectionInfo;
}

export interface CreateInspectionSectionMessageRequest {
    authorization: string;
    inspectionSectionConversationMessageInfo?: InspectionSectionConversationMessageInfo;
}

export interface CreateInspectionTemplateRequest {
    authorization: string;
    inspectionInfo?: InspectionInfo;
}

export interface CreateIntakeRequestRequest {
    createCustomerIntakeRequestObject?: CreateCustomerIntakeRequestObject;
}

export interface CreateIntakeRequestWithVerificationIdRequest {
    xAPIKey: string;
    createCustomerIntakeRequestObject?: CreateCustomerIntakeRequestObject;
}

export interface CreateInventoryAdjustmentRequest {
    authorization: string;
    inventoryAdjustmentInfo?: InventoryAdjustmentInfo;
}

export interface CreateInventoryAdjustmentDocumentRequest {
    authorization: string;
    inventoryAdjustment: string;
    rank: number;
    description: string;
    file?: Blob;
}

export interface CreateInventoryInstanceRequest {
    authorization: string;
    inventoryPartInstanceInfo?: InventoryPartInstanceInfo;
}

export interface CreateInventoryInstancesWithAdjustmentOperationRequest {
    authorization: string;
    createInventoryInstancesWithAdjustmentRequest?: CreateInventoryInstancesWithAdjustmentRequest;
}

export interface CreateInventoryPartRequest {
    authorization: string;
    inventoryPartInfo?: InventoryPartInfo;
}

export interface CreateLaborMatrixEntryRequest {
    authorization: string;
    laborMatrixInfo?: LaborMatrixInfo;
}

export interface CreateLaborOverridesOperationRequest {
    authorization: string;
    createLaborOverridesRequest?: CreateLaborOverridesRequest;
}

export interface CreateMediaEntryForInspectionEntryRequest {
    authorization: string;
    id: string;
    rank: number;
    entry?: string;
    prompt?: string;
    answer?: string;
    title?: string;
    description?: string;
    file?: Blob;
}

export interface CreateMediaForInspectionEntryRequest {
    authorization: string;
    rank: number;
    entry?: string;
    prompt?: string;
    answer?: string;
    title?: string;
    description?: string;
    file?: Blob;
}

export interface CreateMultipleChoiceAnswerRequest {
    authorization: string;
    multipleChoiceAnswerInfo?: MultipleChoiceAnswerInfo;
}

export interface CreateOneTimeReminderRequest {
    authorization: string;
    oneTimeReminderInfo?: OneTimeReminderInfo;
}

export interface CreatePartOverrideRequest {
    authorization: string;
    partOverride?: PartOverride;
}

export interface CreatePartStatusRequest {
    authorization: string;
    partStatusInfo?: PartStatusInfo;
}

export interface CreatePartsMatrixEntryRequest {
    authorization: string;
    partsMatrixInfo?: PartsMatrixInfo;
}

export interface CreatePartsTechQuoteOperationRequest {
    authorization: string;
    createPartsTechQuoteRequest?: CreatePartsTechQuoteRequest;
}

export interface CreatePaymentRequest {
    authorization: string;
    repairPaymentInfo?: RepairPaymentInfo;
}

export interface CreatePriceLevelRequest {
    authorization: string;
    priceLevelInfo?: PriceLevelInfo;
}

export interface CreatePrintActionHistoryRecordRequest {
    authorization: string;
    printActionInfo?: PrintActionInfo;
}

export interface CreateProductivityStatusRequest {
    authorization: string;
    productivityStatusInfo?: ProductivityStatusInfo;
}

export interface CreatePurchaseOrderOperationRequest {
    authorization: string;
    createPurchaseOrderRequest?: CreatePurchaseOrderRequest;
}

export interface CreatePushNotificationRequest {
    authorization: string;
    notificationRequest?: NotificationRequest;
}

export interface CreatePushSubscriptionRequest {
    authorization: string;
    pushSubscriptionInfo?: PushSubscriptionInfo;
}

export interface CreateRepairOrderRequest {
    authorization: string;
    repairOrderInfo?: RepairOrderInfo;
}

export interface CreateRepairOrderAttachmentEntryRequest {
    authorization: string;
    repairOrder: string;
    file?: Blob;
}

export interface CreateRepairOrderMediaEntryRequest {
    authorization: string;
    repairOrder: string;
    rank: number;
    title?: string;
    description?: string;
    file?: Blob;
}

export interface CreateRepairOrderStatusRequest {
    authorization: string;
    repairOrderStatusEntry?: RepairOrderStatusEntry;
}

export interface CreateResourceRequest {
    authorization: string;
    resourceInfo?: ResourceInfo;
}

export interface CreateRoleRequest {
    authorization: string;
    role?: Role;
}

export interface CreateServiceFromCannedJobRequest {
    authorization: string;
    createCannedJobService?: CreateCannedJobService;
}

export interface CreateServiceStandaloneRequest {
    authorization: string;
    serviceInfo?: ServiceInfo;
}

export interface CreateShopRequest {
    authorization: string;
    shopInfoRequest?: ShopInfoRequest;
}

export interface CreateShopFeatureRequest {
    authorization: string;
    shopFeatureInfo?: ShopFeatureInfo;
}

export interface CreateShopVendorRequest {
    authorization: string;
    vendorInfo?: VendorInfo;
}

export interface CreateStandaloneApiKeyForCustomerOperationRequest {
    authorization: string;
    createStandaloneApiKeyForCustomerRequest?: CreateStandaloneApiKeyForCustomerRequest;
}

export interface CreateSubletRequest {
    authorization: string;
    subletInfo?: SubletInfo;
}

export interface CreateTimeClockRequest {
    authorization: string;
    timeClockInfo?: TimeClockInfo;
}

export interface CreateVehicleRequest {
    authorization: string;
    newVehicleRequest?: NewVehicleRequest;
}

export interface CreateVehicleFluidsRequest {
    authorization: string;
    vehicleFluidsInfo?: VehicleFluidsInfo;
}

export interface CreateVehicleImageRequest {
    authorization: string;
    shop: string;
    vehicle: string;
    isPrimary: boolean;
    file?: Blob;
}

export interface CreateVehicleIntakeRequest {
    newVehicleRequest?: NewVehicleRequest;
}

export interface CreateVehicleOilFilterRequest {
    authorization: string;
    vehicleOilFilterInfo?: VehicleOilFilterInfo;
}

export interface CreateVehicleReminderMessageRequest {
    authorization: string;
    vehicleReminderMessageInfo?: VehicleReminderMessageInfo;
}

export interface CreateVehicleWithVerificationIdRequest {
    xAPIKey: string;
    newVehicleRequest?: NewVehicleRequest;
}

export interface CreateVerificationOperationRequest {
    createVerificationRequest?: CreateVerificationRequest;
}

export interface CreateWarrantyEntryRequest {
    authorization: string;
    warrantyInfo: WarrantyInfo;
}

export interface DeleteAdditionalEmailAddressRequest {
    authorization: string;
    customer: string;
    id: string;
}

export interface DeleteAdditionalPhoneRequest {
    authorization: string;
    customer: string;
    id: string;
}

export interface DeleteAnswerToCannedJobEntryRequest {
    authorization: string;
    id: string;
    answer: string;
}

export interface DeleteCannedJobCustomFeeRequest {
    authorization: string;
    cannedJob: string;
    cannedJobFee: string;
}

export interface DeleteCannedJobCustomLaborRequest {
    authorization: string;
    cannedJob: string;
    customLabor: string;
}

export interface DeleteCannedJobCustomPartRequest {
    authorization: string;
    customPart: string;
    cannedJob?: string;
    shop?: string;
    counterSale?: string;
}

export interface DeleteCannedJobMotorFluidsRequest {
    authorization: string;
    cannedJob: string;
    motorFluids: string;
}

export interface DeleteCannedJobMotorLaborRequest {
    authorization: string;
    cannedJob: string;
    motorLabor: string;
}

export interface DeleteCannedJobPartLookupRequest {
    authorization: string;
    cannedJob: string;
    partLookup: string;
}

export interface DeleteCannedJobRelatedRequest {
    authorization: string;
    shopId: string;
    id: string;
}

export interface DeleteCannedJobToInventoryPartRequest {
    authorization: string;
    cannedJob: string;
    inventoryPart: string;
}

export interface DeleteCannedJobToInventoryProductRequest {
    authorization: string;
    cannedJob: string;
    inventoryProduct: string;
}

export interface DeleteCatalogLaborMatrixEntryRequest {
    authorization: string;
    shop: string;
    catalogLaborMatrixEntry: string;
}

export interface DeleteCustomFeeRequest {
    authorization: string;
    customFee: string;
    service?: string;
    counterSale?: string;
}

export interface DeleteCustomMessageTemplateRequest {
    authorization: string;
    shop: string;
    id: string;
}

export interface DeleteDiscountRequest {
    authorization: string;
    discountEntry?: DiscountEntry;
}

export interface DeleteInspectionEntryServiceAssociationRequest {
    authorization: string;
    inspectionEntryId: string;
    serviceId: string;
}

export interface DeleteInventoryInstancesRequest {
    authorization: string;
    shop: string;
    inventoryInstance: Array<string>;
}

export interface DeleteLaborMatrixEntryRequest {
    authorization: string;
    shop: string;
    laborMatrixEntry: string;
}

export interface DeleteLaborOverrideRequest {
    authorization: string;
    shop: string;
    service: string;
    id: string;
}

export interface DeletePartOverrideRequest {
    authorization: string;
    service: string;
    partUuid: string;
}

export interface DeletePartsFromServiceRequest {
    authorization: string;
    service: string;
    partIds: Array<string>;
}

export interface DeletePartsMatrixEntryRequest {
    authorization: string;
    shop: string;
    partsMatrixEntry: string;
}

export interface DeleteRoleRequest {
    authorization: string;
    shop: string;
    role: string;
}

export interface DeleteShopRequest {
    authorization: string;
    id: string;
}

export interface DeleteSubletRequest {
    authorization: string;
    sublet: string;
    service?: string;
}

export interface DeleteWarrantyEntryRequest {
    authorization: string;
    warrantyEntry: string;
    shop: string;
}

export interface DigitalAuthorizationRequest {
    xAPIKey: string;
    authorizationInfo?: AuthorizationInfo;
}

export interface DownloadInvoicePdfByApiKeyRequest {
    xAPIKey: string;
}

export interface EchoPingPersonPostRequest {
    expandedPingPerson?: ExpandedPingPerson;
}

export interface EditCartRefreshPricesRequest {
    authorization: string;
    updatePartsTechQuoteRequest?: UpdatePartsTechQuoteRequest;
}

export interface EmailCustomerInspectionRequest {
    authorization: string;
    emailInspectionRequest?: EmailInspectionRequest;
}

export interface EmailInvoicePdfRequest {
    authorization: string;
    emailInvoiceRequest?: EmailInvoiceRequest;
}

export interface EmailSearchRequest {
    authorization: string;
    shop: string;
    email: string;
}

export interface GenerateApiKeyRequest {
    authorization: string;
    id: string;
}

export interface GenerateApiKeyWithIntentOperationRequest {
    authorization: string;
    generateApiKeyWithIntentRequest?: GenerateApiKeyWithIntentRequest;
}

export interface GenerateInspectionCustomerNoteRequest {
    authorization: string;
    inspectionCustomerNoteRequest?: InspectionCustomerNoteRequest;
}

export interface GenerateRepairOrderCustomerSummaryRequest {
    authorization: string;
    repairOrderCustomerSummaryRequest?: RepairOrderCustomerSummaryRequest;
}

export interface GetRequest {
    myParam?: string;
}

export interface Get1Request {
    authorization: string;
    myParam?: string;
}

export interface GetAccountRequest {
    authorization: string;
    shop: string;
}

export interface GetActivityLogRequest {
    authorization: string;
    pageNumber?: number;
}

export interface GetAdditionalEmailAddressesForCustomerRequest {
    authorization: string;
    customer: string;
}

export interface GetAdditionalPhonesForCustomerRequest {
    authorization: string;
    customer: string;
}

export interface GetAllEnginesRequest {
    authorization: string;
    shop: string;
    year: number;
    makeId: number;
    modelId: number;
    subModelId: number;
}

export interface GetAllEnginesIntakeRequest {
    year: number;
    makeId: number;
    modelId: number;
    subModelId: number;
}

export interface GetAllEnginesWithVerificationIdRequest {
    xAPIKey: string;
    shopId: string;
    customerId: string;
    year: number;
    makeId: number;
    modelId: number;
    subModelId: number;
}

export interface GetAllMakesRequest {
    authorization: string;
    shop: string;
    year: number;
}

export interface GetAllMakesIntakeRequest {
    year: number;
}

export interface GetAllMakesWithVerificationIdRequest {
    xAPIKey: string;
    shopId: string;
    customerId: string;
    year: number;
}

export interface GetAllModelsRequest {
    authorization: string;
    shop: string;
    year: number;
    makeId: number;
}

export interface GetAllModelsIntakeRequest {
    year: number;
    makeId: number;
}

export interface GetAllModelsWithVerificationIdRequest {
    xAPIKey: string;
    shopId: string;
    customerId: string;
    year: number;
    makeId: number;
}

export interface GetAllSubModelsRequest {
    authorization: string;
    shop: string;
    year: number;
    makeId: number;
    modelId: number;
}

export interface GetAllSubModelsIntakeRequest {
    year: number;
    makeId: number;
    modelId: number;
}

export interface GetAllSubModelsWithVerificationIdRequest {
    xAPIKey: string;
    shopId: string;
    customerId: string;
    year: number;
    makeId: number;
    modelId: number;
}

export interface GetApiKeyInventoryPartsRequest {
    xAPIKey: string;
}

export interface GetApiKeyReviewRequest {
    xAPIKey: string;
}

export interface GetApiKeysForRepairOrderRequest {
    authorization: string;
    repairOrderId: string;
}

export interface GetAppointmentRequest {
    authorization: string;
    shop: string;
    appointment: Array<string>;
}

export interface GetAppointmentAvailabilityRequest {
    authorization: string;
    shop: string;
    current: string;
    startDateInclusive: string;
    endDateExclusive: string;
}

export interface GetAppointmentAvailabilityWithVerificationIdRequest {
    xAPIKey: string;
    shopId: string;
    customerId: string;
    current: string;
    startDateInclusive: string;
    endDateExclusive: string;
}

export interface GetAppointmentSettingsByShopRequest {
    authorization: string;
    shop: string;
}

export interface GetAppointmentSettingsByShopWithVerificationIdRequest {
    xAPIKey: string;
    shopId: string;
    customerId: string;
}

export interface GetAppointmentTimeConstraintsByShopRequest {
    authorization: string;
    shop: string;
}

export interface GetAppointmentsForCustomerRequest {
    authorization: string;
    shop: string;
    customer: string;
}

export interface GetAppointmentsForVehicleRequest {
    authorization: string;
    shop: string;
    vehicle: string;
}

export interface GetAutomatedVehicleReminderEntriesByIdRequest {
    authorization: string;
    shop: string;
    ids: Array<string>;
}

export interface GetCannedJobExpandedRequest {
    authorization: string;
    shop: string;
    cannedJobs: Array<string>;
}

export interface GetCannedJobExpandedByApiKeyRequest {
    xAPIKey: string;
}

export interface GetCannedJobMotorFluidsRequest {
    authorization: string;
    shop: string;
    id: string;
}

export interface GetCannedJobMotorLaborRequest {
    authorization: string;
    shop: string;
    id: string;
}

export interface GetCannedJobRelatedRequest {
    authorization: string;
    shopId: string;
    ids: Array<string>;
}

export interface GetCannedJobRelatedToParentRequest {
    authorization: string;
    parent: string;
}

export interface GetCannedJobsRequest {
    authorization: string;
    shop: string;
    cannedJobs: Array<string>;
}

export interface GetCarfaxServicesRequest {
    authorization: string;
    shop: string;
    vin: string;
}

export interface GetCatalogLaborMatrixEntriesRequest {
    authorization: string;
    shop: string;
}

export interface GetChaliceRequest {
    authorization: string;
    formInfo?: FormInfo;
}

export interface GetClassesOperationRequest {
    authorization: string;
    getClassesRequest?: GetClassesRequest;
}

export interface GetCoreInventoryInstancesRequest {
    authorization: string;
    shop: string;
    isPending: boolean;
    endDateExclusive?: string;
    numResults?: number;
}

export interface GetCustomMessageTemplatesForShopRequest {
    authorization: string;
    shop: string;
}

export interface GetCustomerInfoRequest {
    authorization: string;
    shop: string;
    customer: Array<string>;
}

export interface GetCustomerInfoByApiKeyRequest {
    xAPIKey: string;
}

export interface GetCustomerOrdersRequest {
    authorization: string;
    shop: string;
    customer: string;
}

export interface GetCustomerPagesRequest {
    authorization: string;
    shop: string;
    maxTimestampExclusive?: string;
    numResults?: number;
}

export interface GetCustomerPortalInfoForCustomerRequest {
    xAPIKey: string;
}

export interface GetCustomerVehiclesRequest {
    authorization: string;
    shop: string;
    customer: string;
    numResults?: number;
}

export interface GetCustomerVehiclesWithVerificationIdRequest {
    xAPIKey: string;
    shopId: string;
    customerId: string;
    numResults?: number;
}

export interface GetCustomersForVehicleRequest {
    authorization: string;
    shop: string;
    vehicle: string;
}

export interface GetDiscountsForShopRequest {
    authorization: string;
    shop: string;
    activeOnly?: boolean;
}

export interface GetEmployeeRolesRequest {
    authorization: string;
    shop: string;
}

export interface GetExpandedReviewsRequest {
    authorization: string;
    shopId: string;
}

export interface GetFinixIdentityIdRequest {
    authorization: string;
    shop: string;
}

export interface GetFinixOnboardingFormRequest {
    authorization: string;
    shop: string;
}

export interface GetFluidApplicationForEstimatedWorkTimeRequest {
    authorization: string;
    shop: string;
    vehicle: string;
    estimatedWorkTimeApplicationId: number;
}

export interface GetFluidTaxonomyForVehicleRequest {
    authorization: string;
    shop: string;
    vehicle: string;
}

export interface GetFluidsTaxonomyRequest {
    authorization: string;
}

export interface GetInspectionRequest {
    authorization: string;
    id: string;
}

export interface GetInspectionEntryMessagesRequest {
    authorization: string;
    shop?: string;
    inspectionEntry?: string;
}

export interface GetInspectionEntryServiceAssociationsRequest {
    authorization: string;
    inspectionEntryId?: string;
    serviceId?: string;
}

export interface GetInspectionEntryServiceAssociationsByApiKeyRequest {
    xAPIKey: string;
}

export interface GetInspectionEntryServiceAssociationsForRepairOrderRequest {
    authorization: string;
    repairOrder?: string;
    shop?: string;
}

export interface GetInspectionEntryStatusRequest {
    authorization: string;
    shop: string;
    isActive?: boolean;
}

export interface GetInspectionForCustomerRequest {
    xAPIKey: string;
}

export interface GetInspectionSectionMessagesRequest {
    authorization: string;
    shop?: string;
    inspectionSection?: string;
}

export interface GetIntakeRequestsRequest {
    authorization: string;
    customerId?: string;
    intakeRequestId?: string;
    shopId?: string;
    repairOrderId?: string;
    includeArchived?: boolean;
}

export interface GetInventoryAdjustmentsRequest {
    authorization: string;
    shop: string;
    instances?: Array<string>;
    entries?: Array<string>;
}

export interface GetInventoryInstanceExpandedRequest {
    authorization: string;
    shop: string;
    inventoryPart: string;
    isNotCore?: boolean;
    isClaimed?: boolean;
    isMarkedForReturnNotReturned?: boolean;
    isReturned?: boolean;
    isLost?: boolean;
    startDateInclusive?: string;
    endDateExclusive?: string;
    numResults?: number;
}

export interface GetInventoryInstancesRequest {
    authorization: string;
    shop: string;
    inventoryPart: string;
    isNotCore?: boolean;
    isClaimed?: boolean;
    isMarkedForReturnNotReturned?: boolean;
    isReturned?: boolean;
    isLost?: boolean;
    startDateInclusive?: string;
    endDateExclusive?: string;
    numResults?: number;
}

export interface GetInventoryLocationsRequest {
    authorization: string;
    shop: string;
}

export interface GetInventoryNonCoreReturnsRequest {
    authorization: string;
    shop: string;
    isPending?: boolean;
    endDateExclusive?: string;
    numResults?: number;
    stockedOnly?: boolean;
    isFluidsOnly?: boolean;
}

export interface GetInventoryPartIdsByPartNumberRequest {
    authorization: string;
    shop: string;
    partNumbers: Array<string>;
}

export interface GetInventoryPartsRequest {
    authorization: string;
    shop: string;
    stockedOnly?: boolean;
    isFluidsOnly?: boolean;
    pageNumber?: number;
    entriesPerPage?: number;
    partId?: Array<string>;
    cannedJobId?: Array<string>;
    repairOrderId?: Array<string>;
    counterSaleId?: Array<string>;
}

export interface GetInventoryPartsByIdRequest {
    authorization: string;
    shop: string;
    part: Array<string>;
}

export interface GetInventoryPartsByPartNumberAndBrandRequest {
    authorization: string;
    shop: string;
    part: string;
    brand: string;
}

export interface GetKpiRequest {
    authorization: string;
    shop: string;
    startDateInclusive: string;
    endDateExclusive: string;
    numMonth?: number;
}

export interface GetLaborMatrixEntriesRequest {
    authorization: string;
    shop: string;
}

export interface GetLaborMatrixUsersRequest {
    authorization: string;
    shop: string;
    laborMatrix: string;
}

export interface GetLatestAppointmentsAtShopRequest {
    authorization: string;
    shop: string;
    startDateInclusive?: string;
    endDateExclusive?: string;
}

export interface GetLatestAppointmentsForResourceRequest {
    authorization: string;
    shop: string;
    resource: string;
}

export interface GetMaintenanceScheduleTimelineRequest {
    authorization: string;
    shop: string;
    vehicle: string;
    intervalType: GetMaintenanceScheduleTimelineIntervalTypeEnum;
    intervalValue: number;
    severity?: boolean;
}

export interface GetManyCustomerVehiclesRequest {
    authorization: string;
    shop: string;
    customer: Array<string>;
}

export interface GetMultipleVehicleInfoFromLicensePlateRequest {
    authorization: string;
    registeredCountry: GetMultipleVehicleInfoFromLicensePlateRegisteredCountryEnum;
    registeredState: GetMultipleVehicleInfoFromLicensePlateRegisteredStateEnum;
    licensePlate: string;
}

export interface GetMyPinRequest {
    authorization: string;
}

export interface GetMyProfileRequest {
    authorization: string;
}

export interface GetMyRolesRequest {
    authorization: string;
}

export interface GetNewActivityLogRequest {
    authorization: string;
}

export interface GetNumUnreadNotificationsRequest {
    authorization: string;
    shop: string;
}

export interface GetOtherPeopleProfilesRequest {
    authorization: string;
    user: Array<string>;
}

export interface GetPartsMatrixEntriesRequest {
    authorization: string;
    shop: string;
}

export interface GetPartsMatrixUsersRequest {
    authorization: string;
    shop: string;
    partsMatrix: string;
}

export interface GetPartsTechPartTaxonomyRequest {
    authorization: string;
    shop: string;
}

export interface GetPartsTechPartsInInventoryRequest {
    authorization: string;
    shop: string;
    partId: Array<string>;
}

export interface GetPingPersonRequest {
    name?: string;
    age?: number;
}

export interface GetPongRequest {
    myParam?: string;
    test?: number;
}

export interface GetPreviouslyDeferredServicesRequest {
    authorization: string;
    shopId: string;
    customerId: string;
    vehicleId: string;
}

export interface GetPreviouslyDeferredServicesWithVerificationIdRequest {
    xAPIKey: string;
    shopId: string;
    customerId: string;
    vehicleId: string;
}

export interface GetPriceLevelsRequest {
    authorization: string;
    shop: string;
}

export interface GetPrintActionHistoryForRepairOrderRequest {
    authorization: string;
    repairOrder: string;
}

export interface GetProductivityStatusRequest {
    authorization: string;
    shop: string;
}

export interface GetPublicCannedJobsRequest {
    authorization: string;
    shop: string;
}

export interface GetPublicCannedJobsWithVerificationIdRequest {
    xAPIKey: string;
    shopId: string;
    customerId: string;
}

export interface GetPublicShopInfoRequest {
    id: string;
}

export interface GetPurchaseOrderRequest {
    authorization: string;
    shop: string;
    id: string;
}

export interface GetPurchaseOrdersRequest {
    authorization: string;
    shop: string;
    includeArchived: boolean;
}

export interface GetRepairLinkAccessTokenRequest {
    authorization: string;
    shop: string;
}

export interface GetRepairLinkOrdersRequest {
    authorization: string;
    shop: string;
}

export interface GetRepairOrderAttachmentEntriesRequest {
    authorization: string;
    repairOrder?: string;
    attachmentIds?: Array<string>;
}

export interface GetRepairOrderByApiKeyRequest {
    xAPIKey: string;
}

export interface GetRepairOrderMediaEntriesRequest {
    authorization: string;
    repairOrder?: string;
    mediaIds?: Array<string>;
}

export interface GetRepairOrderMediaEntriesByApiKeyRequest {
    xAPIKey: string;
}

export interface GetRepairOrderServicesRequest {
    authorization: string;
    shop: string;
    repairOrder: Array<string>;
}

export interface GetRepairOrdersCsvRequest {
    authorization: string;
    getRepairOrdersCSVRequest?: GetRepairOrdersCSVRequest;
}

export interface GetReviewsRequest {
    authorization: string;
    repairOrderId: string;
}

export interface GetRolePermissionsRequest {
    authorization: string;
    role: string;
}

export interface GetRolesRequest {
    authorization: string;
    shop: string;
}

export interface GetServiceAdvisorForApiKeyRequest {
    xAPIKey: string;
}

export interface GetServiceTechniciansByApiKeyRequest {
    xAPIKey: string;
}

export interface GetServicesRequest {
    authorization: string;
    shop: string;
    services: Array<string>;
}

export interface GetServicesByApiKeyRequest {
    xAPIKey: string;
}

export interface GetSettingsRequest {
    authorization: string;
}

export interface GetShopIdsRequest {
    xAPIKey: string;
}

export interface GetShopLogoForDymoRequest {
    authorization: string;
    shop: string;
}

export interface GetShopProfileRequest {
    authorization: string;
    id: string;
}

export interface GetShopProfileForCustomersRequest {
    xAPIKey: string;
}

export interface GetTaxesAndFeesForShopRequest {
    authorization: string;
    shop: string;
}

export interface GetTechnicianForApiKeyRequest {
    xAPIKey: string;
}

export interface GetTransferRequest {
    authorization: string;
    shop: string;
    transferId: string;
}

export interface GetTruSpeedLinkRequest {
    authorization: string;
    shop: string;
    vehicle?: string;
}

export interface GetTwilioAccessTokenRequest {
    authorization: string;
    body?: string;
}

export interface GetUnarchivedAutomatedVehicleReminderByRdateRequest {
    authorization: string;
    shop: string;
    rdate: string;
}

export interface GetUnreadNotificationsByModifiedEntityRequest {
    authorization: string;
    modifiedEntity?: Array<string>;
}

export interface GetUpcomingUnarchivedAutomatedVehicleReminderRequest {
    authorization: string;
    shop: string;
}

export interface GetUsersWithPermissionRequest {
    authorization: string;
    shop: string;
    act: GetUsersWithPermissionActEnum;
}

export interface GetVehicleImagesRequest {
    authorization: string;
    shop: string;
    vehicle: string;
}

export interface GetVehicleImagesForApiKeyRequest {
    xAPIKey: string;
}

export interface GetVehicleInfoRequest {
    authorization: string;
    shop: string;
    vehicle: Array<string>;
}

export interface GetVehicleInfoForCustomerRequest {
    xAPIKey: string;
}

export interface GetVehicleInfoFromLicensePlateRequest {
    authorization: string;
    registeredCountry: GetVehicleInfoFromLicensePlateRegisteredCountryEnum;
    registeredState: GetVehicleInfoFromLicensePlateRegisteredStateEnum;
    licensePlate: string;
}

export interface GetVehicleInfoFromLicensePlateWithVerificationIdRequest {
    xAPIKey: string;
    shopId: string;
    customerId: string;
    registeredCountry: GetVehicleInfoFromLicensePlateWithVerificationIdRegisteredCountryEnum;
    registeredState: GetVehicleInfoFromLicensePlateWithVerificationIdRegisteredStateEnum;
    licensePlate: string;
}

export interface GetVehicleInfoFromVinRequest {
    authorization: string;
    shop: string;
    vin: string;
}

export interface GetVehicleInfoFromVinIntakeRequest {
    vin: string;
}

export interface GetVehicleInfoFromVinWithVerificationIdRequest {
    xAPIKey: string;
    shopId: string;
    customerId: string;
    vin: string;
}

export interface GetVehicleReminderMessageByIdRequest {
    authorization: string;
    shop: string;
    id: string;
}

export interface GetVehicleReminderMessageByVehicleRequest {
    authorization: string;
    vehicleId: string;
}

export interface GetVendorEntriesByIdRequest {
    authorization: string;
    shop: string;
    vendorIds: Array<string>;
}

export interface GetVendorEntriesByShopIdRequest {
    authorization: string;
    shop: string;
    unarchivedOnly?: boolean;
}

export interface GetVendorIdByShopIdAndPartstechIdRequest {
    authorization: string;
    shop: string;
    partstechId: number;
}

export interface GetVendorIdByShopIdNameAndPartstechIdRequest {
    authorization: string;
    shop: string;
    name: string;
    partstechId: number;
}

export interface GetVerificationByIdRequest {
    xAPIKey: string;
}

export interface GetWorkTimeTaxonomyRequest {
    authorization: string;
}

export interface GetWorkTimeTaxonomyForVehicleRequest {
    authorization: string;
    shop: string;
    vehicle: string;
}

export interface HandleAppointmentScheduleRequestResponseRequest {
    authorization: string;
    appointmentScheduleResponseRequest?: AppointmentScheduleResponseRequest;
}

export interface InitiateAccountLoginRequest {
    authorization: string;
    shop: string;
}

export interface InviteUserRequest {
    authorization: string;
    shop: string;
    realName: string;
    email: string;
    role: string;
}

export interface JoinCustomerChatRequest {
    authorization: string;
    body?: string;
}

export interface JoinRepairOrderRequest {
    authorization: string;
    body?: string;
}

export interface LeaveCustomerChatRequest {
    authorization: string;
    shop: string;
    conversationId: string;
    phoneNumber: string;
}

export interface LinkCustomerToVehicleRequest {
    authorization: string;
    shop: string;
    vehicle: string;
    customer: string;
}

export interface LinkInventoryRequest {
    authorization: string;
    claimInventoryRequest?: ClaimInventoryRequest;
}

export interface LinkInventoryAdjustmentOperationRequest {
    authorization: string;
    linkInventoryAdjustmentRequest?: LinkInventoryAdjustmentRequest;
}

export interface ListAnswerToCannedJobRequest {
    authorization: string;
    inspection: string;
}

export interface ListApiKeyFailedPaymentAttemptsRequest {
    authorization: string;
    repairOrderId?: string;
}

export interface ListAuthorizationsRequest {
    authorization: string;
    shop: string;
    repairOrder: Array<string>;
}

export interface ListAuthorizationsForApiKeyRequest {
    xAPIKey: string;
}

export interface ListCannedJobsRequest {
    authorization: string;
    shop: string;
}

export interface ListCannedPartsRequest {
    authorization: string;
    shop: string;
}

export interface ListCounterSalesRequest {
    authorization: string;
    shop: string;
    ids?: Array<string>;
    numResults?: number;
    showArchived?: boolean;
}

export interface ListCounterSalesExpandedRequest {
    authorization: string;
    shop: string;
    ids?: Array<string>;
    numResults?: number;
    showArchived?: boolean;
}

export interface ListCreditCardReadersRequest {
    authorization: string;
    shop: string;
}

export interface ListCustomersRequest {
    authorization: string;
    shop: string;
    maxTimestampExclusive?: string;
    numResults?: number;
}

export interface ListEntriesRequest {
    authorization: string;
    inspection: string;
}

export interface ListFeaturesForShopRequest {
    authorization: string;
    shop: string;
}

export interface ListFluidApplicationsForTaxonomyRequest {
    authorization: string;
    shop: string;
    vehicle: string;
    system?: number;
    group?: number;
    subgroup?: number;
    taxonomy?: number;
}

export interface ListInspectionsRequest {
    authorization: string;
    template: boolean;
    endDateExclusive: string;
    numResults: number;
    shop?: string;
    repairOrders?: Array<string>;
    technicians?: Array<string>;
    isAssigned?: boolean;
    isStarted?: boolean;
    isFinished?: boolean;
}

export interface ListInspectionsForRepairOrderRequest {
    authorization: string;
    shop: string;
    repairOrders: Array<string>;
}

export interface ListLineItemsForCodatRequest {
    xAPIKey: string;
    shop: string;
    isRecent?: boolean;
    startDateInclusive?: string;
    endDateExclusive?: string;
    numResults?: number;
}

export interface ListMediaRequest {
    authorization: string;
    entryId?: string;
    mediaId?: Array<string>;
}

export interface ListMultipleChoiceAnswersRequest {
    authorization: string;
    inspection: string;
}

export interface ListNotificationsRequest {
    authorization: string;
    shop: string;
    startDateInclusive?: string;
    endDateExclusive?: string;
    numResults?: number;
}

export interface ListPartStatusForRepairOrderRequest {
    authorization: string;
    shop: string;
    repairOrder?: string;
}

export interface ListPaymentsForApiKeyRequest {
    xAPIKey: string;
}

export interface ListPaymentsForCustomerRequest {
    authorization: string;
    shop: string;
    customer?: Array<string>;
}

export interface ListPaymentsForRepairOrderRequest {
    authorization: string;
    shop: string;
    repairOrder: Array<string>;
}

export interface ListPaymentsForRepairOrderForCodatRequest {
    xAPIKey: string;
    shop: string;
    repairOrder: Array<string>;
}

export interface ListPromptsRequest {
    authorization: string;
    inspection: string;
}

export interface ListRepairOrderStatusesForShopRequest {
    authorization: string;
    shop: string;
}

export interface ListRepairOrdersRequest {
    authorization: string;
    shop: string;
    repairOrder?: Array<string>;
    customer?: Array<string>;
    vehicle?: string;
    appointment?: Array<string>;
    isEstimate?: boolean;
    isRecent?: boolean;
    isOpen?: boolean;
    serviceAdvisor?: string;
    technician?: string;
    startDateInclusive?: string;
    endDateExclusive?: string;
    numResults?: number;
}

export interface ListRepairOrdersWithRelatedRequest {
    authorization: string;
    shop: string;
    repairOrder?: Array<string>;
    customer?: Array<string>;
    vehicle?: string;
    appointment?: Array<string>;
    isEstimate?: boolean;
    isRecent?: boolean;
    isOpen?: boolean;
    serviceAdvisor?: string;
    technician?: string;
    startDateInclusive?: string;
    endDateExclusive?: string;
    numResults?: number;
}

export interface ListSectionsRequest {
    authorization: string;
    inspection: string;
}

export interface ListShopResourcesRequest {
    authorization: string;
    shop: string;
}

export interface ListTimeClockEntriesRequest {
    authorization: string;
    shop: string;
    ids?: Array<string>;
    employee?: Array<string>;
    repairOrder?: Array<string>;
    isOpenOnly?: boolean;
    isClosedOnly?: boolean;
    startDateInclusive?: string;
    endDateExclusive?: string;
    numResults?: number;
}

export interface ListTimeClockHistoryEntriesRequest {
    authorization: string;
    shop: string;
    id: string;
}

export interface ListVehicleReminderRequest {
    authorization: string;
    shop: string;
    lastDate?: string;
    lastTimestamp?: string;
    numResults?: number;
}

export interface ListVehicleWithCustomersRequest {
    authorization: string;
    shop: string;
    maxTimestampExclusive?: string;
    numResults?: number;
}

export interface ListVehiclesRequest {
    authorization: string;
    shop: string;
    maxTimestampExclusive?: string;
    numResults?: number;
}

export interface LockSessionRequest {
    authorization: string;
}

export interface LoggingRequest {
    subject: string;
    body: string;
    userAgent?: string;
    authorization?: string;
}

export interface LoginRequest {
    firebaseIdToken: string;
}

export interface LogoutRequest {
    authorization: string;
    deviceToken?: string;
}

export interface LookupPhoneNumberRequest {
    authorization: string;
    shop: string;
    phoneNumber: string;
}

export interface LookupRepairOrdersForPartOverridesRequest {
    authorization: string;
    shop: string;
    partOverride: Array<string>;
}

export interface LookupServicesRequest {
    authorization: string;
    shop: string;
    vehicle: string;
    keyword: string;
}

export interface MarkAllNotificationsAsReadRequest {
    authorization: string;
}

export interface MarkNotificationAsReadRequest {
    authorization: string;
    requestBody?: Array<string>;
}

export interface MarkNotificationAsUnreadRequest {
    authorization: string;
    requestBody?: Array<string>;
}

export interface MergeCustomersOperationRequest {
    authorization: string;
    mergeCustomersRequest?: MergeCustomersRequest;
}

export interface MergeVehiclesOperationRequest {
    authorization: string;
    mergeVehiclesRequest?: MergeVehiclesRequest;
}

export interface MotorSearchVehiclesRequest {
    authorization: string;
    searchTerm?: string;
}

export interface OnboardFinixMerchantOperationRequest {
    authorization: string;
    onboardFinixMerchantRequest?: OnboardFinixMerchantRequest;
}

export interface OnboardShopWithCodatRequest {
    authorization: string;
    shop: string;
    codatId: string;
    codatDefaultItemId: string;
}

export interface OrderCartRequest {
    authorization: string;
    body?: string;
}

export interface OrderCart1Request {
    authorization: string;
    updateOrderRepairLinkRequest?: UpdateOrderRepairLinkRequest;
}

export interface PatchRequest {
    myParam?: string;
}

export interface PostRequest {
    myParam?: string;
}

export interface PostMultipartRequest {
    myParam?: string;
    file?: Blob;
    maxSize?: number;
}

export interface PubliclyCreateCustomerRequest {
    publicCustomerCreationRequest?: PublicCustomerCreationRequest;
}

export interface PullCacheRequest {
    authorization: string;
    shopId: string;
    pullRequest?: PullRequest;
}

export interface PurchaseCallbackRequest {
    body?: string;
}

export interface PushCacheRequest {
    authorization: string;
    shopId: string;
    pushRequest?: PushRequest;
}

export interface PutRequest {
    myParam?: string;
}

export interface PutTextOperationRequest {
    putTextRequest?: PutTextRequest;
}

export interface QuickVinLookupRequest {
    authorization: string;
    plate: string;
    state: string;
    shop: string;
    vin?: string;
}

export interface RecommendMaintenanceScheduleOperationRequest {
    authorization: string;
    recommendMaintenanceScheduleRequest?: RecommendMaintenanceScheduleRequest;
}

export interface RegisterAndroidDeviceRequest {
    authorization: string;
    firebaseToken: string;
}

export interface RegisterIOSDeviceRequest {
    authorization: string;
    deviceToken: string;
}

export interface ReleaseInventoryClaimsRequest {
    authorization: string;
    shop: string;
    partOverride: string;
}

export interface ReplyRequest {
    authorization: string;
    replyCustomerRequest?: ReplyCustomerRequest;
}

export interface ReportUserRequest {
    authorization: string;
    maliciousUserId: string;
    body: string;
}

export interface RevokeRoleRequest {
    authorization: string;
    user: string;
    shop: string;
}

export interface RewriteOperationRequest {
    authorization: string;
    rewriteRequest?: RewriteRequest;
}

export interface SearchCannedJobsRequest {
    authorization: string;
    shop: string;
    keyword: string;
}

export interface SearchCannedPartsRequest {
    authorization: string;
    shop: string;
    keyword: string;
}

export interface SearchForCustomerRequest {
    authorization: string;
    shop: string;
    keyword: string;
}

export interface SearchInventoryPartsRequest {
    authorization: string;
    shop: string;
    keyword: string;
    stockedOnly?: boolean;
    receivedOnly?: boolean;
}

export interface SearchPartsTechBrandsRequest {
    authorization: string;
    shop: string;
    keyword: string;
}

export interface SearchPartsTechSuppliersRequest {
    authorization: string;
    shop: string;
    keyword: string;
}

export interface SearchPublicCustomerInfoRequest {
    shopId: string;
    searchString?: string;
    id?: string;
}

export interface SearchRepairOrderByKeywordRequest {
    authorization: string;
    shop: string;
    keyword?: string;
    isEstimate?: boolean;
    isArchived?: boolean;
    isOpen?: boolean;
}

export interface SearchVehicleByFieldRequest {
    authorization: string;
    shop: string;
    vin?: string;
    registeredCountry?: SearchVehicleByFieldRegisteredCountryEnum;
    registeredState?: SearchVehicleByFieldRegisteredStateEnum;
    licensePlate?: string;
    make?: string;
    model?: string;
    year?: number;
}

export interface SearchVehicleByKeywordRequest {
    authorization: string;
    shop: string;
    keyword?: string;
}

export interface SearchVehicleFluidsRequest {
    authorization: string;
    shop: string;
    year: number;
    makeId?: number;
    modelId?: number;
    submodelId?: number;
    engineId?: number;
    make?: string;
    model?: string;
    submodel?: string;
    engine?: string;
}

export interface SearchVehicleOilFiltersRequest {
    authorization: string;
    shop: string;
    year: number;
    makeId?: number;
    modelId?: number;
    submodelId?: number;
    engineId?: number;
    make?: string;
    model?: string;
    submodel?: string;
    engine?: string;
}

export interface SendFeedbackEmailRequest {
    authorization: string;
    sendFeedbackLinkRequest?: SendFeedbackLinkRequest;
}

export interface SendFeedbackLinkOperationRequest {
    authorization: string;
    sendFeedbackLinkRequest?: SendFeedbackLinkRequest;
}

export interface SendVehicleReminderSmsOperationRequest {
    authorization: string;
    sendVehicleReminderSmsRequest?: SendVehicleReminderSmsRequest;
}

export interface SetFinixIdentityIdOperationRequest {
    authorization: string;
    setFinixIdentityIdRequest?: SetFinixIdentityIdRequest;
}

export interface SetFinixMerchantIdRequest {
    authorization: string;
    addFinixMerchantIdRequest?: AddFinixMerchantIdRequest;
}

export interface SetPartsTechCredentialsRequest {
    authorization: string;
    partsTechCredentials?: PartsTechCredentials;
}

export interface SetRepairLinkAccessTokenRequest {
    authorization: string;
    shop: string;
    code: string;
    redirectUri: string;
}

export interface SetRoleRequest {
    authorization: string;
    user: string;
    shop: string;
    role: string;
    hourlyRate?: number;
}

export interface SetTaxesAndFeesRequest {
    authorization: string;
    taxesAndFees?: TaxesAndFees;
}

export interface SubmitQuoteCallbackRequest {
    service: string;
    body?: string;
}

export interface SubmitQuoteCallbackAddPartWithPartOverrideRequest {
    service: string;
    partsMatrix: string;
    partOverride: string;
    body?: string;
}

export interface SubmitQuoteCallbackAddPartsRequest {
    service: string;
    partsMatrix: string;
    taxExempt: boolean;
    oilFilter?: string;
    fluids?: string;
    body?: string;
}

export interface SubmitReviewRequest {
    xAPIKey: string;
    customerFeedbackInfo?: CustomerFeedbackInfo;
}

export interface TextToPayPaymentOperationRequest {
    xAPIKey: string;
    textToPayPaymentRequest?: TextToPayPaymentRequest;
}

export interface TranscribeAudioRequest {
    authorization: string;
    file?: Blob;
}

export interface TriggerCodatFunctionRequest {
    authorization: string;
    shop: string;
}

export interface TwilioConversationMessageRequest {
    accountSid: string;
    conversationSid: string;
    messageSid?: string;
    body?: string;
    author?: string;
    media?: string;
    dateCreated?: string;
}

export interface TwilioSmsReceivedRequest {
    messageSid?: string;
    accountSid?: string;
    messagingServiceSid?: string;
    from?: string;
    to?: string;
    body?: string;
    numMedia?: number;
}

export interface UnlinkCustomerFromVehicleRequest {
    authorization: string;
    shop: string;
    vehicle: string;
    customer: string;
}

export interface UnlinkInventoryRequest {
    authorization: string;
    shop: string;
    linkId: Array<string>;
}

export interface UnlinkInventoryAndReleaseClaimsRequest {
    authorization: string;
    shop: string;
    partOverride: string;
}

export interface UnlockSessionRequest {
    authorization: string;
    updateMyPinRequest?: UpdateMyPinRequest;
}

export interface UpdateAdditionalEmailAddressRequest {
    authorization: string;
    customerAdditionalEmailAddressEntry?: CustomerAdditionalEmailAddressEntry;
}

export interface UpdateAdditionalPhoneRequest {
    authorization: string;
    customerAdditionalPhoneEntry?: CustomerAdditionalPhoneEntry;
}

export interface UpdateAnswerToCannedJobRequest {
    authorization: string;
    inspectionAnswerToCannedJobEntry?: InspectionAnswerToCannedJobEntry;
}

export interface UpdateAppointmentRequest {
    authorization: string;
    appointmentEntry?: AppointmentEntry;
}

export interface UpdateAppointmentSettingsRequest {
    authorization: string;
    appointmentSettingsEntry?: AppointmentSettingsEntry;
}

export interface UpdateAppointmentTimeConstraintsRequest {
    authorization: string;
    appointmentTimeConstraintsEntry?: AppointmentTimeConstraintsEntry;
}

export interface UpdateAuthorizationRequest {
    authorization: string;
    authorizationEntry?: AuthorizationEntry;
}

export interface UpdateAutomatedVehicleReminderRequest {
    authorization: string;
    automatedVehicleReminderEntry?: AutomatedVehicleReminderEntry;
}

export interface UpdateCannedJobRequest {
    authorization: string;
    cannedJobEntry?: CannedJobEntry;
}

export interface UpdateCannedJobCustomFeeRequest {
    authorization: string;
    cannedJobCustomFeeEntry?: CannedJobCustomFeeEntry;
}

export interface UpdateCannedJobCustomLaborRequest {
    authorization: string;
    cannedJobCustomLaborEntry?: CannedJobCustomLaborEntry;
}

export interface UpdateCannedJobCustomPartRequest {
    authorization: string;
    cannedJobCustomPartEntry?: CannedJobCustomPartEntry;
}

export interface UpdateCannedJobMotorFluidsRequest {
    authorization: string;
    cannedJobMotorFluidsEntry?: CannedJobMotorFluidsEntry;
}

export interface UpdateCannedJobMotorLaborRequest {
    authorization: string;
    cannedJobMotorLaborEntry?: CannedJobMotorLaborEntry;
}

export interface UpdateCannedJobPartLookupRequest {
    authorization: string;
    cannedJobPartLookupEntry?: CannedJobPartLookupEntry;
}

export interface UpdateCannedJobRelatedRequest {
    authorization: string;
    cannedJobRelatedEntry?: CannedJobRelatedEntry;
}

export interface UpdateCannedJobToInventoryPartRequest {
    authorization: string;
    cannedJobInventoryPartEntry?: CannedJobInventoryPartEntry;
}

export interface UpdateCannedJobToInventoryProductRequest {
    authorization: string;
    cannedJobToInventoryProductEntry?: CannedJobToInventoryProductEntry;
}

export interface UpdateCatalogLaborMatrixEntryRequest {
    authorization: string;
    catalogLaborMatrixEntry?: CatalogLaborMatrixEntry;
}

export interface UpdateCatalogLaborMatrixRanksOperationRequest {
    authorization: string;
    updateCatalogLaborMatrixRanksRequest?: UpdateCatalogLaborMatrixRanksRequest;
}

export interface UpdateCounterSaleRequest {
    authorization: string;
    counterSaleEntry?: CounterSaleEntry;
}

export interface UpdateCustomFeeRequest {
    authorization: string;
    customFeeEntry?: CustomFeeEntry;
}

export interface UpdateCustomMessageTemplateRequest {
    authorization: string;
    messageTemplateEntry?: MessageTemplateEntry;
}

export interface UpdateCustomerRequest {
    authorization: string;
    customerEntry?: CustomerEntry;
}

export interface UpdateDiscountRequest {
    authorization: string;
    discountEntry?: DiscountEntry;
}

export interface UpdateInspectionRequest {
    authorization: string;
    inspectionEntry?: InspectionEntry;
}

export interface UpdateInspectionEntryRequest {
    authorization: string;
    entryEntry?: EntryEntry;
}

export interface UpdateInspectionEntryRanksOperationRequest {
    authorization: string;
    updateInspectionEntryRanksRequest?: UpdateInspectionEntryRanksRequest;
}

export interface UpdateInspectionEntryStatusRequest {
    authorization: string;
    inspectionEntryStatusEntry?: InspectionEntryStatusEntry;
}

export interface UpdateInspectionEntryStatusRanksOperationRequest {
    authorization: string;
    updateInspectionEntryStatusRanksRequest?: UpdateInspectionEntryStatusRanksRequest;
}

export interface UpdateInspectionPromptRequest {
    authorization: string;
    promptEntry?: PromptEntry;
}

export interface UpdateInspectionSectionRequest {
    authorization: string;
    sectionEntry?: SectionEntry;
}

export interface UpdateInspectionSectionRanksOperationRequest {
    authorization: string;
    updateInspectionSectionRanksRequest?: UpdateInspectionSectionRanksRequest;
}

export interface UpdateIntakeRequestRequest {
    authorization: string;
    updateIntakeRequestObject?: UpdateIntakeRequestObject;
}

export interface UpdateInventoryAdjustmentRequest {
    authorization: string;
    inventoryAdjustmentEntry?: InventoryAdjustmentEntry;
}

export interface UpdateInventoryAdjustmentDocumentRequest {
    authorization: string;
    inventoryAdjustmentDocumentEntry?: InventoryAdjustmentDocumentEntry;
}

export interface UpdateInventoryInstanceRequest {
    authorization: string;
    inventoryPartInstanceEntry?: InventoryPartInstanceEntry;
}

export interface UpdateInventoryInstancesWithAdjustmentRequest {
    authorization: string;
    updateInventoryInstanceWithAdjustmentRequest?: UpdateInventoryInstanceWithAdjustmentRequest;
}

export interface UpdateInventoryPartRequest {
    authorization: string;
    inventoryPartEntry?: InventoryPartEntry;
}

export interface UpdateInviteeEmailRequest {
    authorization: string;
    updateEmailRequest?: UpdateEmailRequest;
}

export interface UpdateLaborMatrixEntryRequest {
    authorization: string;
    laborMatrixEntry?: LaborMatrixEntry;
}

export interface UpdateLaborMatrixRanksOperationRequest {
    authorization: string;
    updateLaborMatrixRanksRequest?: UpdateLaborMatrixRanksRequest;
}

export interface UpdateLaborMatrixUsersOperationRequest {
    authorization: string;
    updateLaborMatrixUsersRequest?: UpdateLaborMatrixUsersRequest;
}

export interface UpdateLaborOverrideByIdRequest {
    authorization: string;
    laborOverride?: LaborOverride;
}

export interface UpdateLastViewedRequest {
    authorization: string;
}

export interface UpdateMediaInfoRequest {
    authorization: string;
    inspectionMediaEntry?: InspectionMediaEntry;
}

export interface UpdateMileageInRequest {
    authorization: string;
    updateMileageRequest?: UpdateMileageRequest;
}

export interface UpdateMileageOutRequest {
    authorization: string;
    updateMileageRequest?: UpdateMileageRequest;
}

export interface UpdateMultipleChoiceAnswerRequest {
    authorization: string;
    multipleChoiceAnswerEntry?: MultipleChoiceAnswerEntry;
}

export interface UpdateMyEmailRequest {
    authorization: string;
    email: string;
}

export interface UpdateMyPinOperationRequest {
    authorization: string;
    updateMyPinRequest?: UpdateMyPinRequest;
}

export interface UpdatePartOverrideRequest {
    authorization: string;
    partOverride?: PartOverride;
}

export interface UpdatePartStatusRequest {
    authorization: string;
    partStatusEntry?: PartStatusEntry;
}

export interface UpdatePartsMatrixEntryRequest {
    authorization: string;
    partsMatrixEntry?: PartsMatrixEntry;
}

export interface UpdatePartsMatrixRanksOperationRequest {
    authorization: string;
    updatePartsMatrixRanksRequest?: UpdatePartsMatrixRanksRequest;
}

export interface UpdatePartsMatrixUsersOperationRequest {
    authorization: string;
    updatePartsMatrixUsersRequest?: UpdatePartsMatrixUsersRequest;
}

export interface UpdatePriceLevelRequest {
    authorization: string;
    priceLevelEntry?: PriceLevelEntry;
}

export interface UpdatePriceLevelRanksOperationRequest {
    authorization: string;
    updatePriceLevelRanksRequest?: UpdatePriceLevelRanksRequest;
}

export interface UpdateProductivityStatusRequest {
    authorization: string;
    productivityStatusEntry?: ProductivityStatusEntry;
}

export interface UpdateProfileRequest {
    authorization: string;
    realName: string;
}

export interface UpdatePurchaseOrderOperationRequest {
    authorization: string;
    updatePurchaseOrderRequest?: UpdatePurchaseOrderRequest;
}

export interface UpdateQuantityOperationRequest {
    authorization: string;
    updateQuantityRequest?: UpdateQuantityRequest;
}

export interface UpdateRepairLinkPricesRequest {
    authorization: string;
    service: string;
    accessToken: string;
}

export interface UpdateRepairOrderRequest {
    authorization: string;
    repairOrderEntry?: RepairOrderEntry;
}

export interface UpdateRepairOrderAttachmentInfoRequest {
    authorization: string;
    repairOrderAttachmentEntry?: RepairOrderAttachmentEntry;
}

export interface UpdateRepairOrderMediaInfoRequest {
    authorization: string;
    repairOrderMediaEntry?: RepairOrderMediaEntry;
}

export interface UpdateRepairOrderStatusRequest {
    authorization: string;
    repairOrderStatusEntry?: RepairOrderStatusEntry;
}

export interface UpdateRepairOrderStatusRanksRequest {
    authorization: string;
    reorderRepairOrderStatusesRequest?: ReorderRepairOrderStatusesRequest;
}

export interface UpdateRepairPaymentRequest {
    authorization: string;
    repairPaymentEntry?: RepairPaymentEntry;
}

export interface UpdateRepairPayment1Request {
    authorization: string;
    updatePaymentTypes?: UpdatePaymentTypes;
}

export interface UpdateResourceRequest {
    authorization: string;
    resourceEntry?: ResourceEntry;
}

export interface UpdateRoleRequest {
    authorization: string;
    role?: Role;
}

export interface UpdateRolePermissionsOperationRequest {
    authorization: string;
    updateRolePermissionsRequest?: UpdateRolePermissionsRequest;
}

export interface UpdateServiceRequest {
    authorization: string;
    serviceEntry?: ServiceEntry;
}

export interface UpdateServiceViaApiKeyRequest {
    xAPIKey: string;
    customerServiceStatusRequest?: CustomerServiceStatusRequest;
}

export interface UpdateSettingsRequest {
    authorization: string;
    directMessageReceived: boolean;
}

export interface UpdateShopFeatureRequest {
    authorization: string;
    shopFeatureEntry?: ShopFeatureEntry;
}

export interface UpdateShopProfileRequest {
    authorization: string;
    shopEntry?: ShopEntry;
}

export interface UpdateShopVendorRequest {
    authorization: string;
    vendorEntry?: VendorEntry;
}

export interface UpdateSubletRequest {
    authorization: string;
    subletEntry?: SubletEntry;
}

export interface UpdateTeamMemberPasswordRequest {
    authorization: string;
    member: string;
    password: string;
}

export interface UpdateTeamMemberProfileRequest {
    authorization: string;
    member: string;
    name: string;
}

export interface UpdateTimeClockRequest {
    authorization: string;
    timeClockEntry?: TimeClockEntry;
}

export interface UpdateVehicleInfoRequest {
    authorization: string;
    vehicleEntry?: VehicleEntry;
}

export interface UpdateWarrantyEntryRequest {
    authorization: string;
    warrantyEntry: WarrantyEntry;
}

export interface UploadFileOfMediaEntryRequest {
    authorization: string;
    id: string;
    file?: Blob;
}

export interface UploadScannedDocumentRequest {
    authorization: string;
    shopId: string;
    file?: Blob;
}

export interface UploadScannedExpenseDocumentRequest {
    authorization: string;
    shopId: string;
    file?: Blob;
}

export interface UploadShopLogoRequest {
    authorization: string;
    shop: string;
    file?: Blob;
}

export interface UploadUserProfilePicRequest {
    authorization: string;
    file?: Blob;
}

export interface UserContactRequest {
    subject: string;
    body: string;
    authorization?: string;
    email?: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     */
    async addInventoryToServiceRaw(requestParameters: AddInventoryToServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PartOverride>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling addInventoryToService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/inventory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddInventoryToServiceRequestToJSON(requestParameters.addInventoryToServiceRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PartOverrideFromJSON(jsonValue));
    }

    /**
     */
    async addInventoryToService(requestParameters: AddInventoryToServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PartOverride> {
        const response = await this.addInventoryToServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add parts in cart to service
     */
    async addPartsToServiceRaw(requestParameters: AddPartsToServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling addPartsToService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair-link/parts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddPartsToServiceRequestToJSON(requestParameters.addPartsToServiceRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add parts in cart to service
     */
    async addPartsToService(requestParameters: AddPartsToServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addPartsToServiceRaw(requestParameters, initOverrides);
    }

    /**
     * Archive an appointment
     */
    async archiveAppointmentRaw(requestParameters: ArchiveAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling archiveAppointment.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling archiveAppointment.');
        }

        if (requestParameters.appointment === null || requestParameters.appointment === undefined) {
            throw new runtime.RequiredError('appointment','Required parameter requestParameters.appointment was null or undefined when calling archiveAppointment.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.appointment !== undefined) {
            queryParameters['appointment'] = requestParameters.appointment;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive an appointment
     */
    async archiveAppointment(requestParameters: ArchiveAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archiveAppointmentRaw(requestParameters, initOverrides);
    }

    /**
     * Archive an automated vehicle reminder
     */
    async archiveAutomatedVehicleReminderRaw(requestParameters: ArchiveAutomatedVehicleReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling archiveAutomatedVehicleReminder.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling archiveAutomatedVehicleReminder.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling archiveAutomatedVehicleReminder.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/automated-vehicle-reminder`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive an automated vehicle reminder
     */
    async archiveAutomatedVehicleReminder(requestParameters: ArchiveAutomatedVehicleReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archiveAutomatedVehicleReminderRaw(requestParameters, initOverrides);
    }

    /**
     * Archive a counter sale
     */
    async archiveCounterSaleRaw(requestParameters: ArchiveCounterSaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling archiveCounterSale.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling archiveCounterSale.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling archiveCounterSale.');
        }

        if (requestParameters.archiveReason === null || requestParameters.archiveReason === undefined) {
            throw new runtime.RequiredError('archiveReason','Required parameter requestParameters.archiveReason was null or undefined when calling archiveCounterSale.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.archiveReason !== undefined) {
            queryParameters['archive-reason'] = requestParameters.archiveReason;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/counter-sale`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive a counter sale
     */
    async archiveCounterSale(requestParameters: ArchiveCounterSaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archiveCounterSaleRaw(requestParameters, initOverrides);
    }

    /**
     * Archive an intake request, possibly to indicate the request was acknowledged
     */
    async archiveIntakeRequestRaw(requestParameters: ArchiveIntakeRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling archiveIntakeRequest.');
        }

        if (requestParameters.requestId === null || requestParameters.requestId === undefined) {
            throw new runtime.RequiredError('requestId','Required parameter requestParameters.requestId was null or undefined when calling archiveIntakeRequest.');
        }

        const queryParameters: any = {};

        if (requestParameters.requestId !== undefined) {
            queryParameters['request-id'] = requestParameters.requestId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/intake`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive an intake request, possibly to indicate the request was acknowledged
     */
    async archiveIntakeRequest(requestParameters: ArchiveIntakeRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archiveIntakeRequestRaw(requestParameters, initOverrides);
    }

    /**
     * Delete price level
     */
    async archivePriceLevelRaw(requestParameters: ArchivePriceLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling archivePriceLevel.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling archivePriceLevel.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling archivePriceLevel.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/price-level`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete price level
     */
    async archivePriceLevel(requestParameters: ArchivePriceLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archivePriceLevelRaw(requestParameters, initOverrides);
    }

    /**
     * Archive a purchase order
     */
    async archivePurchaseOrderRaw(requestParameters: ArchivePurchaseOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling archivePurchaseOrder.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling archivePurchaseOrder.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling archivePurchaseOrder.');
        }

        if (requestParameters.isDeletion === null || requestParameters.isDeletion === undefined) {
            throw new runtime.RequiredError('isDeletion','Required parameter requestParameters.isDeletion was null or undefined when calling archivePurchaseOrder.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.isDeletion !== undefined) {
            queryParameters['is-deletion'] = requestParameters.isDeletion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchase-order`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive a purchase order
     */
    async archivePurchaseOrder(requestParameters: ArchivePurchaseOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archivePurchaseOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Archive an attachment
     */
    async archiveRepairOrderAttachmentRaw(requestParameters: ArchiveRepairOrderAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling archiveRepairOrderAttachment.');
        }

        if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
            throw new runtime.RequiredError('attachmentId','Required parameter requestParameters.attachmentId was null or undefined when calling archiveRepairOrderAttachment.');
        }

        const queryParameters: any = {};

        if (requestParameters.attachmentId !== undefined) {
            queryParameters['attachment-id'] = requestParameters.attachmentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/attachment`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive an attachment
     */
    async archiveRepairOrderAttachment(requestParameters: ArchiveRepairOrderAttachmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archiveRepairOrderAttachmentRaw(requestParameters, initOverrides);
    }

    /**
     * Archive a resource
     */
    async archiveResourceRaw(requestParameters: ArchiveResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling archiveResource.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling archiveResource.');
        }

        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling archiveResource.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.resource !== undefined) {
            queryParameters['resource'] = requestParameters.resource;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/resource`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive a resource
     */
    async archiveResource(requestParameters: ArchiveResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archiveResourceRaw(requestParameters, initOverrides);
    }

    /**
     * Archive a service
     */
    async archiveServiceRaw(requestParameters: ArchiveServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling archiveService.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling archiveService.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling archiveService.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive a service
     */
    async archiveService(requestParameters: ArchiveServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archiveServiceRaw(requestParameters, initOverrides);
    }

    /**
     * Archive a shop vendor
     */
    async archiveShopVendorRaw(requestParameters: ArchiveShopVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling archiveShopVendor.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling archiveShopVendor.');
        }

        if (requestParameters.vendorId === null || requestParameters.vendorId === undefined) {
            throw new runtime.RequiredError('vendorId','Required parameter requestParameters.vendorId was null or undefined when calling archiveShopVendor.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vendorId !== undefined) {
            queryParameters['vendor-id'] = requestParameters.vendorId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/vendor`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive a shop vendor
     */
    async archiveShopVendor(requestParameters: ArchiveShopVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archiveShopVendorRaw(requestParameters, initOverrides);
    }

    /**
     * Archive a vehicle image
     */
    async archiveVehicleImageRaw(requestParameters: ArchiveVehicleImageOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling archiveVehicleImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/images/archive`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ArchiveVehicleImageRequestToJSON(requestParameters.archiveVehicleImageRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Archive a vehicle image
     */
    async archiveVehicleImage(requestParameters: ArchiveVehicleImageOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.archiveVehicleImageRaw(requestParameters, initOverrides);
    }

    /**
     */
    async assignTechsToLaborOverrideRaw(requestParameters: AssignTechsToLaborOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling assignTechsToLaborOverride.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/labor-override/tech`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignTechsRequestToJSON(requestParameters.assignTechsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async assignTechsToLaborOverride(requestParameters: AssignTechsToLaborOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assignTechsToLaborOverrideRaw(requestParameters, initOverrides);
    }

    /**
     */
    async assignTechsToServiceRaw(requestParameters: AssignTechsToServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling assignTechsToService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/tech`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignTechToServiceRequestToJSON(requestParameters.assignTechToServiceRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async assignTechsToService(requestParameters: AssignTechsToServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.assignTechsToServiceRaw(requestParameters, initOverrides);
    }

    /**
     * Authorize a shop for carfax services
     */
    async authorizeShopForCarfaxRaw(requestParameters: AuthorizeShopForCarfaxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling authorizeShopForCarfax.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/carfax/authorize`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Authorize a shop for carfax services
     */
    async authorizeShopForCarfax(requestParameters: AuthorizeShopForCarfaxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authorizeShopForCarfaxRaw(requestParameters, initOverrides);
    }

    /**
     * Authorize a shop for Carfax QuickVIN services
     */
    async authorizeShopForQuickVinRaw(requestParameters: AuthorizeShopForQuickVinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling authorizeShopForQuickVin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/carfax/authorize-quickvin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Authorize a shop for Carfax QuickVIN services
     */
    async authorizeShopForQuickVin(requestParameters: AuthorizeShopForQuickVinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.authorizeShopForQuickVinRaw(requestParameters, initOverrides);
    }

    /**
     * Browse motor maintenance schedules for a particular vehicle
     */
    async browseMaintenanceSchedulesForVehicleRaw(requestParameters: BrowseMaintenanceSchedulesForVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MaintenanceSchedule>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling browseMaintenanceSchedulesForVehicle.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling browseMaintenanceSchedulesForVehicle.');
        }

        if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
            throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling browseMaintenanceSchedulesForVehicle.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/maintenance-schedule/services`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MaintenanceScheduleFromJSON));
    }

    /**
     * Browse motor maintenance schedules for a particular vehicle
     */
    async browseMaintenanceSchedulesForVehicle(requestParameters: BrowseMaintenanceSchedulesForVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MaintenanceSchedule>> {
        const response = await this.browseMaintenanceSchedulesForVehicleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Browse motor services. Will return services closest to the requested taxonomy. If none match the exact taxonomy provided, will automatically search one level higher
     */
    async browseServicesFromTaxonomyRaw(requestParameters: BrowseServicesFromTaxonomyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LaborApplication>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling browseServicesFromTaxonomy.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling browseServicesFromTaxonomy.');
        }

        if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
            throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling browseServicesFromTaxonomy.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        if (requestParameters.system !== undefined) {
            queryParameters['system'] = requestParameters.system;
        }

        if (requestParameters.group !== undefined) {
            queryParameters['group'] = requestParameters.group;
        }

        if (requestParameters.subgroup !== undefined) {
            queryParameters['subgroup'] = requestParameters.subgroup;
        }

        if (requestParameters.taxonomy !== undefined) {
            queryParameters['taxonomy'] = requestParameters.taxonomy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/work-time-taxonomy/services`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LaborApplicationFromJSON));
    }

    /**
     * Browse motor services. Will return services closest to the requested taxonomy. If none match the exact taxonomy provided, will automatically search one level higher
     */
    async browseServicesFromTaxonomy(requestParameters: BrowseServicesFromTaxonomyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LaborApplication>> {
        const response = await this.browseServicesFromTaxonomyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * File a bug report. Can be invoked by the user, or by the front-end code
     */
    async bugReportRaw(requestParameters: BugReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.subject === null || requestParameters.subject === undefined) {
            throw new runtime.RequiredError('subject','Required parameter requestParameters.subject was null or undefined when calling bugReport.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling bugReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.userAgent !== undefined && requestParameters.userAgent !== null) {
            headerParameters['User-Agent'] = String(requestParameters.userAgent);
        }

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.severity !== undefined) {
            formParams.append('severity', requestParameters.severity as any);
        }

        if (requestParameters.subject !== undefined) {
            formParams.append('subject', requestParameters.subject as any);
        }

        if (requestParameters.body !== undefined) {
            formParams.append('body', requestParameters.body as any);
        }

        const response = await this.request({
            path: `/contact/bug-report`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * File a bug report. Can be invoked by the user, or by the front-end code
     */
    async bugReport(requestParameters: BugReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bugReportRaw(requestParameters, initOverrides);
    }

    /**
     * Migrate all repair orders with one status to another
     */
    async bulkUpdateRepairOrderStatusRaw(requestParameters: BulkUpdateRepairOrderStatusOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling bulkUpdateRepairOrderStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order-status/bulk-update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BulkUpdateRepairOrderStatusRequestToJSON(requestParameters.bulkUpdateRepairOrderStatusRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Migrate all repair orders with one status to another
     */
    async bulkUpdateRepairOrderStatus(requestParameters: BulkUpdateRepairOrderStatusOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkUpdateRepairOrderStatusRaw(requestParameters, initOverrides);
    }

    /**
     * Check feature flags through Unleash for a given shop
     */
    async checkFeaturesForShopRaw(requestParameters: CheckFeaturesForShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: boolean; }>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling checkFeaturesForShop.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling checkFeaturesForShop.');
        }

        if (requestParameters.features === null || requestParameters.features === undefined) {
            throw new runtime.RequiredError('features','Required parameter requestParameters.features was null or undefined when calling checkFeaturesForShop.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.features) {
            queryParameters['features'] = requestParameters.features;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/unleash-features`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Check feature flags through Unleash for a given shop
     */
    async checkFeaturesForShop(requestParameters: CheckFeaturesForShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: boolean; }> {
        const response = await this.checkFeaturesForShopRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check feature flags through Unleash for a given shop using apiKey
     */
    async checkFeaturesForShopByApiKeyRaw(requestParameters: CheckFeaturesForShopByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: boolean; }>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling checkFeaturesForShopByApiKey.');
        }

        if (requestParameters.features === null || requestParameters.features === undefined) {
            throw new runtime.RequiredError('features','Required parameter requestParameters.features was null or undefined when calling checkFeaturesForShopByApiKey.');
        }

        const queryParameters: any = {};

        if (requestParameters.features) {
            queryParameters['features'] = requestParameters.features;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/unleash-features-api-key`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Check feature flags through Unleash for a given shop using apiKey
     */
    async checkFeaturesForShopByApiKey(requestParameters: CheckFeaturesForShopByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: boolean; }> {
        const response = await this.checkFeaturesForShopByApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check prices for repair link parts in cart
     */
    async checkRepairLinkPricesRaw(requestParameters: CheckRepairLinkPricesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepairLinkPartInCartExpandedChecked>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling checkRepairLinkPrices.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling checkRepairLinkPrices.');
        }

        if (requestParameters.accessToken === null || requestParameters.accessToken === undefined) {
            throw new runtime.RequiredError('accessToken','Required parameter requestParameters.accessToken was null or undefined when calling checkRepairLinkPrices.');
        }

        const queryParameters: any = {};

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        if (requestParameters.accessToken !== undefined) {
            queryParameters['access-token'] = requestParameters.accessToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair-link/prices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepairLinkPartInCartExpandedCheckedFromJSON));
    }

    /**
     * Check prices for repair link parts in cart
     */
    async checkRepairLinkPrices(requestParameters: CheckRepairLinkPricesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepairLinkPartInCartExpandedChecked>> {
        const response = await this.checkRepairLinkPricesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check if a shop is onboarded for Finix Card Not Present
     */
    async checkShopOnboardedRaw(requestParameters: CheckShopOnboardedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling checkShopOnboarded.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling checkShopOnboarded.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/payment/finix/card-not-present`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Check if a shop is onboarded for Finix Card Not Present
     */
    async checkShopOnboarded(requestParameters: CheckShopOnboardedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.checkShopOnboardedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check if verification code matches shopId and customerId
     */
    async checkVerificationCodeRaw(requestParameters: CheckVerificationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling checkVerificationCode.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling checkVerificationCode.');
        }

        if (requestParameters.verificationCode === null || requestParameters.verificationCode === undefined) {
            throw new runtime.RequiredError('verificationCode','Required parameter requestParameters.verificationCode was null or undefined when calling checkVerificationCode.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer-id'] = requestParameters.customerId;
        }

        if (requestParameters.verificationCode !== undefined) {
            queryParameters['verification-code'] = requestParameters.verificationCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/verification/check`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Check if verification code matches shopId and customerId
     */
    async checkVerificationCode(requestParameters: CheckVerificationCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.checkVerificationCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a semver indicating the API version. Major: will be updated whenever an existing schema is renamed or deleted.Minor: will be updated whenever a new endpoint or schema-field is added.Patch: will be updated whenever the API\'s externally visible behavior has been changed
     */
    async checkVersionApiRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SemVer>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/static/version-check/api`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SemVerFromJSON(jsonValue));
    }

    /**
     * Returns a semver indicating the API version. Major: will be updated whenever an existing schema is renamed or deleted.Minor: will be updated whenever a new endpoint or schema-field is added.Patch: will be updated whenever the API\'s externally visible behavior has been changed
     */
    async checkVersionApi(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SemVer> {
        const response = await this.checkVersionApiRaw(initOverrides);
        return await response.value();
    }

    /**
     * Check if updates are available/needed
     */
    async checkVersionWebappRaw(requestParameters: CheckVersionWebappRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.appVersion === null || requestParameters.appVersion === undefined) {
            throw new runtime.RequiredError('appVersion','Required parameter requestParameters.appVersion was null or undefined when calling checkVersionWebapp.');
        }

        const queryParameters: any = {};

        if (requestParameters.appVersion !== undefined) {
            queryParameters['app-version'] = requestParameters.appVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/static/version-check/webapp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Check if updates are available/needed
     */
    async checkVersionWebapp(requestParameters: CheckVersionWebappRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.checkVersionWebappRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Claim specific inventory part instances for a part-override in a repair-order. Note that this method does not link to the instance, in order to prevent duplicate links. That needs to be done separately
     */
    async claimInventoryRaw(requestParameters: ClaimInventoryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling claimInventory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/instance/claim`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClaimInventoryRequestToJSON(requestParameters.claimInventoryRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Claim specific inventory part instances for a part-override in a repair-order. Note that this method does not link to the instance, in order to prevent duplicate links. That needs to be done separately
     */
    async claimInventory(requestParameters: ClaimInventoryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.claimInventoryRaw(requestParameters, initOverrides);
    }

    /**
     * Clear all pending PartsTech parts for a given service
     */
    async clearCartRaw(requestParameters: ClearCartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling clearCart.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling clearCart.');
        }

        const queryParameters: any = {};

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/parts-tech`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Clear all pending PartsTech parts for a given service
     */
    async clearCart(requestParameters: ClearCartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.clearCartRaw(requestParameters, initOverrides);
    }

    /**
     * Webhook to capture Codat customer Id upon customer creation within Codat.
     */
    async codatCustomerCreationRaw(requestParameters: CodatCustomerCreationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhook/codat-customer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodatCustomerCreationRequestToJSON(requestParameters.codatCustomerCreationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhook to capture Codat customer Id upon customer creation within Codat.
     */
    async codatCustomerCreation(requestParameters: CodatCustomerCreationOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.codatCustomerCreationRaw(requestParameters, initOverrides);
    }

    /**
     * Webhook to capture Codat invoice Id upon invoice creation within Codat.
     */
    async codatInvoiceCreationRaw(requestParameters: CodatInvoiceCreationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhook/codat-invoice`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodatInvoiceCreationRequestToJSON(requestParameters.codatInvoiceCreationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhook to capture Codat invoice Id upon invoice creation within Codat.
     */
    async codatInvoiceCreation(requestParameters: CodatInvoiceCreationOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.codatInvoiceCreationRaw(requestParameters, initOverrides);
    }

    /**
     * Webhook to trigger lambda function that adds any necessary items into quickbooks.
     */
    async codatLambdaFunctionTriggerRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhook/codat-lambda`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhook to trigger lambda function that adds any necessary items into quickbooks.
     */
    async codatLambdaFunctionTrigger(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.codatLambdaFunctionTriggerRaw(initOverrides);
    }

    /**
     * Webhook to trigger lambda function that adds any necessary items into quickbooks.
     */
    async codatLambdaFunctionTriggerWithShopRaw(requestParameters: CodatLambdaFunctionTriggerWithShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling codatLambdaFunctionTriggerWithShop.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhook/codat-lambda-shop`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhook to trigger lambda function that adds any necessary items into quickbooks.
     */
    async codatLambdaFunctionTriggerWithShop(requestParameters: CodatLambdaFunctionTriggerWithShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.codatLambdaFunctionTriggerWithShopRaw(requestParameters, initOverrides);
    }

    /**
     * Webhook to capture Codat payment Id upon payment creation within Codat.
     */
    async codatRepairPaymentCreationRaw(requestParameters: CodatRepairPaymentCreationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/webhook/codat-payment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CodatRepairPaymentCreationRequestToJSON(requestParameters.codatRepairPaymentCreationRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Webhook to capture Codat payment Id upon payment creation within Codat.
     */
    async codatRepairPaymentCreation(requestParameters: CodatRepairPaymentCreationOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.codatRepairPaymentCreationRaw(requestParameters, initOverrides);
    }

    /**
     * Collect payment via Finix for card-not-present transaction
     */
    async collectPaymentCardNotPresentRaw(requestParameters: CollectPaymentCardNotPresentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RepairPaymentEntry>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling collectPaymentCardNotPresent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/payment/finix/transfer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentRequestCardNotPresentToJSON(requestParameters.paymentRequestCardNotPresent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairPaymentEntryFromJSON(jsonValue));
    }

    /**
     * Collect payment via Finix for card-not-present transaction
     */
    async collectPaymentCardNotPresent(requestParameters: CollectPaymentCardNotPresentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RepairPaymentEntry> {
        const response = await this.collectPaymentCardNotPresentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Collect payment via Finix for card-present transaction
     */
    async collectPaymentCardPresentRaw(requestParameters: CollectPaymentCardPresentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RepairPaymentEntry>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling collectPaymentCardPresent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/payment/finix/card-present`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentRequestCardPresentToJSON(requestParameters.paymentRequestCardPresent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairPaymentEntryFromJSON(jsonValue));
    }

    /**
     * Collect payment via Finix for card-present transaction
     */
    async collectPaymentCardPresent(requestParameters: CollectPaymentCardPresentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RepairPaymentEntry> {
        const response = await this.collectPaymentCardPresentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Replace part in cart with a custom part
     */
    async convertQuotedPartRaw(requestParameters: ConvertQuotedPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling convertQuotedPart.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/convert/part-in-cart/custom-part`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PartOverrideToJSON(requestParameters.partOverride),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Replace part in cart with a custom part
     */
    async convertQuotedPart(requestParameters: ConvertQuotedPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.convertQuotedPartRaw(requestParameters, initOverrides);
    }

    /**
     * Copy a canned job
     */
    async copyCannedJobRaw(requestParameters: CopyCannedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling copyCannedJob.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling copyCannedJob.');
        }

        if (requestParameters.cannedJob === null || requestParameters.cannedJob === undefined) {
            throw new runtime.RequiredError('cannedJob','Required parameter requestParameters.cannedJob was null or undefined when calling copyCannedJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.shop !== undefined) {
            formParams.append('shop', requestParameters.shop as any);
        }

        if (requestParameters.cannedJob !== undefined) {
            formParams.append('canned-job', requestParameters.cannedJob as any);
        }

        const response = await this.request({
            path: `/canned-job/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Copy a canned job
     */
    async copyCannedJob(requestParameters: CopyCannedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.copyCannedJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new entry using an existing one
     */
    async copyEntryRaw(requestParameters: CopyEntryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EntryExpanded>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling copyEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/entry/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CopyEntryRequestToJSON(requestParameters.copyEntryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntryExpandedFromJSON(jsonValue));
    }

    /**
     * Create a new entry using an existing one
     */
    async copyEntry(requestParameters: CopyEntryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EntryExpanded> {
        const response = await this.copyEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async copyPartsTechPartsToServiceRaw(requestParameters: CopyPartsTechPartsToServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling copyPartsTechPartsToService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/copy-parts-tech`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CopyPartsTechPartsToServiceRequestToJSON(requestParameters.copyPartsTechPartsToServiceRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async copyPartsTechPartsToService(requestParameters: CopyPartsTechPartsToServiceOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.copyPartsTechPartsToServiceRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new section using an existing one. All answers and media are removed
     */
    async copySectionRaw(requestParameters: CopySectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SectionExpanded>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling copySection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/section/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CopySectionRequestToJSON(requestParameters.copySectionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SectionExpandedFromJSON(jsonValue));
    }

    /**
     * Create a new section using an existing one. All answers and media are removed
     */
    async copySection(requestParameters: CopySectionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SectionExpanded> {
        const response = await this.copySectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new inspection using an existing one. All answers and media are removed
     */
    async copyToNewInspectionRaw(requestParameters: CopyToNewInspectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InspectionExpanded>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling copyToNewInspection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CopyInspectionRequestToJSON(requestParameters.copyInspectionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InspectionExpandedFromJSON(jsonValue));
    }

    /**
     * Create a new inspection using an existing one. All answers and media are removed
     */
    async copyToNewInspection(requestParameters: CopyToNewInspectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InspectionExpanded> {
        const response = await this.copyToNewInspectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new account, with a unique email address retrieved from firebase
     */
    async createAccountRaw(requestParameters: CreateAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.firebaseIdToken === null || requestParameters.firebaseIdToken === undefined) {
            throw new runtime.RequiredError('firebaseIdToken','Required parameter requestParameters.firebaseIdToken was null or undefined when calling createAccount.');
        }

        if (requestParameters.realName === null || requestParameters.realName === undefined) {
            throw new runtime.RequiredError('realName','Required parameter requestParameters.realName was null or undefined when calling createAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.firebaseIdToken !== undefined) {
            formParams.append('firebaseIdToken', requestParameters.firebaseIdToken as any);
        }

        if (requestParameters.realName !== undefined) {
            formParams.append('realName', requestParameters.realName as any);
        }

        if (requestParameters.profilePictureUrl !== undefined) {
            formParams.append('profilePictureUrl', requestParameters.profilePictureUrl as any);
        }

        if (requestParameters.userId !== undefined) {
            formParams.append('userId', requestParameters.userId as any);
        }

        const response = await this.request({
            path: `/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new account, with a unique email address retrieved from firebase
     */
    async createAccount(requestParameters: CreateAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new additional email address. Required fields: 
     */
    async createAdditionalEmailAddressRaw(requestParameters: CreateAdditionalEmailAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createAdditionalEmailAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer/additional-email-address`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerAdditionalEmailAddressInfoToJSON(requestParameters.customerAdditionalEmailAddressInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new additional email address. Required fields: 
     */
    async createAdditionalEmailAddress(requestParameters: CreateAdditionalEmailAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createAdditionalEmailAddressRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new additional phone. Required fields: 
     */
    async createAdditionalPhoneRaw(requestParameters: CreateAdditionalPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createAdditionalPhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer/additional-phone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerAdditionalPhoneInfoToJSON(requestParameters.customerAdditionalPhoneInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new additional phone. Required fields: 
     */
    async createAdditionalPhone(requestParameters: CreateAdditionalPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createAdditionalPhoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new inspection answer to canned job entry
     */
    async createAnswerToCannedJobRaw(requestParameters: CreateAnswerToCannedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createAnswerToCannedJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/answer-to-canned-job`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InspectionAnswerToCannedJobInfoToJSON(requestParameters.inspectionAnswerToCannedJobInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new inspection answer to canned job entry
     */
    async createAnswerToCannedJob(requestParameters: CreateAnswerToCannedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createAnswerToCannedJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new appointment
     */
    async createAppointmentRaw(requestParameters: CreateAppointmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAppointmentRequestToJSON(requestParameters.createAppointmentRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new appointment
     */
    async createAppointment(requestParameters: CreateAppointmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createAppointmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new appointment with verification id
     */
    async createAppointmentWithVerificationIdRaw(requestParameters: CreateAppointmentWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling createAppointmentWithVerificationId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment/verification-id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAppointmentRequestToJSON(requestParameters.createAppointmentRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new appointment with verification id
     */
    async createAppointmentWithVerificationId(requestParameters: CreateAppointmentWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createAppointmentWithVerificationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new Authorization
     */
    async createAuthorizationRaw(requestParameters: CreateAuthorizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createAuthorization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorizationInfoToJSON(requestParameters.authorizationInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new Authorization
     */
    async createAuthorization(requestParameters: CreateAuthorizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createAuthorizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an authorization based on inspection API key
     */
    async createAuthorizationByApiKeyRaw(requestParameters: CreateAuthorizationByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling createAuthorizationByApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/customer-view`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorizationInfoToJSON(requestParameters.authorizationInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create an authorization based on inspection API key
     */
    async createAuthorizationByApiKey(requestParameters: CreateAuthorizationByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createAuthorizationByApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create automated vehicle reminder entry
     */
    async createAutomatedVehicleReminderRaw(requestParameters: CreateAutomatedVehicleReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createAutomatedVehicleReminder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/automated-vehicle-reminder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AutomatedVehicleReminderInfoToJSON(requestParameters.automatedVehicleReminderInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create automated vehicle reminder entry
     */
    async createAutomatedVehicleReminder(requestParameters: CreateAutomatedVehicleReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createAutomatedVehicleReminderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new canned job
     */
    async createCannedJobRaw(requestParameters: CreateCannedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createCannedJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobInfoToJSON(requestParameters.cannedJobInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new canned job
     */
    async createCannedJob(requestParameters: CreateCannedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCannedJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createCannedJobCustomFeeRaw(requestParameters: CreateCannedJobCustomFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createCannedJobCustomFee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/custom-fee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobCustomFeeInfoToJSON(requestParameters.cannedJobCustomFeeInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async createCannedJobCustomFee(requestParameters: CreateCannedJobCustomFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCannedJobCustomFeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new custom labor for canned job
     */
    async createCannedJobCustomLaborRaw(requestParameters: CreateCannedJobCustomLaborRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createCannedJobCustomLabor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/custom-labor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobCustomLaborInfoToJSON(requestParameters.cannedJobCustomLaborInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new custom labor for canned job
     */
    async createCannedJobCustomLabor(requestParameters: CreateCannedJobCustomLaborRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCannedJobCustomLaborRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new custom part for canned job or a standalone canned part
     */
    async createCannedJobCustomPartRaw(requestParameters: CreateCannedJobCustomPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createCannedJobCustomPart.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/custom-part`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobCustomPartInfoToJSON(requestParameters.cannedJobCustomPartInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new custom part for canned job or a standalone canned part
     */
    async createCannedJobCustomPart(requestParameters: CreateCannedJobCustomPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCannedJobCustomPartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new motor fluids for canned job
     */
    async createCannedJobMotorFluidsRaw(requestParameters: CreateCannedJobMotorFluidsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createCannedJobMotorFluids.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/motor-fluids`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobMotorFluidsInfoToJSON(requestParameters.cannedJobMotorFluidsInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new motor fluids for canned job
     */
    async createCannedJobMotorFluids(requestParameters: CreateCannedJobMotorFluidsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCannedJobMotorFluidsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new motor labor for canned job
     */
    async createCannedJobMotorLaborRaw(requestParameters: CreateCannedJobMotorLaborRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createCannedJobMotorLabor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/motor-labor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobMotorLaborInfoToJSON(requestParameters.cannedJobMotorLaborInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new motor labor for canned job
     */
    async createCannedJobMotorLabor(requestParameters: CreateCannedJobMotorLaborRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCannedJobMotorLaborRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new part lookup for canned job
     */
    async createCannedJobPartLookupRaw(requestParameters: CreateCannedJobPartLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createCannedJobPartLookup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/part-lookup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobPartLookupInfoToJSON(requestParameters.cannedJobPartLookupInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new part lookup for canned job
     */
    async createCannedJobPartLookup(requestParameters: CreateCannedJobPartLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCannedJobPartLookupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create new canned job related
     */
    async createCannedJobRelatedRaw(requestParameters: CreateCannedJobRelatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createCannedJobRelated.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/canned-job-related`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobRelatedInfoToJSON(requestParameters.cannedJobRelatedInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create new canned job related
     */
    async createCannedJobRelated(requestParameters: CreateCannedJobRelatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCannedJobRelatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new canned job to inventory part
     */
    async createCannedJobToInventoryPartRaw(requestParameters: CreateCannedJobToInventoryPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createCannedJobToInventoryPart.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/inventory-part`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobInventoryPartInfoToJSON(requestParameters.cannedJobInventoryPartInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new canned job to inventory part
     */
    async createCannedJobToInventoryPart(requestParameters: CreateCannedJobToInventoryPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCannedJobToInventoryPartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new canned job to inventory product
     */
    async createCannedJobToInventoryProductRaw(requestParameters: CreateCannedJobToInventoryProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createCannedJobToInventoryProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/inventory-product`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobToInventoryProductInfoToJSON(requestParameters.cannedJobToInventoryProductInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new canned job to inventory product
     */
    async createCannedJobToInventoryProduct(requestParameters: CreateCannedJobToInventoryProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCannedJobToInventoryProductRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new catalog labor matrix for the shop
     */
    async createCatalogLaborMatrixEntryRaw(requestParameters: CreateCatalogLaborMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createCatalogLaborMatrixEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/catalog-labor-matrix`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogLaborMatrixInfoToJSON(requestParameters.catalogLaborMatrixInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new catalog labor matrix for the shop
     */
    async createCatalogLaborMatrixEntry(requestParameters: CreateCatalogLaborMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCatalogLaborMatrixEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a counter sale entry
     */
    async createCounterSaleRaw(requestParameters: CreateCounterSaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createCounterSale.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/counter-sale`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CounterSaleInfoToJSON(requestParameters.counterSaleInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a counter sale entry
     */
    async createCounterSale(requestParameters: CreateCounterSaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCounterSaleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createCustomFeeRaw(requestParameters: CreateCustomFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createCustomFee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/custom-fee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomFeeInfoToJSON(requestParameters.customFeeInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async createCustomFee(requestParameters: CreateCustomFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCustomFeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a custom message template
     */
    async createCustomMessageTemplateRaw(requestParameters: CreateCustomMessageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createCustomMessageTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/custom-message-template`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MessageTemplateInfoToJSON(requestParameters.messageTemplateInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a custom message template
     */
    async createCustomMessageTemplate(requestParameters: CreateCustomMessageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCustomMessageTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new customer. Required fields: first name, last name, shop, source, {phone || email}. Required vehicle fields: license plate, vin, make, model, year
     */
    async createCustomerRaw(requestParameters: CreateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewCustomerRequestToJSON(requestParameters.newCustomerRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new customer. Required fields: first name, last name, shop, source, {phone || email}. Required vehicle fields: license plate, vin, make, model, year
     */
    async createCustomer(requestParameters: CreateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new discount entry for a shop
     */
    async createDiscountRaw(requestParameters: CreateDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createDiscount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/discount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DiscountInfoToJSON(requestParameters.discountInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new discount entry for a shop
     */
    async createDiscount(requestParameters: CreateDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createDiscountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new link to an embedded dashboard (via Metabase)
     */
    async createEmbeddedDashboardLinkRaw(requestParameters: CreateEmbeddedDashboardLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createEmbeddedDashboardLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/bi/embedded-dashboard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmbeddedDashboardRequestToJSON(requestParameters.embeddedDashboardRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new link to an embedded dashboard (via Metabase)
     */
    async createEmbeddedDashboardLink(requestParameters: CreateEmbeddedDashboardLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createEmbeddedDashboardLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Collect payment via Finix
     */
    async createFinixBuyerRaw(requestParameters: CreateFinixBuyerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createFinixBuyer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/payment/finix/buyer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerInformationToJSON(requestParameters.customerInformation),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Collect payment via Finix
     */
    async createFinixBuyer(requestParameters: CreateFinixBuyerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createFinixBuyerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new blank inspection entry
     */
    async createInspectionEntryRaw(requestParameters: CreateInspectionEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createInspectionEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/entry`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntryInfoToJSON(requestParameters.entryInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new blank inspection entry
     */
    async createInspectionEntry(requestParameters: CreateInspectionEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createInspectionEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add new message for an inspection entry
     */
    async createInspectionEntryMessageRaw(requestParameters: CreateInspectionEntryMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createInspectionEntryMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection-entry-messages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InspectionConversationMessageInfoToJSON(requestParameters.inspectionConversationMessageInfo),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add new message for an inspection entry
     */
    async createInspectionEntryMessage(requestParameters: CreateInspectionEntryMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createInspectionEntryMessageRaw(requestParameters, initOverrides);
    }

    /**
     * Add new inspection entry to service association
     */
    async createInspectionEntryServiceAssociationRaw(requestParameters: CreateInspectionEntryServiceAssociationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createInspectionEntryServiceAssociation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection-entry-service`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InspectionEntryServiceEntryToJSON(requestParameters.inspectionEntryServiceEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add new inspection entry to service association
     */
    async createInspectionEntryServiceAssociation(requestParameters: CreateInspectionEntryServiceAssociationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createInspectionEntryServiceAssociationRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new entry for the shop\'s inspection entry status
     */
    async createInspectionEntryStatusRaw(requestParameters: CreateInspectionEntryStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createInspectionEntryStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/inspection-entry-status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InspectionEntryStatusInfoToJSON(requestParameters.inspectionEntryStatusInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new entry for the shop\'s inspection entry status
     */
    async createInspectionEntryStatus(requestParameters: CreateInspectionEntryStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createInspectionEntryStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new inspection prompt
     */
    async createInspectionPromptRaw(requestParameters: CreateInspectionPromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createInspectionPrompt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/prompt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PromptInfoToJSON(requestParameters.promptInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new inspection prompt
     */
    async createInspectionPrompt(requestParameters: CreateInspectionPromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createInspectionPromptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new blank inspection section
     */
    async createInspectionSectionRaw(requestParameters: CreateInspectionSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createInspectionSection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/section`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SectionInfoToJSON(requestParameters.sectionInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new blank inspection section
     */
    async createInspectionSection(requestParameters: CreateInspectionSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createInspectionSectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add new message for an inspection section
     */
    async createInspectionSectionMessageRaw(requestParameters: CreateInspectionSectionMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createInspectionSectionMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection-section-messages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InspectionSectionConversationMessageInfoToJSON(requestParameters.inspectionSectionConversationMessageInfo),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add new message for an inspection section
     */
    async createInspectionSectionMessage(requestParameters: CreateInspectionSectionMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createInspectionSectionMessageRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new blank inspection template
     */
    async createInspectionTemplateRaw(requestParameters: CreateInspectionTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InspectionEntry>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createInspectionTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InspectionInfoToJSON(requestParameters.inspectionInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InspectionEntryFromJSON(jsonValue));
    }

    /**
     * Create a new blank inspection template
     */
    async createInspectionTemplate(requestParameters: CreateInspectionTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InspectionEntry> {
        const response = await this.createInspectionTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create new customer intake request without verification id
     */
    async createIntakeRequestRaw(requestParameters: CreateIntakeRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/intake/intake`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCustomerIntakeRequestObjectToJSON(requestParameters.createCustomerIntakeRequestObject),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create new customer intake request without verification id
     */
    async createIntakeRequest(requestParameters: CreateIntakeRequestRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createIntakeRequestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create new customer intake request
     */
    async createIntakeRequestWithVerificationIdRaw(requestParameters: CreateIntakeRequestWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling createIntakeRequestWithVerificationId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/intake`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCustomerIntakeRequestObjectToJSON(requestParameters.createCustomerIntakeRequestObject),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create new customer intake request
     */
    async createIntakeRequestWithVerificationId(requestParameters: CreateIntakeRequestWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createIntakeRequestWithVerificationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create inventory adjustment.
     */
    async createInventoryAdjustmentRaw(requestParameters: CreateInventoryAdjustmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createInventoryAdjustment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/adjustment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InventoryAdjustmentInfoToJSON(requestParameters.inventoryAdjustmentInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create inventory adjustment.
     */
    async createInventoryAdjustment(requestParameters: CreateInventoryAdjustmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createInventoryAdjustmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create inventory adjustment document.
     */
    async createInventoryAdjustmentDocumentRaw(requestParameters: CreateInventoryAdjustmentDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createInventoryAdjustmentDocument.');
        }

        if (requestParameters.inventoryAdjustment === null || requestParameters.inventoryAdjustment === undefined) {
            throw new runtime.RequiredError('inventoryAdjustment','Required parameter requestParameters.inventoryAdjustment was null or undefined when calling createInventoryAdjustmentDocument.');
        }

        if (requestParameters.rank === null || requestParameters.rank === undefined) {
            throw new runtime.RequiredError('rank','Required parameter requestParameters.rank was null or undefined when calling createInventoryAdjustmentDocument.');
        }

        if (requestParameters.description === null || requestParameters.description === undefined) {
            throw new runtime.RequiredError('description','Required parameter requestParameters.description was null or undefined when calling createInventoryAdjustmentDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.inventoryAdjustment !== undefined) {
            formParams.append('inventory-adjustment', requestParameters.inventoryAdjustment as any);
        }

        if (requestParameters.rank !== undefined) {
            formParams.append('rank', requestParameters.rank as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/part/inventory/adjustment/document`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create inventory adjustment document.
     */
    async createInventoryAdjustmentDocument(requestParameters: CreateInventoryAdjustmentDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createInventoryAdjustmentDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new instance for the shop\'s inventory
     */
    async createInventoryInstanceRaw(requestParameters: CreateInventoryInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createInventoryInstance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/instance`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InventoryPartInstanceInfoToJSON(requestParameters.inventoryPartInstanceInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new instance for the shop\'s inventory
     */
    async createInventoryInstance(requestParameters: CreateInventoryInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createInventoryInstanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create inventory instance with an adjustment
     */
    async createInventoryInstancesWithAdjustmentRaw(requestParameters: CreateInventoryInstancesWithAdjustmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateInventoryInstancesWithAdjustmentResponse>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createInventoryInstancesWithAdjustment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/instance/adjustment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateInventoryInstancesWithAdjustmentRequestToJSON(requestParameters.createInventoryInstancesWithAdjustmentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateInventoryInstancesWithAdjustmentResponseFromJSON(jsonValue));
    }

    /**
     * Create inventory instance with an adjustment
     */
    async createInventoryInstancesWithAdjustment(requestParameters: CreateInventoryInstancesWithAdjustmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateInventoryInstancesWithAdjustmentResponse> {
        const response = await this.createInventoryInstancesWithAdjustmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new part for the shop\'s inventory
     */
    async createInventoryPartRaw(requestParameters: CreateInventoryPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createInventoryPart.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InventoryPartInfoToJSON(requestParameters.inventoryPartInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new part for the shop\'s inventory
     */
    async createInventoryPart(requestParameters: CreateInventoryPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createInventoryPartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new entry for the shop\'s labor matrix
     */
    async createLaborMatrixEntryRaw(requestParameters: CreateLaborMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createLaborMatrixEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/labor-matrix`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LaborMatrixInfoToJSON(requestParameters.laborMatrixInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new entry for the shop\'s labor matrix
     */
    async createLaborMatrixEntry(requestParameters: CreateLaborMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createLaborMatrixEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createLaborOverridesRaw(requestParameters: CreateLaborOverridesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createLaborOverrides.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/labor-overrides`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLaborOverridesRequestToJSON(requestParameters.createLaborOverridesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async createLaborOverrides(requestParameters: CreateLaborOverridesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.createLaborOverridesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a new media item for an inspection entry or a prompt or an answer
     */
    async createMediaEntryForInspectionEntryRaw(requestParameters: CreateMediaEntryForInspectionEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InspectionMediaEntry>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createMediaEntryForInspectionEntry.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createMediaEntryForInspectionEntry.');
        }

        if (requestParameters.rank === null || requestParameters.rank === undefined) {
            throw new runtime.RequiredError('rank','Required parameter requestParameters.rank was null or undefined when calling createMediaEntryForInspectionEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.entry !== undefined) {
            formParams.append('entry', requestParameters.entry as any);
        }

        if (requestParameters.prompt !== undefined) {
            formParams.append('prompt', requestParameters.prompt as any);
        }

        if (requestParameters.answer !== undefined) {
            formParams.append('answer', requestParameters.answer as any);
        }

        if (requestParameters.id !== undefined) {
            formParams.append('id', requestParameters.id as any);
        }

        if (requestParameters.rank !== undefined) {
            formParams.append('rank', requestParameters.rank as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/dvi/inspection/media-entry`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InspectionMediaEntryFromJSON(jsonValue));
    }

    /**
     * Upload a new media item for an inspection entry or a prompt or an answer
     */
    async createMediaEntryForInspectionEntry(requestParameters: CreateMediaEntryForInspectionEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InspectionMediaEntry> {
        const response = await this.createMediaEntryForInspectionEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a new media item for an inspection entry or an answer
     */
    async createMediaForInspectionEntryRaw(requestParameters: CreateMediaForInspectionEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createMediaForInspectionEntry.');
        }

        if (requestParameters.rank === null || requestParameters.rank === undefined) {
            throw new runtime.RequiredError('rank','Required parameter requestParameters.rank was null or undefined when calling createMediaForInspectionEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.entry !== undefined) {
            formParams.append('entry', requestParameters.entry as any);
        }

        if (requestParameters.prompt !== undefined) {
            formParams.append('prompt', requestParameters.prompt as any);
        }

        if (requestParameters.answer !== undefined) {
            formParams.append('answer', requestParameters.answer as any);
        }

        if (requestParameters.rank !== undefined) {
            formParams.append('rank', requestParameters.rank as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/dvi/inspection/media`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Upload a new media item for an inspection entry or an answer
     */
    async createMediaForInspectionEntry(requestParameters: CreateMediaForInspectionEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createMediaForInspectionEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new inspection prompt multiple choice answer
     */
    async createMultipleChoiceAnswerRaw(requestParameters: CreateMultipleChoiceAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createMultipleChoiceAnswer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/prompt/multiple-choice-answer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MultipleChoiceAnswerInfoToJSON(requestParameters.multipleChoiceAnswerInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new inspection prompt multiple choice answer
     */
    async createMultipleChoiceAnswer(requestParameters: CreateMultipleChoiceAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createMultipleChoiceAnswerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new one-time reminder
     */
    async createOneTimeReminderRaw(requestParameters: CreateOneTimeReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createOneTimeReminder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/reminder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OneTimeReminderInfoToJSON(requestParameters.oneTimeReminderInfo),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new one-time reminder
     */
    async createOneTimeReminder(requestParameters: CreateOneTimeReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createOneTimeReminderRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createPartOverrideRaw(requestParameters: CreatePartOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createPartOverride.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/parts-override`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PartOverrideToJSON(requestParameters.partOverride),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createPartOverride(requestParameters: CreatePartOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createPartOverrideRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new PartStatus entry
     */
    async createPartStatusRaw(requestParameters: CreatePartStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createPartStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PartStatusInfoToJSON(requestParameters.partStatusInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new PartStatus entry
     */
    async createPartStatus(requestParameters: CreatePartStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createPartStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new entry for the shop\'s parts matrix
     */
    async createPartsMatrixEntryRaw(requestParameters: CreatePartsMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createPartsMatrixEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/parts-matrix`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PartsMatrixInfoToJSON(requestParameters.partsMatrixInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new entry for the shop\'s parts matrix
     */
    async createPartsMatrixEntry(requestParameters: CreatePartsMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createPartsMatrixEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new PartsTech quote/cart as part of a service item. Returns a redirect URL. Any selected parts will be appended to other parts currently in the service
     */
    async createPartsTechQuoteRaw(requestParameters: CreatePartsTechQuoteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PunchoutInfo>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createPartsTechQuote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/parts-tech`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePartsTechQuoteRequestToJSON(requestParameters.createPartsTechQuoteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PunchoutInfoFromJSON(jsonValue));
    }

    /**
     * Create a new PartsTech quote/cart as part of a service item. Returns a redirect URL. Any selected parts will be appended to other parts currently in the service
     */
    async createPartsTechQuote(requestParameters: CreatePartsTechQuoteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PunchoutInfo> {
        const response = await this.createPartsTechQuoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new repair-payment entry
     */
    async createPaymentRaw(requestParameters: CreatePaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/payment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RepairPaymentInfoToJSON(requestParameters.repairPaymentInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new repair-payment entry
     */
    async createPayment(requestParameters: CreatePaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create price level
     */
    async createPriceLevelRaw(requestParameters: CreatePriceLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createPriceLevel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/price-level`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PriceLevelInfoToJSON(requestParameters.priceLevelInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create price level
     */
    async createPriceLevel(requestParameters: CreatePriceLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createPriceLevelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create new record of an printable document print action, which a user can refer to in order to set the date to be printed on an printable document
     */
    async createPrintActionHistoryRecordRaw(requestParameters: CreatePrintActionHistoryRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createPrintActionHistoryRecord.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/print-action`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrintActionInfoToJSON(requestParameters.printActionInfo),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create new record of an printable document print action, which a user can refer to in order to set the date to be printed on an printable document
     */
    async createPrintActionHistoryRecord(requestParameters: CreatePrintActionHistoryRecordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createPrintActionHistoryRecordRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new entry for the shop\'s productivity status
     */
    async createProductivityStatusRaw(requestParameters: CreateProductivityStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createProductivityStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/productivity-status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductivityStatusInfoToJSON(requestParameters.productivityStatusInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new entry for the shop\'s productivity status
     */
    async createProductivityStatus(requestParameters: CreateProductivityStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createProductivityStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new purchase order
     */
    async createPurchaseOrderRaw(requestParameters: CreatePurchaseOrderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createPurchaseOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchase-order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePurchaseOrderRequestToJSON(requestParameters.createPurchaseOrderRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new purchase order
     */
    async createPurchaseOrder(requestParameters: CreatePurchaseOrderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createPurchaseOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create notification
     */
    async createPushNotificationRaw(requestParameters: CreatePushNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NotificationEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createPushNotification.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationRequestToJSON(requestParameters.notificationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationEntryFromJSON));
    }

    /**
     * Create notification
     */
    async createPushNotification(requestParameters: CreatePushNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NotificationEntry>> {
        const response = await this.createPushNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create push subscription or update push subscription if the same endpoint exists
     */
    async createPushSubscriptionRaw(requestParameters: CreatePushSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createPushSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/subscription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PushSubscriptionInfoToJSON(requestParameters.pushSubscriptionInfo),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create push subscription or update push subscription if the same endpoint exists
     */
    async createPushSubscription(requestParameters: CreatePushSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createPushSubscriptionRaw(requestParameters, initOverrides);
    }

    /**
     * Create a new repair order
     */
    async createRepairOrderRaw(requestParameters: CreateRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RepairOrderEntry>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createRepairOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RepairOrderInfoToJSON(requestParameters.repairOrderInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairOrderEntryFromJSON(jsonValue));
    }

    /**
     * Create a new repair order
     */
    async createRepairOrder(requestParameters: CreateRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RepairOrderEntry> {
        const response = await this.createRepairOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a new attachment for a repair order
     */
    async createRepairOrderAttachmentEntryRaw(requestParameters: CreateRepairOrderAttachmentEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createRepairOrderAttachmentEntry.');
        }

        if (requestParameters.repairOrder === null || requestParameters.repairOrder === undefined) {
            throw new runtime.RequiredError('repairOrder','Required parameter requestParameters.repairOrder was null or undefined when calling createRepairOrderAttachmentEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.repairOrder !== undefined) {
            formParams.append('repair-order', requestParameters.repairOrder as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/invoice/repair-order/attachment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Upload a new attachment for a repair order
     */
    async createRepairOrderAttachmentEntry(requestParameters: CreateRepairOrderAttachmentEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createRepairOrderAttachmentEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a new media item for a repair order media entry
     */
    async createRepairOrderMediaEntryRaw(requestParameters: CreateRepairOrderMediaEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createRepairOrderMediaEntry.');
        }

        if (requestParameters.repairOrder === null || requestParameters.repairOrder === undefined) {
            throw new runtime.RequiredError('repairOrder','Required parameter requestParameters.repairOrder was null or undefined when calling createRepairOrderMediaEntry.');
        }

        if (requestParameters.rank === null || requestParameters.rank === undefined) {
            throw new runtime.RequiredError('rank','Required parameter requestParameters.rank was null or undefined when calling createRepairOrderMediaEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.repairOrder !== undefined) {
            formParams.append('repair-order', requestParameters.repairOrder as any);
        }

        if (requestParameters.title !== undefined) {
            formParams.append('title', requestParameters.title as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.rank !== undefined) {
            formParams.append('rank', requestParameters.rank as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/invoice/repair-order/media`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Upload a new media item for a repair order media entry
     */
    async createRepairOrderMediaEntry(requestParameters: CreateRepairOrderMediaEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createRepairOrderMediaEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new repair-order status
     */
    async createRepairOrderStatusRaw(requestParameters: CreateRepairOrderStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createRepairOrderStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/repair-order-status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RepairOrderStatusEntryToJSON(requestParameters.repairOrderStatusEntry),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new repair-order status
     */
    async createRepairOrderStatus(requestParameters: CreateRepairOrderStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createRepairOrderStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new resource
     */
    async createResourceRaw(requestParameters: CreateResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createResource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/resource`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceInfoToJSON(requestParameters.resourceInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new resource
     */
    async createResource(requestParameters: CreateResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createResourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a role with no permissions for a specific shop
     */
    async createRoleRaw(requestParameters: CreateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/role`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleToJSON(requestParameters.role),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a role with no permissions for a specific shop
     */
    async createRole(requestParameters: CreateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a service from a canned job
     */
    async createServiceFromCannedJobRaw(requestParameters: CreateServiceFromCannedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createServiceFromCannedJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/service`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCannedJobServiceToJSON(requestParameters.createCannedJobService),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a service from a canned job
     */
    async createServiceFromCannedJob(requestParameters: CreateServiceFromCannedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createServiceFromCannedJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new service as part of a repair order. Will not contain any labor items
     */
    async createServiceStandaloneRaw(requestParameters: CreateServiceStandaloneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createServiceStandalone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/standalone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceInfoToJSON(requestParameters.serviceInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new service as part of a repair order. Will not contain any labor items
     */
    async createServiceStandalone(requestParameters: CreateServiceStandaloneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createServiceStandaloneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new shop
     */
    async createShopRaw(requestParameters: CreateShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createShop.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShopInfoRequestToJSON(requestParameters.shopInfoRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new shop
     */
    async createShop(requestParameters: CreateShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createShopRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add a feature access to a shop
     */
    async createShopFeatureRaw(requestParameters: CreateShopFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createShopFeature.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/features`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShopFeatureInfoToJSON(requestParameters.shopFeatureInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Add a feature access to a shop
     */
    async createShopFeature(requestParameters: CreateShopFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createShopFeatureRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new shop vendor
     */
    async createShopVendorRaw(requestParameters: CreateShopVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createShopVendor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/vendor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VendorInfoToJSON(requestParameters.vendorInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new shop vendor
     */
    async createShopVendor(requestParameters: CreateShopVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createShopVendorRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an API key that can be used to view inspection results
     */
    async createStandaloneApiKeyForCustomerRaw(requestParameters: CreateStandaloneApiKeyForCustomerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createStandaloneApiKeyForCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/api-key`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateStandaloneApiKeyForCustomerRequestToJSON(requestParameters.createStandaloneApiKeyForCustomerRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create an API key that can be used to view inspection results
     */
    async createStandaloneApiKeyForCustomer(requestParameters: CreateStandaloneApiKeyForCustomerOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createStandaloneApiKeyForCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new sublet
     */
    async createSubletRaw(requestParameters: CreateSubletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createSublet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/sublet`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubletInfoToJSON(requestParameters.subletInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new sublet
     */
    async createSublet(requestParameters: CreateSubletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createSubletRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a time clock entry
     */
    async createTimeClockRaw(requestParameters: CreateTimeClockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createTimeClock.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/time-clock`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TimeClockInfoToJSON(requestParameters.timeClockInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a time clock entry
     */
    async createTimeClock(requestParameters: CreateTimeClockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createTimeClockRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new vehicle entity
     */
    async createVehicleRaw(requestParameters: CreateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleEntry>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createVehicle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewVehicleRequestToJSON(requestParameters.newVehicleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleEntryFromJSON(jsonValue));
    }

    /**
     * Create a new vehicle entity
     */
    async createVehicle(requestParameters: CreateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleEntry> {
        const response = await this.createVehicleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create vehicle fluids
     */
    async createVehicleFluidsRaw(requestParameters: CreateVehicleFluidsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createVehicleFluids.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/fluids`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleFluidsInfoToJSON(requestParameters.vehicleFluidsInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create vehicle fluids
     */
    async createVehicleFluids(requestParameters: CreateVehicleFluidsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createVehicleFluidsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new image for a vehicle
     */
    async createVehicleImageRaw(requestParameters: CreateVehicleImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createVehicleImage.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling createVehicleImage.');
        }

        if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
            throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling createVehicleImage.');
        }

        if (requestParameters.isPrimary === null || requestParameters.isPrimary === undefined) {
            throw new runtime.RequiredError('isPrimary','Required parameter requestParameters.isPrimary was null or undefined when calling createVehicleImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.shop !== undefined) {
            formParams.append('shop', requestParameters.shop as any);
        }

        if (requestParameters.vehicle !== undefined) {
            formParams.append('vehicle', requestParameters.vehicle as any);
        }

        if (requestParameters.isPrimary !== undefined) {
            formParams.append('is-primary', requestParameters.isPrimary as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/vehicle/images`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new image for a vehicle
     */
    async createVehicleImage(requestParameters: CreateVehicleImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createVehicleImageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new vehicle entity without authorization
     */
    async createVehicleIntakeRaw(requestParameters: CreateVehicleIntakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleEntry>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/intake`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewVehicleRequestToJSON(requestParameters.newVehicleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleEntryFromJSON(jsonValue));
    }

    /**
     * Create a new vehicle entity without authorization
     */
    async createVehicleIntake(requestParameters: CreateVehicleIntakeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleEntry> {
        const response = await this.createVehicleIntakeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create vehicle oil filter
     */
    async createVehicleOilFilterRaw(requestParameters: CreateVehicleOilFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createVehicleOilFilter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/oil-filter`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleOilFilterInfoToJSON(requestParameters.vehicleOilFilterInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create vehicle oil filter
     */
    async createVehicleOilFilter(requestParameters: CreateVehicleOilFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createVehicleOilFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create vehicle reminder message entry
     */
    async createVehicleReminderMessageRaw(requestParameters: CreateVehicleReminderMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createVehicleReminderMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/vehicle-reminder-message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleReminderMessageInfoToJSON(requestParameters.vehicleReminderMessageInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create vehicle reminder message entry
     */
    async createVehicleReminderMessage(requestParameters: CreateVehicleReminderMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createVehicleReminderMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new vehicle entity with verification id
     */
    async createVehicleWithVerificationIdRaw(requestParameters: CreateVehicleWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleEntry>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling createVehicleWithVerificationId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/verification-id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewVehicleRequestToJSON(requestParameters.newVehicleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleEntryFromJSON(jsonValue));
    }

    /**
     * Create a new vehicle entity with verification id
     */
    async createVehicleWithVerificationId(requestParameters: CreateVehicleWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleEntry> {
        const response = await this.createVehicleWithVerificationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new verification
     */
    async createVerificationRaw(requestParameters: CreateVerificationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/verification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateVerificationRequestToJSON(requestParameters.createVerificationRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new verification
     */
    async createVerification(requestParameters: CreateVerificationOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createVerificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new warranty entry
     */
    async createWarrantyEntryRaw(requestParameters: CreateWarrantyEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling createWarrantyEntry.');
        }

        if (requestParameters.warrantyInfo === null || requestParameters.warrantyInfo === undefined) {
            throw new runtime.RequiredError('warrantyInfo','Required parameter requestParameters.warrantyInfo was null or undefined when calling createWarrantyEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/warranty`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WarrantyInfoToJSON(requestParameters.warrantyInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create a new warranty entry
     */
    async createWarrantyEntry(requestParameters: CreateWarrantyEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createWarrantyEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete additional email addresses for a customer
     */
    async deleteAdditionalEmailAddressRaw(requestParameters: DeleteAdditionalEmailAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteAdditionalEmailAddress.');
        }

        if (requestParameters.customer === null || requestParameters.customer === undefined) {
            throw new runtime.RequiredError('customer','Required parameter requestParameters.customer was null or undefined when calling deleteAdditionalEmailAddress.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAdditionalEmailAddress.');
        }

        const queryParameters: any = {};

        if (requestParameters.customer !== undefined) {
            queryParameters['customer'] = requestParameters.customer;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer/additional-email-address`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete additional email addresses for a customer
     */
    async deleteAdditionalEmailAddress(requestParameters: DeleteAdditionalEmailAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAdditionalEmailAddressRaw(requestParameters, initOverrides);
    }

    /**
     * Delete additional phone numbers for a customer
     */
    async deleteAdditionalPhoneRaw(requestParameters: DeleteAdditionalPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteAdditionalPhone.');
        }

        if (requestParameters.customer === null || requestParameters.customer === undefined) {
            throw new runtime.RequiredError('customer','Required parameter requestParameters.customer was null or undefined when calling deleteAdditionalPhone.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAdditionalPhone.');
        }

        const queryParameters: any = {};

        if (requestParameters.customer !== undefined) {
            queryParameters['customer'] = requestParameters.customer;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer/additional-phone`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete additional phone numbers for a customer
     */
    async deleteAdditionalPhone(requestParameters: DeleteAdditionalPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAdditionalPhoneRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an existing inspection answer to canned job
     */
    async deleteAnswerToCannedJobEntryRaw(requestParameters: DeleteAnswerToCannedJobEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteAnswerToCannedJobEntry.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAnswerToCannedJobEntry.');
        }

        if (requestParameters.answer === null || requestParameters.answer === undefined) {
            throw new runtime.RequiredError('answer','Required parameter requestParameters.answer was null or undefined when calling deleteAnswerToCannedJobEntry.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.answer !== undefined) {
            queryParameters['answer'] = requestParameters.answer;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/answer-to-canned-job`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing inspection answer to canned job
     */
    async deleteAnswerToCannedJobEntry(requestParameters: DeleteAnswerToCannedJobEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAnswerToCannedJobEntryRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteCannedJobCustomFeeRaw(requestParameters: DeleteCannedJobCustomFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteCannedJobCustomFee.');
        }

        if (requestParameters.cannedJob === null || requestParameters.cannedJob === undefined) {
            throw new runtime.RequiredError('cannedJob','Required parameter requestParameters.cannedJob was null or undefined when calling deleteCannedJobCustomFee.');
        }

        if (requestParameters.cannedJobFee === null || requestParameters.cannedJobFee === undefined) {
            throw new runtime.RequiredError('cannedJobFee','Required parameter requestParameters.cannedJobFee was null or undefined when calling deleteCannedJobCustomFee.');
        }

        const queryParameters: any = {};

        if (requestParameters.cannedJob !== undefined) {
            queryParameters['canned-job'] = requestParameters.cannedJob;
        }

        if (requestParameters.cannedJobFee !== undefined) {
            queryParameters['canned-job-fee'] = requestParameters.cannedJobFee;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/custom-fee`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCannedJobCustomFee(requestParameters: DeleteCannedJobCustomFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCannedJobCustomFeeRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a custom labor entry
     */
    async deleteCannedJobCustomLaborRaw(requestParameters: DeleteCannedJobCustomLaborRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteCannedJobCustomLabor.');
        }

        if (requestParameters.cannedJob === null || requestParameters.cannedJob === undefined) {
            throw new runtime.RequiredError('cannedJob','Required parameter requestParameters.cannedJob was null or undefined when calling deleteCannedJobCustomLabor.');
        }

        if (requestParameters.customLabor === null || requestParameters.customLabor === undefined) {
            throw new runtime.RequiredError('customLabor','Required parameter requestParameters.customLabor was null or undefined when calling deleteCannedJobCustomLabor.');
        }

        const queryParameters: any = {};

        if (requestParameters.cannedJob !== undefined) {
            queryParameters['canned-job'] = requestParameters.cannedJob;
        }

        if (requestParameters.customLabor !== undefined) {
            queryParameters['custom-labor'] = requestParameters.customLabor;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/custom-labor`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a custom labor entry
     */
    async deleteCannedJobCustomLabor(requestParameters: DeleteCannedJobCustomLaborRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCannedJobCustomLaborRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a custom part entry
     */
    async deleteCannedJobCustomPartRaw(requestParameters: DeleteCannedJobCustomPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteCannedJobCustomPart.');
        }

        if (requestParameters.customPart === null || requestParameters.customPart === undefined) {
            throw new runtime.RequiredError('customPart','Required parameter requestParameters.customPart was null or undefined when calling deleteCannedJobCustomPart.');
        }

        const queryParameters: any = {};

        if (requestParameters.cannedJob !== undefined) {
            queryParameters['canned-job'] = requestParameters.cannedJob;
        }

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.counterSale !== undefined) {
            queryParameters['counter-sale'] = requestParameters.counterSale;
        }

        if (requestParameters.customPart !== undefined) {
            queryParameters['custom-part'] = requestParameters.customPart;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/custom-part`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a custom part entry
     */
    async deleteCannedJobCustomPart(requestParameters: DeleteCannedJobCustomPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCannedJobCustomPartRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a motor fluids entry
     */
    async deleteCannedJobMotorFluidsRaw(requestParameters: DeleteCannedJobMotorFluidsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteCannedJobMotorFluids.');
        }

        if (requestParameters.cannedJob === null || requestParameters.cannedJob === undefined) {
            throw new runtime.RequiredError('cannedJob','Required parameter requestParameters.cannedJob was null or undefined when calling deleteCannedJobMotorFluids.');
        }

        if (requestParameters.motorFluids === null || requestParameters.motorFluids === undefined) {
            throw new runtime.RequiredError('motorFluids','Required parameter requestParameters.motorFluids was null or undefined when calling deleteCannedJobMotorFluids.');
        }

        const queryParameters: any = {};

        if (requestParameters.cannedJob !== undefined) {
            queryParameters['canned-job'] = requestParameters.cannedJob;
        }

        if (requestParameters.motorFluids !== undefined) {
            queryParameters['motor-fluids'] = requestParameters.motorFluids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/motor-fluids`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a motor fluids entry
     */
    async deleteCannedJobMotorFluids(requestParameters: DeleteCannedJobMotorFluidsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCannedJobMotorFluidsRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a motor labor entry
     */
    async deleteCannedJobMotorLaborRaw(requestParameters: DeleteCannedJobMotorLaborRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteCannedJobMotorLabor.');
        }

        if (requestParameters.cannedJob === null || requestParameters.cannedJob === undefined) {
            throw new runtime.RequiredError('cannedJob','Required parameter requestParameters.cannedJob was null or undefined when calling deleteCannedJobMotorLabor.');
        }

        if (requestParameters.motorLabor === null || requestParameters.motorLabor === undefined) {
            throw new runtime.RequiredError('motorLabor','Required parameter requestParameters.motorLabor was null or undefined when calling deleteCannedJobMotorLabor.');
        }

        const queryParameters: any = {};

        if (requestParameters.cannedJob !== undefined) {
            queryParameters['canned-job'] = requestParameters.cannedJob;
        }

        if (requestParameters.motorLabor !== undefined) {
            queryParameters['motor-labor'] = requestParameters.motorLabor;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/motor-labor`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a motor labor entry
     */
    async deleteCannedJobMotorLabor(requestParameters: DeleteCannedJobMotorLaborRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCannedJobMotorLaborRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a part lookup entry
     */
    async deleteCannedJobPartLookupRaw(requestParameters: DeleteCannedJobPartLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteCannedJobPartLookup.');
        }

        if (requestParameters.cannedJob === null || requestParameters.cannedJob === undefined) {
            throw new runtime.RequiredError('cannedJob','Required parameter requestParameters.cannedJob was null or undefined when calling deleteCannedJobPartLookup.');
        }

        if (requestParameters.partLookup === null || requestParameters.partLookup === undefined) {
            throw new runtime.RequiredError('partLookup','Required parameter requestParameters.partLookup was null or undefined when calling deleteCannedJobPartLookup.');
        }

        const queryParameters: any = {};

        if (requestParameters.cannedJob !== undefined) {
            queryParameters['canned-job'] = requestParameters.cannedJob;
        }

        if (requestParameters.partLookup !== undefined) {
            queryParameters['part-lookup'] = requestParameters.partLookup;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/part-lookup`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a part lookup entry
     */
    async deleteCannedJobPartLookup(requestParameters: DeleteCannedJobPartLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCannedJobPartLookupRaw(requestParameters, initOverrides);
    }

    /**
     * Delete canned job related
     */
    async deleteCannedJobRelatedRaw(requestParameters: DeleteCannedJobRelatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteCannedJobRelated.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling deleteCannedJobRelated.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCannedJobRelated.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shopId'] = requestParameters.shopId;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/canned-job-related`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete canned job related
     */
    async deleteCannedJobRelated(requestParameters: DeleteCannedJobRelatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCannedJobRelatedRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a canned job to inventory part
     */
    async deleteCannedJobToInventoryPartRaw(requestParameters: DeleteCannedJobToInventoryPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteCannedJobToInventoryPart.');
        }

        if (requestParameters.cannedJob === null || requestParameters.cannedJob === undefined) {
            throw new runtime.RequiredError('cannedJob','Required parameter requestParameters.cannedJob was null or undefined when calling deleteCannedJobToInventoryPart.');
        }

        if (requestParameters.inventoryPart === null || requestParameters.inventoryPart === undefined) {
            throw new runtime.RequiredError('inventoryPart','Required parameter requestParameters.inventoryPart was null or undefined when calling deleteCannedJobToInventoryPart.');
        }

        const queryParameters: any = {};

        if (requestParameters.cannedJob !== undefined) {
            queryParameters['canned-job'] = requestParameters.cannedJob;
        }

        if (requestParameters.inventoryPart !== undefined) {
            queryParameters['inventory-part'] = requestParameters.inventoryPart;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/inventory-part`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a canned job to inventory part
     */
    async deleteCannedJobToInventoryPart(requestParameters: DeleteCannedJobToInventoryPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCannedJobToInventoryPartRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a canned job to inventory product
     */
    async deleteCannedJobToInventoryProductRaw(requestParameters: DeleteCannedJobToInventoryProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteCannedJobToInventoryProduct.');
        }

        if (requestParameters.cannedJob === null || requestParameters.cannedJob === undefined) {
            throw new runtime.RequiredError('cannedJob','Required parameter requestParameters.cannedJob was null or undefined when calling deleteCannedJobToInventoryProduct.');
        }

        if (requestParameters.inventoryProduct === null || requestParameters.inventoryProduct === undefined) {
            throw new runtime.RequiredError('inventoryProduct','Required parameter requestParameters.inventoryProduct was null or undefined when calling deleteCannedJobToInventoryProduct.');
        }

        const queryParameters: any = {};

        if (requestParameters.cannedJob !== undefined) {
            queryParameters['canned-job'] = requestParameters.cannedJob;
        }

        if (requestParameters.inventoryProduct !== undefined) {
            queryParameters['inventory-product'] = requestParameters.inventoryProduct;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/inventory-product`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a canned job to inventory product
     */
    async deleteCannedJobToInventoryProduct(requestParameters: DeleteCannedJobToInventoryProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCannedJobToInventoryProductRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an existing catalog labor matrix entry
     */
    async deleteCatalogLaborMatrixEntryRaw(requestParameters: DeleteCatalogLaborMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Date>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteCatalogLaborMatrixEntry.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling deleteCatalogLaborMatrixEntry.');
        }

        if (requestParameters.catalogLaborMatrixEntry === null || requestParameters.catalogLaborMatrixEntry === undefined) {
            throw new runtime.RequiredError('catalogLaborMatrixEntry','Required parameter requestParameters.catalogLaborMatrixEntry was null or undefined when calling deleteCatalogLaborMatrixEntry.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.catalogLaborMatrixEntry !== undefined) {
            queryParameters['catalogLaborMatrixEntry'] = requestParameters.catalogLaborMatrixEntry;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/catalog-labor-matrix`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<Date>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete an existing catalog labor matrix entry
     */
    async deleteCatalogLaborMatrixEntry(requestParameters: DeleteCatalogLaborMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Date> {
        const response = await this.deleteCatalogLaborMatrixEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteCustomFeeRaw(requestParameters: DeleteCustomFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteCustomFee.');
        }

        if (requestParameters.customFee === null || requestParameters.customFee === undefined) {
            throw new runtime.RequiredError('customFee','Required parameter requestParameters.customFee was null or undefined when calling deleteCustomFee.');
        }

        const queryParameters: any = {};

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        if (requestParameters.counterSale !== undefined) {
            queryParameters['counter-sale'] = requestParameters.counterSale;
        }

        if (requestParameters.customFee !== undefined) {
            queryParameters['custom-fee'] = requestParameters.customFee;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/custom-fee`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCustomFee(requestParameters: DeleteCustomFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCustomFeeRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a custom message template
     */
    async deleteCustomMessageTemplateRaw(requestParameters: DeleteCustomMessageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteCustomMessageTemplate.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling deleteCustomMessageTemplate.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCustomMessageTemplate.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/custom-message-template`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a custom message template
     */
    async deleteCustomMessageTemplate(requestParameters: DeleteCustomMessageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCustomMessageTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a discount entry
     */
    async deleteDiscountRaw(requestParameters: DeleteDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteDiscount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/discount`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DiscountEntryToJSON(requestParameters.discountEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a discount entry
     */
    async deleteDiscount(requestParameters: DeleteDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteDiscountRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an inspection entry to service association
     */
    async deleteInspectionEntryServiceAssociationRaw(requestParameters: DeleteInspectionEntryServiceAssociationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteInspectionEntryServiceAssociation.');
        }

        if (requestParameters.inspectionEntryId === null || requestParameters.inspectionEntryId === undefined) {
            throw new runtime.RequiredError('inspectionEntryId','Required parameter requestParameters.inspectionEntryId was null or undefined when calling deleteInspectionEntryServiceAssociation.');
        }

        if (requestParameters.serviceId === null || requestParameters.serviceId === undefined) {
            throw new runtime.RequiredError('serviceId','Required parameter requestParameters.serviceId was null or undefined when calling deleteInspectionEntryServiceAssociation.');
        }

        const queryParameters: any = {};

        if (requestParameters.inspectionEntryId !== undefined) {
            queryParameters['inspection-entry-id'] = requestParameters.inspectionEntryId;
        }

        if (requestParameters.serviceId !== undefined) {
            queryParameters['service-id'] = requestParameters.serviceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection-entry-service`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an inspection entry to service association
     */
    async deleteInspectionEntryServiceAssociation(requestParameters: DeleteInspectionEntryServiceAssociationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteInspectionEntryServiceAssociationRaw(requestParameters, initOverrides);
    }

    /**
     * Delete inventory instances
     */
    async deleteInventoryInstancesRaw(requestParameters: DeleteInventoryInstancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteInventoryInstances.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling deleteInventoryInstances.');
        }

        if (requestParameters.inventoryInstance === null || requestParameters.inventoryInstance === undefined) {
            throw new runtime.RequiredError('inventoryInstance','Required parameter requestParameters.inventoryInstance was null or undefined when calling deleteInventoryInstances.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.inventoryInstance) {
            queryParameters['inventory_instance'] = requestParameters.inventoryInstance;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/instance`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete inventory instances
     */
    async deleteInventoryInstances(requestParameters: DeleteInventoryInstancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteInventoryInstancesRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an existing entry in the shop\'s labor matrix
     */
    async deleteLaborMatrixEntryRaw(requestParameters: DeleteLaborMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Date>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteLaborMatrixEntry.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling deleteLaborMatrixEntry.');
        }

        if (requestParameters.laborMatrixEntry === null || requestParameters.laborMatrixEntry === undefined) {
            throw new runtime.RequiredError('laborMatrixEntry','Required parameter requestParameters.laborMatrixEntry was null or undefined when calling deleteLaborMatrixEntry.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.laborMatrixEntry !== undefined) {
            queryParameters['laborMatrixEntry'] = requestParameters.laborMatrixEntry;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/labor-matrix`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<Date>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete an existing entry in the shop\'s labor matrix
     */
    async deleteLaborMatrixEntry(requestParameters: DeleteLaborMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Date> {
        const response = await this.deleteLaborMatrixEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteLaborOverrideRaw(requestParameters: DeleteLaborOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteLaborOverride.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling deleteLaborOverride.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling deleteLaborOverride.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteLaborOverride.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/labor-override`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteLaborOverride(requestParameters: DeleteLaborOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLaborOverrideRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deletePartOverrideRaw(requestParameters: DeletePartOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deletePartOverride.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling deletePartOverride.');
        }

        if (requestParameters.partUuid === null || requestParameters.partUuid === undefined) {
            throw new runtime.RequiredError('partUuid','Required parameter requestParameters.partUuid was null or undefined when calling deletePartOverride.');
        }

        const queryParameters: any = {};

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        if (requestParameters.partUuid !== undefined) {
            queryParameters['part-uuid'] = requestParameters.partUuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/parts-override`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePartOverride(requestParameters: DeletePartOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePartOverrideRaw(requestParameters, initOverrides);
    }

    /**
     * Delete parts from service
     */
    async deletePartsFromServiceRaw(requestParameters: DeletePartsFromServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deletePartsFromService.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling deletePartsFromService.');
        }

        if (requestParameters.partIds === null || requestParameters.partIds === undefined) {
            throw new runtime.RequiredError('partIds','Required parameter requestParameters.partIds was null or undefined when calling deletePartsFromService.');
        }

        const queryParameters: any = {};

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        if (requestParameters.partIds) {
            queryParameters['part-ids'] = requestParameters.partIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair-link/parts`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete parts from service
     */
    async deletePartsFromService(requestParameters: DeletePartsFromServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePartsFromServiceRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an existing entry in the shop\'s parts matrix
     */
    async deletePartsMatrixEntryRaw(requestParameters: DeletePartsMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Date>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deletePartsMatrixEntry.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling deletePartsMatrixEntry.');
        }

        if (requestParameters.partsMatrixEntry === null || requestParameters.partsMatrixEntry === undefined) {
            throw new runtime.RequiredError('partsMatrixEntry','Required parameter requestParameters.partsMatrixEntry was null or undefined when calling deletePartsMatrixEntry.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.partsMatrixEntry !== undefined) {
            queryParameters['partsMatrixEntry'] = requestParameters.partsMatrixEntry;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/parts-matrix`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<Date>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete an existing entry in the shop\'s parts matrix
     */
    async deletePartsMatrixEntry(requestParameters: DeletePartsMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Date> {
        const response = await this.deletePartsMatrixEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a role
     */
    async deleteRoleRaw(requestParameters: DeleteRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteRole.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling deleteRole.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling deleteRole.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.role !== undefined) {
            queryParameters['role'] = requestParameters.role;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/role`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a role
     */
    async deleteRole(requestParameters: DeleteRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteRoleRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a shop
     */
    async deleteShopRaw(requestParameters: DeleteShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteShop.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteShop.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a shop
     */
    async deleteShop(requestParameters: DeleteShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteShopRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a sublet entry
     */
    async deleteSubletRaw(requestParameters: DeleteSubletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteSublet.');
        }

        if (requestParameters.sublet === null || requestParameters.sublet === undefined) {
            throw new runtime.RequiredError('sublet','Required parameter requestParameters.sublet was null or undefined when calling deleteSublet.');
        }

        const queryParameters: any = {};

        if (requestParameters.service !== undefined) {
            queryParameters['service'] = requestParameters.service;
        }

        if (requestParameters.sublet !== undefined) {
            queryParameters['sublet'] = requestParameters.sublet;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/sublet`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a sublet entry
     */
    async deleteSublet(requestParameters: DeleteSubletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSubletRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a warranty entry
     */
    async deleteWarrantyEntryRaw(requestParameters: DeleteWarrantyEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling deleteWarrantyEntry.');
        }

        if (requestParameters.warrantyEntry === null || requestParameters.warrantyEntry === undefined) {
            throw new runtime.RequiredError('warrantyEntry','Required parameter requestParameters.warrantyEntry was null or undefined when calling deleteWarrantyEntry.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling deleteWarrantyEntry.');
        }

        const queryParameters: any = {};

        if (requestParameters.warrantyEntry !== undefined) {
            queryParameters['warranty-entry'] = requestParameters.warrantyEntry;
        }

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/warranty`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a warranty entry
     */
    async deleteWarrantyEntry(requestParameters: DeleteWarrantyEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteWarrantyEntryRaw(requestParameters, initOverrides);
    }

    /**
     * Capture ip address, timestamp, and signature
     */
    async digitalAuthorizationRaw(requestParameters: DigitalAuthorizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling digitalAuthorization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/digital-authorization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorizationInfoToJSON(requestParameters.authorizationInfo),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Capture ip address, timestamp, and signature
     */
    async digitalAuthorization(requestParameters: DigitalAuthorizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.digitalAuthorizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Authenticate with API key and download invoice PDF
     */
    async downloadInvoicePdfByApiKeyRaw(requestParameters: DownloadInvoicePdfByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling downloadInvoicePdfByApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/invoice/customer-download`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Authenticate with API key and download invoice PDF
     */
    async downloadInvoicePdfByApiKey(requestParameters: DownloadInvoicePdfByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.downloadInvoicePdfByApiKeyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async echoPingPersonPostRaw(requestParameters: EchoPingPersonPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PingPerson>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ping/echo-ping-person`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExpandedPingPersonToJSON(requestParameters.expandedPingPerson),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PingPersonFromJSON(jsonValue));
    }

    /**
     */
    async echoPingPersonPost(requestParameters: EchoPingPersonPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PingPerson> {
        const response = await this.echoPingPersonPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Edit an existing PartsTech quote/cart as part of a service item. Returns a redirect URL. Will also update all prices in our database
     */
    async editCartRefreshPricesRaw(requestParameters: EditCartRefreshPricesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PunchoutInfo>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling editCartRefreshPrices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/parts-tech`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePartsTechQuoteRequestToJSON(requestParameters.updatePartsTechQuoteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PunchoutInfoFromJSON(jsonValue));
    }

    /**
     * Edit an existing PartsTech quote/cart as part of a service item. Returns a redirect URL. Will also update all prices in our database
     */
    async editCartRefreshPrices(requestParameters: EditCartRefreshPricesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PunchoutInfo> {
        const response = await this.editCartRefreshPricesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send email to customer with a link to inspection results
     */
    async emailCustomerInspectionRaw(requestParameters: EmailCustomerInspectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling emailCustomerInspection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/api-key/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailInspectionRequestToJSON(requestParameters.emailInspectionRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Send email to customer with a link to inspection results
     */
    async emailCustomerInspection(requestParameters: EmailCustomerInspectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.emailCustomerInspectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Email an invoice PDF to the customer
     */
    async emailInvoicePdfRaw(requestParameters: EmailInvoicePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling emailInvoicePdf.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/invoice/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailInvoiceRequestToJSON(requestParameters.emailInvoiceRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Email an invoice PDF to the customer
     */
    async emailInvoicePdf(requestParameters: EmailInvoicePdfRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.emailInvoicePdfRaw(requestParameters, initOverrides);
    }

    /**
     * Get peers with matching email
     */
    async emailSearchRaw(requestParameters: EmailSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserProfile>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling emailSearch.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling emailSearch.');
        }

        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling emailSearch.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/email-search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserProfileFromJSON));
    }

    /**
     * Get peers with matching email
     */
    async emailSearch(requestParameters: EmailSearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserProfile>> {
        const response = await this.emailSearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create API key for customer to access repair order
     */
    async generateApiKeyRaw(requestParameters: GenerateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling generateApiKey.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling generateApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('id', requestParameters.id as any);
        }

        const response = await this.request({
            path: `/invoice/repair-order/api-key`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create API key for customer to access repair order
     */
    async generateApiKey(requestParameters: GenerateApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create API key for customer to access repair order with an underlying intent
     */
    async generateApiKeyWithIntentRaw(requestParameters: GenerateApiKeyWithIntentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling generateApiKeyWithIntent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/api-key-with-intent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GenerateApiKeyWithIntentRequestToJSON(requestParameters.generateApiKeyWithIntentRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create API key for customer to access repair order with an underlying intent
     */
    async generateApiKeyWithIntent(requestParameters: GenerateApiKeyWithIntentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateApiKeyWithIntentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate customer note for inspection with AI
     */
    async generateInspectionCustomerNoteRaw(requestParameters: GenerateInspectionCustomerNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling generateInspectionCustomerNote.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ai/inspection-customer-note`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InspectionCustomerNoteRequestToJSON(requestParameters.inspectionCustomerNoteRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Generate customer note for inspection with AI
     */
    async generateInspectionCustomerNote(requestParameters: GenerateInspectionCustomerNoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateInspectionCustomerNoteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate a repair order summary for customer with AI
     */
    async generateRepairOrderCustomerSummaryRaw(requestParameters: GenerateRepairOrderCustomerSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling generateRepairOrderCustomerSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ai/repair-order-customer-summary`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RepairOrderCustomerSummaryRequestToJSON(requestParameters.repairOrderCustomerSummaryRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Generate a repair order summary for customer with AI
     */
    async generateRepairOrderCustomerSummary(requestParameters: GenerateRepairOrderCustomerSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.generateRepairOrderCustomerSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRaw(requestParameters: GetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.myParam !== undefined) {
            queryParameters['my-param'] = requestParameters.myParam;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ping`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async get(requestParameters: GetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getRaw(requestParameters, initOverrides);
    }

    /**
     */
    async get1Raw(requestParameters: Get1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling get1.');
        }

        const queryParameters: any = {};

        if (requestParameters.myParam !== undefined) {
            queryParameters['my-param'] = requestParameters.myParam;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ping/bearer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async get1(requestParameters: Get1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.get1Raw(requestParameters, initOverrides);
    }

    /**
     * Retrieve a single account using a CapitalOS ID or an external ID
     */
    async getAccountRaw(requestParameters: GetAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CapitalOsAccount>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getAccount.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getAccount.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/capital-os/get-account`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CapitalOsAccountFromJSON(jsonValue));
    }

    /**
     * Retrieve a single account using a CapitalOS ID or an external ID
     */
    async getAccount(requestParameters: GetAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CapitalOsAccount> {
        const response = await this.getAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all activity, using paging
     */
    async getActivityLogRaw(requestParameters: GetActivityLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getActivityLog.');
        }

        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['page-number'] = requestParameters.pageNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/paged`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get all activity, using paging
     */
    async getActivityLog(requestParameters: GetActivityLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getActivityLogRaw(requestParameters, initOverrides);
    }

    /**
     * Get additional email addresses for a customer
     */
    async getAdditionalEmailAddressesForCustomerRaw(requestParameters: GetAdditionalEmailAddressesForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerAdditionalEmailAddressEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getAdditionalEmailAddressesForCustomer.');
        }

        if (requestParameters.customer === null || requestParameters.customer === undefined) {
            throw new runtime.RequiredError('customer','Required parameter requestParameters.customer was null or undefined when calling getAdditionalEmailAddressesForCustomer.');
        }

        const queryParameters: any = {};

        if (requestParameters.customer !== undefined) {
            queryParameters['customer'] = requestParameters.customer;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer/additional-email-address`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerAdditionalEmailAddressEntryFromJSON));
    }

    /**
     * Get additional email addresses for a customer
     */
    async getAdditionalEmailAddressesForCustomer(requestParameters: GetAdditionalEmailAddressesForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerAdditionalEmailAddressEntry>> {
        const response = await this.getAdditionalEmailAddressesForCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get additional phones for a customer
     */
    async getAdditionalPhonesForCustomerRaw(requestParameters: GetAdditionalPhonesForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerAdditionalPhoneEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getAdditionalPhonesForCustomer.');
        }

        if (requestParameters.customer === null || requestParameters.customer === undefined) {
            throw new runtime.RequiredError('customer','Required parameter requestParameters.customer was null or undefined when calling getAdditionalPhonesForCustomer.');
        }

        const queryParameters: any = {};

        if (requestParameters.customer !== undefined) {
            queryParameters['customer'] = requestParameters.customer;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer/additional-phone`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerAdditionalPhoneEntryFromJSON));
    }

    /**
     * Get additional phones for a customer
     */
    async getAdditionalPhonesForCustomer(requestParameters: GetAdditionalPhonesForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerAdditionalPhoneEntry>> {
        const response = await this.getAdditionalPhonesForCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all engines for a specific year/make/model/engine
     */
    async getAllEnginesRaw(requestParameters: GetAllEnginesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IdName>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getAllEngines.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getAllEngines.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getAllEngines.');
        }

        if (requestParameters.makeId === null || requestParameters.makeId === undefined) {
            throw new runtime.RequiredError('makeId','Required parameter requestParameters.makeId was null or undefined when calling getAllEngines.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getAllEngines.');
        }

        if (requestParameters.subModelId === null || requestParameters.subModelId === undefined) {
            throw new runtime.RequiredError('subModelId','Required parameter requestParameters.subModelId was null or undefined when calling getAllEngines.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.makeId !== undefined) {
            queryParameters['make-id'] = requestParameters.makeId;
        }

        if (requestParameters.modelId !== undefined) {
            queryParameters['model-id'] = requestParameters.modelId;
        }

        if (requestParameters.subModelId !== undefined) {
            queryParameters['sub-model-id'] = requestParameters.subModelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/engine`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNameFromJSON));
    }

    /**
     * Get all engines for a specific year/make/model/engine
     */
    async getAllEngines(requestParameters: GetAllEnginesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IdName>> {
        const response = await this.getAllEnginesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all engines for a specific year/make/model/engine without authorization
     */
    async getAllEnginesIntakeRaw(requestParameters: GetAllEnginesIntakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IdName>>> {
        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getAllEnginesIntake.');
        }

        if (requestParameters.makeId === null || requestParameters.makeId === undefined) {
            throw new runtime.RequiredError('makeId','Required parameter requestParameters.makeId was null or undefined when calling getAllEnginesIntake.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getAllEnginesIntake.');
        }

        if (requestParameters.subModelId === null || requestParameters.subModelId === undefined) {
            throw new runtime.RequiredError('subModelId','Required parameter requestParameters.subModelId was null or undefined when calling getAllEnginesIntake.');
        }

        const queryParameters: any = {};

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.makeId !== undefined) {
            queryParameters['make-id'] = requestParameters.makeId;
        }

        if (requestParameters.modelId !== undefined) {
            queryParameters['model-id'] = requestParameters.modelId;
        }

        if (requestParameters.subModelId !== undefined) {
            queryParameters['sub-model-id'] = requestParameters.subModelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/engine/intake`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNameFromJSON));
    }

    /**
     * Get all engines for a specific year/make/model/engine without authorization
     */
    async getAllEnginesIntake(requestParameters: GetAllEnginesIntakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IdName>> {
        const response = await this.getAllEnginesIntakeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all engines for a specific year/make/model/engine with verification id
     */
    async getAllEnginesWithVerificationIdRaw(requestParameters: GetAllEnginesWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IdName>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getAllEnginesWithVerificationId.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling getAllEnginesWithVerificationId.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getAllEnginesWithVerificationId.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getAllEnginesWithVerificationId.');
        }

        if (requestParameters.makeId === null || requestParameters.makeId === undefined) {
            throw new runtime.RequiredError('makeId','Required parameter requestParameters.makeId was null or undefined when calling getAllEnginesWithVerificationId.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getAllEnginesWithVerificationId.');
        }

        if (requestParameters.subModelId === null || requestParameters.subModelId === undefined) {
            throw new runtime.RequiredError('subModelId','Required parameter requestParameters.subModelId was null or undefined when calling getAllEnginesWithVerificationId.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer-id'] = requestParameters.customerId;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.makeId !== undefined) {
            queryParameters['make-id'] = requestParameters.makeId;
        }

        if (requestParameters.modelId !== undefined) {
            queryParameters['model-id'] = requestParameters.modelId;
        }

        if (requestParameters.subModelId !== undefined) {
            queryParameters['sub-model-id'] = requestParameters.subModelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/engine/verification-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNameFromJSON));
    }

    /**
     * Get all engines for a specific year/make/model/engine with verification id
     */
    async getAllEnginesWithVerificationId(requestParameters: GetAllEnginesWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IdName>> {
        const response = await this.getAllEnginesWithVerificationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all makes for a specific year
     */
    async getAllMakesRaw(requestParameters: GetAllMakesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IdName>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getAllMakes.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getAllMakes.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getAllMakes.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/make`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNameFromJSON));
    }

    /**
     * Get all makes for a specific year
     */
    async getAllMakes(requestParameters: GetAllMakesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IdName>> {
        const response = await this.getAllMakesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all makes for a specific year without authorization
     */
    async getAllMakesIntakeRaw(requestParameters: GetAllMakesIntakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IdName>>> {
        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getAllMakesIntake.');
        }

        const queryParameters: any = {};

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/make/intake`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNameFromJSON));
    }

    /**
     * Get all makes for a specific year without authorization
     */
    async getAllMakesIntake(requestParameters: GetAllMakesIntakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IdName>> {
        const response = await this.getAllMakesIntakeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all makes for a specific year with verification id
     */
    async getAllMakesWithVerificationIdRaw(requestParameters: GetAllMakesWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IdName>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getAllMakesWithVerificationId.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling getAllMakesWithVerificationId.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getAllMakesWithVerificationId.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getAllMakesWithVerificationId.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer-id'] = requestParameters.customerId;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/make/verification-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNameFromJSON));
    }

    /**
     * Get all makes for a specific year with verification id
     */
    async getAllMakesWithVerificationId(requestParameters: GetAllMakesWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IdName>> {
        const response = await this.getAllMakesWithVerificationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all models for a specific year/make
     */
    async getAllModelsRaw(requestParameters: GetAllModelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IdName>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getAllModels.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getAllModels.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getAllModels.');
        }

        if (requestParameters.makeId === null || requestParameters.makeId === undefined) {
            throw new runtime.RequiredError('makeId','Required parameter requestParameters.makeId was null or undefined when calling getAllModels.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.makeId !== undefined) {
            queryParameters['make-id'] = requestParameters.makeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/model`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNameFromJSON));
    }

    /**
     * Get all models for a specific year/make
     */
    async getAllModels(requestParameters: GetAllModelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IdName>> {
        const response = await this.getAllModelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all models for a specific year/make without authorization
     */
    async getAllModelsIntakeRaw(requestParameters: GetAllModelsIntakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IdName>>> {
        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getAllModelsIntake.');
        }

        if (requestParameters.makeId === null || requestParameters.makeId === undefined) {
            throw new runtime.RequiredError('makeId','Required parameter requestParameters.makeId was null or undefined when calling getAllModelsIntake.');
        }

        const queryParameters: any = {};

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.makeId !== undefined) {
            queryParameters['make-id'] = requestParameters.makeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/model/intake`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNameFromJSON));
    }

    /**
     * Get all models for a specific year/make without authorization
     */
    async getAllModelsIntake(requestParameters: GetAllModelsIntakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IdName>> {
        const response = await this.getAllModelsIntakeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all models for a specific year/make with verification id
     */
    async getAllModelsWithVerificationIdRaw(requestParameters: GetAllModelsWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IdName>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getAllModelsWithVerificationId.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling getAllModelsWithVerificationId.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getAllModelsWithVerificationId.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getAllModelsWithVerificationId.');
        }

        if (requestParameters.makeId === null || requestParameters.makeId === undefined) {
            throw new runtime.RequiredError('makeId','Required parameter requestParameters.makeId was null or undefined when calling getAllModelsWithVerificationId.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer-id'] = requestParameters.customerId;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.makeId !== undefined) {
            queryParameters['make-id'] = requestParameters.makeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/model/verification-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNameFromJSON));
    }

    /**
     * Get all models for a specific year/make with verification id
     */
    async getAllModelsWithVerificationId(requestParameters: GetAllModelsWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IdName>> {
        const response = await this.getAllModelsWithVerificationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all submodels for a specific year/make/submodel
     */
    async getAllSubModelsRaw(requestParameters: GetAllSubModelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IdName>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getAllSubModels.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getAllSubModels.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getAllSubModels.');
        }

        if (requestParameters.makeId === null || requestParameters.makeId === undefined) {
            throw new runtime.RequiredError('makeId','Required parameter requestParameters.makeId was null or undefined when calling getAllSubModels.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getAllSubModels.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.makeId !== undefined) {
            queryParameters['make-id'] = requestParameters.makeId;
        }

        if (requestParameters.modelId !== undefined) {
            queryParameters['model-id'] = requestParameters.modelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/submodel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNameFromJSON));
    }

    /**
     * Get all submodels for a specific year/make/submodel
     */
    async getAllSubModels(requestParameters: GetAllSubModelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IdName>> {
        const response = await this.getAllSubModelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all submodels for a specific year/make/submodel without authorization
     */
    async getAllSubModelsIntakeRaw(requestParameters: GetAllSubModelsIntakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IdName>>> {
        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getAllSubModelsIntake.');
        }

        if (requestParameters.makeId === null || requestParameters.makeId === undefined) {
            throw new runtime.RequiredError('makeId','Required parameter requestParameters.makeId was null or undefined when calling getAllSubModelsIntake.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getAllSubModelsIntake.');
        }

        const queryParameters: any = {};

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.makeId !== undefined) {
            queryParameters['make-id'] = requestParameters.makeId;
        }

        if (requestParameters.modelId !== undefined) {
            queryParameters['model-id'] = requestParameters.modelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/submodel/intake`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNameFromJSON));
    }

    /**
     * Get all submodels for a specific year/make/submodel without authorization
     */
    async getAllSubModelsIntake(requestParameters: GetAllSubModelsIntakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IdName>> {
        const response = await this.getAllSubModelsIntakeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all submodels for a specific year/make/submodel with verification id
     */
    async getAllSubModelsWithVerificationIdRaw(requestParameters: GetAllSubModelsWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IdName>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getAllSubModelsWithVerificationId.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling getAllSubModelsWithVerificationId.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getAllSubModelsWithVerificationId.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling getAllSubModelsWithVerificationId.');
        }

        if (requestParameters.makeId === null || requestParameters.makeId === undefined) {
            throw new runtime.RequiredError('makeId','Required parameter requestParameters.makeId was null or undefined when calling getAllSubModelsWithVerificationId.');
        }

        if (requestParameters.modelId === null || requestParameters.modelId === undefined) {
            throw new runtime.RequiredError('modelId','Required parameter requestParameters.modelId was null or undefined when calling getAllSubModelsWithVerificationId.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer-id'] = requestParameters.customerId;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.makeId !== undefined) {
            queryParameters['make-id'] = requestParameters.makeId;
        }

        if (requestParameters.modelId !== undefined) {
            queryParameters['model-id'] = requestParameters.modelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/submodel/verification-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNameFromJSON));
    }

    /**
     * Get all submodels for a specific year/make/submodel with verification id
     */
    async getAllSubModelsWithVerificationId(requestParameters: GetAllSubModelsWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IdName>> {
        const response = await this.getAllSubModelsWithVerificationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get inventory parts associated with services associated with API key
     */
    async getApiKeyInventoryPartsRaw(requestParameters: GetApiKeyInventoryPartsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InventoryPartEntry>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getApiKeyInventoryParts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/customer-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InventoryPartEntryFromJSON));
    }

    /**
     * Get inventory parts associated with services associated with API key
     */
    async getApiKeyInventoryParts(requestParameters: GetApiKeyInventoryPartsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InventoryPartEntry>> {
        const response = await this.getApiKeyInventoryPartsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the review for an API key
     */
    async getApiKeyReviewRaw(requestParameters: GetApiKeyReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerFeedbackEntry>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getApiKeyReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/api-key/feedback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFeedbackEntryFromJSON(jsonValue));
    }

    /**
     * Get the review for an API key
     */
    async getApiKeyReview(requestParameters: GetApiKeyReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerFeedbackEntry> {
        const response = await this.getApiKeyReviewRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get details on all API keys for a repair order
     */
    async getApiKeysForRepairOrderRaw(requestParameters: GetApiKeysForRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ApiKeyEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getApiKeysForRepairOrder.');
        }

        if (requestParameters.repairOrderId === null || requestParameters.repairOrderId === undefined) {
            throw new runtime.RequiredError('repairOrderId','Required parameter requestParameters.repairOrderId was null or undefined when calling getApiKeysForRepairOrder.');
        }

        const queryParameters: any = {};

        if (requestParameters.repairOrderId !== undefined) {
            queryParameters['repair-order-id'] = requestParameters.repairOrderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/api-keys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ApiKeyEntryFromJSON));
    }

    /**
     * Get details on all API keys for a repair order
     */
    async getApiKeysForRepairOrder(requestParameters: GetApiKeysForRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ApiKeyEntry>> {
        const response = await this.getApiKeysForRepairOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an appointment info
     */
    async getAppointmentRaw(requestParameters: GetAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AppointmentEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getAppointment.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getAppointment.');
        }

        if (requestParameters.appointment === null || requestParameters.appointment === undefined) {
            throw new runtime.RequiredError('appointment','Required parameter requestParameters.appointment was null or undefined when calling getAppointment.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.appointment) {
            queryParameters['appointment'] = requestParameters.appointment;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppointmentEntryFromJSON));
    }

    /**
     * Get an appointment info
     */
    async getAppointment(requestParameters: GetAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppointmentEntry>> {
        const response = await this.getAppointmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get available local date times to book appointments
     */
    async getAppointmentAvailabilityRaw(requestParameters: GetAppointmentAvailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Date>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getAppointmentAvailability.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getAppointmentAvailability.');
        }

        if (requestParameters.current === null || requestParameters.current === undefined) {
            throw new runtime.RequiredError('current','Required parameter requestParameters.current was null or undefined when calling getAppointmentAvailability.');
        }

        if (requestParameters.startDateInclusive === null || requestParameters.startDateInclusive === undefined) {
            throw new runtime.RequiredError('startDateInclusive','Required parameter requestParameters.startDateInclusive was null or undefined when calling getAppointmentAvailability.');
        }

        if (requestParameters.endDateExclusive === null || requestParameters.endDateExclusive === undefined) {
            throw new runtime.RequiredError('endDateExclusive','Required parameter requestParameters.endDateExclusive was null or undefined when calling getAppointmentAvailability.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.current !== undefined) {
            queryParameters['current'] = requestParameters.current;
        }

        if (requestParameters.startDateInclusive !== undefined) {
            queryParameters['start_date_inclusive'] = requestParameters.startDateInclusive;
        }

        if (requestParameters.endDateExclusive !== undefined) {
            queryParameters['end_date_exclusive'] = requestParameters.endDateExclusive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment/availability`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get available local date times to book appointments
     */
    async getAppointmentAvailability(requestParameters: GetAppointmentAvailabilityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Date>> {
        const response = await this.getAppointmentAvailabilityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get available local date times to book appointments with verification id
     */
    async getAppointmentAvailabilityWithVerificationIdRaw(requestParameters: GetAppointmentAvailabilityWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Date>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getAppointmentAvailabilityWithVerificationId.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling getAppointmentAvailabilityWithVerificationId.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getAppointmentAvailabilityWithVerificationId.');
        }

        if (requestParameters.current === null || requestParameters.current === undefined) {
            throw new runtime.RequiredError('current','Required parameter requestParameters.current was null or undefined when calling getAppointmentAvailabilityWithVerificationId.');
        }

        if (requestParameters.startDateInclusive === null || requestParameters.startDateInclusive === undefined) {
            throw new runtime.RequiredError('startDateInclusive','Required parameter requestParameters.startDateInclusive was null or undefined when calling getAppointmentAvailabilityWithVerificationId.');
        }

        if (requestParameters.endDateExclusive === null || requestParameters.endDateExclusive === undefined) {
            throw new runtime.RequiredError('endDateExclusive','Required parameter requestParameters.endDateExclusive was null or undefined when calling getAppointmentAvailabilityWithVerificationId.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer-id'] = requestParameters.customerId;
        }

        if (requestParameters.current !== undefined) {
            queryParameters['current'] = requestParameters.current;
        }

        if (requestParameters.startDateInclusive !== undefined) {
            queryParameters['start_date_inclusive'] = requestParameters.startDateInclusive;
        }

        if (requestParameters.endDateExclusive !== undefined) {
            queryParameters['end_date_exclusive'] = requestParameters.endDateExclusive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment/availability/verification-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get available local date times to book appointments with verification id
     */
    async getAppointmentAvailabilityWithVerificationId(requestParameters: GetAppointmentAvailabilityWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Date>> {
        const response = await this.getAppointmentAvailabilityWithVerificationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get appointment settings by shop
     */
    async getAppointmentSettingsByShopRaw(requestParameters: GetAppointmentSettingsByShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentSettingsEntry>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getAppointmentSettingsByShop.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getAppointmentSettingsByShop.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment/appointment-settings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentSettingsEntryFromJSON(jsonValue));
    }

    /**
     * Get appointment settings by shop
     */
    async getAppointmentSettingsByShop(requestParameters: GetAppointmentSettingsByShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentSettingsEntry> {
        const response = await this.getAppointmentSettingsByShopRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get appointment settings by shop with verification id
     */
    async getAppointmentSettingsByShopWithVerificationIdRaw(requestParameters: GetAppointmentSettingsByShopWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentSettingsEntry>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getAppointmentSettingsByShopWithVerificationId.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling getAppointmentSettingsByShopWithVerificationId.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getAppointmentSettingsByShopWithVerificationId.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer-id'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment/appointment-settings/verification-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentSettingsEntryFromJSON(jsonValue));
    }

    /**
     * Get appointment settings by shop with verification id
     */
    async getAppointmentSettingsByShopWithVerificationId(requestParameters: GetAppointmentSettingsByShopWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentSettingsEntry> {
        const response = await this.getAppointmentSettingsByShopWithVerificationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get appointment time constraints by shop
     */
    async getAppointmentTimeConstraintsByShopRaw(requestParameters: GetAppointmentTimeConstraintsByShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AppointmentTimeConstraintsEntry>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getAppointmentTimeConstraintsByShop.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getAppointmentTimeConstraintsByShop.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment/appointment-time-constraints`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AppointmentTimeConstraintsEntryFromJSON(jsonValue));
    }

    /**
     * Get appointment time constraints by shop
     */
    async getAppointmentTimeConstraintsByShop(requestParameters: GetAppointmentTimeConstraintsByShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppointmentTimeConstraintsEntry> {
        const response = await this.getAppointmentTimeConstraintsByShopRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all appointments for customer
     */
    async getAppointmentsForCustomerRaw(requestParameters: GetAppointmentsForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AppointmentEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getAppointmentsForCustomer.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getAppointmentsForCustomer.');
        }

        if (requestParameters.customer === null || requestParameters.customer === undefined) {
            throw new runtime.RequiredError('customer','Required parameter requestParameters.customer was null or undefined when calling getAppointmentsForCustomer.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.customer !== undefined) {
            queryParameters['customer'] = requestParameters.customer;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment/customer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppointmentEntryFromJSON));
    }

    /**
     * Get all appointments for customer
     */
    async getAppointmentsForCustomer(requestParameters: GetAppointmentsForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppointmentEntry>> {
        const response = await this.getAppointmentsForCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all appointments for selected vehicle
     */
    async getAppointmentsForVehicleRaw(requestParameters: GetAppointmentsForVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AppointmentEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getAppointmentsForVehicle.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getAppointmentsForVehicle.');
        }

        if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
            throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling getAppointmentsForVehicle.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment/vehicle`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppointmentEntryFromJSON));
    }

    /**
     * Get all appointments for selected vehicle
     */
    async getAppointmentsForVehicle(requestParameters: GetAppointmentsForVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppointmentEntry>> {
        const response = await this.getAppointmentsForVehicleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get automated vehicle reminder by id
     */
    async getAutomatedVehicleReminderEntriesByIdRaw(requestParameters: GetAutomatedVehicleReminderEntriesByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AutomatedVehicleReminderEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getAutomatedVehicleReminderEntriesById.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getAutomatedVehicleReminderEntriesById.');
        }

        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling getAutomatedVehicleReminderEntriesById.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/automated-vehicle-reminder`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AutomatedVehicleReminderEntryFromJSON));
    }

    /**
     * Get automated vehicle reminder by id
     */
    async getAutomatedVehicleReminderEntriesById(requestParameters: GetAutomatedVehicleReminderEntriesByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AutomatedVehicleReminderEntry>> {
        const response = await this.getAutomatedVehicleReminderEntriesByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all associated expanded entries for specified canned jobs
     */
    async getCannedJobExpandedRaw(requestParameters: GetCannedJobExpandedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CannedJobExpanded>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCannedJobExpanded.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getCannedJobExpanded.');
        }

        if (requestParameters.cannedJobs === null || requestParameters.cannedJobs === undefined) {
            throw new runtime.RequiredError('cannedJobs','Required parameter requestParameters.cannedJobs was null or undefined when calling getCannedJobExpanded.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.cannedJobs) {
            queryParameters['canned-jobs'] = requestParameters.cannedJobs;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/expanded`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CannedJobExpandedFromJSON));
    }

    /**
     * Get all associated expanded entries for specified canned jobs
     */
    async getCannedJobExpanded(requestParameters: GetCannedJobExpandedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CannedJobExpanded>> {
        const response = await this.getCannedJobExpandedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all associated entries for specified canned jobs with api key
     */
    async getCannedJobExpandedByApiKeyRaw(requestParameters: GetCannedJobExpandedByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CannedJobExpanded>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getCannedJobExpandedByApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/expanded/api-key`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CannedJobExpandedFromJSON));
    }

    /**
     * Get all associated entries for specified canned jobs with api key
     */
    async getCannedJobExpandedByApiKey(requestParameters: GetCannedJobExpandedByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CannedJobExpanded>> {
        const response = await this.getCannedJobExpandedByApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a motor fluids for canned job
     */
    async getCannedJobMotorFluidsRaw(requestParameters: GetCannedJobMotorFluidsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CannedJobMotorFluidsEntry>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCannedJobMotorFluids.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getCannedJobMotorFluids.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCannedJobMotorFluids.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/motor-fluids`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CannedJobMotorFluidsEntryFromJSON(jsonValue));
    }

    /**
     * Get a motor fluids for canned job
     */
    async getCannedJobMotorFluids(requestParameters: GetCannedJobMotorFluidsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CannedJobMotorFluidsEntry> {
        const response = await this.getCannedJobMotorFluidsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a motor labor for canned job
     */
    async getCannedJobMotorLaborRaw(requestParameters: GetCannedJobMotorLaborRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CannedJobMotorLaborEntry>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCannedJobMotorLabor.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getCannedJobMotorLabor.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCannedJobMotorLabor.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/motor-labor`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CannedJobMotorLaborEntryFromJSON(jsonValue));
    }

    /**
     * Get a motor labor for canned job
     */
    async getCannedJobMotorLabor(requestParameters: GetCannedJobMotorLaborRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CannedJobMotorLaborEntry> {
        const response = await this.getCannedJobMotorLaborRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get canned job related entries
     */
    async getCannedJobRelatedRaw(requestParameters: GetCannedJobRelatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CannedJobRelatedEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCannedJobRelated.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling getCannedJobRelated.');
        }

        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling getCannedJobRelated.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shopId'] = requestParameters.shopId;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/canned-job-related`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CannedJobRelatedEntryFromJSON));
    }

    /**
     * Get canned job related entries
     */
    async getCannedJobRelated(requestParameters: GetCannedJobRelatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CannedJobRelatedEntry>> {
        const response = await this.getCannedJobRelatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get canned jobs related to parent
     */
    async getCannedJobRelatedToParentRaw(requestParameters: GetCannedJobRelatedToParentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CannedJobRelatedEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCannedJobRelatedToParent.');
        }

        if (requestParameters.parent === null || requestParameters.parent === undefined) {
            throw new runtime.RequiredError('parent','Required parameter requestParameters.parent was null or undefined when calling getCannedJobRelatedToParent.');
        }

        const queryParameters: any = {};

        if (requestParameters.parent !== undefined) {
            queryParameters['parent'] = requestParameters.parent;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/canned-job-related/parent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CannedJobRelatedEntryFromJSON));
    }

    /**
     * Get canned jobs related to parent
     */
    async getCannedJobRelatedToParent(requestParameters: GetCannedJobRelatedToParentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CannedJobRelatedEntry>> {
        const response = await this.getCannedJobRelatedToParentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all associated entries for specified canned jobs
     */
    async getCannedJobsRaw(requestParameters: GetCannedJobsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CannedJobEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCannedJobs.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getCannedJobs.');
        }

        if (requestParameters.cannedJobs === null || requestParameters.cannedJobs === undefined) {
            throw new runtime.RequiredError('cannedJobs','Required parameter requestParameters.cannedJobs was null or undefined when calling getCannedJobs.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.cannedJobs) {
            queryParameters['canned-jobs'] = requestParameters.cannedJobs;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/specific`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CannedJobEntryFromJSON));
    }

    /**
     * Get all associated entries for specified canned jobs
     */
    async getCannedJobs(requestParameters: GetCannedJobsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CannedJobEntry>> {
        const response = await this.getCannedJobsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get carfax services
     */
    async getCarfaxServicesRaw(requestParameters: GetCarfaxServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ServiceHistory>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCarfaxServices.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getCarfaxServices.');
        }

        if (requestParameters.vin === null || requestParameters.vin === undefined) {
            throw new runtime.RequiredError('vin','Required parameter requestParameters.vin was null or undefined when calling getCarfaxServices.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vin !== undefined) {
            queryParameters['vin'] = requestParameters.vin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/carfax`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceHistoryFromJSON(jsonValue));
    }

    /**
     * Get carfax services
     */
    async getCarfaxServices(requestParameters: GetCarfaxServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ServiceHistory> {
        const response = await this.getCarfaxServicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all catalog labor matrix entries for the given shop
     */
    async getCatalogLaborMatrixEntriesRaw(requestParameters: GetCatalogLaborMatrixEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CatalogLaborMatrixEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCatalogLaborMatrixEntries.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getCatalogLaborMatrixEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/catalog-labor-matrix`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CatalogLaborMatrixEntryFromJSON));
    }

    /**
     * Get all catalog labor matrix entries for the given shop
     */
    async getCatalogLaborMatrixEntries(requestParameters: GetCatalogLaborMatrixEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CatalogLaborMatrixEntry>> {
        const response = await this.getCatalogLaborMatrixEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Use chalice function to get response and update form data
     */
    async getChaliceRaw(requestParameters: GetChaliceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FormInfo>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getChalice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/chalice/form`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FormInfoToJSON(requestParameters.formInfo),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FormInfoFromJSON(jsonValue));
    }

    /**
     * Use chalice function to get response and update form data
     */
    async getChalice(requestParameters: GetChaliceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FormInfo> {
        const response = await this.getChaliceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fake endpoint to generate sdk for classes not referred by other endpoints
     */
    async getClassesRaw(requestParameters: GetClassesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getClasses.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cache/classes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: GetClassesRequestToJSON(requestParameters.getClassesRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Fake endpoint to generate sdk for classes not referred by other endpoints
     */
    async getClasses(requestParameters: GetClassesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getClassesRaw(requestParameters, initOverrides);
    }

    /**
     * Get all core instances for a shop.Each inner list has exactly 2 elements: the core and its parent. The first element in the inner list is the core item. The second element is its parent and is nullable.This API will only return the most recent 200 cores
     */
    async getCoreInventoryInstancesRaw(requestParameters: GetCoreInventoryInstancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Array<InventoryPartInstanceEntry>>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCoreInventoryInstances.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getCoreInventoryInstances.');
        }

        if (requestParameters.isPending === null || requestParameters.isPending === undefined) {
            throw new runtime.RequiredError('isPending','Required parameter requestParameters.isPending was null or undefined when calling getCoreInventoryInstances.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.isPending !== undefined) {
            queryParameters['is_pending'] = requestParameters.isPending;
        }

        if (requestParameters.endDateExclusive !== undefined) {
            queryParameters['end_date_exclusive'] = requestParameters.endDateExclusive;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/instance/core`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get all core instances for a shop.Each inner list has exactly 2 elements: the core and its parent. The first element in the inner list is the core item. The second element is its parent and is nullable.This API will only return the most recent 200 cores
     */
    async getCoreInventoryInstances(requestParameters: GetCoreInventoryInstancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Array<InventoryPartInstanceEntry>>> {
        const response = await this.getCoreInventoryInstancesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all custom message templates for a given shop
     */
    async getCustomMessageTemplatesForShopRaw(requestParameters: GetCustomMessageTemplatesForShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MessageTemplateEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCustomMessageTemplatesForShop.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getCustomMessageTemplatesForShop.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/custom-message-template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MessageTemplateEntryFromJSON));
    }

    /**
     * Get all custom message templates for a given shop
     */
    async getCustomMessageTemplatesForShop(requestParameters: GetCustomMessageTemplatesForShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MessageTemplateEntry>> {
        const response = await this.getCustomMessageTemplatesForShopRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a customer\'s info
     */
    async getCustomerInfoRaw(requestParameters: GetCustomerInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCustomerInfo.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getCustomerInfo.');
        }

        if (requestParameters.customer === null || requestParameters.customer === undefined) {
            throw new runtime.RequiredError('customer','Required parameter requestParameters.customer was null or undefined when calling getCustomerInfo.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.customer) {
            queryParameters['customer'] = requestParameters.customer;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerEntryFromJSON));
    }

    /**
     * Get a customer\'s info
     */
    async getCustomerInfo(requestParameters: GetCustomerInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerEntry>> {
        const response = await this.getCustomerInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a customer\'s info by API key
     */
    async getCustomerInfoByApiKeyRaw(requestParameters: GetCustomerInfoByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerEntry>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getCustomerInfoByApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer/customer-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerEntryFromJSON(jsonValue));
    }

    /**
     * Get a customer\'s info by API key
     */
    async getCustomerInfoByApiKey(requestParameters: GetCustomerInfoByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerEntry> {
        const response = await this.getCustomerInfoByApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a customer\'s orders
     */
    async getCustomerOrdersRaw(requestParameters: GetCustomerOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerOrdersEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCustomerOrders.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getCustomerOrders.');
        }

        if (requestParameters.customer === null || requestParameters.customer === undefined) {
            throw new runtime.RequiredError('customer','Required parameter requestParameters.customer was null or undefined when calling getCustomerOrders.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.customer !== undefined) {
            queryParameters['customer'] = requestParameters.customer;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/order/customer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerOrdersEntryFromJSON));
    }

    /**
     * Get a customer\'s orders
     */
    async getCustomerOrders(requestParameters: GetCustomerOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerOrdersEntry>> {
        const response = await this.getCustomerOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get customer pages
     */
    async getCustomerPagesRaw(requestParameters: GetCustomerPagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerPages>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCustomerPages.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getCustomerPages.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.maxTimestampExclusive !== undefined) {
            queryParameters['max_timestamp_exclusive'] = requestParameters.maxTimestampExclusive;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer/page`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerPagesFromJSON));
    }

    /**
     * Get customer pages
     */
    async getCustomerPages(requestParameters: GetCustomerPagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerPages>> {
        const response = await this.getCustomerPagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get customer portal information for a single inspection for viewing by a customer
     */
    async getCustomerPortalInfoForCustomerRaw(requestParameters: GetCustomerPortalInfoForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerPortalInfo>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getCustomerPortalInfoForCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/customer-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerPortalInfoFromJSON(jsonValue));
    }

    /**
     * Get customer portal information for a single inspection for viewing by a customer
     */
    async getCustomerPortalInfoForCustomer(requestParameters: GetCustomerPortalInfoForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerPortalInfo> {
        const response = await this.getCustomerPortalInfoForCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all vehicles for a customer
     */
    async getCustomerVehiclesRaw(requestParameters: GetCustomerVehiclesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCustomerVehicles.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getCustomerVehicles.');
        }

        if (requestParameters.customer === null || requestParameters.customer === undefined) {
            throw new runtime.RequiredError('customer','Required parameter requestParameters.customer was null or undefined when calling getCustomerVehicles.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.customer !== undefined) {
            queryParameters['customer'] = requestParameters.customer;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/customer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleEntryFromJSON));
    }

    /**
     * Get all vehicles for a customer
     */
    async getCustomerVehicles(requestParameters: GetCustomerVehiclesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleEntry>> {
        const response = await this.getCustomerVehiclesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all vehicles for a customer with verification id
     */
    async getCustomerVehiclesWithVerificationIdRaw(requestParameters: GetCustomerVehiclesWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleEntry>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getCustomerVehiclesWithVerificationId.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling getCustomerVehiclesWithVerificationId.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getCustomerVehiclesWithVerificationId.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer-id'] = requestParameters.customerId;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/customer/verification-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleEntryFromJSON));
    }

    /**
     * Get all vehicles for a customer with verification id
     */
    async getCustomerVehiclesWithVerificationId(requestParameters: GetCustomerVehiclesWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleEntry>> {
        const response = await this.getCustomerVehiclesWithVerificationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all customers linked to a given vehicle
     */
    async getCustomersForVehicleRaw(requestParameters: GetCustomersForVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getCustomersForVehicle.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getCustomersForVehicle.');
        }

        if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
            throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling getCustomersForVehicle.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer/vehicle`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerEntryFromJSON));
    }

    /**
     * Get all customers linked to a given vehicle
     */
    async getCustomersForVehicle(requestParameters: GetCustomersForVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerEntry>> {
        const response = await this.getCustomersForVehicleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get discounts for shop
     */
    async getDiscountsForShopRaw(requestParameters: GetDiscountsForShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DiscountEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getDiscountsForShop.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getDiscountsForShop.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.activeOnly !== undefined) {
            queryParameters['activeOnly'] = requestParameters.activeOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/discount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscountEntryFromJSON));
    }

    /**
     * Get discounts for shop
     */
    async getDiscountsForShop(requestParameters: GetDiscountsForShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DiscountEntry>> {
        const response = await this.getDiscountsForShopRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all user and roles for a specific shop
     */
    async getEmployeeRolesRaw(requestParameters: GetEmployeeRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EmployeeRole>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getEmployeeRoles.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getEmployeeRoles.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/user-role`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmployeeRoleFromJSON));
    }

    /**
     * Get all user and roles for a specific shop
     */
    async getEmployeeRoles(requestParameters: GetEmployeeRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EmployeeRole>> {
        const response = await this.getEmployeeRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of reviews and related data for a shop
     */
    async getExpandedReviewsRaw(requestParameters: GetExpandedReviewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerFeedbackExpanded>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getExpandedReviews.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling getExpandedReviews.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/feedback-expanded`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerFeedbackExpandedFromJSON));
    }

    /**
     * Get a list of reviews and related data for a shop
     */
    async getExpandedReviews(requestParameters: GetExpandedReviewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerFeedbackExpanded>> {
        const response = await this.getExpandedReviewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Finix identity id for shop
     */
    async getFinixIdentityIdRaw(requestParameters: GetFinixIdentityIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getFinixIdentityId.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getFinixIdentityId.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/finix-identity-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get Finix identity id for shop
     */
    async getFinixIdentityId(requestParameters: GetFinixIdentityIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getFinixIdentityIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get finix onboarding form
     */
    async getFinixOnboardingFormRaw(requestParameters: GetFinixOnboardingFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getFinixOnboardingForm.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getFinixOnboardingForm.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/payment/finix/onboarding-form`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get finix onboarding form
     */
    async getFinixOnboardingForm(requestParameters: GetFinixOnboardingFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getFinixOnboardingFormRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Fluid Applications for a specific estimated work time
     */
    async getFluidApplicationForEstimatedWorkTimeRaw(requestParameters: GetFluidApplicationForEstimatedWorkTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FluidApplication>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getFluidApplicationForEstimatedWorkTime.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getFluidApplicationForEstimatedWorkTime.');
        }

        if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
            throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling getFluidApplicationForEstimatedWorkTime.');
        }

        if (requestParameters.estimatedWorkTimeApplicationId === null || requestParameters.estimatedWorkTimeApplicationId === undefined) {
            throw new runtime.RequiredError('estimatedWorkTimeApplicationId','Required parameter requestParameters.estimatedWorkTimeApplicationId was null or undefined when calling getFluidApplicationForEstimatedWorkTime.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        if (requestParameters.estimatedWorkTimeApplicationId !== undefined) {
            queryParameters['estimated_work_time_application_id'] = requestParameters.estimatedWorkTimeApplicationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fluid/application/estimated-work-time`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FluidApplicationFromJSON));
    }

    /**
     * Get Fluid Applications for a specific estimated work time
     */
    async getFluidApplicationForEstimatedWorkTime(requestParameters: GetFluidApplicationForEstimatedWorkTimeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FluidApplication>> {
        const response = await this.getFluidApplicationForEstimatedWorkTimeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the entire fluids taxonomy for a specific vehicle
     */
    async getFluidTaxonomyForVehicleRaw(requestParameters: GetFluidTaxonomyForVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MotorTaxonomy>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getFluidTaxonomyForVehicle.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getFluidTaxonomyForVehicle.');
        }

        if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
            throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling getFluidTaxonomyForVehicle.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fluid/taxonomy/vehicle`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MotorTaxonomyFromJSON(jsonValue));
    }

    /**
     * Get the entire fluids taxonomy for a specific vehicle
     */
    async getFluidTaxonomyForVehicle(requestParameters: GetFluidTaxonomyForVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MotorTaxonomy> {
        const response = await this.getFluidTaxonomyForVehicleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the entire vehicle-agnostic fluids taxonomy
     */
    async getFluidsTaxonomyRaw(requestParameters: GetFluidsTaxonomyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MotorTaxonomy>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getFluidsTaxonomy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fluid/taxonomy`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MotorTaxonomyFromJSON(jsonValue));
    }

    /**
     * Get the entire vehicle-agnostic fluids taxonomy
     */
    async getFluidsTaxonomy(requestParameters: GetFluidsTaxonomyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MotorTaxonomy> {
        const response = await this.getFluidsTaxonomyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get one full inspection
     */
    async getInspectionRaw(requestParameters: GetInspectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InspectionExpanded>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getInspection.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getInspection.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InspectionExpandedFromJSON(jsonValue));
    }

    /**
     * Get one full inspection
     */
    async getInspection(requestParameters: GetInspectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InspectionExpanded> {
        const response = await this.getInspectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get messages for an inspection entry
     */
    async getInspectionEntryMessagesRaw(requestParameters: GetInspectionEntryMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InspectionConversationMessageEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getInspectionEntryMessages.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.inspectionEntry !== undefined) {
            queryParameters['inspection-entry'] = requestParameters.inspectionEntry;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection-entry-messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InspectionConversationMessageEntryFromJSON));
    }

    /**
     * Get messages for an inspection entry
     */
    async getInspectionEntryMessages(requestParameters: GetInspectionEntryMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InspectionConversationMessageEntry>> {
        const response = await this.getInspectionEntryMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get inspection entry to service associations
     */
    async getInspectionEntryServiceAssociationsRaw(requestParameters: GetInspectionEntryServiceAssociationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InspectionEntryServiceEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getInspectionEntryServiceAssociations.');
        }

        const queryParameters: any = {};

        if (requestParameters.inspectionEntryId !== undefined) {
            queryParameters['inspection-entry-id'] = requestParameters.inspectionEntryId;
        }

        if (requestParameters.serviceId !== undefined) {
            queryParameters['service-id'] = requestParameters.serviceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection-entry-service`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InspectionEntryServiceEntryFromJSON));
    }

    /**
     * Get inspection entry to service associations
     */
    async getInspectionEntryServiceAssociations(requestParameters: GetInspectionEntryServiceAssociationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InspectionEntryServiceEntry>> {
        const response = await this.getInspectionEntryServiceAssociationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all inspection entry to service associations for an API key
     */
    async getInspectionEntryServiceAssociationsByApiKeyRaw(requestParameters: GetInspectionEntryServiceAssociationsByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InspectionEntryServiceEntry>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getInspectionEntryServiceAssociationsByApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection-entry-service/customer-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InspectionEntryServiceEntryFromJSON));
    }

    /**
     * Get all inspection entry to service associations for an API key
     */
    async getInspectionEntryServiceAssociationsByApiKey(requestParameters: GetInspectionEntryServiceAssociationsByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InspectionEntryServiceEntry>> {
        const response = await this.getInspectionEntryServiceAssociationsByApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get inspection entry to service associations for repair order
     */
    async getInspectionEntryServiceAssociationsForRepairOrderRaw(requestParameters: GetInspectionEntryServiceAssociationsForRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InspectionEntryServiceEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getInspectionEntryServiceAssociationsForRepairOrder.');
        }

        const queryParameters: any = {};

        if (requestParameters.repairOrder !== undefined) {
            queryParameters['repair-order'] = requestParameters.repairOrder;
        }

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection-entry-service/repair-order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InspectionEntryServiceEntryFromJSON));
    }

    /**
     * Get inspection entry to service associations for repair order
     */
    async getInspectionEntryServiceAssociationsForRepairOrder(requestParameters: GetInspectionEntryServiceAssociationsForRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InspectionEntryServiceEntry>> {
        const response = await this.getInspectionEntryServiceAssociationsForRepairOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all entries in the shop\'s inspection entry status
     */
    async getInspectionEntryStatusRaw(requestParameters: GetInspectionEntryStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InspectionEntryStatusEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getInspectionEntryStatus.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getInspectionEntryStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.isActive !== undefined) {
            queryParameters['is-active'] = requestParameters.isActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/inspection-entry-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InspectionEntryStatusEntryFromJSON));
    }

    /**
     * Get all entries in the shop\'s inspection entry status
     */
    async getInspectionEntryStatus(requestParameters: GetInspectionEntryStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InspectionEntryStatusEntry>> {
        const response = await this.getInspectionEntryStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get information for a single inspection for viewing by a customer
     */
    async getInspectionForCustomerRaw(requestParameters: GetInspectionForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InspectionExpanded>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getInspectionForCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/customer-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InspectionExpandedFromJSON(jsonValue));
    }

    /**
     * Get information for a single inspection for viewing by a customer
     */
    async getInspectionForCustomer(requestParameters: GetInspectionForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InspectionExpanded> {
        const response = await this.getInspectionForCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get messages for an inspection section
     */
    async getInspectionSectionMessagesRaw(requestParameters: GetInspectionSectionMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InspectionSectionConversationMessageEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getInspectionSectionMessages.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.inspectionSection !== undefined) {
            queryParameters['inspection-section'] = requestParameters.inspectionSection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection-section-messages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InspectionSectionConversationMessageEntryFromJSON));
    }

    /**
     * Get messages for an inspection section
     */
    async getInspectionSectionMessages(requestParameters: GetInspectionSectionMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InspectionSectionConversationMessageEntry>> {
        const response = await this.getInspectionSectionMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get existing intake requests for a customer
     */
    async getIntakeRequestsRaw(requestParameters: GetIntakeRequestsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IntakeRequestEntryExpanded>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getIntakeRequests.');
        }

        const queryParameters: any = {};

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer-id'] = requestParameters.customerId;
        }

        if (requestParameters.intakeRequestId !== undefined) {
            queryParameters['intake-request-id'] = requestParameters.intakeRequestId;
        }

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        if (requestParameters.repairOrderId !== undefined) {
            queryParameters['repair-order-id'] = requestParameters.repairOrderId;
        }

        if (requestParameters.includeArchived !== undefined) {
            queryParameters['include-archived'] = requestParameters.includeArchived;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/intake`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IntakeRequestEntryExpandedFromJSON));
    }

    /**
     * Get existing intake requests for a customer
     */
    async getIntakeRequests(requestParameters: GetIntakeRequestsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IntakeRequestEntryExpanded>> {
        const response = await this.getIntakeRequestsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get inventory adjustments for a shop.
     */
    async getInventoryAdjustmentsRaw(requestParameters: GetInventoryAdjustmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InventoryAdjustmentEntryExpanded>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getInventoryAdjustments.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getInventoryAdjustments.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.instances) {
            queryParameters['instances'] = requestParameters.instances;
        }

        if (requestParameters.entries) {
            queryParameters['entries'] = requestParameters.entries;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/adjustment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InventoryAdjustmentEntryExpandedFromJSON));
    }

    /**
     * Get inventory adjustments for a shop.
     */
    async getInventoryAdjustments(requestParameters: GetInventoryAdjustmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InventoryAdjustmentEntryExpanded>> {
        const response = await this.getInventoryAdjustmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all instances with related entities for a given inventory part
     */
    async getInventoryInstanceExpandedRaw(requestParameters: GetInventoryInstanceExpandedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InventoryPartInstanceExpanded>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getInventoryInstanceExpanded.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getInventoryInstanceExpanded.');
        }

        if (requestParameters.inventoryPart === null || requestParameters.inventoryPart === undefined) {
            throw new runtime.RequiredError('inventoryPart','Required parameter requestParameters.inventoryPart was null or undefined when calling getInventoryInstanceExpanded.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.inventoryPart !== undefined) {
            queryParameters['inventory_part'] = requestParameters.inventoryPart;
        }

        if (requestParameters.isNotCore !== undefined) {
            queryParameters['is_not_core'] = requestParameters.isNotCore;
        }

        if (requestParameters.isClaimed !== undefined) {
            queryParameters['is_claimed'] = requestParameters.isClaimed;
        }

        if (requestParameters.isMarkedForReturnNotReturned !== undefined) {
            queryParameters['is_markedForReturnNotReturned'] = requestParameters.isMarkedForReturnNotReturned;
        }

        if (requestParameters.isReturned !== undefined) {
            queryParameters['is_returned'] = requestParameters.isReturned;
        }

        if (requestParameters.isLost !== undefined) {
            queryParameters['is_lost'] = requestParameters.isLost;
        }

        if (requestParameters.startDateInclusive !== undefined) {
            queryParameters['start_date_inclusive'] = requestParameters.startDateInclusive;
        }

        if (requestParameters.endDateExclusive !== undefined) {
            queryParameters['end_date_exclusive'] = requestParameters.endDateExclusive;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/instance-expanded`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InventoryPartInstanceExpandedFromJSON));
    }

    /**
     * Get all instances with related entities for a given inventory part
     */
    async getInventoryInstanceExpanded(requestParameters: GetInventoryInstanceExpandedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InventoryPartInstanceExpanded>> {
        const response = await this.getInventoryInstanceExpandedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all instances for a given inventory part
     */
    async getInventoryInstancesRaw(requestParameters: GetInventoryInstancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InventoryPartInstanceEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getInventoryInstances.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getInventoryInstances.');
        }

        if (requestParameters.inventoryPart === null || requestParameters.inventoryPart === undefined) {
            throw new runtime.RequiredError('inventoryPart','Required parameter requestParameters.inventoryPart was null or undefined when calling getInventoryInstances.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.inventoryPart !== undefined) {
            queryParameters['inventory_part'] = requestParameters.inventoryPart;
        }

        if (requestParameters.isNotCore !== undefined) {
            queryParameters['is_not_core'] = requestParameters.isNotCore;
        }

        if (requestParameters.isClaimed !== undefined) {
            queryParameters['is_claimed'] = requestParameters.isClaimed;
        }

        if (requestParameters.isMarkedForReturnNotReturned !== undefined) {
            queryParameters['is_markedForReturnNotReturned'] = requestParameters.isMarkedForReturnNotReturned;
        }

        if (requestParameters.isReturned !== undefined) {
            queryParameters['is_returned'] = requestParameters.isReturned;
        }

        if (requestParameters.isLost !== undefined) {
            queryParameters['is_lost'] = requestParameters.isLost;
        }

        if (requestParameters.startDateInclusive !== undefined) {
            queryParameters['start_date_inclusive'] = requestParameters.startDateInclusive;
        }

        if (requestParameters.endDateExclusive !== undefined) {
            queryParameters['end_date_exclusive'] = requestParameters.endDateExclusive;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/instance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InventoryPartInstanceEntryFromJSON));
    }

    /**
     * Get all instances for a given inventory part
     */
    async getInventoryInstances(requestParameters: GetInventoryInstancesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InventoryPartInstanceEntry>> {
        const response = await this.getInventoryInstancesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all stocked parts defined in the shop\'s inventory, together with availability and parts in shop. Excludes cores from both
     */
    async getInventoryLocationsRaw(requestParameters: GetInventoryLocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getInventoryLocations.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getInventoryLocations.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/locations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get all stocked parts defined in the shop\'s inventory, together with availability and parts in shop. Excludes cores from both
     */
    async getInventoryLocations(requestParameters: GetInventoryLocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getInventoryLocationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get non-core instances that are pending-return or returned for a shop.
     */
    async getInventoryNonCoreReturnsRaw(requestParameters: GetInventoryNonCoreReturnsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InventoryPartInstanceEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getInventoryNonCoreReturns.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getInventoryNonCoreReturns.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.isPending !== undefined) {
            queryParameters['is_pending'] = requestParameters.isPending;
        }

        if (requestParameters.endDateExclusive !== undefined) {
            queryParameters['end_date_exclusive'] = requestParameters.endDateExclusive;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        if (requestParameters.stockedOnly !== undefined) {
            queryParameters['stocked_only'] = requestParameters.stockedOnly;
        }

        if (requestParameters.isFluidsOnly !== undefined) {
            queryParameters['is_fluids_only'] = requestParameters.isFluidsOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/instance/non-core-return`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InventoryPartInstanceEntryFromJSON));
    }

    /**
     * Get non-core instances that are pending-return or returned for a shop.
     */
    async getInventoryNonCoreReturns(requestParameters: GetInventoryNonCoreReturnsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InventoryPartInstanceEntry>> {
        const response = await this.getInventoryNonCoreReturnsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List associated PartsTech inventoryPartIds for given partNumbers
     */
    async getInventoryPartIdsByPartNumberRaw(requestParameters: GetInventoryPartIdsByPartNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<string>; }>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getInventoryPartIdsByPartNumber.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getInventoryPartIdsByPartNumber.');
        }

        if (requestParameters.partNumbers === null || requestParameters.partNumbers === undefined) {
            throw new runtime.RequiredError('partNumbers','Required parameter requestParameters.partNumbers was null or undefined when calling getInventoryPartIdsByPartNumber.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.partNumbers) {
            queryParameters['part-numbers'] = requestParameters.partNumbers;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/part-number`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * List associated PartsTech inventoryPartIds for given partNumbers
     */
    async getInventoryPartIdsByPartNumber(requestParameters: GetInventoryPartIdsByPartNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<string>; }> {
        const response = await this.getInventoryPartIdsByPartNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all stocked parts defined in the shop\'s inventory, together with availability and parts in shop. Excludes cores from both
     */
    async getInventoryPartsRaw(requestParameters: GetInventoryPartsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShopInventory>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getInventoryParts.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getInventoryParts.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.stockedOnly !== undefined) {
            queryParameters['stocked_only'] = requestParameters.stockedOnly;
        }

        if (requestParameters.isFluidsOnly !== undefined) {
            queryParameters['is_fluids_only'] = requestParameters.isFluidsOnly;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['page_number'] = requestParameters.pageNumber;
        }

        if (requestParameters.entriesPerPage !== undefined) {
            queryParameters['entries_per_page'] = requestParameters.entriesPerPage;
        }

        if (requestParameters.partId) {
            queryParameters['part_id'] = requestParameters.partId;
        }

        if (requestParameters.cannedJobId) {
            queryParameters['canned_job_id'] = requestParameters.cannedJobId;
        }

        if (requestParameters.repairOrderId) {
            queryParameters['repair_order_id'] = requestParameters.repairOrderId;
        }

        if (requestParameters.counterSaleId) {
            queryParameters['counter_sale_id'] = requestParameters.counterSaleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShopInventoryFromJSON(jsonValue));
    }

    /**
     * Get all stocked parts defined in the shop\'s inventory, together with availability and parts in shop. Excludes cores from both
     */
    async getInventoryParts(requestParameters: GetInventoryPartsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShopInventory> {
        const response = await this.getInventoryPartsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get specific inventory parts by ID
     */
    async getInventoryPartsByIdRaw(requestParameters: GetInventoryPartsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InventoryPartEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getInventoryPartsById.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getInventoryPartsById.');
        }

        if (requestParameters.part === null || requestParameters.part === undefined) {
            throw new runtime.RequiredError('part','Required parameter requestParameters.part was null or undefined when calling getInventoryPartsById.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.part) {
            queryParameters['part'] = requestParameters.part;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InventoryPartEntryFromJSON));
    }

    /**
     * Get specific inventory parts by ID
     */
    async getInventoryPartsById(requestParameters: GetInventoryPartsByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InventoryPartEntry>> {
        const response = await this.getInventoryPartsByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get specific inventory parts by part number and brand
     */
    async getInventoryPartsByPartNumberAndBrandRaw(requestParameters: GetInventoryPartsByPartNumberAndBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InventoryPartEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getInventoryPartsByPartNumberAndBrand.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getInventoryPartsByPartNumberAndBrand.');
        }

        if (requestParameters.part === null || requestParameters.part === undefined) {
            throw new runtime.RequiredError('part','Required parameter requestParameters.part was null or undefined when calling getInventoryPartsByPartNumberAndBrand.');
        }

        if (requestParameters.brand === null || requestParameters.brand === undefined) {
            throw new runtime.RequiredError('brand','Required parameter requestParameters.brand was null or undefined when calling getInventoryPartsByPartNumberAndBrand.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.part !== undefined) {
            queryParameters['part'] = requestParameters.part;
        }

        if (requestParameters.brand !== undefined) {
            queryParameters['brand'] = requestParameters.brand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/part-number-brand`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InventoryPartEntryFromJSON));
    }

    /**
     * Get specific inventory parts by part number and brand
     */
    async getInventoryPartsByPartNumberAndBrand(requestParameters: GetInventoryPartsByPartNumberAndBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InventoryPartEntry>> {
        const response = await this.getInventoryPartsByPartNumberAndBrandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Kpi info of this and last month to date
     */
    async getKpiRaw(requestParameters: GetKpiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Kpi>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getKpi.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getKpi.');
        }

        if (requestParameters.startDateInclusive === null || requestParameters.startDateInclusive === undefined) {
            throw new runtime.RequiredError('startDateInclusive','Required parameter requestParameters.startDateInclusive was null or undefined when calling getKpi.');
        }

        if (requestParameters.endDateExclusive === null || requestParameters.endDateExclusive === undefined) {
            throw new runtime.RequiredError('endDateExclusive','Required parameter requestParameters.endDateExclusive was null or undefined when calling getKpi.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.startDateInclusive !== undefined) {
            queryParameters['start_date_inclusive'] = requestParameters.startDateInclusive;
        }

        if (requestParameters.endDateExclusive !== undefined) {
            queryParameters['end_date_exclusive'] = requestParameters.endDateExclusive;
        }

        if (requestParameters.numMonth !== undefined) {
            queryParameters['num_month'] = requestParameters.numMonth;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/kpi`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(KpiFromJSON));
    }

    /**
     * Get Kpi info of this and last month to date
     */
    async getKpi(requestParameters: GetKpiRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Kpi>> {
        const response = await this.getKpiRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all entries in the shop\'s labor matrix
     */
    async getLaborMatrixEntriesRaw(requestParameters: GetLaborMatrixEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LaborMatrixEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getLaborMatrixEntries.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getLaborMatrixEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/labor-matrix`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LaborMatrixEntryFromJSON));
    }

    /**
     * Get all entries in the shop\'s labor matrix
     */
    async getLaborMatrixEntries(requestParameters: GetLaborMatrixEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LaborMatrixEntry>> {
        const response = await this.getLaborMatrixEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all canned-jobs and customers using a specific labor-matrix-id
     */
    async getLaborMatrixUsersRaw(requestParameters: GetLaborMatrixUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LaborMatrixUsers>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getLaborMatrixUsers.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getLaborMatrixUsers.');
        }

        if (requestParameters.laborMatrix === null || requestParameters.laborMatrix === undefined) {
            throw new runtime.RequiredError('laborMatrix','Required parameter requestParameters.laborMatrix was null or undefined when calling getLaborMatrixUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.laborMatrix !== undefined) {
            queryParameters['labor-matrix'] = requestParameters.laborMatrix;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/labor-matrix/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LaborMatrixUsersFromJSON(jsonValue));
    }

    /**
     * Get all canned-jobs and customers using a specific labor-matrix-id
     */
    async getLaborMatrixUsers(requestParameters: GetLaborMatrixUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LaborMatrixUsers> {
        const response = await this.getLaborMatrixUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List latest appointments at shop
     */
    async getLatestAppointmentsAtShopRaw(requestParameters: GetLatestAppointmentsAtShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AppointmentEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getLatestAppointmentsAtShop.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getLatestAppointmentsAtShop.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.startDateInclusive !== undefined) {
            queryParameters['start_date_inclusive'] = requestParameters.startDateInclusive;
        }

        if (requestParameters.endDateExclusive !== undefined) {
            queryParameters['end_date_exclusive'] = requestParameters.endDateExclusive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment/shop`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppointmentEntryFromJSON));
    }

    /**
     * List latest appointments at shop
     */
    async getLatestAppointmentsAtShop(requestParameters: GetLatestAppointmentsAtShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppointmentEntry>> {
        const response = await this.getLatestAppointmentsAtShopRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List latest appointments for resource
     */
    async getLatestAppointmentsForResourceRaw(requestParameters: GetLatestAppointmentsForResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AppointmentEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getLatestAppointmentsForResource.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getLatestAppointmentsForResource.');
        }

        if (requestParameters.resource === null || requestParameters.resource === undefined) {
            throw new runtime.RequiredError('resource','Required parameter requestParameters.resource was null or undefined when calling getLatestAppointmentsForResource.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.resource !== undefined) {
            queryParameters['resource'] = requestParameters.resource;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment/resource`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AppointmentEntryFromJSON));
    }

    /**
     * List latest appointments for resource
     */
    async getLatestAppointmentsForResource(requestParameters: GetLatestAppointmentsForResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AppointmentEntry>> {
        const response = await this.getLatestAppointmentsForResourceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets an exploded timeline of maintenance schedules for a vehicle by a specified interval
     */
    async getMaintenanceScheduleTimelineRaw(requestParameters: GetMaintenanceScheduleTimelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MaintenanceScheduleTimeline>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getMaintenanceScheduleTimeline.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getMaintenanceScheduleTimeline.');
        }

        if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
            throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling getMaintenanceScheduleTimeline.');
        }

        if (requestParameters.intervalType === null || requestParameters.intervalType === undefined) {
            throw new runtime.RequiredError('intervalType','Required parameter requestParameters.intervalType was null or undefined when calling getMaintenanceScheduleTimeline.');
        }

        if (requestParameters.intervalValue === null || requestParameters.intervalValue === undefined) {
            throw new runtime.RequiredError('intervalValue','Required parameter requestParameters.intervalValue was null or undefined when calling getMaintenanceScheduleTimeline.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        if (requestParameters.intervalType !== undefined) {
            queryParameters['interval-type'] = requestParameters.intervalType;
        }

        if (requestParameters.intervalValue !== undefined) {
            queryParameters['interval-value'] = requestParameters.intervalValue;
        }

        if (requestParameters.severity !== undefined) {
            queryParameters['severity'] = requestParameters.severity;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/maintenance-schedule/timeline`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MaintenanceScheduleTimelineFromJSON(jsonValue));
    }

    /**
     * Gets an exploded timeline of maintenance schedules for a vehicle by a specified interval
     */
    async getMaintenanceScheduleTimeline(requestParameters: GetMaintenanceScheduleTimelineRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MaintenanceScheduleTimeline> {
        const response = await this.getMaintenanceScheduleTimelineRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all vehicles for a customer
     */
    async getManyCustomerVehiclesRaw(requestParameters: GetManyCustomerVehiclesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<VehicleEntry>; }>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getManyCustomerVehicles.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getManyCustomerVehicles.');
        }

        if (requestParameters.customer === null || requestParameters.customer === undefined) {
            throw new runtime.RequiredError('customer','Required parameter requestParameters.customer was null or undefined when calling getManyCustomerVehicles.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.customer) {
            queryParameters['customer'] = requestParameters.customer;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/multiple-customer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get all vehicles for a customer
     */
    async getManyCustomerVehicles(requestParameters: GetManyCustomerVehiclesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<VehicleEntry>; }> {
        const response = await this.getManyCustomerVehiclesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get multiple vehicles\' info from a license-plate
     */
    async getMultipleVehicleInfoFromLicensePlateRaw(requestParameters: GetMultipleVehicleInfoFromLicensePlateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleInformation>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getMultipleVehicleInfoFromLicensePlate.');
        }

        if (requestParameters.registeredCountry === null || requestParameters.registeredCountry === undefined) {
            throw new runtime.RequiredError('registeredCountry','Required parameter requestParameters.registeredCountry was null or undefined when calling getMultipleVehicleInfoFromLicensePlate.');
        }

        if (requestParameters.registeredState === null || requestParameters.registeredState === undefined) {
            throw new runtime.RequiredError('registeredState','Required parameter requestParameters.registeredState was null or undefined when calling getMultipleVehicleInfoFromLicensePlate.');
        }

        if (requestParameters.licensePlate === null || requestParameters.licensePlate === undefined) {
            throw new runtime.RequiredError('licensePlate','Required parameter requestParameters.licensePlate was null or undefined when calling getMultipleVehicleInfoFromLicensePlate.');
        }

        const queryParameters: any = {};

        if (requestParameters.registeredCountry !== undefined) {
            queryParameters['registered-country'] = requestParameters.registeredCountry;
        }

        if (requestParameters.registeredState !== undefined) {
            queryParameters['registered-state'] = requestParameters.registeredState;
        }

        if (requestParameters.licensePlate !== undefined) {
            queryParameters['license-plate'] = requestParameters.licensePlate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/license-plate-multiple`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleInformationFromJSON));
    }

    /**
     * Get multiple vehicles\' info from a license-plate
     */
    async getMultipleVehicleInfoFromLicensePlate(requestParameters: GetMultipleVehicleInfoFromLicensePlateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleInformation>> {
        const response = await this.getMultipleVehicleInfoFromLicensePlateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get my pin
     */
    async getMyPinRaw(requestParameters: GetMyPinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getMyPin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/pin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get my pin
     */
    async getMyPin(requestParameters: GetMyPinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getMyPinRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get my profile info
     */
    async getMyProfileRaw(requestParameters: GetMyProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserProfile>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getMyProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileFromJSON(jsonValue));
    }

    /**
     * Get my profile info
     */
    async getMyProfile(requestParameters: GetMyProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserProfile> {
        const response = await this.getMyProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all shops I have a role in
     */
    async getMyRolesRaw(requestParameters: GetMyRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MyRole>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getMyRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/my-roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MyRoleFromJSON));
    }

    /**
     * Get all shops I have a role in
     */
    async getMyRoles(requestParameters: GetMyRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MyRole>> {
        const response = await this.getMyRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all unread activity
     */
    async getNewActivityLogRaw(requestParameters: GetNewActivityLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getNewActivityLog.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/new`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get all unread activity
     */
    async getNewActivityLog(requestParameters: GetNewActivityLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getNewActivityLogRaw(requestParameters, initOverrides);
    }

    /**
     * Get numbers of unread notifications for a user
     */
    async getNumUnreadNotificationsRaw(requestParameters: GetNumUnreadNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getNumUnreadNotifications.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getNumUnreadNotifications.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/unread`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get numbers of unread notifications for a user
     */
    async getNumUnreadNotifications(requestParameters: GetNumUnreadNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getNumUnreadNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get user-profile-info for specified users
     */
    async getOtherPeopleProfilesRaw(requestParameters: GetOtherPeopleProfilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserProfile>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getOtherPeopleProfiles.');
        }

        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling getOtherPeopleProfiles.');
        }

        const queryParameters: any = {};

        if (requestParameters.user) {
            queryParameters['user'] = requestParameters.user;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/get-profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserProfileFromJSON));
    }

    /**
     * Get user-profile-info for specified users
     */
    async getOtherPeopleProfiles(requestParameters: GetOtherPeopleProfilesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserProfile>> {
        const response = await this.getOtherPeopleProfilesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all entries in the shop\'s parts matrix
     */
    async getPartsMatrixEntriesRaw(requestParameters: GetPartsMatrixEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PartsMatrixEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getPartsMatrixEntries.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getPartsMatrixEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/parts-matrix`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PartsMatrixEntryFromJSON));
    }

    /**
     * Get all entries in the shop\'s parts matrix
     */
    async getPartsMatrixEntries(requestParameters: GetPartsMatrixEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PartsMatrixEntry>> {
        const response = await this.getPartsMatrixEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all canned-jobs using a specific parts-matrix-id
     */
    async getPartsMatrixUsersRaw(requestParameters: GetPartsMatrixUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PartsMatrixUsers>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getPartsMatrixUsers.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getPartsMatrixUsers.');
        }

        if (requestParameters.partsMatrix === null || requestParameters.partsMatrix === undefined) {
            throw new runtime.RequiredError('partsMatrix','Required parameter requestParameters.partsMatrix was null or undefined when calling getPartsMatrixUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.partsMatrix !== undefined) {
            queryParameters['parts-matrix'] = requestParameters.partsMatrix;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/parts-matrix/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PartsMatrixUsersFromJSON(jsonValue));
    }

    /**
     * Get all canned-jobs using a specific parts-matrix-id
     */
    async getPartsMatrixUsers(requestParameters: GetPartsMatrixUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PartsMatrixUsers> {
        const response = await this.getPartsMatrixUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the entire partsTech part taxonomy
     */
    async getPartsTechPartTaxonomyRaw(requestParameters: GetPartsTechPartTaxonomyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PartsTechPartTaxonomy>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getPartsTechPartTaxonomy.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getPartsTechPartTaxonomy.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/partstech-taxonomy`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PartsTechPartTaxonomyFromJSON(jsonValue));
    }

    /**
     * Get the entire partsTech part taxonomy
     */
    async getPartsTechPartTaxonomy(requestParameters: GetPartsTechPartTaxonomyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PartsTechPartTaxonomy> {
        const response = await this.getPartsTechPartTaxonomyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns a table of PartID : InventoryPartID : QuantityAvailable for for requested part_ids
     */
    async getPartsTechPartsInInventoryRaw(requestParameters: GetPartsTechPartsInInventoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: { [key: string]: number; }; }>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getPartsTechPartsInInventory.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getPartsTechPartsInInventory.');
        }

        if (requestParameters.partId === null || requestParameters.partId === undefined) {
            throw new runtime.RequiredError('partId','Required parameter requestParameters.partId was null or undefined when calling getPartsTechPartsInInventory.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.partId) {
            queryParameters['part_id'] = requestParameters.partId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/parts-tech`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Returns a table of PartID : InventoryPartID : QuantityAvailable for for requested part_ids
     */
    async getPartsTechPartsInInventory(requestParameters: GetPartsTechPartsInInventoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: { [key: string]: number; }; }> {
        const response = await this.getPartsTechPartsInInventoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPingPersonRaw(requestParameters: GetPingPersonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PingPerson>> {
        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.age !== undefined) {
            queryParameters['age'] = requestParameters.age;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ping/ping-person-schemed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PingPersonFromJSON(jsonValue));
    }

    /**
     */
    async getPingPerson(requestParameters: GetPingPersonRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PingPerson> {
        const response = await this.getPingPersonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPingTeamRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PingTeam>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ping/ping-team-schemed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PingTeamFromJSON(jsonValue));
    }

    /**
     */
    async getPingTeam(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PingTeam> {
        const response = await this.getPingTeamRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPongRaw(requestParameters: GetPongRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.myParam !== undefined) {
            queryParameters['my-param'] = requestParameters.myParam;
        }

        if (requestParameters.test !== undefined) {
            queryParameters['test'] = requestParameters.test;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ping/pong`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getPong(requestParameters: GetPongRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getPongRaw(requestParameters, initOverrides);
    }

    /**
     * Get all customer-selectable previously deferred services for a vehicle
     */
    async getPreviouslyDeferredServicesRaw(requestParameters: GetPreviouslyDeferredServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getPreviouslyDeferredServices.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling getPreviouslyDeferredServices.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getPreviouslyDeferredServices.');
        }

        if (requestParameters.vehicleId === null || requestParameters.vehicleId === undefined) {
            throw new runtime.RequiredError('vehicleId','Required parameter requestParameters.vehicleId was null or undefined when calling getPreviouslyDeferredServices.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer-id'] = requestParameters.customerId;
        }

        if (requestParameters.vehicleId !== undefined) {
            queryParameters['vehicle-id'] = requestParameters.vehicleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/intake/previously-deferred`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceEntryFromJSON));
    }

    /**
     * Get all customer-selectable previously deferred services for a vehicle
     */
    async getPreviouslyDeferredServices(requestParameters: GetPreviouslyDeferredServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceEntry>> {
        const response = await this.getPreviouslyDeferredServicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all customer-selectable previously deferred services for a vehicle with verification id
     */
    async getPreviouslyDeferredServicesWithVerificationIdRaw(requestParameters: GetPreviouslyDeferredServicesWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceEntry>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getPreviouslyDeferredServicesWithVerificationId.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling getPreviouslyDeferredServicesWithVerificationId.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getPreviouslyDeferredServicesWithVerificationId.');
        }

        if (requestParameters.vehicleId === null || requestParameters.vehicleId === undefined) {
            throw new runtime.RequiredError('vehicleId','Required parameter requestParameters.vehicleId was null or undefined when calling getPreviouslyDeferredServicesWithVerificationId.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer-id'] = requestParameters.customerId;
        }

        if (requestParameters.vehicleId !== undefined) {
            queryParameters['vehicle-id'] = requestParameters.vehicleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/intake/previously-deferred/verification-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceEntryFromJSON));
    }

    /**
     * Get all customer-selectable previously deferred services for a vehicle with verification id
     */
    async getPreviouslyDeferredServicesWithVerificationId(requestParameters: GetPreviouslyDeferredServicesWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceEntry>> {
        const response = await this.getPreviouslyDeferredServicesWithVerificationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get pricing levels for a shop
     */
    async getPriceLevelsRaw(requestParameters: GetPriceLevelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PriceLevelEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getPriceLevels.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getPriceLevels.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/price-level`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PriceLevelEntryFromJSON));
    }

    /**
     * Get pricing levels for a shop
     */
    async getPriceLevels(requestParameters: GetPriceLevelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PriceLevelEntry>> {
        const response = await this.getPriceLevelsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get previous printable document print actions for a repair order
     */
    async getPrintActionHistoryForRepairOrderRaw(requestParameters: GetPrintActionHistoryForRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PrintActionEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getPrintActionHistoryForRepairOrder.');
        }

        if (requestParameters.repairOrder === null || requestParameters.repairOrder === undefined) {
            throw new runtime.RequiredError('repairOrder','Required parameter requestParameters.repairOrder was null or undefined when calling getPrintActionHistoryForRepairOrder.');
        }

        const queryParameters: any = {};

        if (requestParameters.repairOrder !== undefined) {
            queryParameters['repair-order'] = requestParameters.repairOrder;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/print-action`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PrintActionEntryFromJSON));
    }

    /**
     * Get previous printable document print actions for a repair order
     */
    async getPrintActionHistoryForRepairOrder(requestParameters: GetPrintActionHistoryForRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PrintActionEntry>> {
        const response = await this.getPrintActionHistoryForRepairOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPrivacyPolicyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/static/privacy-policy`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getPrivacyPolicy(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getPrivacyPolicyRaw(initOverrides);
    }

    /**
     * Get all entries in the shop\'s productivity status
     */
    async getProductivityStatusRaw(requestParameters: GetProductivityStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductivityStatusEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getProductivityStatus.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getProductivityStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/productivity-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductivityStatusEntryFromJSON));
    }

    /**
     * Get all entries in the shop\'s productivity status
     */
    async getProductivityStatus(requestParameters: GetProductivityStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductivityStatusEntry>> {
        const response = await this.getProductivityStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all customer-selectable canned jobs for a shop
     */
    async getPublicCannedJobsRaw(requestParameters: GetPublicCannedJobsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CannedJobEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getPublicCannedJobs.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getPublicCannedJobs.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/intake/canned-jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CannedJobEntryFromJSON));
    }

    /**
     * Get all customer-selectable canned jobs for a shop
     */
    async getPublicCannedJobs(requestParameters: GetPublicCannedJobsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CannedJobEntry>> {
        const response = await this.getPublicCannedJobsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all customer-selectable canned jobs for a shop with verification id
     */
    async getPublicCannedJobsWithVerificationIdRaw(requestParameters: GetPublicCannedJobsWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CannedJobEntry>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getPublicCannedJobsWithVerificationId.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling getPublicCannedJobsWithVerificationId.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getPublicCannedJobsWithVerificationId.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer-id'] = requestParameters.customerId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/intake/canned-jobs/verification-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CannedJobEntryFromJSON));
    }

    /**
     * Get all customer-selectable canned jobs for a shop with verification id
     */
    async getPublicCannedJobsWithVerificationId(requestParameters: GetPublicCannedJobsWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CannedJobEntry>> {
        const response = await this.getPublicCannedJobsWithVerificationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get public shop information by ID
     */
    async getPublicShopInfoRaw(requestParameters: GetPublicShopInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PublicShopEntry>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPublicShopInfo.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/intake/shop`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicShopEntryFromJSON(jsonValue));
    }

    /**
     * Get public shop information by ID
     */
    async getPublicShopInfo(requestParameters: GetPublicShopInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PublicShopEntry> {
        const response = await this.getPublicShopInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a purchase order and related inventory instances by ID and shop.
     */
    async getPurchaseOrderRaw(requestParameters: GetPurchaseOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PurchaseOrderEntryExpanded>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getPurchaseOrder.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getPurchaseOrder.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPurchaseOrder.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchase-order/id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PurchaseOrderEntryExpandedFromJSON(jsonValue));
    }

    /**
     * Get a purchase order and related inventory instances by ID and shop.
     */
    async getPurchaseOrder(requestParameters: GetPurchaseOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PurchaseOrderEntryExpanded> {
        const response = await this.getPurchaseOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all purchase orders for a shop. Include related inventory instances.
     */
    async getPurchaseOrdersRaw(requestParameters: GetPurchaseOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PurchaseOrderEntryExpanded>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getPurchaseOrders.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getPurchaseOrders.');
        }

        if (requestParameters.includeArchived === null || requestParameters.includeArchived === undefined) {
            throw new runtime.RequiredError('includeArchived','Required parameter requestParameters.includeArchived was null or undefined when calling getPurchaseOrders.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.includeArchived !== undefined) {
            queryParameters['include-archived'] = requestParameters.includeArchived;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchase-order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PurchaseOrderEntryExpandedFromJSON));
    }

    /**
     * Get all purchase orders for a shop. Include related inventory instances.
     */
    async getPurchaseOrders(requestParameters: GetPurchaseOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PurchaseOrderEntryExpanded>> {
        const response = await this.getPurchaseOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get repair link access token
     */
    async getRepairLinkAccessTokenRaw(requestParameters: GetRepairLinkAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getRepairLinkAccessToken.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getRepairLinkAccessToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.shop !== undefined) {
            formParams.append('shop', requestParameters.shop as any);
        }

        const response = await this.request({
            path: `/repair-link/access-token/existing`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get repair link access token
     */
    async getRepairLinkAccessToken(requestParameters: GetRepairLinkAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getRepairLinkAccessTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get repair link orders at shop
     */
    async getRepairLinkOrdersRaw(requestParameters: GetRepairLinkOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepairLinkOrderEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getRepairLinkOrders.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getRepairLinkOrders.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair-link/orders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepairLinkOrderEntryFromJSON));
    }

    /**
     * Get repair link orders at shop
     */
    async getRepairLinkOrders(requestParameters: GetRepairLinkOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepairLinkOrderEntry>> {
        const response = await this.getRepairLinkOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all media items by a repair order ID or by a list of attachment IDs
     */
    async getRepairOrderAttachmentEntriesRaw(requestParameters: GetRepairOrderAttachmentEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepairOrderAttachmentEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getRepairOrderAttachmentEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.repairOrder !== undefined) {
            queryParameters['repair-order'] = requestParameters.repairOrder;
        }

        if (requestParameters.attachmentIds) {
            queryParameters['attachment-ids'] = requestParameters.attachmentIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/attachment`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepairOrderAttachmentEntryFromJSON));
    }

    /**
     * List all media items by a repair order ID or by a list of attachment IDs
     */
    async getRepairOrderAttachmentEntries(requestParameters: GetRepairOrderAttachmentEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepairOrderAttachmentEntry>> {
        const response = await this.getRepairOrderAttachmentEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRepairOrderByApiKeyRaw(requestParameters: GetRepairOrderByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RepairOrderExpanded>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getRepairOrderByApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/customer-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairOrderExpandedFromJSON(jsonValue));
    }

    /**
     */
    async getRepairOrderByApiKey(requestParameters: GetRepairOrderByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RepairOrderExpanded> {
        const response = await this.getRepairOrderByApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all media items by a repair order ID or by a list of media item IDs
     */
    async getRepairOrderMediaEntriesRaw(requestParameters: GetRepairOrderMediaEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepairOrderMediaEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getRepairOrderMediaEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.repairOrder !== undefined) {
            queryParameters['repair-order'] = requestParameters.repairOrder;
        }

        if (requestParameters.mediaIds) {
            queryParameters['media-ids'] = requestParameters.mediaIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/media`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepairOrderMediaEntryFromJSON));
    }

    /**
     * List all media items by a repair order ID or by a list of media item IDs
     */
    async getRepairOrderMediaEntries(requestParameters: GetRepairOrderMediaEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepairOrderMediaEntry>> {
        const response = await this.getRepairOrderMediaEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRepairOrderMediaEntriesByApiKeyRaw(requestParameters: GetRepairOrderMediaEntriesByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepairOrderMediaEntry>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getRepairOrderMediaEntriesByApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/media/api-key`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepairOrderMediaEntryFromJSON));
    }

    /**
     */
    async getRepairOrderMediaEntriesByApiKey(requestParameters: GetRepairOrderMediaEntriesByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepairOrderMediaEntry>> {
        const response = await this.getRepairOrderMediaEntriesByApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all services for a repair order
     */
    async getRepairOrderServicesRaw(requestParameters: GetRepairOrderServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceExpanded>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getRepairOrderServices.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getRepairOrderServices.');
        }

        if (requestParameters.repairOrder === null || requestParameters.repairOrder === undefined) {
            throw new runtime.RequiredError('repairOrder','Required parameter requestParameters.repairOrder was null or undefined when calling getRepairOrderServices.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.repairOrder) {
            queryParameters['repair_order'] = requestParameters.repairOrder;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceExpandedFromJSON));
    }

    /**
     * Get all services for a repair order
     */
    async getRepairOrderServices(requestParameters: GetRepairOrderServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceExpanded>> {
        const response = await this.getRepairOrderServicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get CSV of all repair-orders matching the requested criteria
     */
    async getRepairOrdersCsvRaw(requestParameters: GetRepairOrdersCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getRepairOrdersCsv.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/csv`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetRepairOrdersCSVRequestToJSON(requestParameters.getRepairOrdersCSVRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get CSV of all repair-orders matching the requested criteria
     */
    async getRepairOrdersCsv(requestParameters: GetRepairOrdersCsvRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getRepairOrdersCsvRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list reviews submitted for a repair order
     */
    async getReviewsRaw(requestParameters: GetReviewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerFeedbackEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getReviews.');
        }

        if (requestParameters.repairOrderId === null || requestParameters.repairOrderId === undefined) {
            throw new runtime.RequiredError('repairOrderId','Required parameter requestParameters.repairOrderId was null or undefined when calling getReviews.');
        }

        const queryParameters: any = {};

        if (requestParameters.repairOrderId !== undefined) {
            queryParameters['repair-order-id'] = requestParameters.repairOrderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/feedback`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerFeedbackEntryFromJSON));
    }

    /**
     * Get a list reviews submitted for a repair order
     */
    async getReviews(requestParameters: GetReviewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerFeedbackEntry>> {
        const response = await this.getReviewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get permissions for a specific role
     */
    async getRolePermissionsRaw(requestParameters: GetRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getRolePermissions.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling getRolePermissions.');
        }

        const queryParameters: any = {};

        if (requestParameters.role !== undefined) {
            queryParameters['role'] = requestParameters.role;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/role/permission`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get permissions for a specific role
     */
    async getRolePermissions(requestParameters: GetRolePermissionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getRolePermissionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all roles for a specific shop
     */
    async getRolesRaw(requestParameters: GetRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Role>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getRoles.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getRoles.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/role`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleFromJSON));
    }

    /**
     * Get all roles for a specific shop
     */
    async getRoles(requestParameters: GetRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Role>> {
        const response = await this.getRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get service advisor for an API key
     */
    async getServiceAdvisorForApiKeyRaw(requestParameters: GetServiceAdvisorForApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserProfile>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getServiceAdvisorForApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/service-advisor/customer-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserProfileFromJSON));
    }

    /**
     * Get service advisor for an API key
     */
    async getServiceAdvisorForApiKey(requestParameters: GetServiceAdvisorForApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserProfile>> {
        const response = await this.getServiceAdvisorForApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get user profiles of technicians assigned to services associated with API key
     */
    async getServiceTechniciansByApiKeyRaw(requestParameters: GetServiceTechniciansByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserProfile>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getServiceTechniciansByApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/api-key-service-techs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserProfileFromJSON));
    }

    /**
     * Get user profiles of technicians assigned to services associated with API key
     */
    async getServiceTechniciansByApiKey(requestParameters: GetServiceTechniciansByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserProfile>> {
        const response = await this.getServiceTechniciansByApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get associated service entries
     */
    async getServicesRaw(requestParameters: GetServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getServices.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getServices.');
        }

        if (requestParameters.services === null || requestParameters.services === undefined) {
            throw new runtime.RequiredError('services','Required parameter requestParameters.services was null or undefined when calling getServices.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.services) {
            queryParameters['services'] = requestParameters.services;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/specific`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceEntryFromJSON));
    }

    /**
     * Get associated service entries
     */
    async getServices(requestParameters: GetServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceEntry>> {
        const response = await this.getServicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all services for a repair order by API key
     */
    async getServicesByApiKeyRaw(requestParameters: GetServicesByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ServiceExpanded>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getServicesByApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/customer-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceExpandedFromJSON));
    }

    /**
     * Get all services for a repair order by API key
     */
    async getServicesByApiKey(requestParameters: GetServicesByApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ServiceExpanded>> {
        const response = await this.getServicesByApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get my push-notification settings
     */
    async getSettingsRaw(requestParameters: GetSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/setting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get my push-notification settings
     */
    async getSettings(requestParameters: GetSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getSettingsRaw(requestParameters, initOverrides);
    }

    /**
     * Get all shop ids
     */
    async getShopIdsRaw(requestParameters: GetShopIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getShopIds.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/all-shop-ids`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get all shop ids
     */
    async getShopIds(requestParameters: GetShopIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getShopIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get shop logo as a base64 encoded string
     */
    async getShopLogoForDymoRaw(requestParameters: GetShopLogoForDymoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getShopLogoForDymo.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getShopLogoForDymo.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/logo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get shop logo as a base64 encoded string
     */
    async getShopLogoForDymo(requestParameters: GetShopLogoForDymoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getShopLogoForDymoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a shop\'s profile
     */
    async getShopProfileRaw(requestParameters: GetShopProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShopEntry>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getShopProfile.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getShopProfile.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShopEntryFromJSON(jsonValue));
    }

    /**
     * Get a shop\'s profile
     */
    async getShopProfile(requestParameters: GetShopProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShopEntry> {
        const response = await this.getShopProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a shop\'s profile information for customers to view
     */
    async getShopProfileForCustomersRaw(requestParameters: GetShopProfileForCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShopEntry>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getShopProfileForCustomers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/customer-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShopEntryFromJSON(jsonValue));
    }

    /**
     * Get a shop\'s profile information for customers to view
     */
    async getShopProfileForCustomers(requestParameters: GetShopProfileForCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShopEntry> {
        const response = await this.getShopProfileForCustomersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get taxes and fees for shop
     */
    async getTaxesAndFeesForShopRaw(requestParameters: GetTaxesAndFeesForShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TaxesAndFeesEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getTaxesAndFeesForShop.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getTaxesAndFeesForShop.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/taxes-fees/shop`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaxesAndFeesEntryFromJSON));
    }

    /**
     * Get taxes and fees for shop
     */
    async getTaxesAndFeesForShop(requestParameters: GetTaxesAndFeesForShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TaxesAndFeesEntry>> {
        const response = await this.getTaxesAndFeesForShopRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get technician for an API key
     */
    async getTechnicianForApiKeyRaw(requestParameters: GetTechnicianForApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserProfile>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getTechnicianForApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/technician/customer-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserProfileFromJSON));
    }

    /**
     * Get technician for an API key
     */
    async getTechnicianForApiKey(requestParameters: GetTechnicianForApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserProfile>> {
        const response = await this.getTechnicianForApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTermsOfUseRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/static/terms-of-use`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async getTermsOfUse(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getTermsOfUseRaw(initOverrides);
    }

    /**
     * Get raw response with transfer id
     */
    async getTransferRaw(requestParameters: GetTransferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getTransfer.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getTransfer.');
        }

        if (requestParameters.transferId === null || requestParameters.transferId === undefined) {
            throw new runtime.RequiredError('transferId','Required parameter requestParameters.transferId was null or undefined when calling getTransfer.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.transferId !== undefined) {
            queryParameters['transfer-id'] = requestParameters.transferId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/payment/finix/transfer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get raw response with transfer id
     */
    async getTransfer(requestParameters: GetTransferRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getTransferRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get link for tru speed repair manual
     */
    async getTruSpeedLinkRaw(requestParameters: GetTruSpeedLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getTruSpeedLink.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getTruSpeedLink.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/tru-speed/link`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get link for tru speed repair manual
     */
    async getTruSpeedLink(requestParameters: GetTruSpeedLinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getTruSpeedLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get access token for user
     */
    async getTwilioAccessTokenRaw(requestParameters: GetTwilioAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getTwilioAccessToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/conversation/access-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get access token for user
     */
    async getTwilioAccessToken(requestParameters: GetTwilioAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getTwilioAccessTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get unarchived automated vehicle reminders by rdate
     */
    async getUnarchivedAutomatedVehicleReminderByRdateRaw(requestParameters: GetUnarchivedAutomatedVehicleReminderByRdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AutomatedVehicleReminderEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getUnarchivedAutomatedVehicleReminderByRdate.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getUnarchivedAutomatedVehicleReminderByRdate.');
        }

        if (requestParameters.rdate === null || requestParameters.rdate === undefined) {
            throw new runtime.RequiredError('rdate','Required parameter requestParameters.rdate was null or undefined when calling getUnarchivedAutomatedVehicleReminderByRdate.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.rdate !== undefined) {
            queryParameters['rdate'] = requestParameters.rdate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/automated-vehicle-reminder/rdate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AutomatedVehicleReminderEntryFromJSON));
    }

    /**
     * Get unarchived automated vehicle reminders by rdate
     */
    async getUnarchivedAutomatedVehicleReminderByRdate(requestParameters: GetUnarchivedAutomatedVehicleReminderByRdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AutomatedVehicleReminderEntry>> {
        const response = await this.getUnarchivedAutomatedVehicleReminderByRdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get unread notifications by modified entity
     */
    async getUnreadNotificationsByModifiedEntityRaw(requestParameters: GetUnreadNotificationsByModifiedEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NotificationEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getUnreadNotificationsByModifiedEntity.');
        }

        const queryParameters: any = {};

        if (requestParameters.modifiedEntity) {
            queryParameters['modified-entity'] = requestParameters.modifiedEntity;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/modified-entity`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationEntryFromJSON));
    }

    /**
     * Get unread notifications by modified entity
     */
    async getUnreadNotificationsByModifiedEntity(requestParameters: GetUnreadNotificationsByModifiedEntityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NotificationEntry>> {
        const response = await this.getUnreadNotificationsByModifiedEntityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get upcoming unarchived automated vehicle reminders
     */
    async getUpcomingUnarchivedAutomatedVehicleReminderRaw(requestParameters: GetUpcomingUnarchivedAutomatedVehicleReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AutomatedVehicleReminderEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getUpcomingUnarchivedAutomatedVehicleReminder.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getUpcomingUnarchivedAutomatedVehicleReminder.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/automated-vehicle-reminder/upcoming`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AutomatedVehicleReminderEntryFromJSON));
    }

    /**
     * Get upcoming unarchived automated vehicle reminders
     */
    async getUpcomingUnarchivedAutomatedVehicleReminder(requestParameters: GetUpcomingUnarchivedAutomatedVehicleReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AutomatedVehicleReminderEntry>> {
        const response = await this.getUpcomingUnarchivedAutomatedVehicleReminderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all users at a shop who have been granted a specific permission
     */
    async getUsersWithPermissionRaw(requestParameters: GetUsersWithPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserProfile>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getUsersWithPermission.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getUsersWithPermission.');
        }

        if (requestParameters.act === null || requestParameters.act === undefined) {
            throw new runtime.RequiredError('act','Required parameter requestParameters.act was null or undefined when calling getUsersWithPermission.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.act !== undefined) {
            queryParameters['act'] = requestParameters.act;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/user-role/permission`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserProfileFromJSON));
    }

    /**
     * Get all users at a shop who have been granted a specific permission
     */
    async getUsersWithPermission(requestParameters: GetUsersWithPermissionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserProfile>> {
        const response = await this.getUsersWithPermissionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get images for a vehicle
     */
    async getVehicleImagesRaw(requestParameters: GetVehicleImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleImageEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getVehicleImages.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getVehicleImages.');
        }

        if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
            throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling getVehicleImages.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/images`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleImageEntryFromJSON));
    }

    /**
     * Get images for a vehicle
     */
    async getVehicleImages(requestParameters: GetVehicleImagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleImageEntry>> {
        const response = await this.getVehicleImagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get vehicle image for customer view
     */
    async getVehicleImagesForApiKeyRaw(requestParameters: GetVehicleImagesForApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleImageEntry>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getVehicleImagesForApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/vehicle-image/customer-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleImageEntryFromJSON));
    }

    /**
     * Get vehicle image for customer view
     */
    async getVehicleImagesForApiKey(requestParameters: GetVehicleImagesForApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleImageEntry>> {
        const response = await this.getVehicleImagesForApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a vehicle\'s info
     */
    async getVehicleInfoRaw(requestParameters: GetVehicleInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getVehicleInfo.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getVehicleInfo.');
        }

        if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
            throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling getVehicleInfo.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vehicle) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleEntryFromJSON));
    }

    /**
     * Get a vehicle\'s info
     */
    async getVehicleInfo(requestParameters: GetVehicleInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleEntry>> {
        const response = await this.getVehicleInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a single vehicle\'s info for a customer to view
     */
    async getVehicleInfoForCustomerRaw(requestParameters: GetVehicleInfoForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleEntry>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getVehicleInfoForCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/customer-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleEntryFromJSON(jsonValue));
    }

    /**
     * Get a single vehicle\'s info for a customer to view
     */
    async getVehicleInfoForCustomer(requestParameters: GetVehicleInfoForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleEntry> {
        const response = await this.getVehicleInfoForCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a vehicle\'s info from a license-plate
     */
    async getVehicleInfoFromLicensePlateRaw(requestParameters: GetVehicleInfoFromLicensePlateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleInformation>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getVehicleInfoFromLicensePlate.');
        }

        if (requestParameters.registeredCountry === null || requestParameters.registeredCountry === undefined) {
            throw new runtime.RequiredError('registeredCountry','Required parameter requestParameters.registeredCountry was null or undefined when calling getVehicleInfoFromLicensePlate.');
        }

        if (requestParameters.registeredState === null || requestParameters.registeredState === undefined) {
            throw new runtime.RequiredError('registeredState','Required parameter requestParameters.registeredState was null or undefined when calling getVehicleInfoFromLicensePlate.');
        }

        if (requestParameters.licensePlate === null || requestParameters.licensePlate === undefined) {
            throw new runtime.RequiredError('licensePlate','Required parameter requestParameters.licensePlate was null or undefined when calling getVehicleInfoFromLicensePlate.');
        }

        const queryParameters: any = {};

        if (requestParameters.registeredCountry !== undefined) {
            queryParameters['registered-country'] = requestParameters.registeredCountry;
        }

        if (requestParameters.registeredState !== undefined) {
            queryParameters['registered-state'] = requestParameters.registeredState;
        }

        if (requestParameters.licensePlate !== undefined) {
            queryParameters['license-plate'] = requestParameters.licensePlate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/license-plate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleInformationFromJSON(jsonValue));
    }

    /**
     * Get a vehicle\'s info from a license-plate
     */
    async getVehicleInfoFromLicensePlate(requestParameters: GetVehicleInfoFromLicensePlateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleInformation> {
        const response = await this.getVehicleInfoFromLicensePlateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a vehicle\'s info from a license-plate with verification id
     */
    async getVehicleInfoFromLicensePlateWithVerificationIdRaw(requestParameters: GetVehicleInfoFromLicensePlateWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleInformation>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getVehicleInfoFromLicensePlateWithVerificationId.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling getVehicleInfoFromLicensePlateWithVerificationId.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getVehicleInfoFromLicensePlateWithVerificationId.');
        }

        if (requestParameters.registeredCountry === null || requestParameters.registeredCountry === undefined) {
            throw new runtime.RequiredError('registeredCountry','Required parameter requestParameters.registeredCountry was null or undefined when calling getVehicleInfoFromLicensePlateWithVerificationId.');
        }

        if (requestParameters.registeredState === null || requestParameters.registeredState === undefined) {
            throw new runtime.RequiredError('registeredState','Required parameter requestParameters.registeredState was null or undefined when calling getVehicleInfoFromLicensePlateWithVerificationId.');
        }

        if (requestParameters.licensePlate === null || requestParameters.licensePlate === undefined) {
            throw new runtime.RequiredError('licensePlate','Required parameter requestParameters.licensePlate was null or undefined when calling getVehicleInfoFromLicensePlateWithVerificationId.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer-id'] = requestParameters.customerId;
        }

        if (requestParameters.registeredCountry !== undefined) {
            queryParameters['registered-country'] = requestParameters.registeredCountry;
        }

        if (requestParameters.registeredState !== undefined) {
            queryParameters['registered-state'] = requestParameters.registeredState;
        }

        if (requestParameters.licensePlate !== undefined) {
            queryParameters['license-plate'] = requestParameters.licensePlate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/license-plate/verification-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleInformationFromJSON(jsonValue));
    }

    /**
     * Get a vehicle\'s info from a license-plate with verification id
     */
    async getVehicleInfoFromLicensePlateWithVerificationId(requestParameters: GetVehicleInfoFromLicensePlateWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleInformation> {
        const response = await this.getVehicleInfoFromLicensePlateWithVerificationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a vehicle\'s info from VIN
     */
    async getVehicleInfoFromVinRaw(requestParameters: GetVehicleInfoFromVinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleInformation>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getVehicleInfoFromVin.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getVehicleInfoFromVin.');
        }

        if (requestParameters.vin === null || requestParameters.vin === undefined) {
            throw new runtime.RequiredError('vin','Required parameter requestParameters.vin was null or undefined when calling getVehicleInfoFromVin.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vin !== undefined) {
            queryParameters['vin'] = requestParameters.vin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/vin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleInformationFromJSON(jsonValue));
    }

    /**
     * Get a vehicle\'s info from VIN
     */
    async getVehicleInfoFromVin(requestParameters: GetVehicleInfoFromVinRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleInformation> {
        const response = await this.getVehicleInfoFromVinRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a vehicle\'s info from VIN without authorization
     */
    async getVehicleInfoFromVinIntakeRaw(requestParameters: GetVehicleInfoFromVinIntakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleInformation>> {
        if (requestParameters.vin === null || requestParameters.vin === undefined) {
            throw new runtime.RequiredError('vin','Required parameter requestParameters.vin was null or undefined when calling getVehicleInfoFromVinIntake.');
        }

        const queryParameters: any = {};

        if (requestParameters.vin !== undefined) {
            queryParameters['vin'] = requestParameters.vin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/vin/intake`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleInformationFromJSON(jsonValue));
    }

    /**
     * Get a vehicle\'s info from VIN without authorization
     */
    async getVehicleInfoFromVinIntake(requestParameters: GetVehicleInfoFromVinIntakeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleInformation> {
        const response = await this.getVehicleInfoFromVinIntakeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a vehicle\'s info from VIN with verification id
     */
    async getVehicleInfoFromVinWithVerificationIdRaw(requestParameters: GetVehicleInfoFromVinWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleInformation>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getVehicleInfoFromVinWithVerificationId.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling getVehicleInfoFromVinWithVerificationId.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling getVehicleInfoFromVinWithVerificationId.');
        }

        if (requestParameters.vin === null || requestParameters.vin === undefined) {
            throw new runtime.RequiredError('vin','Required parameter requestParameters.vin was null or undefined when calling getVehicleInfoFromVinWithVerificationId.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        if (requestParameters.customerId !== undefined) {
            queryParameters['customer-id'] = requestParameters.customerId;
        }

        if (requestParameters.vin !== undefined) {
            queryParameters['vin'] = requestParameters.vin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/vin/verification-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleInformationFromJSON(jsonValue));
    }

    /**
     * Get a vehicle\'s info from VIN with verification id
     */
    async getVehicleInfoFromVinWithVerificationId(requestParameters: GetVehicleInfoFromVinWithVerificationIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleInformation> {
        const response = await this.getVehicleInfoFromVinWithVerificationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get vehicle reminder message by id
     */
    async getVehicleReminderMessageByIdRaw(requestParameters: GetVehicleReminderMessageByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleReminderMessageEntry>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getVehicleReminderMessageById.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getVehicleReminderMessageById.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getVehicleReminderMessageById.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/vehicle-reminder-message`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleReminderMessageEntryFromJSON(jsonValue));
    }

    /**
     * Get vehicle reminder message by id
     */
    async getVehicleReminderMessageById(requestParameters: GetVehicleReminderMessageByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleReminderMessageEntry> {
        const response = await this.getVehicleReminderMessageByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get vehicle reminder message by vehicle
     */
    async getVehicleReminderMessageByVehicleRaw(requestParameters: GetVehicleReminderMessageByVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleReminderMessageEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getVehicleReminderMessageByVehicle.');
        }

        if (requestParameters.vehicleId === null || requestParameters.vehicleId === undefined) {
            throw new runtime.RequiredError('vehicleId','Required parameter requestParameters.vehicleId was null or undefined when calling getVehicleReminderMessageByVehicle.');
        }

        const queryParameters: any = {};

        if (requestParameters.vehicleId !== undefined) {
            queryParameters['vehicle-id'] = requestParameters.vehicleId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/vehicle-reminder-message-vehicle`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleReminderMessageEntryFromJSON));
    }

    /**
     * Get vehicle reminder message by vehicle
     */
    async getVehicleReminderMessageByVehicle(requestParameters: GetVehicleReminderMessageByVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleReminderMessageEntry>> {
        const response = await this.getVehicleReminderMessageByVehicleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of vendor entries by ID
     */
    async getVendorEntriesByIdRaw(requestParameters: GetVendorEntriesByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VendorEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getVendorEntriesById.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getVendorEntriesById.');
        }

        if (requestParameters.vendorIds === null || requestParameters.vendorIds === undefined) {
            throw new runtime.RequiredError('vendorIds','Required parameter requestParameters.vendorIds was null or undefined when calling getVendorEntriesById.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vendorIds) {
            queryParameters['vendor-ids'] = requestParameters.vendorIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/vendor-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VendorEntryFromJSON));
    }

    /**
     * Get a list of vendor entries by ID
     */
    async getVendorEntriesById(requestParameters: GetVendorEntriesByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VendorEntry>> {
        const response = await this.getVendorEntriesByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a list of vendor entries by shop ID
     */
    async getVendorEntriesByShopIdRaw(requestParameters: GetVendorEntriesByShopIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VendorEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getVendorEntriesByShopId.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getVendorEntriesByShopId.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.unarchivedOnly !== undefined) {
            queryParameters['unarchived-only'] = requestParameters.unarchivedOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/vendor-shop-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VendorEntryFromJSON));
    }

    /**
     * Get a list of vendor entries by shop ID
     */
    async getVendorEntriesByShopId(requestParameters: GetVendorEntriesByShopIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VendorEntry>> {
        const response = await this.getVendorEntriesByShopIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the vendor entry id by shop ID and partstech ID if exists
     */
    async getVendorIdByShopIdAndPartstechIdRaw(requestParameters: GetVendorIdByShopIdAndPartstechIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getVendorIdByShopIdAndPartstechId.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getVendorIdByShopIdAndPartstechId.');
        }

        if (requestParameters.partstechId === null || requestParameters.partstechId === undefined) {
            throw new runtime.RequiredError('partstechId','Required parameter requestParameters.partstechId was null or undefined when calling getVendorIdByShopIdAndPartstechId.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.partstechId !== undefined) {
            queryParameters['partstech-id'] = requestParameters.partstechId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/vendor-shop-id-partstech-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get the vendor entry id by shop ID and partstech ID if exists
     */
    async getVendorIdByShopIdAndPartstechId(requestParameters: GetVendorIdByShopIdAndPartstechIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getVendorIdByShopIdAndPartstechIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the vendor entry id by shop ID, name, and partstech ID if exists
     */
    async getVendorIdByShopIdNameAndPartstechIdRaw(requestParameters: GetVendorIdByShopIdNameAndPartstechIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getVendorIdByShopIdNameAndPartstechId.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getVendorIdByShopIdNameAndPartstechId.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getVendorIdByShopIdNameAndPartstechId.');
        }

        if (requestParameters.partstechId === null || requestParameters.partstechId === undefined) {
            throw new runtime.RequiredError('partstechId','Required parameter requestParameters.partstechId was null or undefined when calling getVendorIdByShopIdNameAndPartstechId.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.partstechId !== undefined) {
            queryParameters['partstech-id'] = requestParameters.partstechId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/vendor-shop-id-name-partstech-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get the vendor entry id by shop ID, name, and partstech ID if exists
     */
    async getVendorIdByShopIdNameAndPartstechId(requestParameters: GetVendorIdByShopIdNameAndPartstechIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getVendorIdByShopIdNameAndPartstechIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get verification by verificationId
     */
    async getVerificationByIdRaw(requestParameters: GetVerificationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VerificationEntry>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling getVerificationById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/verification`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VerificationEntryFromJSON(jsonValue));
    }

    /**
     * Get verification by verificationId
     */
    async getVerificationById(requestParameters: GetVerificationByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VerificationEntry> {
        const response = await this.getVerificationByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the entire vehicle-agnostic estimated-work-time taxonomy
     */
    async getWorkTimeTaxonomyRaw(requestParameters: GetWorkTimeTaxonomyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MotorTaxonomy>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getWorkTimeTaxonomy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/work-time-taxonomy`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MotorTaxonomyFromJSON(jsonValue));
    }

    /**
     * Get the entire vehicle-agnostic estimated-work-time taxonomy
     */
    async getWorkTimeTaxonomy(requestParameters: GetWorkTimeTaxonomyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MotorTaxonomy> {
        const response = await this.getWorkTimeTaxonomyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the entire taxonomy for a specific vehicle
     */
    async getWorkTimeTaxonomyForVehicleRaw(requestParameters: GetWorkTimeTaxonomyForVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MotorTaxonomy>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling getWorkTimeTaxonomyForVehicle.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling getWorkTimeTaxonomyForVehicle.');
        }

        if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
            throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling getWorkTimeTaxonomyForVehicle.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/work-time-taxonomy/vehicle`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MotorTaxonomyFromJSON(jsonValue));
    }

    /**
     * Get the entire taxonomy for a specific vehicle
     */
    async getWorkTimeTaxonomyForVehicle(requestParameters: GetWorkTimeTaxonomyForVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MotorTaxonomy> {
        const response = await this.getWorkTimeTaxonomyForVehicleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Handle appointment schedule request response
     */
    async handleAppointmentScheduleRequestResponseRaw(requestParameters: HandleAppointmentScheduleRequestResponseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling handleAppointmentScheduleRequestResponse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment/respond`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentScheduleResponseRequestToJSON(requestParameters.appointmentScheduleResponseRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Handle appointment schedule request response
     */
    async handleAppointmentScheduleRequestResponse(requestParameters: HandleAppointmentScheduleRequestResponseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.handleAppointmentScheduleRequestResponseRaw(requestParameters, initOverrides);
    }

    /**
     * Generate a single-use expiring login link for the specified account
     */
    async initiateAccountLoginRaw(requestParameters: InitiateAccountLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling initiateAccountLogin.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling initiateAccountLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.shop !== undefined) {
            formParams.append('shop', requestParameters.shop as any);
        }

        const response = await this.request({
            path: `/capital-os/initiate-account-login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Generate a single-use expiring login link for the specified account
     */
    async initiateAccountLogin(requestParameters: InitiateAccountLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.initiateAccountLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create an account on behalf of another user, so that you can pre-emptively grant permissions
     */
    async inviteUserRaw(requestParameters: InviteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling inviteUser.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling inviteUser.');
        }

        if (requestParameters.realName === null || requestParameters.realName === undefined) {
            throw new runtime.RequiredError('realName','Required parameter requestParameters.realName was null or undefined when calling inviteUser.');
        }

        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling inviteUser.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling inviteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.shop !== undefined) {
            formParams.append('shop', requestParameters.shop as any);
        }

        if (requestParameters.realName !== undefined) {
            formParams.append('realName', requestParameters.realName as any);
        }

        if (requestParameters.email !== undefined) {
            formParams.append('email', requestParameters.email as any);
        }

        if (requestParameters.role !== undefined) {
            formParams.append('role', requestParameters.role as any);
        }

        const response = await this.request({
            path: `/user/invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create an account on behalf of another user, so that you can pre-emptively grant permissions
     */
    async inviteUser(requestParameters: InviteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.inviteUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create and join a conversation for a specific customer
     */
    async joinCustomerChatRaw(requestParameters: JoinCustomerChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling joinCustomerChat.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/conversation/customer`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create and join a conversation for a specific customer
     */
    async joinCustomerChat(requestParameters: JoinCustomerChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.joinCustomerChatRaw(requestParameters, initOverrides);
    }

    /**
     * Create and join a conversation for a specific repair-order
     */
    async joinRepairOrderRaw(requestParameters: JoinRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling joinRepairOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/conversation/repair-order`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create and join a conversation for a specific repair-order
     */
    async joinRepairOrder(requestParameters: JoinRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.joinRepairOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Remove a conversation for a specific customer
     */
    async leaveCustomerChatRaw(requestParameters: LeaveCustomerChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling leaveCustomerChat.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling leaveCustomerChat.');
        }

        if (requestParameters.conversationId === null || requestParameters.conversationId === undefined) {
            throw new runtime.RequiredError('conversationId','Required parameter requestParameters.conversationId was null or undefined when calling leaveCustomerChat.');
        }

        if (requestParameters.phoneNumber === null || requestParameters.phoneNumber === undefined) {
            throw new runtime.RequiredError('phoneNumber','Required parameter requestParameters.phoneNumber was null or undefined when calling leaveCustomerChat.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.conversationId !== undefined) {
            queryParameters['conversation-id'] = requestParameters.conversationId;
        }

        if (requestParameters.phoneNumber !== undefined) {
            queryParameters['phone-number'] = requestParameters.phoneNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/conversation/customer/participant`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a conversation for a specific customer
     */
    async leaveCustomerChat(requestParameters: LeaveCustomerChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.leaveCustomerChatRaw(requestParameters, initOverrides);
    }

    /**
     * Link a customer to a vehicle
     */
    async linkCustomerToVehicleRaw(requestParameters: LinkCustomerToVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling linkCustomerToVehicle.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling linkCustomerToVehicle.');
        }

        if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
            throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling linkCustomerToVehicle.');
        }

        if (requestParameters.customer === null || requestParameters.customer === undefined) {
            throw new runtime.RequiredError('customer','Required parameter requestParameters.customer was null or undefined when calling linkCustomerToVehicle.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.shop !== undefined) {
            formParams.append('shop', requestParameters.shop as any);
        }

        if (requestParameters.vehicle !== undefined) {
            formParams.append('vehicle', requestParameters.vehicle as any);
        }

        if (requestParameters.customer !== undefined) {
            formParams.append('customer', requestParameters.customer as any);
        }

        const response = await this.request({
            path: `/vehicle/customer`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Link a customer to a vehicle
     */
    async linkCustomerToVehicle(requestParameters: LinkCustomerToVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.linkCustomerToVehicleRaw(requestParameters, initOverrides);
    }

    /**
     * Link specific inventory part instances for a part-override in a repair-order. Only links, does not claim. Useful when wanting to provide an estimate, but not lock up the inventory since the customer has not authorized it yet. API allows duplicate instances. Eg: the shop has only 1 quantity currently in inventory, but the estimate requires 3 instances. For purposes of providing an estimate, just use the same instance 3 times. Note that the API is not idempotent. If the API is called twice, 2 links will be created for each instance
     */
    async linkInventoryRaw(requestParameters: LinkInventoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling linkInventory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/instance/link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClaimInventoryRequestToJSON(requestParameters.claimInventoryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Link specific inventory part instances for a part-override in a repair-order. Only links, does not claim. Useful when wanting to provide an estimate, but not lock up the inventory since the customer has not authorized it yet. API allows duplicate instances. Eg: the shop has only 1 quantity currently in inventory, but the estimate requires 3 instances. For purposes of providing an estimate, just use the same instance 3 times. Note that the API is not idempotent. If the API is called twice, 2 links will be created for each instance
     */
    async linkInventory(requestParameters: LinkInventoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.linkInventoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Link inventory adjustment to instances.
     */
    async linkInventoryAdjustmentRaw(requestParameters: LinkInventoryAdjustmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling linkInventoryAdjustment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/adjustment/link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkInventoryAdjustmentRequestToJSON(requestParameters.linkInventoryAdjustmentRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Link inventory adjustment to instances.
     */
    async linkInventoryAdjustment(requestParameters: LinkInventoryAdjustmentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.linkInventoryAdjustmentRaw(requestParameters, initOverrides);
    }

    /**
     * List all inspection answers to canned jobs
     */
    async listAnswerToCannedJobRaw(requestParameters: ListAnswerToCannedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InspectionAnswerToCannedJobEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listAnswerToCannedJob.');
        }

        if (requestParameters.inspection === null || requestParameters.inspection === undefined) {
            throw new runtime.RequiredError('inspection','Required parameter requestParameters.inspection was null or undefined when calling listAnswerToCannedJob.');
        }

        const queryParameters: any = {};

        if (requestParameters.inspection !== undefined) {
            queryParameters['inspection'] = requestParameters.inspection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/answer-to-canned-job`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InspectionAnswerToCannedJobEntryFromJSON));
    }

    /**
     * List all inspection answers to canned jobs
     */
    async listAnswerToCannedJob(requestParameters: ListAnswerToCannedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InspectionAnswerToCannedJobEntry>> {
        const response = await this.listAnswerToCannedJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * For a repair order, list all failed text-to-pay payment attempts
     */
    async listApiKeyFailedPaymentAttemptsRaw(requestParameters: ListApiKeyFailedPaymentAttemptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<ApiKeyFailedPaymentAttemptEntry>; }>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listApiKeyFailedPaymentAttempts.');
        }

        const queryParameters: any = {};

        if (requestParameters.repairOrderId !== undefined) {
            queryParameters['repair_order_id'] = requestParameters.repairOrderId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/payment/api-key-failed-attempts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * For a repair order, list all failed text-to-pay payment attempts
     */
    async listApiKeyFailedPaymentAttempts(requestParameters: ListApiKeyFailedPaymentAttemptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<ApiKeyFailedPaymentAttemptEntry>; }> {
        const response = await this.listApiKeyFailedPaymentAttemptsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all Authorizations
     */
    async listAuthorizationsRaw(requestParameters: ListAuthorizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AuthorizationEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listAuthorizations.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listAuthorizations.');
        }

        if (requestParameters.repairOrder === null || requestParameters.repairOrder === undefined) {
            throw new runtime.RequiredError('repairOrder','Required parameter requestParameters.repairOrder was null or undefined when calling listAuthorizations.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.repairOrder) {
            queryParameters['repair_order'] = requestParameters.repairOrder;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuthorizationEntryFromJSON));
    }

    /**
     * List all Authorizations
     */
    async listAuthorizations(requestParameters: ListAuthorizationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AuthorizationEntry>> {
        const response = await this.listAuthorizationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all authorizations for an inspection API key
     */
    async listAuthorizationsForApiKeyRaw(requestParameters: ListAuthorizationsForApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AuthorizationEntry>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling listAuthorizationsForApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/customer-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AuthorizationEntryFromJSON));
    }

    /**
     * List all authorizations for an inspection API key
     */
    async listAuthorizationsForApiKey(requestParameters: ListAuthorizationsForApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AuthorizationEntry>> {
        const response = await this.listAuthorizationsForApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all unarchived canned jobs at shop
     */
    async listCannedJobsRaw(requestParameters: ListCannedJobsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CannedJobEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listCannedJobs.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listCannedJobs.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CannedJobEntryFromJSON));
    }

    /**
     * List all unarchived canned jobs at shop
     */
    async listCannedJobs(requestParameters: ListCannedJobsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CannedJobEntry>> {
        const response = await this.listCannedJobsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all canned parts at shop
     */
    async listCannedPartsRaw(requestParameters: ListCannedPartsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CannedJobCustomPartEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listCannedParts.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listCannedParts.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/canned-part`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CannedJobCustomPartEntryFromJSON));
    }

    /**
     * List all canned parts at shop
     */
    async listCannedParts(requestParameters: ListCannedPartsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CannedJobCustomPartEntry>> {
        const response = await this.listCannedPartsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List counter sale entries
     */
    async listCounterSalesRaw(requestParameters: ListCounterSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CounterSaleEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listCounterSales.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listCounterSales.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        if (requestParameters.showArchived !== undefined) {
            queryParameters['show-archived'] = requestParameters.showArchived;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/counter-sale`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CounterSaleEntryFromJSON));
    }

    /**
     * List counter sale entries
     */
    async listCounterSales(requestParameters: ListCounterSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CounterSaleEntry>> {
        const response = await this.listCounterSalesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List expanded counter sales
     */
    async listCounterSalesExpandedRaw(requestParameters: ListCounterSalesExpandedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CounterSaleExpanded>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listCounterSalesExpanded.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listCounterSalesExpanded.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        if (requestParameters.showArchived !== undefined) {
            queryParameters['show-archived'] = requestParameters.showArchived;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/counter-sale/expanded`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CounterSaleExpandedFromJSON));
    }

    /**
     * List expanded counter sales
     */
    async listCounterSalesExpanded(requestParameters: ListCounterSalesExpandedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CounterSaleExpanded>> {
        const response = await this.listCounterSalesExpandedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all credit card readers for a given shop
     */
    async listCreditCardReadersRaw(requestParameters: ListCreditCardReadersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CreditCardReader>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listCreditCardReaders.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listCreditCardReaders.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/credit-card-reader`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CreditCardReaderFromJSON));
    }

    /**
     * List all credit card readers for a given shop
     */
    async listCreditCardReaders(requestParameters: ListCreditCardReadersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CreditCardReader>> {
        const response = await this.listCreditCardReadersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List the most recent customers
     */
    async listCustomersRaw(requestParameters: ListCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listCustomers.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listCustomers.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.maxTimestampExclusive !== undefined) {
            queryParameters['max_timestamp_exclusive'] = requestParameters.maxTimestampExclusive;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerEntryFromJSON));
    }

    /**
     * List the most recent customers
     */
    async listCustomers(requestParameters: ListCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerEntry>> {
        const response = await this.listCustomersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all inspection entries
     */
    async listEntriesRaw(requestParameters: ListEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EntryEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listEntries.');
        }

        if (requestParameters.inspection === null || requestParameters.inspection === undefined) {
            throw new runtime.RequiredError('inspection','Required parameter requestParameters.inspection was null or undefined when calling listEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.inspection !== undefined) {
            queryParameters['inspection'] = requestParameters.inspection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/entry`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EntryEntryFromJSON));
    }

    /**
     * List all inspection entries
     */
    async listEntries(requestParameters: ListEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EntryEntry>> {
        const response = await this.listEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all features for a given shop
     */
    async listFeaturesForShopRaw(requestParameters: ListFeaturesForShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ShopFeatureEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listFeaturesForShop.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listFeaturesForShop.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/features`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ShopFeatureEntryFromJSON));
    }

    /**
     * Get all features for a given shop
     */
    async listFeaturesForShop(requestParameters: ListFeaturesForShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ShopFeatureEntry>> {
        const response = await this.listFeaturesForShopRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all applications for provided taxonomy and vehicle
     */
    async listFluidApplicationsForTaxonomyRaw(requestParameters: ListFluidApplicationsForTaxonomyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<FluidApplication>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listFluidApplicationsForTaxonomy.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listFluidApplicationsForTaxonomy.');
        }

        if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
            throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling listFluidApplicationsForTaxonomy.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        if (requestParameters.system !== undefined) {
            queryParameters['system'] = requestParameters.system;
        }

        if (requestParameters.group !== undefined) {
            queryParameters['group'] = requestParameters.group;
        }

        if (requestParameters.subgroup !== undefined) {
            queryParameters['subgroup'] = requestParameters.subgroup;
        }

        if (requestParameters.taxonomy !== undefined) {
            queryParameters['taxonomy'] = requestParameters.taxonomy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/fluid/application/taxonomy`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FluidApplicationFromJSON));
    }

    /**
     * Get all applications for provided taxonomy and vehicle
     */
    async listFluidApplicationsForTaxonomy(requestParameters: ListFluidApplicationsForTaxonomyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<FluidApplication>> {
        const response = await this.listFluidApplicationsForTaxonomyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all inspections at shop
     */
    async listInspectionsRaw(requestParameters: ListInspectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InspectionEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listInspections.');
        }

        if (requestParameters.template === null || requestParameters.template === undefined) {
            throw new runtime.RequiredError('template','Required parameter requestParameters.template was null or undefined when calling listInspections.');
        }

        if (requestParameters.endDateExclusive === null || requestParameters.endDateExclusive === undefined) {
            throw new runtime.RequiredError('endDateExclusive','Required parameter requestParameters.endDateExclusive was null or undefined when calling listInspections.');
        }

        if (requestParameters.numResults === null || requestParameters.numResults === undefined) {
            throw new runtime.RequiredError('numResults','Required parameter requestParameters.numResults was null or undefined when calling listInspections.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.template !== undefined) {
            queryParameters['template'] = requestParameters.template;
        }

        if (requestParameters.endDateExclusive !== undefined) {
            queryParameters['end_date_exclusive'] = requestParameters.endDateExclusive;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        if (requestParameters.repairOrders) {
            queryParameters['repair_orders'] = requestParameters.repairOrders;
        }

        if (requestParameters.technicians) {
            queryParameters['technicians'] = requestParameters.technicians;
        }

        if (requestParameters.isAssigned !== undefined) {
            queryParameters['is_assigned'] = requestParameters.isAssigned;
        }

        if (requestParameters.isStarted !== undefined) {
            queryParameters['is_started'] = requestParameters.isStarted;
        }

        if (requestParameters.isFinished !== undefined) {
            queryParameters['is_finished'] = requestParameters.isFinished;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InspectionEntryFromJSON));
    }

    /**
     * List all inspections at shop
     */
    async listInspections(requestParameters: ListInspectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InspectionEntry>> {
        const response = await this.listInspectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all inspections for a specific repair order
     */
    async listInspectionsForRepairOrderRaw(requestParameters: ListInspectionsForRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InspectionEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listInspectionsForRepairOrder.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listInspectionsForRepairOrder.');
        }

        if (requestParameters.repairOrders === null || requestParameters.repairOrders === undefined) {
            throw new runtime.RequiredError('repairOrders','Required parameter requestParameters.repairOrders was null or undefined when calling listInspectionsForRepairOrder.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.repairOrders) {
            queryParameters['repair-orders'] = requestParameters.repairOrders;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/repair-order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InspectionEntryFromJSON));
    }

    /**
     * List all inspections for a specific repair order
     */
    async listInspectionsForRepairOrder(requestParameters: ListInspectionsForRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InspectionEntry>> {
        const response = await this.listInspectionsForRepairOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all codat invoices matching provided params
     */
    async listLineItemsForCodatRaw(requestParameters: ListLineItemsForCodatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepairOrderCodatLineItemsEntry>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling listLineItemsForCodat.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listLineItemsForCodat.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.isRecent !== undefined) {
            queryParameters['is_recent'] = requestParameters.isRecent;
        }

        if (requestParameters.startDateInclusive !== undefined) {
            queryParameters['start_date_inclusive'] = requestParameters.startDateInclusive;
        }

        if (requestParameters.endDateExclusive !== undefined) {
            queryParameters['end_date_exclusive'] = requestParameters.endDateExclusive;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/codat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepairOrderCodatLineItemsEntryFromJSON));
    }

    /**
     * List all codat invoices matching provided params
     */
    async listLineItemsForCodat(requestParameters: ListLineItemsForCodatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepairOrderCodatLineItemsEntry>> {
        const response = await this.listLineItemsForCodatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all media items by an inspection entry ID or by a list of media item IDs
     */
    async listMediaRaw(requestParameters: ListMediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InspectionMediaEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listMedia.');
        }

        const queryParameters: any = {};

        if (requestParameters.entryId !== undefined) {
            queryParameters['entry_id'] = requestParameters.entryId;
        }

        if (requestParameters.mediaId) {
            queryParameters['media_id'] = requestParameters.mediaId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/media`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InspectionMediaEntryFromJSON));
    }

    /**
     * List all media items by an inspection entry ID or by a list of media item IDs
     */
    async listMedia(requestParameters: ListMediaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InspectionMediaEntry>> {
        const response = await this.listMediaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all inspection prompt multiple choice answers
     */
    async listMultipleChoiceAnswersRaw(requestParameters: ListMultipleChoiceAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MultipleChoiceAnswerEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listMultipleChoiceAnswers.');
        }

        if (requestParameters.inspection === null || requestParameters.inspection === undefined) {
            throw new runtime.RequiredError('inspection','Required parameter requestParameters.inspection was null or undefined when calling listMultipleChoiceAnswers.');
        }

        const queryParameters: any = {};

        if (requestParameters.inspection !== undefined) {
            queryParameters['inspection'] = requestParameters.inspection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/prompt/multiple-choice-answer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MultipleChoiceAnswerEntryFromJSON));
    }

    /**
     * List all inspection prompt multiple choice answers
     */
    async listMultipleChoiceAnswers(requestParameters: ListMultipleChoiceAnswersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MultipleChoiceAnswerEntry>> {
        const response = await this.listMultipleChoiceAnswersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List notifications
     */
    async listNotificationsRaw(requestParameters: ListNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NotificationEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listNotifications.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listNotifications.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.startDateInclusive !== undefined) {
            queryParameters['start_date_inclusive'] = requestParameters.startDateInclusive;
        }

        if (requestParameters.endDateExclusive !== undefined) {
            queryParameters['end_date_exclusive'] = requestParameters.endDateExclusive;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotificationEntryFromJSON));
    }

    /**
     * List notifications
     */
    async listNotifications(requestParameters: ListNotificationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NotificationEntry>> {
        const response = await this.listNotificationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all part-status for a given repair-order
     */
    async listPartStatusForRepairOrderRaw(requestParameters: ListPartStatusForRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PartStatusEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listPartStatusForRepairOrder.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listPartStatusForRepairOrder.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.repairOrder !== undefined) {
            queryParameters['repair_order'] = requestParameters.repairOrder;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/status/repair-order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PartStatusEntryFromJSON));
    }

    /**
     * List all part-status for a given repair-order
     */
    async listPartStatusForRepairOrder(requestParameters: ListPartStatusForRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PartStatusEntry>> {
        const response = await this.listPartStatusForRepairOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all payments for a repair order by API key
     */
    async listPaymentsForApiKeyRaw(requestParameters: ListPaymentsForApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepairPaymentEntry>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling listPaymentsForApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/payment/repair-order/customer-view`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepairPaymentEntryFromJSON));
    }

    /**
     * List all payments for a repair order by API key
     */
    async listPaymentsForApiKey(requestParameters: ListPaymentsForApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepairPaymentEntry>> {
        const response = await this.listPaymentsForApiKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all payments for a customer
     */
    async listPaymentsForCustomerRaw(requestParameters: ListPaymentsForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<RepairPaymentEntry>; }>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listPaymentsForCustomer.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listPaymentsForCustomer.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.customer) {
            queryParameters['customer'] = requestParameters.customer;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/payment/customer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * List all payments for a customer
     */
    async listPaymentsForCustomer(requestParameters: ListPaymentsForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<RepairPaymentEntry>; }> {
        const response = await this.listPaymentsForCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all payments for a repair-order
     */
    async listPaymentsForRepairOrderRaw(requestParameters: ListPaymentsForRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepairPaymentEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listPaymentsForRepairOrder.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listPaymentsForRepairOrder.');
        }

        if (requestParameters.repairOrder === null || requestParameters.repairOrder === undefined) {
            throw new runtime.RequiredError('repairOrder','Required parameter requestParameters.repairOrder was null or undefined when calling listPaymentsForRepairOrder.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.repairOrder) {
            queryParameters['repair_order'] = requestParameters.repairOrder;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/payment/repair-order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepairPaymentEntryFromJSON));
    }

    /**
     * List all payments for a repair-order
     */
    async listPaymentsForRepairOrder(requestParameters: ListPaymentsForRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepairPaymentEntry>> {
        const response = await this.listPaymentsForRepairOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all payments for a repair-order
     */
    async listPaymentsForRepairOrderForCodatRaw(requestParameters: ListPaymentsForRepairOrderForCodatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepairPaymentEntry>>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling listPaymentsForRepairOrderForCodat.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listPaymentsForRepairOrderForCodat.');
        }

        if (requestParameters.repairOrder === null || requestParameters.repairOrder === undefined) {
            throw new runtime.RequiredError('repairOrder','Required parameter requestParameters.repairOrder was null or undefined when calling listPaymentsForRepairOrderForCodat.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.repairOrder) {
            queryParameters['repair_order'] = requestParameters.repairOrder;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/payment/repair-order/codat`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepairPaymentEntryFromJSON));
    }

    /**
     * List all payments for a repair-order
     */
    async listPaymentsForRepairOrderForCodat(requestParameters: ListPaymentsForRepairOrderForCodatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepairPaymentEntry>> {
        const response = await this.listPaymentsForRepairOrderForCodatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all inspection prompts
     */
    async listPromptsRaw(requestParameters: ListPromptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PromptEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listPrompts.');
        }

        if (requestParameters.inspection === null || requestParameters.inspection === undefined) {
            throw new runtime.RequiredError('inspection','Required parameter requestParameters.inspection was null or undefined when calling listPrompts.');
        }

        const queryParameters: any = {};

        if (requestParameters.inspection !== undefined) {
            queryParameters['inspection'] = requestParameters.inspection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/prompt`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PromptEntryFromJSON));
    }

    /**
     * List all inspection prompts
     */
    async listPrompts(requestParameters: ListPromptsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PromptEntry>> {
        const response = await this.listPromptsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all repair order statuses for a given shop
     */
    async listRepairOrderStatusesForShopRaw(requestParameters: ListRepairOrderStatusesForShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepairOrderStatusEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listRepairOrderStatusesForShop.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listRepairOrderStatusesForShop.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/repair-order-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepairOrderStatusEntryFromJSON));
    }

    /**
     * List all repair order statuses for a given shop
     */
    async listRepairOrderStatusesForShop(requestParameters: ListRepairOrderStatusesForShopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepairOrderStatusEntry>> {
        const response = await this.listRepairOrderStatusesForShopRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all repair orders matching provided params
     */
    async listRepairOrdersRaw(requestParameters: ListRepairOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepairOrderExpanded>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listRepairOrders.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listRepairOrders.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.repairOrder) {
            queryParameters['repair_order'] = requestParameters.repairOrder;
        }

        if (requestParameters.customer) {
            queryParameters['customer'] = requestParameters.customer;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        if (requestParameters.appointment) {
            queryParameters['appointment'] = requestParameters.appointment;
        }

        if (requestParameters.isEstimate !== undefined) {
            queryParameters['is_estimate'] = requestParameters.isEstimate;
        }

        if (requestParameters.isRecent !== undefined) {
            queryParameters['is_recent'] = requestParameters.isRecent;
        }

        if (requestParameters.isOpen !== undefined) {
            queryParameters['is_open'] = requestParameters.isOpen;
        }

        if (requestParameters.serviceAdvisor !== undefined) {
            queryParameters['service_advisor'] = requestParameters.serviceAdvisor;
        }

        if (requestParameters.technician !== undefined) {
            queryParameters['technician'] = requestParameters.technician;
        }

        if (requestParameters.startDateInclusive !== undefined) {
            queryParameters['start_date_inclusive'] = requestParameters.startDateInclusive;
        }

        if (requestParameters.endDateExclusive !== undefined) {
            queryParameters['end_date_exclusive'] = requestParameters.endDateExclusive;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepairOrderExpandedFromJSON));
    }

    /**
     * List all repair orders matching provided params
     */
    async listRepairOrders(requestParameters: ListRepairOrdersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepairOrderExpanded>> {
        const response = await this.listRepairOrdersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all repair order entries with related entities matching provided params
     */
    async listRepairOrdersWithRelatedRaw(requestParameters: ListRepairOrdersWithRelatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepairOrderWithRelated>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listRepairOrdersWithRelated.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listRepairOrdersWithRelated.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.repairOrder) {
            queryParameters['repair_order'] = requestParameters.repairOrder;
        }

        if (requestParameters.customer) {
            queryParameters['customer'] = requestParameters.customer;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        if (requestParameters.appointment) {
            queryParameters['appointment'] = requestParameters.appointment;
        }

        if (requestParameters.isEstimate !== undefined) {
            queryParameters['is_estimate'] = requestParameters.isEstimate;
        }

        if (requestParameters.isRecent !== undefined) {
            queryParameters['is_recent'] = requestParameters.isRecent;
        }

        if (requestParameters.isOpen !== undefined) {
            queryParameters['is_open'] = requestParameters.isOpen;
        }

        if (requestParameters.serviceAdvisor !== undefined) {
            queryParameters['service_advisor'] = requestParameters.serviceAdvisor;
        }

        if (requestParameters.technician !== undefined) {
            queryParameters['technician'] = requestParameters.technician;
        }

        if (requestParameters.startDateInclusive !== undefined) {
            queryParameters['start_date_inclusive'] = requestParameters.startDateInclusive;
        }

        if (requestParameters.endDateExclusive !== undefined) {
            queryParameters['end_date_exclusive'] = requestParameters.endDateExclusive;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order-entry`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepairOrderWithRelatedFromJSON));
    }

    /**
     * List all repair order entries with related entities matching provided params
     */
    async listRepairOrdersWithRelated(requestParameters: ListRepairOrdersWithRelatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepairOrderWithRelated>> {
        const response = await this.listRepairOrdersWithRelatedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all inspections sections
     */
    async listSectionsRaw(requestParameters: ListSectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SectionEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listSections.');
        }

        if (requestParameters.inspection === null || requestParameters.inspection === undefined) {
            throw new runtime.RequiredError('inspection','Required parameter requestParameters.inspection was null or undefined when calling listSections.');
        }

        const queryParameters: any = {};

        if (requestParameters.inspection !== undefined) {
            queryParameters['inspection'] = requestParameters.inspection;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/section`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SectionEntryFromJSON));
    }

    /**
     * List all inspections sections
     */
    async listSections(requestParameters: ListSectionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SectionEntry>> {
        const response = await this.listSectionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all resources at shop
     */
    async listShopResourcesRaw(requestParameters: ListShopResourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ResourceEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listShopResources.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listShopResources.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/resource/shop`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResourceEntryFromJSON));
    }

    /**
     * List all resources at shop
     */
    async listShopResources(requestParameters: ListShopResourcesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ResourceEntry>> {
        const response = await this.listShopResourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all time clock entries
     */
    async listTimeClockEntriesRaw(requestParameters: ListTimeClockEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TimeClockEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listTimeClockEntries.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listTimeClockEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }

        if (requestParameters.employee) {
            queryParameters['employee'] = requestParameters.employee;
        }

        if (requestParameters.repairOrder) {
            queryParameters['repair_order'] = requestParameters.repairOrder;
        }

        if (requestParameters.isOpenOnly !== undefined) {
            queryParameters['is_open_only'] = requestParameters.isOpenOnly;
        }

        if (requestParameters.isClosedOnly !== undefined) {
            queryParameters['is_closed_only'] = requestParameters.isClosedOnly;
        }

        if (requestParameters.startDateInclusive !== undefined) {
            queryParameters['start_date_inclusive'] = requestParameters.startDateInclusive;
        }

        if (requestParameters.endDateExclusive !== undefined) {
            queryParameters['end_date_exclusive'] = requestParameters.endDateExclusive;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/time-clock`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TimeClockEntryFromJSON));
    }

    /**
     * List all time clock entries
     */
    async listTimeClockEntries(requestParameters: ListTimeClockEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TimeClockEntry>> {
        const response = await this.listTimeClockEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List history entries of a time clock
     */
    async listTimeClockHistoryEntriesRaw(requestParameters: ListTimeClockHistoryEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TimeClockEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listTimeClockHistoryEntries.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listTimeClockHistoryEntries.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listTimeClockHistoryEntries.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/time-clock/history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TimeClockEntryFromJSON));
    }

    /**
     * List history entries of a time clock
     */
    async listTimeClockHistoryEntries(requestParameters: ListTimeClockHistoryEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TimeClockEntry>> {
        const response = await this.listTimeClockHistoryEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List reminders for all unarchived vehicles with unarchived customers in the order of service/inspection date
     */
    async listVehicleReminderRaw(requestParameters: ListVehicleReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleReminder>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listVehicleReminder.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listVehicleReminder.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.lastDate !== undefined) {
            queryParameters['last_date'] = requestParameters.lastDate;
        }

        if (requestParameters.lastTimestamp !== undefined) {
            queryParameters['last_timestamp'] = requestParameters.lastTimestamp;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/reminder`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleReminderFromJSON));
    }

    /**
     * List reminders for all unarchived vehicles with unarchived customers in the order of service/inspection date
     */
    async listVehicleReminder(requestParameters: ListVehicleReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleReminder>> {
        const response = await this.listVehicleReminderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all unarchived vehicles with unarchived customers in the order of created date
     */
    async listVehicleWithCustomersRaw(requestParameters: ListVehicleWithCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleWithCustomers>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listVehicleWithCustomers.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listVehicleWithCustomers.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.maxTimestampExclusive !== undefined) {
            queryParameters['max_timestamp_exclusive'] = requestParameters.maxTimestampExclusive;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/vehicle-with-customers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleWithCustomersFromJSON));
    }

    /**
     * List all unarchived vehicles with unarchived customers in the order of created date
     */
    async listVehicleWithCustomers(requestParameters: ListVehicleWithCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleWithCustomers>> {
        const response = await this.listVehicleWithCustomersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all unarchived vehicles in the order of created date
     */
    async listVehiclesRaw(requestParameters: ListVehiclesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling listVehicles.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling listVehicles.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.maxTimestampExclusive !== undefined) {
            queryParameters['max_timestamp_exclusive'] = requestParameters.maxTimestampExclusive;
        }

        if (requestParameters.numResults !== undefined) {
            queryParameters['num_results'] = requestParameters.numResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleEntryFromJSON));
    }

    /**
     * List all unarchived vehicles in the order of created date
     */
    async listVehicles(requestParameters: ListVehiclesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleEntry>> {
        const response = await this.listVehiclesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lock a user\'s session, with the ability to easily unlock it. Note that all active devices are still left active. Expectation is that only private devices will be registered
     */
    async lockSessionRaw(requestParameters: LockSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling lockSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/session/lock`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Lock a user\'s session, with the ability to easily unlock it. Note that all active devices are still left active. Expectation is that only private devices will be registered
     */
    async lockSession(requestParameters: LockSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.lockSessionRaw(requestParameters, initOverrides);
    }

    /**
     * Logging of front-end events
     */
    async loggingRaw(requestParameters: LoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.subject === null || requestParameters.subject === undefined) {
            throw new runtime.RequiredError('subject','Required parameter requestParameters.subject was null or undefined when calling logging.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling logging.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.userAgent !== undefined && requestParameters.userAgent !== null) {
            headerParameters['User-Agent'] = String(requestParameters.userAgent);
        }

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.subject !== undefined) {
            formParams.append('subject', requestParameters.subject as any);
        }

        if (requestParameters.body !== undefined) {
            formParams.append('body', requestParameters.body as any);
        }

        const response = await this.request({
            path: `/contact/logging`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Logging of front-end events
     */
    async logging(requestParameters: LoggingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.loggingRaw(requestParameters, initOverrides);
    }

    /**
     * Login for a user with existing account
     */
    async loginRaw(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        if (requestParameters.firebaseIdToken === null || requestParameters.firebaseIdToken === undefined) {
            throw new runtime.RequiredError('firebaseIdToken','Required parameter requestParameters.firebaseIdToken was null or undefined when calling login.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.firebaseIdToken !== undefined) {
            formParams.append('firebaseIdToken', requestParameters.firebaseIdToken as any);
        }

        const response = await this.request({
            path: `/user/session`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Login for a user with existing account
     */
    async login(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Logout
     */
    async logoutRaw(requestParameters: LogoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling logout.');
        }

        const queryParameters: any = {};

        if (requestParameters.deviceToken !== undefined) {
            queryParameters['device-token'] = requestParameters.deviceToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/session`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Logout
     */
    async logout(requestParameters: LogoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.logoutRaw(requestParameters, initOverrides);
    }

    /**
     * Lookup a specific phone number
     */
    async lookupPhoneNumberRaw(requestParameters: LookupPhoneNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling lookupPhoneNumber.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling lookupPhoneNumber.');
        }

        if (requestParameters.phoneNumber === null || requestParameters.phoneNumber === undefined) {
            throw new runtime.RequiredError('phoneNumber','Required parameter requestParameters.phoneNumber was null or undefined when calling lookupPhoneNumber.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.phoneNumber !== undefined) {
            queryParameters['phone-number'] = requestParameters.phoneNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/conversation/lookup-phone-number`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Lookup a specific phone number
     */
    async lookupPhoneNumber(requestParameters: LookupPhoneNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.lookupPhoneNumberRaw(requestParameters, initOverrides);
    }

    /**
     * Lookup repair order for part-override
     */
    async lookupRepairOrdersForPartOverridesRaw(requestParameters: LookupRepairOrdersForPartOverridesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: RepairOrderEntry; }>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling lookupRepairOrdersForPartOverrides.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling lookupRepairOrdersForPartOverrides.');
        }

        if (requestParameters.partOverride === null || requestParameters.partOverride === undefined) {
            throw new runtime.RequiredError('partOverride','Required parameter requestParameters.partOverride was null or undefined when calling lookupRepairOrdersForPartOverrides.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.partOverride) {
            queryParameters['part_override'] = requestParameters.partOverride;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/parts-override`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, RepairOrderEntryFromJSON));
    }

    /**
     * Lookup repair order for part-override
     */
    async lookupRepairOrdersForPartOverrides(requestParameters: LookupRepairOrdersForPartOverridesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: RepairOrderEntry; }> {
        const response = await this.lookupRepairOrdersForPartOverridesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lookup services using labor guide
     */
    async lookupServicesRaw(requestParameters: LookupServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LaborApplication>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling lookupServices.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling lookupServices.');
        }

        if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
            throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling lookupServices.');
        }

        if (requestParameters.keyword === null || requestParameters.keyword === undefined) {
            throw new runtime.RequiredError('keyword','Required parameter requestParameters.keyword was null or undefined when calling lookupServices.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/lookup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LaborApplicationFromJSON));
    }

    /**
     * Lookup services using labor guide
     */
    async lookupServices(requestParameters: LookupServicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LaborApplication>> {
        const response = await this.lookupServicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Mark all notifications as read
     */
    async markAllNotificationsAsReadRaw(requestParameters: MarkAllNotificationsAsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling markAllNotificationsAsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/all`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark all notifications as read
     */
    async markAllNotificationsAsRead(requestParameters: MarkAllNotificationsAsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markAllNotificationsAsReadRaw(requestParameters, initOverrides);
    }

    /**
     * Mark notifications as read
     */
    async markNotificationAsReadRaw(requestParameters: MarkNotificationAsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling markNotificationAsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark notifications as read
     */
    async markNotificationAsRead(requestParameters: MarkNotificationAsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markNotificationAsReadRaw(requestParameters, initOverrides);
    }

    /**
     * Mark notifications as unread
     */
    async markNotificationAsUnreadRaw(requestParameters: MarkNotificationAsUnreadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling markNotificationAsUnread.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/unread`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Mark notifications as unread
     */
    async markNotificationAsUnread(requestParameters: MarkNotificationAsUnreadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.markNotificationAsUnreadRaw(requestParameters, initOverrides);
    }

    /**
     * Merge one customer entity into a different customer entity
     */
    async mergeCustomersRaw(requestParameters: MergeCustomersOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling mergeCustomers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer/merge-customers`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MergeCustomersRequestToJSON(requestParameters.mergeCustomersRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Merge one customer entity into a different customer entity
     */
    async mergeCustomers(requestParameters: MergeCustomersOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.mergeCustomersRaw(requestParameters, initOverrides);
    }

    /**
     * Merge two vehicles
     */
    async mergeVehiclesRaw(requestParameters: MergeVehiclesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling mergeVehicles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/merge`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MergeVehiclesRequestToJSON(requestParameters.mergeVehiclesRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Merge two vehicles
     */
    async mergeVehicles(requestParameters: MergeVehiclesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.mergeVehiclesRaw(requestParameters, initOverrides);
    }

    /**
     * Lookup vehicles by search term using motor DaaS
     */
    async motorSearchVehiclesRaw(requestParameters: MotorSearchVehiclesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleInformation>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling motorSearchVehicles.');
        }

        const queryParameters: any = {};

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['search-term'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/motor-search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleInformationFromJSON));
    }

    /**
     * Lookup vehicles by search term using motor DaaS
     */
    async motorSearchVehicles(requestParameters: MotorSearchVehiclesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleInformation>> {
        const response = await this.motorSearchVehiclesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Onboard Finix merchant
     */
    async onboardFinixMerchantRaw(requestParameters: OnboardFinixMerchantOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling onboardFinixMerchant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/finix-onboard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardFinixMerchantRequestToJSON(requestParameters.onboardFinixMerchantRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Onboard Finix merchant
     */
    async onboardFinixMerchant(requestParameters: OnboardFinixMerchantOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.onboardFinixMerchantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Onboard shop with codat
     */
    async onboardShopWithCodatRaw(requestParameters: OnboardShopWithCodatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling onboardShopWithCodat.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling onboardShopWithCodat.');
        }

        if (requestParameters.codatId === null || requestParameters.codatId === undefined) {
            throw new runtime.RequiredError('codatId','Required parameter requestParameters.codatId was null or undefined when calling onboardShopWithCodat.');
        }

        if (requestParameters.codatDefaultItemId === null || requestParameters.codatDefaultItemId === undefined) {
            throw new runtime.RequiredError('codatDefaultItemId','Required parameter requestParameters.codatDefaultItemId was null or undefined when calling onboardShopWithCodat.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.shop !== undefined) {
            formParams.append('shop', requestParameters.shop as any);
        }

        if (requestParameters.codatId !== undefined) {
            formParams.append('codat-id', requestParameters.codatId as any);
        }

        if (requestParameters.codatDefaultItemId !== undefined) {
            formParams.append('codat-default-item-id', requestParameters.codatDefaultItemId as any);
        }

        const response = await this.request({
            path: `/shop/codat`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Onboard shop with codat
     */
    async onboardShopWithCodat(requestParameters: OnboardShopWithCodatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.onboardShopWithCodatRaw(requestParameters, initOverrides);
    }

    /**
     * Order all items present in the PartsTech cart for that service
     */
    async orderCartRaw(requestParameters: OrderCartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderCartResponse>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling orderCart.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/parts-tech/order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderCartResponseFromJSON(jsonValue));
    }

    /**
     * Order all items present in the PartsTech cart for that service
     */
    async orderCart(requestParameters: OrderCartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderCartResponse> {
        const response = await this.orderCartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Order repair link parts in cart
     */
    async orderCart1Raw(requestParameters: OrderCart1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling orderCart1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/repair-link/order-cart`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOrderRepairLinkRequestToJSON(requestParameters.updateOrderRepairLinkRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Order repair link parts in cart
     */
    async orderCart1(requestParameters: OrderCart1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.orderCart1Raw(requestParameters, initOverrides);
    }

    /**
     * Produces JDK 17 errors? module java.base does not \'opens java.net\'. Can be solved by using --add-opens, but there\'s no real need for it at this time
     */
    async patchRaw(requestParameters: PatchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.myParam !== undefined) {
            formParams.append('myParam', requestParameters.myParam as any);
        }

        const response = await this.request({
            path: `/ping`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Produces JDK 17 errors? module java.base does not \'opens java.net\'. Can be solved by using --add-opens, but there\'s no real need for it at this time
     */
    async patch(requestParameters: PatchRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postRaw(requestParameters: PostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.myParam !== undefined) {
            formParams.append('myParam', requestParameters.myParam as any);
        }

        const response = await this.request({
            path: `/ping`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async post(requestParameters: PostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postMultipartRaw(requestParameters: PostMultipartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.myParam !== undefined) {
            formParams.append('myParam', requestParameters.myParam as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.maxSize !== undefined) {
            formParams.append('maxSize', requestParameters.maxSize as any);
        }

        const response = await this.request({
            path: `/ping/multipart`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postMultipart(requestParameters: PostMultipartRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postMultipartRaw(requestParameters, initOverrides);
    }

    /**
     * Create customer by unauthenticated user
     */
    async publiclyCreateCustomerRaw(requestParameters: PubliclyCreateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/intake/customer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PublicCustomerCreationRequestToJSON(requestParameters.publicCustomerCreationRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create customer by unauthenticated user
     */
    async publiclyCreateCustomer(requestParameters: PubliclyCreateCustomerRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.publiclyCreateCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Pull endpoint for syncing cache
     */
    async pullCacheRaw(requestParameters: PullCacheRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CachePullResponseOK>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling pullCache.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling pullCache.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cache/pull/{shopId}`.replace(`{${"shopId"}}`, encodeURIComponent(String(requestParameters.shopId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PullRequestToJSON(requestParameters.pullRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CachePullResponseOKFromJSON(jsonValue));
    }

    /**
     * Pull endpoint for syncing cache
     */
    async pullCache(requestParameters: PullCacheRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CachePullResponseOK> {
        const response = await this.pullCacheRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Callback URL to be used when a PartsTech cart is purchased via PartsTech UI
     */
    async purchaseCallbackRaw(requestParameters: PurchaseCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/parts-tech/quote/purchased-callback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback URL to be used when a PartsTech cart is purchased via PartsTech UI
     */
    async purchaseCallback(requestParameters: PurchaseCallbackRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.purchaseCallbackRaw(requestParameters, initOverrides);
    }

    /**
     * Push endpoint for syncing cache
     */
    async pushCacheRaw(requestParameters: PushCacheRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling pushCache.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling pushCache.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cache/push/{shopId}`.replace(`{${"shopId"}}`, encodeURIComponent(String(requestParameters.shopId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PushRequestToJSON(requestParameters.pushRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Push endpoint for syncing cache
     */
    async pushCache(requestParameters: PushCacheRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.pushCacheRaw(requestParameters, initOverrides);
    }

    /**
     */
    async putRaw(requestParameters: PutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.myParam !== undefined) {
            formParams.append('myParam', requestParameters.myParam as any);
        }

        const response = await this.request({
            path: `/ping`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async put(requestParameters: PutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putRaw(requestParameters, initOverrides);
    }

    /**
     */
    async putTextRaw(requestParameters: PutTextOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'text/plain';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ping/text_plain`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutTextRequestToJSON(requestParameters.putTextRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async putText(requestParameters: PutTextOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putTextRaw(requestParameters, initOverrides);
    }

    /**
     * Lookup Carfax service history for a vehicle via plate/state with optional VIN fallback
     */
    async quickVinLookupRaw(requestParameters: QuickVinLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling quickVinLookup.');
        }

        if (requestParameters.plate === null || requestParameters.plate === undefined) {
            throw new runtime.RequiredError('plate','Required parameter requestParameters.plate was null or undefined when calling quickVinLookup.');
        }

        if (requestParameters.state === null || requestParameters.state === undefined) {
            throw new runtime.RequiredError('state','Required parameter requestParameters.state was null or undefined when calling quickVinLookup.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling quickVinLookup.');
        }

        const queryParameters: any = {};

        if (requestParameters.plate !== undefined) {
            queryParameters['plate'] = requestParameters.plate;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        if (requestParameters.vin !== undefined) {
            queryParameters['vin'] = requestParameters.vin;
        }

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/carfax/quickvin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Lookup Carfax service history for a vehicle via plate/state with optional VIN fallback
     */
    async quickVinLookup(requestParameters: QuickVinLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.quickVinLookupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Recommend-maintenance-schedule with AI
     */
    async recommendMaintenanceScheduleRaw(requestParameters: RecommendMaintenanceScheduleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: MaintenanceScheduleWithRecommendation; }>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling recommendMaintenanceSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ai/recommend-maintenance-schedule`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecommendMaintenanceScheduleRequestToJSON(requestParameters.recommendMaintenanceScheduleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => runtime.mapValues(jsonValue, MaintenanceScheduleWithRecommendationFromJSON));
    }

    /**
     * Recommend-maintenance-schedule with AI
     */
    async recommendMaintenanceSchedule(requestParameters: RecommendMaintenanceScheduleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: MaintenanceScheduleWithRecommendation; }> {
        const response = await this.recommendMaintenanceScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Register an android device for the user
     */
    async registerAndroidDeviceRaw(requestParameters: RegisterAndroidDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling registerAndroidDevice.');
        }

        if (requestParameters.firebaseToken === null || requestParameters.firebaseToken === undefined) {
            throw new runtime.RequiredError('firebaseToken','Required parameter requestParameters.firebaseToken was null or undefined when calling registerAndroidDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.firebaseToken !== undefined) {
            formParams.append('firebaseToken', requestParameters.firebaseToken as any);
        }

        const response = await this.request({
            path: `/user/device/android`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Register an android device for the user
     */
    async registerAndroidDevice(requestParameters: RegisterAndroidDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.registerAndroidDeviceRaw(requestParameters, initOverrides);
    }

    /**
     * Register an iOS device for the user
     */
    async registerIOSDeviceRaw(requestParameters: RegisterIOSDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling registerIOSDevice.');
        }

        if (requestParameters.deviceToken === null || requestParameters.deviceToken === undefined) {
            throw new runtime.RequiredError('deviceToken','Required parameter requestParameters.deviceToken was null or undefined when calling registerIOSDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.deviceToken !== undefined) {
            formParams.append('deviceToken', requestParameters.deviceToken as any);
        }

        const response = await this.request({
            path: `/user/device/ios`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Register an iOS device for the user
     */
    async registerIOSDevice(requestParameters: RegisterIOSDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.registerIOSDeviceRaw(requestParameters, initOverrides);
    }

    /**
     * Release all claims for a specific part override
     */
    async releaseInventoryClaimsRaw(requestParameters: ReleaseInventoryClaimsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling releaseInventoryClaims.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling releaseInventoryClaims.');
        }

        if (requestParameters.partOverride === null || requestParameters.partOverride === undefined) {
            throw new runtime.RequiredError('partOverride','Required parameter requestParameters.partOverride was null or undefined when calling releaseInventoryClaims.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.partOverride !== undefined) {
            queryParameters['part_override'] = requestParameters.partOverride;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/instance/claim`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Release all claims for a specific part override
     */
    async releaseInventoryClaims(requestParameters: ReleaseInventoryClaimsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.releaseInventoryClaimsRaw(requestParameters, initOverrides);
    }

    /**
     * Reply customer message with AI
     */
    async replyRaw(requestParameters: ReplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling reply.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ai/reply`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReplyCustomerRequestToJSON(requestParameters.replyCustomerRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Reply customer message with AI
     */
    async reply(requestParameters: ReplyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.replyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Report a malicious user, to the app\'s admins
     */
    async reportUserRaw(requestParameters: ReportUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling reportUser.');
        }

        if (requestParameters.maliciousUserId === null || requestParameters.maliciousUserId === undefined) {
            throw new runtime.RequiredError('maliciousUserId','Required parameter requestParameters.maliciousUserId was null or undefined when calling reportUser.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling reportUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.maliciousUserId !== undefined) {
            formParams.append('maliciousUserId', requestParameters.maliciousUserId as any);
        }

        if (requestParameters.body !== undefined) {
            formParams.append('body', requestParameters.body as any);
        }

        const response = await this.request({
            path: `/contact/report-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Report a malicious user, to the app\'s admins
     */
    async reportUser(requestParameters: ReportUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportUserRaw(requestParameters, initOverrides);
    }

    /**
     * Remove a user\'s role from a shop, thus revoking all access
     */
    async revokeRoleRaw(requestParameters: RevokeRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling revokeRole.');
        }

        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling revokeRole.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling revokeRole.');
        }

        const queryParameters: any = {};

        if (requestParameters.user !== undefined) {
            queryParameters['user'] = requestParameters.user;
        }

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/user-role`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a user\'s role from a shop, thus revoking all access
     */
    async revokeRole(requestParameters: RevokeRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.revokeRoleRaw(requestParameters, initOverrides);
    }

    /**
     * Rewrite a message to customer with AI
     */
    async rewriteRaw(requestParameters: RewriteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling rewrite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ai/rewrite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RewriteRequestToJSON(requestParameters.rewriteRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Rewrite a message to customer with AI
     */
    async rewrite(requestParameters: RewriteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.rewriteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search canned jobs at shop by keyword
     */
    async searchCannedJobsRaw(requestParameters: SearchCannedJobsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CannedJobEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling searchCannedJobs.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling searchCannedJobs.');
        }

        if (requestParameters.keyword === null || requestParameters.keyword === undefined) {
            throw new runtime.RequiredError('keyword','Required parameter requestParameters.keyword was null or undefined when calling searchCannedJobs.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CannedJobEntryFromJSON));
    }

    /**
     * Search canned jobs at shop by keyword
     */
    async searchCannedJobs(requestParameters: SearchCannedJobsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CannedJobEntry>> {
        const response = await this.searchCannedJobsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search canned parts
     */
    async searchCannedPartsRaw(requestParameters: SearchCannedPartsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CannedJobCustomPartEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling searchCannedParts.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling searchCannedParts.');
        }

        if (requestParameters.keyword === null || requestParameters.keyword === undefined) {
            throw new runtime.RequiredError('keyword','Required parameter requestParameters.keyword was null or undefined when calling searchCannedParts.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/canned-part/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CannedJobCustomPartEntryFromJSON));
    }

    /**
     * Search canned parts
     */
    async searchCannedParts(requestParameters: SearchCannedPartsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CannedJobCustomPartEntry>> {
        const response = await this.searchCannedPartsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for a customer
     */
    async searchForCustomerRaw(requestParameters: SearchForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling searchForCustomer.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling searchForCustomer.');
        }

        if (requestParameters.keyword === null || requestParameters.keyword === undefined) {
            throw new runtime.RequiredError('keyword','Required parameter requestParameters.keyword was null or undefined when calling searchForCustomer.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerEntryFromJSON));
    }

    /**
     * Search for a customer
     */
    async searchForCustomer(requestParameters: SearchForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerEntry>> {
        const response = await this.searchForCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get stocked-only or all parts defined in the shop\'s inventory
     */
    async searchInventoryPartsRaw(requestParameters: SearchInventoryPartsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<InventoryPartEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling searchInventoryParts.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling searchInventoryParts.');
        }

        if (requestParameters.keyword === null || requestParameters.keyword === undefined) {
            throw new runtime.RequiredError('keyword','Required parameter requestParameters.keyword was null or undefined when calling searchInventoryParts.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        if (requestParameters.stockedOnly !== undefined) {
            queryParameters['stocked_only'] = requestParameters.stockedOnly;
        }

        if (requestParameters.receivedOnly !== undefined) {
            queryParameters['received_only'] = requestParameters.receivedOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InventoryPartEntryFromJSON));
    }

    /**
     * Get stocked-only or all parts defined in the shop\'s inventory
     */
    async searchInventoryParts(requestParameters: SearchInventoryPartsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<InventoryPartEntry>> {
        const response = await this.searchInventoryPartsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for part brands on PartsTech
     */
    async searchPartsTechBrandsRaw(requestParameters: SearchPartsTechBrandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Brand>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling searchPartsTechBrands.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling searchPartsTechBrands.');
        }

        if (requestParameters.keyword === null || requestParameters.keyword === undefined) {
            throw new runtime.RequiredError('keyword','Required parameter requestParameters.keyword was null or undefined when calling searchPartsTechBrands.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/parts-tech/brand`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BrandFromJSON));
    }

    /**
     * Search for part brands on PartsTech
     */
    async searchPartsTechBrands(requestParameters: SearchPartsTechBrandsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Brand>> {
        const response = await this.searchPartsTechBrandsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for part suppliers on PartsTech
     */
    async searchPartsTechSuppliersRaw(requestParameters: SearchPartsTechSuppliersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<IdName>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling searchPartsTechSuppliers.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling searchPartsTechSuppliers.');
        }

        if (requestParameters.keyword === null || requestParameters.keyword === undefined) {
            throw new runtime.RequiredError('keyword','Required parameter requestParameters.keyword was null or undefined when calling searchPartsTechSuppliers.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/parts-tech/supplier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IdNameFromJSON));
    }

    /**
     * Search for part suppliers on PartsTech
     */
    async searchPartsTechSuppliers(requestParameters: SearchPartsTechSuppliersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<IdName>> {
        const response = await this.searchPartsTechSuppliersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for existing customer information via email address or phone number
     */
    async searchPublicCustomerInfoRaw(requestParameters: SearchPublicCustomerInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PublicCustomerEntry>>> {
        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling searchPublicCustomerInfo.');
        }

        const queryParameters: any = {};

        if (requestParameters.shopId !== undefined) {
            queryParameters['shop-id'] = requestParameters.shopId;
        }

        if (requestParameters.searchString !== undefined) {
            queryParameters['search-string'] = requestParameters.searchString;
        }

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/intake/customer`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PublicCustomerEntryFromJSON));
    }

    /**
     * Search for existing customer information via email address or phone number
     */
    async searchPublicCustomerInfo(requestParameters: SearchPublicCustomerInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PublicCustomerEntry>> {
        const response = await this.searchPublicCustomerInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lookup a repair order based on a generic keyword
     */
    async searchRepairOrderByKeywordRaw(requestParameters: SearchRepairOrderByKeywordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RepairOrderWithRelated>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling searchRepairOrderByKeyword.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling searchRepairOrderByKeyword.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        if (requestParameters.isEstimate !== undefined) {
            queryParameters['is_estimate'] = requestParameters.isEstimate;
        }

        if (requestParameters.isArchived !== undefined) {
            queryParameters['is_archived'] = requestParameters.isArchived;
        }

        if (requestParameters.isOpen !== undefined) {
            queryParameters['is_open'] = requestParameters.isOpen;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/search/keyword`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RepairOrderWithRelatedFromJSON));
    }

    /**
     * Lookup a repair order based on a generic keyword
     */
    async searchRepairOrderByKeyword(requestParameters: SearchRepairOrderByKeywordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RepairOrderWithRelated>> {
        const response = await this.searchRepairOrderByKeywordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for any vehicles in our database that exactly match the specified fields
     */
    async searchVehicleByFieldRaw(requestParameters: SearchVehicleByFieldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling searchVehicleByField.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling searchVehicleByField.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vin !== undefined) {
            queryParameters['vin'] = requestParameters.vin;
        }

        if (requestParameters.registeredCountry !== undefined) {
            queryParameters['registered-country'] = requestParameters.registeredCountry;
        }

        if (requestParameters.registeredState !== undefined) {
            queryParameters['registered-state'] = requestParameters.registeredState;
        }

        if (requestParameters.licensePlate !== undefined) {
            queryParameters['license-plate'] = requestParameters.licensePlate;
        }

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        if (requestParameters.model !== undefined) {
            queryParameters['model'] = requestParameters.model;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/search/field`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleEntryFromJSON));
    }

    /**
     * Search for any vehicles in our database that exactly match the specified fields
     */
    async searchVehicleByField(requestParameters: SearchVehicleByFieldRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleEntry>> {
        const response = await this.searchVehicleByFieldRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lookup a vehicle based on a generic keyword
     */
    async searchVehicleByKeywordRaw(requestParameters: SearchVehicleByKeywordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling searchVehicleByKeyword.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling searchVehicleByKeyword.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/search/keyword`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleEntryFromJSON));
    }

    /**
     * Lookup a vehicle based on a generic keyword
     */
    async searchVehicleByKeyword(requestParameters: SearchVehicleByKeywordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleEntry>> {
        const response = await this.searchVehicleByKeywordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search existing vehicle fluids for a vehicle
     */
    async searchVehicleFluidsRaw(requestParameters: SearchVehicleFluidsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleFluidsEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling searchVehicleFluids.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling searchVehicleFluids.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling searchVehicleFluids.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.makeId !== undefined) {
            queryParameters['make-id'] = requestParameters.makeId;
        }

        if (requestParameters.modelId !== undefined) {
            queryParameters['model-id'] = requestParameters.modelId;
        }

        if (requestParameters.submodelId !== undefined) {
            queryParameters['submodel-id'] = requestParameters.submodelId;
        }

        if (requestParameters.engineId !== undefined) {
            queryParameters['engine-id'] = requestParameters.engineId;
        }

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        if (requestParameters.model !== undefined) {
            queryParameters['model'] = requestParameters.model;
        }

        if (requestParameters.submodel !== undefined) {
            queryParameters['submodel'] = requestParameters.submodel;
        }

        if (requestParameters.engine !== undefined) {
            queryParameters['engine'] = requestParameters.engine;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/fluids`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleFluidsEntryFromJSON));
    }

    /**
     * Search existing vehicle fluids for a vehicle
     */
    async searchVehicleFluids(requestParameters: SearchVehicleFluidsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleFluidsEntry>> {
        const response = await this.searchVehicleFluidsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search existing vehicle oil filters for a vehicle
     */
    async searchVehicleOilFiltersRaw(requestParameters: SearchVehicleOilFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VehicleOilFilterEntry>>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling searchVehicleOilFilters.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling searchVehicleOilFilters.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling searchVehicleOilFilters.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.makeId !== undefined) {
            queryParameters['make-id'] = requestParameters.makeId;
        }

        if (requestParameters.modelId !== undefined) {
            queryParameters['model-id'] = requestParameters.modelId;
        }

        if (requestParameters.submodelId !== undefined) {
            queryParameters['submodel-id'] = requestParameters.submodelId;
        }

        if (requestParameters.engineId !== undefined) {
            queryParameters['engine-id'] = requestParameters.engineId;
        }

        if (requestParameters.make !== undefined) {
            queryParameters['make'] = requestParameters.make;
        }

        if (requestParameters.model !== undefined) {
            queryParameters['model'] = requestParameters.model;
        }

        if (requestParameters.submodel !== undefined) {
            queryParameters['submodel'] = requestParameters.submodel;
        }

        if (requestParameters.engine !== undefined) {
            queryParameters['engine'] = requestParameters.engine;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/oil-filter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VehicleOilFilterEntryFromJSON));
    }

    /**
     * Search existing vehicle oil filters for a vehicle
     */
    async searchVehicleOilFilters(requestParameters: SearchVehicleOilFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VehicleOilFilterEntry>> {
        const response = await this.searchVehicleOilFiltersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate feedback link with API key and send to customer via email
     */
    async sendFeedbackEmailRaw(requestParameters: SendFeedbackEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling sendFeedbackEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/feedback-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendFeedbackLinkRequestToJSON(requestParameters.sendFeedbackLinkRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Generate feedback link with API key and send to customer via email
     */
    async sendFeedbackEmail(requestParameters: SendFeedbackEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.sendFeedbackEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate feedback link with API key and send to customer via SMS
     */
    async sendFeedbackLinkRaw(requestParameters: SendFeedbackLinkOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling sendFeedbackLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/feedback-link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendFeedbackLinkRequestToJSON(requestParameters.sendFeedbackLinkRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Generate feedback link with API key and send to customer via SMS
     */
    async sendFeedbackLink(requestParameters: SendFeedbackLinkOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.sendFeedbackLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send vehicle reminder via SMS
     */
    async sendVehicleReminderSmsRaw(requestParameters: SendVehicleReminderSmsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling sendVehicleReminderSms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/send-vehicle-reminder-sms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendVehicleReminderSmsRequestToJSON(requestParameters.sendVehicleReminderSmsRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Send vehicle reminder via SMS
     */
    async sendVehicleReminderSms(requestParameters: SendVehicleReminderSmsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.sendVehicleReminderSmsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set Finix identity id for shop
     */
    async setFinixIdentityIdRaw(requestParameters: SetFinixIdentityIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling setFinixIdentityId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/finix-identity-id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetFinixIdentityIdRequestToJSON(requestParameters.setFinixIdentityIdRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set Finix identity id for shop
     */
    async setFinixIdentityId(requestParameters: SetFinixIdentityIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setFinixIdentityIdRaw(requestParameters, initOverrides);
    }

    /**
     * Set Finix merchant id for shop
     */
    async setFinixMerchantIdRaw(requestParameters: SetFinixMerchantIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling setFinixMerchantId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/finix-merchant-id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddFinixMerchantIdRequestToJSON(requestParameters.addFinixMerchantIdRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set Finix merchant id for shop
     */
    async setFinixMerchantId(requestParameters: SetFinixMerchantIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setFinixMerchantIdRaw(requestParameters, initOverrides);
    }

    /**
     * Set PartsTech credentials
     */
    async setPartsTechCredentialsRaw(requestParameters: SetPartsTechCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling setPartsTechCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/parts-tech-credentials`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PartsTechCredentialsToJSON(requestParameters.partsTechCredentials),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set PartsTech credentials
     */
    async setPartsTechCredentials(requestParameters: SetPartsTechCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setPartsTechCredentialsRaw(requestParameters, initOverrides);
    }

    /**
     * Set repair link access token
     */
    async setRepairLinkAccessTokenRaw(requestParameters: SetRepairLinkAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling setRepairLinkAccessToken.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling setRepairLinkAccessToken.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling setRepairLinkAccessToken.');
        }

        if (requestParameters.redirectUri === null || requestParameters.redirectUri === undefined) {
            throw new runtime.RequiredError('redirectUri','Required parameter requestParameters.redirectUri was null or undefined when calling setRepairLinkAccessToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.shop !== undefined) {
            formParams.append('shop', requestParameters.shop as any);
        }

        if (requestParameters.code !== undefined) {
            formParams.append('code', requestParameters.code as any);
        }

        if (requestParameters.redirectUri !== undefined) {
            formParams.append('redirect-uri', requestParameters.redirectUri as any);
        }

        const response = await this.request({
            path: `/repair-link/access-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Set repair link access token
     */
    async setRepairLinkAccessToken(requestParameters: SetRepairLinkAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.setRepairLinkAccessTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set a user\'s role for a specific shop
     */
    async setRoleRaw(requestParameters: SetRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling setRole.');
        }

        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling setRole.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling setRole.');
        }

        if (requestParameters.role === null || requestParameters.role === undefined) {
            throw new runtime.RequiredError('role','Required parameter requestParameters.role was null or undefined when calling setRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.user !== undefined) {
            formParams.append('user', requestParameters.user as any);
        }

        if (requestParameters.shop !== undefined) {
            formParams.append('shop', requestParameters.shop as any);
        }

        if (requestParameters.role !== undefined) {
            formParams.append('role', requestParameters.role as any);
        }

        if (requestParameters.hourlyRate !== undefined) {
            formParams.append('hourly-rate', requestParameters.hourlyRate as any);
        }

        const response = await this.request({
            path: `/shop/user-role`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set a user\'s role for a specific shop
     */
    async setRole(requestParameters: SetRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setRoleRaw(requestParameters, initOverrides);
    }

    /**
     * Set taxes and fees for shop
     */
    async setTaxesAndFeesRaw(requestParameters: SetTaxesAndFeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling setTaxesAndFees.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/taxes-fees`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TaxesAndFeesToJSON(requestParameters.taxesAndFees),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Set taxes and fees for shop
     */
    async setTaxesAndFees(requestParameters: SetTaxesAndFeesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.setTaxesAndFeesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Callback URL to be used when creating a parts-tech quote session
     */
    async submitQuoteCallbackRaw(requestParameters: SubmitQuoteCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling submitQuoteCallback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/parts-tech/quote/submitted-callback/{service}`.replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback URL to be used when creating a parts-tech quote session
     */
    async submitQuoteCallback(requestParameters: SubmitQuoteCallbackRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitQuoteCallbackRaw(requestParameters, initOverrides);
    }

    /**
     * Callback URL to be used when creating a parts-tech quote session and map the newly added part to the existing part-override
     */
    async submitQuoteCallbackAddPartWithPartOverrideRaw(requestParameters: SubmitQuoteCallbackAddPartWithPartOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling submitQuoteCallbackAddPartWithPartOverride.');
        }

        if (requestParameters.partsMatrix === null || requestParameters.partsMatrix === undefined) {
            throw new runtime.RequiredError('partsMatrix','Required parameter requestParameters.partsMatrix was null or undefined when calling submitQuoteCallbackAddPartWithPartOverride.');
        }

        if (requestParameters.partOverride === null || requestParameters.partOverride === undefined) {
            throw new runtime.RequiredError('partOverride','Required parameter requestParameters.partOverride was null or undefined when calling submitQuoteCallbackAddPartWithPartOverride.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/parts-tech/quote/submitted-callback-add-parts-with-override/{service}/{parts-matrix}/{part-override}`.replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))).replace(`{${"parts-matrix"}}`, encodeURIComponent(String(requestParameters.partsMatrix))).replace(`{${"part-override"}}`, encodeURIComponent(String(requestParameters.partOverride))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback URL to be used when creating a parts-tech quote session and map the newly added part to the existing part-override
     */
    async submitQuoteCallbackAddPartWithPartOverride(requestParameters: SubmitQuoteCallbackAddPartWithPartOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitQuoteCallbackAddPartWithPartOverrideRaw(requestParameters, initOverrides);
    }

    /**
     * Callback URL to be used when creating a parts-tech quote session
     */
    async submitQuoteCallbackAddPartsRaw(requestParameters: SubmitQuoteCallbackAddPartsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling submitQuoteCallbackAddParts.');
        }

        if (requestParameters.partsMatrix === null || requestParameters.partsMatrix === undefined) {
            throw new runtime.RequiredError('partsMatrix','Required parameter requestParameters.partsMatrix was null or undefined when calling submitQuoteCallbackAddParts.');
        }

        if (requestParameters.taxExempt === null || requestParameters.taxExempt === undefined) {
            throw new runtime.RequiredError('taxExempt','Required parameter requestParameters.taxExempt was null or undefined when calling submitQuoteCallbackAddParts.');
        }

        const queryParameters: any = {};

        if (requestParameters.oilFilter !== undefined) {
            queryParameters['oil-filter'] = requestParameters.oilFilter;
        }

        if (requestParameters.fluids !== undefined) {
            queryParameters['fluids'] = requestParameters.fluids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/parts-tech/quote/submitted-callback-add-parts/{service}/{parts-matrix}/{tax-exempt}`.replace(`{${"service"}}`, encodeURIComponent(String(requestParameters.service))).replace(`{${"parts-matrix"}}`, encodeURIComponent(String(requestParameters.partsMatrix))).replace(`{${"tax-exempt"}}`, encodeURIComponent(String(requestParameters.taxExempt))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback URL to be used when creating a parts-tech quote session
     */
    async submitQuoteCallbackAddParts(requestParameters: SubmitQuoteCallbackAddPartsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitQuoteCallbackAddPartsRaw(requestParameters, initOverrides);
    }

    /**
     * Submit a review for a repair order
     */
    async submitReviewRaw(requestParameters: SubmitReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling submitReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerFeedbackInfoToJSON(requestParameters.customerFeedbackInfo),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Submit a review for a repair order
     */
    async submitReview(requestParameters: SubmitReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.submitReviewRaw(requestParameters, initOverrides);
    }

    /**
     * Collect payment via Finix for card-not-present transaction for text-to-pay
     */
    async textToPayPaymentRaw(requestParameters: TextToPayPaymentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RepairPaymentEntry>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling textToPayPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/payment/finix/transfer/text-to-pay`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TextToPayPaymentRequestToJSON(requestParameters.textToPayPaymentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairPaymentEntryFromJSON(jsonValue));
    }

    /**
     * Collect payment via Finix for card-not-present transaction for text-to-pay
     */
    async textToPayPayment(requestParameters: TextToPayPaymentOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RepairPaymentEntry> {
        const response = await this.textToPayPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async throwArithmeticExceptionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ping/error/unknown`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async throwArithmeticException(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.throwArithmeticExceptionRaw(initOverrides);
    }

    /**
     */
    async throwExceptionInConcurrentThreadRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ping/error/threaded-exception`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async throwExceptionInConcurrentThread(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.throwExceptionInConcurrentThreadRaw(initOverrides);
    }

    /**
     */
    async throwLoggableWebAppExceptionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ping/error/dispatch-web-app`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async throwLoggableWebAppException(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.throwLoggableWebAppExceptionRaw(initOverrides);
    }

    /**
     */
    async throwWebAppExceptionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ping/error/web-application`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async throwWebAppException(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.throwWebAppExceptionRaw(initOverrides);
    }

    /**
     * Transcribe an audio file into text
     */
    async transcribeAudioRaw(requestParameters: TranscribeAudioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling transcribeAudio.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/dvi/audio/transcription`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Transcribe an audio file into text
     */
    async transcribeAudio(requestParameters: TranscribeAudioRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.transcribeAudioRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send invoices to codat
     */
    async triggerCodatFunctionRaw(requestParameters: TriggerCodatFunctionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling triggerCodatFunction.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling triggerCodatFunction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.shop !== undefined) {
            formParams.append('shop', requestParameters.shop as any);
        }

        const response = await this.request({
            path: `/invoice/repair-order/codat`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send invoices to codat
     */
    async triggerCodatFunction(requestParameters: TriggerCodatFunctionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.triggerCodatFunctionRaw(requestParameters, initOverrides);
    }

    /**
     * Twilio webhook to dynamically send messages on their appropriate merged conversation
     */
    async twilioConversationMessageRaw(requestParameters: TwilioConversationMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.accountSid === null || requestParameters.accountSid === undefined) {
            throw new runtime.RequiredError('accountSid','Required parameter requestParameters.accountSid was null or undefined when calling twilioConversationMessage.');
        }

        if (requestParameters.conversationSid === null || requestParameters.conversationSid === undefined) {
            throw new runtime.RequiredError('conversationSid','Required parameter requestParameters.conversationSid was null or undefined when calling twilioConversationMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.accountSid !== undefined) {
            formParams.append('AccountSid', requestParameters.accountSid as any);
        }

        if (requestParameters.conversationSid !== undefined) {
            formParams.append('ConversationSid', requestParameters.conversationSid as any);
        }

        if (requestParameters.messageSid !== undefined) {
            formParams.append('MessageSid', requestParameters.messageSid as any);
        }

        if (requestParameters.body !== undefined) {
            formParams.append('Body', requestParameters.body as any);
        }

        if (requestParameters.author !== undefined) {
            formParams.append('Author', requestParameters.author as any);
        }

        if (requestParameters.media !== undefined) {
            formParams.append('Media', requestParameters.media as any);
        }

        if (requestParameters.dateCreated !== undefined) {
            formParams.append('DateCreated', requestParameters.dateCreated as any);
        }

        const response = await this.request({
            path: `/webhook/twilio-conversation-message`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Twilio webhook to dynamically send messages on their appropriate merged conversation
     */
    async twilioConversationMessage(requestParameters: TwilioConversationMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.twilioConversationMessageRaw(requestParameters, initOverrides);
    }

    /**
     * Twilio SMS webhook
     */
    async twilioSmsReceivedRaw(requestParameters: TwilioSmsReceivedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.messageSid !== undefined) {
            formParams.append('MessageSid', requestParameters.messageSid as any);
        }

        if (requestParameters.accountSid !== undefined) {
            formParams.append('AccountSid', requestParameters.accountSid as any);
        }

        if (requestParameters.messagingServiceSid !== undefined) {
            formParams.append('MessagingServiceSid', requestParameters.messagingServiceSid as any);
        }

        if (requestParameters.from !== undefined) {
            formParams.append('From', requestParameters.from as any);
        }

        if (requestParameters.to !== undefined) {
            formParams.append('To', requestParameters.to as any);
        }

        if (requestParameters.body !== undefined) {
            formParams.append('Body', requestParameters.body as any);
        }

        if (requestParameters.numMedia !== undefined) {
            formParams.append('NumMedia', requestParameters.numMedia as any);
        }

        const response = await this.request({
            path: `/webhook/twilio-sms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Twilio SMS webhook
     */
    async twilioSmsReceived(requestParameters: TwilioSmsReceivedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.twilioSmsReceivedRaw(requestParameters, initOverrides);
    }

    /**
     * Unlink a customer from a vehicle
     */
    async unlinkCustomerFromVehicleRaw(requestParameters: UnlinkCustomerFromVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling unlinkCustomerFromVehicle.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling unlinkCustomerFromVehicle.');
        }

        if (requestParameters.vehicle === null || requestParameters.vehicle === undefined) {
            throw new runtime.RequiredError('vehicle','Required parameter requestParameters.vehicle was null or undefined when calling unlinkCustomerFromVehicle.');
        }

        if (requestParameters.customer === null || requestParameters.customer === undefined) {
            throw new runtime.RequiredError('customer','Required parameter requestParameters.customer was null or undefined when calling unlinkCustomerFromVehicle.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.vehicle !== undefined) {
            queryParameters['vehicle'] = requestParameters.vehicle;
        }

        if (requestParameters.customer !== undefined) {
            queryParameters['customer'] = requestParameters.customer;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/customer`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unlink a customer from a vehicle
     */
    async unlinkCustomerFromVehicle(requestParameters: UnlinkCustomerFromVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unlinkCustomerFromVehicleRaw(requestParameters, initOverrides);
    }

    /**
     * Unlink specific inventory part instances for a part-override in a repair-order. Does not release claims since we may only want to remove duplicate links while preserving the claim
     */
    async unlinkInventoryRaw(requestParameters: UnlinkInventoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling unlinkInventory.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling unlinkInventory.');
        }

        if (requestParameters.linkId === null || requestParameters.linkId === undefined) {
            throw new runtime.RequiredError('linkId','Required parameter requestParameters.linkId was null or undefined when calling unlinkInventory.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.linkId) {
            queryParameters['link_id'] = requestParameters.linkId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/instance/link/id`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unlink specific inventory part instances for a part-override in a repair-order. Does not release claims since we may only want to remove duplicate links while preserving the claim
     */
    async unlinkInventory(requestParameters: UnlinkInventoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unlinkInventoryRaw(requestParameters, initOverrides);
    }

    /**
     * Unlink specific inventory part instances for a part-override in a repair-order. Also releases all claims since it makes no sense to unlink but not release claims.Use /inventory/instance/link/id instead since this will unlink all entries
     */
    async unlinkInventoryAndReleaseClaimsRaw(requestParameters: UnlinkInventoryAndReleaseClaimsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling unlinkInventoryAndReleaseClaims.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling unlinkInventoryAndReleaseClaims.');
        }

        if (requestParameters.partOverride === null || requestParameters.partOverride === undefined) {
            throw new runtime.RequiredError('partOverride','Required parameter requestParameters.partOverride was null or undefined when calling unlinkInventoryAndReleaseClaims.');
        }

        const queryParameters: any = {};

        if (requestParameters.shop !== undefined) {
            queryParameters['shop'] = requestParameters.shop;
        }

        if (requestParameters.partOverride !== undefined) {
            queryParameters['part_override'] = requestParameters.partOverride;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/instance/link`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unlink specific inventory part instances for a part-override in a repair-order. Also releases all claims since it makes no sense to unlink but not release claims.Use /inventory/instance/link/id instead since this will unlink all entries
     */
    async unlinkInventoryAndReleaseClaims(requestParameters: UnlinkInventoryAndReleaseClaimsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unlinkInventoryAndReleaseClaimsRaw(requestParameters, initOverrides);
    }

    /**
     * Unlock a session
     */
    async unlockSessionRaw(requestParameters: UnlockSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling unlockSession.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/session/unlock`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMyPinRequestToJSON(requestParameters.updateMyPinRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unlock a session
     */
    async unlockSession(requestParameters: UnlockSessionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unlockSessionRaw(requestParameters, initOverrides);
    }

    /**
     * Update an additional email address for a customer
     */
    async updateAdditionalEmailAddressRaw(requestParameters: UpdateAdditionalEmailAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateAdditionalEmailAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer/additional-email-address`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerAdditionalEmailAddressEntryToJSON(requestParameters.customerAdditionalEmailAddressEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an additional email address for a customer
     */
    async updateAdditionalEmailAddress(requestParameters: UpdateAdditionalEmailAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAdditionalEmailAddressRaw(requestParameters, initOverrides);
    }

    /**
     * Update an additional phone number for a customer
     */
    async updateAdditionalPhoneRaw(requestParameters: UpdateAdditionalPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateAdditionalPhone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer/additional-phone`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerAdditionalPhoneEntryToJSON(requestParameters.customerAdditionalPhoneEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an additional phone number for a customer
     */
    async updateAdditionalPhone(requestParameters: UpdateAdditionalPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAdditionalPhoneRaw(requestParameters, initOverrides);
    }

    /**
     * Update an existing inspection answer to canned job
     */
    async updateAnswerToCannedJobRaw(requestParameters: UpdateAnswerToCannedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateAnswerToCannedJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/answer-to-canned-job`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InspectionAnswerToCannedJobEntryToJSON(requestParameters.inspectionAnswerToCannedJobEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing inspection answer to canned job
     */
    async updateAnswerToCannedJob(requestParameters: UpdateAnswerToCannedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAnswerToCannedJobRaw(requestParameters, initOverrides);
    }

    /**
     * Update an appointment
     */
    async updateAppointmentRaw(requestParameters: UpdateAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateAppointment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentEntryToJSON(requestParameters.appointmentEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an appointment
     */
    async updateAppointment(requestParameters: UpdateAppointmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAppointmentRaw(requestParameters, initOverrides);
    }

    /**
     * Update appointment settings
     */
    async updateAppointmentSettingsRaw(requestParameters: UpdateAppointmentSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateAppointmentSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment/appointment-settings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentSettingsEntryToJSON(requestParameters.appointmentSettingsEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update appointment settings
     */
    async updateAppointmentSettings(requestParameters: UpdateAppointmentSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAppointmentSettingsRaw(requestParameters, initOverrides);
    }

    /**
     * Update appointment time constraints
     */
    async updateAppointmentTimeConstraintsRaw(requestParameters: UpdateAppointmentTimeConstraintsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateAppointmentTimeConstraints.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/appointment/appointment-time-constraints`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AppointmentTimeConstraintsEntryToJSON(requestParameters.appointmentTimeConstraintsEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update appointment time constraints
     */
    async updateAppointmentTimeConstraints(requestParameters: UpdateAppointmentTimeConstraintsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAppointmentTimeConstraintsRaw(requestParameters, initOverrides);
    }

    /**
     * Update an authorization
     */
    async updateAuthorizationRaw(requestParameters: UpdateAuthorizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateAuthorization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorizationEntryToJSON(requestParameters.authorizationEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an authorization
     */
    async updateAuthorization(requestParameters: UpdateAuthorizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAuthorizationRaw(requestParameters, initOverrides);
    }

    /**
     * Update automated vehicle reminder info
     */
    async updateAutomatedVehicleReminderRaw(requestParameters: UpdateAutomatedVehicleReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateAutomatedVehicleReminder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle/automated-vehicle-reminder`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AutomatedVehicleReminderEntryToJSON(requestParameters.automatedVehicleReminderEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update automated vehicle reminder info
     */
    async updateAutomatedVehicleReminder(requestParameters: UpdateAutomatedVehicleReminderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateAutomatedVehicleReminderRaw(requestParameters, initOverrides);
    }

    /**
     * Update a canned job
     */
    async updateCannedJobRaw(requestParameters: UpdateCannedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCannedJob.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobEntryToJSON(requestParameters.cannedJobEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a canned job
     */
    async updateCannedJob(requestParameters: UpdateCannedJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCannedJobRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateCannedJobCustomFeeRaw(requestParameters: UpdateCannedJobCustomFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCannedJobCustomFee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/custom-fee`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobCustomFeeEntryToJSON(requestParameters.cannedJobCustomFeeEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateCannedJobCustomFee(requestParameters: UpdateCannedJobCustomFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCannedJobCustomFeeRaw(requestParameters, initOverrides);
    }

    /**
     * Update a custom labor for canned job
     */
    async updateCannedJobCustomLaborRaw(requestParameters: UpdateCannedJobCustomLaborRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCannedJobCustomLabor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/custom-labor`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobCustomLaborEntryToJSON(requestParameters.cannedJobCustomLaborEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a custom labor for canned job
     */
    async updateCannedJobCustomLabor(requestParameters: UpdateCannedJobCustomLaborRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCannedJobCustomLaborRaw(requestParameters, initOverrides);
    }

    /**
     * Update a canned job custom part
     */
    async updateCannedJobCustomPartRaw(requestParameters: UpdateCannedJobCustomPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCannedJobCustomPart.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/custom-part`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobCustomPartEntryToJSON(requestParameters.cannedJobCustomPartEntry),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update a canned job custom part
     */
    async updateCannedJobCustomPart(requestParameters: UpdateCannedJobCustomPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateCannedJobCustomPartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a motor fluids for canned job
     */
    async updateCannedJobMotorFluidsRaw(requestParameters: UpdateCannedJobMotorFluidsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCannedJobMotorFluids.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/motor-fluids`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobMotorFluidsEntryToJSON(requestParameters.cannedJobMotorFluidsEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a motor fluids for canned job
     */
    async updateCannedJobMotorFluids(requestParameters: UpdateCannedJobMotorFluidsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCannedJobMotorFluidsRaw(requestParameters, initOverrides);
    }

    /**
     * Update a motor labor for canned job
     */
    async updateCannedJobMotorLaborRaw(requestParameters: UpdateCannedJobMotorLaborRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCannedJobMotorLabor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/motor-labor`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobMotorLaborEntryToJSON(requestParameters.cannedJobMotorLaborEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a motor labor for canned job
     */
    async updateCannedJobMotorLabor(requestParameters: UpdateCannedJobMotorLaborRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCannedJobMotorLaborRaw(requestParameters, initOverrides);
    }

    /**
     * Update a part lookup for canned job
     */
    async updateCannedJobPartLookupRaw(requestParameters: UpdateCannedJobPartLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCannedJobPartLookup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/part-lookup`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobPartLookupEntryToJSON(requestParameters.cannedJobPartLookupEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a part lookup for canned job
     */
    async updateCannedJobPartLookup(requestParameters: UpdateCannedJobPartLookupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCannedJobPartLookupRaw(requestParameters, initOverrides);
    }

    /**
     * Update canned job related
     */
    async updateCannedJobRelatedRaw(requestParameters: UpdateCannedJobRelatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCannedJobRelated.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/canned-job-related`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobRelatedEntryToJSON(requestParameters.cannedJobRelatedEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update canned job related
     */
    async updateCannedJobRelated(requestParameters: UpdateCannedJobRelatedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCannedJobRelatedRaw(requestParameters, initOverrides);
    }

    /**
     * Update a canned job to inventory part
     */
    async updateCannedJobToInventoryPartRaw(requestParameters: UpdateCannedJobToInventoryPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCannedJobToInventoryPart.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/inventory-part`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobInventoryPartEntryToJSON(requestParameters.cannedJobInventoryPartEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a canned job to inventory part
     */
    async updateCannedJobToInventoryPart(requestParameters: UpdateCannedJobToInventoryPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCannedJobToInventoryPartRaw(requestParameters, initOverrides);
    }

    /**
     * Update a canned job to inventory product
     */
    async updateCannedJobToInventoryProductRaw(requestParameters: UpdateCannedJobToInventoryProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCannedJobToInventoryProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/canned-job/inventory-product`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CannedJobToInventoryProductEntryToJSON(requestParameters.cannedJobToInventoryProductEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a canned job to inventory product
     */
    async updateCannedJobToInventoryProduct(requestParameters: UpdateCannedJobToInventoryProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCannedJobToInventoryProductRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a new UUID corresponding to the new catalog labor matrix entry
     */
    async updateCatalogLaborMatrixEntryRaw(requestParameters: UpdateCatalogLaborMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCatalogLaborMatrixEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/catalog-labor-matrix`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CatalogLaborMatrixEntryToJSON(requestParameters.catalogLaborMatrixEntry),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns a new UUID corresponding to the new catalog labor matrix entry
     */
    async updateCatalogLaborMatrixEntry(requestParameters: UpdateCatalogLaborMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateCatalogLaborMatrixEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update catalog labor matrix ranks
     */
    async updateCatalogLaborMatrixRanksRaw(requestParameters: UpdateCatalogLaborMatrixRanksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCatalogLaborMatrixRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/catalog-labor-matrix/rank`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCatalogLaborMatrixRanksRequestToJSON(requestParameters.updateCatalogLaborMatrixRanksRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update catalog labor matrix ranks
     */
    async updateCatalogLaborMatrixRanks(requestParameters: UpdateCatalogLaborMatrixRanksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCatalogLaborMatrixRanksRaw(requestParameters, initOverrides);
    }

    /**
     * Update a counter sale entry
     */
    async updateCounterSaleRaw(requestParameters: UpdateCounterSaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCounterSale.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/counter-sale`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CounterSaleEntryToJSON(requestParameters.counterSaleEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a counter sale entry
     */
    async updateCounterSale(requestParameters: UpdateCounterSaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCounterSaleRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateCustomFeeRaw(requestParameters: UpdateCustomFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCustomFee.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/custom-fee`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomFeeEntryToJSON(requestParameters.customFeeEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateCustomFee(requestParameters: UpdateCustomFeeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCustomFeeRaw(requestParameters, initOverrides);
    }

    /**
     * Update a custom message template
     */
    async updateCustomMessageTemplateRaw(requestParameters: UpdateCustomMessageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCustomMessageTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/custom-message-template`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MessageTemplateEntryToJSON(requestParameters.messageTemplateEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a custom message template
     */
    async updateCustomMessageTemplate(requestParameters: UpdateCustomMessageTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCustomMessageTemplateRaw(requestParameters, initOverrides);
    }

    /**
     * Update a customer. Same required fields as creating a new customer
     */
    async updateCustomerRaw(requestParameters: UpdateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/customer`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerEntryToJSON(requestParameters.customerEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a customer. Same required fields as creating a new customer
     */
    async updateCustomer(requestParameters: UpdateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateCustomerRaw(requestParameters, initOverrides);
    }

    /**
     * Update a discount entry
     */
    async updateDiscountRaw(requestParameters: UpdateDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateDiscount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/discount`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DiscountEntryToJSON(requestParameters.discountEntry),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update a discount entry
     */
    async updateDiscount(requestParameters: UpdateDiscountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateDiscountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing inspection
     */
    async updateInspectionRaw(requestParameters: UpdateInspectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateInspection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InspectionEntryToJSON(requestParameters.inspectionEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing inspection
     */
    async updateInspection(requestParameters: UpdateInspectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInspectionRaw(requestParameters, initOverrides);
    }

    /**
     * Update an existing inspection entry
     */
    async updateInspectionEntryRaw(requestParameters: UpdateInspectionEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateInspectionEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/entry`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EntryEntryToJSON(requestParameters.entryEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing inspection entry
     */
    async updateInspectionEntry(requestParameters: UpdateInspectionEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInspectionEntryRaw(requestParameters, initOverrides);
    }

    /**
     * Update ranks of inspection entries
     */
    async updateInspectionEntryRanksRaw(requestParameters: UpdateInspectionEntryRanksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateInspectionEntryRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/entry/rank`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInspectionEntryRanksRequestToJSON(requestParameters.updateInspectionEntryRanksRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update ranks of inspection entries
     */
    async updateInspectionEntryRanks(requestParameters: UpdateInspectionEntryRanksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInspectionEntryRanksRaw(requestParameters, initOverrides);
    }

    /**
     * Update an existing entry in the shop\'s inspection entry status
     */
    async updateInspectionEntryStatusRaw(requestParameters: UpdateInspectionEntryStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateInspectionEntryStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/inspection-entry-status`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InspectionEntryStatusEntryToJSON(requestParameters.inspectionEntryStatusEntry),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update an existing entry in the shop\'s inspection entry status
     */
    async updateInspectionEntryStatus(requestParameters: UpdateInspectionEntryStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateInspectionEntryStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update ranks of inspection entry statuses
     */
    async updateInspectionEntryStatusRanksRaw(requestParameters: UpdateInspectionEntryStatusRanksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateInspectionEntryStatusRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/inspection-entry-status/rank`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInspectionEntryStatusRanksRequestToJSON(requestParameters.updateInspectionEntryStatusRanksRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update ranks of inspection entry statuses
     */
    async updateInspectionEntryStatusRanks(requestParameters: UpdateInspectionEntryStatusRanksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInspectionEntryStatusRanksRaw(requestParameters, initOverrides);
    }

    /**
     * Update an existing inspection prompt
     */
    async updateInspectionPromptRaw(requestParameters: UpdateInspectionPromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateInspectionPrompt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/prompt`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PromptEntryToJSON(requestParameters.promptEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing inspection prompt
     */
    async updateInspectionPrompt(requestParameters: UpdateInspectionPromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInspectionPromptRaw(requestParameters, initOverrides);
    }

    /**
     * Update an existing inspection
     */
    async updateInspectionSectionRaw(requestParameters: UpdateInspectionSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateInspectionSection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/section`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SectionEntryToJSON(requestParameters.sectionEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing inspection
     */
    async updateInspectionSection(requestParameters: UpdateInspectionSectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInspectionSectionRaw(requestParameters, initOverrides);
    }

    /**
     * Update ranks of inspection sections
     */
    async updateInspectionSectionRanksRaw(requestParameters: UpdateInspectionSectionRanksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateInspectionSectionRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/section/rank`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInspectionSectionRanksRequestToJSON(requestParameters.updateInspectionSectionRanksRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update ranks of inspection sections
     */
    async updateInspectionSectionRanks(requestParameters: UpdateInspectionSectionRanksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInspectionSectionRanksRaw(requestParameters, initOverrides);
    }

    /**
     * Update an intake request
     */
    async updateIntakeRequestRaw(requestParameters: UpdateIntakeRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateIntakeRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/intake`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateIntakeRequestObjectToJSON(requestParameters.updateIntakeRequestObject),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an intake request
     */
    async updateIntakeRequest(requestParameters: UpdateIntakeRequestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateIntakeRequestRaw(requestParameters, initOverrides);
    }

    /**
     * Update inventory adjustment.
     */
    async updateInventoryAdjustmentRaw(requestParameters: UpdateInventoryAdjustmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateInventoryAdjustment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/adjustment`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InventoryAdjustmentEntryToJSON(requestParameters.inventoryAdjustmentEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update inventory adjustment.
     */
    async updateInventoryAdjustment(requestParameters: UpdateInventoryAdjustmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInventoryAdjustmentRaw(requestParameters, initOverrides);
    }

    /**
     * Update inventory adjustment document.
     */
    async updateInventoryAdjustmentDocumentRaw(requestParameters: UpdateInventoryAdjustmentDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateInventoryAdjustmentDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/adjustment/document`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InventoryAdjustmentDocumentEntryToJSON(requestParameters.inventoryAdjustmentDocumentEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update inventory adjustment document.
     */
    async updateInventoryAdjustmentDocument(requestParameters: UpdateInventoryAdjustmentDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInventoryAdjustmentDocumentRaw(requestParameters, initOverrides);
    }

    /**
     * Update an instance for the shop\'s inventory
     */
    async updateInventoryInstanceRaw(requestParameters: UpdateInventoryInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateInventoryInstance.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/instance`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InventoryPartInstanceEntryToJSON(requestParameters.inventoryPartInstanceEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an instance for the shop\'s inventory
     */
    async updateInventoryInstance(requestParameters: UpdateInventoryInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInventoryInstanceRaw(requestParameters, initOverrides);
    }

    /**
     * Update inventory instances with an adjustment
     */
    async updateInventoryInstancesWithAdjustmentRaw(requestParameters: UpdateInventoryInstancesWithAdjustmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateInventoryInstancesWithAdjustment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory/instance/adjustment`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateInventoryInstanceWithAdjustmentRequestToJSON(requestParameters.updateInventoryInstanceWithAdjustmentRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update inventory instances with an adjustment
     */
    async updateInventoryInstancesWithAdjustment(requestParameters: UpdateInventoryInstancesWithAdjustmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateInventoryInstancesWithAdjustmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a part for the shop\'s inventory
     */
    async updateInventoryPartRaw(requestParameters: UpdateInventoryPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateInventoryPart.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/inventory`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InventoryPartEntryToJSON(requestParameters.inventoryPartEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a part for the shop\'s inventory
     */
    async updateInventoryPart(requestParameters: UpdateInventoryPartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInventoryPartRaw(requestParameters, initOverrides);
    }

    /**
     * Update email address for an invitee. Will only work if the user has not created an account yet. If they have, they need to use the PUT /user/email API to update their own address
     */
    async updateInviteeEmailRaw(requestParameters: UpdateInviteeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateInviteeEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/invitee/email`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmailRequestToJSON(requestParameters.updateEmailRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update email address for an invitee. Will only work if the user has not created an account yet. If they have, they need to use the PUT /user/email API to update their own address
     */
    async updateInviteeEmail(requestParameters: UpdateInviteeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateInviteeEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Returns a new UUID corresponding to the new labor matrix entry
     */
    async updateLaborMatrixEntryRaw(requestParameters: UpdateLaborMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateLaborMatrixEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/labor-matrix`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LaborMatrixEntryToJSON(requestParameters.laborMatrixEntry),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns a new UUID corresponding to the new labor matrix entry
     */
    async updateLaborMatrixEntry(requestParameters: UpdateLaborMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateLaborMatrixEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update labor matrix ranks
     */
    async updateLaborMatrixRanksRaw(requestParameters: UpdateLaborMatrixRanksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateLaborMatrixRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/labor-matrix/rank`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLaborMatrixRanksRequestToJSON(requestParameters.updateLaborMatrixRanksRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update labor matrix ranks
     */
    async updateLaborMatrixRanks(requestParameters: UpdateLaborMatrixRanksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateLaborMatrixRanksRaw(requestParameters, initOverrides);
    }

    /**
     * Update all canned-jobs and customers using a specific labor-matrix-id to a replacement labor-matrix-id
     */
    async updateLaborMatrixUsersRaw(requestParameters: UpdateLaborMatrixUsersOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateLaborMatrixUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/labor-matrix/users`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLaborMatrixUsersRequestToJSON(requestParameters.updateLaborMatrixUsersRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update all canned-jobs and customers using a specific labor-matrix-id to a replacement labor-matrix-id
     */
    async updateLaborMatrixUsers(requestParameters: UpdateLaborMatrixUsersOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateLaborMatrixUsersRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateLaborOverrideByIdRaw(requestParameters: UpdateLaborOverrideByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateLaborOverrideById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/labor-override/id`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LaborOverrideToJSON(requestParameters.laborOverride),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateLaborOverrideById(requestParameters: UpdateLaborOverrideByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateLaborOverrideByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Update last-viewed timestamp
     */
    async updateLastViewedRaw(requestParameters: UpdateLastViewedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateLastViewed.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/notification/new`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update last-viewed timestamp
     */
    async updateLastViewed(requestParameters: UpdateLastViewedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateLastViewedRaw(requestParameters, initOverrides);
    }

    /**
     * Update details of a media item other than the binary media content
     */
    async updateMediaInfoRaw(requestParameters: UpdateMediaInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateMediaInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/media`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InspectionMediaEntryToJSON(requestParameters.inspectionMediaEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update details of a media item other than the binary media content
     */
    async updateMediaInfo(requestParameters: UpdateMediaInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMediaInfoRaw(requestParameters, initOverrides);
    }

    /**
     * Update mileage in for repair order
     */
    async updateMileageInRaw(requestParameters: UpdateMileageInRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Date>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateMileageIn.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/mileage-in`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMileageRequestToJSON(requestParameters.updateMileageRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<Date>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update mileage in for repair order
     */
    async updateMileageIn(requestParameters: UpdateMileageInRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Date> {
        const response = await this.updateMileageInRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update mileage out for repair order and vehicle
     */
    async updateMileageOutRaw(requestParameters: UpdateMileageOutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Date>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateMileageOut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/mileage-out`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMileageRequestToJSON(requestParameters.updateMileageRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<Date>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update mileage out for repair order and vehicle
     */
    async updateMileageOut(requestParameters: UpdateMileageOutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Date> {
        const response = await this.updateMileageOutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing inspection prompt multiple choice answer
     */
    async updateMultipleChoiceAnswerRaw(requestParameters: UpdateMultipleChoiceAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateMultipleChoiceAnswer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dvi/inspection/prompt/multiple-choice-answer`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MultipleChoiceAnswerEntryToJSON(requestParameters.multipleChoiceAnswerEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing inspection prompt multiple choice answer
     */
    async updateMultipleChoiceAnswer(requestParameters: UpdateMultipleChoiceAnswerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMultipleChoiceAnswerRaw(requestParameters, initOverrides);
    }

    /**
     * Update user\'s email address. This will impact the user\'s login credentials, and is a complex process. Hence, do this as a separate endpoint, to ensure safety and user-intent
     */
    async updateMyEmailRaw(requestParameters: UpdateMyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateMyEmail.');
        }

        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling updateMyEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.email !== undefined) {
            formParams.append('email', requestParameters.email as any);
        }

        const response = await this.request({
            path: `/user/email`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update user\'s email address. This will impact the user\'s login credentials, and is a complex process. Hence, do this as a separate endpoint, to ensure safety and user-intent
     */
    async updateMyEmail(requestParameters: UpdateMyEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMyEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Update my pin
     */
    async updateMyPinRaw(requestParameters: UpdateMyPinOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateMyPin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/pin`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMyPinRequestToJSON(requestParameters.updateMyPinRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update my pin
     */
    async updateMyPin(requestParameters: UpdateMyPinOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateMyPinRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updatePartOverrideRaw(requestParameters: UpdatePartOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updatePartOverride.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/parts-override`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PartOverrideToJSON(requestParameters.partOverride),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updatePartOverride(requestParameters: UpdatePartOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePartOverrideRaw(requestParameters, initOverrides);
    }

    /**
     * Update the status of a part
     */
    async updatePartStatusRaw(requestParameters: UpdatePartStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updatePartStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/status`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PartStatusEntryToJSON(requestParameters.partStatusEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the status of a part
     */
    async updatePartStatus(requestParameters: UpdatePartStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePartStatusRaw(requestParameters, initOverrides);
    }

    /**
     * Update an existing entry in the shop\'s parts matrix
     */
    async updatePartsMatrixEntryRaw(requestParameters: UpdatePartsMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updatePartsMatrixEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/parts-matrix`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PartsMatrixEntryToJSON(requestParameters.partsMatrixEntry),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update an existing entry in the shop\'s parts matrix
     */
    async updatePartsMatrixEntry(requestParameters: UpdatePartsMatrixEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updatePartsMatrixEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update parts matrix ranks
     */
    async updatePartsMatrixRanksRaw(requestParameters: UpdatePartsMatrixRanksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updatePartsMatrixRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/parts-matrix/rank`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePartsMatrixRanksRequestToJSON(requestParameters.updatePartsMatrixRanksRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update parts matrix ranks
     */
    async updatePartsMatrixRanks(requestParameters: UpdatePartsMatrixRanksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePartsMatrixRanksRaw(requestParameters, initOverrides);
    }

    /**
     * Update all canned-jobs using a specific parts-matrix-id to a replacement parts-matrix-id
     */
    async updatePartsMatrixUsersRaw(requestParameters: UpdatePartsMatrixUsersOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updatePartsMatrixUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/parts-matrix/users`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePartsMatrixUsersRequestToJSON(requestParameters.updatePartsMatrixUsersRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update all canned-jobs using a specific parts-matrix-id to a replacement parts-matrix-id
     */
    async updatePartsMatrixUsers(requestParameters: UpdatePartsMatrixUsersOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePartsMatrixUsersRaw(requestParameters, initOverrides);
    }

    /**
     * Update price level
     */
    async updatePriceLevelRaw(requestParameters: UpdatePriceLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updatePriceLevel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/price-level`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PriceLevelEntryToJSON(requestParameters.priceLevelEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update price level
     */
    async updatePriceLevel(requestParameters: UpdatePriceLevelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePriceLevelRaw(requestParameters, initOverrides);
    }

    /**
     * Update price level ranks
     */
    async updatePriceLevelRanksRaw(requestParameters: UpdatePriceLevelRanksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updatePriceLevelRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/price-level/rank`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePriceLevelRanksRequestToJSON(requestParameters.updatePriceLevelRanksRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update price level ranks
     */
    async updatePriceLevelRanks(requestParameters: UpdatePriceLevelRanksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePriceLevelRanksRaw(requestParameters, initOverrides);
    }

    /**
     * Update an existing entry in the shop\'s productivity status
     */
    async updateProductivityStatusRaw(requestParameters: UpdateProductivityStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateProductivityStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/productivity-status`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductivityStatusEntryToJSON(requestParameters.productivityStatusEntry),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update an existing entry in the shop\'s productivity status
     */
    async updateProductivityStatus(requestParameters: UpdateProductivityStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateProductivityStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Perform a full-update on my user-profile
     */
    async updateProfileRaw(requestParameters: UpdateProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateProfile.');
        }

        if (requestParameters.realName === null || requestParameters.realName === undefined) {
            throw new runtime.RequiredError('realName','Required parameter requestParameters.realName was null or undefined when calling updateProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.realName !== undefined) {
            formParams.append('realName', requestParameters.realName as any);
        }

        const response = await this.request({
            path: `/user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Perform a full-update on my user-profile
     */
    async updateProfile(requestParameters: UpdateProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateProfileRaw(requestParameters, initOverrides);
    }

    /**
     * Update a purchase order
     */
    async updatePurchaseOrderRaw(requestParameters: UpdatePurchaseOrderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updatePurchaseOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/purchase-order`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePurchaseOrderRequestToJSON(requestParameters.updatePurchaseOrderRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a purchase order
     */
    async updatePurchaseOrder(requestParameters: UpdatePurchaseOrderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updatePurchaseOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Edit the quantity of an item in a PartsTech cart
     */
    async updateQuantityRaw(requestParameters: UpdateQuantityOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateQuantity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/parts-tech/quantity`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateQuantityRequestToJSON(requestParameters.updateQuantityRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Edit the quantity of an item in a PartsTech cart
     */
    async updateQuantity(requestParameters: UpdateQuantityOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateQuantityRaw(requestParameters, initOverrides);
    }

    /**
     * Update prices for repair link parts in cart
     */
    async updateRepairLinkPricesRaw(requestParameters: UpdateRepairLinkPricesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateRepairLinkPrices.');
        }

        if (requestParameters.service === null || requestParameters.service === undefined) {
            throw new runtime.RequiredError('service','Required parameter requestParameters.service was null or undefined when calling updateRepairLinkPrices.');
        }

        if (requestParameters.accessToken === null || requestParameters.accessToken === undefined) {
            throw new runtime.RequiredError('accessToken','Required parameter requestParameters.accessToken was null or undefined when calling updateRepairLinkPrices.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.service !== undefined) {
            formParams.append('service', requestParameters.service as any);
        }

        if (requestParameters.accessToken !== undefined) {
            formParams.append('access-token', requestParameters.accessToken as any);
        }

        const response = await this.request({
            path: `/repair-link/prices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update prices for repair link parts in cart
     */
    async updateRepairLinkPrices(requestParameters: UpdateRepairLinkPricesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRepairLinkPricesRaw(requestParameters, initOverrides);
    }

    /**
     * Update an existing repair order. Note that the ID, vehicle or shop cannot be changed
     */
    async updateRepairOrderRaw(requestParameters: UpdateRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Date>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateRepairOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RepairOrderEntryToJSON(requestParameters.repairOrderEntry),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<Date>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update an existing repair order. Note that the ID, vehicle or shop cannot be changed
     */
    async updateRepairOrder(requestParameters: UpdateRepairOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Date> {
        const response = await this.updateRepairOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update details of an attachment other than url
     */
    async updateRepairOrderAttachmentInfoRaw(requestParameters: UpdateRepairOrderAttachmentInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateRepairOrderAttachmentInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/attachment`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RepairOrderAttachmentEntryToJSON(requestParameters.repairOrderAttachmentEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update details of an attachment other than url
     */
    async updateRepairOrderAttachmentInfo(requestParameters: UpdateRepairOrderAttachmentInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRepairOrderAttachmentInfoRaw(requestParameters, initOverrides);
    }

    /**
     * Update details of a media item other than the binary media content
     */
    async updateRepairOrderMediaInfoRaw(requestParameters: UpdateRepairOrderMediaInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateRepairOrderMediaInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/media`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RepairOrderMediaEntryToJSON(requestParameters.repairOrderMediaEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update details of a media item other than the binary media content
     */
    async updateRepairOrderMediaInfo(requestParameters: UpdateRepairOrderMediaInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRepairOrderMediaInfoRaw(requestParameters, initOverrides);
    }

    /**
     * Update a repair-order status
     */
    async updateRepairOrderStatusRaw(requestParameters: UpdateRepairOrderStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateRepairOrderStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/repair-order-status`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RepairOrderStatusEntryToJSON(requestParameters.repairOrderStatusEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a repair-order status
     */
    async updateRepairOrderStatus(requestParameters: UpdateRepairOrderStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRepairOrderStatusRaw(requestParameters, initOverrides);
    }

    /**
     * Adjust ranks for repair order statuses for a shop based on an ordered list of status IDs
     */
    async updateRepairOrderStatusRanksRaw(requestParameters: UpdateRepairOrderStatusRanksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateRepairOrderStatusRanks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/repair-order-status/rank`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReorderRepairOrderStatusesRequestToJSON(requestParameters.reorderRepairOrderStatusesRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Adjust ranks for repair order statuses for a shop based on an ordered list of status IDs
     */
    async updateRepairOrderStatusRanks(requestParameters: UpdateRepairOrderStatusRanksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRepairOrderStatusRanksRaw(requestParameters, initOverrides);
    }

    /**
     * Update a repair-payment entry
     */
    async updateRepairPaymentRaw(requestParameters: UpdateRepairPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateRepairPayment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/payment`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RepairPaymentEntryToJSON(requestParameters.repairPaymentEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a repair-payment entry
     */
    async updateRepairPayment(requestParameters: UpdateRepairPaymentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRepairPaymentRaw(requestParameters, initOverrides);
    }

    /**
     * Update a repair-payment type for one or more entries
     */
    async updateRepairPayment1Raw(requestParameters: UpdateRepairPayment1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateRepairPayment1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/authorization/payment/type`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePaymentTypesToJSON(requestParameters.updatePaymentTypes),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a repair-payment type for one or more entries
     */
    async updateRepairPayment1(requestParameters: UpdateRepairPayment1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRepairPayment1Raw(requestParameters, initOverrides);
    }

    /**
     * Update a resource
     */
    async updateResourceRaw(requestParameters: UpdateResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateResource.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/resource`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResourceEntryToJSON(requestParameters.resourceEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a resource
     */
    async updateResource(requestParameters: UpdateResourceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateResourceRaw(requestParameters, initOverrides);
    }

    /**
     * Update a role
     */
    async updateRoleRaw(requestParameters: UpdateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/role`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoleToJSON(requestParameters.role),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update a role
     */
    async updateRole(requestParameters: UpdateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update permissions for a specific role
     */
    async updateRolePermissionsRaw(requestParameters: UpdateRolePermissionsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateRolePermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/role/permission`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRolePermissionsRequestToJSON(requestParameters.updateRolePermissionsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update permissions for a specific role
     */
    async updateRolePermissions(requestParameters: UpdateRolePermissionsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateRolePermissionsRaw(requestParameters, initOverrides);
    }

    /**
     * Update a service
     */
    async updateServiceRaw(requestParameters: UpdateServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceEntryToJSON(requestParameters.serviceEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a service
     */
    async updateService(requestParameters: UpdateServiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateServiceRaw(requestParameters, initOverrides);
    }

    /**
     * Set Recommended or Deferred status for a service by API key
     */
    async updateServiceViaApiKeyRaw(requestParameters: UpdateServiceViaApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xAPIKey === null || requestParameters.xAPIKey === undefined) {
            throw new runtime.RequiredError('xAPIKey','Required parameter requestParameters.xAPIKey was null or undefined when calling updateServiceViaApiKey.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAPIKey !== undefined && requestParameters.xAPIKey !== null) {
            headerParameters['X-API-Key'] = String(requestParameters.xAPIKey);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/customer-view`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerServiceStatusRequestToJSON(requestParameters.customerServiceStatusRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Set Recommended or Deferred status for a service by API key
     */
    async updateServiceViaApiKey(requestParameters: UpdateServiceViaApiKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateServiceViaApiKeyRaw(requestParameters, initOverrides);
    }

    /**
     * Full-update my push-notification settings
     */
    async updateSettingsRaw(requestParameters: UpdateSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateSettings.');
        }

        if (requestParameters.directMessageReceived === null || requestParameters.directMessageReceived === undefined) {
            throw new runtime.RequiredError('directMessageReceived','Required parameter requestParameters.directMessageReceived was null or undefined when calling updateSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.directMessageReceived !== undefined) {
            formParams.append('directMessageReceived', requestParameters.directMessageReceived as any);
        }

        const response = await this.request({
            path: `/notification/setting`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Full-update my push-notification settings
     */
    async updateSettings(requestParameters: UpdateSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateSettingsRaw(requestParameters, initOverrides);
    }

    /**
     * Update a shop feature
     */
    async updateShopFeatureRaw(requestParameters: UpdateShopFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateShopFeature.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/features`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ShopFeatureEntryToJSON(requestParameters.shopFeatureEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a shop feature
     */
    async updateShopFeature(requestParameters: UpdateShopFeatureRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateShopFeatureRaw(requestParameters, initOverrides);
    }

    /**
     * Perform a full-update on a shop
     */
    async updateShopProfileRaw(requestParameters: UpdateShopProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateShopProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ShopEntryToJSON(requestParameters.shopEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Perform a full-update on a shop
     */
    async updateShopProfile(requestParameters: UpdateShopProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateShopProfileRaw(requestParameters, initOverrides);
    }

    /**
     * Update a shop vendor
     */
    async updateShopVendorRaw(requestParameters: UpdateShopVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateShopVendor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shop/vendor`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VendorEntryToJSON(requestParameters.vendorEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a shop vendor
     */
    async updateShopVendor(requestParameters: UpdateShopVendorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateShopVendorRaw(requestParameters, initOverrides);
    }

    /**
     * Update a sublet
     */
    async updateSubletRaw(requestParameters: UpdateSubletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateSublet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invoice/repair-order/service/sublet`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SubletEntryToJSON(requestParameters.subletEntry),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Update a sublet
     */
    async updateSublet(requestParameters: UpdateSubletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateSubletRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a team member\'s password
     */
    async updateTeamMemberPasswordRaw(requestParameters: UpdateTeamMemberPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateTeamMemberPassword.');
        }

        if (requestParameters.member === null || requestParameters.member === undefined) {
            throw new runtime.RequiredError('member','Required parameter requestParameters.member was null or undefined when calling updateTeamMemberPassword.');
        }

        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling updateTeamMemberPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.member !== undefined) {
            formParams.append('member', requestParameters.member as any);
        }

        if (requestParameters.password !== undefined) {
            formParams.append('password', requestParameters.password as any);
        }

        const response = await this.request({
            path: `/user/team-member/password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a team member\'s password
     */
    async updateTeamMemberPassword(requestParameters: UpdateTeamMemberPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTeamMemberPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Update a team member\'s profile
     */
    async updateTeamMemberProfileRaw(requestParameters: UpdateTeamMemberProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateTeamMemberProfile.');
        }

        if (requestParameters.member === null || requestParameters.member === undefined) {
            throw new runtime.RequiredError('member','Required parameter requestParameters.member was null or undefined when calling updateTeamMemberProfile.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling updateTeamMemberProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.member !== undefined) {
            formParams.append('member', requestParameters.member as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        const response = await this.request({
            path: `/user/team-member`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a team member\'s profile
     */
    async updateTeamMemberProfile(requestParameters: UpdateTeamMemberProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTeamMemberProfileRaw(requestParameters, initOverrides);
    }

    /**
     * Update a time clock entry
     */
    async updateTimeClockRaw(requestParameters: UpdateTimeClockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateTimeClock.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/time-clock`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TimeClockEntryToJSON(requestParameters.timeClockEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a time clock entry
     */
    async updateTimeClock(requestParameters: UpdateTimeClockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateTimeClockRaw(requestParameters, initOverrides);
    }

    /**
     * Perform a full-update on a vehicle
     */
    async updateVehicleInfoRaw(requestParameters: UpdateVehicleInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateVehicleInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicle`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleEntryToJSON(requestParameters.vehicleEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Perform a full-update on a vehicle
     */
    async updateVehicleInfo(requestParameters: UpdateVehicleInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateVehicleInfoRaw(requestParameters, initOverrides);
    }

    /**
     * Update a warranty entry
     */
    async updateWarrantyEntryRaw(requestParameters: UpdateWarrantyEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling updateWarrantyEntry.');
        }

        if (requestParameters.warrantyEntry === null || requestParameters.warrantyEntry === undefined) {
            throw new runtime.RequiredError('warrantyEntry','Required parameter requestParameters.warrantyEntry was null or undefined when calling updateWarrantyEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/part/warranty`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WarrantyEntryToJSON(requestParameters.warrantyEntry),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a warranty entry
     */
    async updateWarrantyEntry(requestParameters: UpdateWarrantyEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateWarrantyEntryRaw(requestParameters, initOverrides);
    }

    /**
     * Upload file of an existing media item
     */
    async uploadFileOfMediaEntryRaw(requestParameters: UploadFileOfMediaEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InspectionMediaEntry>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling uploadFileOfMediaEntry.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadFileOfMediaEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('id', requestParameters.id as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/dvi/inspection/media-entry`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InspectionMediaEntryFromJSON(jsonValue));
    }

    /**
     * Upload file of an existing media item
     */
    async uploadFileOfMediaEntry(requestParameters: UploadFileOfMediaEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InspectionMediaEntry> {
        const response = await this.uploadFileOfMediaEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload document for text analysis
     */
    async uploadScannedDocumentRaw(requestParameters: UploadScannedDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PartInvoiceAnalysisResponse>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling uploadScannedDocument.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling uploadScannedDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.shopId !== undefined) {
            formParams.append('shopId', requestParameters.shopId as any);
        }

        const response = await this.request({
            path: `/ai/scanned-document`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PartInvoiceAnalysisResponseFromJSON(jsonValue));
    }

    /**
     * Upload document for text analysis
     */
    async uploadScannedDocument(requestParameters: UploadScannedDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PartInvoiceAnalysisResponse> {
        const response = await this.uploadScannedDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload invoice for analysis
     */
    async uploadScannedExpenseDocumentRaw(requestParameters: UploadScannedExpenseDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExpenseAnalysisResponse>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling uploadScannedExpenseDocument.');
        }

        if (requestParameters.shopId === null || requestParameters.shopId === undefined) {
            throw new runtime.RequiredError('shopId','Required parameter requestParameters.shopId was null or undefined when calling uploadScannedExpenseDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.shopId !== undefined) {
            formParams.append('shopId', requestParameters.shopId as any);
        }

        const response = await this.request({
            path: `/ai/scanned-expense-document`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExpenseAnalysisResponseFromJSON(jsonValue));
    }

    /**
     * Upload invoice for analysis
     */
    async uploadScannedExpenseDocument(requestParameters: UploadScannedExpenseDocumentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExpenseAnalysisResponse> {
        const response = await this.uploadScannedExpenseDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change shop logo
     */
    async uploadShopLogoRaw(requestParameters: UploadShopLogoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling uploadShopLogo.');
        }

        if (requestParameters.shop === null || requestParameters.shop === undefined) {
            throw new runtime.RequiredError('shop','Required parameter requestParameters.shop was null or undefined when calling uploadShopLogo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.shop !== undefined) {
            formParams.append('shop', requestParameters.shop as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/shop/logo`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change shop logo
     */
    async uploadShopLogo(requestParameters: UploadShopLogoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadShopLogoRaw(requestParameters, initOverrides);
    }

    /**
     * Change my profile-picture
     */
    async uploadUserProfilePicRaw(requestParameters: UploadUserProfilePicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling uploadUserProfilePic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/user/profile-picture`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change my profile-picture
     */
    async uploadUserProfilePic(requestParameters: UploadUserProfilePicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadUserProfilePicRaw(requestParameters, initOverrides);
    }

    /**
     * Allow user to contact the app\'s admins
     */
    async userContactRaw(requestParameters: UserContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.subject === null || requestParameters.subject === undefined) {
            throw new runtime.RequiredError('subject','Required parameter requestParameters.subject was null or undefined when calling userContact.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling userContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.email !== undefined) {
            formParams.append('email', requestParameters.email as any);
        }

        if (requestParameters.subject !== undefined) {
            formParams.append('subject', requestParameters.subject as any);
        }

        if (requestParameters.body !== undefined) {
            formParams.append('body', requestParameters.body as any);
        }

        const response = await this.request({
            path: `/contact/user-form`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Allow user to contact the app\'s admins
     */
    async userContact(requestParameters: UserContactRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userContactRaw(requestParameters, initOverrides);
    }

    /**
     * Warm up the server by accessing the slowest 3rd party services. Eg: Database
     */
    async warmupRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/static/warmup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Warm up the server by accessing the slowest 3rd party services. Eg: Database
     */
    async warmup(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.warmupRaw(initOverrides);
    }

}

/**
 * @export
 */
export const GetMaintenanceScheduleTimelineIntervalTypeEnum = {
    Miles: 'Miles',
    Kilometers: 'Kilometers',
    Months: 'Months'
} as const;
export type GetMaintenanceScheduleTimelineIntervalTypeEnum = typeof GetMaintenanceScheduleTimelineIntervalTypeEnum[keyof typeof GetMaintenanceScheduleTimelineIntervalTypeEnum];
/**
 * @export
 */
export const GetMultipleVehicleInfoFromLicensePlateRegisteredCountryEnum = {
    Usa: 'USA',
    Canada: 'Canada'
} as const;
export type GetMultipleVehicleInfoFromLicensePlateRegisteredCountryEnum = typeof GetMultipleVehicleInfoFromLicensePlateRegisteredCountryEnum[keyof typeof GetMultipleVehicleInfoFromLicensePlateRegisteredCountryEnum];
/**
 * @export
 */
export const GetMultipleVehicleInfoFromLicensePlateRegisteredStateEnum = {
    Al: 'AL',
    Ak: 'AK',
    Az: 'AZ',
    Ar: 'AR',
    Ca: 'CA',
    Co: 'CO',
    Ct: 'CT',
    De: 'DE',
    Fl: 'FL',
    Ga: 'GA',
    Hi: 'HI',
    Id: 'ID',
    Il: 'IL',
    In: 'IN',
    Ia: 'IA',
    Ks: 'KS',
    Ky: 'KY',
    La: 'LA',
    Me: 'ME',
    Md: 'MD',
    Ma: 'MA',
    Mi: 'MI',
    Mn: 'MN',
    Ms: 'MS',
    Mo: 'MO',
    Mt: 'MT',
    Ne: 'NE',
    Nv: 'NV',
    Nh: 'NH',
    Nj: 'NJ',
    Nm: 'NM',
    Ny: 'NY',
    Nc: 'NC',
    Nd: 'ND',
    Oh: 'OH',
    Ok: 'OK',
    Or: 'OR',
    Pa: 'PA',
    Ri: 'RI',
    Sc: 'SC',
    Sd: 'SD',
    Tn: 'TN',
    Tx: 'TX',
    Ut: 'UT',
    Vt: 'VT',
    Va: 'VA',
    Wa: 'WA',
    Wv: 'WV',
    Wi: 'WI',
    Wy: 'WY',
    Dc: 'DC'
} as const;
export type GetMultipleVehicleInfoFromLicensePlateRegisteredStateEnum = typeof GetMultipleVehicleInfoFromLicensePlateRegisteredStateEnum[keyof typeof GetMultipleVehicleInfoFromLicensePlateRegisteredStateEnum];
/**
 * @export
 */
export const GetUsersWithPermissionActEnum = {
    Default: 'default_',
    AssignRoles: 'assign_roles',
    UpdateShopProfile: 'update_shop_profile',
    ManageCredentials: 'manage_credentials',
    ManagePricing: 'manage_pricing',
    DeleteShop: 'delete_shop',
    IsTechnician: 'is_technician',
    AssignToRepairOrder: 'assign_to_repair_order',
    ManageCannedJobs: 'manage_canned_jobs',
    DeleteRepairOrder: 'delete_repair_order',
    ReopenRepairOrder: 'reopen_repair_order',
    ManageRepairOrder: 'manage_repair_order',
    ManageResource: 'manage_resource',
    ManageAppointment: 'manage_appointment',
    AccessAdminReports: 'access_admin_reports',
    ManageTimeClock: 'manage_time_clock',
    ManageExpenseCards: 'manage_expense_cards'
} as const;
export type GetUsersWithPermissionActEnum = typeof GetUsersWithPermissionActEnum[keyof typeof GetUsersWithPermissionActEnum];
/**
 * @export
 */
export const GetVehicleInfoFromLicensePlateRegisteredCountryEnum = {
    Usa: 'USA',
    Canada: 'Canada'
} as const;
export type GetVehicleInfoFromLicensePlateRegisteredCountryEnum = typeof GetVehicleInfoFromLicensePlateRegisteredCountryEnum[keyof typeof GetVehicleInfoFromLicensePlateRegisteredCountryEnum];
/**
 * @export
 */
export const GetVehicleInfoFromLicensePlateRegisteredStateEnum = {
    Al: 'AL',
    Ak: 'AK',
    Az: 'AZ',
    Ar: 'AR',
    Ca: 'CA',
    Co: 'CO',
    Ct: 'CT',
    De: 'DE',
    Fl: 'FL',
    Ga: 'GA',
    Hi: 'HI',
    Id: 'ID',
    Il: 'IL',
    In: 'IN',
    Ia: 'IA',
    Ks: 'KS',
    Ky: 'KY',
    La: 'LA',
    Me: 'ME',
    Md: 'MD',
    Ma: 'MA',
    Mi: 'MI',
    Mn: 'MN',
    Ms: 'MS',
    Mo: 'MO',
    Mt: 'MT',
    Ne: 'NE',
    Nv: 'NV',
    Nh: 'NH',
    Nj: 'NJ',
    Nm: 'NM',
    Ny: 'NY',
    Nc: 'NC',
    Nd: 'ND',
    Oh: 'OH',
    Ok: 'OK',
    Or: 'OR',
    Pa: 'PA',
    Ri: 'RI',
    Sc: 'SC',
    Sd: 'SD',
    Tn: 'TN',
    Tx: 'TX',
    Ut: 'UT',
    Vt: 'VT',
    Va: 'VA',
    Wa: 'WA',
    Wv: 'WV',
    Wi: 'WI',
    Wy: 'WY',
    Dc: 'DC'
} as const;
export type GetVehicleInfoFromLicensePlateRegisteredStateEnum = typeof GetVehicleInfoFromLicensePlateRegisteredStateEnum[keyof typeof GetVehicleInfoFromLicensePlateRegisteredStateEnum];
/**
 * @export
 */
export const GetVehicleInfoFromLicensePlateWithVerificationIdRegisteredCountryEnum = {
    Usa: 'USA',
    Canada: 'Canada'
} as const;
export type GetVehicleInfoFromLicensePlateWithVerificationIdRegisteredCountryEnum = typeof GetVehicleInfoFromLicensePlateWithVerificationIdRegisteredCountryEnum[keyof typeof GetVehicleInfoFromLicensePlateWithVerificationIdRegisteredCountryEnum];
/**
 * @export
 */
export const GetVehicleInfoFromLicensePlateWithVerificationIdRegisteredStateEnum = {
    Al: 'AL',
    Ak: 'AK',
    Az: 'AZ',
    Ar: 'AR',
    Ca: 'CA',
    Co: 'CO',
    Ct: 'CT',
    De: 'DE',
    Fl: 'FL',
    Ga: 'GA',
    Hi: 'HI',
    Id: 'ID',
    Il: 'IL',
    In: 'IN',
    Ia: 'IA',
    Ks: 'KS',
    Ky: 'KY',
    La: 'LA',
    Me: 'ME',
    Md: 'MD',
    Ma: 'MA',
    Mi: 'MI',
    Mn: 'MN',
    Ms: 'MS',
    Mo: 'MO',
    Mt: 'MT',
    Ne: 'NE',
    Nv: 'NV',
    Nh: 'NH',
    Nj: 'NJ',
    Nm: 'NM',
    Ny: 'NY',
    Nc: 'NC',
    Nd: 'ND',
    Oh: 'OH',
    Ok: 'OK',
    Or: 'OR',
    Pa: 'PA',
    Ri: 'RI',
    Sc: 'SC',
    Sd: 'SD',
    Tn: 'TN',
    Tx: 'TX',
    Ut: 'UT',
    Vt: 'VT',
    Va: 'VA',
    Wa: 'WA',
    Wv: 'WV',
    Wi: 'WI',
    Wy: 'WY',
    Dc: 'DC'
} as const;
export type GetVehicleInfoFromLicensePlateWithVerificationIdRegisteredStateEnum = typeof GetVehicleInfoFromLicensePlateWithVerificationIdRegisteredStateEnum[keyof typeof GetVehicleInfoFromLicensePlateWithVerificationIdRegisteredStateEnum];
/**
 * @export
 */
export const SearchVehicleByFieldRegisteredCountryEnum = {
    Usa: 'USA',
    Canada: 'Canada'
} as const;
export type SearchVehicleByFieldRegisteredCountryEnum = typeof SearchVehicleByFieldRegisteredCountryEnum[keyof typeof SearchVehicleByFieldRegisteredCountryEnum];
/**
 * @export
 */
export const SearchVehicleByFieldRegisteredStateEnum = {
    Al: 'AL',
    Ak: 'AK',
    Az: 'AZ',
    Ar: 'AR',
    Ca: 'CA',
    Co: 'CO',
    Ct: 'CT',
    De: 'DE',
    Fl: 'FL',
    Ga: 'GA',
    Hi: 'HI',
    Id: 'ID',
    Il: 'IL',
    In: 'IN',
    Ia: 'IA',
    Ks: 'KS',
    Ky: 'KY',
    La: 'LA',
    Me: 'ME',
    Md: 'MD',
    Ma: 'MA',
    Mi: 'MI',
    Mn: 'MN',
    Ms: 'MS',
    Mo: 'MO',
    Mt: 'MT',
    Ne: 'NE',
    Nv: 'NV',
    Nh: 'NH',
    Nj: 'NJ',
    Nm: 'NM',
    Ny: 'NY',
    Nc: 'NC',
    Nd: 'ND',
    Oh: 'OH',
    Ok: 'OK',
    Or: 'OR',
    Pa: 'PA',
    Ri: 'RI',
    Sc: 'SC',
    Sd: 'SD',
    Tn: 'TN',
    Tx: 'TX',
    Ut: 'UT',
    Vt: 'VT',
    Va: 'VA',
    Wa: 'WA',
    Wv: 'WV',
    Wi: 'WI',
    Wy: 'WY',
    Dc: 'DC'
} as const;
export type SearchVehicleByFieldRegisteredStateEnum = typeof SearchVehicleByFieldRegisteredStateEnum[keyof typeof SearchVehicleByFieldRegisteredStateEnum];
