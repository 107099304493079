/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentTimeConstraintsInfo
 */
export interface AppointmentTimeConstraintsInfo {
    /**
     * 
     * @type {string}
     * @memberof AppointmentTimeConstraintsInfo
     */
    shop?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof AppointmentTimeConstraintsInfo
     */
    mondayIntervals?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AppointmentTimeConstraintsInfo
     */
    tuesdayIntervals?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AppointmentTimeConstraintsInfo
     */
    wednesdayIntervals?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AppointmentTimeConstraintsInfo
     */
    thursdayIntervals?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AppointmentTimeConstraintsInfo
     */
    fridayIntervals?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AppointmentTimeConstraintsInfo
     */
    saturdayIntervals?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AppointmentTimeConstraintsInfo
     */
    sundayIntervals?: Array<number>;
}

/**
 * Check if a given object implements the AppointmentTimeConstraintsInfo interface.
 */
export function instanceOfAppointmentTimeConstraintsInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AppointmentTimeConstraintsInfoFromJSON(json: any): AppointmentTimeConstraintsInfo {
    return AppointmentTimeConstraintsInfoFromJSONTyped(json, false);
}

export function AppointmentTimeConstraintsInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppointmentTimeConstraintsInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'mondayIntervals': !exists(json, 'mondayIntervals') ? undefined : json['mondayIntervals'],
        'tuesdayIntervals': !exists(json, 'tuesdayIntervals') ? undefined : json['tuesdayIntervals'],
        'wednesdayIntervals': !exists(json, 'wednesdayIntervals') ? undefined : json['wednesdayIntervals'],
        'thursdayIntervals': !exists(json, 'thursdayIntervals') ? undefined : json['thursdayIntervals'],
        'fridayIntervals': !exists(json, 'fridayIntervals') ? undefined : json['fridayIntervals'],
        'saturdayIntervals': !exists(json, 'saturdayIntervals') ? undefined : json['saturdayIntervals'],
        'sundayIntervals': !exists(json, 'sundayIntervals') ? undefined : json['sundayIntervals'],
    };
}

export function AppointmentTimeConstraintsInfoToJSON(value?: AppointmentTimeConstraintsInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'mondayIntervals': value.mondayIntervals,
        'tuesdayIntervals': value.tuesdayIntervals,
        'wednesdayIntervals': value.wednesdayIntervals,
        'thursdayIntervals': value.thursdayIntervals,
        'fridayIntervals': value.fridayIntervals,
        'saturdayIntervals': value.saturdayIntervals,
        'sundayIntervals': value.sundayIntervals,
    };
}

