/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RepairLinkDealerEntry } from './RepairLinkDealerEntry';
import {
    RepairLinkDealerEntryFromJSON,
    RepairLinkDealerEntryFromJSONTyped,
    RepairLinkDealerEntryToJSON,
} from './RepairLinkDealerEntry';
import type { RepairLinkPartInCartEntry } from './RepairLinkPartInCartEntry';
import {
    RepairLinkPartInCartEntryFromJSON,
    RepairLinkPartInCartEntryFromJSONTyped,
    RepairLinkPartInCartEntryToJSON,
} from './RepairLinkPartInCartEntry';
import type { RepairLinkVehicleEntry } from './RepairLinkVehicleEntry';
import {
    RepairLinkVehicleEntryFromJSON,
    RepairLinkVehicleEntryFromJSONTyped,
    RepairLinkVehicleEntryToJSON,
} from './RepairLinkVehicleEntry';

/**
 * 
 * @export
 * @interface RepairLinkPartInCartExpanded
 */
export interface RepairLinkPartInCartExpanded {
    /**
     * 
     * @type {RepairLinkPartInCartEntry}
     * @memberof RepairLinkPartInCartExpanded
     */
    entry?: RepairLinkPartInCartEntry;
    /**
     * 
     * @type {RepairLinkDealerEntry}
     * @memberof RepairLinkPartInCartExpanded
     */
    dealerEntry?: RepairLinkDealerEntry;
    /**
     * 
     * @type {RepairLinkVehicleEntry}
     * @memberof RepairLinkPartInCartExpanded
     */
    vehicleEntry?: RepairLinkVehicleEntry;
}

/**
 * Check if a given object implements the RepairLinkPartInCartExpanded interface.
 */
export function instanceOfRepairLinkPartInCartExpanded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairLinkPartInCartExpandedFromJSON(json: any): RepairLinkPartInCartExpanded {
    return RepairLinkPartInCartExpandedFromJSONTyped(json, false);
}

export function RepairLinkPartInCartExpandedFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairLinkPartInCartExpanded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': !exists(json, 'entry') ? undefined : RepairLinkPartInCartEntryFromJSON(json['entry']),
        'dealerEntry': !exists(json, 'dealerEntry') ? undefined : RepairLinkDealerEntryFromJSON(json['dealerEntry']),
        'vehicleEntry': !exists(json, 'vehicleEntry') ? undefined : RepairLinkVehicleEntryFromJSON(json['vehicleEntry']),
    };
}

export function RepairLinkPartInCartExpandedToJSON(value?: RepairLinkPartInCartExpanded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': RepairLinkPartInCartEntryToJSON(value.entry),
        'dealerEntry': RepairLinkDealerEntryToJSON(value.dealerEntry),
        'vehicleEntry': RepairLinkVehicleEntryToJSON(value.vehicleEntry),
    };
}

