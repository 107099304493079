/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationInfo
 */
export interface NotificationInfo {
    /**
     * 
     * @type {string}
     * @memberof NotificationInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfo
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfo
     */
    modifiedEntity?: NotificationInfoModifiedEntityEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfo
     */
    modifiedEntityId?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfo
     */
    modifiedAction?: NotificationInfoModifiedActionEnum;
    /**
     * 
     * @type {Date}
     * @memberof NotificationInfo
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof NotificationInfo
     */
    readAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfo
     */
    sender?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfo
     */
    senderType?: NotificationInfoSenderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfo
     */
    externalSender?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInfo
     */
    twilioId?: string;
}


/**
 * @export
 */
export const NotificationInfoModifiedEntityEnum = {
    Inspection: 'Inspection',
    CustomerMessage: 'Customer_Message',
    RepairOrder: 'Repair_Order',
    RepairOrderMessage: 'Repair_Order_Message',
    IntakeRequest: 'Intake_Request',
    AppointmentScheduleRequest: 'Appointment_Schedule_Request'
} as const;
export type NotificationInfoModifiedEntityEnum = typeof NotificationInfoModifiedEntityEnum[keyof typeof NotificationInfoModifiedEntityEnum];

/**
 * @export
 */
export const NotificationInfoModifiedActionEnum = {
    Create: 'Create',
    Update: 'Update',
    Delete: 'Delete'
} as const;
export type NotificationInfoModifiedActionEnum = typeof NotificationInfoModifiedActionEnum[keyof typeof NotificationInfoModifiedActionEnum];

/**
 * @export
 */
export const NotificationInfoSenderTypeEnum = {
    Employee: 'Employee',
    Customer: 'Customer',
    External: 'External'
} as const;
export type NotificationInfoSenderTypeEnum = typeof NotificationInfoSenderTypeEnum[keyof typeof NotificationInfoSenderTypeEnum];


/**
 * Check if a given object implements the NotificationInfo interface.
 */
export function instanceOfNotificationInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationInfoFromJSON(json: any): NotificationInfo {
    return NotificationInfoFromJSONTyped(json, false);
}

export function NotificationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'modifiedEntity': !exists(json, 'modifiedEntity') ? undefined : json['modifiedEntity'],
        'modifiedEntityId': !exists(json, 'modifiedEntityId') ? undefined : json['modifiedEntityId'],
        'modifiedAction': !exists(json, 'modifiedAction') ? undefined : json['modifiedAction'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'readAt': !exists(json, 'read_at') ? undefined : (new Date(json['read_at'])),
        'sender': !exists(json, 'sender') ? undefined : json['sender'],
        'senderType': !exists(json, 'senderType') ? undefined : json['senderType'],
        'externalSender': !exists(json, 'externalSender') ? undefined : json['externalSender'],
        'twilioId': !exists(json, 'twilioId') ? undefined : json['twilioId'],
    };
}

export function NotificationInfoToJSON(value?: NotificationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'title': value.title,
        'description': value.description,
        'modifiedEntity': value.modifiedEntity,
        'modifiedEntityId': value.modifiedEntityId,
        'modifiedAction': value.modifiedAction,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'read_at': value.readAt === undefined ? undefined : (value.readAt.toISOString()),
        'sender': value.sender,
        'senderType': value.senderType,
        'externalSender': value.externalSender,
        'twilioId': value.twilioId,
    };
}

