/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderEntry } from './OrderEntry';
import {
    OrderEntryFromJSON,
    OrderEntryFromJSONTyped,
    OrderEntryToJSON,
} from './OrderEntry';

/**
 * 
 * @export
 * @interface CustomerOrdersEntry
 */
export interface CustomerOrdersEntry {
    /**
     * 
     * @type {OrderEntry}
     * @memberof CustomerOrdersEntry
     */
    entry?: OrderEntry;
    /**
     * 
     * @type {string}
     * @memberof CustomerOrdersEntry
     */
    vehicleName?: string;
}

/**
 * Check if a given object implements the CustomerOrdersEntry interface.
 */
export function instanceOfCustomerOrdersEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerOrdersEntryFromJSON(json: any): CustomerOrdersEntry {
    return CustomerOrdersEntryFromJSONTyped(json, false);
}

export function CustomerOrdersEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerOrdersEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': !exists(json, 'entry') ? undefined : OrderEntryFromJSON(json['entry']),
        'vehicleName': !exists(json, 'vehicleName') ? undefined : json['vehicleName'],
    };
}

export function CustomerOrdersEntryToJSON(value?: CustomerOrdersEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': OrderEntryToJSON(value.entry),
        'vehicleName': value.vehicleName,
    };
}

