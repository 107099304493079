/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerInformation } from './CustomerInformation';
import {
    CustomerInformationFromJSON,
    CustomerInformationFromJSONTyped,
    CustomerInformationToJSON,
} from './CustomerInformation';

/**
 * 
 * @export
 * @interface NewCustomerRequest
 */
export interface NewCustomerRequest {
    /**
     * 
     * @type {CustomerInformation}
     * @memberof NewCustomerRequest
     */
    customerInformation?: CustomerInformation;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewCustomerRequest
     */
    vehicles?: Array<string>;
}

/**
 * Check if a given object implements the NewCustomerRequest interface.
 */
export function instanceOfNewCustomerRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NewCustomerRequestFromJSON(json: any): NewCustomerRequest {
    return NewCustomerRequestFromJSONTyped(json, false);
}

export function NewCustomerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewCustomerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerInformation': !exists(json, 'customerInformation') ? undefined : CustomerInformationFromJSON(json['customerInformation']),
        'vehicles': !exists(json, 'vehicles') ? undefined : json['vehicles'],
    };
}

export function NewCustomerRequestToJSON(value?: NewCustomerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerInformation': CustomerInformationToJSON(value.customerInformation),
        'vehicles': value.vehicles,
    };
}

