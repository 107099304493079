/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepairOrderAttachmentInfo
 */
export interface RepairOrderAttachmentInfo {
    /**
     * 
     * @type {string}
     * @memberof RepairOrderAttachmentInfo
     */
    repairOrder?: string;
    /**
     * 
     * @type {Date}
     * @memberof RepairOrderAttachmentInfo
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderAttachmentInfo
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof RepairOrderAttachmentInfo
     */
    modifiedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderAttachmentInfo
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof RepairOrderAttachmentInfo
     */
    archivedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof RepairOrderAttachmentInfo
     */
    archivedBy?: string;
}

/**
 * Check if a given object implements the RepairOrderAttachmentInfo interface.
 */
export function instanceOfRepairOrderAttachmentInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairOrderAttachmentInfoFromJSON(json: any): RepairOrderAttachmentInfo {
    return RepairOrderAttachmentInfoFromJSONTyped(json, false);
}

export function RepairOrderAttachmentInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairOrderAttachmentInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'repairOrder': !exists(json, 'repair_order') ? undefined : json['repair_order'],
        'createdAt': !exists(json, 'created_at') ? undefined : (new Date(json['created_at'])),
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'modifiedAt': !exists(json, 'modified_at') ? undefined : (new Date(json['modified_at'])),
        'modifiedBy': !exists(json, 'modified_by') ? undefined : json['modified_by'],
        'archivedAt': !exists(json, 'archived_at') ? undefined : (new Date(json['archived_at'])),
        'archivedBy': !exists(json, 'archived_by') ? undefined : json['archived_by'],
    };
}

export function RepairOrderAttachmentInfoToJSON(value?: RepairOrderAttachmentInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'repair_order': value.repairOrder,
        'created_at': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'created_by': value.createdBy,
        'modified_at': value.modifiedAt === undefined ? undefined : (value.modifiedAt.toISOString()),
        'modified_by': value.modifiedBy,
        'archived_at': value.archivedAt === undefined ? undefined : (value.archivedAt.toISOString()),
        'archived_by': value.archivedBy,
    };
}

