/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PartTaxonomy } from './PartTaxonomy';
import {
    PartTaxonomyFromJSON,
    PartTaxonomyFromJSONTyped,
    PartTaxonomyToJSON,
} from './PartTaxonomy';

/**
 * 
 * @export
 * @interface PartsMatrixInfo
 */
export interface PartsMatrixInfo {
    /**
     * 
     * @type {string}
     * @memberof PartsMatrixInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof PartsMatrixInfo
     */
    priceLevel?: string;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PartsMatrixInfo
     */
    thresholdToMarginPercentage?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PartsMatrixInfo
     */
    thresholdToMarginFlatRate?: { [key: string]: number; };
    /**
     * 
     * @type {string}
     * @memberof PartsMatrixInfo
     */
    markupUsage?: PartsMatrixInfoMarkupUsageEnum;
    /**
     * 
     * @type {PartTaxonomy}
     * @memberof PartsMatrixInfo
     */
    partTaxonomy?: PartTaxonomy;
    /**
     * 
     * @type {string}
     * @memberof PartsMatrixInfo
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof PartsMatrixInfo
     */
    brandDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartsMatrixInfo
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof PartsMatrixInfo
     */
    archived?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PartsMatrixInfo
     */
    isDefault?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PartsMatrixInfo
     */
    rank?: number;
}


/**
 * @export
 */
export const PartsMatrixInfoMarkupUsageEnum = {
    UseLesser: 'use_lesser',
    UseGreater: 'use_greater',
    IgnoreFlatRate: 'ignore_flat_rate',
    IgnorePercentage: 'ignore_percentage',
    UseMsrp: 'use_msrp'
} as const;
export type PartsMatrixInfoMarkupUsageEnum = typeof PartsMatrixInfoMarkupUsageEnum[keyof typeof PartsMatrixInfoMarkupUsageEnum];


/**
 * Check if a given object implements the PartsMatrixInfo interface.
 */
export function instanceOfPartsMatrixInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PartsMatrixInfoFromJSON(json: any): PartsMatrixInfo {
    return PartsMatrixInfoFromJSONTyped(json, false);
}

export function PartsMatrixInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartsMatrixInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'priceLevel': !exists(json, 'priceLevel') ? undefined : json['priceLevel'],
        'thresholdToMarginPercentage': !exists(json, 'thresholdToMarginPercentage') ? undefined : json['thresholdToMarginPercentage'],
        'thresholdToMarginFlatRate': !exists(json, 'thresholdToMarginFlatRate') ? undefined : json['thresholdToMarginFlatRate'],
        'markupUsage': !exists(json, 'markupUsage') ? undefined : json['markupUsage'],
        'partTaxonomy': !exists(json, 'partTaxonomy') ? undefined : PartTaxonomyFromJSON(json['partTaxonomy']),
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'brandDisplayName': !exists(json, 'brandDisplayName') ? undefined : json['brandDisplayName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'archived': !exists(json, 'archived') ? undefined : (new Date(json['archived'])),
        'isDefault': !exists(json, 'isDefault') ? undefined : json['isDefault'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
    };
}

export function PartsMatrixInfoToJSON(value?: PartsMatrixInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'priceLevel': value.priceLevel,
        'thresholdToMarginPercentage': value.thresholdToMarginPercentage,
        'thresholdToMarginFlatRate': value.thresholdToMarginFlatRate,
        'markupUsage': value.markupUsage,
        'partTaxonomy': PartTaxonomyToJSON(value.partTaxonomy),
        'brand': value.brand,
        'brandDisplayName': value.brandDisplayName,
        'description': value.description,
        'archived': value.archived === undefined ? undefined : (value.archived.toISOString()),
        'isDefault': value.isDefault,
        'rank': value.rank,
    };
}

