/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerificationInfo
 */
export interface VerificationInfo {
    /**
     * 
     * @type {string}
     * @memberof VerificationInfo
     */
    verificationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationInfo
     */
    shopId?: string;
    /**
     * 
     * @type {string}
     * @memberof VerificationInfo
     */
    customerId?: string;
    /**
     * 
     * @type {Date}
     * @memberof VerificationInfo
     */
    expiry?: Date;
}

/**
 * Check if a given object implements the VerificationInfo interface.
 */
export function instanceOfVerificationInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VerificationInfoFromJSON(json: any): VerificationInfo {
    return VerificationInfoFromJSONTyped(json, false);
}

export function VerificationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerificationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'verificationCode': !exists(json, 'verificationCode') ? undefined : json['verificationCode'],
        'shopId': !exists(json, 'shopId') ? undefined : json['shopId'],
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'expiry': !exists(json, 'expiry') ? undefined : (new Date(json['expiry'])),
    };
}

export function VerificationInfoToJSON(value?: VerificationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'verificationCode': value.verificationCode,
        'shopId': value.shopId,
        'customerId': value.customerId,
        'expiry': value.expiry === undefined ? undefined : (value.expiry.toISOString()),
    };
}

