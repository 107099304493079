/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChatRequestSetting } from './ChatRequestSetting';
import {
    ChatRequestSettingFromJSON,
    ChatRequestSettingFromJSONTyped,
    ChatRequestSettingToJSON,
} from './ChatRequestSetting';
import type { CustomerEntry } from './CustomerEntry';
import {
    CustomerEntryFromJSON,
    CustomerEntryFromJSONTyped,
    CustomerEntryToJSON,
} from './CustomerEntry';
import type { MessageContent } from './MessageContent';
import {
    MessageContentFromJSON,
    MessageContentFromJSONTyped,
    MessageContentToJSON,
} from './MessageContent';
import type { ShopEntry } from './ShopEntry';
import {
    ShopEntryFromJSON,
    ShopEntryFromJSONTyped,
    ShopEntryToJSON,
} from './ShopEntry';

/**
 * 
 * @export
 * @interface ReplyCustomerRequest
 */
export interface ReplyCustomerRequest {
    /**
     * 
     * @type {ChatRequestSetting}
     * @memberof ReplyCustomerRequest
     */
    chatRequestSetting?: ChatRequestSetting;
    /**
     * 
     * @type {string}
     * @memberof ReplyCustomerRequest
     */
    customPrompt?: string;
    /**
     * 
     * @type {Array<MessageContent>}
     * @memberof ReplyCustomerRequest
     */
    messages?: Array<MessageContent>;
    /**
     * 
     * @type {ShopEntry}
     * @memberof ReplyCustomerRequest
     */
    shopEntry?: ShopEntry;
    /**
     * 
     * @type {CustomerEntry}
     * @memberof ReplyCustomerRequest
     */
    customerEntry?: CustomerEntry;
}

/**
 * Check if a given object implements the ReplyCustomerRequest interface.
 */
export function instanceOfReplyCustomerRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReplyCustomerRequestFromJSON(json: any): ReplyCustomerRequest {
    return ReplyCustomerRequestFromJSONTyped(json, false);
}

export function ReplyCustomerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReplyCustomerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chatRequestSetting': !exists(json, 'chatRequestSetting') ? undefined : ChatRequestSettingFromJSON(json['chatRequestSetting']),
        'customPrompt': !exists(json, 'customPrompt') ? undefined : json['customPrompt'],
        'messages': !exists(json, 'messages') ? undefined : ((json['messages'] as Array<any>).map(MessageContentFromJSON)),
        'shopEntry': !exists(json, 'shopEntry') ? undefined : ShopEntryFromJSON(json['shopEntry']),
        'customerEntry': !exists(json, 'customerEntry') ? undefined : CustomerEntryFromJSON(json['customerEntry']),
    };
}

export function ReplyCustomerRequestToJSON(value?: ReplyCustomerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chatRequestSetting': ChatRequestSettingToJSON(value.chatRequestSetting),
        'customPrompt': value.customPrompt,
        'messages': value.messages === undefined ? undefined : ((value.messages as Array<any>).map(MessageContentToJSON)),
        'shopEntry': ShopEntryToJSON(value.shopEntry),
        'customerEntry': CustomerEntryToJSON(value.customerEntry),
    };
}

