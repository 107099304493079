/* eslint-disable max-lines */
import { createApi } from '@reduxjs/toolkit/query/react'

import { sdk } from '../../config'
import { LocalDateTimeArray } from '../../models/SerializableSdkTypes/LocalDateTimeArray'
import { SerializableAppointmentEntry } from '../../models/SerializableSdkTypes/SerializableAppointmentEntry'
import {
  AddInventoryToServiceOperationRequest,
  AddPartsToServiceOperationRequest,
  AppointmentSettingsEntry,
  ArchiveAppointmentRequest,
  ArchiveCounterSaleRequest,
  ArchiveRepairOrderAttachmentRequest,
  ArchiveResourceRequest,
  ArchiveServiceRequest,
  ArchiveShopVendorRequest,
  ArchiveVehicleImageOperationRequest,
  AssignTechsToLaborOverrideRequest,
  AssignTechsToServiceRequest,
  AuthorizationEntry,
  BulkUpdateRepairOrderStatusOperationRequest,
  CheckRepairLinkPricesRequest,
  CheckShopOnboardedRequest,
  ClaimInventoryOperationRequest,
  CollectPaymentCardNotPresentRequest,
  CollectPaymentCardPresentRequest,
  ConvertQuotedPartRequest,
  CopyPartsTechPartsToServiceOperationRequest,
  CounterSaleEntry,
  CounterSaleExpanded,
  CreateAdditionalEmailAddressRequest,
  CreateAdditionalPhoneRequest,
  CreateAppointmentOperationRequest,
  CreateAppointmentWithVerificationIdRequest,
  CreateAuthorizationRequest,
  CreateCounterSaleRequest,
  CreateCustomerRequest,
  CreateCustomFeeRequest,
  CreateCustomMessageTemplateRequest,
  CreateInventoryAdjustmentDocumentRequest,
  CreateInventoryInstanceRequest,
  CreateInventoryInstancesWithAdjustmentOperationRequest,
  CreateInventoryInstancesWithAdjustmentResponse,
  CreateInventoryPartRequest,
  CreateLaborOverridesOperationRequest,
  CreatePartOverrideRequest,
  CreatePartStatusRequest,
  CreatePaymentRequest,
  CreateRepairOrderAttachmentEntryRequest,
  CreateRepairOrderMediaEntryRequest,
  CreateRepairOrderRequest,
  CreateRepairOrderStatusRequest,
  CreateResourceRequest,
  CreateRoleRequest,
  CreateServiceStandaloneRequest,
  CreateShopVendorRequest,
  CreateSubletRequest,
  CreateVehicleImageRequest,
  CreateVehicleRequest,
  CreateVehicleWithVerificationIdRequest,
  CreateWarrantyEntryRequest,
  CreditCardReader,
  CustomerAdditionalEmailAddressEntry,
  CustomerAdditionalPhoneEntry,
  CustomerEntry,
  DeleteAdditionalEmailAddressRequest,
  DeleteAdditionalPhoneRequest,
  DeleteCustomFeeRequest,
  DeleteCustomMessageTemplateRequest,
  DeleteInventoryInstancesRequest,
  DeleteLaborOverrideRequest,
  DeletePartOverrideRequest,
  DeleteRoleRequest,
  DeleteShopRequest,
  DeleteSubletRequest,
  DeleteWarrantyEntryRequest,
  EditCartRefreshPricesRequest,
  EmailInvoicePdfRequest,
  EmployeeRole,
  GetAdditionalEmailAddressesForCustomerRequest,
  GetAdditionalPhonesForCustomerRequest,
  GetAppointmentAvailabilityRequest,
  GetAppointmentAvailabilityWithVerificationIdRequest,
  GetAppointmentRequest,
  GetAppointmentSettingsByShopRequest,
  GetAppointmentSettingsByShopWithVerificationIdRequest,
  GetAppointmentsForCustomerRequest,
  GetAppointmentsForVehicleRequest,
  GetAppointmentTimeConstraintsByShopRequest,
  GetCoreInventoryInstancesRequest,
  GetCustomerInfoRequest,
  GetCustomersForVehicleRequest,
  GetCustomerVehiclesRequest,
  GetCustomerVehiclesWithVerificationIdRequest,
  GetCustomMessageTemplatesForShopRequest,
  GetEmployeeRolesRequest,
  GetInventoryAdjustmentsRequest,
  GetInventoryInstanceExpandedRequest,
  GetInventoryInstancesRequest,
  GetInventoryLocationsRequest,
  GetInventoryNonCoreReturnsRequest,
  GetInventoryPartIdsByPartNumberRequest,
  GetInventoryPartsByIdRequest,
  GetInventoryPartsByPartNumberAndBrandRequest,
  GetInventoryPartsRequest,
  GetKpiRequest,
  GetLatestAppointmentsAtShopRequest,
  GetOtherPeopleProfilesRequest,
  GetRepairLinkAccessTokenRequest,
  GetRepairOrderAttachmentEntriesRequest,
  GetRepairOrderMediaEntriesByApiKeyRequest,
  GetRepairOrderMediaEntriesRequest,
  GetRepairOrderServicesRequest,
  GetRolePermissionsRequest,
  GetRolesRequest,
  GetServicesRequest,
  GetShopLogoForDymoRequest,
  GetShopProfileRequest,
  GetUsersWithPermissionRequest,
  GetVehicleImagesRequest,
  GetVehicleInfoRequest,
  GetVendorEntriesByIdRequest,
  GetVendorEntriesByShopIdRequest,
  HandleAppointmentScheduleRequestResponseRequest,
  InventoryAdjustmentEntryExpanded,
  InventoryPartEntry,
  InventoryPartInstanceExpanded,
  InviteUserRequest,
  Kpi,
  LinkCustomerToVehicleRequest,
  ListAuthorizationsRequest,
  ListCounterSalesExpandedRequest,
  ListCounterSalesRequest,
  ListCreditCardReadersRequest,
  ListCustomersRequest,
  ListFeaturesForShopRequest,
  ListPartStatusForRepairOrderRequest,
  ListPaymentsForCustomerRequest,
  ListPaymentsForRepairOrderRequest,
  ListRepairOrdersRequest,
  ListRepairOrderStatusesForShopRequest,
  ListRepairOrdersWithRelatedRequest,
  ListShopResourcesRequest,
  LookupRepairOrdersForPartOverridesRequest,
  MergeCustomersOperationRequest,
  MergeVehiclesOperationRequest,
  MessageTemplateEntry,
  OrderCart1Request,
  OrderCartRequest,
  PartOverride,
  PartStatusEntry,
  PunchoutInfo,
  ReleaseInventoryClaimsRequest,
  RepairLinkPartInCartExpandedChecked,
  RepairOrderAttachmentEntry,
  RepairOrderEntry,
  RepairOrderExpanded,
  RepairOrderMediaEntry,
  RepairOrderStatusEntry,
  RepairOrderWithRelated,
  RepairPaymentEntry,
  ResourceEntry,
  RevokeRoleRequest,
  Role,
  ServiceEntry,
  ServiceExpanded,
  SetPartsTechCredentialsRequest,
  SetRepairLinkAccessTokenRequest,
  SetRoleRequest,
  ShopEntry,
  ShopFeatureEntry,
  TriggerCodatFunctionRequest,
  UnlinkCustomerFromVehicleRequest,
  UnlinkInventoryRequest,
  UpdateAdditionalEmailAddressRequest,
  UpdateAdditionalPhoneRequest,
  UpdateAppointmentRequest,
  UpdateAppointmentSettingsRequest,
  UpdateAppointmentTimeConstraintsRequest,
  UpdateCounterSaleRequest,
  UpdateCustomerRequest,
  UpdateCustomFeeRequest,
  UpdateCustomMessageTemplateRequest,
  UpdateInventoryInstanceRequest,
  UpdateInventoryInstancesWithAdjustmentRequest,
  UpdateInventoryPartRequest,
  UpdateLaborOverrideByIdRequest,
  UpdateMileageInRequest,
  UpdateMileageOutRequest,
  UpdatePartOverrideRequest,
  UpdatePartStatusRequest,
  UpdateQuantityOperationRequest,
  UpdateRepairLinkPricesRequest,
  UpdateRepairOrderAttachmentInfoRequest,
  UpdateRepairOrderMediaInfoRequest,
  UpdateRepairOrderRequest,
  UpdateRepairOrderStatusRanksRequest,
  UpdateRepairOrderStatusRequest,
  UpdateRepairPayment1Request,
  UpdateRepairPaymentRequest,
  UpdateResourceRequest,
  UpdateRolePermissionsOperationRequest,
  UpdateRoleRequest,
  UpdateServiceRequest,
  UpdateShopProfileRequest,
  UpdateShopVendorRequest,
  UpdateSubletRequest,
  UpdateTeamMemberPasswordRequest,
  UpdateTeamMemberProfileRequest,
  UpdateVehicleInfoRequest,
  UpdateWarrantyEntryRequest,
  UploadShopLogoRequest,
  UserProfile,
  VehicleEntry,
  VehicleImageEntry,
  VendorEntry
} from '../../sdk'
import { InventoryPartInstanceEntry } from '../../sdk/models/InventoryPartInstanceEntry'
import { OrderCartResponse } from '../../sdk/models/OrderCartResponse'
import { ShopInventory } from '../../sdk/models/ShopInventory'
import { defaultTimeZoneDateToUtcDate, stripQuotes } from '../../utils'
import { decodeRawAppointmentEntryResponse } from '../rawResponseDecoders'
import {
  transformDate,
  transformServiceExpandedArray
} from '../responseTransformers'
import {
  generateRawSdkQueryFn,
  sdkBaseQuery,
  sdkQueryNoToast
} from '../sdkBaseQuery'

// appointment
const getAppointmentRaw = sdk.getAppointmentRaw.bind(sdk)
const getLatestAppointmentsAtShopRaw =
  sdk.getLatestAppointmentsAtShopRaw.bind(sdk)
const getAppointmentsForCustomerRaw =
  sdk.getAppointmentsForCustomerRaw.bind(sdk)
const getAppointmentsForVehicleRaw = sdk.getAppointmentsForVehicleRaw.bind(sdk)
const updateAppointment = sdk.updateAppointment.bind(sdk)
const createAppointment = sdk.createAppointment.bind(sdk)
const createAppointmentWithVerificationId =
  sdk.createAppointmentWithVerificationId.bind(sdk)
const archiveAppointment = sdk.archiveAppointment.bind(sdk)

const getAppointmentSettingsByShop = sdk.getAppointmentSettingsByShop.bind(sdk)
const getAppointmentSettingsByShopWithVerificationId =
  sdk.getAppointmentSettingsByShopWithVerificationId.bind(sdk)
const updateAppointmentSettings = sdk.updateAppointmentSettings.bind(sdk)
const getAppointmentTimeConstraintsByShop =
  sdk.getAppointmentTimeConstraintsByShop.bind(sdk)
const updateAppointmentTimeConstraints =
  sdk.updateAppointmentTimeConstraints.bind(sdk)

const getAppointmentAvailability = sdk.getAppointmentAvailability.bind(sdk)
const getAppointmentAvailabilityWithVerificationId =
  sdk.getAppointmentAvailabilityWithVerificationId.bind(sdk)

const handleAppointmentScheduleRequestResponse =
  sdk.handleAppointmentScheduleRequestResponse.bind(sdk)

// customer
const getCustomerInfo = sdk.getCustomerInfo.bind(sdk)
const getCustomerVehicles = sdk.getCustomerVehicles.bind(sdk)
const getCustomerVehiclesWithVerificationId =
  sdk.getCustomerVehiclesWithVerificationId.bind(sdk)
const updateCustomer = sdk.updateCustomer.bind(sdk)
const linkCustomerToVehicle = sdk.linkCustomerToVehicle.bind(sdk)
const unlinkCustomerFromVehicle = sdk.unlinkCustomerFromVehicle.bind(sdk)
const createCustomer = sdk.createCustomer.bind(sdk)
const listCustomers = sdk.listCustomers.bind(sdk)
const getAdditionalPhonesForCustomer =
  sdk.getAdditionalPhonesForCustomer.bind(sdk)
const updateAdditionalPhone = sdk.updateAdditionalPhone.bind(sdk)
const createAdditionalPhone = sdk.createAdditionalPhone.bind(sdk)
const deleteAdditionalPhone = sdk.deleteAdditionalPhone.bind(sdk)
const getAdditionalEmailAddressesForCustomer =
  sdk.getAdditionalEmailAddressesForCustomer.bind(sdk)
const updateAdditionalEmailAddress = sdk.updateAdditionalEmailAddress.bind(sdk)
const createAdditionalEmailAddress = sdk.createAdditionalEmailAddress.bind(sdk)
const deleteAdditionalEmailAddress = sdk.deleteAdditionalEmailAddress.bind(sdk)
const mergeCustomers = sdk.mergeCustomers.bind(sdk)

// credit card readers
const listCreditCardReaders = sdk.listCreditCardReaders.bind(sdk)

// repair order
const listRepairOrders = sdk.listRepairOrders.bind(sdk)
const listRepairOrdersWithRelated = sdk.listRepairOrdersWithRelated.bind(sdk)
const updateRepairOrder = sdk.updateRepairOrder.bind(sdk)
const createRepairOrder = sdk.createRepairOrder.bind(sdk)
const getServices = sdk.getServices.bind(sdk)
const getRepairOrderServices = sdk.getRepairOrderServices.bind(sdk)
const createService = sdk.createServiceStandalone.bind(sdk)
const emailInvoicePdf = sdk.emailInvoicePdf.bind(sdk)
const bulkUpdateRepairOrderStatus = sdk.bulkUpdateRepairOrderStatus.bind(sdk)
const updateMileageIn = sdk.updateMileageIn.bind(sdk)
const updateMileageOut = sdk.updateMileageOut.bind(sdk)
const lookupRepairOrdersForPartOverrides =
  sdk.lookupRepairOrdersForPartOverrides.bind(sdk)
const triggerCodatFunction = sdk.triggerCodatFunction.bind(sdk)

// repair order payment
const createPayment = sdk.createPayment.bind(sdk)
const collectPaymentCardPresent = sdk.collectPaymentCardPresent.bind(sdk)
const collectPaymentCardNotPresent = sdk.collectPaymentCardNotPresent.bind(sdk)
const updateRepairPayment = sdk.updateRepairPayment.bind(sdk)
const updateRepairPaymentType = sdk.updateRepairPayment1.bind(sdk)
const listPaymentsForRepairOrder = sdk.listPaymentsForRepairOrder.bind(sdk)
const listPartStatusForRepairOrder = sdk.listPartStatusForRepairOrder.bind(sdk)
const listPaymentsForCustomer = sdk.listPaymentsForCustomer.bind(sdk)

// service
const createPartOverride = sdk.createPartOverride.bind(sdk)
const updatePartOverride = sdk.updatePartOverride.bind(sdk)
const deletePartOverride = sdk.deletePartOverride.bind(sdk)
const updateService = sdk.updateService.bind(sdk)
const archiveService = sdk.archiveService.bind(sdk)
const createLaborOverrides = sdk.createLaborOverrides.bind(sdk)
const updateLaborOverride = sdk.updateLaborOverrideById.bind(sdk)
const deleteLaborOverride = sdk.deleteLaborOverride.bind(sdk)
const updateQuantity = sdk.updateQuantity.bind(sdk)
const orderCart = sdk.orderCart.bind(sdk)
const createCustomFee = sdk.createCustomFee.bind(sdk)
const updateCustomFee = sdk.updateCustomFee.bind(sdk)
const deleteCustomFee = sdk.deleteCustomFee.bind(sdk)
const createSublet = sdk.createSublet.bind(sdk)
const updateSublet = sdk.updateSublet.bind(sdk)
const deleteSublet = sdk.deleteSublet.bind(sdk)
const assignTechsToService = sdk.assignTechsToService.bind(sdk)
const assignTechsToLaborOverride = sdk.assignTechsToLaborOverride.bind(sdk)
const editCartRefreshPrices = sdk.editCartRefreshPrices.bind(sdk)
const copyPartsTechPartsToService = sdk.copyPartsTechPartsToService.bind(sdk)
const convertQuotedPart = sdk.convertQuotedPart.bind(sdk)
const addPartsToService = sdk.addPartsToService.bind(sdk)
const orderCart1 = sdk.orderCart1.bind(sdk)
const checkRepairLinkPrices = sdk.checkRepairLinkPrices.bind(sdk)
const updateRepairLinkPrices = sdk.updateRepairLinkPrices.bind(sdk)

// shop profile
const getShopProfile = sdk.getShopProfile.bind(sdk)
const updateShopProfile = sdk.updateShopProfile.bind(sdk)
const setPartsTechCredentials = sdk.setPartsTechCredentials.bind(sdk)
const deleteShop = sdk.deleteShop.bind(sdk)
const listFeaturesForShop = sdk.listFeaturesForShop.bind(sdk)
const setRepairLinkAccessToken = sdk.setRepairLinkAccessToken.bind(sdk)
const getRepairLinkAccessToken = sdk.getRepairLinkAccessToken.bind(sdk)
const getShopLogoForDymo = sdk.getShopLogoForDymo.bind(sdk)

// shop roles
const getRoles = sdk.getRoles.bind(sdk)
const createRole = sdk.createRole.bind(sdk)
const updateRole = sdk.updateRole.bind(sdk)
const deleteRole = sdk.deleteRole.bind(sdk)
const getRolePermissions = sdk.getRolePermissions.bind(sdk)
const updateRolePermissions = sdk.updateRolePermissions.bind(sdk)
const getEmployeeRoles = sdk.getEmployeeRoles.bind(sdk)
const setRole = sdk.setRole.bind(sdk)
const revokeRole = sdk.revokeRole.bind(sdk)
const inviteUser = sdk.inviteUser.bind(sdk)
const updateTeamMemberProfile = sdk.updateTeamMemberProfile.bind(sdk)
const updateTeamMemberPassword = sdk.updateTeamMemberPassword.bind(sdk)

// shop resources
const listShopResources = sdk.listShopResources.bind(sdk)
const createResource = sdk.createResource.bind(sdk)
const updateResource = sdk.updateResource.bind(sdk)
const archiveResource = sdk.archiveResource.bind(sdk)

// shop vendors
const createShopVendor = sdk.createShopVendor.bind(sdk)
const getVendorEntriesById = sdk.getVendorEntriesById.bind(sdk)
const getVendorEntriesByShopId = sdk.getVendorEntriesByShopId.bind(sdk)
const updateShopVendor = sdk.updateShopVendor.bind(sdk)
const archiveShopVendor = sdk.archiveShopVendor.bind(sdk)

const createRepairOrderStatus = sdk.createRepairOrderStatus.bind(sdk)
const updateRepairOrderStatus = sdk.updateRepairOrderStatus.bind(sdk)
const listRepairOrderStatusesForShop =
  sdk.listRepairOrderStatusesForShop.bind(sdk)
const updateRepairOrderStatusRanks = sdk.updateRepairOrderStatusRanks.bind(sdk)

const getUsersWithPermission = sdk.getUsersWithPermission.bind(sdk)
const getOtherPeopleProfiles = sdk.getOtherPeopleProfiles.bind(sdk)

const getVehicleInfo = sdk.getVehicleInfo.bind(sdk)
const updateVehicleInfo = sdk.updateVehicleInfo.bind(sdk)
const mergeVehicle = sdk.mergeVehicles.bind(sdk)
const getCustomersForVehicle = sdk.getCustomersForVehicle.bind(sdk)
const createVehicle = sdk.createVehicle.bind(sdk)
const createVehicleWithVerificationId =
  sdk.createVehicleWithVerificationId.bind(sdk)
const createVehicleImage = sdk.createVehicleImage.bind(sdk)
const getVehicleImages = sdk.getVehicleImages.bind(sdk)
const archiveVehicleImage = sdk.archiveVehicleImage.bind(sdk)

const createAuthorization = sdk.createAuthorization.bind(sdk)
const listAuthorizations = sdk.listAuthorizations.bind(sdk)

const createPartStatus = sdk.createPartStatus.bind(sdk)
const updatePartStatus = sdk.updatePartStatus.bind(sdk)

const getAllInventoryParts = sdk.getInventoryParts.bind(sdk)
const getInventoryPartsById = sdk.getInventoryPartsById.bind(sdk)
const getInventoryPartsByPartNumberAndBrand =
  sdk.getInventoryPartsByPartNumberAndBrand.bind(sdk)
const createInventoryPart = sdk.createInventoryPart.bind(sdk)
const updateInventoryPart = sdk.updateInventoryPart.bind(sdk)
const getInventoryInstances = sdk.getInventoryInstances.bind(sdk)
const getInventoryInstanceExpanded = sdk.getInventoryInstanceExpanded.bind(sdk)
const createInventoryInstance = sdk.createInventoryInstance.bind(sdk)
const updateInventoryInstance = sdk.updateInventoryInstance.bind(sdk)
const deleteInventoryInstances = sdk.deleteInventoryInstances.bind(sdk)
const claimInventory = sdk.claimInventory.bind(sdk)
const linkInventory = sdk.linkInventory.bind(sdk)
const getInventoryPartIdsByPartNumber =
  sdk.getInventoryPartIdsByPartNumber.bind(sdk)
const unlinkInventory = sdk.unlinkInventory.bind(sdk)
const releaseInventoryClaims = sdk.releaseInventoryClaims.bind(sdk)
const addInventoryToService = sdk.addInventoryToService.bind(sdk)
const getCoreInventoryInstances = sdk.getCoreInventoryInstances.bind(sdk)
const getInventoryPendingReturn = sdk.getInventoryNonCoreReturns.bind(sdk)
const getInventoryAdjustments = sdk.getInventoryAdjustments.bind(sdk)
const createInventoryAdjustmentDocument =
  sdk.createInventoryAdjustmentDocument.bind(sdk)
const createInventoryInstancesWithAdjustment =
  sdk.createInventoryInstancesWithAdjustment.bind(sdk)
const updateInventoryInstancesWithAdjustment =
  sdk.updateInventoryInstancesWithAdjustment.bind(sdk)
const getInventoryLocations = sdk.getInventoryLocations.bind(sdk)

const getCustomMessageTemplatesForShop =
  sdk.getCustomMessageTemplatesForShop.bind(sdk)
const createCustomMessageTemplate = sdk.createCustomMessageTemplate.bind(sdk)
const updateCustomMessageTemplate = sdk.updateCustomMessageTemplate.bind(sdk)
const deleteCustomMessageTemplate = sdk.deleteCustomMessageTemplate.bind(sdk)

const uploadShopLogo = sdk.uploadShopLogo.bind(sdk)

// finix
const checkShopOnboarded = sdk.checkShopOnboarded.bind(sdk)

// counter sales
const listCounterSalesExpanded = sdk.listCounterSalesExpanded.bind(sdk)
const listCounterSales = sdk.listCounterSales.bind(sdk)
const createCounterSale = sdk.createCounterSale.bind(sdk)
const updateCounterSale = sdk.updateCounterSale.bind(sdk)
const archiveCounterSale = sdk.archiveCounterSale.bind(sdk)

const createWarrantyEntry = sdk.createWarrantyEntry.bind(sdk)
const updateWarrantyEntry = sdk.updateWarrantyEntry.bind(sdk)
const deleteWarrantyEntry = sdk.deleteWarrantyEntry.bind(sdk)

// repair order media
const createRepairOrderMediaEntry = sdk.createRepairOrderMediaEntry.bind(sdk)
const updateRepairOrderMediaInfo = sdk.updateRepairOrderMediaInfo.bind(sdk)
const getRepairOrderMediaEntries = sdk.getRepairOrderMediaEntries.bind(sdk)
const getRepairOrderMediaEntriesByApiKey =
  sdk.getRepairOrderMediaEntriesByApiKey.bind(sdk)

// repair order attachment
const createRepairOrderAttachmentEntry =
  sdk.createRepairOrderAttachmentEntry.bind(sdk)
const getRepairOrderAttachmentEntries =
  sdk.getRepairOrderAttachmentEntries.bind(sdk)
const updateRepairOrderAttachmentInfo =
  sdk.updateRepairOrderAttachmentInfo.bind(sdk)
const archiveRepairOrderAttachment = sdk.archiveRepairOrderAttachment.bind(sdk)
const getKpi = sdk.getKpi.bind(sdk)

export const api = createApi({
  reducerPath: 'api',
  baseQuery: sdkBaseQuery,
  tagTypes: [
    'LatestAppointment',
    'Appointment',
    'AppointmentsOfCustomer',
    'AppointmentsOfVehicle',
    'Authorization',
    'Customer',
    'CustomersOfVehicle',
    'Repair Order',
    'Service',
    'Resource',
    'Vendor',
    'Price Level',
    'Parts Matrix',
    'Labor Matrix',
    'Status',
    'Vehicle',
    'VehiclesOfCustomer',
    'Payment',
    'User',
    'Part Status',
    'CannedJob',
    'CannedJobExpanded',
    'TaxonomyForVehicle',
    'Taxonomy',
    'FluidTaxonomyForVehicle',
    'FluidTaxonomy',
    'Shop',
    'Team',
    'ShopRole',
    'TaxesAndFees',
    'InventoryPart',
    'InventoryInstance',
    'Inspection',
    'InspectionExpanded',
    'AdditionalPhone',
    'AdditionalPhonesOfCustomer',
    'AdditionalEmailAddress',
    'AdditionalEmailAddressesOfCustomer',
    'ShopRolePermissions',
    'CustomMessageTemplate',
    'InspectionEntryToService',
    'ShopFeatures',
    'VehicleImage',
    'TimeClock',
    'TimeClockHistory',
    'Notification',
    'MaintenanceSchedules',
    'CannedPart',
    'ProductivityStatus',
    'InspectionEntryStatus',
    'CounterSales',
    'Review',
    'Discount',
    'VehicleFluids',
    'VehicleOilFilter',
    'FailedPaymentAttempt',
    'ApiKey',
    'PrintAction',
    'CannedJobRelated',
    'PurchaseOrder',
    'IntakeRequest',
    'CatalogLaborMatrix',
    'AutomatedVehicleReminder',
    'ShopLogoRaw',
    'InventoryAdjustment',
    'AppointmentScheduleSettings',
    'AppointmentAvailability',
    'InventoryLocations',
    'Verification',
    'Reminder'
  ],
  // appointments
  endpoints: (builder) => ({
    getAppointments: builder.query<
      SerializableAppointmentEntry[],
      GetAppointmentRequest
    >({
      query: generateRawSdkQueryFn(
        getAppointmentRaw,
        decodeRawAppointmentEntryResponse
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Appointment' as const,
                id: entry.id!
              })),
              'Appointment'
            ]
          : ['Appointment']
    }),
    getSingleAppointment: builder.query<
      SerializableAppointmentEntry[],
      GetAppointmentRequest
    >({
      query: generateRawSdkQueryFn(
        getAppointmentRaw,
        decodeRawAppointmentEntryResponse
      ),
      providesTags: (result) =>
        [{ type: 'Appointment' as const }].concat(
          result
            ? result.map((entry) => ({
                type: 'Appointment' as const,
                id: entry.id!
              }))
            : []
        )
    }),
    getLatestAppointments: builder.query<
      SerializableAppointmentEntry[],
      GetLatestAppointmentsAtShopRequest
    >({
      query: generateRawSdkQueryFn(
        getLatestAppointmentsAtShopRaw,
        decodeRawAppointmentEntryResponse
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Appointment' as const,
                id: entry.id!
              })),
              'Appointment',
              'LatestAppointment' as const
            ]
          : ['Appointment', 'LatestAppointment' as const]
    }),
    getAppointmentsForCustomer: builder.query<
      SerializableAppointmentEntry[],
      GetAppointmentsForCustomerRequest
    >({
      query: generateRawSdkQueryFn(
        getAppointmentsForCustomerRaw,
        decodeRawAppointmentEntryResponse
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Appointment' as const,
                id: entry.id!
              })),
              'Appointment',
              'AppointmentsOfCustomer' as const
            ]
          : ['Appointment', 'AppointmentsOfCustomer' as const]
    }),
    getAppointmentsForVehicle: builder.query<
      SerializableAppointmentEntry[],
      GetAppointmentsForVehicleRequest
    >({
      query: generateRawSdkQueryFn(
        getAppointmentsForVehicleRaw,
        decodeRawAppointmentEntryResponse
      ),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Appointment' as const,
                id: entry.id!
              })),
              'Appointment',
              'AppointmentsOfVehicle' as const
            ]
          : ['Appointment', 'AppointmentsOfVehicle' as const]
    }),

    updateAppointment: builder.mutation<void, UpdateAppointmentRequest>({
      query: (req) => () => updateAppointment(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Appointment', id: req.appointmentEntry!.id }
      ]
    }),
    createAppointment: builder.mutation<
      string,
      CreateAppointmentOperationRequest
    >({
      query: (req) => () => createAppointment(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: [
        { type: 'LatestAppointment' },
        { type: 'AppointmentsOfVehicle' },
        { type: 'AppointmentsOfCustomer' }
      ]
    }),
    createAppointmentWithVerificationId: builder.mutation<
      string,
      CreateAppointmentWithVerificationIdRequest
    >({
      query: (req) => () => createAppointmentWithVerificationId(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: [
        { type: 'LatestAppointment' },
        { type: 'AppointmentsOfVehicle' },
        { type: 'AppointmentsOfCustomer' }
      ]
    }),
    archiveAppointment: builder.mutation<void, ArchiveAppointmentRequest>({
      query: (req) => () => archiveAppointment(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Appointment', id: req.appointment! }
      ]
    }),

    getAppointmentSettingsByShop: builder.query<
      AppointmentSettingsEntry,
      GetAppointmentSettingsByShopRequest
    >({
      query: (req) => () => getAppointmentSettingsByShop(req),
      providesTags: ['AppointmentScheduleSettings']
    }),
    getAppointmentSettingsByShopWithVerificationId: builder.query<
      AppointmentSettingsEntry,
      GetAppointmentSettingsByShopWithVerificationIdRequest
    >({
      query: (req) => () => getAppointmentSettingsByShopWithVerificationId(req),
      providesTags: ['AppointmentScheduleSettings']
    }),
    updateAppointmentSettings: builder.mutation<
      void,
      UpdateAppointmentSettingsRequest
    >({
      query: (req) => () => updateAppointmentSettings(req),
      invalidatesTags: () => [{ type: 'AppointmentScheduleSettings' }]
    }),
    getAppointmentTimeConstraintsByShop: builder.query<
      AppointmentSettingsEntry,
      GetAppointmentTimeConstraintsByShopRequest
    >({
      query: (req) => () => getAppointmentTimeConstraintsByShop(req),
      providesTags: ['AppointmentScheduleSettings']
    }),
    updateAppointmentTimeConstraints: builder.mutation<
      void,
      UpdateAppointmentTimeConstraintsRequest
    >({
      query: (req) => () => updateAppointmentTimeConstraints(req),
      invalidatesTags: () => [{ type: 'AppointmentScheduleSettings' }]
    }),

    getAppointmentAvailability: builder.query<
      LocalDateTimeArray[],
      GetAppointmentAvailabilityRequest
    >({
      query: (req) => () => getAppointmentAvailability(req),
      providesTags: ['AppointmentAvailability']
    }),
    getAppointmentAvailabilityWithVerificationId: builder.query<
      LocalDateTimeArray[],
      GetAppointmentAvailabilityWithVerificationIdRequest
    >({
      query: (req) => () => getAppointmentAvailabilityWithVerificationId(req),
      providesTags: ['AppointmentAvailability']
    }),

    handleAppointmentScheduleRequestResponse: builder.mutation<
      void,
      HandleAppointmentScheduleRequestResponseRequest
    >({
      query: (req) => () => handleAppointmentScheduleRequestResponse(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'Appointment',
          id: req.appointmentScheduleResponseRequest?.appointmentId
        }
      ]
    }),

    // customers
    getCustomers: builder.query<CustomerEntry[], GetCustomerInfoRequest>({
      query: (req) => () => getCustomerInfo(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Customer' as const,
                id: entry.id!
              })),
              'Customer'
            ]
          : ['Customer']
    }),
    getCustomerDetail: builder.query<CustomerEntry[], GetCustomerInfoRequest>({
      queryFn: (req) => sdkQueryNoToast(() => getCustomerInfo(req)),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Customer' as const,
                id: entry.id!
              })),
              'Customer'
            ]
          : ['Customer']
    }),
    getCustomersForVehicle: builder.query<
      CustomerEntry[],
      GetCustomersForVehicleRequest
    >({
      query: (req) => () => getCustomersForVehicle(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Customer' as const,
                id: entry.id!
              })),
              'Customer',
              'CustomersOfVehicle' as const
            ]
          : ['Customer', 'CustomersOfVehicle' as const]
    }),
    updateCustomerDetail: builder.mutation<void, UpdateCustomerRequest>({
      query: (req) => () => updateCustomer(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Customer', id: req.customerEntry!.id }
      ]
    }),
    createCustomer: builder.mutation<string, CreateCustomerRequest>({
      query: (req) => () => createCustomer(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: [{ type: 'Customer' }]
    }),
    linkCustomerToVehicle: builder.mutation<void, LinkCustomerToVehicleRequest>(
      {
        query: (req) => () => linkCustomerToVehicle(req),
        invalidatesTags: ['VehiclesOfCustomer', 'CustomersOfVehicle']
      }
    ),
    unlinkCustomerFromVehicle: builder.mutation<
      void,
      UnlinkCustomerFromVehicleRequest
    >({
      query: (req) => () => unlinkCustomerFromVehicle(req),
      invalidatesTags: ['VehiclesOfCustomer', 'CustomersOfVehicle']
    }),
    listCustomers: builder.query<CustomerEntry[], ListCustomersRequest>({
      query: (req) => () => listCustomers(req),
      providesTags: (result) =>
        [
          { type: 'Customer' as const },
          { type: 'Customer' as const, id: 'List' }
        ].concat(
          (result &&
            result.map((entry) => ({
              type: 'Customer' as const,
              id: entry!.id!
            }))) ||
            []
        )
    }),
    mergeCustomers: builder.mutation<void, MergeCustomersOperationRequest>({
      query: (req) => () => mergeCustomers(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Customer', id: req.mergeCustomersRequest!.customerToArchive },
        { type: 'Customer', id: req.mergeCustomersRequest!.customerToKeep },
        { type: 'VehiclesOfCustomer' },
        { type: 'CustomersOfVehicle' },
        { type: 'AppointmentsOfCustomer' },
        {
          type: 'Authorization' as const,
          id: 'LIST'
        },
        { type: 'Repair Order' as const, id: 'List' }
      ]
    }),

    // credit card readers
    listCreditCardReaders: builder.query<
      CreditCardReader[],
      ListCreditCardReadersRequest
    >({
      query: (req) => () => listCreditCardReaders(req)
    }),

    // Repair orders
    getRepairOrders: builder.query<
      RepairOrderExpanded[],
      ListRepairOrdersRequest
    >({
      query: (req) => () => listRepairOrders(req),
      providesTags: (result) =>
        [
          'Repair Order' as const,
          { type: 'Repair Order' as const, id: 'List' }
        ].concat(
          result
            ? result.map((repairOrder) => ({
                type: 'Repair Order',
                id: repairOrder!.entry!.id!
              }))
            : []
        )
    }),
    listRepairOrdersWithRelated: builder.query<
      RepairOrderWithRelated[],
      ListRepairOrdersWithRelatedRequest
    >({
      query: (req) => () => listRepairOrdersWithRelated(req),
      providesTags: (result) =>
        [
          'Repair Order' as const,
          { type: 'Repair Order' as const, id: 'List' }
        ].concat(
          result
            ? result.map((repairOrder) => ({
                type: 'Repair Order',
                id: repairOrder!.repairOrder!.id!
              }))
            : []
        )
    }),
    lookupRepairOrdersForPartOverrides: builder.query<
      { [key: string]: RepairOrderEntry },
      LookupRepairOrdersForPartOverridesRequest
    >({
      query: (req) => () => lookupRepairOrdersForPartOverrides(req),
      providesTags: (result) =>
        [
          'Repair Order' as const,
          { type: 'Repair Order' as const, id: 'List' }
        ].concat(
          result
            ? Object.values(result).map((repairOrder: RepairOrderEntry) => ({
                type: 'Repair Order',
                id: repairOrder!.id!
              }))
            : []
        )
    }),
    triggerCodatFunction: builder.mutation<void, TriggerCodatFunctionRequest>({
      query: (req) => () => triggerCodatFunction(req)
    }),
    updateRepairOrder: builder.mutation<string, UpdateRepairOrderRequest>({
      query: (req) => () => updateRepairOrder(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Repair Order', id: req.repairOrderEntry!.id },
        'Repair Order'
      ]
    }),
    bulkUpdateRepairOrderStatus: builder.mutation<
      void,
      BulkUpdateRepairOrderStatusOperationRequest
    >({
      query: (req) => () => bulkUpdateRepairOrderStatus(req),
      invalidatesTags: ['Repair Order']
    }),
    updateMileageIn: builder.mutation<string, UpdateMileageInRequest>({
      query: (req) => () => updateMileageIn(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Repair Order', id: req.updateMileageRequest?.repairOrder }
      ]
    }),
    updateMileageOut: builder.mutation<string, UpdateMileageOutRequest>({
      query: (req) => () => updateMileageOut(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Repair Order', id: req.updateMileageRequest?.repairOrder },
        { type: 'Vehicle', id: req.updateMileageRequest?.vehicle }
      ]
    }),
    createRepairOrder: builder.mutation<
      RepairOrderEntry,
      CreateRepairOrderRequest
    >({
      query: (req) => () => createRepairOrder(req),
      invalidatesTags: ['Repair Order', { type: 'Repair Order', id: 'List' }]
    }),
    listPaymentsForRepairOrder: builder.query<
      RepairPaymentEntry[],
      ListPaymentsForRepairOrderRequest
    >({
      query: (req) => () => listPaymentsForRepairOrder(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Payment' as const,
                id: entry.id
              })),
              'Payment'
            ]
          : ['Payment']
    }),
    listPaymentsForCustomer: builder.query<
      { [key: string]: Array<RepairPaymentEntry> },
      ListPaymentsForCustomerRequest
    >({
      query: (req) => () => listPaymentsForCustomer(req),
      providesTags: (result) =>
        result
          ? [
              ...Object.values(result)
                .flat()
                .map((entry) => ({
                  type: 'Payment' as const,
                  id: entry.id
                })),
              'Payment'
            ]
          : ['Payment']
    }),
    createPayment: builder.mutation<string, CreatePaymentRequest>({
      query: (req) => () => createPayment(req),
      invalidatesTags: [{ type: 'Payment' }, 'CounterSales', 'Payment']
    }),
    collectPaymentCardPresent: builder.mutation<
      RepairPaymentEntry,
      CollectPaymentCardPresentRequest
    >({
      query: (req) => () => collectPaymentCardPresent(req),
      invalidatesTags: [{ type: 'Payment' }]
    }),
    collectPaymentCardNotPresent: builder.mutation<
      RepairPaymentEntry,
      CollectPaymentCardNotPresentRequest
    >({
      query: (req) => () => collectPaymentCardNotPresent(req),
      invalidatesTags: [{ type: 'Payment' }]
    }),
    updateRepairPayment: builder.mutation<void, UpdateRepairPaymentRequest>({
      query: (req) => () => updateRepairPayment(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Payment', id: req.repairPaymentEntry!.id }
      ]
    }),
    updateRepairPaymentType: builder.mutation<
      void,
      UpdateRepairPayment1Request
    >({
      query: (req) => () => updateRepairPaymentType(req),
      invalidatesTags: (result, error, req) => [
        ...req.updatePaymentTypes!.paymentIds!.map((pId) => ({
          type: 'Payment' as const,
          id: pId
        }))
      ]
    }),
    // Services
    getServiceItems: builder.query<
      ServiceExpanded[],
      GetRepairOrderServicesRequest
    >({
      query: (req) => () => getRepairOrderServices(req),
      // SDK Date transformation seems to fail for doubly-nested objects
      transformResponse: transformServiceExpandedArray,
      providesTags: (result) =>
        result
          ? [
              ...result.map((service) => ({
                type: 'Service' as const,
                id: service.entry!.id!
              })),
              'Service'
            ]
          : ['Service']
    }),
    getServices: builder.query<ServiceEntry[], GetServicesRequest>({
      query: (req) => () => getServices(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((service) => ({
                type: 'Service' as const,
                id: service.id!
              })),
              'Service'
            ]
          : ['Service']
    }),
    addServiceItem: builder.mutation<string, CreateServiceStandaloneRequest>({
      query: (req) => () => createService(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: (_, __, req) => [
        'Service',
        { type: 'Repair Order', id: req.serviceInfo!.repairOrder! }
      ]
    }),
    emailInvoicePdf: builder.mutation<void, EmailInvoicePdfRequest>({
      query: (req) => () => emailInvoicePdf(req)
    }),
    orderCart: builder.mutation<OrderCartResponse, OrderCartRequest>({
      query: (req) => () => orderCart(req),
      invalidatesTags: () => ['Service', 'Repair Order']
    }),
    createPartOverride: builder.mutation<void, CreatePartOverrideRequest>({
      query: (req) => () => createPartOverride(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Service', id: req.partOverride!.service! },
        'Repair Order'
      ]
    }),
    updatePartOverride: builder.mutation<void, UpdatePartOverrideRequest>({
      query: (req) => () => updatePartOverride(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Service', id: req.partOverride!.service! },
        'Repair Order'
      ]
    }),
    deletePartOverride: builder.mutation<void, DeletePartOverrideRequest>({
      query: (req) => () => deletePartOverride(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Service', id: req.service! },
        'Repair Order',
        'InventoryPart'
      ]
    }),
    addPartsToService: builder.mutation<
      void,
      AddPartsToServiceOperationRequest
    >({
      query: (req) => () => addPartsToService(req),
      invalidatesTags: ['Service', 'Repair Order']
    }),
    orderCart1: builder.mutation<void, OrderCart1Request>({
      query: (req) => () => orderCart1(req),
      invalidatesTags: ['Service', 'Repair Order']
    }),
    checkRepairLinkPrices: builder.mutation<
      RepairLinkPartInCartExpandedChecked[],
      CheckRepairLinkPricesRequest
    >({
      query: (req) => () => checkRepairLinkPrices(req)
    }),
    updateRepairLinkPrices: builder.mutation<
      void,
      UpdateRepairLinkPricesRequest
    >({
      query: (req) => () => updateRepairLinkPrices(req),
      invalidatesTags: ['Service', 'Repair Order']
    }),
    updateService: builder.mutation<void, UpdateServiceRequest>({
      query: (req) => () => updateService(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Service', id: req.serviceEntry!.id! },
        { type: 'Repair Order', id: req.serviceEntry!.info!.repairOrder! }
      ]
    }),
    archiveService: builder.mutation<void, ArchiveServiceRequest>({
      query: (req) => () => archiveService(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Service', id: req.service! },
        'Repair Order'
      ]
    }),
    createLaborOverride: builder.mutation<
      string[],
      CreateLaborOverridesOperationRequest
    >({
      query: (req) => () => createLaborOverrides(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Service', id: req.createLaborOverridesRequest!.service },
        'Repair Order'
      ]
    }),
    updateLaborOverride: builder.mutation<void, UpdateLaborOverrideByIdRequest>(
      {
        query: (req) => () => updateLaborOverride(req),
        invalidatesTags: (result, error, req) => [
          { type: 'Service', id: req.laborOverride!.service! },
          'Repair Order'
        ]
      }
    ),
    deleteLaborOverride: builder.mutation<void, DeleteLaborOverrideRequest>({
      query: (req) => () => deleteLaborOverride(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Service', id: req.service! },
        'Repair Order'
      ]
    }),
    updateQuantity: builder.mutation<void, UpdateQuantityOperationRequest>({
      query: (req) => () => updateQuantity(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Service', id: req.updateQuantityRequest!.service! },
        'Repair Order'
      ]
    }),
    createWarrantyEntry: builder.mutation<string, CreateWarrantyEntryRequest>({
      query: (req) => () => createWarrantyEntry(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: ['Service', 'Repair Order']
    }),
    updateWarrantyEntry: builder.mutation<void, UpdateWarrantyEntryRequest>({
      query: (req) => () => updateWarrantyEntry(req),
      invalidatesTags: ['Service', 'Repair Order']
    }),
    deleteWarrantyEntry: builder.mutation<void, DeleteWarrantyEntryRequest>({
      query: (req) => () => deleteWarrantyEntry(req),
      invalidatesTags: ['Service', 'Repair Order']
    }),
    createCustomFee: builder.mutation<void, CreateCustomFeeRequest>({
      query: (req) => () => createCustomFee(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Service', id: req.customFeeInfo!.service! },
        'Repair Order',
        'CounterSales'
      ]
    }),
    updateCustomFee: builder.mutation<void, UpdateCustomFeeRequest>({
      query: (req) => () => updateCustomFee(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Service', id: req.customFeeEntry!.info!.service! },
        'Repair Order',
        'CounterSales'
      ]
    }),
    // OPTIMIZATION: invalidate one service
    deleteCustomFee: builder.mutation<void, DeleteCustomFeeRequest>({
      query: (req) => () => deleteCustomFee(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Service', id: req.service },
        'Repair Order',
        'CounterSales'
      ]
    }),
    createSublet: builder.mutation<void, CreateSubletRequest>({
      query: (req) => () => createSublet(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Service', id: req.subletInfo!.service! },
        'Repair Order'
      ]
    }),
    updateSublet: builder.mutation<void, UpdateSubletRequest>({
      query: (req) => () => updateSublet(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Service', id: req.subletEntry!.info!.service! },
        'Repair Order'
      ]
    }),
    deleteSublet: builder.mutation<void, DeleteSubletRequest>({
      query: (req) => () => deleteSublet(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Service', id: req.service },
        'Repair Order'
      ]
    }),
    createAuthorization: builder.mutation<string, CreateAuthorizationRequest>({
      query: (req) => () => createAuthorization(req),
      invalidatesTags: () => [
        {
          type: 'Authorization',
          id: 'LIST'
        },
        'Service',
        'Repair Order'
      ]
    }),
    listAuthorizations: builder.query<
      Array<AuthorizationEntry>,
      ListAuthorizationsRequest
    >({
      query: (req) => () => listAuthorizations(req),
      providesTags: (result) =>
        [
          {
            type: 'Authorization' as const,
            id: 'LIST'
          }
        ].concat(
          (result &&
            result.map((entry) => ({
              type: 'Authorization' as const,
              id: entry.id!
            }))) ||
            []
        ),
      transformResponse: (result: Array<AuthorizationEntry>) =>
        result.map((entry) => ({
          ...entry,
          info: {
            ...entry.info!,
            dateTime: new Date(entry.info!.dateTime!)
          }
        }))
    }),
    assignTechsToService: builder.mutation<void, AssignTechsToServiceRequest>({
      query: (req) => () => assignTechsToService(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'Service',
          id: req.assignTechToServiceRequest!.service!
        }
      ]
    }),
    assignTechsToLaborOverride: builder.mutation<
      void,
      AssignTechsToLaborOverrideRequest
    >({
      query: (req) => () => assignTechsToLaborOverride(req),
      invalidatesTags: () => ['Service']
    }),
    editCartRefreshPrices: builder.mutation<
      PunchoutInfo,
      EditCartRefreshPricesRequest
    >({
      query: (req) => () => editCartRefreshPrices(req),
      invalidatesTags: ['Service', 'Repair Order']
    }),
    copyPartsTechPartsToService: builder.mutation<
      void,
      CopyPartsTechPartsToServiceOperationRequest
    >({
      query: (req) => () => copyPartsTechPartsToService(req),
      invalidatesTags: () => ['Service', 'Repair Order']
    }),
    convertQuotedPart: builder.mutation<void, ConvertQuotedPartRequest>({
      query: (req) => () => convertQuotedPart(req),
      invalidatesTags: ['Service', 'Repair Order']
    }),

    // shop settings
    getShopProfile: builder.query<ShopEntry, GetShopProfileRequest>({
      query: (req) => () => getShopProfile(req),
      providesTags: ['Shop']
    }),
    updateShopProfile: builder.mutation<void, UpdateShopProfileRequest>({
      query: (req) => () => {
        req.shopEntry!.info!.calendarStartTime = defaultTimeZoneDateToUtcDate(
          req.shopEntry!.info!.calendarStartTime!
        )
        const endTime = req.shopEntry!.info!.calendarEndTime!
        if (endTime.toLocaleTimeString() === '12:00:00 AM') {
          endTime.setHours(11, 59, 59)
        }
        req.shopEntry!.info!.calendarEndTime =
          defaultTimeZoneDateToUtcDate(endTime)
        return updateShopProfile(req)
      },
      invalidatesTags: ['Shop']
    }),
    setPartsTechCredentials: builder.mutation<
      void,
      SetPartsTechCredentialsRequest
    >({
      query: (req) => () => setPartsTechCredentials(req),
      invalidatesTags: ['Shop']
    }),
    setRepairLinkAccessToken: builder.mutation<
      string,
      SetRepairLinkAccessTokenRequest
    >({
      query: (req) => () => setRepairLinkAccessToken(req),
      invalidatesTags: ['Shop']
    }),
    getRepairLinkAccessToken: builder.mutation<
      string,
      GetRepairLinkAccessTokenRequest
    >({
      query: (req) => () => getRepairLinkAccessToken(req),
      invalidatesTags: ['Shop']
    }),
    getShopLogoForDymo: builder.query<string, GetShopLogoForDymoRequest>({
      query: (req) => () => getShopLogoForDymo(req),
      providesTags: ['ShopLogoRaw']
    }),
    deleteShop: builder.mutation<void, DeleteShopRequest>({
      query: (req) => () => deleteShop(req),
      invalidatesTags: ['Shop']
    }),
    getShopRoles: builder.query<Role[], GetRolesRequest>({
      query: (req) => () => getRoles(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'ShopRole' as const,
                id: entry.id!
              })),
              'ShopRole'
            ]
          : ['ShopRole']
    }),
    createShopRole: builder.mutation<string, CreateRoleRequest>({
      query: (req) => () => createRole(req),
      invalidatesTags: ['ShopRole']
    }),
    updateShopRole: builder.mutation<string, UpdateRoleRequest>({
      query: (req) => () => updateRole(req),
      invalidatesTags: ['ShopRole']
    }),
    deleteShopRole: builder.mutation<void, DeleteRoleRequest>({
      query: (req) => () => deleteRole(req),
      invalidatesTags: ['ShopRole']
    }),
    getShopRolePermissions: builder.query<string[], GetRolePermissionsRequest>({
      query: (req) => () => getRolePermissions(req),
      providesTags: () => ['ShopRolePermissions']
    }),
    updateRolePermissions: builder.mutation<
      void,
      UpdateRolePermissionsOperationRequest
    >({
      query: (req) => () => updateRolePermissions(req),
      invalidatesTags: ['ShopRolePermissions']
    }),
    getEmployeeRoles: builder.query<EmployeeRole[], GetEmployeeRolesRequest>({
      query: (req) => () => getEmployeeRoles(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Team' as const,
                id: entry.userProfile!.id!
              })),
              'Team'
            ]
          : ['Team']
    }),
    setRole: builder.mutation<void, SetRoleRequest>({
      query: (req) => () => setRole(req),
      invalidatesTags: (result, error, req) => [{ type: 'Team', id: req.user }]
    }),
    revokeRole: builder.mutation<void, RevokeRoleRequest>({
      query: (req) => () => revokeRole(req),
      invalidatesTags: (result, error, req) => [{ type: 'Team', id: req.user }]
    }),
    inviteUser: builder.mutation<string, InviteUserRequest>({
      query: (req) => () => inviteUser(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: [{ type: 'Team' }]
    }),
    updateTeamMemberProfile: builder.mutation<
      void,
      UpdateTeamMemberProfileRequest
    >({
      query: (req) => () => updateTeamMemberProfile(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Team', id: req.member }
      ]
    }),
    updateTeamMemberPassword: builder.mutation<
      void,
      UpdateTeamMemberPasswordRequest
    >({
      query: (req) => () => updateTeamMemberPassword(req)
    }),
    getShopResources: builder.query<ResourceEntry[], ListShopResourcesRequest>({
      query: (req) => () => listShopResources(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entity) => ({
                type: 'Resource' as const,
                id: entity.id!
              })),
              'Resource'
            ]
          : ['Resource']
    }),
    createShopResource: builder.mutation<string, CreateResourceRequest>({
      query: (req) => () => createResource(req),
      invalidatesTags: [{ type: 'Resource' }]
    }),
    updateShopResource: builder.mutation<void, UpdateResourceRequest>({
      query: (req) => () => updateResource(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Resource', id: req.resourceEntry!.id }
      ]
    }),
    archiveShopResource: builder.mutation<void, ArchiveResourceRequest>({
      query: (req) => () => archiveResource(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Resource', id: req.resource }
      ]
    }),

    createShopVendor: builder.mutation<string, CreateShopVendorRequest>({
      query: (req) => () => createShopVendor(req),
      invalidatesTags: [{ type: 'Vendor' }, 'Vendor']
    }),
    getVendorEntriesById: builder.query<
      VendorEntry[],
      GetVendorEntriesByIdRequest
    >({
      query: (req) => () => getVendorEntriesById(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Vendor' as const,
                id: entry.id!
              })),
              { type: 'Vendor' as const },
              'Vendor'
            ]
          : ['Vendor']
    }),
    getVendorEntriesByShopId: builder.query<
      VendorEntry[],
      GetVendorEntriesByShopIdRequest
    >({
      query: (req) => () => getVendorEntriesByShopId(req),
      providesTags: (result, error, req) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Vendor' as const,
                id: entry.id!
              })),
              { type: 'Vendor' as const, id: `shop: ${req.shop}` },
              'Vendor'
            ]
          : ['Vendor']
    }),
    updateShopVendor: builder.mutation<void, UpdateShopVendorRequest>({
      query: (req) => () => updateShopVendor(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Vendor', id: req.vendorEntry?.id }
      ]
    }),
    archiveShopVendor: builder.mutation<void, ArchiveShopVendorRequest>({
      query: (req) => () => archiveShopVendor(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Vendor', id: req.vendorId }
      ]
    }),

    createRepairOrderStatus: builder.mutation<
      string,
      CreateRepairOrderStatusRequest
    >({
      query: (req) => () => createRepairOrderStatus(req),
      invalidatesTags: ['Status']
    }),
    updateRepairOrderStatus: builder.mutation<
      void,
      UpdateRepairOrderStatusRequest
    >({
      query: (req) => () => updateRepairOrderStatus(req),
      invalidatesTags: (result, error, req) =>
        !error
          ? [{ id: req!.repairOrderStatusEntry!.id, type: 'Status' as const }]
          : []
    }),
    listRepairOrderStatusesForShop: builder.query<
      RepairOrderStatusEntry[],
      ListRepairOrderStatusesForShopRequest
    >({
      query: (req) => () => listRepairOrderStatusesForShop(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Status' as const,
                id: entry.id
              })),
              'Status'
            ]
          : ['Status']
    }),
    updateRepairOrderStatusRanks: builder.mutation<
      void,
      UpdateRepairOrderStatusRanksRequest
    >({
      query: (req) => () => updateRepairOrderStatusRanks(req),
      invalidatesTags: (res, error, req) => [
        ...req.reorderRepairOrderStatusesRequest!.statuses!.map((id) => ({
          type: 'Status' as const,
          id
        })),
        'Status'
      ]
    }),
    getUsersWithPermission: builder.query<
      UserProfile[],
      GetUsersWithPermissionRequest
    >({
      query: (req) => () => getUsersWithPermission(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entity) => ({
                type: 'User' as const,
                id: entity.id!
              })),
              'User'
            ]
          : []
    }),
    getOtherPeopleProfiles: builder.query<
      UserProfile[],
      GetOtherPeopleProfilesRequest
    >({
      query: (req) => () => getOtherPeopleProfiles(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entity) => ({
                type: 'User' as const,
                id: entity.id!
              })),
              'User'
            ]
          : []
    }),
    uploadShopLogo: builder.mutation<void, UploadShopLogoRequest>({
      query: (req) => () => uploadShopLogo(req),
      invalidatesTags: ['Shop', 'ShopLogoRaw']
    }),

    // vehicles
    getVehicles: builder.query<VehicleEntry[], GetVehicleInfoRequest>({
      query: (req) => () => getVehicleInfo(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Vehicle' as const,
                id: entry.id!
              })),
              'Vehicle'
            ]
          : ['Vehicle' as const]
    }),
    getVehicleDetail: builder.query<VehicleEntry[], GetVehicleInfoRequest>({
      query: (req) => () => getVehicleInfo(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Vehicle' as const,
                id: entry.id!
              })),
              'Vehicle'
            ]
          : ['Vehicle' as const]
    }),
    getCustomerVehicles: builder.query<
      VehicleEntry[],
      GetCustomerVehiclesRequest
    >({
      query: (req) => () => getCustomerVehicles(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Vehicle' as const,
                id: entry.id!
              })),
              'Vehicle',
              'VehiclesOfCustomer' as const
            ]
          : ['VehiclesOfCustomer' as const]
    }),
    getCustomerVehiclesWithVerificationId: builder.query<
      VehicleEntry[],
      GetCustomerVehiclesWithVerificationIdRequest
    >({
      query: (req) => () => getCustomerVehiclesWithVerificationId(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Vehicle' as const,
                id: entry.id!
              })),
              'Vehicle',
              'VehiclesOfCustomer' as const
            ]
          : ['VehiclesOfCustomer' as const]
    }),
    getVehicleImages: builder.query<
      VehicleImageEntry[],
      GetVehicleImagesRequest
    >({
      query: (req) => () => getVehicleImages(req),
      providesTags: (result, error, req) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'VehicleImage' as const,
                id: entry.id!
              })),
              { type: 'VehicleImage' as const, vehicle: req.vehicle },
              'VehicleImage'
            ]
          : ['VehicleImage' as const]
    }),
    updateVehicleDetail: builder.mutation<void, UpdateVehicleInfoRequest>({
      query: (req) => () => updateVehicleInfo(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Vehicle', id: req.vehicleEntry!.id }
      ]
    }),
    mergeVehicle: builder.mutation<void, MergeVehiclesOperationRequest>({
      query: (req) => () => mergeVehicle(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Vehicle', id: req.mergeVehiclesRequest?.vehicleToKeep },
        { type: 'Vehicle', id: req.mergeVehiclesRequest?.vehicleToArchive }
      ]
    }),
    createVehicle: builder.mutation<VehicleEntry, CreateVehicleRequest>({
      query: (req) => () => createVehicle(req),
      invalidatesTags: [{ type: 'VehiclesOfCustomer' }]
    }),
    createVehicleWithVerificationId: builder.mutation<
      VehicleEntry,
      CreateVehicleWithVerificationIdRequest
    >({
      query: (req) => () => createVehicleWithVerificationId(req),
      invalidatesTags: [{ type: 'VehiclesOfCustomer' }]
    }),
    createVehicleImage: builder.mutation<string, CreateVehicleImageRequest>({
      query: (req) => () => createVehicleImage(req),
      invalidatesTags: (result, error, req) => [
        { type: 'VehicleImage', vehicle: req.vehicle }
      ]
    }),
    archiveVehicleImage: builder.mutation<
      void,
      ArchiveVehicleImageOperationRequest
    >({
      query: (req) => () => archiveVehicleImage(req),
      invalidatesTags: (result, error, req) => [
        { type: 'VehicleImage', id: req.archiveVehicleImageRequest?.imageId }
      ]
    }),

    // additional phone/email
    getAdditionalPhonesForCustomer: builder.query<
      CustomerAdditionalPhoneEntry[],
      GetAdditionalPhonesForCustomerRequest
    >({
      query: (req) => () => getAdditionalPhonesForCustomer(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'AdditionalPhone' as const,
                id: entry.id
              })),
              'AdditionalPhone',
              'AdditionalPhonesOfCustomer' as const
            ]
          : ['AdditionalPhonesOfCustomer' as const]
    }),
    updateAdditionalPhone: builder.mutation<void, UpdateAdditionalPhoneRequest>(
      {
        query: (req) => () => updateAdditionalPhone(req),
        invalidatesTags: (result, error, req) => [
          { type: 'AdditionalPhone', id: req.customerAdditionalPhoneEntry!.id }
        ]
      }
    ),
    createAdditionalPhone: builder.mutation<
      string,
      CreateAdditionalPhoneRequest
    >({
      query: (req) => () => createAdditionalPhone(req),
      invalidatesTags: [{ type: 'AdditionalPhonesOfCustomer' }]
    }),
    deleteAdditionalPhone: builder.mutation<void, DeleteAdditionalPhoneRequest>(
      {
        query: (req) => () => deleteAdditionalPhone(req),
        invalidatesTags: [{ type: 'AdditionalPhonesOfCustomer' }]
      }
    ),
    getAdditionalEmailAddressesForCustomer: builder.query<
      CustomerAdditionalEmailAddressEntry[],
      GetAdditionalEmailAddressesForCustomerRequest
    >({
      query: (req) => () => getAdditionalEmailAddressesForCustomer(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'AdditionalEmailAddress' as const,
                id: entry.id
              })),
              'AdditionalEmailAddress',
              'AdditionalEmailAddressesOfCustomer' as const
            ]
          : ['AdditionalEmailAddressesOfCustomer' as const]
    }),
    updateAdditionalEmailAddress: builder.mutation<
      void,
      UpdateAdditionalEmailAddressRequest
    >({
      query: (req) => () => updateAdditionalEmailAddress(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'AdditionalEmailAddress',
          id: req.customerAdditionalEmailAddressEntry!.id
        }
      ]
    }),
    createAdditionalEmailAddress: builder.mutation<
      string,
      CreateAdditionalEmailAddressRequest
    >({
      query: (req) => () => createAdditionalEmailAddress(req),
      invalidatesTags: [{ type: 'AdditionalEmailAddressesOfCustomer' }]
    }),
    deleteAdditionalEmailAddress: builder.mutation<
      void,
      DeleteAdditionalEmailAddressRequest
    >({
      query: (req) => () => deleteAdditionalEmailAddress(req),
      invalidatesTags: [{ type: 'AdditionalEmailAddressesOfCustomer' }]
    }),

    // inventory
    getAllInventoryParts: builder.query<
      ShopInventory,
      GetInventoryPartsRequest
    >({
      query: (req) => () => getAllInventoryParts(req),
      providesTags: (result) =>
        result
          ? [
              ...Object.keys(result.allParts!).map((id) => ({
                type: 'InventoryPart' as const,
                id
              })),
              'InventoryPart'
            ]
          : ['InventoryPart']
    }),
    getInventoryPartsById: builder.query<
      Array<InventoryPartEntry>,
      GetInventoryPartsByIdRequest
    >({
      query: (req) => () => getInventoryPartsById(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((part) => ({
                type: 'InventoryPart' as const,
                id: part.id!
              })),
              'InventoryPart'
            ]
          : ['InventoryPart']
    }),
    getInventoryPartsByPartNumberAndBrand: builder.query<
      Array<InventoryPartEntry>,
      GetInventoryPartsByPartNumberAndBrandRequest
    >({
      query: (req) => () => getInventoryPartsByPartNumberAndBrand(req)
    }),
    createInventoryPart: builder.mutation<string, CreateInventoryPartRequest>({
      query: (req) => () => createInventoryPart(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: ['InventoryPart']
    }),
    updateInventoryPart: builder.mutation<void, UpdateInventoryPartRequest>({
      query: (req) => () => updateInventoryPart(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'InventoryPart',
          id: req.inventoryPartEntry!.id!
        }
      ]
    }),
    getInventoryInstances: builder.query<
      Array<InventoryPartInstanceEntry>,
      GetInventoryInstancesRequest
    >({
      query: (req) => () => getInventoryInstances(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((instance) => ({
                type: 'InventoryInstance' as const,
                id: instance.id!
              })),
              'InventoryInstance'
            ]
          : ['InventoryInstance']
    }),
    getInventoryInstanceExpanded: builder.query<
      Array<InventoryPartInstanceExpanded>,
      GetInventoryInstanceExpandedRequest
    >({
      query: (req) => () => getInventoryInstanceExpanded(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((instance) => ({
                type: 'InventoryInstance' as const,
                id: instance.entry!.id!
              })),
              'InventoryInstance',
              ...result
                .filter((i) => !!i.repairOrder)
                .map((instance) => ({
                  type: 'Repair Order' as const,
                  id: instance.repairOrder!.id!
                })),
              'Repair Order'
            ]
          : ['InventoryInstance']
    }),
    getCoreInventoryInstances: builder.query<
      [InventoryPartInstanceEntry, InventoryPartInstanceEntry | null][],
      GetCoreInventoryInstancesRequest
    >({
      query: (req) => () => getCoreInventoryInstances(req),
      // SDK Date transformation seems to fail for nested objects
      transformResponse: (
        response: [
          InventoryPartInstanceEntry,
          InventoryPartInstanceEntry | null
        ][]
      ) => {
        response.forEach(
          (
            coreParentAndChild: [
              InventoryPartInstanceEntry,
              InventoryPartInstanceEntry | null
            ]
          ) => {
            coreParentAndChild.forEach((instance) => {
              if (instance) {
                const invInfo = instance.info!
                invInfo.ordered = transformDate(invInfo.ordered)
                invInfo.received = transformDate(invInfo.received)
                invInfo.used = transformDate(invInfo.used)
                invInfo.returned = transformDate(invInfo.returned)
                invInfo.lost = transformDate(invInfo.lost)
                invInfo.corePaymentReceived = transformDate(
                  invInfo.corePaymentReceived
                )
                invInfo.markedForReturn = transformDate(invInfo.markedForReturn)
              }
            })
          }
        )
        return response
      },
      providesTags: [
        'InventoryInstance',
        { type: 'InventoryInstance', id: 'Core' }
      ]
    }),
    getInventoryPendingReturn: builder.query<
      InventoryPartInstanceEntry[],
      GetInventoryNonCoreReturnsRequest
    >({
      query: (req) => () => getInventoryPendingReturn(req),
      providesTags: [
        'InventoryInstance',
        { type: 'InventoryInstance', id: 'Return' }
      ]
    }),
    getInventoryAdjustments: builder.query<
      InventoryAdjustmentEntryExpanded[],
      GetInventoryAdjustmentsRequest
    >({
      query: (req) => () => getInventoryAdjustments(req),
      providesTags: ['InventoryAdjustment']
    }),
    createInventoryAdjustmentDocument: builder.mutation<
      string,
      CreateInventoryAdjustmentDocumentRequest
    >({
      query: (req) => () => createInventoryAdjustmentDocument(req),
      invalidatesTags: ['InventoryAdjustment']
    }),
    createInventoryInstancesWithAdjustment: builder.mutation<
      CreateInventoryInstancesWithAdjustmentResponse,
      CreateInventoryInstancesWithAdjustmentOperationRequest
    >({
      query: (req) => () => createInventoryInstancesWithAdjustment(req),
      invalidatesTags: [
        'InventoryAdjustment',
        'InventoryInstance',
        'InventoryPart'
      ]
    }),
    updateInventoryInstancesWithAdjustment: builder.mutation<
      string,
      UpdateInventoryInstancesWithAdjustmentRequest
    >({
      query: (req) => () => updateInventoryInstancesWithAdjustment(req),
      invalidatesTags: [
        'InventoryAdjustment',
        'InventoryInstance',
        'InventoryPart'
      ]
    }),
    createInventoryInstance: builder.mutation<
      string,
      CreateInventoryInstanceRequest
    >({
      query: (req) => () => createInventoryInstance(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: ['InventoryInstance', 'InventoryPart']
    }),
    updateInventoryInstance: builder.mutation<
      void,
      UpdateInventoryInstanceRequest
    >({
      query: (req) => () => updateInventoryInstance(req),
      invalidatesTags: (result, error, req) => [
        {
          type: 'InventoryInstance',
          id: req.inventoryPartInstanceEntry!.id!
        },
        'InventoryPart',
        'CounterSales',
        ...(req.inventoryPartInstanceEntry!.info!.purchaseOrder
          ? [
              {
                type: 'PurchaseOrder' as const,
                id: req.inventoryPartInstanceEntry!.info!.purchaseOrder
              }
            ]
          : [])
      ]
    }),
    deleteInventoryInstances: builder.mutation<
      void,
      DeleteInventoryInstancesRequest
    >({
      query: (req) => () => deleteInventoryInstances(req),
      invalidatesTags: ['InventoryInstance', 'InventoryPart']
    }),
    getInventoryPartIdsByPartNumber: builder.query<
      { [key: string]: string[] },
      GetInventoryPartIdsByPartNumberRequest
    >({
      query: (req) => () => getInventoryPartIdsByPartNumber(req),
      providesTags: ['InventoryPart']
    }),
    // create partOverride + link inventory
    addInventoryToService: builder.mutation<
      PartOverride,
      AddInventoryToServiceOperationRequest
    >({
      query: (req) => () => addInventoryToService(req),
      invalidatesTags: ['Service', 'Repair Order']
    }),
    getInventoryLocations: builder.query<
      string[],
      GetInventoryLocationsRequest
    >({
      query: (req) => () => getInventoryLocations(req),
      providesTags: () => ['InventoryLocations']
    }),
    claimInventory: builder.mutation<void, ClaimInventoryOperationRequest>({
      query: (req) => () => claimInventory(req),
      invalidatesTags: (result, error, req) => [
        ...req.claimInventoryRequest!.instanceId!.map((id) => ({
          type: 'InventoryInstance' as const,
          id
        })),
        'InventoryPart',
        'Service',
        'Repair Order'
      ]
    }),
    // link inventory does not affect inventory availability, no invalidation needed
    linkInventory: builder.mutation<string[], ClaimInventoryOperationRequest>({
      query: (req) => () => linkInventory(req),
      invalidatesTags: () => ['Service', 'Repair Order']
    }),
    unlinkInventory: builder.mutation<void, UnlinkInventoryRequest>({
      query: (req) => () => unlinkInventory(req),
      invalidatesTags: () => ['Service', 'Repair Order']
    }),
    releaseInventoryClaims: builder.mutation<
      void,
      ReleaseInventoryClaimsRequest
    >({
      query: (req) => () => releaseInventoryClaims(req),
      invalidatesTags: [
        'InventoryInstance',
        'InventoryPart',
        'Service',
        'Repair Order'
      ]
    }),
    listPartStatusForRepairOrder: builder.query<
      PartStatusEntry[],
      ListPartStatusForRepairOrderRequest
    >({
      query: (req) => () => listPartStatusForRepairOrder(req),
      providesTags: (result) =>
        result
          ? [
              ...result.map((entry) => ({
                type: 'Part Status' as const,
                id: entry.id
              })),
              'Part Status'
            ]
          : ['Part Status']
    }),
    createPartStatus: builder.mutation<string, CreatePartStatusRequest>({
      query: (req) => () => createPartStatus(req),
      invalidatesTags: [{ type: 'Part Status' }]
    }),
    updatePartStatus: builder.mutation<void, UpdatePartStatusRequest>({
      query: (req) => () => updatePartStatus(req),
      invalidatesTags: (result, error, req) => [
        { type: 'Part Status', id: req.partStatusEntry!.id! }
      ]
    }),
    checkShopOnboarded: builder.query<string, CheckShopOnboardedRequest>({
      query: (req) => () => checkShopOnboarded(req)
    }),
    getCustomMessageTemplatesForShop: builder.query<
      MessageTemplateEntry[],
      GetCustomMessageTemplatesForShopRequest
    >({
      query: (req) => () => getCustomMessageTemplatesForShop(req),
      providesTags: ['CustomMessageTemplate']
    }),
    createCustomMessageTemplate: builder.mutation<
      string,
      CreateCustomMessageTemplateRequest
    >({
      query: (req) => () => createCustomMessageTemplate(req),
      invalidatesTags: () => ['CustomMessageTemplate']
    }),
    updateCustomMessageTemplate: builder.mutation<
      void,
      UpdateCustomMessageTemplateRequest
    >({
      query: (req) => () => updateCustomMessageTemplate(req),
      invalidatesTags: () => ['CustomMessageTemplate']
    }),
    deleteCustomMessageTemplate: builder.mutation<
      void,
      DeleteCustomMessageTemplateRequest
    >({
      query: (req) => () => deleteCustomMessageTemplate(req),
      invalidatesTags: () => ['CustomMessageTemplate']
    }),
    listFeaturesForShop: builder.query<
      ShopFeatureEntry[],
      ListFeaturesForShopRequest
    >({
      query: (req) => () => listFeaturesForShop(req),
      providesTags: ['ShopFeatures']
    }),
    // counter sales
    listCounterSalesExpanded: builder.query<
      CounterSaleExpanded[],
      ListCounterSalesExpandedRequest
    >({
      query: (req) => () => listCounterSalesExpanded(req),
      providesTags: ['CounterSales']
    }),
    listCounterSales: builder.query<
      CounterSaleEntry[],
      ListCounterSalesRequest
    >({
      query: (req) => () => listCounterSales(req),
      providesTags: ['CounterSales']
    }),
    createCounterSale: builder.mutation<string, CreateCounterSaleRequest>({
      query: (req) => () => createCounterSale(req),
      transformResponse: (response: string) => stripQuotes(response),
      invalidatesTags: () => ['CounterSales']
    }),
    createCounterSaleNoTags: builder.mutation<string, CreateCounterSaleRequest>(
      {
        query: (req) => () => createCounterSale(req),
        transformResponse: (response: string) => stripQuotes(response)
      }
    ),
    updateCounterSale: builder.mutation<void, UpdateCounterSaleRequest>({
      query: (req) => () => updateCounterSale(req),
      invalidatesTags: () => ['CounterSales']
    }),
    archiveCounterSale: builder.mutation<void, ArchiveCounterSaleRequest>({
      query: (req) => () => archiveCounterSale(req),
      invalidatesTags: () => ['CounterSales']
    }),
    createRepairOrderMediaEntry: builder.mutation<
      void,
      CreateRepairOrderMediaEntryRequest
    >({
      query: (req) => () => createRepairOrderMediaEntry(req),
      invalidatesTags: ['Repair Order' as const]
    }),
    getRepairOrderMediaEntries: builder.query<
      RepairOrderMediaEntry[],
      GetRepairOrderMediaEntriesRequest
    >({
      query: (req) => () => getRepairOrderMediaEntries(req),
      providesTags: ['Repair Order' as const]
    }),
    updateRepairOrderMediaInfo: builder.mutation<
      void,
      UpdateRepairOrderMediaInfoRequest
    >({
      query: (req) => () => updateRepairOrderMediaInfo(req),
      invalidatesTags: ['Repair Order' as const]
    }),
    getRepairOrderMediaEntriesByApiKey: builder.query<
      RepairOrderMediaEntry[],
      GetRepairOrderMediaEntriesByApiKeyRequest
    >({
      query: (req) => () => getRepairOrderMediaEntriesByApiKey(req),
      providesTags: ['Repair Order' as const]
    }),
    createRepairOrderAttachmentEntry: builder.mutation<
      void,
      CreateRepairOrderAttachmentEntryRequest
    >({
      query: (req) => () => createRepairOrderAttachmentEntry(req),
      invalidatesTags: ['Repair Order' as const]
    }),
    getRepairOrderAttachmentEntries: builder.query<
      RepairOrderAttachmentEntry[],
      GetRepairOrderAttachmentEntriesRequest
    >({
      query: (req) => () => getRepairOrderAttachmentEntries(req),
      providesTags: ['Repair Order' as const]
    }),
    updateRepairOrderAttachmentInfo: builder.mutation<
      void,
      UpdateRepairOrderAttachmentInfoRequest
    >({
      query: (req) => () => updateRepairOrderAttachmentInfo(req),
      invalidatesTags: ['Repair Order' as const]
    }),
    archiveRepairOrderAttachment: builder.mutation<
      void,
      ArchiveRepairOrderAttachmentRequest
    >({
      query: (req) => () => archiveRepairOrderAttachment(req),
      invalidatesTags: ['Repair Order' as const]
    }),
    getKpi: builder.query<Kpi[], GetKpiRequest>({
      query: (req) => () => getKpi(req),
      providesTags: ['Repair Order' as const]
    })
  })
})
export const {
  useGetAppointmentsQuery,
  useGetSingleAppointmentQuery,
  useGetLatestAppointmentsQuery,
  useGetAppointmentsForCustomerQuery,
  useGetAppointmentsForVehicleQuery,
  useUpdateAppointmentMutation,
  useCreateAppointmentMutation,
  useCreateAppointmentWithVerificationIdMutation,
  useArchiveAppointmentMutation,
  useGetAppointmentSettingsByShopQuery,
  useGetAppointmentSettingsByShopWithVerificationIdQuery,
  useUpdateAppointmentSettingsMutation,
  useGetAppointmentTimeConstraintsByShopQuery,
  useUpdateAppointmentTimeConstraintsMutation,
  useGetAppointmentAvailabilityQuery,
  useLazyGetAppointmentAvailabilityQuery,
  useGetAppointmentAvailabilityWithVerificationIdQuery,
  useLazyGetAppointmentAvailabilityWithVerificationIdQuery,
  useHandleAppointmentScheduleRequestResponseMutation,
  useGetCustomerDetailQuery,
  useUpdateCustomerDetailMutation,
  useGetCustomerVehiclesQuery,
  useGetCustomerVehiclesWithVerificationIdQuery,
  useLinkCustomerToVehicleMutation,
  useUnlinkCustomerFromVehicleMutation,
  useGetCustomersQuery,
  useCreateCustomerMutation,
  useGetRepairOrdersQuery,
  useUpdateRepairOrderMutation,
  useCreateRepairOrderMutation,
  useGetServiceItemsQuery,
  useLazyGetServiceItemsQuery,
  useGetServicesQuery,
  useAddServiceItemMutation,
  useOrderCartMutation,
  useCreatePartOverrideMutation,
  useUpdatePartOverrideMutation,
  useDeletePartOverrideMutation,
  useUpdateServiceMutation,
  useArchiveServiceMutation,
  useCreateLaborOverrideMutation,
  useUpdateLaborOverrideMutation,
  useDeleteLaborOverrideMutation,
  useUpdateQuantityMutation,
  useCreateCustomFeeMutation,
  useUpdateCustomFeeMutation,
  useDeleteCustomFeeMutation,
  useGetShopProfileQuery,
  useLazyGetShopProfileQuery,
  useUpdateShopProfileMutation,
  useSetPartsTechCredentialsMutation,
  useDeleteShopMutation,
  useGetShopRolesQuery,
  useGetEmployeeRolesQuery,
  useSetRoleMutation,
  useRevokeRoleMutation,
  useInviteUserMutation,
  useGetShopResourcesQuery,
  useCreateShopResourceMutation,
  useUpdateShopResourceMutation,
  useArchiveShopResourceMutation,
  useCreateShopVendorMutation,
  useGetVendorEntriesByIdQuery,
  useLazyGetVendorEntriesByIdQuery,
  useGetVendorEntriesByShopIdQuery,
  useLazyGetVendorEntriesByShopIdQuery,
  useUpdateShopVendorMutation,
  useArchiveShopVendorMutation,
  useGetVehiclesQuery,
  useGetVehicleDetailQuery,
  useUpdateVehicleDetailMutation,
  useCreateVehicleMutation,
  useCreateVehicleWithVerificationIdMutation,
  useGetCustomersForVehicleQuery,
  useLazyGetCustomersForVehicleQuery,
  useListAuthorizationsQuery,
  useCreateAuthorizationMutation,
  useListPaymentsForRepairOrderQuery,
  useLazyListPaymentsForRepairOrderQuery,
  useCreatePaymentMutation,
  useGetUsersWithPermissionQuery,
  useLazyGetUsersWithPermissionQuery,
  useAssignTechsToServiceMutation,
  useEditCartRefreshPricesMutation,
  useCreatePartStatusMutation,
  useListCustomersQuery,
  useListRepairOrderStatusesForShopQuery,
  useUpdateRepairOrderStatusRanksMutation,
  useCreateRepairOrderStatusMutation,
  useUpdateRepairOrderStatusMutation,
  useGetAllInventoryPartsQuery,
  useLazyGetAllInventoryPartsQuery,
  useGetInventoryPartsByIdQuery,
  useLazyGetInventoryPartsByIdQuery,
  useGetInventoryPartsByPartNumberAndBrandQuery,
  useLazyGetInventoryPartsByPartNumberAndBrandQuery,
  useCreateInventoryPartMutation,
  useUpdateInventoryPartMutation,
  useGetInventoryInstancesQuery,
  useLazyGetInventoryInstancesQuery,
  useCreateInventoryInstanceMutation,
  useUpdateInventoryInstanceMutation,
  useDeleteInventoryInstancesMutation,
  useGetInventoryPartIdsByPartNumberQuery,
  useClaimInventoryMutation,
  useLinkInventoryMutation,
  useUnlinkInventoryMutation,
  useReleaseInventoryClaimsMutation,
  useAddInventoryToServiceMutation,
  useListPartStatusForRepairOrderQuery,
  useUpdatePartStatusMutation,
  useGetCoreInventoryInstancesQuery,
  useGetInventoryPendingReturnQuery,
  useLazyGetInventoryPendingReturnQuery,
  useLazyGetCoreInventoryInstancesQuery,
  useListCreditCardReadersQuery,
  useCollectPaymentCardPresentMutation,
  useCollectPaymentCardNotPresentMutation,
  useEmailInvoicePdfMutation,
  useListPaymentsForCustomerQuery,
  useUpdateRepairPaymentTypeMutation,
  useBulkUpdateRepairOrderStatusMutation,
  useUpdateMileageInMutation,
  useUpdateMileageOutMutation,
  useCopyPartsTechPartsToServiceMutation,
  useCheckShopOnboardedQuery,
  useUpdateTeamMemberProfileMutation,
  useCreateAdditionalPhoneMutation,
  useDeleteAdditionalPhoneMutation,
  useGetAdditionalPhonesForCustomerQuery,
  useUpdateAdditionalPhoneMutation,
  useCreateAdditionalEmailAddressMutation,
  useDeleteAdditionalEmailAddressMutation,
  useGetAdditionalEmailAddressesForCustomerQuery,
  useUpdateAdditionalEmailAddressMutation,
  useAssignTechsToLaborOverrideMutation,
  useGetOtherPeopleProfilesQuery,
  useUpdateTeamMemberPasswordMutation,
  useCreateShopRoleMutation,
  useUpdateShopRoleMutation,
  useDeleteShopRoleMutation,
  useGetShopRolePermissionsQuery,
  useUpdateRolePermissionsMutation,
  useGetCustomMessageTemplatesForShopQuery,
  useCreateCustomMessageTemplateMutation,
  useUpdateCustomMessageTemplateMutation,
  useDeleteCustomMessageTemplateMutation,
  useUploadShopLogoMutation,
  useListFeaturesForShopQuery,
  useConvertQuotedPartMutation,
  useGetVehicleImagesQuery,
  useCreateVehicleImageMutation,
  useArchiveVehicleImageMutation,
  useLookupRepairOrdersForPartOverridesQuery,
  useMergeCustomersMutation,
  useListCounterSalesExpandedQuery,
  useLazyListCounterSalesExpandedQuery,
  useListCounterSalesQuery,
  useCreateCounterSaleMutation,
  useCreateCounterSaleNoTagsMutation,
  useUpdateCounterSaleMutation,
  useArchiveCounterSaleMutation,
  useListRepairOrdersWithRelatedQuery,
  useCreateWarrantyEntryMutation,
  useUpdateWarrantyEntryMutation,
  useDeleteWarrantyEntryMutation,
  useGetRepairOrderMediaEntriesQuery,
  useCreateRepairOrderMediaEntryMutation,
  useUpdateRepairOrderMediaInfoMutation,
  useGetRepairOrderMediaEntriesByApiKeyQuery,
  useGetRepairOrderAttachmentEntriesQuery,
  useCreateRepairOrderAttachmentEntryMutation,
  useUpdateRepairOrderAttachmentInfoMutation,
  useArchiveRepairOrderAttachmentMutation,
  useCreateSubletMutation,
  useUpdateSubletMutation,
  useDeleteSubletMutation,
  useSetRepairLinkAccessTokenMutation,
  useGetRepairLinkAccessTokenMutation,
  useAddPartsToServiceMutation,
  useOrderCart1Mutation,
  useCheckRepairLinkPricesMutation,
  useUpdateRepairLinkPricesMutation,
  useGetShopLogoForDymoQuery,
  useGetInventoryInstanceExpandedQuery,
  useLazyGetInventoryInstanceExpandedQuery,
  useTriggerCodatFunctionMutation,
  useGetKpiQuery,
  useMergeVehicleMutation,
  useGetInventoryAdjustmentsQuery,
  useCreateInventoryAdjustmentDocumentMutation,
  useCreateInventoryInstancesWithAdjustmentMutation,
  useUpdateInventoryInstancesWithAdjustmentMutation,
  useGetInventoryLocationsQuery
} = api
