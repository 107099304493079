/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PingPerson } from './PingPerson';
import {
    PingPersonFromJSON,
    PingPersonFromJSONTyped,
    PingPersonToJSON,
} from './PingPerson';

/**
 * 
 * @export
 * @interface PingTeam
 */
export interface PingTeam {
    /**
     * 
     * @type {Array<PingPerson>}
     * @memberof PingTeam
     */
    members?: Array<PingPerson>;
    /**
     * 
     * @type {string}
     * @memberof PingTeam
     */
    teamName?: string;
    /**
     * 
     * @type {string}
     * @memberof PingTeam
     */
    teamId?: string;
}

/**
 * Check if a given object implements the PingTeam interface.
 */
export function instanceOfPingTeam(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PingTeamFromJSON(json: any): PingTeam {
    return PingTeamFromJSONTyped(json, false);
}

export function PingTeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): PingTeam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'members': !exists(json, 'members') ? undefined : ((json['members'] as Array<any>).map(PingPersonFromJSON)),
        'teamName': !exists(json, 'teamName') ? undefined : json['teamName'],
        'teamId': !exists(json, 'teamId') ? undefined : json['teamId'],
    };
}

export function PingTeamToJSON(value?: PingTeam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'members': value.members === undefined ? undefined : ((value.members as Array<any>).map(PingPersonToJSON)),
        'teamName': value.teamName,
        'teamId': value.teamId,
    };
}

