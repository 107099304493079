/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FluidCapacity } from './FluidCapacity';
import {
    FluidCapacityFromJSON,
    FluidCapacityFromJSONTyped,
    FluidCapacityToJSON,
} from './FluidCapacity';
import type { FluidItem } from './FluidItem';
import {
    FluidItemFromJSON,
    FluidItemFromJSONTyped,
    FluidItemToJSON,
} from './FluidItem';
import type { Taxonomy } from './Taxonomy';
import {
    TaxonomyFromJSON,
    TaxonomyFromJSONTyped,
    TaxonomyToJSON,
} from './Taxonomy';

/**
 * 
 * @export
 * @interface FluidApplication
 */
export interface FluidApplication {
    /**
     * 
     * @type {number}
     * @memberof FluidApplication
     */
    id?: number;
    /**
     * 
     * @type {Taxonomy}
     * @memberof FluidApplication
     */
    taxonomy?: Taxonomy;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof FluidApplication
     */
    attributes?: { [key: string]: number; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof FluidApplication
     */
    attributeDescriptors?: { [key: string]: string; };
    /**
     * 
     * @type {Array<FluidCapacity>}
     * @memberof FluidApplication
     */
    capacities?: Array<FluidCapacity>;
    /**
     * 
     * @type {Array<FluidItem>}
     * @memberof FluidApplication
     */
    items?: Array<FluidItem>;
}

/**
 * Check if a given object implements the FluidApplication interface.
 */
export function instanceOfFluidApplication(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FluidApplicationFromJSON(json: any): FluidApplication {
    return FluidApplicationFromJSONTyped(json, false);
}

export function FluidApplicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): FluidApplication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'taxonomy': !exists(json, 'taxonomy') ? undefined : TaxonomyFromJSON(json['taxonomy']),
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
        'attributeDescriptors': !exists(json, 'attributeDescriptors') ? undefined : json['attributeDescriptors'],
        'capacities': !exists(json, 'capacities') ? undefined : ((json['capacities'] as Array<any>).map(FluidCapacityFromJSON)),
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(FluidItemFromJSON)),
    };
}

export function FluidApplicationToJSON(value?: FluidApplication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'taxonomy': TaxonomyToJSON(value.taxonomy),
        'attributes': value.attributes,
        'attributeDescriptors': value.attributeDescriptors,
        'capacities': value.capacities === undefined ? undefined : ((value.capacities as Array<any>).map(FluidCapacityToJSON)),
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(FluidItemToJSON)),
    };
}

