/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Role } from './Role';
import {
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';
import type { UserProfile } from './UserProfile';
import {
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
} from './UserProfile';

/**
 * 
 * @export
 * @interface EmployeeRole
 */
export interface EmployeeRole {
    /**
     * 
     * @type {Role}
     * @memberof EmployeeRole
     */
    role?: Role;
    /**
     * 
     * @type {UserProfile}
     * @memberof EmployeeRole
     */
    userProfile?: UserProfile;
    /**
     * 
     * @type {number}
     * @memberof EmployeeRole
     */
    hourlyRate?: number;
}

/**
 * Check if a given object implements the EmployeeRole interface.
 */
export function instanceOfEmployeeRole(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmployeeRoleFromJSON(json: any): EmployeeRole {
    return EmployeeRoleFromJSONTyped(json, false);
}

export function EmployeeRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': !exists(json, 'role') ? undefined : RoleFromJSON(json['role']),
        'userProfile': !exists(json, 'userProfile') ? undefined : UserProfileFromJSON(json['userProfile']),
        'hourlyRate': !exists(json, 'hourlyRate') ? undefined : json['hourlyRate'],
    };
}

export function EmployeeRoleToJSON(value?: EmployeeRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': RoleToJSON(value.role),
        'userProfile': UserProfileToJSON(value.userProfile),
        'hourlyRate': value.hourlyRate,
    };
}

