/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InspectionSectionConversationMessageInfo
 */
export interface InspectionSectionConversationMessageInfo {
    /**
     * 
     * @type {string}
     * @memberof InspectionSectionConversationMessageInfo
     */
    section?: string;
    /**
     * 
     * @type {string}
     * @memberof InspectionSectionConversationMessageInfo
     */
    message?: string;
}

/**
 * Check if a given object implements the InspectionSectionConversationMessageInfo interface.
 */
export function instanceOfInspectionSectionConversationMessageInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InspectionSectionConversationMessageInfoFromJSON(json: any): InspectionSectionConversationMessageInfo {
    return InspectionSectionConversationMessageInfoFromJSONTyped(json, false);
}

export function InspectionSectionConversationMessageInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionSectionConversationMessageInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'section': !exists(json, 'section') ? undefined : json['section'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function InspectionSectionConversationMessageInfoToJSON(value?: InspectionSectionConversationMessageInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'section': value.section,
        'message': value.message,
    };
}

