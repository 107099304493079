/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthorizationInfo
 */
export interface AuthorizationInfo {
    /**
     * 
     * @type {string}
     * @memberof AuthorizationInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationInfo
     */
    repairOrder?: string;
    /**
     * 
     * @type {Date}
     * @memberof AuthorizationInfo
     */
    dateTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof AuthorizationInfo
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationInfo
     */
    via?: AuthorizationInfoViaEnum;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationInfo
     */
    viaDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationInfo
     */
    authorizer?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationInfo
     */
    authorizerName?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationInfo
     */
    contactInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationInfo
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationInfo
     */
    ipAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationInfo
     */
    signature?: string;
}


/**
 * @export
 */
export const AuthorizationInfoViaEnum = {
    Email: 'email',
    Phone: 'phone',
    InPerson: 'in_person',
    CustomerPortal: 'customer_portal',
    Other: 'other',
    CustomerIntake: 'customer_intake'
} as const;
export type AuthorizationInfoViaEnum = typeof AuthorizationInfoViaEnum[keyof typeof AuthorizationInfoViaEnum];


/**
 * Check if a given object implements the AuthorizationInfo interface.
 */
export function instanceOfAuthorizationInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuthorizationInfoFromJSON(json: any): AuthorizationInfo {
    return AuthorizationInfoFromJSONTyped(json, false);
}

export function AuthorizationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'repairOrder': !exists(json, 'repairOrder') ? undefined : json['repairOrder'],
        'dateTime': !exists(json, 'dateTime') ? undefined : (new Date(json['dateTime'])),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'via': !exists(json, 'via') ? undefined : json['via'],
        'viaDescription': !exists(json, 'viaDescription') ? undefined : json['viaDescription'],
        'authorizer': !exists(json, 'authorizer') ? undefined : json['authorizer'],
        'authorizerName': !exists(json, 'authorizerName') ? undefined : json['authorizerName'],
        'contactInfo': !exists(json, 'contactInfo') ? undefined : json['contactInfo'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'ipAddress': !exists(json, 'ipAddress') ? undefined : json['ipAddress'],
        'signature': !exists(json, 'signature') ? undefined : json['signature'],
    };
}

export function AuthorizationInfoToJSON(value?: AuthorizationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'repairOrder': value.repairOrder,
        'dateTime': value.dateTime === undefined ? undefined : (value.dateTime.toISOString()),
        'amount': value.amount,
        'via': value.via,
        'viaDescription': value.viaDescription,
        'authorizer': value.authorizer,
        'authorizerName': value.authorizerName,
        'contactInfo': value.contactInfo,
        'notes': value.notes,
        'ipAddress': value.ipAddress,
        'signature': value.signature,
    };
}

