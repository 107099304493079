/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IdName } from './IdName';
import {
    IdNameFromJSON,
    IdNameFromJSONTyped,
    IdNameToJSON,
} from './IdName';
import type { PartsTechPart } from './PartsTechPart';
import {
    PartsTechPartFromJSON,
    PartsTechPartFromJSONTyped,
    PartsTechPartToJSON,
} from './PartsTechPart';

/**
 * 
 * @export
 * @interface SupplierOrder
 */
export interface SupplierOrder {
    /**
     * 
     * @type {number}
     * @memberof SupplierOrder
     */
    totalPrice?: number;
    /**
     * 
     * @type {IdName}
     * @memberof SupplierOrder
     */
    supplier?: IdName;
    /**
     * 
     * @type {string}
     * @memberof SupplierOrder
     */
    poNumber?: string;
    /**
     * 
     * @type {Array<PartsTechPart>}
     * @memberof SupplierOrder
     */
    parts?: Array<PartsTechPart>;
    /**
     * 
     * @type {number}
     * @memberof SupplierOrder
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SupplierOrder
     */
    tax?: number;
}

/**
 * Check if a given object implements the SupplierOrder interface.
 */
export function instanceOfSupplierOrder(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplierOrderFromJSON(json: any): SupplierOrder {
    return SupplierOrderFromJSONTyped(json, false);
}

export function SupplierOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPrice': !exists(json, 'totalPrice') ? undefined : json['totalPrice'],
        'supplier': !exists(json, 'supplier') ? undefined : IdNameFromJSON(json['supplier']),
        'poNumber': !exists(json, 'poNumber') ? undefined : json['poNumber'],
        'parts': !exists(json, 'parts') ? undefined : ((json['parts'] as Array<any>).map(PartsTechPartFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tax': !exists(json, 'tax') ? undefined : json['tax'],
    };
}

export function SupplierOrderToJSON(value?: SupplierOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalPrice': value.totalPrice,
        'supplier': IdNameToJSON(value.supplier),
        'poNumber': value.poNumber,
        'parts': value.parts === undefined ? undefined : ((value.parts as Array<any>).map(PartsTechPartToJSON)),
        'id': value.id,
        'tax': value.tax,
    };
}

