/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddInventoryToServiceRequest
 */
export interface AddInventoryToServiceRequest {
    /**
     * 
     * @type {string}
     * @memberof AddInventoryToServiceRequest
     */
    service?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AddInventoryToServiceRequest
     */
    inventoryInstanceIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryToServiceRequest
     */
    markup?: string;
    /**
     * 
     * @type {number}
     * @memberof AddInventoryToServiceRequest
     */
    pricePerUnit?: number;
    /**
     * 
     * @type {number}
     * @memberof AddInventoryToServiceRequest
     */
    quantity?: number;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryToServiceRequest
     */
    unitOfMeasure?: AddInventoryToServiceRequestUnitOfMeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryToServiceRequest
     */
    oilFilter?: string;
    /**
     * 
     * @type {string}
     * @memberof AddInventoryToServiceRequest
     */
    fluids?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddInventoryToServiceRequest
     */
    taxExempt?: boolean;
}


/**
 * @export
 */
export const AddInventoryToServiceRequestUnitOfMeasureEnum = {
    DiscreteUnit: 'discrete_unit',
    Liter: 'liter',
    Ml: 'ml',
    Gallon: 'gallon',
    Quart: 'quart',
    Ounce: 'ounce',
    FlOz: 'fl_oz'
} as const;
export type AddInventoryToServiceRequestUnitOfMeasureEnum = typeof AddInventoryToServiceRequestUnitOfMeasureEnum[keyof typeof AddInventoryToServiceRequestUnitOfMeasureEnum];


/**
 * Check if a given object implements the AddInventoryToServiceRequest interface.
 */
export function instanceOfAddInventoryToServiceRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddInventoryToServiceRequestFromJSON(json: any): AddInventoryToServiceRequest {
    return AddInventoryToServiceRequestFromJSONTyped(json, false);
}

export function AddInventoryToServiceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddInventoryToServiceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'service': !exists(json, 'service') ? undefined : json['service'],
        'inventoryInstanceIds': !exists(json, 'inventoryInstanceIds') ? undefined : json['inventoryInstanceIds'],
        'markup': !exists(json, 'markup') ? undefined : json['markup'],
        'pricePerUnit': !exists(json, 'pricePerUnit') ? undefined : json['pricePerUnit'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'unitOfMeasure': !exists(json, 'unitOfMeasure') ? undefined : json['unitOfMeasure'],
        'oilFilter': !exists(json, 'oilFilter') ? undefined : json['oilFilter'],
        'fluids': !exists(json, 'fluids') ? undefined : json['fluids'],
        'taxExempt': !exists(json, 'taxExempt') ? undefined : json['taxExempt'],
    };
}

export function AddInventoryToServiceRequestToJSON(value?: AddInventoryToServiceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service': value.service,
        'inventoryInstanceIds': value.inventoryInstanceIds,
        'markup': value.markup,
        'pricePerUnit': value.pricePerUnit,
        'quantity': value.quantity,
        'unitOfMeasure': value.unitOfMeasure,
        'oilFilter': value.oilFilter,
        'fluids': value.fluids,
        'taxExempt': value.taxExempt,
    };
}

