/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateInspectionEntryRanksRequest
 */
export interface UpdateInspectionEntryRanksRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateInspectionEntryRanksRequest
     */
    section?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateInspectionEntryRanksRequest
     */
    entries?: Array<string>;
}

/**
 * Check if a given object implements the UpdateInspectionEntryRanksRequest interface.
 */
export function instanceOfUpdateInspectionEntryRanksRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateInspectionEntryRanksRequestFromJSON(json: any): UpdateInspectionEntryRanksRequest {
    return UpdateInspectionEntryRanksRequestFromJSONTyped(json, false);
}

export function UpdateInspectionEntryRanksRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateInspectionEntryRanksRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'section': !exists(json, 'section') ? undefined : json['section'],
        'entries': !exists(json, 'entries') ? undefined : json['entries'],
    };
}

export function UpdateInspectionEntryRanksRequestToJSON(value?: UpdateInspectionEntryRanksRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'section': value.section,
        'entries': value.entries,
    };
}

