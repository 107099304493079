/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PartTaxonomy } from './PartTaxonomy';
import {
    PartTaxonomyFromJSON,
    PartTaxonomyFromJSONTyped,
    PartTaxonomyToJSON,
} from './PartTaxonomy';

/**
 * 
 * @export
 * @interface CannedJobPartLookupInfo
 */
export interface CannedJobPartLookupInfo {
    /**
     * 
     * @type {string}
     * @memberof CannedJobPartLookupInfo
     */
    cannedJob?: string;
    /**
     * 
     * @type {string}
     * @memberof CannedJobPartLookupInfo
     */
    searchTerm?: string;
    /**
     * 
     * @type {string}
     * @memberof CannedJobPartLookupInfo
     */
    partsMatrix?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CannedJobPartLookupInfo
     */
    taxExempt?: boolean;
    /**
     * 
     * @type {PartTaxonomy}
     * @memberof CannedJobPartLookupInfo
     */
    partTaxonomy?: PartTaxonomy;
}

/**
 * Check if a given object implements the CannedJobPartLookupInfo interface.
 */
export function instanceOfCannedJobPartLookupInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CannedJobPartLookupInfoFromJSON(json: any): CannedJobPartLookupInfo {
    return CannedJobPartLookupInfoFromJSONTyped(json, false);
}

export function CannedJobPartLookupInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CannedJobPartLookupInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cannedJob': !exists(json, 'cannedJob') ? undefined : json['cannedJob'],
        'searchTerm': !exists(json, 'searchTerm') ? undefined : json['searchTerm'],
        'partsMatrix': !exists(json, 'partsMatrix') ? undefined : json['partsMatrix'],
        'taxExempt': !exists(json, 'taxExempt') ? undefined : json['taxExempt'],
        'partTaxonomy': !exists(json, 'partTaxonomy') ? undefined : PartTaxonomyFromJSON(json['partTaxonomy']),
    };
}

export function CannedJobPartLookupInfoToJSON(value?: CannedJobPartLookupInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cannedJob': value.cannedJob,
        'searchTerm': value.searchTerm,
        'partsMatrix': value.partsMatrix,
        'taxExempt': value.taxExempt,
        'partTaxonomy': PartTaxonomyToJSON(value.partTaxonomy),
    };
}

