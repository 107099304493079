/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RepairLinkDealerInfo } from './RepairLinkDealerInfo';
import {
    RepairLinkDealerInfoFromJSON,
    RepairLinkDealerInfoFromJSONTyped,
    RepairLinkDealerInfoToJSON,
} from './RepairLinkDealerInfo';
import type { RepairLinkPartInCartInfo } from './RepairLinkPartInCartInfo';
import {
    RepairLinkPartInCartInfoFromJSON,
    RepairLinkPartInCartInfoFromJSONTyped,
    RepairLinkPartInCartInfoToJSON,
} from './RepairLinkPartInCartInfo';
import type { RepairLinkVehicleInfo } from './RepairLinkVehicleInfo';
import {
    RepairLinkVehicleInfoFromJSON,
    RepairLinkVehicleInfoFromJSONTyped,
    RepairLinkVehicleInfoToJSON,
} from './RepairLinkVehicleInfo';

/**
 * 
 * @export
 * @interface RepairLinkPartInCartExpandedInfo
 */
export interface RepairLinkPartInCartExpandedInfo {
    /**
     * 
     * @type {RepairLinkPartInCartInfo}
     * @memberof RepairLinkPartInCartExpandedInfo
     */
    info?: RepairLinkPartInCartInfo;
    /**
     * 
     * @type {RepairLinkDealerInfo}
     * @memberof RepairLinkPartInCartExpandedInfo
     */
    dealerInfo?: RepairLinkDealerInfo;
    /**
     * 
     * @type {RepairLinkVehicleInfo}
     * @memberof RepairLinkPartInCartExpandedInfo
     */
    vehicleInfo?: RepairLinkVehicleInfo;
}

/**
 * Check if a given object implements the RepairLinkPartInCartExpandedInfo interface.
 */
export function instanceOfRepairLinkPartInCartExpandedInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairLinkPartInCartExpandedInfoFromJSON(json: any): RepairLinkPartInCartExpandedInfo {
    return RepairLinkPartInCartExpandedInfoFromJSONTyped(json, false);
}

export function RepairLinkPartInCartExpandedInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairLinkPartInCartExpandedInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'info': !exists(json, 'info') ? undefined : RepairLinkPartInCartInfoFromJSON(json['info']),
        'dealerInfo': !exists(json, 'dealerInfo') ? undefined : RepairLinkDealerInfoFromJSON(json['dealerInfo']),
        'vehicleInfo': !exists(json, 'vehicleInfo') ? undefined : RepairLinkVehicleInfoFromJSON(json['vehicleInfo']),
    };
}

export function RepairLinkPartInCartExpandedInfoToJSON(value?: RepairLinkPartInCartExpandedInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'info': RepairLinkPartInCartInfoToJSON(value.info),
        'dealerInfo': RepairLinkDealerInfoToJSON(value.dealerInfo),
        'vehicleInfo': RepairLinkVehicleInfoToJSON(value.vehicleInfo),
    };
}

