/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RepairLinkDealerInfo } from './RepairLinkDealerInfo';
import {
    RepairLinkDealerInfoFromJSON,
    RepairLinkDealerInfoFromJSONTyped,
    RepairLinkDealerInfoToJSON,
} from './RepairLinkDealerInfo';
import type { RepairLinkPartInCartInfo } from './RepairLinkPartInCartInfo';
import {
    RepairLinkPartInCartInfoFromJSON,
    RepairLinkPartInCartInfoFromJSONTyped,
    RepairLinkPartInCartInfoToJSON,
} from './RepairLinkPartInCartInfo';
import type { RepairLinkVehicleInfo } from './RepairLinkVehicleInfo';
import {
    RepairLinkVehicleInfoFromJSON,
    RepairLinkVehicleInfoFromJSONTyped,
    RepairLinkVehicleInfoToJSON,
} from './RepairLinkVehicleInfo';

/**
 * 
 * @export
 * @interface AddPartsToServiceRequest
 */
export interface AddPartsToServiceRequest {
    /**
     * 
     * @type {string}
     * @memberof AddPartsToServiceRequest
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof AddPartsToServiceRequest
     */
    service?: string;
    /**
     * 
     * @type {Array<RepairLinkPartInCartInfo>}
     * @memberof AddPartsToServiceRequest
     */
    parts?: Array<RepairLinkPartInCartInfo>;
    /**
     * 
     * @type {Array<RepairLinkDealerInfo>}
     * @memberof AddPartsToServiceRequest
     */
    dealers?: Array<RepairLinkDealerInfo>;
    /**
     * 
     * @type {Array<RepairLinkVehicleInfo>}
     * @memberof AddPartsToServiceRequest
     */
    vehicles?: Array<RepairLinkVehicleInfo>;
}

/**
 * Check if a given object implements the AddPartsToServiceRequest interface.
 */
export function instanceOfAddPartsToServiceRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddPartsToServiceRequestFromJSON(json: any): AddPartsToServiceRequest {
    return AddPartsToServiceRequestFromJSONTyped(json, false);
}

export function AddPartsToServiceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddPartsToServiceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'service': !exists(json, 'service') ? undefined : json['service'],
        'parts': !exists(json, 'parts') ? undefined : ((json['parts'] as Array<any>).map(RepairLinkPartInCartInfoFromJSON)),
        'dealers': !exists(json, 'dealers') ? undefined : ((json['dealers'] as Array<any>).map(RepairLinkDealerInfoFromJSON)),
        'vehicles': !exists(json, 'vehicles') ? undefined : ((json['vehicles'] as Array<any>).map(RepairLinkVehicleInfoFromJSON)),
    };
}

export function AddPartsToServiceRequestToJSON(value?: AddPartsToServiceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'service': value.service,
        'parts': value.parts === undefined ? undefined : ((value.parts as Array<any>).map(RepairLinkPartInCartInfoToJSON)),
        'dealers': value.dealers === undefined ? undefined : ((value.dealers as Array<any>).map(RepairLinkDealerInfoToJSON)),
        'vehicles': value.vehicles === undefined ? undefined : ((value.vehicles as Array<any>).map(RepairLinkVehicleInfoToJSON)),
    };
}

