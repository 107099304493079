/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RepairLinkVehicleData
 */
export interface RepairLinkVehicleData {
    /**
     * 
     * @type {number}
     * @memberof RepairLinkVehicleData
     */
    year?: number;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkVehicleData
     */
    make?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkVehicleData
     */
    model?: string;
    /**
     * 
     * @type {string}
     * @memberof RepairLinkVehicleData
     */
    vin?: string;
}

/**
 * Check if a given object implements the RepairLinkVehicleData interface.
 */
export function instanceOfRepairLinkVehicleData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RepairLinkVehicleDataFromJSON(json: any): RepairLinkVehicleData {
    return RepairLinkVehicleDataFromJSONTyped(json, false);
}

export function RepairLinkVehicleDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RepairLinkVehicleData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'year': !exists(json, 'year') ? undefined : json['year'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'vin': !exists(json, 'vin') ? undefined : json['vin'],
    };
}

export function RepairLinkVehicleDataToJSON(value?: RepairLinkVehicleData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'year': value.year,
        'make': value.make,
        'model': value.model,
        'vin': value.vin,
    };
}

