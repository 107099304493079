/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerEntry } from './CustomerEntry';
import {
    CustomerEntryFromJSON,
    CustomerEntryFromJSONTyped,
    CustomerEntryToJSON,
} from './CustomerEntry';
import type { RepairPaymentEntry } from './RepairPaymentEntry';
import {
    RepairPaymentEntryFromJSON,
    RepairPaymentEntryFromJSONTyped,
    RepairPaymentEntryToJSON,
} from './RepairPaymentEntry';
import type { VehicleEntry } from './VehicleEntry';
import {
    VehicleEntryFromJSON,
    VehicleEntryFromJSONTyped,
    VehicleEntryToJSON,
} from './VehicleEntry';

/**
 * 
 * @export
 * @interface CustomerPages
 */
export interface CustomerPages {
    /**
     * 
     * @type {CustomerEntry}
     * @memberof CustomerPages
     */
    customer?: CustomerEntry;
    /**
     * 
     * @type {Array<VehicleEntry>}
     * @memberof CustomerPages
     */
    vehicles?: Array<VehicleEntry>;
    /**
     * 
     * @type {Array<RepairPaymentEntry>}
     * @memberof CustomerPages
     */
    payments?: Array<RepairPaymentEntry>;
}

/**
 * Check if a given object implements the CustomerPages interface.
 */
export function instanceOfCustomerPages(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerPagesFromJSON(json: any): CustomerPages {
    return CustomerPagesFromJSONTyped(json, false);
}

export function CustomerPagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerPages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customer': !exists(json, 'customer') ? undefined : CustomerEntryFromJSON(json['customer']),
        'vehicles': !exists(json, 'vehicles') ? undefined : ((json['vehicles'] as Array<any>).map(VehicleEntryFromJSON)),
        'payments': !exists(json, 'payments') ? undefined : ((json['payments'] as Array<any>).map(RepairPaymentEntryFromJSON)),
    };
}

export function CustomerPagesToJSON(value?: CustomerPages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customer': CustomerEntryToJSON(value.customer),
        'vehicles': value.vehicles === undefined ? undefined : ((value.vehicles as Array<any>).map(VehicleEntryToJSON)),
        'payments': value.payments === undefined ? undefined : ((value.payments as Array<any>).map(RepairPaymentEntryToJSON)),
    };
}

