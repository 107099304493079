/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PartEntry } from './PartEntry';
import {
    PartEntryFromJSON,
    PartEntryFromJSONTyped,
    PartEntryToJSON,
} from './PartEntry';

/**
 * 
 * @export
 * @interface CopyPartsTechPartsToServiceRequest
 */
export interface CopyPartsTechPartsToServiceRequest {
    /**
     * 
     * @type {string}
     * @memberof CopyPartsTechPartsToServiceRequest
     */
    service?: string;
    /**
     * 
     * @type {Array<PartEntry>}
     * @memberof CopyPartsTechPartsToServiceRequest
     */
    partEntries?: Array<PartEntry>;
}

/**
 * Check if a given object implements the CopyPartsTechPartsToServiceRequest interface.
 */
export function instanceOfCopyPartsTechPartsToServiceRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CopyPartsTechPartsToServiceRequestFromJSON(json: any): CopyPartsTechPartsToServiceRequest {
    return CopyPartsTechPartsToServiceRequestFromJSONTyped(json, false);
}

export function CopyPartsTechPartsToServiceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopyPartsTechPartsToServiceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'service': !exists(json, 'service') ? undefined : json['service'],
        'partEntries': !exists(json, 'partEntries') ? undefined : ((json['partEntries'] as Array<any>).map(PartEntryFromJSON)),
    };
}

export function CopyPartsTechPartsToServiceRequestToJSON(value?: CopyPartsTechPartsToServiceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service': value.service,
        'partEntries': value.partEntries === undefined ? undefined : ((value.partEntries as Array<any>).map(PartEntryToJSON)),
    };
}

