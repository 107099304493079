/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailInvoiceRequest
 */
export interface EmailInvoiceRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailInvoiceRequest
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailInvoiceRequest
     */
    repairOrderId?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailInvoiceRequest
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailInvoiceRequest
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailInvoiceRequest
     */
    toEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailInvoiceRequest
     */
    toName?: string;
}

/**
 * Check if a given object implements the EmailInvoiceRequest interface.
 */
export function instanceOfEmailInvoiceRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmailInvoiceRequestFromJSON(json: any): EmailInvoiceRequest {
    return EmailInvoiceRequestFromJSONTyped(json, false);
}

export function EmailInvoiceRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailInvoiceRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'repairOrderId': !exists(json, 'repairOrderId') ? undefined : json['repairOrderId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'toEmail': !exists(json, 'toEmail') ? undefined : json['toEmail'],
        'toName': !exists(json, 'toName') ? undefined : json['toName'],
    };
}

export function EmailInvoiceRequestToJSON(value?: EmailInvoiceRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'repairOrderId': value.repairOrderId,
        'title': value.title,
        'body': value.body,
        'toEmail': value.toEmail,
        'toName': value.toName,
    };
}

