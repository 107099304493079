/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LaborOverride } from './LaborOverride';
import {
    LaborOverrideFromJSON,
    LaborOverrideFromJSONTyped,
    LaborOverrideToJSON,
} from './LaborOverride';

/**
 * 
 * @export
 * @interface CreateLaborOverridesRequest
 */
export interface CreateLaborOverridesRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLaborOverridesRequest
     */
    service?: string;
    /**
     * 
     * @type {Array<LaborOverride>}
     * @memberof CreateLaborOverridesRequest
     */
    laborOverrides?: Array<LaborOverride>;
}

/**
 * Check if a given object implements the CreateLaborOverridesRequest interface.
 */
export function instanceOfCreateLaborOverridesRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateLaborOverridesRequestFromJSON(json: any): CreateLaborOverridesRequest {
    return CreateLaborOverridesRequestFromJSONTyped(json, false);
}

export function CreateLaborOverridesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLaborOverridesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'service': !exists(json, 'service') ? undefined : json['service'],
        'laborOverrides': !exists(json, 'laborOverrides') ? undefined : ((json['laborOverrides'] as Array<any>).map(LaborOverrideFromJSON)),
    };
}

export function CreateLaborOverridesRequestToJSON(value?: CreateLaborOverridesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service': value.service,
        'laborOverrides': value.laborOverrides === undefined ? undefined : ((value.laborOverrides as Array<any>).map(LaborOverrideToJSON)),
    };
}

