/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IdName } from './IdName';
import {
    IdNameFromJSON,
    IdNameFromJSONTyped,
    IdNameToJSON,
} from './IdName';
import type { PartTaxonomy } from './PartTaxonomy';
import {
    PartTaxonomyFromJSON,
    PartTaxonomyFromJSONTyped,
    PartTaxonomyToJSON,
} from './PartTaxonomy';

/**
 * 
 * @export
 * @interface PartsTechPart
 */
export interface PartsTechPart {
    /**
     * 
     * @type {string}
     * @memberof PartsTechPart
     */
    orderItemId?: string;
    /**
     * 
     * @type {IdName}
     * @memberof PartsTechPart
     */
    supplier?: IdName;
    /**
     * 
     * @type {string}
     * @memberof PartsTechPart
     */
    partId?: string;
    /**
     * 
     * @type {string}
     * @memberof PartsTechPart
     */
    partNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PartsTechPart
     */
    partName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartsTechPart
     */
    partDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof PartsTechPart
     */
    position?: string;
    /**
     * 
     * @type {string}
     * @memberof PartsTechPart
     */
    brandName?: string;
    /**
     * 
     * @type {string}
     * @memberof PartsTechPart
     */
    brandDisplayName?: string;
    /**
     * 
     * @type {number}
     * @memberof PartsTechPart
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof PartsTechPart
     */
    costPerUnit?: number;
    /**
     * 
     * @type {number}
     * @memberof PartsTechPart
     */
    corePerUnit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartsTechPart
     */
    isAvailable?: boolean;
    /**
     * 
     * @type {PartTaxonomy}
     * @memberof PartsTechPart
     */
    partTaxonomy?: PartTaxonomy;
    /**
     * 
     * @type {string}
     * @memberof PartsTechPart
     */
    tireSize?: string;
    /**
     * 
     * @type {number}
     * @memberof PartsTechPart
     */
    listCost?: number;
}

/**
 * Check if a given object implements the PartsTechPart interface.
 */
export function instanceOfPartsTechPart(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PartsTechPartFromJSON(json: any): PartsTechPart {
    return PartsTechPartFromJSONTyped(json, false);
}

export function PartsTechPartFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartsTechPart {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderItemId': !exists(json, 'orderItemId') ? undefined : json['orderItemId'],
        'supplier': !exists(json, 'supplier') ? undefined : IdNameFromJSON(json['supplier']),
        'partId': !exists(json, 'partId') ? undefined : json['partId'],
        'partNumber': !exists(json, 'partNumber') ? undefined : json['partNumber'],
        'partName': !exists(json, 'partName') ? undefined : json['partName'],
        'partDescription': !exists(json, 'partDescription') ? undefined : json['partDescription'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'brandName': !exists(json, 'brandName') ? undefined : json['brandName'],
        'brandDisplayName': !exists(json, 'brandDisplayName') ? undefined : json['brandDisplayName'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'costPerUnit': !exists(json, 'costPerUnit') ? undefined : json['costPerUnit'],
        'corePerUnit': !exists(json, 'corePerUnit') ? undefined : json['corePerUnit'],
        'isAvailable': !exists(json, 'isAvailable') ? undefined : json['isAvailable'],
        'partTaxonomy': !exists(json, 'partTaxonomy') ? undefined : PartTaxonomyFromJSON(json['partTaxonomy']),
        'tireSize': !exists(json, 'tireSize') ? undefined : json['tireSize'],
        'listCost': !exists(json, 'listCost') ? undefined : json['listCost'],
    };
}

export function PartsTechPartToJSON(value?: PartsTechPart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'orderItemId': value.orderItemId,
        'supplier': IdNameToJSON(value.supplier),
        'partId': value.partId,
        'partNumber': value.partNumber,
        'partName': value.partName,
        'partDescription': value.partDescription,
        'position': value.position,
        'brandName': value.brandName,
        'brandDisplayName': value.brandDisplayName,
        'quantity': value.quantity,
        'costPerUnit': value.costPerUnit,
        'corePerUnit': value.corePerUnit,
        'isAvailable': value.isAvailable,
        'partTaxonomy': PartTaxonomyToJSON(value.partTaxonomy),
        'tireSize': value.tireSize,
        'listCost': value.listCost,
    };
}

