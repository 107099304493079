import 'react-toastify/dist/ReactToastify.css'

import { toast } from 'react-toastify'

import {
  DEFAULT_ERROR_MESSAGE,
  LSCurrentUserAccessToken,
  LSCurrentUserShopId
} from '../../config'
import { ResponseError } from '../../sdk'
import { handleGenericError } from './handleGenericError'
import SdkError from './SdkError'
import { sendBugReport } from './sendBugReport'

export type IntuneApiError = {
  message: string
}

export const handleSdkError = async (
  e: unknown,
  showToast: boolean = true
): Promise<SdkError> => {
  if (!(e instanceof ResponseError)) {
    return new SdkError(handleGenericError(e))
  }

  const response = e.response

  // Seems to not result in an error even with corner cases, so no need for
  // error handling
  const decodedText: string = await response.text()

  if (response.status === 401) {
    localStorage.removeItem(LSCurrentUserAccessToken)
    localStorage.removeItem(LSCurrentUserShopId)
  }

  try {
    const decodedJson = JSON.parse(decodedText)
    if (typeof decodedJson.message === 'string') {
      if (showToast) {
        toast.error(decodedJson.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored'
        })
      }

      return new SdkError(String(decodedJson.message))
    }
    sendBugReport(
      'error',
      'Unexpected error object format in "handleSdkError".',
      `Response body text: ${decodedText}\nStack trace: ${new Error().stack}`
    )
  } catch {
    // This can happen if the API request parameters are badly formatted. For
    // example, if the API input schema calls for a UUID, but an "abc" string is
    // sent instead. When this happens, the jersey framework takes over and
    // returns a 404 not found response, along with an HTML body
    sendBugReport(
      'error',
      'Error decoding JSON in "handleSdkError".',
      `Response body text: ${decodedText}\nStack trace: ${new Error().stack}`
    )
  }

  return new SdkError(DEFAULT_ERROR_MESSAGE)
}
