import { createTheme, responsiveFontSizes } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string
    }
  }
}

let theme = createTheme({
  palette: {
    // type: 'light',
    primary: {
      main: '#302B54',
      light: '#f2f5fc',
      contrastText: '#fff'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.87)'
    },
    divider: 'rgba(0, 0, 0, 0.24)'
  },
  typography: {
    h1: {
      fontWeight: 400,
      fontSize: '2rem',
      lineHeight: 1.2,
      letterSpacing: '0em'
    },
    h2: {
      fontWeight: 400,
      fontSize: '1.5rem',
      lineHeight: 1.2,
      letterSpacing: '0em'
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: 1.2,
      letterSpacing: '0em'
    },
    h4: {
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: 1.5
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.1rem',
      lineHeight: 1.5
    },
    h6: {
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.5
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '0.9rem',
      lineHeight: 1.5
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '0.85rem',
      lineHeight: 1.5
    },
    body1: {
      fontWeight: 400,
      fontSize: '0.9rem',
      lineHeight: 1.5
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.85rem',
      lineHeight: 1.5
    }
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          breakInside: 'avoid'
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: '#b0cef9', // Custom color. Consider adjusting theme colors at some point to include this one.
          '&.Mui-active': {
            color: '#302B54' // primary.main
          },
          '&.Mui-completed': {
            color: '#2e7d32' // success.main
          }
        },
        text: {
          fill: '#302B54', // primary.main
          '.Mui-active > &': {
            fill: '#fff' // primary.contrastText
          }
        }
      }
    }
  }
})
theme = responsiveFontSizes(theme)

export default theme
