/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CapitalOsAccount
 */
export interface CapitalOsAccount {
    /**
     * 
     * @type {string}
     * @memberof CapitalOsAccount
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CapitalOsAccount
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof CapitalOsAccount
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof CapitalOsAccount
     */
    mode?: CapitalOsAccountModeEnum;
    /**
     * 
     * @type {string}
     * @memberof CapitalOsAccount
     */
    origin?: CapitalOsAccountOriginEnum;
    /**
     * 
     * @type {string}
     * @memberof CapitalOsAccount
     */
    status?: CapitalOsAccountStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof CapitalOsAccount
     */
    currentLimitCents?: number;
    /**
     * 
     * @type {number}
     * @memberof CapitalOsAccount
     */
    currentBalanceCents?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CapitalOsAccount
     */
    requirements?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CapitalOsAccount
     */
    externalIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CapitalOsAccount
     */
    depositAmountCents?: number;
}


/**
 * @export
 */
export const CapitalOsAccountModeEnum = {
    Charge: 'CHARGE',
    Deposit: 'DEPOSIT'
} as const;
export type CapitalOsAccountModeEnum = typeof CapitalOsAccountModeEnum[keyof typeof CapitalOsAccountModeEnum];

/**
 * @export
 */
export const CapitalOsAccountOriginEnum = {
    Platform: 'PLATFORM',
    PaymentProcessor: 'PAYMENT_PROCESSOR',
    Finix: 'FINIX'
} as const;
export type CapitalOsAccountOriginEnum = typeof CapitalOsAccountOriginEnum[keyof typeof CapitalOsAccountOriginEnum];

/**
 * @export
 */
export const CapitalOsAccountStatusEnum = {
    Eligible: 'ELIGIBLE',
    Ineligible: 'INELIGIBLE',
    Onboarding: 'ONBOARDING',
    Approved: 'APPROVED',
    Processing: 'PROCESSING',
    Pending: 'PENDING',
    Declined: 'DECLINED'
} as const;
export type CapitalOsAccountStatusEnum = typeof CapitalOsAccountStatusEnum[keyof typeof CapitalOsAccountStatusEnum];


/**
 * Check if a given object implements the CapitalOsAccount interface.
 */
export function instanceOfCapitalOsAccount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CapitalOsAccountFromJSON(json: any): CapitalOsAccount {
    return CapitalOsAccountFromJSONTyped(json, false);
}

export function CapitalOsAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): CapitalOsAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'currentLimitCents': !exists(json, 'currentLimitCents') ? undefined : json['currentLimitCents'],
        'currentBalanceCents': !exists(json, 'currentBalanceCents') ? undefined : json['currentBalanceCents'],
        'requirements': !exists(json, 'requirements') ? undefined : json['requirements'],
        'externalIds': !exists(json, 'externalIds') ? undefined : json['externalIds'],
        'depositAmountCents': !exists(json, 'depositAmountCents') ? undefined : json['depositAmountCents'],
    };
}

export function CapitalOsAccountToJSON(value?: CapitalOsAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'mode': value.mode,
        'origin': value.origin,
        'status': value.status,
        'currentLimitCents': value.currentLimitCents,
        'currentBalanceCents': value.currentBalanceCents,
        'requirements': value.requirements,
        'externalIds': value.externalIds,
        'depositAmountCents': value.depositAmountCents,
    };
}

