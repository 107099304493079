/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShopFeatureInfo } from './ShopFeatureInfo';
import {
    ShopFeatureInfoFromJSON,
    ShopFeatureInfoFromJSONTyped,
    ShopFeatureInfoToJSON,
} from './ShopFeatureInfo';

/**
 * 
 * @export
 * @interface ShopFeatureEntry
 */
export interface ShopFeatureEntry {
    /**
     * 
     * @type {string}
     * @memberof ShopFeatureEntry
     */
    id?: string;
    /**
     * 
     * @type {ShopFeatureInfo}
     * @memberof ShopFeatureEntry
     */
    info?: ShopFeatureInfo;
}

/**
 * Check if a given object implements the ShopFeatureEntry interface.
 */
export function instanceOfShopFeatureEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ShopFeatureEntryFromJSON(json: any): ShopFeatureEntry {
    return ShopFeatureEntryFromJSONTyped(json, false);
}

export function ShopFeatureEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopFeatureEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : ShopFeatureInfoFromJSON(json['info']),
    };
}

export function ShopFeatureEntryToJSON(value?: ShopFeatureEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': ShopFeatureInfoToJSON(value.info),
    };
}

