/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Group } from './Group';
import {
    GroupFromJSON,
    GroupFromJSONTyped,
    GroupToJSON,
} from './Group';
import type { IdName } from './IdName';
import {
    IdNameFromJSON,
    IdNameFromJSONTyped,
    IdNameToJSON,
} from './IdName';

/**
 * 
 * @export
 * @interface System
 */
export interface System {
    /**
     * 
     * @type {IdName}
     * @memberof System
     */
    idName?: IdName;
    /**
     * 
     * @type {{ [key: string]: Group; }}
     * @memberof System
     */
    groups?: { [key: string]: Group; };
}

/**
 * Check if a given object implements the System interface.
 */
export function instanceOfSystem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SystemFromJSON(json: any): System {
    return SystemFromJSONTyped(json, false);
}

export function SystemFromJSONTyped(json: any, ignoreDiscriminator: boolean): System {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idName': !exists(json, 'idName') ? undefined : IdNameFromJSON(json['idName']),
        'groups': !exists(json, 'groups') ? undefined : (mapValues(json['groups'], GroupFromJSON)),
    };
}

export function SystemToJSON(value?: System | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idName': IdNameToJSON(value.idName),
        'groups': value.groups === undefined ? undefined : (mapValues(value.groups, GroupToJSON)),
    };
}

