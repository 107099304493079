/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EntryEntry } from './EntryEntry';
import {
    EntryEntryFromJSON,
    EntryEntryFromJSONTyped,
    EntryEntryToJSON,
} from './EntryEntry';

/**
 * 
 * @export
 * @interface EntryEntryOfInspection
 */
export interface EntryEntryOfInspection {
    /**
     * 
     * @type {string}
     * @memberof EntryEntryOfInspection
     */
    inspection?: string;
    /**
     * 
     * @type {EntryEntry}
     * @memberof EntryEntryOfInspection
     */
    entry?: EntryEntry;
}

/**
 * Check if a given object implements the EntryEntryOfInspection interface.
 */
export function instanceOfEntryEntryOfInspection(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EntryEntryOfInspectionFromJSON(json: any): EntryEntryOfInspection {
    return EntryEntryOfInspectionFromJSONTyped(json, false);
}

export function EntryEntryOfInspectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntryEntryOfInspection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspection': !exists(json, 'inspection') ? undefined : json['inspection'],
        'entry': !exists(json, 'entry') ? undefined : EntryEntryFromJSON(json['entry']),
    };
}

export function EntryEntryOfInspectionToJSON(value?: EntryEntryOfInspection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspection': value.inspection,
        'entry': EntryEntryToJSON(value.entry),
    };
}

