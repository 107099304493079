/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EntryEntry } from './EntryEntry';
import {
    EntryEntryFromJSON,
    EntryEntryFromJSONTyped,
    EntryEntryToJSON,
} from './EntryEntry';
import type { InspectionAnswerToCannedJobEntry } from './InspectionAnswerToCannedJobEntry';
import {
    InspectionAnswerToCannedJobEntryFromJSON,
    InspectionAnswerToCannedJobEntryFromJSONTyped,
    InspectionAnswerToCannedJobEntryToJSON,
} from './InspectionAnswerToCannedJobEntry';
import type { InspectionEntryStatusEntry } from './InspectionEntryStatusEntry';
import {
    InspectionEntryStatusEntryFromJSON,
    InspectionEntryStatusEntryFromJSONTyped,
    InspectionEntryStatusEntryToJSON,
} from './InspectionEntryStatusEntry';
import type { InspectionMediaEntry } from './InspectionMediaEntry';
import {
    InspectionMediaEntryFromJSON,
    InspectionMediaEntryFromJSONTyped,
    InspectionMediaEntryToJSON,
} from './InspectionMediaEntry';
import type { MultipleChoiceAnswerEntry } from './MultipleChoiceAnswerEntry';
import {
    MultipleChoiceAnswerEntryFromJSON,
    MultipleChoiceAnswerEntryFromJSONTyped,
    MultipleChoiceAnswerEntryToJSON,
} from './MultipleChoiceAnswerEntry';
import type { PromptEntry } from './PromptEntry';
import {
    PromptEntryFromJSON,
    PromptEntryFromJSONTyped,
    PromptEntryToJSON,
} from './PromptEntry';

/**
 * 
 * @export
 * @interface EntryExpanded
 */
export interface EntryExpanded {
    /**
     * 
     * @type {EntryEntry}
     * @memberof EntryExpanded
     */
    entry?: EntryEntry;
    /**
     * 
     * @type {Array<PromptEntry>}
     * @memberof EntryExpanded
     */
    prompts?: Array<PromptEntry>;
    /**
     * 
     * @type {Array<MultipleChoiceAnswerEntry>}
     * @memberof EntryExpanded
     */
    multipleChoiceAnswers?: Array<MultipleChoiceAnswerEntry>;
    /**
     * 
     * @type {Array<InspectionAnswerToCannedJobEntry>}
     * @memberof EntryExpanded
     */
    answerToCannedJobs?: Array<InspectionAnswerToCannedJobEntry>;
    /**
     * 
     * @type {Array<InspectionMediaEntry>}
     * @memberof EntryExpanded
     */
    media?: Array<InspectionMediaEntry>;
    /**
     * 
     * @type {Array<InspectionEntryStatusEntry>}
     * @memberof EntryExpanded
     */
    entryStatuses?: Array<InspectionEntryStatusEntry>;
}

/**
 * Check if a given object implements the EntryExpanded interface.
 */
export function instanceOfEntryExpanded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EntryExpandedFromJSON(json: any): EntryExpanded {
    return EntryExpandedFromJSONTyped(json, false);
}

export function EntryExpandedFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntryExpanded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entry': !exists(json, 'entry') ? undefined : EntryEntryFromJSON(json['entry']),
        'prompts': !exists(json, 'prompts') ? undefined : ((json['prompts'] as Array<any>).map(PromptEntryFromJSON)),
        'multipleChoiceAnswers': !exists(json, 'multipleChoiceAnswers') ? undefined : ((json['multipleChoiceAnswers'] as Array<any>).map(MultipleChoiceAnswerEntryFromJSON)),
        'answerToCannedJobs': !exists(json, 'answerToCannedJobs') ? undefined : ((json['answerToCannedJobs'] as Array<any>).map(InspectionAnswerToCannedJobEntryFromJSON)),
        'media': !exists(json, 'media') ? undefined : ((json['media'] as Array<any>).map(InspectionMediaEntryFromJSON)),
        'entryStatuses': !exists(json, 'entryStatuses') ? undefined : ((json['entryStatuses'] as Array<any>).map(InspectionEntryStatusEntryFromJSON)),
    };
}

export function EntryExpandedToJSON(value?: EntryExpanded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entry': EntryEntryToJSON(value.entry),
        'prompts': value.prompts === undefined ? undefined : ((value.prompts as Array<any>).map(PromptEntryToJSON)),
        'multipleChoiceAnswers': value.multipleChoiceAnswers === undefined ? undefined : ((value.multipleChoiceAnswers as Array<any>).map(MultipleChoiceAnswerEntryToJSON)),
        'answerToCannedJobs': value.answerToCannedJobs === undefined ? undefined : ((value.answerToCannedJobs as Array<any>).map(InspectionAnswerToCannedJobEntryToJSON)),
        'media': value.media === undefined ? undefined : ((value.media as Array<any>).map(InspectionMediaEntryToJSON)),
        'entryStatuses': value.entryStatuses === undefined ? undefined : ((value.entryStatuses as Array<any>).map(InspectionEntryStatusEntryToJSON)),
    };
}

