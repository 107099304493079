/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerPortalInfo
 */
export interface CustomerPortalInfo {
    /**
     * 
     * @type {boolean}
     * @memberof CustomerPortalInfo
     */
    showPrice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerPortalInfo
     */
    showPartsLabor?: boolean;
}

/**
 * Check if a given object implements the CustomerPortalInfo interface.
 */
export function instanceOfCustomerPortalInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerPortalInfoFromJSON(json: any): CustomerPortalInfo {
    return CustomerPortalInfoFromJSONTyped(json, false);
}

export function CustomerPortalInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerPortalInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'showPrice': !exists(json, 'showPrice') ? undefined : json['showPrice'],
        'showPartsLabor': !exists(json, 'showPartsLabor') ? undefined : json['showPartsLabor'],
    };
}

export function CustomerPortalInfoToJSON(value?: CustomerPortalInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'showPrice': value.showPrice,
        'showPartsLabor': value.showPartsLabor,
    };
}

