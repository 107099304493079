/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VehicleInformation } from './VehicleInformation';
import {
    VehicleInformationFromJSON,
    VehicleInformationFromJSONTyped,
    VehicleInformationToJSON,
} from './VehicleInformation';

/**
 * 
 * @export
 * @interface NewVehicleRequest
 */
export interface NewVehicleRequest {
    /**
     * 
     * @type {string}
     * @memberof NewVehicleRequest
     */
    customer?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVehicleRequest
     */
    shop?: string;
    /**
     * 
     * @type {VehicleInformation}
     * @memberof NewVehicleRequest
     */
    info?: VehicleInformation;
    /**
     * 
     * @type {boolean}
     * @memberof NewVehicleRequest
     */
    updateVCDB?: boolean;
}

/**
 * Check if a given object implements the NewVehicleRequest interface.
 */
export function instanceOfNewVehicleRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NewVehicleRequestFromJSON(json: any): NewVehicleRequest {
    return NewVehicleRequestFromJSONTyped(json, false);
}

export function NewVehicleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewVehicleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'info': !exists(json, 'info') ? undefined : VehicleInformationFromJSON(json['info']),
        'updateVCDB': !exists(json, 'updateVCDB') ? undefined : json['updateVCDB'],
    };
}

export function NewVehicleRequestToJSON(value?: NewVehicleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customer': value.customer,
        'shop': value.shop,
        'info': VehicleInformationToJSON(value.info),
        'updateVCDB': value.updateVCDB,
    };
}

