/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaxesAndFees
 */
export interface TaxesAndFees {
    /**
     * 
     * @type {string}
     * @memberof TaxesAndFees
     */
    shop?: string;
    /**
     * 
     * @type {number}
     * @memberof TaxesAndFees
     */
    partsTaxPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxesAndFees
     */
    laborTaxPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxesAndFees
     */
    subcontractTaxPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxesAndFees
     */
    feeTaxPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxesAndFees
     */
    suppliesFeePercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxesAndFees
     */
    suppliesFeeSubtotalCap?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TaxesAndFees
     */
    suppliesFeeIncludeParts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TaxesAndFees
     */
    suppliesFeeIncludeLabor?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TaxesAndFees
     */
    hazmatFeePercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxesAndFees
     */
    hazmatFeeSubtotalCap?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TaxesAndFees
     */
    hazmatFeeIncludeParts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TaxesAndFees
     */
    hazmatFeeIncludeLabor?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TaxesAndFees
     */
    counterSalesPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof TaxesAndFees
     */
    surchargeFeePercentage?: number;
}

/**
 * Check if a given object implements the TaxesAndFees interface.
 */
export function instanceOfTaxesAndFees(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TaxesAndFeesFromJSON(json: any): TaxesAndFees {
    return TaxesAndFeesFromJSONTyped(json, false);
}

export function TaxesAndFeesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaxesAndFees {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'partsTaxPercentage': !exists(json, 'partsTaxPercentage') ? undefined : json['partsTaxPercentage'],
        'laborTaxPercentage': !exists(json, 'laborTaxPercentage') ? undefined : json['laborTaxPercentage'],
        'subcontractTaxPercentage': !exists(json, 'subcontractTaxPercentage') ? undefined : json['subcontractTaxPercentage'],
        'feeTaxPercentage': !exists(json, 'feeTaxPercentage') ? undefined : json['feeTaxPercentage'],
        'suppliesFeePercentage': !exists(json, 'suppliesFeePercentage') ? undefined : json['suppliesFeePercentage'],
        'suppliesFeeSubtotalCap': !exists(json, 'suppliesFeeSubtotalCap') ? undefined : json['suppliesFeeSubtotalCap'],
        'suppliesFeeIncludeParts': !exists(json, 'suppliesFeeIncludeParts') ? undefined : json['suppliesFeeIncludeParts'],
        'suppliesFeeIncludeLabor': !exists(json, 'suppliesFeeIncludeLabor') ? undefined : json['suppliesFeeIncludeLabor'],
        'hazmatFeePercentage': !exists(json, 'hazmatFeePercentage') ? undefined : json['hazmatFeePercentage'],
        'hazmatFeeSubtotalCap': !exists(json, 'hazmatFeeSubtotalCap') ? undefined : json['hazmatFeeSubtotalCap'],
        'hazmatFeeIncludeParts': !exists(json, 'hazmatFeeIncludeParts') ? undefined : json['hazmatFeeIncludeParts'],
        'hazmatFeeIncludeLabor': !exists(json, 'hazmatFeeIncludeLabor') ? undefined : json['hazmatFeeIncludeLabor'],
        'counterSalesPercentage': !exists(json, 'counterSalesPercentage') ? undefined : json['counterSalesPercentage'],
        'surchargeFeePercentage': !exists(json, 'surchargeFeePercentage') ? undefined : json['surchargeFeePercentage'],
    };
}

export function TaxesAndFeesToJSON(value?: TaxesAndFees | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'partsTaxPercentage': value.partsTaxPercentage,
        'laborTaxPercentage': value.laborTaxPercentage,
        'subcontractTaxPercentage': value.subcontractTaxPercentage,
        'feeTaxPercentage': value.feeTaxPercentage,
        'suppliesFeePercentage': value.suppliesFeePercentage,
        'suppliesFeeSubtotalCap': value.suppliesFeeSubtotalCap,
        'suppliesFeeIncludeParts': value.suppliesFeeIncludeParts,
        'suppliesFeeIncludeLabor': value.suppliesFeeIncludeLabor,
        'hazmatFeePercentage': value.hazmatFeePercentage,
        'hazmatFeeSubtotalCap': value.hazmatFeeSubtotalCap,
        'hazmatFeeIncludeParts': value.hazmatFeeIncludeParts,
        'hazmatFeeIncludeLabor': value.hazmatFeeIncludeLabor,
        'counterSalesPercentage': value.counterSalesPercentage,
        'surchargeFeePercentage': value.surchargeFeePercentage,
    };
}

