/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResourceInfo } from './ResourceInfo';
import {
    ResourceInfoFromJSON,
    ResourceInfoFromJSONTyped,
    ResourceInfoToJSON,
} from './ResourceInfo';

/**
 * 
 * @export
 * @interface ResourceEntry
 */
export interface ResourceEntry {
    /**
     * 
     * @type {string}
     * @memberof ResourceEntry
     */
    id?: string;
    /**
     * 
     * @type {ResourceInfo}
     * @memberof ResourceEntry
     */
    info?: ResourceInfo;
}

/**
 * Check if a given object implements the ResourceEntry interface.
 */
export function instanceOfResourceEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResourceEntryFromJSON(json: any): ResourceEntry {
    return ResourceEntryFromJSONTyped(json, false);
}

export function ResourceEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResourceEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : ResourceInfoFromJSON(json['info']),
    };
}

export function ResourceEntryToJSON(value?: ResourceEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': ResourceInfoToJSON(value.info),
    };
}

