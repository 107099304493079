/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateMileageRequest
 */
export interface UpdateMileageRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMileageRequest
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMileageRequest
     */
    vehicle?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMileageRequest
     */
    repairOrder?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateMileageRequest
     */
    mileage?: number;
}

/**
 * Check if a given object implements the UpdateMileageRequest interface.
 */
export function instanceOfUpdateMileageRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateMileageRequestFromJSON(json: any): UpdateMileageRequest {
    return UpdateMileageRequestFromJSONTyped(json, false);
}

export function UpdateMileageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMileageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'vehicle': !exists(json, 'vehicle') ? undefined : json['vehicle'],
        'repairOrder': !exists(json, 'repairOrder') ? undefined : json['repairOrder'],
        'mileage': !exists(json, 'mileage') ? undefined : json['mileage'],
    };
}

export function UpdateMileageRequestToJSON(value?: UpdateMileageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'vehicle': value.vehicle,
        'repairOrder': value.repairOrder,
        'mileage': value.mileage,
    };
}

