/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Rrule
 */
export interface Rrule {
    /**
     * 
     * @type {Date}
     * @memberof Rrule
     */
    dtstart?: Date;
    /**
     * 
     * @type {string}
     * @memberof Rrule
     */
    freq?: RruleFreqEnum;
    /**
     * 
     * @type {Date}
     * @memberof Rrule
     */
    until?: Date;
    /**
     * 
     * @type {number}
     * @memberof Rrule
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof Rrule
     */
    interval?: number;
    /**
     * 
     * @type {number}
     * @memberof Rrule
     */
    byminute?: number;
    /**
     * 
     * @type {number}
     * @memberof Rrule
     */
    byhour?: number;
    /**
     * 
     * @type {string}
     * @memberof Rrule
     */
    byday?: RruleBydayEnum;
    /**
     * 
     * @type {number}
     * @memberof Rrule
     */
    bymonthday?: number;
    /**
     * 
     * @type {number}
     * @memberof Rrule
     */
    byyearday?: number;
    /**
     * 
     * @type {number}
     * @memberof Rrule
     */
    byweekno?: number;
    /**
     * 
     * @type {number}
     * @memberof Rrule
     */
    bymonth?: number;
    /**
     * 
     * @type {number}
     * @memberof Rrule
     */
    bysetpos?: number;
    /**
     * 
     * @type {Date}
     * @memberof Rrule
     */
    rdate?: Date;
}


/**
 * @export
 */
export const RruleFreqEnum = {
    Secondly: 'SECONDLY',
    Minutely: 'MINUTELY',
    Hourly: 'HOURLY',
    Daily: 'DAILY',
    Weekly: 'WEEKLY',
    Monthly: 'MONTHLY',
    Yearly: 'YEARLY'
} as const;
export type RruleFreqEnum = typeof RruleFreqEnum[keyof typeof RruleFreqEnum];

/**
 * @export
 */
export const RruleBydayEnum = {
    Su: 'SU',
    Mo: 'MO',
    Tu: 'TU',
    We: 'WE',
    Th: 'TH',
    Fr: 'FR',
    Sa: 'SA',
    _1Su: '_1SU',
    _1Mo: '_1MO',
    _1Tu: '_1TU',
    _1We: '_1WE',
    _1Th: '_1TH',
    _1Fr: '_1FR',
    _1Sa: '_1SA',
    _2Su: '_2SU',
    _2Mo: '_2MO',
    _2Tu: '_2TU',
    _2We: '_2WE',
    _2Th: '_2TH',
    _2Fr: '_2FR',
    _2Sa: '_2SA',
    _3Su: '_3SU',
    _3Mo: '_3MO',
    _3Tu: '_3TU',
    _3We: '_3WE',
    _3Th: '_3TH',
    _3Fr: '_3FR',
    _3Sa: '_3SA',
    _4Su: '_4SU',
    _4Mo: '_4MO',
    _4Tu: '_4TU',
    _4We: '_4WE',
    _4Th: '_4TH',
    _4Fr: '_4FR',
    _4Sa: '_4SA',
    _5Su: '_5SU',
    _5Mo: '_5MO',
    _5Tu: '_5TU',
    _5We: '_5WE',
    _5Th: '_5TH',
    _5Fr: '_5FR',
    _5Sa: '_5SA'
} as const;
export type RruleBydayEnum = typeof RruleBydayEnum[keyof typeof RruleBydayEnum];


/**
 * Check if a given object implements the Rrule interface.
 */
export function instanceOfRrule(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RruleFromJSON(json: any): Rrule {
    return RruleFromJSONTyped(json, false);
}

export function RruleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Rrule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dtstart': !exists(json, 'dtstart') ? undefined : (new Date(json['dtstart'])),
        'freq': !exists(json, 'freq') ? undefined : json['freq'],
        'until': !exists(json, 'until') ? undefined : (new Date(json['until'])),
        'count': !exists(json, 'count') ? undefined : json['count'],
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
        'byminute': !exists(json, 'byminute') ? undefined : json['byminute'],
        'byhour': !exists(json, 'byhour') ? undefined : json['byhour'],
        'byday': !exists(json, 'byday') ? undefined : json['byday'],
        'bymonthday': !exists(json, 'bymonthday') ? undefined : json['bymonthday'],
        'byyearday': !exists(json, 'byyearday') ? undefined : json['byyearday'],
        'byweekno': !exists(json, 'byweekno') ? undefined : json['byweekno'],
        'bymonth': !exists(json, 'bymonth') ? undefined : json['bymonth'],
        'bysetpos': !exists(json, 'bysetpos') ? undefined : json['bysetpos'],
        'rdate': !exists(json, 'rdate') ? undefined : (new Date(json['rdate'])),
    };
}

export function RruleToJSON(value?: Rrule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dtstart': value.dtstart === undefined ? undefined : (value.dtstart.toISOString()),
        'freq': value.freq,
        'until': value.until === undefined ? undefined : (value.until.toISOString()),
        'count': value.count,
        'interval': value.interval,
        'byminute': value.byminute,
        'byhour': value.byhour,
        'byday': value.byday,
        'bymonthday': value.bymonthday,
        'byyearday': value.byyearday,
        'byweekno': value.byweekno,
        'bymonth': value.bymonth,
        'bysetpos': value.bysetpos,
        'rdate': value.rdate === undefined ? undefined : (value.rdate.toISOString()),
    };
}

