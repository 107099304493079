/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendFeedbackLinkRequest
 */
export interface SendFeedbackLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof SendFeedbackLinkRequest
     */
    repairOrderId?: string;
    /**
     * 
     * @type {Date}
     * @memberof SendFeedbackLinkRequest
     */
    dateTime?: Date;
}

/**
 * Check if a given object implements the SendFeedbackLinkRequest interface.
 */
export function instanceOfSendFeedbackLinkRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SendFeedbackLinkRequestFromJSON(json: any): SendFeedbackLinkRequest {
    return SendFeedbackLinkRequestFromJSONTyped(json, false);
}

export function SendFeedbackLinkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendFeedbackLinkRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'repairOrderId': !exists(json, 'repairOrderId') ? undefined : json['repairOrderId'],
        'dateTime': !exists(json, 'dateTime') ? undefined : (new Date(json['dateTime'])),
    };
}

export function SendFeedbackLinkRequestToJSON(value?: SendFeedbackLinkRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'repairOrderId': value.repairOrderId,
        'dateTime': value.dateTime === undefined ? undefined : (value.dateTime.toISOString()),
    };
}

