import { ServiceExpanded } from '../sdk'

// Transform an input that is typed as a Date at compile-time but is actually
// a different format at run-time. The run-time type of the input must be one
// that can be taken by the Date constructor.
export function transformDate(date: Date | undefined): Date | undefined {
  return date ? new Date(date) : undefined
}

// Transform date strings into actual Dates
export const transformServiceExpandedArray: (
  response: ServiceExpanded[]
) => ServiceExpanded[] = (response) => {
  response.forEach((serviceExpanded) => {
    const overrideToInv = serviceExpanded.partOverrideToLinkIdInventory!
    Object.keys(overrideToInv).forEach((poId) => {
      const linkToInv = overrideToInv[poId]
      Object.keys(linkToInv).forEach((linkId) => {
        const invInfo = linkToInv[linkId].info!
        invInfo.ordered = transformDate(invInfo.ordered)
        invInfo.received = transformDate(invInfo.received)
        invInfo.used = transformDate(invInfo.used)
        invInfo.returned = transformDate(invInfo.returned)
        invInfo.lost = transformDate(invInfo.lost)
        invInfo.corePaymentReceived = transformDate(invInfo.corePaymentReceived)
        invInfo.markedForReturn = transformDate(invInfo.markedForReturn)
      })
    })
  })
  return response
}
