/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationInfo } from './NotificationInfo';
import {
    NotificationInfoFromJSON,
    NotificationInfoFromJSONTyped,
    NotificationInfoToJSON,
} from './NotificationInfo';

/**
 * 
 * @export
 * @interface NotificationEntry
 */
export interface NotificationEntry {
    /**
     * 
     * @type {string}
     * @memberof NotificationEntry
     */
    id?: string;
    /**
     * 
     * @type {NotificationInfo}
     * @memberof NotificationEntry
     */
    info?: NotificationInfo;
    /**
     * 
     * @type {string}
     * @memberof NotificationEntry
     */
    recipient?: string;
}

/**
 * Check if a given object implements the NotificationEntry interface.
 */
export function instanceOfNotificationEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationEntryFromJSON(json: any): NotificationEntry {
    return NotificationEntryFromJSONTyped(json, false);
}

export function NotificationEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : NotificationInfoFromJSON(json['info']),
        'recipient': !exists(json, 'recipient') ? undefined : json['recipient'],
    };
}

export function NotificationEntryToJSON(value?: NotificationEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': NotificationInfoToJSON(value.info),
        'recipient': value.recipient,
    };
}

