/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReorderRepairOrderStatusesRequest
 */
export interface ReorderRepairOrderStatusesRequest {
    /**
     * 
     * @type {string}
     * @memberof ReorderRepairOrderStatusesRequest
     */
    shop?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReorderRepairOrderStatusesRequest
     */
    statuses?: Array<string>;
}

/**
 * Check if a given object implements the ReorderRepairOrderStatusesRequest interface.
 */
export function instanceOfReorderRepairOrderStatusesRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ReorderRepairOrderStatusesRequestFromJSON(json: any): ReorderRepairOrderStatusesRequest {
    return ReorderRepairOrderStatusesRequestFromJSONTyped(json, false);
}

export function ReorderRepairOrderStatusesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReorderRepairOrderStatusesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'statuses': !exists(json, 'statuses') ? undefined : json['statuses'],
    };
}

export function ReorderRepairOrderStatusesRequestToJSON(value?: ReorderRepairOrderStatusesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'statuses': value.statuses,
    };
}

