/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InspectionAnswerToCannedJobInfo
 */
export interface InspectionAnswerToCannedJobInfo {
    /**
     * 
     * @type {string}
     * @memberof InspectionAnswerToCannedJobInfo
     */
    inspectionAnswer?: string;
    /**
     * 
     * @type {string}
     * @memberof InspectionAnswerToCannedJobInfo
     */
    cannedJob?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InspectionAnswerToCannedJobInfo
     */
    selected?: boolean;
}

/**
 * Check if a given object implements the InspectionAnswerToCannedJobInfo interface.
 */
export function instanceOfInspectionAnswerToCannedJobInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InspectionAnswerToCannedJobInfoFromJSON(json: any): InspectionAnswerToCannedJobInfo {
    return InspectionAnswerToCannedJobInfoFromJSONTyped(json, false);
}

export function InspectionAnswerToCannedJobInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionAnswerToCannedJobInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspectionAnswer': !exists(json, 'inspectionAnswer') ? undefined : json['inspectionAnswer'],
        'cannedJob': !exists(json, 'cannedJob') ? undefined : json['cannedJob'],
        'selected': !exists(json, 'selected') ? undefined : json['selected'],
    };
}

export function InspectionAnswerToCannedJobInfoToJSON(value?: InspectionAnswerToCannedJobInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspectionAnswer': value.inspectionAnswer,
        'cannedJob': value.cannedJob,
        'selected': value.selected,
    };
}

