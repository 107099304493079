/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IdName } from './IdName';
import {
    IdNameFromJSON,
    IdNameFromJSONTyped,
    IdNameToJSON,
} from './IdName';

/**
 * 
 * @export
 * @interface Subcategory
 */
export interface Subcategory {
    /**
     * 
     * @type {IdName}
     * @memberof Subcategory
     */
    idName?: IdName;
    /**
     * 
     * @type {{ [key: string]: IdName; }}
     * @memberof Subcategory
     */
    partTypes?: { [key: string]: IdName; };
}

/**
 * Check if a given object implements the Subcategory interface.
 */
export function instanceOfSubcategory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SubcategoryFromJSON(json: any): Subcategory {
    return SubcategoryFromJSONTyped(json, false);
}

export function SubcategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Subcategory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idName': !exists(json, 'idName') ? undefined : IdNameFromJSON(json['idName']),
        'partTypes': !exists(json, 'partTypes') ? undefined : (mapValues(json['partTypes'], IdNameFromJSON)),
    };
}

export function SubcategoryToJSON(value?: Subcategory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idName': IdNameToJSON(value.idName),
        'partTypes': value.partTypes === undefined ? undefined : (mapValues(value.partTypes, IdNameToJSON)),
    };
}

