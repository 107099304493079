/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WarrantyInfo } from './WarrantyInfo';
import {
    WarrantyInfoFromJSON,
    WarrantyInfoFromJSONTyped,
    WarrantyInfoToJSON,
} from './WarrantyInfo';

/**
 * 
 * @export
 * @interface WarrantyEntry
 */
export interface WarrantyEntry {
    /**
     * 
     * @type {string}
     * @memberof WarrantyEntry
     */
    id?: string;
    /**
     * 
     * @type {WarrantyInfo}
     * @memberof WarrantyEntry
     */
    info?: WarrantyInfo;
    /**
     * 
     * @type {Date}
     * @memberof WarrantyEntry
     */
    createdAt?: Date;
}

/**
 * Check if a given object implements the WarrantyEntry interface.
 */
export function instanceOfWarrantyEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WarrantyEntryFromJSON(json: any): WarrantyEntry {
    return WarrantyEntryFromJSONTyped(json, false);
}

export function WarrantyEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): WarrantyEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : WarrantyInfoFromJSON(json['info']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}

export function WarrantyEntryToJSON(value?: WarrantyEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': WarrantyInfoToJSON(value.info),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
    };
}

