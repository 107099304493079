/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CopyInspectionRequest
 */
export interface CopyInspectionRequest {
    /**
     * 
     * @type {string}
     * @memberof CopyInspectionRequest
     */
    inspection?: string;
    /**
     * 
     * @type {string}
     * @memberof CopyInspectionRequest
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof CopyInspectionRequest
     */
    tech?: string;
    /**
     * 
     * @type {string}
     * @memberof CopyInspectionRequest
     */
    repairOrder?: string;
    /**
     * 
     * @type {string}
     * @memberof CopyInspectionRequest
     */
    notes?: string;
}

/**
 * Check if a given object implements the CopyInspectionRequest interface.
 */
export function instanceOfCopyInspectionRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CopyInspectionRequestFromJSON(json: any): CopyInspectionRequest {
    return CopyInspectionRequestFromJSONTyped(json, false);
}

export function CopyInspectionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopyInspectionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspection': !exists(json, 'inspection') ? undefined : json['inspection'],
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'tech': !exists(json, 'tech') ? undefined : json['tech'],
        'repairOrder': !exists(json, 'repairOrder') ? undefined : json['repairOrder'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function CopyInspectionRequestToJSON(value?: CopyInspectionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspection': value.inspection,
        'shop': value.shop,
        'tech': value.tech,
        'repairOrder': value.repairOrder,
        'notes': value.notes,
    };
}

