/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomFeeInfo
 */
export interface CustomFeeInfo {
    /**
     * 
     * @type {string}
     * @memberof CustomFeeInfo
     */
    service?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomFeeInfo
     */
    counterSale?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomFeeInfo
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomFeeInfo
     */
    numDollars?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomFeeInfo
     */
    taxable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomFeeInfo
     */
    override?: string;
}

/**
 * Check if a given object implements the CustomFeeInfo interface.
 */
export function instanceOfCustomFeeInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomFeeInfoFromJSON(json: any): CustomFeeInfo {
    return CustomFeeInfoFromJSONTyped(json, false);
}

export function CustomFeeInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFeeInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'service': !exists(json, 'service') ? undefined : json['service'],
        'counterSale': !exists(json, 'counterSale') ? undefined : json['counterSale'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'numDollars': !exists(json, 'numDollars') ? undefined : json['numDollars'],
        'taxable': !exists(json, 'taxable') ? undefined : json['taxable'],
        'override': !exists(json, 'override') ? undefined : json['override'],
    };
}

export function CustomFeeInfoToJSON(value?: CustomFeeInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service': value.service,
        'counterSale': value.counterSale,
        'name': value.name,
        'numDollars': value.numDollars,
        'taxable': value.taxable,
        'override': value.override,
    };
}

