/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { System } from './System';
import {
    SystemFromJSON,
    SystemFromJSONTyped,
    SystemToJSON,
} from './System';

/**
 * 
 * @export
 * @interface MotorTaxonomy
 */
export interface MotorTaxonomy {
    /**
     * 
     * @type {{ [key: string]: System; }}
     * @memberof MotorTaxonomy
     */
    systems?: { [key: string]: System; };
}

/**
 * Check if a given object implements the MotorTaxonomy interface.
 */
export function instanceOfMotorTaxonomy(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MotorTaxonomyFromJSON(json: any): MotorTaxonomy {
    return MotorTaxonomyFromJSONTyped(json, false);
}

export function MotorTaxonomyFromJSONTyped(json: any, ignoreDiscriminator: boolean): MotorTaxonomy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'systems': !exists(json, 'systems') ? undefined : (mapValues(json['systems'], SystemFromJSON)),
    };
}

export function MotorTaxonomyToJSON(value?: MotorTaxonomy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'systems': value.systems === undefined ? undefined : (mapValues(value.systems, SystemToJSON)),
    };
}

