/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomerAdditionalEmailAddressInfo } from './CustomerAdditionalEmailAddressInfo';
import {
    CustomerAdditionalEmailAddressInfoFromJSON,
    CustomerAdditionalEmailAddressInfoFromJSONTyped,
    CustomerAdditionalEmailAddressInfoToJSON,
} from './CustomerAdditionalEmailAddressInfo';

/**
 * 
 * @export
 * @interface CustomerAdditionalEmailAddressEntry
 */
export interface CustomerAdditionalEmailAddressEntry {
    /**
     * 
     * @type {string}
     * @memberof CustomerAdditionalEmailAddressEntry
     */
    id?: string;
    /**
     * 
     * @type {CustomerAdditionalEmailAddressInfo}
     * @memberof CustomerAdditionalEmailAddressEntry
     */
    info?: CustomerAdditionalEmailAddressInfo;
}

/**
 * Check if a given object implements the CustomerAdditionalEmailAddressEntry interface.
 */
export function instanceOfCustomerAdditionalEmailAddressEntry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomerAdditionalEmailAddressEntryFromJSON(json: any): CustomerAdditionalEmailAddressEntry {
    return CustomerAdditionalEmailAddressEntryFromJSONTyped(json, false);
}

export function CustomerAdditionalEmailAddressEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerAdditionalEmailAddressEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'info': !exists(json, 'info') ? undefined : CustomerAdditionalEmailAddressInfoFromJSON(json['info']),
    };
}

export function CustomerAdditionalEmailAddressEntryToJSON(value?: CustomerAdditionalEmailAddressEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'info': CustomerAdditionalEmailAddressInfoToJSON(value.info),
    };
}

