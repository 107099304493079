/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentRequestCardPresent
 */
export interface PaymentRequestCardPresent {
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestCardPresent
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestCardPresent
     */
    repairOrder?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestCardPresent
     */
    counterSales?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequestCardPresent
     */
    device?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentRequestCardPresent
     */
    amountCents?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentRequestCardPresent
     */
    surchargeCents?: number;
}

/**
 * Check if a given object implements the PaymentRequestCardPresent interface.
 */
export function instanceOfPaymentRequestCardPresent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaymentRequestCardPresentFromJSON(json: any): PaymentRequestCardPresent {
    return PaymentRequestCardPresentFromJSONTyped(json, false);
}

export function PaymentRequestCardPresentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentRequestCardPresent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'repairOrder': !exists(json, 'repairOrder') ? undefined : json['repairOrder'],
        'counterSales': !exists(json, 'counterSales') ? undefined : json['counterSales'],
        'device': !exists(json, 'device') ? undefined : json['device'],
        'amountCents': !exists(json, 'amountCents') ? undefined : json['amountCents'],
        'surchargeCents': !exists(json, 'surchargeCents') ? undefined : json['surchargeCents'],
    };
}

export function PaymentRequestCardPresentToJSON(value?: PaymentRequestCardPresent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'repairOrder': value.repairOrder,
        'counterSales': value.counterSales,
        'device': value.device,
        'amountCents': value.amountCents,
        'surchargeCents': value.surchargeCents,
    };
}

