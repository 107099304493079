/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SerializableExpenseField } from './SerializableExpenseField';
import {
    SerializableExpenseFieldFromJSON,
    SerializableExpenseFieldFromJSONTyped,
    SerializableExpenseFieldToJSON,
} from './SerializableExpenseField';

/**
 * 
 * @export
 * @interface SerializableLineItemGroup
 */
export interface SerializableLineItemGroup {
    /**
     * 
     * @type {number}
     * @memberof SerializableLineItemGroup
     */
    lineItemGroupIndex?: number;
    /**
     * 
     * @type {Array<Array<SerializableExpenseField>>}
     * @memberof SerializableLineItemGroup
     */
    lineItemFields?: Array<Array<SerializableExpenseField>>;
}

/**
 * Check if a given object implements the SerializableLineItemGroup interface.
 */
export function instanceOfSerializableLineItemGroup(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SerializableLineItemGroupFromJSON(json: any): SerializableLineItemGroup {
    return SerializableLineItemGroupFromJSONTyped(json, false);
}

export function SerializableLineItemGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerializableLineItemGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lineItemGroupIndex': !exists(json, 'lineItemGroupIndex') ? undefined : json['lineItemGroupIndex'],
        'lineItemFields': !exists(json, 'lineItemFields') ? undefined : json['lineItemFields'],
    };
}

export function SerializableLineItemGroupToJSON(value?: SerializableLineItemGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lineItemGroupIndex': value.lineItemGroupIndex,
        'lineItemFields': value.lineItemFields,
    };
}

