/* tslint:disable */
/* eslint-disable */
/**
 * Main InTune API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PartTaxonomy } from './PartTaxonomy';
import {
    PartTaxonomyFromJSON,
    PartTaxonomyFromJSONTyped,
    PartTaxonomyToJSON,
} from './PartTaxonomy';

/**
 * 
 * @export
 * @interface InventoryPartInfo
 */
export interface InventoryPartInfo {
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInfo
     */
    shop?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInfo
     */
    partsTechPartId?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInfo
     */
    repairLinkPartId?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInfo
     */
    partNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInfo
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInfo
     */
    brandDisplayName?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInfo
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInfo
     */
    position?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInfo
     */
    size?: string;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInfo
     */
    location?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryPartInfo
     */
    stocked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InventoryPartInfo
     */
    isFluids?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InventoryPartInfo
     */
    suggestedPricePerUnit?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryPartInfo
     */
    listCost?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInfo
     */
    unitOfMeasure?: InventoryPartInfoUnitOfMeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof InventoryPartInfo
     */
    partsMatrix?: string;
    /**
     * 
     * @type {PartTaxonomy}
     * @memberof InventoryPartInfo
     */
    partTaxonomy?: PartTaxonomy;
    /**
     * 
     * @type {Date}
     * @memberof InventoryPartInfo
     */
    archived?: Date;
}


/**
 * @export
 */
export const InventoryPartInfoUnitOfMeasureEnum = {
    DiscreteUnit: 'discrete_unit',
    Liter: 'liter',
    Ml: 'ml',
    Gallon: 'gallon',
    Quart: 'quart',
    Ounce: 'ounce',
    FlOz: 'fl_oz'
} as const;
export type InventoryPartInfoUnitOfMeasureEnum = typeof InventoryPartInfoUnitOfMeasureEnum[keyof typeof InventoryPartInfoUnitOfMeasureEnum];


/**
 * Check if a given object implements the InventoryPartInfo interface.
 */
export function instanceOfInventoryPartInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InventoryPartInfoFromJSON(json: any): InventoryPartInfo {
    return InventoryPartInfoFromJSONTyped(json, false);
}

export function InventoryPartInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryPartInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shop': !exists(json, 'shop') ? undefined : json['shop'],
        'partsTechPartId': !exists(json, 'partsTechPartId') ? undefined : json['partsTechPartId'],
        'repairLinkPartId': !exists(json, 'repairLinkPartId') ? undefined : json['repairLinkPartId'],
        'partNumber': !exists(json, 'partNumber') ? undefined : json['partNumber'],
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'brandDisplayName': !exists(json, 'brandDisplayName') ? undefined : json['brandDisplayName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'stocked': !exists(json, 'stocked') ? undefined : json['stocked'],
        'isFluids': !exists(json, 'isFluids') ? undefined : json['isFluids'],
        'suggestedPricePerUnit': !exists(json, 'suggestedPricePerUnit') ? undefined : json['suggestedPricePerUnit'],
        'listCost': !exists(json, 'listCost') ? undefined : json['listCost'],
        'unitOfMeasure': !exists(json, 'unitOfMeasure') ? undefined : json['unitOfMeasure'],
        'partsMatrix': !exists(json, 'partsMatrix') ? undefined : json['partsMatrix'],
        'partTaxonomy': !exists(json, 'partTaxonomy') ? undefined : PartTaxonomyFromJSON(json['partTaxonomy']),
        'archived': !exists(json, 'archived') ? undefined : (new Date(json['archived'])),
    };
}

export function InventoryPartInfoToJSON(value?: InventoryPartInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop': value.shop,
        'partsTechPartId': value.partsTechPartId,
        'repairLinkPartId': value.repairLinkPartId,
        'partNumber': value.partNumber,
        'brand': value.brand,
        'brandDisplayName': value.brandDisplayName,
        'name': value.name,
        'description': value.description,
        'position': value.position,
        'size': value.size,
        'location': value.location,
        'stocked': value.stocked,
        'isFluids': value.isFluids,
        'suggestedPricePerUnit': value.suggestedPricePerUnit,
        'listCost': value.listCost,
        'unitOfMeasure': value.unitOfMeasure,
        'partsMatrix': value.partsMatrix,
        'partTaxonomy': PartTaxonomyToJSON(value.partTaxonomy),
        'archived': value.archived === undefined ? undefined : (value.archived.toISOString()),
    };
}

